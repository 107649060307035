import { createSlice } from '@reduxjs/toolkit';
import _, { uniqBy } from 'lodash';
import { asyncActionCheckMarkerIsValid, asyncActionCheckMarkerIsValidMandala } from './droppable_marker.asyncAction';

/**
 * @description For marker history
 * @typedef TMarkerHistoryObject
 * @type {Object}
 * @property {number} id
 * @property {number} lat
 * @property {number} long
 * @property {number} id_provinsi
 * @property {string} nama_provinsi
 * @property {number} id_kota
 * @property {string} nama_kota
 * @property {number} id_kecamatan
 * @property {string} nama_kecamatan
 * @property {number} id_desa
 * @property {string} nama_desa
 */

/**
 * @description For marker list and history
 * @typedef TMarkerObject
 * @type {object}
 * @property {number} id
 * @property {string} siteName
 * @property {string} address
 * @property {string} IconColor
 * @property {string} icon_type
 * @property {Array<number|string>} coordinate
 * @property {number} id_provinsi
 * @property {string} nama_provinsi
 * @property {number} id_kota
 * @property {string} nama_kota
 * @property {number} id_kecamatan
 * @property {string} nama_kecamatan
 * @property {number} id_desa
 * @property {string} nama_desa
 * @property {boolean} isChecked
 * @property {number} lat
 * @property {number} long
 * @property {string} id_group
 * @property {Array<TMarkerHistoryObject>} history
 * @property {Array<string>} MarkerGroups
 * @property {string} created_at
 * @property {string} updated_at
 */

/**
 * @typedef initialStateModel
 * @type {object}
 * @property {number} activeMarkerIndex
 * @property {number} activeMarkerHistoryIndex
 * @property {Array<TMarkerObject>} markerList
 * @property {Array<TMarkerHistoryObject>} historyList
 * @property {boolean} draggable
 * @property {string} marker_icon
 * @property {string} marker_color
 * @property {'IDLE'|'SUCCESS'|'FAILED' | 'LOADING'} status_PLACE
 * @property {Array<TMarkerObject>} filteredMarkerList
 * @property {{x: number, y: number}} point
 */

/**
 * @type {initialStateModel} initialState
 */
const initialState = {
  activeMarkerHistoryIndex: null,
  activeMarkerIndex: null,
  markerList: [],
  historyList: [],
  draggable: false,
  marker_icon: 'Marker8903',
  marker_color: '#046A65',
  status_PLACE: 'IDLE',
  filteredMarkerList: [],
  point: undefined,
  coordinate: [],
  activeMarkers: [],
  activeMarkersRight: [],
};

export const droppable_marker_slice = createSlice({
  name: 'LI/mainmap/droppable-marker',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {TMarkerObject} action.payload.marker
     */
    addNewHistoryToList(state, action) {
      const {
        payload: { history },
      } = action;
      state.historyList.push(history);
      state.activeMarkerHistoryIndex = state.historyList.length - 1;
    },
    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {TMarkerObject} action.payload.marker
     */
    addNewMarkerToList(state, action) {
      const {
        payload: { marker },
      } = action;
      state.markerList = uniqBy([marker, ...state.markerList], 'id').sort((a, b) => a.id - b.id) || [];
      state.activeMarkerIndex = state.markerList.length - 1;
      state.historyList = [];
      state.activeMarkerHistoryIndex = null;
    },
    clearHistoryLisst(state) {
      state.historyList = [];
      state.activeMarkerHistoryIndex = null;
      state.activeMarkerIndex = null;
      state.marker_color = initialState.marker_color;
      state.marker_icon = initialState.marker_icon;
    },
    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {number} action.payload.id
     */
    deleteMarkerById(state, action) {
      const {
        payload: { id },
      } = action;
      state.markerList = _.filter(state.markerList, (vm) => vm.id !== id);
      state.historyList = [];
      state.activeMarkerIndex = null;
      state.activeMarkerHistoryIndex = null;
      state.marker_color = initialState.marker_color;
      state.marker_icon = initialState.marker_icon;
      state.filteredMarkerList = _.filter(
        state.markerList,
        (vm) => vm.id !== id
      );
    },
    /**
     * @description
     * @param {object} action
     * @param {object} action.payload
     * @param {number} action.payload.id
     * @param {TMarkerObject} action.payload.data
     */
    saveMarkerOrEditById(state, action) {
      const {
        payload: { id, data },
      } = action;
      const tmpIdx = _.findIndex(state.markerList, (vm) => vm.id === id);
      if (tmpIdx === -1) {
        let tmpData = { ...data, id: new Date().getTime() };
        state.markerList.push(tmpData);
        state.historyList = [];
        state.activeMarkerIndex = null;
        state.activeMarkerHistoryIndex = null;
        state.marker_color = initialState.marker_color;
        state.marker_icon = initialState.marker_icon;
        state.filteredMarkerList.push(tmpData);
      } else {
        state.markerList[tmpIdx] = data;
        state.historyList = [];
        state.activeMarkerIndex = null;
        state.activeMarkerHistoryIndex = null;
        state.filteredMarkerList[tmpIdx] = data;
      }
    },
    /**
     * @param {object} action
     * @param {boolean} action.payload
     */
    changeDragStatus(state, action) {
      const { payload } = action;
      state.draggable = payload;
    },

    /**
     * @param {object} action
     * @param {boolean} action.payload
     */
    changeMarkerIcon(state, action) {
      const { payload } = action;
      state.marker_icon = payload;
    },

    resetMarkerIcon(state) {
      state.marker_icon = initialState.marker_icon;
    },

    /**
     * @param {object} action
     * @param {boolean} action.payload
     */
    changeMarkerColor(state, action) {
      const { payload } = action;
      state.marker_color = payload;
    },

    resetMarkerColor(state) {
      state.marker_color = initialState.marker_color;
    },

    /**
     * @param {object} action
     * @param {boolean} action.payload
     */
    setMarkerActiveIndex(state, action) {
      const { payload } = action;
      state.activeMarkerIndex = payload;
    },

    /**
     * @param {object} action
     * @param {boolean} action.payload
     */
    setHistoryMarkerActiveIndex(state, action) {
      const { payload } = action;
      state.activeMarkerHistoryIndex = payload;
    },
    setFilterMarkerList(state, { payload }) {
      state.filteredMarkerList = state.markerList.filter((v) =>
        v.siteName.toLocaleLowerCase().includes(payload.toLocaleLowerCase())
      );
    },
    setFilterByArea(state, { payload }) {
      let tempMarkerList = state.markerList;
      if (payload?.keyword) {
        tempMarkerList = state.markerList.filter((v) =>
          v.siteName
            ?.toLocaleLowerCase()
            .includes(payload.keyword?.toLowerCase())
        );
      }
      state.filteredMarkerList = tempMarkerList.filter((v) => {
        if (payload.id_desa) {
          return v.id_desa === payload.id_desa;
        } else if (payload.id_kecamatan) {
          return v.id_kecamatan === payload.id_kecamatan;
        } else if (payload.id_kota) {
          return v.id_kota === payload.id_kota;
        } else if (payload.id_provinsi) {
          return v.id_provinsi === payload.id_provinsi;
        }
        return v.id_provinsi;
      });
    },
    setPoint(state, { payload }) {
      state.point = payload;
    },

    setActiveMarkers(state, { payload }) {
      const ids = payload.ids || [];
      state.activeMarkers = ids.map(id => state.markerList.find(marker => marker.id === id))
        .filter(marker => marker !== undefined);

    },
    setActiveMarkersRight(state, { payload }) {
      const ids = payload.ids || [];
      state.activeMarkersRight = ids.map(id => state.markerList.find(marker => marker.id === id))
        .filter(marker => marker !== undefined);
    },
    setCoordinate(state, { payload }) {
      state.coordinate = payload;
    },
    setMarkerList(state, { payload }) {
      state.markerList = payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(asyncActionCheckMarkerIsValid.pending, (state) => {
        state.status_PLACE = 'LOADING';
      })
      .addCase(asyncActionCheckMarkerIsValid.fulfilled, (state, action) => {
        const { payload } = action;
        state.status_PLACE = 'SUCCESS';
        state.historyList.push(payload.result);
        state.activeMarkerHistoryIndex = state.historyList.length - 1;
        state.point = undefined;
      })
      .addCase(asyncActionCheckMarkerIsValid.rejected, (state) => {
        state.status_PLACE = 'FAILED';
        state.point = undefined;
      })
      .addCase(asyncActionCheckMarkerIsValidMandala.pending, (state) => {
        state.status_PLACE = 'LOADING';
      })
      .addCase(asyncActionCheckMarkerIsValidMandala.fulfilled, (state, action) => {
        const { payload } = action;
        state.status_PLACE = 'SUCCESS';
        state.historyList.push(payload);
        state.activeMarkerHistoryIndex = state.historyList.length - 1;
        state.point = undefined;
      })
      .addCase(asyncActionCheckMarkerIsValidMandala.rejected, (state) => {
        state.status_PLACE = 'FAILED';
        state.point = undefined;
      }),
});

export const {
  reducer: DROPPABLE_MARKER_REDUCER,
  actions: DROPPABLE_MARKER_ACTION,
} = droppable_marker_slice;
