import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * @author Amiruriza <amiruriza@bvarta.com>
 * @memberof atoms
 * @param {Object} props
 * @param {boolean} props.disabled
 * @param {number} props.max
 * @param {number} props.min
 * @param {string} props.name
 * @param {func} props.onChange
 * @param {number} props.step
 * @param {number} props.value

 */

function RangeSlider(props) {
  const {
    disabled,
    min,
    max,
    name,
    step,
    value,
    onChange,
    detail,
    width,
    height,
  } = props;
  const color = disabled ? '#b1b7c1, #b1b7c1' : '#007e75, #007e75';
  const style = {
    input: `RangeSlider ${disabled ? 'disabled' : ''}`,
    text: `RangeSlider-text ${disabled ? 'disabled' : ''}`,
  };

  const [inputValue, setInputValue] = useState(value);
  const [mouseState, setMouseState] = useState('');

  useEffect(() => {
    if (mouseState === 'up' && !disabled) {
      onChange(inputValue); // update value yg ada di parent
    }
  }, [mouseState]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const changeCallback = (e) => {
    if (!disabled) {
      setInputValue(e.target.value);
    }
  };

  return (
    <>
      <input
        className={style.input}
        max={max}
        min={min}
        name={name}
        onChange={changeCallback}
        onMouseDown={() => setMouseState('down')}
        onMouseUp={() => setMouseState('up')}
        step={step}
        style={{
          height: height ? height : '0.375rem',
          width: width ? width : 'inherit',
          backgroundSize: `${(inputValue * 100) / max}% 100%`,
          backgroundColor: '#78849e27',
          backgroundImage: `linear-gradient(${color})`,
          cursor: disabled ? 'auto' : 'pointer',
        }}
        title={inputValue}
        type='range'
        value={inputValue}
      />
      {detail && <span className={style.text}>{inputValue}</span>}
    </>
  );
}

RangeSlider.defaultProps = {
  detail: false,
  disabled: false,
  max: 100,
  min: 0,
  name: '',
  onChange: () => {},
  step: 1,
  value: 0,
};

RangeSlider.propTypes = {
  detail: PropTypes.bool,
  disabled: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  step: PropTypes.number,
  value: PropTypes.number,
};

export default memo(RangeSlider);
