import React from 'react';
import './CardDatexTelcoDataDetail.scss';
import { CardLayerLegend } from '../CardLayerLegend';
import { CardLayerResult } from '../CardLayerResult/CardLayerResult';
import { ImageBox } from '@bvt-shared/component/ImageBox';

/**
 * @copyright Bhumi Varta Technology
 * @author Malik<malik.ibrahim@bvarta.com>
 * @param {Object} props
 * @param {String} props.area
 * @param {String} props.date
 * @param {String} props.time
 * @param {Object} props.dataSource
 * @param {String} props.catchmentBy
 * @param {String} props.catchmentTimeType
 * @param {Array} props.layerLegend
 * @param {object} props.gridDetail

 */
const CarDatexTelcoDataDetail = (props) => {
  const {
    layerLegend,
    gridDetail,
  } = props;

  return (
    <div className='CarDatexTelcoDataDetail__container'>
      <div className='CarDatexTelcoDataDetail__btn-summary'>
        <span className='CarDatexTelcoDataDetail__btn-summary-text'>
          Layer Legend
        </span>
      </div>

      <CardLayerLegend data={layerLegend} />

      {Object.entries(gridDetail).length !== 0 ? (
        <>
          <div className='CarDatexTelcoDataDetail__btn-summary'>
            <span className='CarDatexTelcoDataDetail__btn-summary-text'>
              Layer Result
            </span>
          </div>

          <CardLayerResult data={gridDetail} />
        </>
      ) : (
        <div className='CarDatexTelcoDataDetail__no-data'>
          <ImageBox className='CarDatexTelcoDataDetail__no-data--icon' src='ic-no-data-grid-detail' />
          <div className='CarDatexTelcoDataDetail__no-data--text'>
            <span className='CarDatexTelcoDataDetail__no-data--text--header'>
              Please select a grid
            </span>
            <span className='CarDatexTelcoDataDetail__no-data--text--desc'>
            Click a grid on map to view summary
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export { CarDatexTelcoDataDetail };
