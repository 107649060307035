export const typeProject = (type) => {
  if (type === 'Grid Analysis') {
    return 'Analysis - Grid Profiling';
  } else if (type === 'Site Profiling') {
    return 'Analysis - Site Profiling';
  } else if (type === 'Site Profilling Mandala') {
    return 'Analysis - Site Profiling Mandala';
  } else if (type === 'Grid Analysis Lawu') {
    return 'Analysis - Grid Profiling Lawu';
  } else if (type === 'Business Profilling') {
    return 'Analysis - Business Profiling';
  } else {
    return 'Analysis - Grid Profiling';
  }
};
