import React from 'react';
import Page from '../../../pages/bvt';
import MenuBarContainer from '@bvt-features/mainmap/menu-bar/container/MenuBarContainer';

export const RoutesAppMaps = {
  path: 'maps',
  element: (
    <React.Fragment>
      <MenuBarContainer />
      <Page.NewInterface />
    </React.Fragment>
  ),
};
