import { useDispatch, useSelector } from 'react-redux';
import { LEGEND_ACTION } from '../store/legend.slice';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

export const useLegend = () => {
  const state = useSelector(
    /**
     * @param {ReturnType<typeof import('../../../../redux/rootStore').rootStore.getState>} state
     */
    (state) => state.features.MAINMAP.LEGEND
  );

  const dispatch = useDispatch();

  /**
   * @description For legend list
   * @typedef ILegendParent
   * @type {object}
   * @property {string|number} key
   * @property {string} title
   * @property {Array<ILegendItem>} items
   */

  /**
   * @description For legend list item
   * @typedef ILegendItem
   * @type {object}
   * @property {string|number} key
   * @property {'CIRCLE'|'SQUARE'|'POLY'|'ICON'} type
   * @property {React.ReactElement} icon
   * @property {string} title
   * @property {string} color
   * @property {string} group
   * @property {number} groupOrder
   * @property {number} id
   * @property {number} order
   */

  /**
   * @param {object} param
   * @param {ILegendParent} param.data
   */
  const pushData = (param) => {
    dispatch(LEGEND_ACTION.pushDataToLegend(param));
  };

  const registerData = (param) => {
    dispatch(LEGEND_ACTION.registerDataToLegend(param));
  };

  const removeDataGroup = (parent, group) => {
    dispatch(LEGEND_ACTION.removeDataGroup({ parent, group }));
  };

  /**
   *
   * @param {boolean} param
   */
  const toggleOpenLegend = (param) => {
    dispatch(LEGEND_ACTION.toggleOpenLegend({ isOpen: param }));
  };

  const clearData = () => {
    dispatch(LEGEND_ACTION.clearLegendData());
  };

  /**
   * @param {string} parent
   * @param {string} group
   * @param {string} key
   */
  const removeDataByKey = (parent, group, key) => {
    dispatch(LEGEND_ACTION.removeDataByKey({ parent, group, key }));
  };

  /**
   * @param {Array<string | number>} param
   */
  const removeDataByPrefix = (param) => {
    dispatch(LEGEND_ACTION.removeDataByPrefix({ prefix: param }));
  };

  /**
   * @param {boolean} param
   */
  const isLoadingData = (param) => {
    dispatch(LEGEND_ACTION.onLoadLegendData({ isLoading: param }));
  };

  return {
    state,
    pushData,
    toggleOpenLegend,
    clearData,
    removeDataByKey,
    removeDataByPrefix,
    isLoadingData,
    registerData,
    removeDataGroup,
  };
};
