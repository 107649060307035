import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 2.1
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Faisal <ahmad.faisal@bvarta.com>
 */

function IconFilter({ className }) {
  const customClass = `${className} icon-filter`;

  return (
    <div className={customClass}>
      <svg
        height='12.864'
        viewBox='0 0 15.041 12.864'
        width='15.041'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M18.643,6.907a1.222,1.222,0,0,0-1.177-1.22v0H4.888v0c-.022,0-.043,0-.065,0a1.222,1.222,0,0,0-.767,2.173l5.667,5.667v3.526h0a.638.638,0,0,0,.322.526l0,0,1.48.854c.013.009.026.015.039.022l.005,0h0a.64.64,0,0,0,.962-.554c0-.017,0-.033,0-.05h0V13.53l5.719-5.719-.006-.006A1.217,1.217,0,0,0,18.643,6.907ZM7.784,8.129h0v0Z'
          fill='#007e75'
          id='filter'
          transform='translate(-3.601 -5.685)'
        />
      </svg>
    </div>
  );
}

export default IconFilter;

IconFilter.defaultProps = {
  className: '',
};

IconFilter.propTypes = {
  className: PropTypes.string,
};
