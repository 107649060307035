import {
  MAPP_PROJECT_ACTIONS,
  MAPP_PROJECT_CONSTANT,
} from '@bvt-features/mapp/project/redux';
import { useDispatch, useSelector } from 'react-redux';

export function useProject() {
  /**
   * @typedef {Object} MAPP_PROJECT_REDUCER
   * @property {string} [status_GET]
   * @property {string} [status_DETAIL]
   * @property {string} [status_POST]
   * @property {string} [status_PUT]
   * @property {string} [status_DELETE]
   * @property {string} [message]
   * @property {Array} [list]
   * @property {Object} [detail]
   * @property {string} [message]
   */

  /**
   *  @type {MAPP_PROJECT_REDUCER} MAPP_PROJECT_REDUCER
   */
  const state = useSelector((state) => state.features.MAPP.PROJECT);
  const dispatch = useDispatch();

  const create = (payload) => {
    dispatch(MAPP_PROJECT_ACTIONS.create(payload));
  };

  /**
   *
   * @param {number} id id project
   */
  const getDetail = (id) => {
    dispatch(MAPP_PROJECT_ACTIONS.getDetail(id));
  };

  /**
   *
   * @param {number} id id project
   * @param {object} payload body project name
   */
  const rename = (id, payload) => {
    dispatch(MAPP_PROJECT_ACTIONS.rename(id, payload));
  };

  const resetState = () => {
    dispatch({ type: MAPP_PROJECT_CONSTANT.RESET_ALL });
  };

  const resetStatus = () => {
    dispatch({ type: MAPP_PROJECT_CONSTANT.RESET_STATUS });
  };

  /**
   * @param {object} param
   * @param {number | string} param.project_id
   * @param {string} param.base64
   * @param {'pdf' | 'csv' | "xlsx"} param.type
   */
  const generateReport = (param) => {
    dispatch(MAPP_PROJECT_ACTIONS.generateReport(param));
  };

  const getAll = (param) => {
    dispatch(MAPP_PROJECT_ACTIONS.getAll(param));
  };

  const rangeDateExist = (param, rangeDateExist) => {
    dispatch(MAPP_PROJECT_ACTIONS.rangeDateExist(param, rangeDateExist));
  };

  return {
    state,
    create,
    getDetail,
    rename,
    resetState,
    resetStatus,
    generateReport,
    getAll,
    rangeDateExist
  };
}
