import React, { useEffect, useState, createRef } from 'react';
import PropTypes from 'prop-types';
import { IconClose, IconCloud, IconGridAnalysis } from '@bvt-icons';
import { Modal } from 'antd';
import { BusinessProfiling, SiteProfiling } from 'bvt-icon';
import { typeProject } from './constans';

/**
 * Popup Form Create Project
 * @copyright PT Bhumi Varta Technology
 * @memberof organisms
 * @author Faisal <ahmad.faisal@bvarta.com>
 * @author Renta <renta.yustika@bvarta.com>
 * @param {Object} props
 * @param {Object} props.handlers
 * @param {Function} props.handlers.handleCancel
 * @param {Function} props.handlers.handleSubmit
 * @param {Function} props.handlers.handleFailed
 * @param {Boolean} props.show
 * @param {Boolean} props.isFailed
 * @param {String} props.lastUsed
 * @param {'Grid Analysis' | 'Site Profilling' | 'Business Profilling' } props.type
 * @param {string} props.projectName
 * @param {string} props.errorMessage
 * @param {number} props.lastStep
 * @param {boolean} props.renameChecker
 * @param {(name:string)=>void} props.onChangeProjectName
 */

function PopupFormCreateProject(props) {
  const {
    handlers: { handleCancel, handleSubmit, handleFailed },
    show,
    type,
    lastUsed,
    isFailed,
    projectName,
    errorMessage,
    isLoading,
    lastStep = 0,
    renameChecker = false,
    onChangeProjectName,
  } = props;

  const [nameProject, setNameProject] = useState('');

  /** @type {import('react').RefObject<HTMLInputElement>} */
  const inputRef = createRef();

  const payload = {
    name: nameProject,
    project_type: typeProject(type),
  };

  useEffect(() => {
    if (projectName) {
      setNameProject(projectName);
      onChangeProjectName && onChangeProjectName(projectName);
    }
  }, [projectName]);

  useEffect(() => {
    inputRef.current.blur();
  }, [isFailed]);

  let errMsg = errorMessage ?? 'Failed';
  let submitText = !renameChecker ? 'Create' : 'Rename';

  return (
    <Modal
      className='modalCreateProject'
      closeIcon={<IconClose background='white' />}
      footer={null}
      onCancel={() => handleCancel(lastStep)}
      // onOk={() => handleSubmit(payload, lastStep)}
      open={show}
      title='Create new project'
      zIndex={10000}
    >
      <form
        className='popupFormCreateProject'
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(payload, lastStep);
        }}
      >
        <div className='header'>
          <div className='logo'>
            {type === 'Grid Analysis' && <IconGridAnalysis />}
            {type === 'Grid Analysis Lawu' && <IconGridAnalysis />}
            {type === 'Site Profiling' && <SiteProfiling />}
            {type === 'Site Profilling Mandala' && <SiteProfiling />}
            {type === 'Business Profiling' && <BusinessProfiling />}
          </div>
          <div className='title'>
            <div className='name'>{type}</div>
            <div className='lastUsed'>Last used {lastUsed}</div>
          </div>
        </div>
        <div
          className={`input ${nameProject && !isFailed ? 'active' : ''} ${
            isFailed ? 'failed' : ''
          }`}
        >
          <input
            className={`input_value ${
              nameProject && !isFailed ? 'active' : ''
            }`}
            disabled={isFailed}
            onChange={(e) => {
              setNameProject(e.target.value);
              onChangeProjectName && onChangeProjectName(e.target.value);
            }}
            placeholder='project name'
            ref={inputRef}
            type='text'
            value={isFailed ? errMsg : nameProject}
          />
          <div className='endadornment'>
            {isFailed || nameProject ? (
              <IconClose
                background='white'
                onClick={() => {
                  handleFailed(false, lastStep);
                  setNameProject('');
                  onChangeProjectName && onChangeProjectName('');
                }}
              />
            ) : null}
          </div>
        </div>
        <div className='note'>
          <div className='logo'>
            <IconCloud />
          </div>
          <div className='text'>
            Remember: When you save the file it will be directed to our cloud
            server by linking your account
          </div>
        </div>
        <div className='footer'>
          <button
            className='cancel'
            onClick={() => handleCancel(lastStep)}
            type='button'
          >
            Cancel
          </button>
          <button
            className={`create ${nameProject && !isLoading ? 'active' : ''}`}
            disabled={!nameProject || isLoading}
            type='submit'
          >
            {isLoading ? 'Creating...' : submitText}
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default PopupFormCreateProject;

PopupFormCreateProject.defaultProps = {
  handlers: {
    handleCancel: () => {
      return;
    },
    handleFailed: () => {
      return;
    },
    handleSubmit: () => {
      return;
    },
  },
  isFailed: false,
  lastUsed: '2 days ago',
  show: false,
  type: 'Site Profilling',
};

PopupFormCreateProject.propTypes = {
  errorMessage: PropTypes.any,
  handlers: PropTypes.shape({
    handleCancel: PropTypes.func,
    handleFailed: PropTypes.func,
    handleSubmit: PropTypes.func,
  }),
  isFailed: PropTypes.bool,
  isLoading: PropTypes.any,
  lastUsed: PropTypes.string,
  projectName: PropTypes.any,
  show: PropTypes.bool,
  type: PropTypes.string,
};
