import React from 'react';
import PropTypes from 'prop-types';
import './Loading.scss';

/**
 *
 * @param {object} props
 * @param {string} props.fill
 * @param {string} props.height
 * @param {string} props.width
 */

export default function Loading(props) {
  return (
    <div className='feature-account Loading'>
      <svg
        height={props.height}
        style={{ marginTop: '2px', marginBottom: '2px' }}
        viewBox='0 0 14.229 14.229'
        width={props.width}
      >
        <path
          d='M9.115,14.1a4.98,4.98,0,1,0-4.98-4.98A4.98,4.98,0,0,0,9.115,14.1Zm0,2.134A7.115,7.115,0,1,0,2,9.115,7.115,7.115,0,0,0,9.115,16.229Z'
          fill='#000'
          fillRule='evenodd'
          opacity='0.2'
          transform='translate(-2 -2)'
        />
        <path
          d='M2,9.115A7.115,7.115,0,0,1,9.115,2V4.134a4.98,4.98,0,0,0-4.98,4.98Z'
          fill={props.fill}
          transform='translate(-2 -2)'
        />
      </svg>
    </div>
  );
}

Loading.defaultProps = {
  fill: '#007E75',
  height: '16',
  width: '16',
};
Loading.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};
