/* eslint-disable import/namespace */
import React from 'react';
import './POISymbol.scss';

/**
 * @company PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

/**
 * @param {object} props
 * @param {React.ReactElement} props.icon
 */

export const POISymbol = (props) => {
  const { icon } = props;

  return <div className='legend-symbol__poi'>{icon}</div>;
};
