import React from 'react';
import { Col, Row, Typography } from 'antd';
import { Skeleton } from '@bvt-shared/component/Skeleton';
import './HeaderSegment.scss';
/**
 * @version 2.1
 * @copyright Bhumi Varta Technology
 * @memberof molecules
 * @author Randa <malik.ibrahim@bvarta.com>
 * @author Randa <m.randa@bvarta.com>
 * @author Amir <amir@bvarta.com>
 * @author Renta <renta.damanik@bvarta.com>
 * @author Imam <imam.rozali@bvarta.com>
 * @param {Object} props
 * @param {JSX.Element} props.children children element can be filled with any react element
 * @param {'default'|'outlined' | 'gray'} props.variant variant of the header segment
 * @param {string} props.title if title string filled, the header become half
 * @param {Object} props.handler Object handler
 * @param {Boolean} props.loading loading state
 * @param {import('react').CSSProperties} props.style harcoded style
 * @param {String} props.className harcoded style
 * @param {Function} props.handler.onBack back click handler, if defined back button will showed
 */

const segment = (props) => {
  return (
    <Skeleton height='2.9rem' loading={props.loading} type='square'>
      <div className={props.className} style={props.style}>
        <div
          className={`header-segment header-segment--${
            props.variant ? props.variant : 'default'
          }`}
        >
          <Row align='middle' gutter={[16, 32]}>
            <Col
              className='header-segment__title-container'
              md={8}
              sm={12}
              xl={6}
              xs={24}
            >
              {props.handler?.onBack && (
                <div
                  className='header-segment__back-button'
                  onClick={(e) => props.handler.onBack(e)}
                  tabIndex={-1}
                >
                  <svg height='16' viewBox='0 0 7.132 11.374' width='16'>
                    <g
                      data-name='Group 534'
                      id='Group_534'
                      transform='translate(10 5) rotate(135)'
                    >
                      <rect
                        data-name='Rectangle 15'
                        fill='currentColor'
                        height='2.486'
                        id='Rectangle_15'
                        rx='1.243'
                        transform='translate(0 5.557)'
                        width='7.6'
                      />
                      <rect
                        data-name='Rectangle 16'
                        fill='currentColor'
                        height='2.486'
                        id='Rectangle_16'
                        rx='1.243'
                        transform='translate(8.043) rotate(90)'
                        width='7.6'
                      />
                    </g>
                  </svg>
                </div>
              )}
              <Typography.Title
                className={`header-segment__title header-segment__title--${
                  props.variant ? props.variant : 'default'
                }`}
                title={props.title}
              >
                {props.title}
              </Typography.Title>
            </Col>
            <Col md={16} sm={12} xl={props?.title ? 18 : 24} xs={24}>
              {props.children}
            </Col>
          </Row>
        </div>
      </div>
    </Skeleton>
  );
};

export const HeaderSegment = React.memo(segment);

