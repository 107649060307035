import * as React from "react"

export const IconTriangleDown = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
    {...props}
  >
    <path
      d="M7.143 9.4a1.448 1.448 0 0 1-.504.442 1.373 1.373 0 0 1-1.278 0 1.448 1.448 0 0 1-.504-.442l-3.571-5a1.565 1.565 0 0 1-.135-1.57c.118-.25.3-.46.527-.606.225-.146.485-.224.75-.224h7.143c.266 0 .526.078.752.224.225.146.407.356.526.605a1.565 1.565 0 0 1-.135 1.571l-3.571 5Z"
      fill="#4D4F5C"
    />
  </svg>
)
