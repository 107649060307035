/* eslint-disable indent */
import { Collapse, Radio } from 'antd';
import { isObject } from 'lodash';
const { Panel } = Collapse;
import React, { useEffect, useState } from 'react';
import './BaseCollapsePOI.scss';
import { Skeleton } from '@bvt-shared/component/Skeleton';
import { Popover } from '@bvt-atoms';
import { generateUUID } from '@bvt-utils';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 * @author Malik<malik.ibrahim@bvarta.com>
 */

/**
 * @typedef IBaseKeyValue
 * @type {Object}
 * @property {string|number} key
 * @property {string} value
 */

/**
 * @typedef IBaseKeyValueIcon
 * @type {Object}
 * @property {string|number} key
 * @property {string} value
 * @property {string} icon
 */

/**
 * @typedef IBaseGroup
 * @type {Object}
 * @property {string|number} key
 * @property {string} label
 * @property {string} icon
 * @property {Array<IBaseKeyValue>} brands
 * @property {Array<IBaseKeyValue>} criterias
 */
/**
 * @typedef IGroupObject
 * @type {object}
 * @property {string|number} key
 * @property {string} label
 * @property {string} icon
 * @property {Array<IBaseGroup>} categories
 */
/**
 * @typedef TGroupList
 * @type {Array<IGroupObject>}
 * /
/**
 * @param {Object} props
 * @param {(valid:boolean)=>void} props.onValidInvalid
 * @param {TGroupList} props.groupList
 * @param {ICheckObject} props.checked
 * @param {(data:{poiGroup:IBaseKeyValueIcon,brandList:Array<IBaseKeyValue>,criteriaList:Array<IBaseKeyValue>,poiCategory:{ index:Number,...IBaseKeyValueIcon}})=>void} props.onCheck
 * @param {(idCategory:string|number)=>void} props.onEdit
 * @param {Array<String>} props.selectedCriterias
 * @param {Array<Number|String>} props.selectedBrands
 */

export const BaseCollapsePOI = (props) => {
  const {
    onValidInvalid,
    groupList,
    checked,
    onCheck,
    onEdit,
    selectedBrands = [],
    selectedCriterias = [],
  } = props;

  const [state, setState] = useState({
    activeCollapse: '',
    checkedGroup: {},
    checkedCategory: {},
  });

  function compareStatus(a, b) {
    const statusA = a.status || '';
    const statusB = b.status || '';

    return statusB.localeCompare(statusA);
  }

  useEffect(() => {
    if (isObject(checked)) {
      setState({
        ...state,
        checkedGroup: checked?.poiGroup,
        checkedCategory: checked?.poiCategory,
      });
    }
  }, [checked]);

  useEffect(() => {
    if (Object.keys(state?.checkedCategory)?.length > 0) {
      onValidInvalid && onValidInvalid(true);
    } else {
      onValidInvalid && onValidInvalid(false);
    }
  }, [state]);

  useEffect(() => {
    setState({
      ...state,
      activeCollapse: [checked?.poiCategory?.index],
    });
  }, []);

  const popoverContent = (
    <div className='BaseCollapsePOI container-popover'>
      <div className='BaseCollapsePOI arrow arrow---down' />
      <p className='BaseCollapsePOI container-popover-text'>
        Contact our sales to subscribe
      </p>
    </div>
  );

  const getClassChecked = (key) => state?.checkedCategory?.key === key ? 'checked' : '';
  const getInfoBrandsCriterias = (key) => {
    return state?.checkedCategory?.key === key
      ? `${selectedBrands?.length} Brands, ${selectedCriterias?.length} Criterias`
      : '';
  };

  return (
    <Collapse
      activeKey={state?.activeCollapse}
      className='BaseCollapsePOI'
      onChange={(e) => {
        setState({
          ...state,
          activeCollapse: e,
        });
      }}
    >
      {groupList?.toSorted(compareStatus)?.map((v, vi) => {
        return !v.status ? (
          <Popover
            className='BaseCollapsePOI__popover-wrapper'
            content={popoverContent}
            header={false}
            key={generateUUID()}
            placement='top-left'
            trigger='hover'
            visibility={v.status}
          >
            <Panel
              className='BaseCollapsePOI__panel'
              header={
                <div className='BaseCollapsePOI__panel--header disabled'>
                  <Skeleton height='2rem' loading={!v?.label} type='image'>
                    <img alt={v?.label} src={v?.icon} />
                  </Skeleton>
                  <span>{v?.label}</span>
                </div>
              }
              key={v?.key}
            >
              <Radio.Group
                className='BaseCollapsePOI__radio-group'
                value={state?.checkedCategory?.key}
              >
                <div
                  className='BaseCollapsePOI__radio-group--space'
                  direction='vertical'
                >
                  {v?.categories?.map((w) => {
                    return (
                      <div className='BaseCollapsePOI__radio--wrapper' key={generateUUID()}>
                        <Radio
                          checked={state?.checkedGroup?.key === w?.key}
                          className={`BaseCollapsePOI__radio--item ${getClassChecked(v?.key)}`}
                          onChange={() => {
                            setState({
                              ...state,
                              checkedGroup: {
                                key: v?.key,
                                value: v?.value,
                              },
                              checkedCategory: {
                                key: w?.key,
                                value: w?.value,
                              },
                            });
                           onCheck?.({
                              poiGroup: {
                                key: v?.key,
                                value: v?.label,
                                icon: v?.icon,
                              },
                              poiCategory: {
                                key: w?.key,
                                value: w?.label,
                                icon: w?.icon,
                                index: vi,
                              },
                              brandList: w?.brands || [],
                              criteriaList: w?.criterias || [],
                            });
                          }}
                          value={w?.key}
                        >
                          <div className='BaseCollapsePOI__radio-wrapper'>
                            <div className='BaseCollapsePOI__radio-wrapper--left'>
                              <div className='BaseCollapsePOI__radio-item--icon'>
                                <Skeleton
                                  height='2rem'
                                  loading={!w?.label}
                                  type='image'
                                >
                                  <img alt={w?.label} src={w?.icon} />
                                </Skeleton>
                              </div>
                              <div className='BaseCollapsePOI__radio-item--title'>
                                {w?.label}
                              </div>
                            </div>

                            <div className='BaseCollapsePOI__radio-wrapper--right'>
                              <div className='BaseCollapsePOI__radio-item--total'>
                                {getInfoBrandsCriterias(w?.key)}
                              </div>
                              <button
                                className='BaseCollapsePOI__radio-item--action'
                                disabled={
                                  state?.checkedCategory?.key !== w?.key
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  onEdit?.(w?.key);
                                }}
                              >
                                Edit
                              </button>
                            </div>
                          </div>
                        </Radio>
                      </div>
                    );
                  })}
                </div>
              </Radio.Group>
            </Panel>
          </Popover>
        ) : (
          <Panel
            className='BaseCollapsePOI__panel'
            header={
              <div className='BaseCollapsePOI__panel--header'>
                <Skeleton height='2rem' loading={!v?.label} type='image'>
                  <img alt={v?.label} src={v?.icon} />
                </Skeleton>
                <span>{v?.label}</span>
              </div>
            }
            key={v?.key}
          >
            <Radio.Group
              className='BaseCollapsePOI__radio-group'
              value={state?.checkedCategory?.key}
            >
              <div
                className='BaseCollapsePOI__radio-group--space'
                direction='vertical'
              >
                {v?.categories?.map((w) => {
                  return (
                    <div className='BaseCollapsePOI__radio--wrapper' key={w.key}>
                      <Radio
                        checked={state?.checkedGroup?.key === w?.key}
                        className={`BaseCollapsePOI__radio--item ${getClassChecked(w?.key)}`}
                        onChange={() => {
                          setState({
                            ...state,
                            checkedGroup: {
                              key: v?.key,
                              value: v?.value,
                            },
                            checkedCategory: {
                              key: w?.key,
                              value: w?.value,
                            },
                          });
                          onCheck?.({
                            poiGroup: {
                              key: v?.key,
                              value: v?.label,
                              icon: v?.icon,
                            },
                            poiCategory: {
                              key: w?.key,
                              value: w?.label,
                              icon: w?.icon,
                              index: vi,
                            },
                            brandList: w?.brands || [],
                            criteriaList: w?.criterias || [],
                          });
                        }}
                        value={w?.key}
                      >
                        <div className='BaseCollapsePOI__radio-wrapper'>
                          <div className='BaseCollapsePOI__radio-wrapper--left'>
                            <div className='BaseCollapsePOI__radio-item--icon'>
                              <Skeleton
                                height='2rem'
                                loading={!w?.label}
                                type='image'
                              >
                                <img alt={w?.label} src={w?.icon} />
                              </Skeleton>
                            </div>
                            <div className='BaseCollapsePOI__radio-item--title'>
                              {w?.label}
                            </div>
                          </div>

                          <div className='BaseCollapsePOI__radio-wrapper--right'>
                            <div className='BaseCollapsePOI__radio-item--total'>
                              {getInfoBrandsCriterias(w?.key)}
                            </div>
                            <button
                              className='BaseCollapsePOI__radio-item--action'
                              disabled={state?.checkedCategory?.key !== w?.key}
                              onClick={(e) => {
                                e.preventDefault();
                                onEdit?.(w?.key);
                              }}
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                      </Radio>
                    </div>
                  );
                })}
              </div>
            </Radio.Group>
          </Panel>
        );
      })}
    </Collapse>
  );
};
