import Resource from '../../../../api/resource';

export class MainMapPoiSearch extends Resource {
  constructor() {
    super('poi', '/api', 'api-poi', 'v3');
  }

  getList(payload) {
    this.setAdditionUri('/search/poi');
    return this.create(payload);
  }
}
