import React, { useCallback, useEffect, useState } from 'react';
import { BaseSelectedCategoryCard } from '../BaseSelectedCategoryCard';
import { BaseSearchCardDatex } from '../BaseSearchCardDatex';
import { BaseOptionListItemThematic } from '../BaseOptionListItemThematic';
import { BaseOptionListItemMultipleThematic } from '../BaseOptionListItemMultipleThematic/BaseOptionListItemMultipleThematic';
import { getIcon } from '@bvt-shared/utils/iconUtils';
import _ from 'lodash';

/**
 * @company PT Bhumi Varta Technology
 * @author Malik<malik.ibrahim@bvarta.com>
 */

/**
 * @typedef IBaseKeyValueIcon
 * @type {Object}
 * @property {string|number} key
 * @property {string} value
 * @property {string} icon
 */

/**
 * @typedef IBaseKeyValue
 * @type {Object}
 * @property {string|number} key
 * @property {string} value
 */

/**
 * @typedef IBaseKeyLabelIcon
 * @type {Object}
 * @property {string|number} key
 * @property {string} label
 * @property {string} icon
 */

/**
 *
 * @param {Object} props
 * @param {Object} props.data
 * @param {Array<IBaseKeyValueIcon>} props.data.thematicItemList
 * @param {(valid:Boolean)=>void} props.onValidOrInvalid
 * @param {(data: Object{ key: Array; label: Array })=>void} props.onChange
 * @param {(e:string)=>void} props.onSearch
 * @param {boolean} props.isMultiple
 * @param {IBaseKeyLabelIcon} props.selectedCategories
 * @param {IBaseKeyValue} props.selectedSubcategories
 * @param {Array<IBaseKeyValue>} props.selectedItems
 * @param {boolean} props.isLoading
 * @param {string} props.type
 *
 */

export const DatextStepThreeThematic = (props) => {
  const {
    onChange,
    onValidOrInvalid,
    selectedCategories,
    selectedSubcategories,
    selectedItems,
    thematicItemList,
    isMultiple,
    isLoading,
    type,
  } = props;

  const [filteredThematicItemList, setFilteredThematicItemList] = useState([]);

  useEffect(() => {
    if (!filteredThematicItemList?.length > 0) {
      const sortItems = _.sortBy(thematicItemList, [
        (item) => item?.label?.split(' ')?.[0],
        (item) => {
          return parseInt(item?.label?.match(/\d+/)?.[0], 10);
        },
      ]);
      setFilteredThematicItemList(sortItems);
    }
  }, [thematicItemList]);

  const internalDebouncedSearchThematicSubcategory = useCallback(
    (e) => {
      setFilteredThematicItemList(
        thematicItemList?.filter((v) =>
          v?.label?.toLowerCase()?.includes(e?.toLowerCase())
        )
      );
    },
    [filteredThematicItemList]
  );

  return (
    <div className='DatextStepTwoThematic'>
      <BaseSelectedCategoryCard
        description={
          selectedCategories?.label ? `in ${selectedCategories?.label}` : null
        }
        icon={getIcon('thematic', selectedCategories?.icon)}
        onClear={() => {
          onChange && onChange({ key: [], label: [] });
        }}
        onSelectAll={() => {
          onChange &&
            onChange({
              key: thematicItemList?.map((v) => v?.key) ?? [],
              label: thematicItemList?.map((v) => v?.value) ?? [],
            });
        }}
        showAction={isMultiple}
        showDescription={true}
        title={selectedSubcategories?.label}
      />
      {!['grdp', 'investment'].includes(type) && (
        <BaseSearchCardDatex
          onSearch={(keyword) => {
            internalDebouncedSearchThematicSubcategory(keyword);
          }}
          placeholder='Search specific thematic data items'
        />)
      }
      {isMultiple ? (
        <BaseOptionListItemMultipleThematic
          data={filteredThematicItemList}
          isLoading={isLoading}
          onSelectItems={(e) => {
            let tempItems = e.key;
            const itemsWithKeyword = filteredThematicItemList.map((v) => v.key);
            const shoulRemoveID = itemsWithKeyword.filter(
              (v) => !e?.key?.includes(v)
            );
            tempItems = [
              ...new Set([
                ...selectedItems.filter((el) => !shoulRemoveID.includes(el)),
                ...e.key,
              ]),
            ];
            onChange &&
              onChange({
                key: tempItems,
                label: thematicItemList
                  ?.filter((v) => tempItems.includes(v?.key))
                  .map((v) => v.value),
              });
          }}
          onValidOrInvalid={onValidOrInvalid}
          selectedItems={selectedItems}
        />
      ) : (
        <BaseOptionListItemThematic
          isLoading={isLoading}
          listItem={
            type === 'grdp' ? thematicItemList : filteredThematicItemList
          }
          onChange={(e) => {
            onChange &&
              onChange({
                key: [e?.key],
                label: [e?.label],
              });
          }}
          onValidOrInvalid={onValidOrInvalid}
          selectedItem={{
            key: selectedItems[0],
          }}
          type={type}
        />
      )}
    </div>
  );
};
