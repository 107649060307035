import { reduxForm } from 'redux-form';
import PopupForm from './PopupForm';
import validate from './validate';

const initialValues = {
  project_name: '',
};

export default reduxForm({
  form: 'project',
  initialValues,
  validate,
})(PopupForm);
