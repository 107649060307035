import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './AuthInput.scss';
import { ImageBox } from '@bvt-shared/component/ImageBox';

/**
 * @author Randa<m.randa@bvarta.com>
 * @copyright PT Bhumi Varta Technology
 * @param {Object} props react props
 * @param {string} props.title
 * @param {string} props.icon
 * @param {string} props.error
 * @param {(e)=>void} props.onChange
 * @param {(e)=>void} props.onClear
 * @param {string} props.value
 * @param {import('react').CSSProperties} props.style
 * @param {string} props.className
 * @param {boolean} props.showShowHide
 * @param {import('react').HTMLInputTypeAttribute} props.type
 * @param {string} props.placeholder
 * @param {(e)=>void} props.onFocus
 */
function AuthInput(props) {
  const {
    className,
    error,
    icon,
    onChange,
    style,
    title,
    value,
    onClear,
    showShowHide,
    type,
    placeholder,
    onFocus,
  } = props;

  const [show, setShow] = useState(true);

  useEffect(() => {}, []);

  let variantType = type === 'password' ? 'text' : type;

  return (
    <div
      className={`feature-auth AuthInput feature-auth AuthInput__input-wrapper ${className}`}
      style={style}
    >
      <div className='feature-auth AuthInput__input-title'>
        <ImageBox className='icon' src={icon} />
        <span className='title'>{title}</span>
      </div>
      <div
        className={`feature-auth AuthInput__input-container ${
          value && 'feature-auth AuthInput__input-container--filled'
        }`}
      >
        <input
          name={show && showShowHide ? type : variantType}
          onChange={(e) => onChange && onChange(e)}
          onFocus={(e) => onFocus && onFocus(e)}
          placeholder={placeholder}
          type={show && showShowHide ? type : variantType}
          value={value}
        />
        {value && onClear && (
          <span className='close-icon' onClick={() => onClear()}>
            <ImageBox src='ic-close' />
          </span>
        )}
        {showShowHide && (
          <div>
            <div className='show-hide' onClick={() => setShow(!show)}>
              {!show ? 'Hide' : 'Show'}
            </div>
          </div>
        )}
      </div>
      {error && <div className='error-message'>{error}</div>}
    </div>
  );
}

AuthInput.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  icon: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  showShowHide: PropTypes.bool,
  style: PropTypes.object,
  title: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
};

export default AuthInput;
