import React from 'react';

export const IconChecklist = () => {
  return (
    <svg
      fill='none'
      height='24'
      viewBox='0 0 13 12'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_5891_31553)'>
        <path
          d='M5.62587 9.31094C5.46947 9.46735 5.25735 9.55539 5.03615 9.55539C4.81496 9.55539 4.60284 9.46735 4.44644 9.31094L2.10242 6.96692C1.94464 6.80877 1.85577 6.5944 1.8559 6.37099C1.85603 6.14759 1.9448 5.93338 2.10277 5.77541C2.26067 5.6175 2.47539 5.52889 2.6987 5.52889C2.92201 5.52889 3.13603 5.6175 3.29394 5.77541L5.05514 7.53661L9.63925 2.9525C9.7956 2.79653 10.0078 2.70909 10.2286 2.70909C10.4495 2.70909 10.661 2.79653 10.8173 2.9525C10.9736 3.10879 11.0613 3.32086 11.0614 3.54188C11.0615 3.76289 10.9741 3.97478 10.818 4.13125L5.62587 9.31094Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_5891_31553'>
          <rect
            fill='currentColor'
            height='8'
            transform='translate(1.5 2)'
            width='10'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
