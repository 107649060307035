import { createSlice } from '@reduxjs/toolkit';
import { authAsyncAction } from './auth.asyncAction';

const initialState = {
  status_GET: 'IDLE',
  status_POST: 'IDLE',
  status_PUT: 'IDLE',
  status_DELETE: 'IDLE',
  message: '',
  data: {
    isAuthReady: false,
    isAuthenticated: false,
    user: {},
    permissions: [],
    lokasiintelligenceAccess: false,
  },
  tokenResetPassword: {
    status: 'IDLE',
    message: '',
  }
};

export const authSlice = createSlice({
  name: 'LI/AUTH',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status_DELETE = 'IDLE';
      state.status_GET = 'IDLE';
      state.status_POST = 'IDLE';
      state.status_PUT = 'IDLE';
      state.message = '';
    },
    unauthorized: (state) => ({
      ...state,
      ...initialState,
      data: {
        isAuthReady: true,
        isAuthenticated: false,
        user: {},
      },
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(authAsyncAction.login.pending, (state) => {
        state.status_POST = 'LOADING';
      })
      .addCase(authAsyncAction.login.rejected, (state, p) => {
        state.status_POST = 'FAILED';
        state.data.isAuthReady = true;
        state.data.isAuthenticated = false;
        state.message = p.error.message;
      })
      .addCase(authAsyncAction.login.fulfilled, (state, action) => {
        const { payload } = action;
        state.status_POST = 'SUCCESS';
        state.data = payload;
        state.message = !payload.lokasiintelligenceAccess ? 'You dont have permission. please contact administrator!' : '';
      })
      .addCase(authAsyncAction.checkToken.pending, (state) => {
        state.status_GET = 'LOADING';
      })
      .addCase(authAsyncAction.checkToken.rejected, (state) => {
        state.status_GET = 'FAILED';
        state.data.isAuthReady = true;
      })
      .addCase(authAsyncAction.checkToken.fulfilled, (state, action) => {
        const { payload } = action;
        state.status_GET = 'SUCCESS';
        state.data = payload;
      })
      .addCase(authAsyncAction.logout.pending, (state) => {
        state.status_POST = 'LOADING';
      })
      .addCase(authAsyncAction.logout.rejected, (state) => {
        state.status_POST = 'FAILED';
        state.data.isAuthReady = true;
        state.data.isAuthenticated = false;
      })
      .addCase(authAsyncAction.logout.fulfilled, (state) => {
        state.status_POST = 'SUCCESS';
        state.data.isAuthReady = true;
        state.data.isAuthenticated = false;
      })
      .addCase(authAsyncAction.forgetPassword.pending, (state) => {
        state.status_POST = 'LOADING';
      })
      .addCase(authAsyncAction.forgetPassword.rejected, (state) => {
        state.status_POST = 'FAILED';
      })
      .addCase(authAsyncAction.forgetPassword.fulfilled, (state) => {
        state.status_POST = 'SUCCESS';
      })
      .addCase(authAsyncAction.resetPasswordByToken.pending, (state) => {
        state.status_POST = 'LOADING';
      })
      .addCase(authAsyncAction.resetPasswordByToken.rejected, (state,{ error }) => {
        state.status_POST = 'FAILED';
        state.message = error?.message;
      })
      .addCase(authAsyncAction.resetPasswordByToken.fulfilled, (state) => {
        state.status_POST = 'SUCCESS';
        state.message = 'Succesfully reset password';
      })
      .addCase(authAsyncAction.getSetting.pending, (state) => {
        state.status_GET = 'LOADING';
      })
      .addCase(authAsyncAction.getSetting.rejected, (state,{ error }) => {
        state.status_GET = 'FAILED';
        state.message = error?.message;
      })
      .addCase(authAsyncAction.getSetting.fulfilled, (state,{ payload }) => {
        state.status_GET = 'SUCCESS';
        state.message = 'Succesfully reset password';
        state.data.setting = payload.result;
      })
      .addCase(authAsyncAction.updateSetting.pending, (state) => {
        state.status_POST = 'LOADING';
      })
      .addCase(authAsyncAction.updateSetting.rejected, (state,{ error }) => {
        state.status_POST = 'FAILED';
        state.message = error?.message;
      })
      .addCase(authAsyncAction.updateSetting.fulfilled, (state) => {
        state.status_POST = 'SUCCESS';
        state.message = 'Succesfully reset password';
      })
      .addCase(authAsyncAction.checkTokenResetPassword.pending, (state) => {
        state.tokenResetPassword.status = 'LOADING';
      })
      .addCase(authAsyncAction.checkTokenResetPassword.rejected, (state,{ error }) => {
        state.tokenResetPassword.status = 'FAILED';
        state.tokenResetPassword.message = error?.message;
      })
      .addCase(authAsyncAction.checkTokenResetPassword.fulfilled, (state) => {
        state.tokenResetPassword.status = 'SUCCESS';
      })
    ;
  },
});

export const { reducer: authReducer, actions: authAction } = authSlice;
