import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './TaskProcessItem.scss';
import DynamicIcon from '@bvt-icons';

/**
 * @author Randa<m.randa@bvarta.com>
 * @copyright PT Bhumi Varta Technology
 * @param {object} props
 * @param {string} props.projectName
 * @param {string} props.projectType
 * @param {object} props.elapsedTime
 * @param {'PROCCESING' | 'SUCCESS' | 'FAILED'} props.status
 * @param {()=>void} props.onClick
 */
export function TaskProcessItem(props) {
  const [mapper, setMapper] = useState({
    color: 'red',
    status: 'No Data',
    buttonText: 'No Data',
    icon: 'failed',
  });
  const { elapsedTime, onClick, projectName, projectType, status } = props;

  useEffect(() => {
    if (status === 'SUCCESS') {
      setMapper({
        color: '#12b2a7',
        buttonText: 'Show',
        status: 'Success',
        icon: 'ProcessCheck',
      });
    } else if (status === 'FAILED') {
      setMapper({
        color: '#f44e4a',
        buttonText: 'Delete',
        status: 'Failed',
        icon: 'ProcessFailed',
      });
    } else if (status === 'PROCESING') {
      setMapper({
        color: '#00a8ff',
        buttonText: 'Cancel',
        status: 'Processing',
        icon: 'ProcessProcessing',
      });
    } else if (status === 'WARNING') {
      setMapper({
        color: '#FFD500',
        buttonText: 'Show',
        buttonColor: '#657991',
        status: 'Warning',
        icon: 'ProcessWarning',
      });
    } else if (status === 'WAITING') {
      setMapper({
        color: '#E682FE',
        buttonText: 'Cancel',
        buttonColor: '#657991',
        status: 'Waiting',
        icon: 'ProcessWaiting',
      });
    } else if (status === 'CANCELED') {
      setMapper({
        color: '#CACED5',
        buttonText: 'Delete',
        buttonColor: '#F44E4A',
        status: 'Canceled',
        icon: 'ProcessCanceled',
      });
    }
  }, [status]);

  return (
    <div className='task-analyze TaskProcessItem'>
      <div>
        <DynamicIcon
          className={`TaskProcessItem__process-item-dynamic-icon ${
            mapper.icon === 'ProcessProcessing' && 'should-spin'
          }`}
          height={'32px'}
          icon={mapper.icon}
          width={'32px'}
        />
      </div>
      <div className='TaskProcessItem__process-item'>
        <div className='TaskProcessItem__project-type'>
          {projectType || '-'}
        </div>
        <div className='TaskProcessItem__project-name'>
          {projectName || '-'}
        </div>
        <div style={{ display: 'flex' }}>
          <div
            className='TaskProcessItem__procces-label'
            style={{ background: mapper.color }}
          >
            {mapper.status}
          </div>
        </div>
      </div>
      <div className='TaskProcessItem__right-wrapper'>
        <div className='TaskProcessItem__elapsed-time'>
          {elapsedTime || '-'}
        </div>
        <button
          className='TaskProcessItem__button'
          onClick={() => onClick && onClick()}
          style={{
            color: mapper.buttonColor ? mapper.buttonColor : mapper.color,
          }}
        >
          {mapper.buttonText}
        </button>
      </div>
    </div>
  );
}
TaskProcessItem.propTypes = {
  elapsedTime: PropTypes.any,
  onClick: PropTypes.any,
  projectName: PropTypes.any,
  projectType: PropTypes.any,
  status: PropTypes.any,
};
