import React from 'react';
import _ from 'lodash';
import { Chart } from '@bvt-atoms';
import Highcharts from 'highcharts/highstock';
import HighchartsMore from 'highcharts/highcharts-more';
HighchartsMore(Highcharts);

import { SharedBaseCardTypeOneComponent } from '@bvt-shared/component/SharedBaseCardTypeOne.component';

import './AsmanCardProfessionChart.component.scss';

export const AsmanCardProfessionChartComponent = (props) => {
  const { data, isLoading } = props;
  const sortedData = _.sortBy(data, ['total']).reverse();
  const arrChart = sortedData.slice(0, 6);
  const arrLabel = arrChart.map((item) => item.title);
  const arrValue = arrChart.map((item) => item.total);
  const totalValue = arrValue.reduce((a, b) => a + b, 0);
  const series = arrChart.map(({ title }) => ({
    title,
    data: arrValue.map((num) => (num / totalValue) * 100),
    pointPlacement: 'on',
    opacity: 0.1,
  }));


  /** @type {import('highcharts/highstock').Options} */
  const option = {
    accessibility: {
      enabled: false,
    },
    chart: {
      polar: true,
      type: 'area',
      backgroundColor: 'transparent',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    pane: {
      size: '90%',
    },
    xAxis: {
      categories: arrLabel,
      tickmarkPlacement: 'off',
      lineWidth: 0,
    },
    yAxis: {
      gridLineInterpolation: 'polygon',
      lineWidth: 0,
      min: 0,
      tickInterval: 10,
    },
    tooltip: {
      shared: true,
      formatter: function () {
        return `<span><b>${((totalValue / 100) * this.y).toFixed(
          0
        )} (${this.y.toFixed(0)}%)</b><br/>`;
      },
      borderColor: '#12B2A7',
      backgroundColor: '#12B2A7',
      style: {
        color: '#fff',
      },
    },
    legend: false,
    exporting: {
      enabled: false,
    },
    colors: ['#12B2A7'],
    series: series,
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 400,
          },
          chartOptions: {
            pane: {
              size: '60%',
            },
          },
        },
      ],
    },
  };

  return (
    <SharedBaseCardTypeOneComponent
      isLoading={isLoading}
      showIcon
      title={'Profession'}
    >
      <div className='AsmanCardProfessionChartComponent__wrapper'>
        <div className='AsmanCardProfessionChartComponent__wrapper--chart'>
          <div className='AsmanCardProfessionChartComponent__wrapper--chart-title'>
            Top 6 Profession In area
          </div>
          <Chart options={option} />
        </div>
        <div className='AsmanCardProfessionChartComponent__wrapper--others'>
          <div className='AsmanCardProfessionChartComponent__wrapper--others-header'>
            <div>Profession other</div>
            <div>Total</div>
          </div>
          {sortedData.slice(0, 6).map((item) => (
            <div
              className='AsmanCardProfessionChartComponent__wrapper--others-item'
              key={`${item.title}`}
            >
              <div className='AsmanCardProfessionChartComponent__wrapper--others-title'>
                <div>{item.icon}</div>
                <div>{item.title}</div>
              </div>
              <div>{item.total.toLocaleString('id')}</div>
            </div>
          ))}
        </div>
      </div>
    </SharedBaseCardTypeOneComponent>
  );
};
