import Resource from '../../../api/resource';

export class ActivityService extends Resource {
  constructor() {
    super('module-activities', '/api', 'api-activities', 'v2');
  }

  by_module(query) {
    this.setAdditionUri('/module-name');
    const paramString = JSON.stringify(query);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }
  by_time(query) {
    this.setAdditionUri('/activity-time');
    const paramString = JSON.stringify(query);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }
}
