import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import ParameterInput from '../ParameterInput';
import * as yup from 'yup';
import './RenameForm.scss';

export default function RenameForm(props) {
  const formik = useFormik({
    initialValues: {
      name: props.defaultValue,
    },
    validationSchema: yup.object({
      name: yup.string().required('name is required'),
    }),
    onSubmit: (e) => {
      props.onSubmit && props.onSubmit(e);
    },
  });

  return (
    <form
      className='feature-genset-parameter RenameForm'
      onSubmit={(e) => {
        e.preventDefault();
        formik.submitForm();
      }}
    >
      <ParameterInput
        error={formik.errors.name}
        onChange={(e) => formik.setFieldValue('name', e.target.value)}
        onClear={() => formik.setFieldValue('name', '')}
        placeholder={props.placeholder}
        value={formik.values.name}
      />
      <div className='feature-genset-parameter--btn_group'>
        <button
          className='feature-genset-parameter--btn_outlined'
          onClick={props?.onCancel}
          type='button'
        >
          Cancel
        </button>
        <button
          className={`feature-genset-parameter--btn_filled${
            !formik.values.name ? '_disabled' : ''
          }`}
          type='submit'
        >
          {props.sumbitText}
        </button>
      </div>
    </form>
  );
}

RenameForm.defaultProps = {
  defaultValue: '',
  onCancel: undefined,
  onSubmit: undefined,
  placeholder: 'name parameter',
  sumbitText: 'Rename',
};

RenameForm.propTypes = {
  defaultValue: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  sumbitText: PropTypes.string,
};
