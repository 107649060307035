import React, { Fragment, useEffect } from 'react';

import * as Leaflet from 'leaflet';
import { GeoJSON, useMap, Popup } from 'react-leaflet';
import { isEmpty } from 'lodash';
/**
 * @copyright PT Bhumi Varta Technology
 * @author Malik<malik.ibrahim@bvarta.com>
 */

/**
 * @typedef TGeoJson
 * @type {Object}
 * @property {object} geometry
 * @property {string} geometry.type
 * @property {Array<any>} geometry.coordinates
 * @property {object} properties
 * @property {string} properties.id
 * @property {string} properties.displayName
 * @property {string} properties.kode
 * @property {string} type
 */

/**
 * @param {Object} props
 * @param {TGeoJson} props.polygon_provinsi
 * @param {TGeoJson} props.polygon_kota
 * @param {TGeoJson} props.polygon_kecamatan
 * @param {TGeoJson} props.polygon_desa
 */

export const GeoJsonAdvanceMap = (props) => {

  const { polygon_provinsi, polygon_kota, polygon_kecamatan, polygon_desa } = props;

  const initialMap = useMap();

  const getBounds = (value) => {
    return Leaflet.geoJSON(value).getBounds();
  };

  const Style = {
    color: '#748DA6',
    stroke: 1,
    fillColor: '#748DA6',
    fillOpacity: 0.3,
    opacity: 0.3,
  };

  useEffect(() => {
    if (polygon_provinsi) {
      if (!isEmpty(polygon_provinsi) !== false) {
        initialMap.flyToBounds(getBounds(polygon_provinsi));
      }
    }
    if (polygon_kota) {
      if (!isEmpty(polygon_kota) !== false) {
        initialMap.flyToBounds(getBounds(polygon_kota));
      }
    }
    if (polygon_kecamatan) {
      if (!isEmpty(polygon_kecamatan) !== false) {
        initialMap.flyToBounds(getBounds(polygon_kecamatan), { maxZoom: 40 });
      }
    }
    if (polygon_desa) {
      if (!isEmpty(polygon_desa) !== false) {
        initialMap.flyToBounds(getBounds(polygon_desa), { maxZoom: 40 });
      }
    }
  }, [polygon_provinsi, polygon_kota, polygon_kecamatan, polygon_desa]);

  return (
    <Fragment>
      {!isEmpty(polygon_provinsi) && (
        <GeoJSON
          data={
            !isEmpty(polygon_provinsi) !==
            null
              ? polygon_provinsi
              : null
          }
          ref={(ref) => {
            if (ref && ref.setZIndex) {
              ref.setZIndex(1);
            }
          }}
          style={() => Style}
        >
          <Popup closeButton={false}>
            <center>
              {
                polygon_provinsi
                  ?.properties?.displayName
              }
            </center>
          </Popup>
        </GeoJSON>
      )}
      {!isEmpty(polygon_kota) && (
        <GeoJSON
          data={
            !isEmpty(polygon_kota) !== null
              ? polygon_kota
              : null
          }
          ref={(ref) => {
            if (ref && ref.setZIndex) {
              ref.setZIndex(1);
            }
          }}
          style={() => Style}
        >
          <Popup closeButton={false}>
            <center>
              {
                polygon_kota?.properties
                  ?.displayName
              }
            </center>
          </Popup>
        </GeoJSON>
      )}
      {!isEmpty(polygon_kecamatan) && (
        <GeoJSON
          data={
            !isEmpty(polygon_kecamatan) !==
            null
              ? polygon_kecamatan
              : null
          }
          ref={(ref) => {
            if (ref && ref.setZIndex) {
              ref.setZIndex(1);
            }
          }}
          style={() => Style}
        >
          <Popup closeButton={false}>
            <center>
              {
                polygon_kecamatan
                  ?.properties?.displayName
              }
            </center>
          </Popup>
        </GeoJSON>
      )}
      {!isEmpty(polygon_desa) && (
        <GeoJSON
          data={
            !isEmpty(polygon_desa) !== null
              ? polygon_desa
              : null
          }
          ref={(ref) => {
            if (ref && ref.setZIndex) {
              ref.setZIndex(1);
            }
          }}
          style={() => Style}
        >
          <Popup closeButton={false}>
            <center>
              {
                polygon_desa?.properties
                  ?.displayName
              }
            </center>
          </Popup>
        </GeoJSON>
      )}
    </Fragment>
  );
};
