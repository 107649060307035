import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, change } from 'redux-form';
import { Button } from '@bvt-atoms';
import { InputField } from '@bvt-molecules';
import { ImageBox } from '@bvt-shared/component/ImageBox';

/**
 * @version 1.1
 * @copyright PT Bhumi Varta Technology
 * @memberof molecules
 * @author Renta <renta.yustika@bvarta.com>
 * @param {Object} props
 * @param {Boolean} props.closeButton close button is shown optionally
 * @param {String} props.errorMessage set error message
 * @param {Boolean} props.footer footer is shown optionally
 * @param {Object} props.handler Object handler
 * @param {Function} props.handler.onCloseError function for closing error message
 * @param {Function} props.handler.onHide function for hiding the modal
 * @param {Function} props.handler.onSubmit function for submit
 * @param {Function} props.formRender formRender is shown form function
 * @param {Boolean} props.header header is shown optionally
 * @param {String} props.headerText label for header text
 * @param {String} props.inputText value of input value
 * @param {String} props.placeholder set text for placeholder in input text
 * @param {Boolean} props.show boolean for either show or hide modal
 * @param {Boolean} props.statusError boolean for show error status
 * @param {String} props.submitText set text for submit button
 */

const formName = 'project';

export default function PopupForm(props) {
  const { handleSubmit } = props;
  const { project } = useSelector((s) => s.form);
  const dispatch = useDispatch();

  const project_name = project?.values?.project_name;
  const disabledButton = props.submitDisabled || !project_name;

  if (!props.show) {
    return null;
  }

  return (
    <div className='popup-form'>
      <div className='popup-form__content'>
        {props.header && (
          <div className='popup-form__header'>
            {props.headerText}
            {props.closeButton && (
              <Button className='closeButton' onClick={props.handler?.onHide}>
                <div className='labelIcon'>
                  <ImageBox className='labelIcon__icon' src='ic-close' />
                </div>
              </Button>
            )}
          </div>
        )}

        {props.formRender({ props, project_name, dispatch, handleSubmit })}

        {props.footer && (
          <div className='popup-form__footer'>
            {props.closeButton && (
              <Button
                className='popup-form__footer__button--left'
                onClick={props.handler?.onHide}
              >
                Cancel
              </Button>
            )}

            <Button
              className={`button__contained button__medium popup-form__footer__button--right ${
                disabledButton ? 'button__disabled' : ''
              }`}
              onClick={props.handler?.onSubmit}
              type={disabledButton ? 'button' : 'submit'}
            >
              <h5 className={disabledButton ? 'txt-grey' : 'txt-white'}>
                {props.submitText}
              </h5>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

PopupForm.defaultProps = {
  closeButton: false,
  errorMessage: 'Error Message',
  footer: true,
  formRender: ({ props, project_name, dispatch, handleSubmit }) => (
    <form className='popup-form__form txt-bold' onSubmit={handleSubmit}>
      {props.statusError ? (
        <Field
          component={InputField}
          endadornment={
            project_name?.length > 0 && (
              <Button
                className='removeButton'
                onClick={() => {
                  dispatch(change(formName, 'project_name', ''));
                }}
              >
                <div className='labelIcon'>
                  <ImageBox className='labelIcon__icon' src='ic-close' />
                </div>
              </Button>
            )
          }
          name='project_name'
          placeholder={props.placeholder}
          variant={project_name?.length ? 'outlined' : 'contained'}
        />
      ) : (
        <div className='popup-form__form__error'>
          {props.errorMessage}{' '}
          {
            <Button
              className='errorCloseButton'
              onClick={props.handler?.onCloseError}
            >
              <div className='labelIcon'>
                <ImageBox className='labelIcon__icon' src='ic-close' />
              </div>
            </Button>
          }
        </div>
      )}
    </form>
  ),
  handler: {},
  handleSubmit: undefined,
  header: true,
  headerText: 'Form header',
  inputText: '',
  placeholder: 'Input project name',
  show: false,
  statusError: true,
  submitDisabled: false,
  submitText: 'Submit',
};

PopupForm.propTypes = {
  closeButton: PropTypes.bool,
  errorMessage: PropTypes.string,
  footer: PropTypes.bool,
  formRender: PropTypes.func,
  handler: PropTypes.shape({
    onCloseError: PropTypes.func,
    onHide: PropTypes.func,
    onSubmit: PropTypes.func,
  }),
  handleSubmit: PropTypes.func,
  header: PropTypes.bool,
  headerText: PropTypes.string,
  inputText: PropTypes.string,
  placeholder: PropTypes.string,
  show: PropTypes.bool,
  statusError: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  submitText: PropTypes.string,
};
