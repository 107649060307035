import React from 'react';
import './ColorSymbol.scss';

/**
 * @company PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

/**
 * @param {Object} props
 * @param {string} props.color
 */

export const ColorSymbol = (props) => {
  const { color } = props;

  return (
    <div
      className='legend-symbol__color'
      style={{
        '--mapp-legend-color-symbol': color || '#ffffff',
      }}
    />
  );
};
