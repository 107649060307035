import * as React from 'react';
const IconMapMarker = (props) => (
  <svg
    fill='none'
    height={12}
    viewBox='0 0 8 12'
    width={8}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M4.00168 0.666748C2.94146 0.666748 1.92467 1.0903 1.17498 1.84421C0.425289 2.59813 0.00411882 3.62067 0.00411882 4.68687C-0.0314652 5.41652 0.164245 6.1387 0.563041 6.7493L4.00168 11.3334L7.44032 6.75058C7.81974 6.13136 8.01378 5.41534 7.99924 4.68815C7.99941 4.16011 7.89613 3.63721 7.69531 3.14932C7.49449 2.66143 7.20006 2.2181 6.82883 1.84467C6.45761 1.47123 6.01686 1.17499 5.53177 0.972884C5.04668 0.770774 4.52675 0.666748 4.00168 0.666748V0.666748ZM4.00168 2.99553C4.33447 2.99553 4.65979 3.09478 4.93648 3.28072C5.21318 3.46667 5.42883 3.73096 5.55615 4.04016C5.68347 4.34937 5.71676 4.6896 5.65179 5.01783C5.58682 5.34606 5.42651 5.64754 5.19115 5.88414C4.95579 6.12074 4.65594 6.28184 4.32953 6.34705C4.00311 6.41226 3.6648 6.37866 3.35738 6.25051C3.04996 6.12235 2.78724 5.90538 2.60244 5.62705C2.41764 5.34873 2.31907 5.02154 2.3192 4.68687C2.31937 4.23824 2.4967 3.80804 2.81221 3.49087C3.12772 3.1737 3.55557 2.99553 4.00168 2.99553Z'
      fill='#F8F8FB'
    />
  </svg>
);
export { IconMapMarker };
