import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@bvt-atoms';
import DynamicIcon from '@bvt-icons';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 */

/**
 * @param {object} props
 * @param {object} props.item
 * @param {number} props.item.id
 * @param {string} props.item.code
 * @param {string} props.item.icon
 * @param {string} props.item.name
 * @param {boolean} props.item.status
 * @param {func} props.onClickonOpenMenu
 * @param {string} props.open
 */

export default function ModuleBarMenu(props) {
  /**
   * ${
          props?.item?.code === open ? ' opened ' : ''
        }
   */
  return (
    <Button
      className={`module-bar__menu__button ${
        props?.item?.code === props.open ? ' opened ' : ''
      } ${props.item.status ? '' : ' inactive '}`}
      onClick={() =>
        props.onClickonOpenMenu && props.onClickonOpenMenu(props?.item)
      }
    >
      <DynamicIcon
        className='module-bar__menu__icon'
        height='1.25rem'
        icon={props?.item?.icon}
        width='1.25rem'
      />
      <div className='module-bar__menu__text'>{props?.item?.name}</div>
    </Button>
  );
}

ModuleBarMenu.propTypes = {
  item: PropTypes.shape({
    code: PropTypes.string,
    icon: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.bool,
  }),
  onClickonOpenMenu: PropTypes.func,
  open: PropTypes.string,
};
