import React, { useEffect } from 'react';
import { useAuth } from '@bvt-features/auth/hooks';
import LoginForm from '@bvt-features/auth/component/LoginForm';
import { useNavigate, useLocation } from 'react-router';
import LottiePlayer from 'lottie-react';
import { HomePageAnimation } from '@bvt-animations';
import './LoginContainer.scss';

export default function LoginContainer() {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  useEffect(() => () => auth.resetStatus(), []);

  return (
    <div className='feature-auth LoginContainer__wrap'>
      <h1 className='feature-auth LoginContainer__title'>Login</h1>
      <LottiePlayer animationData={HomePageAnimation} />
      <LoginForm
        isLoading={auth.state.status_POST === 'LOADING'}
        message={
          (auth.state.status_POST === 'FAILED' ||
            auth.state.status_POST === 'SUCCESS') &&
          auth.state.message
        }
        onCompanyClick={() =>
          window.open('https://www.bvarta.com/', '_blank', 'noreferrer')
        }
        onDismissMessage={() => auth.resetStatus()}
        onForgotClick={() =>
          navigate('/auth/forgot-password', {
            state: { from: location.pathname },
          })
        }
        onSubmit={(e) => auth.login(e)}
      />
    </div>
  );
}
