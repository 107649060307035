import { useDispatch, useSelector } from 'react-redux';
import { STEP_ACTION } from '../../redux';

export function useStep() {
  const dispatch = useDispatch();

  const state = useSelector((statement) => statement.features.MAPP.STEP);

  const setStepCode = (code = '') => {
    dispatch(STEP_ACTION.setStepCode(code));
  };

  const getStepByCode = (code = '') => {
    dispatch(STEP_ACTION.getByCode(code));
  };

  const setCurrentStep = (step = 0) => {
    dispatch(STEP_ACTION.setStep({ step: step }));
  };

  const resetAll = () => {
    dispatch(STEP_ACTION.resetAll());
  };

  const resetStatus = () => {
    dispatch(STEP_ACTION.resetStatus());
  };

  return {
    state,
    setStepCode,
    getStepByCode,
    setCurrentStep,
    resetAll,
    resetStatus,
  };
}
