import { useAuth } from '@bvt-features/auth/hooks';
import { useEffect, useRef } from 'react';

/**
 * @author multiple
 * @copyright PT BHUMI Varta Technology
 * @param {string} soundUrl
 * @returns
 */
export default function usePlaySound(soundUrl) {
  const AUTH = useAuth();
  /**
   * @type {import('react').MutableRefObject<HTMLAudioElement>}
   */
  const audioRef = useRef(new Audio());

  useEffect(() => {
    let srcSound = '/assets/sound/mixkit-software-interface-start-2574.wav';
    if (soundUrl) {
      srcSound = soundUrl;
    } else if (!soundUrl && AUTH.state.data?.setting?.sound_path) {
      srcSound = AUTH.state.data?.setting?.sound_path;
    }
    audioRef.current.src = srcSound;
  }, [AUTH.state.data?.setting, soundUrl]);

  const playSound = () => {
    audioRef.current.play();
  };

  const pauseSound = () => {
    audioRef.current.pause();
  };

  const stopSound = () => {
    audioRef.pause();
    audioRef.currentTime = 0;
  };

  return {
    playSound,
    pauseSound,
    stopSound,
  };
}
