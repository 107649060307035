import { MAPP_PROJECT_CONSTANT } from './MAPP_PROJECT_CONSTANT';

const initialState = {
  status_GET: 'IDLE',
  status_POST: 'IDLE',
  status_PUT: 'IDLE',
  status_PATCH: 'IDLE',
  status_DETAIL: 'IDLE',
  status_DELETE: 'IDLE',
  status_DOWNLOAD: 'IDLE',
  status_UPLOAD: 'IDLE',
  message: '',
  list: [],
  detail: {},
  properties: {},
  filteredDetailByMarker: [],
};

export function MAPP_PROJECT_REDUCER(state = initialState, action) {
  let returnData = state;
  Object.values(MAPP_PROJECT_CONSTANT).map((ctx) => {
    if (action.type === MAPP_PROJECT_CONSTANT.RESET_STATUS) {
      returnData = {
        ...returnData,
        status_GET: 'IDLE',
        status_POST: 'IDLE',
        status_PUT: 'IDLE',
        status_PATCH: 'IDLE',
        status_DETAIL: 'IDLE',
        status_DELETE: 'IDLE',
        status_DOWNLOAD: 'IDLE',
        status_UPLOAD: 'IDLE',
        message: '',
      };
    } else if (action.type === MAPP_PROJECT_CONSTANT.RESET_ALL) {
      returnData = {
        ...initialState,
      };
    } else if (ctx === action.type && !ctx.includes('[NO_STATE_UPDATE]')) {
      returnData = { ...returnData, ...action.payload };
    }
    return null;
  });

  return returnData;
}
