import _ from 'lodash';
import React from 'react';
import './AccountTab.scss';

/**
 * @company PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

/**
 * @description For data object
 * @typedef IAccountTabItemObject
 * @type {Object}
 * @property {string} props.key
 * @property {any} props.icon
 * @property {string} props.label
 * @property {boolean} props.disabled
 */

/**
 * @param {Object} props
 * @param {boolean} props.activeKey
 * @param {Array<IAccountTabItemObject>} props.data
 * @param {(key:string)=>void} props.onChangeTab
 */

export const AccountTab = (props) => {
  const { activeKey, data, onChangeTab } = props;
  return (
    <div className='AccountTab'>
      {_.map(data || [], (v, idx) => {
        return (
          <div
            className={`AccountTab--item ${
              activeKey === v?.key ? 'active' : ''
            }`}
            disabled={v?.disabled}
            key={idx}
            onClick={() => onChangeTab && onChangeTab(v?.key)}
          >
            <span className='AccountTab--icon'>{v?.icon || <></>}</span>
            <span className='AccountTab--label'>{v?.label}</span>
          </div>
        );
      })}
    </div>
  );
};
