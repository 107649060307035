/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Dropdown, Menu, Pagination, Row, Table } from 'antd';
import './RecentActivityTable.scss';
import DynamicIcon, { IconChecklist } from '@bvt-icons';
import { Skeleton } from '@bvt-shared/component/Skeleton';
import moment from 'moment';
import { useAuth } from '@bvt-features/auth/hooks';
import { ProgressBar } from '@bvt-atoms';
const skeletonArr = [];
for (let index = 0; index < 3; index++) {
  skeletonArr[index] = index;
}

/**
 * @author Randa <m.randa@bvarta.com>
 * @copyright PT Bhumi Varta Technology
 * @param {object} props property
 * @param {Array<{project_name:any,project_size:any,create_date:any,last_update:,time:any,project_type:any}>} props.data
 * @param {boolean} props.isLoading
 * @param {(e:any)=>void} props.onChange
 * @param {(e:any)=>void} props.onCreateReportClick
 * @param {(e:any)=>void} props.onDuplicateClick
 * @param {(e:any)=>void} props.onDuplicateClick
 * @param {(e:any)=>void} props.onRemoveClick
 * @param {(e:any)=>void} props.onResumeClick
 * @param {(e:any)=>void} props.onEditClick
 * @param {(e:any)=>void} props.onShowAll
 * @param {{by:any,type:any}} props.sort
 * @param {{name:string}} props.filter
 * @param {boolean} props.isHideCreateProject
 * @param {boolean} props.isHideSort
 */
function RecentActivityTable(props) {
  const {
    data,
    isLoading,
    total,
    perPage,
    currentPage,
    sort,
    onChange,
    onShowAll,
    onCreateReportClick,
    onCreateProject,
    onRemoveClick,
    onResumeClick,
    onEditClick,
    isHideCreateProject,
    isHideSort,
    filter,
  } = props;
  const [internalFilter, setInternalFilter] = useState('lastupdate_DESC');
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const AUTH = useAuth();
  /** @type {import('antd').TableColumnsType} */
  const TABLE_COLUMN = [
    {
      title: 'Edit',
      key: 'edit',
      render: (text, record) => (
        <button className='btn btn-edit' onClick={() => onEditClick(record)}>
          <DynamicIcon height='1.25rem' icon='edit' width='2rem' />
        </button>
      ),
    },
    {
      title: 'Project Name',
      dataIndex: 'project_name',
      key: 'project_name',
      render: (text) => text || '-',
    },
    {
      title: 'Create Date',
      dataIndex: 'create_date',
      key: 'create_date',
      align: 'center',
      render: (text) => (text ? moment(text).format('DD MMM YYYY') : '-'),
    },
    {
      title: 'Last Update',
      dataIndex: 'last_update',
      key: 'last_update',
      align: 'center',
      render: (text) => (text ? moment(text).format('DD MMM YYYY') : '-'),
    },
    {
      title: 'Time',
      align: 'center',
      dataIndex: 'last_update',
      key: 'time',
      render: (text) => (text ? moment(text).format('HH:MM:SS') : '-'),
    },
    {
      title: 'Type',
      dataIndex: 'project_type',
      key: 'project_type',
      render: (text, record) => {
        const typeCheck = text.includes('Site');
        const step = typeCheck ? 7 : 6;
        const data = [
          {
            color: typeCheck ? '#4880FF' : '#F425A3',
            percent:
              record.task === null || record?.task?.status !== 'SUCCESS'
                ? ((record.step_order / step) * 100).toFixed(0)
                : 100,
            type: text.split('-')[1],
          },
        ];
        return <ProgressBar data={data} fontWeigth={400} />;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <div className='btn-wrap'>
          <button
            className={`btn ${
              record?.task?.status === 'SUCCESS'
                ? 'open-button'
                : 'resume-button'
            }`}
            onClick={() => {
              onResumeClick(record);
            }}
          >
            <DynamicIcon
              height='1rem'
              icon={record?.task?.status === 'SUCCESS' ? 'eye' : 'resume'}
              width='1rem'
            />
            {record?.task?.status === 'SUCCESS' ? 'Open' : 'Resume'}
          </button>
          <button
            className='btn create-report-button'
            disabled={
              record?.task?.status !== 'SUCCESS' ||
              (record?.task?.analyze_type?.includes('site_profiling') &&
                !AUTH.hasAccess('MAPANA.SIPRO.GENERATE_REPORT')) ||
              (record?.task?.analyze_type?.includes('grid_analitic') &&
                !AUTH.hasAccess('MAPANA.GRIANA.GENERATE_REPORT'))
            }
            onClick={() => {
              if (record?.task?.UUID) {
                onCreateReportClick(record);
              }
            }}
          >
            <DynamicIcon height='1rem' icon='createreport' width='1rem' />
            Create Report
          </button>
          <button
            className='btn remove-button'
            onClick={() => {
              onRemoveClick(record);
            }}
          >
            <DynamicIcon height='1rem' icon='remove' width='1rem' />
            Remove
          </button>
        </div>
      ),
    },
  ];

  const filterMenu = (
    <Menu
      activeKey={internalFilter}
      className='mapp-RecentActivity filter-dropdown-menu'
      items={[
        {
          key: 'lastupdate_DESC',
          label: (
            <div>
              Last Updated <IconChecklist />
            </div>
          ),
        },
        {
          key: 'lastupdate_ASC',
          label: (
            <div>
              First Updated <IconChecklist />
            </div>
          ),
        },
        {
          key: 'name_ASC',
          label: (
            <div>
              A To Z <IconChecklist />
            </div>
          ),
        },
        {
          key: 'name_DESC',
          label: (
            <div>
              Z To A <IconChecklist />
            </div>
          ),
        },
        {
          key: 'datecreate_ASC',
          label: (
            <div>
              Create Date <IconChecklist />
            </div>
          ),
        },
        {
          key: 'apply',
          label: <button className='btn-apply'>Apply</button>,
        },
      ]}
      onClick={(e) => {
        if (e.key === 'apply') {
          setIsFilterVisible(false);
          internalHandleChange({
            sort: {
              by: internalFilter.split('_')[0],
              type: internalFilter.split('_')[1],
            },
          });
        } else {
          setInternalFilter(e.key);
        }
      }}
    />
  );

  const internalHandleChange = (param) => {
    onChange &&
      onChange({
        perPage: param?.perPage || perPage,
        currentPage: param?.currentPage || currentPage,
        sort: {
          by: param?.sort?.by || sort?.by,
          type: param?.sort?.type || sort?.type,
        },
        filter: {
          name: param?.filter?.name || filter?.name,
        },
      });
  };

  return (
    <div className='RecentActivityTable RecentActivityTable__wrapper'>
      <div
        style={{
          position: 'absolute',
          right: '1rem',
          top: isHideCreateProject && isHideSort ? '1.75rem' : '3rem',
          zIndex: 1,
        }}
      >
        {!isLoading && (
          <Pagination
            current={currentPage}
            onChange={(v, p) =>
              internalHandleChange({ perPage: p, currentPage: v })
            }
            pageSize={perPage}
            simple
            total={total}
          />
        )}
      </div>
      <Row gutter={[0, 8]}>
        <Col xs={24}>
          <div className='RecentActivityTable btn-wrap'>
            {!isHideCreateProject && (
              <Dropdown
                onOpenChange={(e) => setIsFilterVisible(e)}
                open={isFilterVisible}
                overlay={filterMenu}
                trigger={['click']}
              >
                <button className='RecentActivityTable btn some-white'>
                  Sort by
                  <DynamicIcon
                    height='0.588rem'
                    icon='arrow'
                    width='0.588rem'
                  />
                </button>
              </Dropdown>
            )}
            {!isHideSort && (
              <button
                className='RecentActivityTable btn some-green'
                onClick={() => onCreateProject()}
              >
                <DynamicIcon height='1rem' icon='rectangleplus' width='1rem' />
                Create new project
              </button>
            )}
          </div>
        </Col>
        <Col xs={24}>
          {isLoading ? (
            <React.Fragment>
              {skeletonArr.map((v) => (
                <Skeleton height='5rem' key={v} loading width='100%' />
              ))}
            </React.Fragment>
          ) : (
            <Table
              className='RecentActivityTable__table'
              columns={TABLE_COLUMN}
              dataSource={data}
              pagination={false}
            />
          )}
        </Col>
        <Col style={{ paddingBottom: perPage <= 3 ? '0rem' : '2rem' }} xs={24}>
          <div className='RecentActivityTable btn-wrap'>
            <button
              className='RecentActivityTable btn some-white'
              onClick={() =>
                internalHandleChange({ perPage: perPage <= 3 ? 10 : 3 })
              }
              style={{ marginLeft: 'auto', marginRight: '16px' }}
            >
              Show {perPage <= 3 ? 'More' : 'Less'}
              <div
                style={{
                  transform: `rotate(${perPage <= 3 ? '0deg' : '180deg'})`,
                }}
              >
                <DynamicIcon height='0.588rem' icon='arrow' width='0.588rem' />
              </div>
            </button>
            <button
              className='RecentActivityTable btn some-green'
              onClick={() => onShowAll()}
            >
              Show All
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

RecentActivityTable.propTypes = {
  currentPage: PropTypes.number,
  data: PropTypes.array,
  filter: PropTypes.object,
  isHideCreateProject: PropTypes.bool,
  isHideSort: PropTypes.bool,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  onCreateProject: PropTypes.func,
  onCreateReportClick: PropTypes.func,
  onDuplicateClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  onResumeClick: PropTypes.func,
  onShowAll: PropTypes.func,
  perPage: PropTypes.number,
  sort: PropTypes.object,
  total: PropTypes.number,
};

export default RecentActivityTable;
