import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import wv from 'wavesurfer.js';
import { Svg } from '@bvt-atoms';
import soundDefault from '../../../../assets/sound/mixkit-software-interface-start-2574.wav';
/**
 * @version 1
 * @copyright Bhumi Varta Technology
 * @memberof molecules
 * @author Randa <m.randa@bvarta.com>
 * @param {Object} props
 * @param {string} props.className
 * @param {import('react').CSSProperties} props.style
 * @param {string} props.text
 * @param {string} props.soundSrc
 * @param {isActive} props.isActive
 * @param {object} props.handler
 * @param {Function} props.handler.onActiveClick
 */

export default function SoundPlayerCard(props) {
  const [wvState, setWvState] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const waveFormRef = useRef(null);

  useEffect(() => {
    let waveConf = wv.create({
      container: waveFormRef.current,
      barWidth: 4,
      barHeight: 1,
      barGap: 4,
      barRadius: 2,
      backend: 'WebAudio',
      waveColor: '#DCDCDC',
      progressColor: '#fff',
      responsive: true,
      interact: false,
      height: 52,
      cursorColor: 'transparent',
      autoCenter: true,
      hideScrollbar: true,
    });
    waveConf.load(props.soundSrc);
    waveConf.on('ready', () => {
      setWvState(waveConf);
    });
    waveConf.on('play', () => {
      setIsPlaying(true);
    });
    waveConf.on('finish', () => {
      waveConf.seekTo(0);
      setIsPlaying(false);
    });

    return () => {
      setWvState({});
      waveConf.destroy();
    };
  }, []);
  return (
    <div
      className={`SoundPlayerCard SoundPlayerCard__container SoundPlayerCard__container${
        props.isActive && '--active'
      } ${props.className}`}
      style={{ ...props.style }}
    >
      <div
        className='SoundPlayerCard__container-soundwave-overlay'
        style={{
          height: waveFormRef.current?.offsetHeight,
          width: (isPlaying ? 100 : 0) + '%',
          transition: `${isPlaying && wvState?.getDuration()}s linear`,
        }}
      />
      <div className='SoundPlayerCard__container-soundwave' ref={waveFormRef} />
      <div className='SoundPlayerCard__footer'>
        <div className='SoundPlayerCard__footer-text'>{props.text}</div>
        <div className='SoundPlayerCard__footer-btn-wrapper'>
          <button
            className='SoundPlayerCard__footer-btn SoundPlayerCard__footer-btn--test'
            onClick={() => wvState.play()}
          >
            <div style={{ marginRight: '8px' }}>Test</div>
            <Svg src='ic-volume-up' />
          </button>
          <button
            className={`SoundPlayerCard__footer-btn SoundPlayerCard__footer-btn--play ${
              props.isActive && 'SoundPlayerCard__footer-btn--play-disabled'
            }`}
            disabled={props.isActive}
            onClick={() => props.handler.onActiveClick(!props.isActive)}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
}

SoundPlayerCard.propTypes = {
  className: PropTypes.string,
  handler: PropTypes.shape({
    onActiveClick: PropTypes.func,
  }),
  isActive: PropTypes.bool,
  soundSrc: PropTypes.string,
  style: PropTypes.object,
  text: PropTypes.string,
};

SoundPlayerCard.defaultProps = {
  className: '-',
  handler: {
    onActiveClick: () => {},
  },
  isActive: true,
  soundSrc: soundDefault,
  style: {},
  text: '-',
};
