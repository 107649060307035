import { useDispatch, useSelector } from 'react-redux';
import { MAINMAP_MAP_MENUBAR_ACTION } from '../../redux';

export function useMainMapMenuBar() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.features.MAINMAP.MENUBAR);

  /**
   * @param {string} param
   */
  const setProjectName = (param) => {
    dispatch(MAINMAP_MAP_MENUBAR_ACTION.setProjectName(param));
  };

  const removeProjectName = () => {
    dispatch(MAINMAP_MAP_MENUBAR_ACTION.setProjectName(''));
  };

  /**
   * @param {number} id
   */
  const getProject = (id) => {
    dispatch(MAINMAP_MAP_MENUBAR_ACTION.getDetail(id));
  };

  const resetState = () => {
    dispatch(MAINMAP_MAP_MENUBAR_ACTION.resetState());
  };

  return {
    state,
    setProjectName,
    removeProjectName,
    getProject,
    resetState,
  };
}
