/* eslint-disable react/prop-types */
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Routes from '../src/routes';
import './sass/bvt/main.scss';
import { GA_Utils } from './shared/utils/GA-Utils/GA-Utils';
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

GA_Utils.initGA();

const mode = process.env.NEWRELIC_MODE;
if (mode === 'production') {
  const options = {
    init: {
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: ['bam.nr-data.net'] },
    },
    info: {
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      licenseKey: process.env.NEWRELIC_LICENSE_KEY,
      applicationID: process.env.NEWRELIC_APPLICATION_ID,
      sa: 1,
    },
    loader_config: {
      accountID: process.env.NEWRELIC_ACCOUNT_ID,
      trustKey: process.env.NEWRELIC_TRUST_KEY,
      agentID: process.env.NEWRELIC_AGENT_ID,
      licenseKey: process.env.NEWRELIC_LICENSE_KEY,
      applicationID: process.env.NEWRELIC_APPLICATION_ID,
    },
  };

  try {
    // eslint-disable-next-line no-unused-vars
    const _agent = new BrowserAgent(options); // Even if unused, store it to avoid warnings
  } catch (error) {
    console.error('Error initializing BrowserAgent:', error);
  }
}

const App = ({ history, store }) => {
  return (
    <Provider store={store}>
      <BrowserRouter history={history}>
        <Routes />
      </BrowserRouter>
    </Provider>
  );
};

export default hot(App);
