import { GENSET_POI_ACTIONS } from '@bvt-features/genset/poi/redux/GENSET_POI_ACTION';
import { useDispatch, useSelector } from 'react-redux';

export function usePOI() {
  const dispatch = useDispatch();

  const state = useSelector(
    (statement) => statement.features.GENSET.POI_MODULE.POI
  );

  const get = (_query) => {
    dispatch(GENSET_POI_ACTIONS.get(_query));
  };
  const create = (payload) => {
    dispatch(GENSET_POI_ACTIONS.create(payload));
  };
  const destroy = (id) => {
    dispatch(GENSET_POI_ACTIONS.delete(id));
  };
  const download = (payload, id) => {
    dispatch(GENSET_POI_ACTIONS.download(payload, id));
  };
  const download_dot = (payload, id) => {
    dispatch(GENSET_POI_ACTIONS.download_dot(payload, id));
  };
  const upload = (payload, id) => {
    dispatch(GENSET_POI_ACTIONS.uploadPOI(payload, id));
  };
  const rename = (payload, id) => {
    dispatch(GENSET_POI_ACTIONS.renamePOI(payload, id));
  };
  const reset_all = () => {
    dispatch(GENSET_POI_ACTIONS.reset_all());
  };
  const reset_status = () => {
    dispatch(GENSET_POI_ACTIONS.reset_status());
  };

  const detail = (_id) => {
    dispatch(GENSET_POI_ACTIONS.detail(_id));
  };

  const update = (_id, _body) => {
    dispatch(GENSET_POI_ACTIONS.update(_id, _body));
  };

  return {
    state,
    get,
    create,
    destroy,
    download,
    download_dot,
    upload,
    rename,
    reset_all,
    reset_status,
    detail,
    update,
  };
}
