import React from 'react';
import PropTypes from 'prop-types';
/**
 * @typedef {Object} IAgeRangeSelectedBox
 * @property {JSX.Element} iconElement icon of the component
 * @property {string} ageType age type follow design documentation
 * @property {string} ageRange age range follow design documentation
 * @property {string} className React className
 * @property {boolean} isActive is card active
 * @property {import('react').CSSProperties} style React css properies
 * @property {import('react').ReactEventHandler} onClick on card clicks
 * @property {()=>void} onHover on card hover
 */

/**
 * @version 1
 * @summary Age range selected box for showing age
 * @description Total hour wasted in this component 1 Hour
 * @author Randa <m.randa@bvarta.com>
 * @param {...IAgeRangeSelectedBox} props derived from {@link IAgeRangeSelectedBox}
 */
export default function AgeRangeSelectedBox(props) {
  const {
    iconElement,
    ageType,
    ageRange,
    className,
    style,
    onClick,
    onHover,
    isActive,
  } = props;

  const internalHandleClick = (e) => {
    onClick && onClick(e);
  };

  const internalHandleHover = (e) => {
    onHover && onHover(e);
  };

  return (
    <div
      className={`AgeRangeSelectedBox AgeRangeSelectedBox__card ${
        isActive && 'AgeRangeSelectedBox__card--active'
      } ${className}`}
      onClick={internalHandleClick}
      onMouseEnter={internalHandleHover}
      style={style}
    >
      <div className='AgeRangeSelectedBox__card-check'>
        <svg height='17.341' viewBox='0 0 17.341 17.341' width='17.341'>
          <g transform='translate(-364 -720)'>
            <path
              d='M16.19,2.69a8.67,8.67,0,1,1-8.67,8.67A8.719,8.719,0,0,1,16.19,2.69Z'
              fill='#3c3'
              fillRule='evenodd'
              transform='translate(356.48 717.31)'
            />
            <path
              d='M13732.6,6189.381a1.6,1.6,0,0,1-1.6-1.6v-3.191a1.6,1.6,0,0,1,3.191,0v1.6h4.783a1.6,1.6,0,1,1,0,3.19Z'
              fill='#b8fccf'
              transform='translate(-13714.293 6066.657) rotate(-45)'
            />
          </g>
        </svg>
      </div>
      <div className='AgeRangeSelectedBox__card-header'>
        <div className='AgeRangeSelectedBox__card-header-typeheading'>Type</div>
        <div className='AgeRangeSelectedBox__card-header-agetype'>
          {ageType || '-'}
        </div>
      </div>
      <div className='AgeRangeSelectedBox__card-body'>
        <div className='AgeRangeSelectedBox__card-body-icon'>
          {iconElement || '-'}
        </div>
      </div>
      <div className='AgeRangeSelectedBox__card-bottom'>
        <div className='AgeRangeSelectedBox__card-bottom-age'>Age</div>
        <div className='AgeRangeSelectedBox__card-bottom-agerange-container'>
          <div className='AgeRangeSelectedBox__card-bottom-agerange'>
            {ageRange || '-'}
          </div>
          <div className='AgeRangeSelectedBox__card-bottom-agerange-years'>
            Years
          </div>
        </div>
      </div>
    </div>
  );
}

AgeRangeSelectedBox.defaultProps = {
  ageRange: undefined,
  ageType: undefined,
  className: undefined,
  iconElement: undefined,
  isActive: undefined,
  onClick: undefined,
  onHover: undefined,
  style: undefined,
};
AgeRangeSelectedBox.propTypes = {
  ageRange: PropTypes.string,
  ageType: PropTypes.string,
  className: PropTypes.string,
  iconElement: PropTypes.element,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
  style: PropTypes.object,
};
