export const GENSET_CONSUME_POI_CONSTANT = {
  GET_START: 'LI/POI/consume_poi/get/requested',
  GET_FAILED: 'LI/POI/consume_poi/get/failed',
  GET_SUCCESS: 'LI/POI/consume_poi/get/success',

  DETAIL_START: '/LI/POI/consume_poi/detail/requested',
  DETAIL_FAILED: '/LI/POI/consume_poi/detail/failed',
  DETAIL_SUCCESS: '/LI/POI/consume_poi/detail/success',

  CREATE_START: '/LI/POI/consume_poi/create/requested',
  CREATE_FAILED: '/LI/POI/consume_poi/create/failed',
  CREATE_SUCCESS: '/LI/POI/consume_poi/create/success',

  UPDATE_START: 'LI/POI/consume_poi/update/requested',
  UPDATE_FAILED: 'LI/POI/consume_poi/update/failed',
  UPDATE_SUCCESS: 'LI/POI/consume_poi/update/success',

  DELETE_START: 'LI/POI/consume_poi/delete/requested',
  DELETE_FAILED: 'LI/POI/consume_poi/delete/failed',
  DELETE_SUCCESS: 'LI/POI/consume_poi/delete/success',

  DOWNLOAD_START: 'LI/POI/consume_poi/download/requested',
  DOWNLOAD_FAILED: 'LI/POI/consume_poi/download/failed',
  DOWNLOAD_SUCCESS: 'LI/POI/consume_poi/download/success',

  UPLOAD_START: 'LI/POI/consume_poi/upload/requested',
  UPLOAD_FAILED: 'LI/POI/consume_poi/upload/failed',
  UPLOAD_SUCCESS: 'LI/POI/consume_poi/upload/success',

  RESET_STATUS: 'LI/POI/consume_poi/reset-status',
  RESET_ALL: 'LI/POI/consume_poi/reset-all',
};
