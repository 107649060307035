import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import _ from 'lodash';

import { SharedBaseCardTypeOneComponent } from '@bvt-shared/component/SharedBaseCardTypeOne.component';

import './AsmanCardSES.component.scss';

ChartJS.register(ArcElement, Tooltip, Legend);

export const AsmanCardSESComponent = props => {
  const { data, isLoading } = props;

  const sumTotal = _.sumBy(data, v => v.value || 0);
  const arrValue = data.map(item => item.value);
  const arrColor = data.map(item => item.color);
  const topValue =
    data.length > 1
      ? data.reduce((accumulator, current) => {
        return accumulator.value > current.value ? accumulator : current;
      })
      : { title: 'unknown', color: 'red', value: 0 };

  const dataChart = {
    labels: [''],
    datasets: [
      {
        data: arrValue,
        backgroundColor: arrColor
      }
    ]
  };

  const options = {
    cutout: 110,
    plugins: {
      legend: {
        display: false
      }
    }
  };
  return (
    <SharedBaseCardTypeOneComponent
      isLoading={isLoading}
      showIcon
      title={<span>Social Economic Status (SES)</span>}
    >
      <div className='AsmanCardSESComponent__wrapper'>
        <div className='AsmanCardSESComponent__chart'>
          <Doughnut data={dataChart} options={options} />
          <div className='AsmanCardSESComponent__chart-inner'>
            <div className='AsmanCardSESComponent__chart-inner--title'>
              SES Domination
            </div>
            <div className='AsmanCardSESComponent__chart-inner--value'>
              {topValue.title.toUpperCase()}
            </div>
          </div>
        </div>
        <div className='AsmanCardSESComponent__list'>
          {data.map((item) => {
            const percentage = (
              ((item.value || 0) / sumTotal) *
              100
            ).toPrecision(3);
            return (
              <div
                className='AsmanCardSESComponent__chart-wrap'
                key={item.title}
              >
                <div className='AsmanCardSESComponent__chart-title'>
                  {percentage}%
                </div>
                <div className='AsmanCardSESComponent__chart-bar-item'>
                  <div
                    className='AsmanCardSESComponent__chart-bar'
                    style={{
                      width: `${percentage}%`,
                      backgroundColor: `${item.color}`
                    }}
                  >
                    {item.title}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </SharedBaseCardTypeOneComponent>
  );
};
