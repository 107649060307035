import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Faisal <ahmad.faisal@bvarta.com>
 * @param {Object} props
 * @param {string} props.className
 * @param {string} props.onClick
 */

export default function IconInfo(props) {
  const { className, onClick } = props;

  return (
    <div className={`${className} icon-info`} onClick={onClick}>
      <svg
        height='11.869'
        viewBox='0 0 3.716 11.869'
        width='3.716'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g
          data-name='Group 8658'
          id='Group_8658'
          transform='translate(-381.502 -220.04)'
        >
          <path
            d='M-5981.013,23530.867a1.427,1.427,0,0,1-1.485-1.365v-4.773a1.425,1.425,0,0,1,1.485-1.359h.747a1.424,1.424,0,0,1,1.484,1.359v4.773a1.426,1.426,0,0,1-1.484,1.365Zm0-8.457a1.429,1.429,0,0,1-1.485-1.365v-.68a1.43,1.43,0,0,1,1.485-1.367h.747a1.429,1.429,0,0,1,1.484,1.367v.68a1.429,1.429,0,0,1-1.484,1.365Z'
            data-name='Path 3027'
            fill='#fff'
            id='Path_3027'
            transform='translate(6364 -23298.957)'
          />
        </g>
      </svg>
    </div>
  );
}

IconInfo.defaultProps = {
  className: '',
  onClick: () => {},
};

IconInfo.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
