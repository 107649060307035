import INTERACTIVE_MAP_CONSTANT from '../constant';

const initialState = {
  value: false,
};

export function FULL_SCREEN(state = initialState, action) {
  switch (action.type) {
    case INTERACTIVE_MAP_CONSTANT.FULLSCREEN:
      return action.payload;
    case 'SET_FULLSCREEN':
      return {
        ...state,
        value: action.payload,
      };
    default:
      return state;
  }
}
