import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

/**
 * @description For legend list
 * @typedef ILegendItem
 * @type {object}
 * @property {string|number} key
 * @property {'CIRCLE'|'SQUARE'|'POLY'|'ICON'} type
 * @property {React.ReactElement} icon
 * @property {string} title
 * @property {string} color
 */

/**
 * @description For legend groupped list
 * @typedef ILegendParent
 * @type {object}
 * @property {string|number} key
 * @property {string} title
 * @property {{[x:string]: Array<ILegendItemGroupped>}} items
 */

/**
 * @description For legend list groupped item
 * @typedef ILegendItemGroupped
 * @type {object}
 * @property {string|number} key
 * @property {'CIRCLE'|'SQUARE'|'POLY'|'ICON'} type
 * @property {React.ReactElement} icon
 * @property {string} title
 * @property {string} color
 * @property {string} group
 * @property {number} groupOrder
 * @property {number} id
 * @property {number} order
 */

/**
 * @typedef initialStateModel
 * @type {object}
 * @property {Array<ILegendParent>} data
 * @property {{[x:string]: ILegendParent}} grouppedData
 * @property {boolean} isOpen
 * @property {boolean} isLoading
 */

/**
 * @type {initialStateModel} initialState
 */
const initialState = {
  data: [],
  isOpen: false,
  isLoading: false,
  grouppedData: {}
};

export const legend_slice = createSlice({
  name: 'LI/mainmap/legend',
  initialState,
  reducers: {
    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {ILegendItem} action.payload.data
     */
    pushDataToLegend(state, action) {
      const {
        payload: { data },
      } = action;
      state.data = [...state.data, ...data];
    },
    /**
     * groupped data
     * @param {object} action
     * @param {object} action.payload
     * @param {ILegendParent} action.payload.data
     */
    registerDataToLegend(state, action) {
      const {
        payload: { data },
      } = action;
      if (!data.items.length) {
        return;
      }
      const groupedItems = _.groupBy(data.items, 'group');

      if (!state.grouppedData?.[data.key]) {
        state.grouppedData[data.key] = {};
      }

      state.grouppedData = {
        ...state.grouppedData,
        [data.key]: {
          key: data.key,
          title: data.title,
          items: {
            ...(state.grouppedData?.[data.key]?.items || {}),
            ...groupedItems
          },
        },
      };
    },
    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {string} action.payload.parent
     * @param {string} action.payload.group
     */
    removeDataGroup(state, action) {
      const { parent, group } = action.payload;
      const oldStateGroupped = { ...state.grouppedData };
      if (oldStateGroupped?.[parent]?.items?.[group]) {
        delete oldStateGroupped[parent].items[group];
        if (_.isEmpty(oldStateGroupped[parent].items)) {
          delete oldStateGroupped[parent];
        }
        state.grouppedData = oldStateGroupped;
      }
    },
    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {string} action.payload.parent
     * @param {string} action.payload.group
     * @param {string} action.payload.key
     */
    removeDataByKey(state, action) {
      const { parent, group, key } = action.payload;
      const oldStateGroupped = { ...state.grouppedData };
      if (oldStateGroupped?.[parent]?.items?.[group]) {
        oldStateGroupped[parent].items[group] = oldStateGroupped?.[parent]?.items?.[group]
          .filter((v) => v.key !== key);

        if (oldStateGroupped[parent].items[group].length === 0) {
          delete oldStateGroupped[parent].items[group];
          if (_.isEmpty(oldStateGroupped[parent].items)) {
            delete oldStateGroupped[parent];
          }
        }
        state.grouppedData = oldStateGroupped;
      }
    },
    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {object} action.payload.isOpen
     */
    toggleOpenLegend(state, action) {
      const {
        payload: { isOpen },
      } = action;
      state.isOpen = isOpen;
    },
    clearLegendData(state) {
      state.data = [];
    },
    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {Array<number | string>} action.payload.key
     */
    removeDataByIds(state, action) {
      const { key } = action.payload;
      const filteredData = _.filter(state.data, (v) => !key.includes(v.key));
      state.data = filteredData;
    },
    /**
     * @param {Object} action
     * @param {Object} action.payload
     * @param {string} action.payload.prefix
     */
    removeDataByPrefix(state, action) {
      const { prefix } = action.payload;
      const oldStateGroupped = { ...state.grouppedData };
      if (oldStateGroupped?.[prefix]) {
        delete oldStateGroupped[prefix];
        state.grouppedData = oldStateGroupped;
      }
    },
    /**
     * @param {Object} action
     * @param {Object} action.payload
     * @param {boolean} action.payload.isLoading
     */
    onLoadLegendData(state, action) {
      const { isLoading } = action.payload;
      state.isLoading = isLoading;
    },
  },
});

export const { reducer: LEGEND_REDUCER, actions: LEGEND_ACTION } = legend_slice;
