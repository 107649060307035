import React from 'react';
import PropTypes from 'prop-types';
import './MenuItem.scss';

export default function MenuItem({ icon, value }) {
  return (
    <div className='MenuItem--container'>
      <div className='MenuItem--icon' >
        {icon}
      </div>
      <div className='MenuItem--item'>{value}</div>
    </div>
  );
}

MenuItem.defaultProps = {
  icon: '',
  value: '',
};

MenuItem.propTypes = {
  icon: PropTypes.node,
  value: PropTypes.string,
};
