import React from 'react';
import { Button } from 'antd';

import './AsmanCardAnalysisResultError.component.scss';

export const AsmanCardAnalysisResultErrorComponent = props => {
  const { message, onClick } = props;
  return (
    <div className='AsmanCardAnalysisResultErrorComponent__wrapper'>
      <img alt='error404' src='/assets/images/error404.png' />
      <div className='AsmanCardAnalysisResultErrorComponent__title'>
        An Error Analysis Detected
      </div>
      <div className='AsmanCardAnalysisResultErrorComponent__body'>
        We apologize for the inconvenience during your analysis. Our team is
        working to identify and resolve the issue as quickly as possible. In the
        meantime, we recommend that you try to Refresh the page or try running
        the analysis again. <b>Please report to us if the error still occur.</b>
      </div>
      <div className='AsmanCardAnalysisResultErrorComponent__body'>
        <i>{message || '"Unknown Error"'}</i>
      </div>
      <Button onClick={() => onClick && onClick()} size='large'>
        Refresh page
      </Button>
    </div>
  );
};
