import React from 'react';

export const IconInformation = () => {
  return (
    <svg
      fill='none'
      height='24'
      viewBox='0 0 14 14'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_5891_20757)'>
        <path
          d='M6.33289 10.333H7.66647V6.33313H6.33289V10.333ZM7.00009 0.333252C5.68147 0.333252 4.39245 0.724269 3.29606 1.45686C2.19966 2.18945 1.34513 3.2307 0.840512 4.44895C0.335897 5.6672 0.203866 7.00773 0.461117 8.30101C0.718368 9.5943 1.35335 10.7823 2.28575 11.7147C3.21816 12.6471 4.40612 13.2821 5.69941 13.5393C6.99269 13.7966 8.33322 13.6645 9.55147 13.1599C10.7697 12.6553 11.811 11.8008 12.5436 10.7044C13.2762 9.60796 13.6672 8.31895 13.6672 7.00033C13.6665 5.23231 12.9639 3.5369 11.7137 2.28672C10.4635 1.03654 8.76811 0.333908 7.00009 0.333252ZM7.00009 12.3338C5.94522 12.3338 4.91405 12.021 4.03696 11.435C3.15987 10.8489 2.47626 10.0159 2.07258 9.04137C1.6689 8.0668 1.56328 6.99441 1.76907 5.95982C1.97487 4.92522 2.48283 3.97488 3.22874 3.22898C3.97464 2.48307 4.92498 1.97511 5.95958 1.76931C6.99417 1.56352 8.06656 1.66914 9.04113 2.07282C10.0157 2.4765 10.8487 3.16011 11.4347 4.0372C12.0208 4.91429 12.3336 5.94546 12.3336 7.00033C12.3318 8.41433 11.7694 9.76991 10.7695 10.7698C9.76967 11.7696 8.41408 12.3321 7.00009 12.3338ZM6.33289 5.00037H7.66647V3.66679H6.33289V5.00037Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_5891_20757'>
          <rect
            fill='white'
            height='13.3333'
            transform='translate(0.333008 0.333252)'
            width='13.3333'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
