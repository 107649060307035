import Resource from '../../../../src/api/resource';

export class MainModuleService extends Resource {
  constructor() {
    super('main-module', '/api', 'general-setting', 'v2');
  }

  detail(_slug) {
    return this.get(_slug);
  }
}
