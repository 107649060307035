import React from 'react';
import PropTypes from 'prop-types';
import { Pie } from '@ant-design/plots';

export default function Donutchart(props) {
  const { data } = props;

  const colors = data.map((Item) => Item.color);
  const total = data.reduce((prev, current) => prev + current?.value, 0) || 0;

  const config = {
    padding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    color: colors,
    radius: 1,
    innerRadius: 0.8,
    label: {
      style: {
        fill: '#4D4F5C',
        opacity: 0.6,
        fontSize: 16,
      },
      labelLine: {
        style: {
          stroke: '#4D4F5C',
          strokeOpacity: 0.9,
        },
      },
      rotate: true,
      formatter: (e) => {
        return `${(((e.value / total) || 0) * 100).toFixed(1)}%`;
      },
    },
    legend: false,
    interactions: [{ type: 'element-active' }, { type: 'element-selected' }],
    state: {
      active: {
        animate: { duration: 100, easing: 'easeLinear' },
        style: {
          lineWidth: 0,
        },
      },
    },
    style: {
      lineWidth: 0,
    },
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '2rem',
        },
        content: ``,
      },
    },
    width: 200,
    height: 200,
  };
  return <Pie {...config} />;
}

Donutchart.defaultProps = {
  className: '',
  data: [],
  title: '',
};

Donutchart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  title: PropTypes.string,
};
