import * as React from 'react';
const IconTable = (props) => (
  <svg
    fill='none'
    height={16}
    viewBox='0 0 16 16'
    width={16}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M13.5 2H1.5L1 2.5V13.5L1.5 14H13.5L14 13.5V2.5L13.5 2ZM2 3H13V4H2V3ZM9 7H6V5H9V7ZM9 8V10H6V8H9ZM2 5H5V7H2V5ZM2 8H5V10H2V8ZM2 13V11H5V13H2ZM6 13V11H9V13H6ZM13 13H10V11H13V13ZM13 10H10V8H13V10ZM10 7V5H13V7H10Z'
      fill='#6C757D'
    />
  </svg>
);
export { IconTable };
