import React, { useEffect, useRef, useState } from 'react';
import { Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Svg } from '@bvt-atoms';
import { useDebounceEffect } from 'ahooks';
import { useLocation, useSearchParams } from 'react-router-dom';
import './SearchBox.scss';

/**
 * SearchBox Component
 * @version 1.5
 * @author Malik <malik.ibrahim@bvarta.com>
 * @author Randa <m.randa@bvarta.com>
 * @author Imam
 * @copyright PT Bhumi varta
 * @param {Object} props React Props
 * @param {String} props.className React classname for wrapper component
 * @param {Boolean} props.isLoading set is component loading or not
 * @param {Boolean} props.isDisabled set is component loading or not
 * @param {String} props.placeholder set component placeholder
 * @param {Number} props.width set size in percentage
 * @param {Boolean} props.withButtonText set is search button text show or not
 * @param {React.CSSProperties} props.style set style
 * @param {Object} props.handler object handler
 * @param {Function} props.handler.onDebounce set handler on search
 * @param {Function} props.handler.onSearch set handler on search
 * @param {Function} props.handler.onClear set handler on search clear
 */
export const SearchBox = (props) => {
  const [searchVal, setSearchVal] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const decoyref = useRef(null);

  const internalSearchHandler = () => {
    props.handler?.onSearch?.(searchVal);
  };

  const handleSearchChange = (val) => {
    setSearchVal(val);
    if (location.pathname === '/app/search') {
      setSearchParams({ name: val });
    }
  };

  const handleClear = () => {
    setSearchVal('');
    props.handler?.onClear?.(false);
  };

  useDebounceEffect(
    () => {
      if (props.handler?.onDebounce) {
        props.handler?.onDebounce?.(searchVal);
      }
    },
    [searchVal],
    {
      wait: 1000,
    }
  );

  useEffect(() => {
    setSearchVal(searchParams.get('name'));
    function unfocusinput(e) {
      if (e.key === 'Escape') {
        decoyref.current.focus();
        decoyref.current.blur();
        setSearchVal('');
      }
    }
    window.addEventListener('keyup', unfocusinput);
    return () => {
      window.removeEventListener('keyup', unfocusinput);
    };
  }, []);

  return (
    <div
      className={`search-box__wrapper ${props.className}`}
      ref={decoyref}
      style={{ width: props.width + '%', ...props.style }}
      tabIndex={-1}
    >
      <Input
        addonAfter={
          <span
            className={`search-box__button-search ${
              props.isDisabled ? 'search-box__button-search--disabled' : ''
            }
            `}
            onClick={() => !props.isDisabled && internalSearchHandler()}
          >
            {props.isLoading ? (
              <LoadingOutlined spin />
            ) : (
              <Svg className={'icon__search'} src='ic-search' />
            )}
            {props.withButtonText && (
              <span style={{ marginLeft: '8px' }}>Search</span>
            )}
          </span>
        }
        className={`search-box__ant-input-wrapper ${
          searchVal && 'search-box__ant-input-wrapper--filled'
        }`}
        disabled={props.isDisabled}
        onChange={(e) => handleSearchChange(e.target.value)}
        onKeyPress={(e) => e.key === 'Enter' && internalSearchHandler()}
        placeholder={props.placeholder}
        suffix={
          searchVal ? (
            <button
              className='search-box__clear-button'
              disabled={props.isDisabled}
              onClick={() => handleClear()}
            >
              <svg height={14} viewBox='0 0 12.263 12.263' width={14}>
                <path
                  d='M3.153,7.489V5.518H1.183a1.182,1.182,0,1,1,0-2.364h1.97V1.183a1.183,1.183,0,1,1,2.365,0V3.154H7.489a1.182,1.182,0,1,1,0,2.364H5.518V7.489a1.183,1.183,0,1,1-2.365,0Z'
                  fill='currentColor'
                  transform='translate(6.132) rotate(45)'
                />
              </svg>
            </button>
          ) : (
            <span />
          )
        }
        value={searchVal}
      />
    </div>
  );
};

SearchBox.defaultProps = {
  className: '',
  handler: {
    onSearch: () => {},
    onDebounce: () => {},
    onClear: () => {},
  },
  isDisabled: false,
  isLoading: false,
  placeholder: 'search...',
  style: {},
  width: 100,
  withButtonText: true,
};
