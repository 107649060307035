import React from 'react';
import { CreditCardFilled } from '@ant-design/icons';

import { SharedBaseCardTypeOneComponent } from '@bvt-shared/component/SharedBaseCardTypeOne.component';

import './AsmanCardLandValue.component.scss';

export const AsmanCardLandValueComponent = props => {
  const { current, previous, isLoading } = props;
  return (
    <SharedBaseCardTypeOneComponent
      isLoading={isLoading}
      showIcon
      title={<span>Land value</span>}
    >
      <span className='AsmanCardLandValueComponent__des'>
        The selling value of land will always change every year
      </span>
      <div className='AsmanCardLandValueComponent__wrapper'>
        <div className='AsmanCardLandValueComponent__previous'>
          <div className='AsmanCardLandValueComponent__icon'>
            <CreditCardFilled style={{ fontSize: '26px' }} />
          </div>
          <div className='AsmanCardLandValueComponent__value'>
            {previous ? (
              <React.Fragment>
                {previous.toLocaleString('id', {
                  style: 'currency',
                  currency: 'IDR'
                })}{' '}
                <span>/Sqm</span>
              </React.Fragment>
            ) : (
              'N/A'
            )}
          </div>
          <div className='AsmanCardLandValueComponent__sub'>Previous year</div>
        </div>
        <div className='AsmanCardLandValueComponent__current'>
          <div className='AsmanCardLandValueComponent__icon'>
            <CreditCardFilled style={{ fontSize: '26px' }} />
          </div>
          <div className='AsmanCardLandValueComponent__value'>
            {current.toLocaleString('id', {
              style: 'currency',
              currency: 'IDR'
            })}{' '}
            <span>/Sqm</span>
          </div>
          <div className='AsmanCardLandValueComponent__sub'>This year</div>
        </div>
      </div>
    </SharedBaseCardTypeOneComponent>
  );
};
