import { IndustriesService } from '../service';
import { GENSET_CATEGORY_CONSTANT } from './GENSET_CATEGORY_CONSTANT';

const consumePoiService = new IndustriesService();


export const GENSET_CATEGORY_ACTIONS = {
  get: (_query) => {
    return async (dispatch) => {
      dispatch({
        type: GENSET_CATEGORY_CONSTANT.GET_START,
        payload: {
          status_GET: 'LOADING',
        },
      });
      try {
        let { message, meta,
          result } = await consumePoiService.getDetailIndustry(_query);
        dispatch({
          type: GENSET_CATEGORY_CONSTANT.GET_SUCCESS,
          payload: {
            status_GET: 'SUCCESS',
            list: result,
            message: message,
            properties: meta,
          },
        });
      } catch (error) {
        dispatch({
          type: GENSET_CATEGORY_CONSTANT.GET_FAILED,
          payload: {
            status_GET: 'FAILED',
            message: error.response?.data?.message,
          },
        });
      }
    };
  },
};
