import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Faisal <ahmad.faisal@bvarta.com>
 * @param {Object} props
 * @param {string} props.className
 */

export default function IconCalender(props) {
  const { className, height, width } = props;

  return (
    <div className={(className, 'icon-calender')}>
      <svg
        data-name='Group 9619'
        height={height}
        id='Group_9619'
        viewBox={'0 0 18 18'}
        width={width}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M0,1A1,1,0,1,1,1,2,1,1,0,0,1,0,1Z'
          fill='#007e75'
          id='Path'
          transform='translate(4 6)'
        />
        <path
          d='M0,1A1,1,0,1,1,1,2,1,1,0,0,1,0,1Z'
          data-name='Path'
          fill='#007e75'
          id='Path-2'
          transform='translate(4 10)'
        />
        <path
          d='M1,0A1,1,0,1,0,2,1,1,1,0,0,0,1,0Z'
          data-name='Path'
          fill='#007e75'
          id='Path-3'
          transform='translate(8 6)'
        />
        <path
          d='M0,1A1,1,0,1,1,1,2,1,1,0,0,1,0,1Z'
          data-name='Path'
          fill='#007e75'
          id='Path-4'
          transform='translate(12 6)'
        />
        <path
          d='M16,18H2a2,2,0,0,1-2-2V4A2,2,0,0,1,2,2H4V1A1,1,0,0,1,6,1V2h6V1a1,1,0,0,1,2,0V2h2a2,2,0,0,1,2,2V16A2,2,0,0,1,16,18ZM3,4A1,1,0,0,0,2,5V15a1,1,0,0,0,1,1H15a1,1,0,0,0,1-1V5a1,1,0,0,0-1-1Z'
          fill='#007e75'
          id='Shape'
        />
      </svg>
    </div>
  );
}

IconCalender.defaultProps = {
  className: '',
  height: 18,
  width: 18,
};
IconCalender.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
