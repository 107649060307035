import React, { useRef } from 'react';
import './LayoutMainMapRightSide.scss';

/**
 * @author Randa<m.randa@bvarta.com>
 * @description Layoutmainmaprightside
 * @param {object} props
 * @param {number} props.top
 * @param {number} props.width
 * @param {boolean} props.isActive
 * @param {JSX.Element} props.children
 * @param {(e)=>void} props.onMouseEnter
 * @param {(e)=>void} props.onMouseLeave
 */
export const LayoutMainMapRightSide = (props) => {
  const elRef = useRef();
  const { top, width, isActive, children,onMouseEnter,onMouseLeave } = props;

  return (
    <div
      className='feature-mainmap LayoutMainMapRightSide'
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={elRef}
      style={{
        top,
        right: 0,
        width,
        height: window.innerHeight - (top || 0),
        transition:'0.3s ease-in-out',
        display:'flex',
        flexDirection:'column',
        transform: isActive
          ? 'translateX(0px)'
          : `translateX(${elRef.current?.clientWidth || width}px)`,
      }}
    >
      {children}
    </div>
  );
};
