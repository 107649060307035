import React from 'react';
import './AccountDetailCard.scss';

/**
 * @company PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

/**
 *
 * @param {Object} props
 * @param {string} props.title
 * @param {any} props.headContent
 * @param {any} props.bodyContent
 * @param {any} props.footer
 */

export const AccountDetailCard = (props) => {
  const { title, headContent, bodyContent, footer } = props;
  return (
    <div className='AccountDetailCard'>
      <div className='AccountDetailCard--title'>{title}</div>
      <div className='AccountDetailCard--head-content'>{headContent}</div>
      <div className='AccountDetailCard--body-content'>{bodyContent}</div>
      <div className='AccountDetailCard--footer'>{footer}</div>
    </div>
  );
};
