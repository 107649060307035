import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Faisal <ahmad.faisal@bvarta.com>
 * @param {Object} props
 * @param {string} props.className
 * @param {string} props.onClick
 */

export default function IconPlus(props) {
  const { className, onClick } = props;

  return (
    <div className={`${className} icon-plus`} onClick={onClick}>
      <svg
        height='14'
        viewBox='0 0 14 14'
        width='14'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g
          data-name='Group 534'
          id='Group_534'
          transform='translate(-1556 -79.5)'
        >
          <rect
            data-name='Rectangle 15'
            fill='#364961'
            height='3'
            id='Rectangle_15'
            rx='1.5'
            transform='translate(1556 85)'
            width='14'
          />
          <rect
            data-name='Rectangle 16'
            fill='#364961'
            height='3'
            id='Rectangle_16'
            rx='1.5'
            transform='translate(1564.5 79.5) rotate(90)'
            width='14'
          />
        </g>
      </svg>
    </div>
  );
}

IconPlus.defaultProps = {
  className: '',
  onClick: () => {},
};

IconPlus.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
