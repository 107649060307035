import { useCookies } from 'react-cookie';

const Set = (
  key = '',
  value = '',
  options = {
    path: '/'
  }
) => {
  const [, setCookie, removeCookie] = useCookies([key]);
  removeCookie(key, { ...options });
  setCookie(key, value, { ...options });
  // const [, setCookie] = useCookies([key]);
  // const [cookies, setCookie] = this.reactCookies
  //     setCookie(key, value, {
  //         path: '/',
  //         httpOnly: true
  // })
};

const Get = (key = '') => {
  const [cookies] = useCookies([key]);
  return cookies[key];

};

const Remove = (
  key = '',
  options = {
    path: '/',
  }
) => {
  const [ , , removeCookie] = useCookies([key]);
  removeCookie(key, { ...options });
};

export default {
  Set,
  Get,
  Remove
};
