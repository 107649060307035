import React from 'react';
import { IconType, IconTypeDefault } from '@bvt-types';

export default function IconEdit(props) {
  const { className, height, width } = props;

  return (
    <svg height={height} viewBox='0 0 29 29' width={width}>
      <rect className={className} height='29' rx='14.5' width='29' />
      <g transform='translate(7.227 7.293)'>
        <path
          d='M9.277,16.711,16.71,9.278a2.4,2.4,0,0,0,0-3.385L14.451,3.633a2.4,2.4,0,0,0-3.385,0L3.633,
          11.066a2.4,2.4,0,0,0-.7,1.693v4.655H7.585a2.4,2.4,0,0,0,1.693-.7Zm-4.79-3.992a.8.8,0,0,1,.232-.559L12.16,
          4.719a.8.8,0,0,1,1.126,0l2.259,2.259a.8.8,0,0,1,0,1.126l-7.4,7.481a.8.8,0,0,1-.559.232H4.527Z'
          fill='#00a8ff'
          transform='translate(-2.93 -2.933)'
        />
      </g>
      <g transform='matrix(1, 0, 0, 1, -6.22, -1.15)'>
        <path
          d='M4.487,12.719a.8.8,0,0,1,.232-.559L12.16,4.719a.8.8,0,0,1,1.126,0l2.259,2.259a.8.8,0,0,1,0,1.126l-7.4,7.481a.8.8,0,0,1-.559.232H4.527Z'
          fill='#fff'
          transform='translate(10.51 5.51)'
        />
      </g>
    </svg>
  );
}

IconEdit.defaultProps = IconTypeDefault;

IconEdit.propTypes = IconType;
