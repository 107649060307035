import React from 'react';
import PropTypes from 'prop-types';
import './ParameterInput.scss';
import { ImageBox } from '@bvt-shared/component/ImageBox';

export default function ParameterInput(props) {
  const getStyle = () => {
    switch (props.titlePlacement) {
      case 'top':
        return {
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '0.125rem',
        };
      case 'left':
        return {
          flexDirection: 'row',
          alignItems: 'center',
          gap: '1rem',
        };
      case 'right':
        return {
          flexDirection: 'row-revers',
          alignItems: 'center',
          gap: '1rem',
        };
      default:
        return {};
    }
  };

  return (
    <div className='feature-genset-parameter ParameterInput'>
      <div
        className='feature-genset-parameter ParameterInput--container'
        style={getStyle()}
      >
        {props.title && (
          <div className='feature-genset-parameter ParameterInput--title'>
            {props.title}
          </div>
        )}
        <div
          className='feature-genset-parameter ParameterInput--input_group'
          style={{ borderColor: props.value ? '#007e75' : '' }}
        >
          <input
            onChange={props?.onChange}
            placeholder={props.placeholder}
            type='text'
            value={props.value}
          />
          {props.value && props.onClear && (
            <span className='close-icon' onClick={() => props.onClear()}>
              <ImageBox src='ic-close' />
            </span>
          )}
        </div>
        {props.error && <div className='error-message'>{props.error}</div>}
      </div>
    </div>
  );
}

ParameterInput.defaultProps = {
  error: '',
  onChange: undefined,
  onClear: undefined,
  placeholder: '',
  style: {},
  title: '',
  titlePlacement: 'top',
  type: '',
  value: '',
};

ParameterInput.propTypes = {
  error: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  titlePlacement: PropTypes.oneOf(['top', 'left', 'right']),
  type: PropTypes.string,
  value: PropTypes.string,
};
