import { USER_CONSTANT } from './USER_CONSTANT';

const initialState = {
  status_GET: 'IDLE',
  status_POST: 'IDLE',
  status_PUT: 'IDLE',
  status_DELETE: 'IDLE',
  message: '',
  data: {},
};

export function USER_REDUCER(state = initialState, action) {
  let returnData = state;
  Object.values(USER_CONSTANT).map((ctx) => {
    if (action.type === USER_CONSTANT.RESET_STATUS) {
      returnData = {
        ...returnData,
        status_GET: 'IDLE',
        status_POST: 'IDLE',
        status_PUT: 'IDLE',
        status_DELETE: 'IDLE',
        message: '',
      };
    } else if (action.type === USER_CONSTANT.RESET_ALL) {
      returnData = {
        status_GET: 'IDLE',
        status_POST: 'IDLE',
        status_PUT: 'IDLE',
        status_DELETE: 'IDLE',
        message: '',
        data: {},
      };
    } else if (ctx === action.type && !ctx.includes('[NO_STATE_UPDATE]')) {
      returnData = { ...returnData, ...action.payload };
    }
    return null;
  });

  return returnData;
}
