import React from 'react';
import './AccountCard.scss';

/**
 * @company PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {any} props.content
 */

export const AccountCard = (props) => {
  const { content, title } = props;
  return (
    <div className='AccountCard'>
      <div className='AccountCard--header'>{title}</div>
      <div className='AccountCard--content'>{content}</div>
    </div>
  );
};
