import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Faisal <ahmad.faisal@bvarta.com>
 * @param {Object} props
 * @param {string} props.className
 */

export default function IconStatistic(props) {
  const { className } = props;

  return (
    <div className={(className, 'icon-statistic')}>
      <svg
        height='14.227'
        viewBox='0 0 15.807 14.227'
        width='15.807'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M8.624,12.99,7.742,6.035,6.86,12.99H3.79a.772.772,0,1,1,0-1.544H5.463l.711-5.6a1.585,1.585,0,0,1,3.137,0l.711,5.6h1.56l.9,5.727.9-5.726h4.63a.773.773,0,1,1,0,1.545H14.743l-.7,4.415a1.587,1.587,0,0,1-3.125,0l-.7-4.415h-1.6Z'
          fill='#657991'
          id='activity'
          transform='translate(-3 -4.49)'
        />
      </svg>
    </div>
  );
}

IconStatistic.defaultProps = {
  className: '',
};

IconStatistic.propTypes = {
  className: PropTypes.string,
};
