import React from 'react';
import { Button } from '@bvt-atoms';
import DynamicIcon from '@bvt-icons';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { useAuth } from '@bvt-features/auth/hooks';
import { generateUUID } from '@bvt-utils';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 */

/**
 * @param {object} props
 * @param {Array.<id:number, name: string, code: string, icon: string, step: string, active: bool>} props.item
 * @param {func} props.onClickCloseMenu
 * @param {func} props.onClickonOpenSubMenu
 * @param {string} props.open
 * add props show
 */

export default function SubMenuList(props) {
  const AUTH = useAuth();
  return (
    <div className='module-bar__submenu__list'>
      <Button
        className='module-bar__submenu__button close'
        onClick={() => props.onClickCloseMenu && props.onClickCloseMenu()}
      >
        <DynamicIcon
          className='module-bar__submenu__icon close'
          height='1rem'
          icon='close'
          width='1rem'
        />
      </Button>
      <Swiper
        className='module-bar__swiper'
        modules={[Navigation]}
        navigation={{
          nextEl: '.module-bar__submenu__button--arrow--next',
          prevEl: '.module-bar__submenu__button--arrow--prev',
        }}
        slidesPerView={8}
      >
        {props?.item?.map((item) => {
          return (
            AUTH.hasAccess(item.permission) && <SwiperSlide
              className={`module-bar__submenu__button ${
                item.active ? 'active' : ''
              }`}
              key={generateUUID()}
              onClick={() =>
                props.onClickonOpenSubMenu && props.onClickonOpenSubMenu(item)
              }
            >
              <DynamicIcon
                className={`module-bar__submenu__icon ${
                  item.active ? 'active' : ''
                }`}
                height='1.25rem'
                icon={item.icon}
                width='1.25rem'
              />
              <div
                className={`module-bar__submenu__text ${
                  item.active ? 'active' : ''
                }`}
              >
                {item.name}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}

SubMenuList.propTypes = {
  item: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      code: PropTypes.string,
      icon: PropTypes.string,
      step: PropTypes.string,
      active: PropTypes.bool,
    })
  ),
  onClickCloseSubMenu: PropTypes.func,
};
