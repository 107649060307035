import React from 'react';
export const IconCross = () => {
  return (
    <svg
      fill='none'
      height='1rem'
      viewBox='0 0 24 24'
      width='1rem'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.72671 14.3804L9.11845 11.9887L6.70841 9.57865C6.3249 9.19514 6.10821 8.67623 6.10602 8.13606C6.10382 7.59589 6.3163 7.07872 6.6967 6.69832C7.0771 6.31792 7.59427 6.10544 8.13444 6.10764C8.67461 6.10983 9.19352 6.32652 9.57703 6.71003L11.9871 9.12007L14.3788 6.72833C14.5651 6.53076 14.7895 6.3729 15.0386 6.26409C15.2878 6.15529 15.5566 6.09776 15.8292 6.0949C16.1019 6.09205 16.3728 6.14393 16.6258 6.24747C16.8789 6.35101 17.1091 6.50411 17.3027 6.69771C17.4963 6.8913 17.6494 7.12146 17.7529 7.37455C17.8565 7.62764 17.9084 7.89852 17.9055 8.17115C17.9026 8.44379 17.8451 8.71263 17.7363 8.96177C17.6275 9.21091 17.4696 9.43528 17.2721 9.6216L14.8803 12.0133L17.2916 14.4246C17.6751 14.8081 17.8918 15.327 17.894 15.8672C17.8962 16.4073 17.6837 16.9245 17.3033 17.3049C16.9229 17.6853 16.4057 17.8978 15.8656 17.8956C15.3254 17.8934 14.8065 17.6767 14.423 17.2932L12.0117 14.882L9.61998 17.2737C9.43366 17.4713 9.20929 17.6291 8.96015 17.7379C8.71101 17.8467 8.44217 17.9043 8.16953 17.9071C7.8969 17.91 7.62602 17.8581 7.37293 17.7545C7.11984 17.651 6.88968 17.4979 6.69609 17.3043C6.50249 17.1107 6.34939 16.8806 6.24585 16.6275C6.14231 16.3744 6.09043 16.1035 6.09328 15.8309C6.09614 15.5582 6.15367 15.2894 6.26247 15.0402C6.37128 14.7911 6.52914 14.5667 6.72671 14.3804Z'
        fill='currentColor'
      />
    </svg>
  );
};
