import CookiesApp from './cookies';

class TokenManager {

  constructor() {
    this.ACCESS_TOKEN = 'li_acc_token';
    this.REFRESH_TOKEN = 'li_ref_token';
  }

  static setAccessToken(value = '') {
    CookiesApp.Set(this.ACCESS_TOKEN, value);
  }

  static setRefreshToken(value = '') {
    CookiesApp.Get(this.REFRESH_TOKEN, value);
  }

  static getAccessToken() {
    return CookiesApp.Get(this.ACCESS_TOKEN);
  }

  static getRefreshToken() {
    return CookiesApp.Get(this.REFRESH_TOKEN);
  }

  static clearAll() {
    Object.keys(this).forEach(key=> {
      CookiesApp.Remove(key);
    });
  }

  static clear(key = '') {
    if(![this.ACCESS_TOKEN, this.REFRESH_TOKEN].includes(key)) {
      throw new Error('Token Key Not Registered');
    }
    CookiesApp.Remove(key);
  }

}

export default TokenManager;
