import React from 'react';

export const IconFocus = () => (
  <svg
    fill='none'
    height='1rem'
    viewBox='0 0 24 24'
    width='1rem'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_5244_27240)'>
      <path
        d='M12 7.55556C11.121 7.55556 10.2617 7.81622 9.5308 8.30458C8.79991 8.79294 8.23026 9.48707 7.89387 10.2992C7.55748 11.1113 7.46946 12.0049 7.64095 12.8671C7.81244 13.7292 8.23574 14.5211 8.8573 15.1427C9.47887 15.7643 10.2708 16.1876 11.1329 16.359C11.9951 16.5305 12.8887 16.4425 13.7008 16.1061C14.5129 15.7697 15.2071 15.2001 15.6954 14.4692C16.1838 13.7383 16.4444 12.879 16.4444 12C16.4444 10.8213 15.9762 9.6908 15.1427 8.8573C14.3092 8.02381 13.1787 7.55556 12 7.55556ZM4.22222 15.3333H2V19.7778C2.00176 20.3666 2.23645 20.9308 2.65282 21.3472C3.06918 21.7636 3.63339 21.9982 4.22222 22H8.66667V19.7778H4.22222V15.3333ZM4.22222 4.22222H8.66667V2H4.22222C3.63339 2.00176 3.06918 2.23645 2.65282 2.65282C2.23645 3.06918 2.00176 3.63339 2 4.22222V8.66667H4.22222V4.22222ZM19.7778 2H15.3333V4.22222H19.7778V8.66667H22V4.22222C21.9982 3.63339 21.7636 3.06918 21.3472 2.65282C20.9308 2.23645 20.3666 2.00176 19.7778 2ZM19.7778 19.7778H15.3333V22H19.7778C20.3666 21.9982 20.9308 21.7636 21.3472 21.3472C21.7636 20.9308 21.9982 20.3666 22 19.7778V15.3333H19.7778V19.7778Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_5244_27240'>
        <rect fill='white' height='20' transform='translate(2 2)' width='20' />
      </clipPath>
    </defs>
  </svg>
);
