import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import AuthInput from '../AuthInput';
import './ForgotPasswordForm.scss';
import { Player } from '@lottiefiles/react-lottie-player';
import { ForgotPasswordIn, ForgotPasswordOut } from '@bvt-animations';

/**
 * @author Randa<m.randa@bvarta.com>
 * @copyright PT Bhumi Varta Tecnology
 * @param {object} props React props
 * @param {(v:{email:string})=>void} props.onSubmit
 * @param {()=>void} props.onBackClick
 * @param {Boolean} props.isLoading
 */
const ForgotPasswordForm = (props) => {
  const { onSubmit, isLoading, onBackClick } = props;
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email('E - Mail Not Valid')
        .required('Email is required field'),
    }),
    onSubmit: (e) => {
      onSubmit && onSubmit(e);
    },
  });
  const isValidForm = !isLoading && formik.values.email && formik.isValid;
  return (
    <React.Fragment>
      {!isLoading &&
        (formik.values.email ? (
          <Player
            autoplay
            className='feature-auth ForgotPasswordForm__lottie'
            keepLastFrame
            speed={3}
            src={ForgotPasswordOut}
          />
        ) : (
          <Player
            autoplay
            className='feature-auth ForgotPasswordForm__lottie'
            keepLastFrame
            speed={2}
            src={ForgotPasswordIn}
          />
        ))}
      <form
        className='feature-auth ForgotPasswordForm feature-auth ForgotPasswordForm__wrapper'
        onSubmit={(e) => {
          e.preventDefault();
          !isLoading && formik.submitForm();
        }}
      >
        {!isLoading && (
          <>
            <h1 className='feature-auth ForgotPasswordForm__title'>
              Password Recovery
            </h1>
            <div className='feature-auth ForgotPasswordForm__title-desc'>
              After submit your email address, we'll email you a code to verify
              that it's you and you'll be able to recovering your password.
            </div>
            <AuthInput
              error={formik.touched.email && formik.errors.email}
              icon='ic-email'
              onChange={(e) => formik.setFieldValue('email', e.target.value)}
              onClear={() => formik.setFieldValue('email', '')}
              title='Email Address'
              value={formik.values.email}
            />
            <div className='feature-auth ForgotPasswordForm__helper-text'>
              Still having trouble recovering your password? please contact us
              at &nbsp;<a href='tech.support@bvarta.com'>tech.support@bvarta.com</a>
            </div>
          </>
        )}
        <div className='feature-auth ForgotPasswordForm__button-wrapper'>
          {!isLoading && (
            <div>
              <button
                className='feature-auth ForgotPasswordForm__button back-to-login'
                onClick={() => onBackClick()}
                type='button'
              >
                Back To Login
              </button>
            </div>
          )}
          <button
            className={`
          feature-auth ForgotPasswordForm__button
          ${
    !isValidForm && 'feature-auth ForgotPasswordForm__button--not-valid'
    }
          ${isLoading && 'feature-auth ForgotPasswordForm__button--loading'}`}
            disabled={isLoading}
          >
            {isLoading ? 'Proccesing...' : 'Submit'}
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

ForgotPasswordForm.defaultProps = {};

ForgotPasswordForm.propTypes = {
  isLoading: PropTypes.bool,
  onBackClick: PropTypes.oneOfType([PropTypes.any, PropTypes.func]),
  onSubmit: PropTypes.oneOfType([PropTypes.any, PropTypes.func]),
};

export default ForgotPasswordForm;
