const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const regexUserName = /^[a-zA-Z0-9]/;
const regexPassword =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-]).{8,}$/;

export default function validateInput(value, rules) {
  const checkRule = (rule, condition, errorMessage) => {
    return rules.find((i) => i === rule && !condition) ? errorMessage : '';
  };

  const getValueFromRule = (rule) => Number(rule.replace(/[^0-9,]+/g, ''));

  if (checkRule('required', noValue(value), 'Harus diisi') !== '') {
    return 'Harus diisi';
  }

  if (
    checkRule('valid-email', !regexEmail.test(value), 'Format email salah') !==
    ''
  ) {
    return 'Format email salah';
  }

  if (checkRule('valid-user', !regexUserName.test(value), '') !== '') {
    return ' ';
  }

  if (checkRule('valid-password', !regexPassword.test(value), '') !== '') {
    return ' ';
  }

  const minRuleChecks = [
    {
      key: 'min-value',
      message: (value) => `Harus lebih dari ${value} karakter`,
    },
    { key: 'min-number', message: (value) => `Harus lebih dari ${value - 1}` },
    { key: 'min-day', message: (value) => `Minimal ${value} hari` },
    {
      key: 'min-max',
      message: (value) =>
        `Harus lebih dari Rp ${value.toLocaleString(['ban', 'id'])}`,
    },
    { key: 'min-percent', message: (value) => `Minimal ${value} %` },
    { key: 'max-percent', message: (value) => `Maksimal ${value} %` },
  ];

  for (const ruleCheck of minRuleChecks) {
    const rule = rules.find((i) => i.includes(ruleCheck.key));

    if (rule && value < getValueFromRule(rule)) {
      return ruleCheck.message(getValueFromRule(rule));
    }
  }

  if (rules.find((i) => i.includes('min-user') && getValueFromRule(i) > 0)) {
    return `${rules[2]} sudah ada`;
  }

  if (rules.find((i) => i.includes('date') && value < getValueFromRule(i))) {
    return 'Tanggal berakhir harus lebih dari tanggal mulai';
  }

  return '';
}

export function noValue(val) {
  return typeof val === 'object' ? val && !val.length : !val;
}
