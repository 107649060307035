import Resource from '../../../api/resource';

/**
 * @copyright PT Bhumi Varta Techonology
 * @author Renta<renta.yustika@bvarta.com>
 */

export class DatexService extends Resource {
  constructor() {
    super('telco-data', '/api', 'api-poi', 'v2');
  }
  /**
   *
   * place service
   * @param {object} params - Params for get list place
   */

  AnalyzeTelcoData(_body) {
    const data = _body;
    this.setAdditionUri('/v3');
    return this.create(data);
  }

  GetLegendColor() {
    this.setAdditionUri('/list-legend-color');
    return this.get();
  }

  GetListDate() {
    this.setAdditionUri('/disabled-date');
    return this.list();
  }
}
