import React, { useEffect } from 'react';
import { DatexNoData } from '@bvt-features/datex/component/DatexNoData';

import './BaseOptionListItemMultipleThematic.scss';
import { Checkbox, Skeleton } from 'antd';
/**
 * @copyright PT Bhumi Varta Technology
 * @author Malik<malik.ibrahim@bvarta.com>
 */

/**
 * @description For item object
 * @typedef IItemObject
 * @type {Object}
 * @property {string|number} key
 * @property {string} value
 */

/**
 * @param {Object} props
 * @param {Array<IItemObject>} props.data
 * @param {(data: Object{ key: Array; label: Array })=>void} props.onSelectItems
 * @param {Array<String|Number>} props.selectedItems
 * @param {(valid:Boolean)=>void} props.onValidOrInvalid
 * @param {boolean} props.isLoading
 */
export const BaseOptionListItemMultipleThematic = (props) => {
  const {
    data,
    isLoading,
    onSelectItems,
    selectedItems,
    onValidOrInvalid,
  } = props;

  useEffect(() => {
    if (selectedItems.length > 0) {
      onValidOrInvalid && onValidOrInvalid(true);
    } else {
      onValidOrInvalid && onValidOrInvalid(false);
    }
  }, [selectedItems]);

  return (
    <>
      {isLoading
        ? Array.from([1, 2, 3, 4, 5]).map((v) => (
          <Skeleton.Input
            active={true}
            block={true}
            key={v}
            loading={isLoading}
            paragraph={false}
            style={{ height: '2rem', marginTop: '1rem' }}
            type='image'
          />
        ))
        : null}
      {!isLoading && !data?.length > 0 && (
        <DatexNoData title='Item name not found' />
      )}
      <Checkbox.Group
        className='BaseOptionListItemMultipleThematic__list'
        onChange={(e) => {
          const label = data
            ?.filter((v) => e?.includes(v?.key))
            .map((v) => v.value);
          onSelectItems && onSelectItems({ key: e, label });
        }}
        options={data?.map((v) => {
          return {
            value: v?.key,
            label: v?.value,
          };
        })}
        value={selectedItems ?? undefined}
      />
      <div className='DatexStepPOICategoryCollection__footer'>
        <span className='DatexStepPOICategoryCollection__footer--text'>
          {selectedItems?.length || 0} Items Selected
        </span>
      </div>
    </>
  );
};
