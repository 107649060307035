import { combineReducers } from 'redux';
import { mainmapReducer } from '../mainmap/store/mainmap.slice';
import { advanceSearchReducer } from '../advance-search/store/advanceSearch.slice';
import { asmanReducer } from '../asman/store/asman.slice';

export const SUBMODULE_REDUCER = combineReducers({
  mainMap: mainmapReducer,
  advanceSearch: advanceSearchReducer,
  asman: asmanReducer,
});
