import React, { Fragment, useEffect } from 'react';
import { usePlace } from '../../../hooks/usePlace';
import { debounce, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import '../Dropdown.scss';
import Dropdown from '../../Dropdown';

export function SearchProvince(props) {
  const { isProvince } = props;
  const PLACE = usePlace();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!isEmpty(PLACE.state.params.provinsi)) {
      isProvince && isProvince(PLACE.state.params.provinsi);
    }
  }, [PLACE.state.params.provinsi]);

  const getData = (value) => {
    const params = {
      meta: { filter: { keyword: value }, page: 1, pageSize: 100 },
    };
    PLACE.get_province({ params });
  };

  const getDataDebounce = debounce(getData, 800);

  return (
    <Fragment>
      <Dropdown
        handler={{
          select: (ctx) => {
            PLACE.setParams({ type: 'provinsi', ...ctx });
            PLACE.removeParams({ kota: null });
            PLACE.removeParams({ kecamatan: null });
            PLACE.removeParams({ desa: null });
            isProvince(ctx);
            props?.isCity?.(null);
            props?.setDistrict?.(null);
            props?.setVillage?.(null);
          },
          clear: () => {
            PLACE.removeParams({ provinsi: null });
            PLACE.removeParams({ kota: null });
            PLACE.removeParams({ kecamatan: null });
            PLACE.removeParams({ desa: null });
            props.isProvince && props.isProvince(null);
            props.setCity && props.setCity(null);
            props.setDistrict && props.setDistrict(null);
            props.setVillage && props.setVillage(null);
          },
          search: (value) => {
            getDataDebounce(value);
          },
        }}
        isLoading={PLACE.state.province?.status_GET === 'LOADING'}
        options={PLACE.state.province?.list}
        placeholder='Province'
        selected={{
          id: PLACE.state.params.provinsi?.id_provinsi,
          name: PLACE.state.params.provinsi?.nama_provinsi,
          code: PLACE.state.params.provinsi?.kode_provinsi,
        }}
        type='provinsi'
        validation={props.validation}
      />
    </Fragment>
  );
}

SearchProvince.defaultProps = {
  isProvince: undefined,
};

SearchProvince.propTypes = {
  isProvince: PropTypes.func,
  province: PropTypes.object,
  setCity: PropTypes.func,
  setDistrict: PropTypes.func,
  setVillage: PropTypes.func,
  validation: PropTypes.bool,
};
