import Resource from '../../../../api/resource';

export class AdvanceSearchService extends Resource {
  constructor() {
    super('places', '/api', 'api-poi', 'v2');
  }
  /**
   *
   * place service
   * @param {object} params - Params for get list place
   */

  getAll(query) {
    this.setAdditionUri('');
    const paramString = JSON.stringify(query.params);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }

  getProvince(query) {
    this.setAdditionUri('/province');
    const paramString = JSON.stringify(query?.params);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }

  getRegency(query) {
    this.setAdditionUri('/city');
    const paramString = JSON.stringify(query.params);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }
  getDistrict(query) {
    this.setAdditionUri('/district');
    const paramString = JSON.stringify(query.params);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }
  getVillage(query) {
    this.setAdditionUri('/village');
    const paramString = JSON.stringify(query.params);

    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }
  getGeoJson(query) {
    this.setAdditionUri('/advance-search');
    const paramString = JSON.stringify(query.params);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }
  get_coordinates(query) {
    this.setAdditionUri('/coordinates');
    const paramString = JSON.stringify(query);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }
  getSearchByKeyword(query) {
    this.setAdditionUri('/search');
    const paramString = JSON.stringify(query.params);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }
}
