import React from 'react';
import ErrorCross from '@bvt-assets/images/error-cross.png';
import './LinkExpired.scss';
import { useNavigate } from 'react-router';

export default function LinkExpired() {
  const navigate = useNavigate();

  return (
    <div className='feature-auth LinkExpired__wrapper'>
      <div className='feature-auth LinkExpired__image'><img src={ErrorCross}/></div>
      <div className='feature-auth LinkExpired__title'>This link has expired</div>
      <div className='feature-auth LinkExpired__paragraph'>
        <p>Please click “Forgot password” below to receive a new link by submitting your email.</p>
        <p>To avoid link expiration, use it within 5 minutes.</p>
      </div>
      <div className='feature-auth LinkExpired__paragraph muted'>
        If you don't see it in your inbox shortly,
        check your spam folder or contact our admin <span className='bold'>tech.support@bvarta.com.</span>
      </div>
      <div>
        <button
          className='feature-auth LinkExpired__button'
          onClick={() => navigate('/auth/forgot-password')}
        >
          Forgot password
        </button>
      </div>
    </div>
  );
}
