import _, { isNumber } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import './DatexTelcoStepOneThematic.scss';
import { BaseSearchCardDatex } from '@bvt-features/datex/component/BaseSearchCardDatex';
import { Skeleton } from 'antd';
import { DatexNoData } from '@bvt-features/datex/component/DatexNoData';
import { getIcon } from '@bvt-shared/utils/iconUtils';
import { Popover } from '@bvt-atoms';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

/**
 * @description For data thematic object
 * @typedef TThematicDataObjectCategory
 * @property {number} key
 * @property {string} label
 * @property {any} icon
 */

/**
 * @description For data poi object
 * @typedef TThematicDataObject
 * @property {TThematicDataObjectCategory} thematicCategory
 * @property {TThematicDataObjectCategory} subCategory
 */

/**
 * @param {object} props
 * @param {Array<TThematicDataObjectCategory>} props.categoryList
 * @param {TThematicDataObject} props.data
 * @param {boolean} props.isLoading
 * @param {(data:TThematicDataObject)=>void} props.onChange
 * @param {(key:string)=>void} props.onSearch
 * @param {(isValid:boolean)=>void} props.onValidOrInvalid
 *
 */

export const DatexTelcoStepOneThematic = (props) => {
  const { data, isLoading, onChange, onValidOrInvalid, categoryList } = props;

  const [state, setState] = useState({});
  const [filteredCategoryList, setFilteredCategoryList] = useState([]);
  const popoverContent = (
    <div className='DatexTelcoStepOneThematic container-popover'>
      <div className='DatexTelcoStepOneThematic arrow arrow---down' />
      <p className='DatexTelcoStepOneThematic container-popover-text'>
        Contact our sales to subscribe
      </p>
    </div>
  );

  useEffect(() => {
    if (Object.keys(state).length > 0) {
      onValidOrInvalid && onValidOrInvalid(true);
    } else {
      onValidOrInvalid && onValidOrInvalid(false);
    }
  }, [state]);

  useEffect(() => {
    if (isNumber(data?.thematicCategory?.key)) {
      setState({
        ...state,
        thematicCategory: data?.thematicCategory,
      });
    }
  }, [data]);

  useEffect(() => {
    setFilteredCategoryList(categoryList);
  }, []);

  const internalDebouncedSearchThematicCategory = useCallback((e) => {
    setFilteredCategoryList(
      categoryList.filter((v) =>
        v.label.toLocaleLowerCase().includes(e.toLocaleLowerCase())
      )
    );
  }, []);
  
  return (
    <React.Fragment>
      <BaseSearchCardDatex
        onSearch={(e) => internalDebouncedSearchThematicCategory(e)}
        placeholder='Search a specific thematic category'
      />
      <div className='DatexTelcoStepOneThematic__container scroll'>
        {isLoading
          ? Array.from([1, 2, 3, 4, 5, 6]).map((v) => (
            <Skeleton.Input
              active={true}
              block={false}
              key={v}
              loading={isLoading}
              paragraph={false}
              style={{
                height: '6rem',
                marginTop: '1rem',
                marginRight: '.5rem',
                width: '25%',
                minWidth: '6rem',
              }}
              type='image'
            />
          ))
          : null}
        {!isLoading && !filteredCategoryList?.length > 0 && (
          <DatexNoData title='Category name not found' />
        )}
        {_.map(filteredCategoryList || [], (v, i) => {
          return v.status ? (
            <div
              className={`DatexTelcoStepOneThematic__item ${
                state?.thematicCategory?.key === v?.key
                  ? 'DatexTelcoStepOneThematic__item--active'
                  : ''
              } ${v.status ? '' : 'disabled'}`}
              key={i}
              onClick={(e) => {
                if (v.status) {
                  e.stopPropagation();
                  onChange &&
                    onChange({
                      thematicCategory: v,
                    });
                }
              }}
            >
              <img alt='icon datex' src={getIcon('thematic', v?.icon)} />
              <span>{v?.label}</span>
            </div>
          ) : (
            <Popover
              className='DatexTelcoStepOneThematic popover-wrapper'
              content={popoverContent}
              header={false}
              key={i}
              placement='top'
              trigger='hover'
            >
              <div
                className={`DatexTelcoStepOneThematic__item ${
                  state?.thematicCategory?.key === v?.key
                    ? 'DatexTelcoStepOneThematic__item--active'
                    : ''
                } disabled`}
                key={i}
                onClick={(e) => {
                  if (v.status) {
                    e.stopPropagation();
                    onChange &&
                      onChange({
                        thematicCategory: v,
                      });
                  }
                }}
              >
                <img alt='icon datex' src={getIcon('thematic', v?.icon)} />
                <span>{v?.label}</span>
              </div>
            </Popover>
          );
        })}
      </div>
    </React.Fragment>
  );
};
