import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { MarkerAsyncAction } from './marker.asyncAction';

/**
 * Marker
 * @typedef {object} Marker
 * @property {number} marker_id
 * @property {string} name
 * @property {string} address
 * @property {number} id_desa
 * @property {number} id_kota
 * @property {string} latitude
 * @property {string} longitude
 * @property {string} siteName
 * @property {string} icon_color
 * @property {string} icon_type
 * @property {string} nama_desa
 * @property {string} nama_kota
 * @property {string} nama_kecamatan
 * @property {string} nama_provinsi
 * @property {number[]} coordinate
 * @property {number} id_provinsi
 * @property {number} id_kecamatan
 * @property {string} created_at
 * @property {string} updated_at
 * @property {number} created_by
 */

/**
 * @typedef {object} InitialState
 * @property {object} status
 * @property {'IDLE'|'SUCCESS'|'FAILED'|'LOADING'} status.LIST
 * @property {'IDLE'|'SUCCESS'|'FAILED'|'LOADING'} status.CREATE
 * @property {'IDLE'|'SUCCESS'|'FAILED'|'LOADING'} status.UPDATE
 * @property {'IDLE'|'SUCCESS'|'FAILED'|'LOADING'} status.DELETE
 * @property {{[x: number]: Marker}} entities
 * @property {number[]} ids
 * @property {string} message
 * @property {{nextPage:number, prePage:number, prevPage:number, total:number}} meta
 */

const adapter = createEntityAdapter({
  selectId: (marker) => marker.marker_id,
});

/**
 * @type {InitialState}
 */
const initialState = {
  entities: {},
  ids: [],
  status: {
    LIST: 'IDLE',
    CREATE: 'IDLE',
    UPDATE: 'IDLE',
    DELETE: 'IDLE',
  },
  message: '',
  meta: {},
};

const MarkerSlice = createSlice({
  name: 'LI/GENSET/MARKER',
  initialState,
  reducers: {
    addone: adapter.addOne,
    addMany: adapter.addMany,
    setOne: adapter.setOne,
    setMany: adapter.setMany,
    setAll: adapter.setAll,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    setMessage: (state, action) => {
      const { payload } = action;
      state.message = payload.message || '';
    },
    setStatus: (state, action) => {
      const { payload } = action;
      if (payload.status) {
        payload.status.map((v) => {
          state.status[v] = payload.type;
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(MarkerAsyncAction.getList.pending, (state) => {
        state.status.LIST = 'LOADING';
      })
      .addCase(MarkerAsyncAction.getList.rejected, (state, { error }) => {
        state.status.LIST = 'FAILED';
        state.message = error.message;
      })
      .addCase(MarkerAsyncAction.getList.fulfilled, (state, { payload }) => {
        state.status.LIST = 'SUCCESS';
        state.message = 'Successfully get markers';
        state.meta = payload.meta;
      });
    builder
      .addCase(MarkerAsyncAction.create.pending, (state) => {
        state.status.CREATE = 'LOADING';
      })
      .addCase(MarkerAsyncAction.create.rejected, (state, { error }) => {
        state.status.CREATE = 'FAILED';
        state.message = error.message;
      })
      .addCase(MarkerAsyncAction.create.fulfilled, (state) => {
        state.status.CREATE = 'SUCCESS';
        state.message = 'Successfully create marker';
      });
    builder
      .addCase(MarkerAsyncAction.update.pending, (state) => {
        state.status.UPDATE = 'LOADING';
      })
      .addCase(MarkerAsyncAction.update.rejected, (state, { error }) => {
        state.status.UPDATE = 'FAILED';
        state.message = error.message;
      })
      .addCase(MarkerAsyncAction.update.fulfilled, (state) => {
        state.status.UPDATE = 'SUCCESS';
        state.message = 'Successfully update marker';
      });
    builder
      .addCase(MarkerAsyncAction.delete.pending, (state) => {
        state.status.DELETE = 'LOADING';
      })
      .addCase(MarkerAsyncAction.delete.rejected, (state, { error }) => {
        state.status.DELETE = 'FAILED';
        state.message = error.message;
      })
      .addCase(MarkerAsyncAction.delete.fulfilled, (state) => {
        state.status.DELETE = 'SUCCESS';
        state.message = 'Successfully remove marker';
      });
  },
});

export const {
  reducer: MARKER_REDUCER,
  actions: MARKER_ACTION,
} = MarkerSlice;
