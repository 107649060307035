import { useAuth } from '@bvt-features/auth/hooks';
import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router';

export default function AuthenticatedGateContainer() {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/auth/logout') {
      auth.logout();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!auth.state.data.isAuthenticated && location.pathname === '/auth/logout') {
      navigate('/auth/login'); // Redirect to login after /users/logout fulfilled
    }
  }, [auth.state.data.isAuthenticated]);

  return auth.state.data.isAuthenticated &&
  auth.state.data.lokasiintelligenceAccess &&
    location.pathname !== '/auth/logout' ? (
      <Navigate to={'/app'} />
    ) : (
      <Outlet />
    );
}
