import { thousandSeparator } from '@bvt-features/datex/helper';
import React from 'react';

/**
 * @copyright Bhumi Varta Technology
 * @author Malik<malik.ibrahim@bvarta.com>
 * @param {Object} props
 * @param {String} props.total
 * @param {String} props.image
 * @param {String} props.classname
 * @param {String} props.title
 * @param {String} props.measurement
 */

export const CardDevice = (props) => {
  const { total, image, className, title, measurement } = props;

  return (
    <div className={`CarDatexTelcoDataDetail__counter ${className}`}>
      <div className='CarDatexTelcoDataDetail__counter-layer1'>
        <div className='CarDatexTelcoDataDetail__counter-layer1--left'>
          <span className='CarDatexTelcoDataDetail__counter-layer1--left-title'>
            {title}
          </span>
          <span className='CarDatexTelcoDataDetail__counter-layer1--left-devices'>
            <span className='CarDatexTelcoDataDetail__counter-layer1--left-devices-total'>
              {thousandSeparator(total)}
            </span>{' '}
            <span className='CarDatexTelcoDataDetail__counter-layer1--left-devices-measurement'>
              {measurement}
            </span>
          </span>
        </div>
        <div className='CarDatexTelcoDataDetail__counter-layer1--right'>
          {image && (
            <img
              alt='Illustration'
              className='CarDatexTelcoDataDetail__counter-layer1--right'
              src={image}
            />
          )}
        </div>
      </div>
      <div className='CarDatexTelcoDataDetail__counter-layer2' />
      <div className='CarDatexTelcoDataDetail__counter-layer3' />
    </div>
  );
};
