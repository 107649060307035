export const MAPP_TASK_ANALYZE_CONSTANT = {
  GET_LIST_START: 'LI/mapp/task-analyze/get-data/requested',
  GET_LIST_FAILED: 'LI/mapp/task-analyze/get-data/failed',
  GET_LIST_SUCCESS: 'LI/mapp/task-analyze/get-data/success',

  DELETE_ONE_START: 'LI/mapp/task-analyze/delete-one-data/requested',
  DELETE_ONE_FAILED: 'LI/mapp/task-analyze/delete-one-data/failed',
  DELETE_ONE_SUCCESS: 'LI/mapp/task-analyze/delete-one-data/success',

  CANCEL_ONE_START: 'LI/mapp/task-analyze/cancel-one-data/requested',
  CANCEL_ONE_FAILED: 'LI/mapp/task-analyze/cancel-one-data/failed',
  CANCEL_ONE_SUCCESS: 'LI/mapp/task-analyze/cancel-one-data/success',

  TOGGLE_POPUP: 'LI/mapp/task-analyze/popup/toggle',

  RESET_STATUS: 'LI/mapp/task-analyze/reset-status',
  RESET_ALL: 'LI/mapp/task-analyze/reset-all',
  UNAUTHORIZED: 'LI/AUTH/unauthorized',
};
