import React from 'react';

function IconGridAnalysis() {
  return (
    <div className='iconGridAnalysis'>
      <svg
        height='27'
        viewBox='0 0 27 27'
        width='27'
        xmlns='http://www.w3.org/2000/svg'
      >
        <defs>
          <linearGradient
            gradientUnits='objectBoundingBox'
            id='linear-gradient'
            x1='0.487'
            x2='0.938'
            y1='0.501'
            y2='0.501'
          >
            <stop offset='0' stopColor='#8cbcff' />
            <stop offset='1' stopColor='#598bff' />
          </linearGradient>
          <linearGradient
            gradientUnits='objectBoundingBox'
            id='linear-gradient-2'
            x1='0.354'
            x2='0.354'
            y1='0.388'
            y2='-0.027'
          >
            <stop offset='0' stopColor='#b8d6ff' />
            <stop offset='1' stopColor='#598bff' />
          </linearGradient>
          <linearGradient
            gradientUnits='objectBoundingBox'
            id='linear-gradient-3'
            x1='0.796'
            x2='0.796'
            y1='0.136'
            y2='1'
          >
            <stop offset='0' stopColor='#4880ff' />
            <stop offset='0.729' stopColor='#3d6cd5' />
            <stop offset='1' stopColor='#598bff' />
          </linearGradient>
          <linearGradient
            gradientUnits='objectBoundingBox'
            id='linear-gradient-4'
            x1='0.726'
            x2='-0.026'
            y1='0.201'
            y2='1.062'
          >
            <stop offset='0' stopColor='#f0f9ff' />
            <stop offset='0.522' stopColor='#c2ddff' />
            <stop offset='1' stopColor='#4880ff' />
          </linearGradient>
          <linearGradient
            id='linear-gradient-5'
            x1='0.755'
            x2='0.756'
            xlinkHref='#linear-gradient-3'
            y1='0.202'
            y2='1.05'
          />
        </defs>
        <g
          data-name='Group 6799'
          id='Group_6799'
          transform='translate(-0.26 -0.26)'
        >
          <rect
            data-name='Rectangle 4119'
            fill='#fff'
            height='27'
            id='Rectangle_4119'
            opacity='0'
            transform='translate(0.26 0.26)'
            width='27'
          />
          <g
            data-name='Group 6776'
            id='Group_6776'
            transform='translate(5.051 4.81)'
          >
            <path
              d='M1504.263,51.128h5.818V45.31h-5.818Zm-6.113,0h5.818V45.31h-5.818Z'
              data-name='Path 2010'
              fill='url(#linear-gradient)'
              fillRule='evenodd'
              id='Path_2010'
              transform='translate(-1498.15 -45.31)'
            />
            <path
              d='M1557.73,87.137h5.818V81.32h-5.818Zm0-6.13h5.818V75.19h-5.818Z'
              data-name='Path 2011'
              fill='url(#linear-gradient-2)'
              fillRule='evenodd'
              id='Path_2011'
              transform='translate(-1545.503 -69.058)'
            />
            <path
              d='M1504.263,87.137h5.818V81.32h-5.818Zm-6.113-6.13h5.818V75.19h-5.818Zm6.113,0h5.818V75.19h-5.818Z'
              data-name='Path 2012'
              fill='url(#linear-gradient-3)'
              fillRule='evenodd'
              id='Path_2012'
              transform='translate(-1498.15 -69.058)'
            />
          </g>
          <g
            data-name='Group 6777'
            id='Group_6777'
            transform='translate(15.189 0.851)'
          >
            <path
              d='M1548.343,26.28h7.415a2.039,2.039,0,0,1,2.033,2.033v7.415a2.039,2.039,0,0,1-2.033,2.033h-3.707l-4.221,1.471c-1,.347-1.519.315-1.519-.838V28.313a2.039,2.039,0,0,1,2.033-2.033Z'
              data-name='Path 2013'
              fill='url(#linear-gradient-4)'
              fillRule='evenodd'
              id='Path_2013'
              transform='translate(-1546.31 -26.28)'
            />
            <path
              d='M1565.8,35.5h2.033v2.033H1565.8Zm0,3.366h2.033v3.647H1565.8Z'
              data-name='Path 2014'
              fill='url(#linear-gradient-5)'
              fillRule='evenodd'
              id='Path_2014'
              transform='translate(-1561.077 -33.266)'
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default IconGridAnalysis;
