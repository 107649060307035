import { MainModuleService } from '../service/mainModuleService';
import { MAIN_MODULE_CONSTANT } from './MAIN_MODULE_CONSTANT';

const mainModuleService = new MainModuleService();

export const MAIN_MODULE_ACTION = {
  detail: (slug) => {
    return async (dispatch) => {
      dispatch({
        type: MAIN_MODULE_CONSTANT.DETAIL_START,
        payload: { status_GET: 'LOADING' },
      });
      try {
        const detailData = await mainModuleService.detail(slug);
        dispatch({
          type: MAIN_MODULE_CONSTANT.DETAIL_SUCCESS,
          payload: {
            status_GET: 'SUCCESS',
            message: detailData.message,
            data: detailData.result,
          },
        });
      } catch (error) {
        dispatch({
          type: MAIN_MODULE_CONSTANT.DETAIL_FAILED,
          payload: {
            status_GET: 'FAILED',
            message: error.response.data.message,
          },
        });
      }
    };
  },
};
