export const SIPRO_CONSTANT = {
  SET_STEP: 'LI/MAPANA/SIPRO/set-step',
  SET_STEP_ORDER: 'LI/MAPANA/SIPRO/set-step-order',
  SET_LAST_STEP: 'LI/MAPANA/SIPRO/set-last-step',

  SAVE_CURRENT_STEP_REQUESTED: 'LI/MAPANA/SIPRO/save-current-step/requested',
  SAVE_CURRENT_STEP_SUCCESS: 'LI/MAPANA/SIPRO/save-current-step/success',
  SAVE_CURRENT_STEP_FAILED: 'LI/MAPANA/SIPRO/save-current-step/failed',

  SAVE_PROJECT_REQUESTED: 'LI/MAPANA/SIPRO/save-project/requested',
  SAVE_PROJECT_SUCCESS: 'LI/MAPANA/SIPRO/save-project/success',
  SAVE_PROJECT_FAILED: 'LI/MAPANA/SIPRO/save-project/failed',

  UPDATE_PROJECT_REQUESTED: 'LI/MAPANA/SIPRO/updateproject/requested',
  UPDATE_PROJECT_SUCCESS: 'LI/MAPANA/SIPRO/updateproject/success',
  UPDATE_PROJECT_FAILED: 'LI/MAPANA/SIPRO/updateproject/failed',

  DO_ANALYZE_REQUESTED: 'LI/MAPANA/SIPRO/do-analyze/requested',
  DO_ANALYZE_SUCCESS: 'LI/MAPANA/SIPRO/do-analyze/success',
  DO_ANALYZE_FAILED: 'LI/MAPANA/SIPRO/do-analyze/failed',

  DO_ANALYZE_MANDALA_REQUESTED: 'LI/MAPANA/SIPRO/do-analyze-mandala/requested',
  DO_ANALYZE_MANDALA_SUCCESS: 'LI/MAPANA/SIPRO/do-analyze-mandala/success',
  DO_ANALYZE_MANDALA_FAILED: 'LI/MAPANA/SIPRO/do-analyze-mandala/failed',

  GET_AREA_COLLECTIONS_REQUESTED: 'LI/MAPANA/SIPRO/area-collection/requested',
  GET_AREA_COLLECTIONS_SUCCESS: 'LI/MAPANA/SIPRO/area-collection/success',
  GET_AREA_COLLECTIONS_FAILED: 'LI/MAPANA/SIPRO/area-collection/failed',

  GET_POI_COLLECTIONS_REQUESTED: 'LI/MAPANA/SIPRO/poi-collection/requested',
  GET_POI_COLLECTIONS_SUCCESS: 'LI/MAPANA/SIPRO/poi-collection/success',
  GET_POI_COLLECTIONS_FAILED: 'LI/MAPANA/SIPRO/poi-collection/failed',

  GET_SITE_DETAIL_REQUESTED: 'LI/MAPANA/SIPRO/detail/requested',
  GET_SITE_DETAIL_SUCCESS: 'LI/MAPANA/SIPRO/detail/success',
  GET_SITE_DETAIL_FAILED: 'LI/MAPANA/SIPRO/detail/failed',

  FILTERED_AREA: 'LI/MAPANA/SIPRO/area-collection/filter',
  FILTERED_AREA_RIGHT: 'LI/MAPANA/SIPRO/area-collection/filter-right',

  RESET_STATUS: 'LI/MAPANA/SIPRO/reset-status',
  RESET_ALL: 'LI/MAPANA/SIPRO/reset-all',

  SET_IS_SHOWN_ON_MAP: 'LI/MAPANA/SIPRO/is-shown-on-map',
  RESET_COLLECTION: 'LI/MAPANA/SIPRO/reset-collection',
};
