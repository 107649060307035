import Resource from '../../../../api/resource';

export class MappProjectService extends Resource {
  constructor() {
    super('project', '/api', 'api-poi', 'v2');
  }

  crate(payload) {
    return this.create(payload);
  }

  detail(id) {
    this.setAdditionUri('');
    return this.get(id);
  }

  rename(id, payload) {
    this.setAdditionUri('/rename');
    return this.patch(id, payload);
  }
  /**
   * @param {object} param
   * @param {number | string} param.projectId
   * @param {string} param.base64
   * @param {'pdf' | 'csv' | "xlsx"} param.type
   */
  generateReport(param = {}){
    this.setAdditionUri('/upload');
    this.uri = 'report';
    let returnValue = this.create(param);
    this.uri = 'project';
    return returnValue;
  }

  getAll(query) {
    const paramString = JSON.stringify(query);
    const params = encodeURIComponent(paramString);
    if(paramString){
      return this.list({ params });
    }
    return this.list({});
  }
}
