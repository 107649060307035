import PropTypes from 'prop-types';

const DynamicIconDefault = {
  className: '',
  height: '',
  icon: '',
  width: '',
  color: '',
};

const DynamicIconType = {
  className: PropTypes.string,
  height: PropTypes.string,
  icon: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string
};

export { DynamicIconType, DynamicIconDefault };
