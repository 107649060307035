import React from 'react';
import { Navigate } from 'react-router';
import { GA_Utils } from '../../utils/GA-Utils/GA-Utils';
export class ErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log(error);
    GA_Utils.sendError(error,errorInfo?.componentStack);
  }

  render() {
    if (this.state.hasError) {
      return <Navigate replace={true} to={'/500'} />;
    }

    return this.props.children;
  }
}
