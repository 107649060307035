import { AuthGateContainer } from '@bvt-features/auth/container';
import SearchResultContainer from '@bvt-features/search/container/SearchResultContainer';
import React from 'react';

/**@type {import('react-router').RouteObject} */
export const RoutesSearch = {
  path: 'search',
  element: (
    <React.Fragment>
      <AuthGateContainer />
    </React.Fragment>
  ),
  children: [
    {
      index: true,
      element: <SearchResultContainer />,
    },
  ],
};
