import { LEGACY_AUTH_CONSTANT } from '@bvt-features/auth/redux';
import _ from 'lodash';
import { SIPRO_CONSTANT } from './SIPRO_CONSTANT';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta <renta.yustika@bvarta.com>
 */

const initialState = {
  status_GET: 'IDLE',
  status_DETAIL: 'IDLE',
  status_GET_AREA: 'IDLE',
  status_GET_POI: 'IDLE',
  status_POST: 'IDLE',
  status_PUT: 'IDLE',
  status_DELETE: 'IDLE',
  message: '',
  data: {},
  formSaveProject: {},
  step: 0,
  step_order: 0,
  area_collection: [],
  poi_collection: [],
  filtered_area_collection: {},
  filtered_area_collection_right: {},
  IS_SHOWN_ON_MAP: false,
  last_step: 0,
};

export function SIPRO_REDUCER(state = initialState, action) {
  let returnData = state;
  Object.values(SIPRO_CONSTANT).map((ctx) => {
    if (action.type === SIPRO_CONSTANT.RESET_STATUS) {
      returnData = {
        ...returnData,
        status_GET: 'IDLE',
        status_DETAIL: 'IDLE',
        status_GET_AREA: 'IDLE',
        status_GET_POI: 'IDLE',
        status_POST: 'IDLE',
        status_PUT: 'IDLE',
        status_DELETE: 'IDLE',
        message: ' ',
      };
    } else if (action.type === SIPRO_CONSTANT.FILTERED_AREA) {
      const tempArea = _.filter(
        state.area_collection,
        (v) =>
          v.site_name === action.payload.marker &&
          v.radius === action.payload.distance
      )[0];
      const tempArrFilteredArea = [tempArea].map((v) => {
        return {
          type: v?.features[0]?.type,
          geometry: {
            type: v?.features[0]?.geometry?.type,
            coordinates: v?.features[0]?.geometry?.coordinates,
          },
          properties: v?.features[0]?.properties,
        };
      });
      returnData = {
        ...returnData,
        filtered_area_collection: tempArrFilteredArea[0],
      };
    }  else if (action.type === SIPRO_CONSTANT.FILTERED_AREA_RIGHT) {
      const tempArea = _.filter(
        state.area_collection,
        (v) =>
          v.site_name === action.payload.marker &&
          v.radius === action.payload.distance
      )[0];
      const tempArrFilteredArea = [tempArea].map((v) => {
        return {
          type: v?.features[0]?.type,
          geometry: {
            type: v?.features[0]?.geometry?.type,
            coordinates: v?.features[0]?.geometry?.coordinates,
          },
          properties: v?.features[0]?.properties,
        };
      });
      returnData = {
        ...returnData,
        filtered_area_collection_right: tempArrFilteredArea[0],
      };
    } else if (action.type === SIPRO_CONSTANT.RESET_COLLECTION) {
      returnData = {
        ...initialState,
        area_collection: [],
        poi_collection: [],
        filtered_area_collection: {},
      };
    } else if (action.type === SIPRO_CONSTANT.RESET_ALL) {
      returnData = { ...initialState };
    } else if (action.type === LEGACY_AUTH_CONSTANT.UNAUTHORIZED) {
      returnData = { ...initialState };
    } else if (ctx === action.type && !ctx.includes('[NO_STATE_UPDATE]')) {
      returnData = { ...returnData, ...action.payload };
    }
    return null;
  });

  return returnData;
}
