import * as React from 'react';
const IconLock = (props) => (
  <svg
    fill='none'
    height={16}
    viewBox='0 0 16 16'
    width={16}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      clipRule='evenodd'
      d='M8 8C8.36731 8 8.72637 8.10264 9.03177 8.29493C9.33718 8.48722 9.57521 8.76053 9.71578 9.0803C9.85634 9.40007 9.89312 9.75194 9.82146 10.0914C9.7498 10.4309 9.57292 10.7427 9.3132 10.9874C9.05347 11.2322 8.72256 11.3989 8.36231 11.4664C8.00206 11.5339 7.62865 11.4992 7.2893 11.3668C6.94995 11.2343 6.65991 11.01 6.45584 10.7222C6.25178 10.4345 6.14286 10.0961 6.14286 9.75C6.14433 9.2863 6.34046 8.84198 6.68843 8.51409C7.03639 8.1862 7.50791 8.00138 8 8ZM8 2.75C7.50791 2.75138 7.03639 2.9362 6.68843 3.26409C6.34046 3.59198 6.14433 4.0363 6.14286 4.5H9.85714C9.85567 4.0363 9.65954 3.59198 9.31157 3.26409C8.96361 2.9362 8.49209 2.75138 8 2.75ZM12.6429 15H3.35714C2.86505 14.9986 2.39353 14.8138 2.04557 14.4859C1.6976 14.158 1.50147 13.7137 1.5 13.25V6.25C1.50147 5.7863 1.6976 5.34198 2.04557 5.01409C2.39353 4.6862 2.86505 4.50138 3.35714 4.5H4.28571C4.28571 3.57174 4.67704 2.6815 5.3736 2.02513C6.07017 1.36875 7.01491 1 8 1C8.98509 1 9.92983 1.36875 10.6264 2.02513C11.323 2.6815 11.7143 3.57174 11.7143 4.5H12.6429C13.1349 4.50138 13.6065 4.6862 13.9544 5.01409C14.3024 5.34198 14.4985 5.7863 14.5 6.25V13.25C14.4985 13.7137 14.3024 14.158 13.9544 14.4859C13.6065 14.8138 13.1349 14.9986 12.6429 15Z'
      fill='#CED4DA'
      fillRule='evenodd'
    />
  </svg>
);
export { IconLock };
