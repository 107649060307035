export const TIME_TYPE = [
  {
    value: '',
    label: 'None',
  },
  {
    value: 'popular',
    label: 'Popular Time',
  },
  {
    value: 'specific',
    label: 'Specific Time',
  },
];
