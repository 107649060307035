import React, {
  Fragment,
  useLayoutEffect,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import ReactDOM from 'react-dom';
import { getPosition } from './PopoverHelpers';
import PropTypes from 'prop-types';

/**
 * @version 1.1
 * @copyright Bhumi Varta Technology
 * @memberof atoms
 * @author Imam <imam.rozali@bvarta.com>
 * @param {Object} props
 * @param {string} props.className className of the component
 * @param {'top' | 'top-right' | 'top-left' | 'left' | 'left-top' | 'left-bottom | 'bottom' | 'bottom-right' | 'bottom-left'| 'right' | 'right-top' | 'right-bottom} props.placement placement position align of the component
 * @param {JSX.Element} props.children children element can be filled with any react element
 * @param {'hover'|'click'} props.trigger trigger of the component
 * @param {string} props.title if title string filled, the header become half
 * @param {bool} props.visibility show or hide
 * @param {bool} props.header show or hide
 * @param {visibility} props.visibility show or hide
 * @param {Object} props.content Object handler
 */
export default function Popover(props) {
  const {
    children,
    className,
    content,
    trigger,
    placement,
    title,
    visibility,
    getContainer,
    header,
  } = props;
  const [visible, setVisible] = useState(true);
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const [isIndex, setIsIndex] = useState(99);
  const contentEl = useRef();
  let setVisibility = (visible) => {
    setVisible(visible);
  };

  useEffect(() => {
    if (typeof visibility !== 'undefined') {
      setVisible(visibility);
    }
  }, [visibility]);

  const getMaxZIndex = () => {
    return Math.max(
      ...Array.from(document.querySelectorAll('body *'), (el) =>
        parseFloat(window.getComputedStyle(el).zIndex)
      ).filter((zIndex) => !Number.isNaN(zIndex)),
      0
    );
  };
  const toggleVisibility = () => setVisible((prevState) => !prevState.visible);
  const handleContentEnter = () => {
    trigger === 'hover' && setVisibility(true);
  };
  const handleContentLeave = () => {
    trigger === 'hover' && setVisibility(false);
  };
  const setPositions = (target) => {
    const contentReact = contentEl.current.getBoundingClientRect();
    const targetRect = target.getBoundingClientRect();
    let position = getPosition(placement, contentReact, targetRect);
    setTop(position.top);
    setLeft(position.left);
  };
  const getChildProps = (childProps) => {
    const props = {
      ...childProps,
    };
    const handleMouseEnter = (e) => {
      if (childProps.onMouseEnter) {
        childProps.onMouseEnter(e);
      }
      setIsIndex(getMaxZIndex() + 1);
      setVisibility(true);
      setPositions(e.currentTarget);
    };

    const handleMouseLeave = (e) => {
      if (childProps.onMouseLeave) {
        childProps.onMouseLeave(e);
      }
      setVisibility(false);
    };
    const handleClick = (e) => {
      if (childProps.onClick) {
        childProps.onClick(e);
      }
      setIsIndex(getMaxZIndex() + 1);
      toggleVisibility();
      setPositions(e.currentTarget);
    };

    if (trigger === 'hover') {
      props.onMouseEnter = handleMouseEnter;
      props.onMouseLeave = handleMouseLeave;
    }
    trigger === 'click' && (props.onClick = handleClick);

    return props;
  };

  const handleScroll = () => {
    setVisible(false);
  };

  const handleResize = useCallback(() => {
    setVisible(false);
  }, []);

  useLayoutEffect(() => {
    if (document.querySelector('.main__children')) {
      document
        .querySelector('.main__children')
        .addEventListener('scroll', handleScroll);
    }
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  });

  const getContentStyles = () => {
    return {
      position: 'fixed',
      visibility: visible ? 'visible' : 'hidden',
      opacity: visible ? 1 : 0,
      top: `${top}px`,
      left: `${left}px`,
      zIndex: isIndex,
    };
  };
  return (
    <Fragment>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return <child.type {...getChildProps(child.props)} />;
        }
        return child;
      })}
      {ReactDOM.createPortal(
        <div
          className={`popover-container ${className}`}
          onClick={() => setIsIndex(isIndex + 1)}
          onMouseEnter={handleContentEnter}
          onMouseLeave={handleContentLeave}
          ref={contentEl}
          style={getContentStyles()}
        >
          {header && (
            <Fragment>
              {title !== '' ? (
                <div className='popover-container__title'>
                  {title}
                  <button
                    className='popover-container__button'
                    onClick={() => setVisible(false)}
                  >
                    <svg
                      height='12.263'
                      viewBox='0 0 12.263 12.263'
                      width='12.263'
                    >
                      <path
                        d='M3.153,7.489V5.518H1.183a1.182,1.182,0,1,1,0-2.364h1.97V1.183a1.183,1.183,0,1,1,2.365,0V3.154H7.489a1.182,1.182,0,1,1,0,2.364H5.518V7.489a1.183,1.183,0,1,1-2.365,0Z'
                        data-name='Union 24'
                        fill='#616477'
                        id='Union_24'
                        transform='translate(6.132) rotate(45)'
                      />
                    </svg>
                  </button>
                </div>
              ) : (
                <div className='popover-container__title'>
                  <div style={{ content: '' }} />
                  <button
                    className='popover-container__button'
                    onClick={() => setVisible(false)}
                  >
                    <svg
                      height='12.263'
                      viewBox='0 0 12.263 12.263'
                      width='12.263'
                    >
                      <path
                        d='M3.153,7.489V5.518H1.183a1.182,1.182,0,1,1,0-2.364h1.97V1.183a1.183,1.183,0,1,1,2.365,0V3.154H7.489a1.182,1.182,0,1,1,0,2.364H5.518V7.489a1.183,1.183,0,1,1-2.365,0Z'
                        data-name='Union 24'
                        fill='#616477'
                        id='Union_24'
                        transform='translate(6.132) rotate(45)'
                      />
                    </svg>
                  </button>
                </div>
              )}
            </Fragment>
          )}
          {content}
        </div>,
        getContainer()
      )}
    </Fragment>
  );
}

Popover.defaultProps = {
  children: null,
  className: '',
  content: `${(<div>popover</div>)}`,
  getContainer: () => document.getElementById('bvt-portal'),
  header: true,
  placement: 'bottom',
  title: '',
  trigger: 'hover',
  visibility: false,
};

Popover.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  content: PropTypes.node,
  getContainer: PropTypes.func,
  header: PropTypes.bool,
  placement: PropTypes.string,
  title: PropTypes.string,
  trigger: PropTypes.string,
  visibility: PropTypes.bool,
};
