import { APP_STORE_PREFIX_CONSTANT } from '@bvt-shared/constant/APP_PREFIX_CONSTANT';
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
  layout: {
    baseMapControl: false,
  },
  zoom: {
    level: 0,
    allowZoomIn: true,
    allowZoomOut: true,
  },
  selectedBaseMap: 'grayMapSetting',
  baseMapList: [
    {
      key: 'grayMapSetting',
      img: '/assets/images/lightMap.png',
      title: 'Gray Map (Default)',
      tile: 'https://api.mapbox.com/styles/v1/mapbox/light-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZ2VuamVyZG90a29tODUiLCJhIjoiY2s4cG95ang4MDZxZTNscHFhc2EydzhldCJ9.3OgXU8XIpaeTpEeKPAHzxg',
    },
    {
      key: 'nightMapSetting',
      img: '/assets/images/darkMap.png',
      title: 'Night',
      tile: 'https://api.mapbox.com/styles/v1/mapbox/dark-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZ2VuamVyZG90a29tODUiLCJhIjoiY2s4cG95ang4MDZxZTNscHFhc2EydzhldCJ9.3OgXU8XIpaeTpEeKPAHzxg',
    },
    {
      key: 'lightMapSetting',
      img: '/assets/images/brightMap.png',
      title: 'Light',
      tile: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    },
    {
      key: 'streetMapSetting',
      img: '/assets/images/streetsMap.png',
      title: 'Steets',
      tile: 'https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoicmV6YXN5YXJpZjEwIiwiYSI6ImNsZmx0bnR1ZzA1Y3UzeHJ2ZmVpMzE1dWoifQ._p1B05y5HTFVTZZNJcW5aA',
    },
    {
      key: 'satelitMapSetting',
      img: '/assets/images/satelitteHybridMap.png',
      title: 'Satellite Hybrid',
      tile: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    },
  ],
};

export const mainmapSlice = createSlice({
  name: APP_STORE_PREFIX_CONSTANT + '/mainmap',
  initialState,
  reducers: {
    toggleBaseMapControl: (state, action) => {
      if (_.isBoolean(action?.payload?.isOpen)) {
        state.layout.baseMapControl = action?.payload?.isOpen || false;
      } else {
        state.layout.baseMapControl = !state.layout.baseMapControl;
      }
    },
    changeBaseMap: (state, action) => {
      const { payload } = action;
      if (payload.key) {
        state.selectedBaseMap = payload.key;
      }
    },
  },
  extraReducers: (builder) => builder,
});

export const { reducer: mainmapReducer, actions: mainmapAction } = mainmapSlice;
