import React, { useEffect, useState } from 'react';
import { Radio, Skeleton } from 'antd';
import { DatexNoData } from '@bvt-features/datex/component/DatexNoData';
import './BaseOptionListItemThematic.scss';
import { Popover } from '@bvt-atoms';
import { generateUUID } from '@bvt-utils';

/**
 * @company PT Bhumi Varta Technology
 * @author Malik<malik.ibrahim@bvarta.com>
 */

/**
 * @typedef IBaseKeyValueIcon
 * @type {Object}
 * @property {string|number} key
 * @property {string} value
 * @property {string} icon
 */

/**
 * @typedef IBaseKeyValue
 * @type {Object}
 * @property {string|number} key
 * @property {string} value
 */

/**
 *
 * @param {Object} props
 * @param {Array<IBaseKeyValueIcon>} props.listItem
 * @param {(valid:Boolean)=>void} props.onValidOrInvalid
 * @param {(data:IBaseKeyValue)=>void} props.onChange
 * @param {IBaseKeyValue} props.selectedItem
 * @param {boolean} props.isLoading
 * @param {boolean} props.validation
 * @param {string} props.type
 *
 */

export const BaseOptionListItemThematic = (props) => {
  const {
    selectedItem,
    listItem,
    isLoading,
    onChange,
    onValidOrInvalid,
    validation,
    type,
  } = props;
  const [region, setRegion] = useState();
  const [typeData, setTypeData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(isLoading);
    return () => {
      setLoading(true);
    };
  }, [isLoading]);

  useEffect(() => {
    if (selectedItem && selectedItem.key) {
      onValidOrInvalid && onValidOrInvalid(true);
    } else {
      onValidOrInvalid && onValidOrInvalid(false);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (region && typeData) {
      const result = {
        label: `${region.label} - ${typeData.label}`,
        key: region.key.filter(v => typeData.key.includes(v))[0],
      };
      onChange && onChange(result);
    }
  }, [region, typeData]);

  const internalOnChange = (data, key) => {
    if (key === 'Region') {
      setRegion(data);
    }
    if (key === 'Type') {
      setTypeData(data);
    }
  };

  function compareStatus(a, b) {
    const statusA = a?.status || '';
    const statusB = b?.status || '';

    return statusB.localeCompare(statusA);
  }

  const popoverContent = (
    <div className='BaseOptionListItemThematic container-popover'>
      <div className='BaseOptionListItemThematic arrow arrow---down' />
      <p className='BaseOptionListItemThematic container-popover-text'>
        Contact our sales to subscribe
      </p>
    </div>
  );

  return (
    <div className='BaseOptionListItemThematic'>
      {loading
        ? Array.from([1, 2, 3, 4, 5]).map((v) => (
          <Skeleton.Input
            active={true}
            block={true}
            key={v}
            loading={loading}
            paragraph={false}
            style={{ height: '2rem', marginTop: '1rem' }}
            type='image'
          />
        ))
        : null}
      {(!loading && listItem?.length === 0) && (
        <DatexNoData title='Subcategory name not found' />
      )}
      {!loading && listItem?.length > 0 && (
        <React.Fragment>
          {type === 'grdp' ? (
            listItem?.map((v) => (
              <Radio.Group
                className='BaseOptionListItemThematic__radio-group'
                key={generateUUID()}
                value={v.title === 'Region' && region?.value || v.title === 'Type' && typeData?.value}
              >
                <div
                  className='BaseOptionListItemThematic__radio-group--space'
                  direction='vertical'
                >
                  <div className='BaseOptionListItemThematic__group--wrapper'>
                    <span className='BaseOptionListItemThematic__group--title'>
                      {v.title}
                    </span>
                    {v.children.map((w) => (
                      <div
                        className='BaseOptionListItemThematic__radio--wrapper'
                        key={generateUUID()}
                      >
                        <Radio
                          className={`BaseOptionListItemThematic__radio--item ${
                            region?.value === w.value ? 'checked' : ''
                          } ${typeData?.value === w.value ? 'checked' : ''}`}
                          onChange={() => {
                            internalOnChange(w, v?.title);
                          }}
                          value={w.value}
                        >
                          <div className='BaseOptionListItemThematic__radio-wrapper'>
                            <div className='BaseOptionListItemThematic__radio-wrapper--left'>
                              <div className='BaseOptionListItemThematic__radio-item--title'>
                                {w?.label}
                              </div>
                            </div>
                          </div>
                        </Radio>
                      </div>
                    ))}
                  </div>
                </div>
              </Radio.Group>
            ))
          ) : (
            <Radio.Group
              className='BaseOptionListItemThematic__radio-group'
              value={selectedItem?.key}
            >
              <div
                className='BaseOptionListItemThematic__radio-group--space'
                direction='vertical'
              >
                {listItem?.toSorted(compareStatus)?.map((w) => {
                  return validation && !w.status ? (
                    <Popover
                      className='BaseOptionListItemThematic popover-wrapper'
                      content={popoverContent}
                      header={false}
                      key={generateUUID()}
                      placement='top-left'
                      trigger='hover'
                    >
                      <div
                        className='BaseOptionListItemThematic__radio--wrapper'
                        key={generateUUID()}
                      >
                        <Radio
                          className={`BaseOptionListItemThematic__radio--item ${
                            selectedItem?.key === w?.key ? 'checked' : ''
                          }`}
                          disabled
                          onChange={() => {
                            onChange && onChange(w);
                          }}
                          value={w?.key}
                        >
                          <div className='BaseOptionListItemThematic__radio-wrapper'>
                            <div className='BaseOptionListItemThematic__radio-wrapper--left'>
                              <div className='BaseOptionListItemThematic__radio-item--title'>
                                {w?.label}
                              </div>
                            </div>
                          </div>
                        </Radio>
                      </div>
                    </Popover>
                  ) : (
                    <div
                      className='BaseOptionListItemThematic__radio--wrapper'
                      key={generateUUID()}
                    >
                      <Radio
                        className={`BaseOptionListItemThematic__radio--item ${
                          selectedItem?.key === w?.key ? 'checked' : ''
                        }`}
                        onChange={() => {
                          onChange && onChange(w);
                        }}
                        value={w?.key}
                      >
                        <div className='BaseOptionListItemThematic__radio-wrapper'>
                          <div className='BaseOptionListItemThematic__radio-wrapper--left'>
                            <div className='BaseOptionListItemThematic__radio-item--title'>
                              {w?.label}
                            </div>
                          </div>
                        </div>
                      </Radio>
                    </div>
                  );
                })}
              </div>
            </Radio.Group>
          )}
        </React.Fragment>
      )}
    </div>
  );
};
