import React, { useEffect, useRef, useState } from 'react';

import { IconBaseMap } from '@bvt-assets/icon/icon-baseMap';
import { IconMinus } from '@bvt-assets/icon/icon-minus';
import { IconPlus } from '@bvt-assets/icon/icon-plus';
import { useMainMap } from '../../hook/useMainMap';
import { MainMapControlComponent } from '../../component/MainMapControl.component';

/**
 * @author Randa <m.randa@bvarta.com>
 * @copyright PT Bhumi Varta Technology
 * @description Main Map Control for top right
 */
export const MainMapControlContainer = () => {
  const mainMap = useMainMap();
  const divRef = useRef(null);
  const [state, setState] = useState({
    zoomIn: true,
    zoomOut: true,
  });

  const internalHandleClick = (val) => {
    switch (val) {
      case 'map-zoom-in':
        mainMap.mapInstance.zoomIn(1);
        break;
      case 'map-zoom-out':
        mainMap.mapInstance.zoomOut(1);
        break;
      case 'toggle-basemap':
        mainMap.toggleBaseMapControl({ isOpen: true });
        break;
      default:
        break;
    }
  };

  const zoomListener = () => {
    const zoomLevel = mainMap.mapInstance.getZoom();

    if (zoomLevel > 17) {
      setState({ ...state, zoomIn: false });
    } else if (zoomLevel < 6) {
      setState({ ...state, zoomOut: false });
    } else {
      setState({ zoomIn: true, zoomOut: true });
    }
  };

  useEffect(() => {
    zoomListener();

    // Disable event propagation and bubling from leaflet container
    mainMap.disableMapEvent(divRef.current || undefined);

    // Add Listener to zoom
    mainMap.mapInstance.addEventListener('zoom', zoomListener);

    return () => {
      // Remove Listener from zoom
      mainMap.mapInstance.removeEventListener('zoom', zoomListener);
    };
  }, []);
  return (
    <React.Fragment>
      <MainMapControlComponent
        data={[
          [
            {
              children: <IconPlus height={14} width={14} />,
              key: 'map-zoom-in',
              disabled: !state.zoomIn,
              tooltip: 'Zoom In',
            },
            {
              children: <IconMinus height={14} width={14} />,
              key: 'map-zoom-out',
              disabled: !state.zoomOut,
              tooltip: 'Zoom Out',
            },
          ],
          [
            {
              children: <IconBaseMap height={14} width={14} />,
              key: 'toggle-basemap',
              tooltip: 'Base Map',
              disabled: false,
            },
          ],
        ]}
        onClick={internalHandleClick}
        ref={divRef}
      />
    </React.Fragment>
  );
};
