export const getPosition = (placement, elRect, targetRect) => {
  switch (placement) {
    case 'top-right':
      return getTopRightPosition(elRect, targetRect);
    case 'top':
      return getTopPosition(elRect, targetRect);
    case 'top-left':
      return getTopLeftPosition(elRect, targetRect);
    case 'right-top':
      return getRightTopPosition(elRect, targetRect);
    case 'right':
      return getRightPosition(elRect, targetRect);
    case 'right-bottom':
      return getRightBottomPosition(elRect, targetRect);
    case 'left-top':
      return getLeftTopPosition(elRect, targetRect);
    case 'left':
      return getLeftPosition(elRect, targetRect);
    case 'left-bottom':
      return getLeftBottomPosition(elRect, targetRect);
    case 'bottom-right':
      return getBottomRightPosition(elRect, targetRect);
    case 'bottom':
      return getBottomPosition(elRect, targetRect);
    case 'bottom-left':
      return getBottomLeftPosition(elRect, targetRect);
    default:
      return getBottomPosition(elRect, targetRect);
  }
};

const getTopLeftPosition = (elRect, targetRect) => {
  return {
    left: targetRect.left,
    top: targetRect.top - elRect.height,
  };
};
const getTopPosition = (elRect, targetRect) => {
  return {
    left: targetRect.left + targetRect.width / 2 - elRect.width / 2,
    top: targetRect.top - elRect.height,
  };
};
const getTopRightPosition = (elRect, targetRect) => {
  return {
    left: targetRect.left + targetRect.width - elRect.width,
    top: targetRect.top - elRect.height,
  };
};
const getRightTopPosition = (elRect, targetRect) => {
  return {
    left: targetRect.left + targetRect.width,
    top: targetRect.top - targetRect.height + elRect.height / 2.6,
  };
};
const getRightPosition = (elRect, targetRect) => {
  return {
    left: targetRect.left + targetRect.width,
    top: targetRect.top + targetRect.height / 2 - elRect.height / 2,
  };
};
const getRightBottomPosition = (elRect, targetRect) => {
  return {
    left: targetRect.left + targetRect.width,
    top: targetRect.top + targetRect.height - elRect.height,
  };
};
const getBottomRightPosition = (elRect, targetRect) => {
  return {
    left: targetRect.left - elRect.width + targetRect.width,
    top: targetRect.top + targetRect.height,
  };
};
const getBottomPosition = (elRect, targetRect) => {
  return {
    left: targetRect.left + targetRect.width / 2 - elRect.width / 2,
    top: targetRect.top + targetRect.height,
  };
};
const getBottomLeftPosition = (elRect, targetRect) => {
  return {
    left: targetRect.left,
    top: targetRect.top + targetRect.height,
  };
};
const getLeftTopPosition = (elRect, targetRect) => {
  return {
    left: targetRect.left - elRect.width,
    top: targetRect.top - targetRect.height + elRect.height / 2.6,
  };
};
const getLeftPosition = (elRect, targetRect) => {
  return {
    left: targetRect.left - elRect.width,
    top: targetRect.top + targetRect.height / 2 - elRect.height / 2,
  };
};
const getLeftBottomPosition = (elRect, targetRect) => {
  return {
    left: targetRect.left - elRect.width,
    top: targetRect.top + targetRect.height - elRect.height,
  };
};
