import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import 'leaflet.markercluster';
import 'leaflet.markercluster.placementstrategies';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import './GeoJsonDatexPOIMap.scss';
import { Divider } from 'antd';
import { getIcon } from '@bvt-shared/utils/iconUtils';
/**
 * @copyright PT Bhumi Varta Technology
 * @author Malik <malik.ibrahim@bvarta.com>
 * @description Geojson for datex POI
 * @param {Object} props.data
 * @param {Number} props.opacity
 */
export const GeoJsonDatexPOIMap = (props) => {
  const map = useMap();

  useEffect(() => {
    let markerClusterGroupInstance = new L.MarkerClusterGroup({
      showCoverageOnHover: false,
      spiderfyDistanceMultiplier: 1.2,
      maxClusterRadius: 80,
      iconCreateFunction: (icfParam) => {
        const totalMarkerPerCluster = icfParam.getChildCount();

        const multiplier = map.getZoom() > 16 ? 8 : 3;
        const percentageOfTotalMarker =
          (totalMarkerPerCluster / props?.data?.length || 1) * 100;
        const opacityMultiplier = (percentageOfTotalMarker / 10) * multiplier;

        const sizeMultiPlier = opacityMultiplier > 0.9 ? 1.3 : 1.1;

        const baseStyle = {
          background: `rgba(151,106,218,${props.opacity/2 || .5})`,
          width: 42 * sizeMultiPlier,
          height: 42 * sizeMultiPlier,
          borderRadius: 64,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        };
        const htmlElement = renderToStaticMarkup(
          <div style={baseStyle}>
            <div style={{
              ...baseStyle,
              opacity: isNaN(props?.opacity) ? 1 : props?.opacity,
              width: 32 * sizeMultiPlier,
              height: 32 * sizeMultiPlier,
              color: '#DEEEED',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '26px',
            }}>
              {totalMarkerPerCluster}
            </div>
          </div>
        );

        return L.divIcon({ html: htmlElement,
          iconAnchor: { x: 22, y: 22 } });
      },
    });

    if (props?.data?.length > 0) {
      // Add Cluster To Map

      props.data?.forEach((el) => {
        const properties = el?.popupContent?.props?.properties;
        let markerLayer = L.marker(el.position, {
          icon: new L.DivIcon({
            html: renderToStaticMarkup(
              <div
                className=''
                data-meta={JSON.stringify(el)}
                style={{ opacity: isNaN(props.opacity) ? 1 : props.opacity }}
              >
                <img
                  alt=''
                  src={getIcon('poi', properties?.category_icon)}
                  style={{ height: '2.25rem', width: '2.25rem' }}
                />
              </div>
            ),
          }),
        });


        markerLayer.bindPopup(renderToStaticMarkup(
          <div className='DatexPOIMap__popup'>
            <div className='header'>
              <div className='icon'>
                <img
                  alt=''
                  src={getIcon('poi', properties?.category_icon)}
                  style={{ height: '2.25rem', width: '2.25rem' }}
                />
              </div>
              <div className='wrapper-title'>
                <div className='title'>
                  {properties?.poi_name}
                </div>
                <div className='subtitle'>
                  {properties.industry_name}
                </div>
              </div>
            </div>
            <div className='body'>
              <div className='detail'>
                <div className='detail--text'>Group</div>
                <div className='detail--text'>{properties?.group_name}</div>
              </div>
              <div className='detail'>
                <div className='detail--text'>Category</div>
                <div className='detail--text'>{properties?.category_name}</div>
              </div>
              <Divider className='divider' />
              <div className='detail'>
                <div className='detail--text'>Longitude</div>
                <div className='detail--text'>{properties?.longitude}</div>
              </div>
              <div className='detail'>
                <div className='detail--text'>Latitude</div>
                <div className='detail--text'>{properties?.latitude}</div>
              </div>
              <div className='detail'>
                <div className='detail--text'>Address</div>
                <div className='detail--text'>{properties?.address?.length > 23 ? properties?.address?.substring(0, 20) + '...' : properties?.address}</div>
              </div>
            </div>
          </div>
        ), {
          offset: {
            x: 14,
            y: 16,
          }
        });


        // markerClusterGroupInstance.setZIndex(201 + 2)

        // Add Marker To cluster Layer
        markerClusterGroupInstance.addLayer(markerLayer);
      });

      markerClusterGroupInstance.addTo(map);
    }
    return () => {
      // Remove Cluster On Remount
      markerClusterGroupInstance.removeFrom(map);
    };
  }, [props.data, props.opacity]);
  return <></>;
};

