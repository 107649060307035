import React from 'react';
import { useNavigate, useParams } from 'react-router';

import { AsmanDashboardSidebarComponent } from '@bvt-features/sub-module/asman/component/AsmanDashboardSidebar.component';

export const AsmanDashboardSidebarContainer = () => {
  const navigate = useNavigate();
  const { gsid } = useParams();
  return (
    <AsmanDashboardSidebarComponent
      onBackToMapClick={() => navigate('/sub-module/map/asset-management')}
      onExportPdfClick={() => navigate(`/sub-module/export/asset-management/${gsid}`)}
    />
  );
};
