import { LayoutSideBarMainApp } from '@bvt-features/mapp/component/LayoutSideBarMainApp';
import { LayoutTopBarMainApp } from '@bvt-features/mapp/component/LayoutTopBarMainApp';
import { LayoutContentMainApp } from '@bvt-features/mapp/component/LayoutContentMainApp';
import React, { useState } from 'react';
import './LayoutMainAppContainer.scss';
import { useAuth } from '@bvt-features/auth/hooks';
import { Popup } from '@bvt-atoms';
import { useNavigate } from 'react-router';

/**
 * @version 1
 * @copyright Bhumi Varta Technology
 * @author Malik <malik.ibrahim@bvarta.com>
 * @param {object} props
 * @param {Component} props.children
 */

export function LayoutMainAppContainer(props) {
  const { children } = props;
  const AUTH = useAuth();
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const s3URL = '/api/api-uploads3/v2/photo-user-company/display-photo/';

  const handleLogout = () => {
    navigate('/auth/logout');
  };

  return (
    <div className='LayoutMainAppContainer'>
      <LayoutTopBarMainApp
        data={{
          userName: AUTH.state?.data?.user?.fullName
            ? AUTH.state.data.user.fullName.split(' ')[0]
            : undefined,
          userPhoto:
            AUTH.state?.data?.user?.photo_path &&
            AUTH.state?.data?.user?.photo_path !== 'bnVsbA=='
              ? `${s3URL}${AUTH.state.data.user.photo_path}`
              : undefined,
        }}
        onLogoutClick={() => setShowPopup(!showPopup)}
      />
      <Popup
        body={{
          icon: 'confirmlogout',
          desc: <p>Are you sure want to log out?</p>,
        }}
        footerButton={{
          rightClass: 'red',
        }}
        handler={{
          onHide: () => {
            setShowPopup(false);
          },
          onSubmit: () => {
            handleLogout();
          },
        }}
        headerText='Confirm'
        show={showPopup}
        submitText='Logout'
      />
      <LayoutSideBarMainApp
        activeMenu='home'
        menuList={[
          { key: 'home', label: 'Home', icon: 'home' },
          { key: 'new', label: 'New', icon: 'new' },
          { key: 'open', label: 'Open', icon: 'open' },
          { key: 'history', label: 'History', icon: 'history' },
          { key: 'account', label: 'Account', icon: 'account' },
          { key: 'map', label: 'Go to Map', icon: 'map' },
        ]}
        showVersion={true}
      />
      <LayoutContentMainApp>{children}</LayoutContentMainApp>
    </div>
  );
}
