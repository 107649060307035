import React, { useEffect, useRef } from 'react';
import * as L from 'leaflet';
import { MapContainer } from 'react-leaflet';
import { Spin } from 'antd';
import { renderToString } from 'react-dom/server';

import { MainMapTileLayerContainer } from '@bvt-features/sub-module/mainmap/container/MainMapTileLayer.container';
import { SharedBaseCardTypeOneComponent } from '@bvt-shared/component/SharedBaseCardTypeOne.component';

import { AsmanMarkerGeojsonComponent } from '../AsmanMarkerGeojson.component';
import { AsmanMapViewPopupInternalComponent } from './AsmanMapViewPopup.internal.component';

import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import './AsmanMapView.component.scss';

export const AsmanMapViewComponent = props => {
  const {
    GEOJSONBuffer,
    GEOJSONCenterAsset,
    GEOJSONPOICollection,
    isLoading
  } = props;
  const MAP_OPTIONS = {
    zoom: 5,
    attributionControl: false,
    center: [-0.263671, 118.564453],
    fadeAnimation: true,
    maxZoom: 18,
    maxBounds: [
      [-16.59408141271846, 63.25195312500001],
      [16.088042220148818, 163.83300781250003]
    ],
    maxBoundsViscosity: 1.0,
    minZoom: 5,
    keyboard: true,
    keyboardPanDelta: 20,
    trackResize: true,
    scrollWheelZoom: true,
    zoomControl: false,
    zoomAnimation: true,
    doubleClickZoom: false,
    zoomSnap: 1,
    preferCanvas: true
  };

  const mapRef = useRef(null);
  const gjsAreaInstance = L.geoJson(GEOJSONBuffer, {
    style: {
      fill: true,
      color: '#03D85D',
      fillColor: '#03D85D',
      fillOpacity: 0.3
    }
  });

  useEffect(() => {
    if (mapRef.current && GEOJSONBuffer && GEOJSONCenterAsset) {
      gjsAreaInstance.addData(GEOJSONBuffer);

      gjsAreaInstance.addTo(mapRef.current);

      gjsAreaInstance.setZIndex(0);

      try {
        mapRef.current.fitBounds(gjsAreaInstance.getBounds());
      } catch (error) {
        mapRef.current.zoomOut();
      }
    }
    return () => {
      if (mapRef.current) {
        gjsAreaInstance.removeFrom(mapRef.current);
      }
    };
  }, [GEOJSONBuffer]);

  useEffect(() => {
    const lyrGroup = [];

    GEOJSONPOICollection?.forEach(v => {
      const tmpclstr = L.markerClusterGroup();

      v.geojson?.features?.map(v2 => {
        tmpclstr.addLayer(
          L.marker(
            {
              lat: v2.geometry.coordinates[1],
              lng: v2.geometry.coordinates[0]
            },
            {
              icon: L.icon({
                iconUrl: v2.properties.icon || '',
                iconSize: [32, 32]
              })
            }
          ).bindPopup(
            renderToString(
              <AsmanMapViewPopupInternalComponent
                data={{
                  icon: v2.properties.icon,
                  group: v2.properties.group,
                  title: v2.properties.name,
                  category: v2.properties.category?.label,
                  address: v2.properties.address,
                  buildingArea: v2.properties.buildingArea,
                  price: v2.properties.price,
                  nearestPublicTransport: v2.properties.nearestPublicTransport,
                  surfaceArea: v2.properties.surfaceArea,
                  height: v2.properties.height
                }}
              />
            ),
            {
              className: 'AsmanMapViewComponent__popup'
            }
          )
        );
      });
      if (mapRef.current) {
        tmpclstr.addTo(mapRef.current);
        lyrGroup.push(tmpclstr);
      }
    });
    return () => {
      lyrGroup.forEach(v => {
        if (mapRef.current) {
          v.removeFrom(mapRef.current);
        }
      });
    };
  }, [GEOJSONPOICollection]);

  return (
    <SharedBaseCardTypeOneComponent
      showIcon
      title={
        <React.Fragment>
          <span>Map View {isLoading && '(Loading Map Data)'}</span>
        </React.Fragment>
      }
    >
      <Spin spinning={isLoading}>
        <MapContainer
          {...MAP_OPTIONS}
          style={{ width: '100%', height: 374 }}
          whenCreated={(mapEl) => mapRef.current = mapEl}
        >
          {GEOJSONCenterAsset && mapRef.current && (
            <AsmanMarkerGeojsonComponent
              data={GEOJSONCenterAsset}
              mapInstance={mapRef.current}
            />
          )}
          <MainMapTileLayerContainer />
        </MapContainer>
      </Spin>
    </SharedBaseCardTypeOneComponent>
  );
};
