import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { CustomPOIAsyncAction } from './customPOI.asyncAction';

/**
 * @typedef {object} CustomPOI
 * @property {string} id
 * @property {string} name_site
 * @property {number} marker_for_user
 * @property {string} color
 * @property {string} longitude
 * @property {string} latitude
 * @property {string} created_at
 * @property {number} updated_at
 * @property {number} updated_by
 * @property {string} slug
 * @property {number} id_status
 * @property {string} name_status
 * @property {number} created_by
 * @property {string} icon_type
 */

/**
 * @typedef {object} CustomPoiResponse
 * @property {{nextPage:number, perPage:number, prevPage:number, total:number}} meta
 * @property {CustomPOI[]} data
 */

/**
 * @typedef {object} InitialState
 * @property {object} status
 * @property {'IDLE'|'SUCCESS'|'FAILED'|'LOADING'} status.LIST
 * @property {{[x: number]: Marker}} entities
 * @property {number[]} ids
 * @property {string} message
 * @property {{nextPage:number, perPage:number, prevPage:number, total:number}} meta
 */

const adapter = createEntityAdapter({
  selectId: (data) => data.id,
});

/**
 * @type {InitialState}
 */
const initialState = {
  entities: {},
  ids: [],
  status: {
    LIST: 'IDLE',
  },
  message: '',
  meta: {},
};

const CustomPOISlice = createSlice({
  name: 'LI/GENSET/CustomPOI',
  initialState,
  reducers: {
    addone: adapter.addOne,
    addMany: adapter.addMany,
    setOne: adapter.setOne,
    setMany: adapter.setMany,
    setAll: adapter.setAll,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    setMessage: (state, action) => {
      const { payload } = action;
      state.message = payload.message || '';
    },
    setStatus: (state, action) => {
      const { payload } = action;
      if (payload.status) {
        payload.status.map((v) => {
          state.status[v] = payload.type;
        });
      }
    },
    resetAll: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(CustomPOIAsyncAction.getList.pending, (state) => {
        state.status.LIST = 'LOADING';
      })
      .addCase(CustomPOIAsyncAction.getList.rejected, (state, { error }) => {
        state.status.LIST = 'FAILED';
        state.message = error.message;
      })
      .addCase(CustomPOIAsyncAction.getList.fulfilled, (state, { payload }) => {
        state.status.LIST = 'SUCCESS';
        state.message = 'Successfully get markers';
        state.meta = {
          total: payload.total,
          prevPage: payload.prevPage,
          perPage: payload.perPage,
          nextPage: payload.nextPage,
        };
      });
  },
});

export const {
  reducer: CUSTOM_POI_REDUCER,
  actions: CUSTOM_POI_ACTION,
} = CustomPOISlice;
