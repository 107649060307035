export const LIST_MENU_BAR = [
  {
    id: 1,
    name: 'Project',
    code: 'project',
    children: [
      {
        id: 1,
        name: 'Create new project',
        code: 'createProject',
        icon: 'createProject',
        active: true,
        action: 'createNewProject',
      },
      {
        id: 2,
        name: 'Save project',
        code: 'saveProject',
        icon: 'saveProject',
        active: false,
        action: () => {},
      },
      {
        id: 3,
        name: 'Save as project',
        code: 'saveProject',
        icon: 'saveProject',
        active: false,
        action: () => {},
      },
      {
        id: 4,
        name: 'Rename project',
        code: 'renameProject',
        icon: 'renameProject',
        active: false,
        action: () => {},
      },
      {
        id: 5,
        name: 'Close project',
        code: 'closeProject',
        icon: 'closeProject',
        active: false,
        action: () => {},
      },
      {
        id: 6,
        name: 'Open recent',
        code: 'openRecent',
        icon: 'openRecent',
        active: true,
        action: 'openRecentProject',
      },
    ],
    visible: false,
  },
  // {
  //   id: 2,
  //   name: 'View',
  //   code: 'view',
  //   children: [
  //     {
  //       id: 1,
  //       name: 'Full screen',
  //       code: 'fullscreen',
  //       icon: 'fullscreen',
  //       active: true,
  //       action: 'openFullscreen',
  //     },
  //     {
  //       id: 2,
  //       name: 'Base map',
  //       code: 'baseMap',
  //       icon: 'baseMap',
  //       active: true,
  //       action: 'ToggleOnBaseLayer',
  //     },
  //     {
  //       id: 3,
  //       name: 'Layer Visibility',
  //       code: 'layerVisibility',
  //       icon: 'layerVisibility',
  //       active: true,
  //       action: 'toggleOnOpacityLayer',
  //     },
  //   ],
  //   visible: false,
  // },
  {
    id: 3,
    name: 'Setup',
    code: 'setup',
    children: [
      {
        id: 1,
        name: 'POI Parameter',
        code: 'setupGroupPoi',
        icon: 'setupGroupPoi',
        active: true,
        action: () => {},
        url: '/app/menu/parameter-setup',
      },
      {
        id: 2,
        name: 'Thematic Parameter',
        code: 'setupParameter',
        icon: 'setupParameter',
        active: true,
        action: () => {},
        url: '/app/menu/parameter-setup/thematic',
      },
    ],
    visible: false,
  },
  // {
  //   id: 4,
  //   name: 'Help',
  //   code: 'help',
  //   children: [
  //     {
  //       id: 1,
  //       name: 'About',
  //       code: 'about',
  //       icon: 'about',
  //       active: true,
  //       action: 'showAbout',
  //     },
  //   ],
  //   visible: false,
  // },
];
