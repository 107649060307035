import { LEGACY_AUTH_CONSTANT } from '@bvt-features/auth/redux';
import { authAsyncAction } from '@bvt-features/auth/store/auth.asyncAction';
import { authAction } from '@bvt-features/auth/store/auth.slice';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

/**
 * @author Randa<m.randa@bvarta.com>
 * @description Hooks for feature auth
 */
export function useAuth() {
  const state = useSelector(
    /**
     * @param {ReturnType<typeof import('../../../../redux/rootStore').rootStore.getState>} state
     */
    (state) => state.features.AUTH
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   *
   * @param {object} param
   * @param {string} param.email
   * @param {string} param.password
   */
  const login = (param) => {
    dispatch(authAsyncAction.login(param));
  };

  const logout = () => {
    dispatch(authAsyncAction.logout());
  };

  const checkToken = () => {
    dispatch(authAsyncAction.checkToken());
  };

  /**
   * @param {object} param object
   * @param {string} param.email email
   */
  const forgetPassword = (param) => {
    dispatch(authAsyncAction.forgetPassword(param));
  };

  /**
   * @param {object} param object
   * @param {string} param.password pwd
   * @param {string} param.password_confirmation pwd confirmation
   * @param {string} param.token confirmation token get from user email
   */
  const resetPasswordUsingToken = (param) => {
    dispatch(authAsyncAction.resetPasswordByToken(param));
  };

  const resetStatus = () => {
    dispatch(authAction.resetStatus());
  };

  const unauthorized = () => {
    dispatch({ type: LEGACY_AUTH_CONSTANT.UNAUTHORIZED });
  };

  const doLogout = () => {
    dispatch(authAsyncAction.doLogout());
  };

  const getSetting = () => {
    dispatch(authAsyncAction.getSetting());
  };

  const updateSetting = (v) => {
    dispatch(authAsyncAction.updateSetting({ ...state?.data?.setting, ...v }));
  };
  /**
   * @param {object} param
   * @param {'MAPANA'|'DATEX'|'GENSET'} param.feature
   * @param {'SIPRO'|'GRIANA'|'POI'|'PARAMETER'|'THEMATIC'|'TELCO'|'MANDALA'|'POI_RINJANI'|'THEMATIC_RINJANI'} param.subFeature
   * @param {'ANALYZE' | 'PEOPLE_DENSITY'} param.action
   */
  const isAuthorized = (param = {}) => {
    const { feature,subFeature,action } = param;
    const permission = state.data.permissions;
    if(!!feature && !!subFeature && !!action){
      const fFeature = _.find(permission,(v)=>v.key === feature);
      const fSubFeature = _.find(fFeature.children,(v)=>v.key === subFeature);
      const fAction = _.find(fSubFeature.children,(v)=>v.key === action);
      return !!fFeature?.isAllowed && !!fSubFeature?.isAllowed && !!fAction?.isAllowed;
    }
    if(!!feature && !!subFeature){
      const fFeature = _.find(permission,(v)=>v.key === feature);
      const fSubFeature = _.find(fFeature.children,(v)=>v.key === subFeature);
      return !!fFeature?.isAllowed && !!fSubFeature?.isAllowed;
    }
    if(feature){
      const fFeature = _.find(permission,(v)=>v.key === feature);
      return !!fFeature?.isAllowed;
    }

    return true;
  };

  /**
   *
   * @param {string} param feature.subFeature.action
   * @returns {{
   *    feature: 'MAPANA'|'DATEX'|'GENSET',
   *    subFeature?: 'SIPRO'|'GRIANA'|'MANDALA'|'POI'|'PARAMETER'|'THEMATIC'|'TELCO'|'POI_RINJANI'|'THEMATIC_RINJANI',
   *    action?: 'ANALYZE' | 'PEOPLE_DENSITY'
   *  }}
   */
  const permissonStringToObject = (param = '') => {
    const feature = 0;
    const subFeature = 1;
    const action = 2;
    const permission = param.split('.');

    return {
      feature: permission[feature],
      subFeature: permission[subFeature],
      action: permission[action],
    };
  };

  /**
   *
   * @param {string} value feature.subFeature.action
   * @return {boolean}
   *
   * @example
   * hasAccess('MAPANA')
   * hasAccess('MAPANA.POI')
   * hasAccess('MAPANA.POI.ANALYZE')
   */
  const hasAccess = (value) => {
    const permission = permissonStringToObject(value);
    return isAuthorized(permission);
  };

  /**
   *
   * @param {string} value feature.subFeature.action
   *
   * if has no access will be redirect to page 403
   *
   * @example
   * useEffect(() => {
   *  defineAccess('MAPANA.GRIANA.ANALYZE');
   * });
   */
  const defineAccess = (value) => {
    if (!hasAccess(value)) {
      navigate('/403');
    }
  };

  const checkTokenResetPassword = (token) => {
    dispatch(authAsyncAction.checkTokenResetPassword(token));
  };

  return {
    state,
    resetStatus,
    login,
    logout,
    checkToken,
    forgetPassword,
    resetPasswordUsingToken,
    unauthorized,
    getSetting,
    updateSetting,
    isAuthorized,
    hasAccess,
    defineAccess,
    checkTokenResetPassword,
    doLogout
  };
}
