import * as React from "react"

export const IconEye = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.24 8.419c.35.528.766 1.055 1.24 1.546 1.327 1.373 2.842 2.183 4.52 2.183 1.677 0 3.192-.81 4.519-2.183A11.022 11.022 0 0 0 14.024 8a11.022 11.022 0 0 0-1.506-1.965C11.193 4.662 9.678 3.852 8 3.852c-1.677 0-3.192.81-4.52 2.183A11.025 11.025 0 0 0 1.975 8c.077.129.166.27.265.419ZM.73 7.735c.087-.167.248-.445.483-.797.388-.584.847-1.168 1.375-1.714C4.126 3.634 5.934 2.666 8 2.666c2.066 0 3.874.968 5.411 2.558.529.546.987 1.13 1.376 1.714.235.352.396.63.482.797a.576.576 0 0 1 0 .53 8.94 8.94 0 0 1-.482.797 12.187 12.187 0 0 1-1.376 1.713c-1.537 1.59-3.345 2.558-5.41 2.558-2.066 0-3.874-.967-5.412-2.558a12.197 12.197 0 0 1-1.375-1.713 8.974 8.974 0 0 1-.482-.797.577.577 0 0 1 0-.53Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 10.37C6.65 10.37 5.554 9.31 5.554 8c0-1.31 1.094-2.37 2.444-2.37 1.35 0 2.445 1.06 2.445 2.37 0 1.309-1.095 2.37-2.445 2.37Zm0-1.185c.674 0 1.221-.53 1.221-1.185S8.674 6.815 8 6.815c-.675 0-1.222.53-1.222 1.185 0 .654.547 1.185 1.222 1.185Z"
      fill="currentColor"
    />
  </svg>
)

