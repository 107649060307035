import React from 'react';
import { IconType, IconTypeDefault } from '@bvt-types';

export default function IconCreateReport(props) {
  const { className, height, width } = props;

  return (
    <svg height={height} viewBox='0 0 16.533 17' width={width}  >
      <path
        className={className}
        d='M18.742,9.013V1.369H7.332V15.631H12.58a.685.685,0,0,1,0,1.369H6.648a.687.687,0,0,1-.685-.685V.685A.687.687,0,0,1,6.648,0H19.426a.687.687,0,0,1,.685.685V9.013a.685.685,0,1,1-1.369,0Zm3.526,1.666a.686.686,0,0,0-.97.057l-3.754,4.176L15.661,13.1a.686.686,0,0,0-.947.993l2.4,2.3a.673.673,0,0,0,.479.194h.023a.684.684,0,0,0,.479-.228l4.221-4.7A.689.689,0,0,0,22.267,10.679ZM16.231,3.993H10.07a.685.685,0,1,0,0,1.369h6.161a.685.685,0,1,0,0-1.369Zm.685,4.45a.687.687,0,0,0-.685-.685H10.07a.685.685,0,1,0,0,1.369h6.161A.687.687,0,0,0,16.916,8.443ZM10.07,11.409a.685.685,0,1,0,0,1.369H11.9a.685.685,0,0,0,0-1.369Z'
        transform='translate(-5.963)'
      />
    </svg>
  );
}

IconCreateReport.defaultProps = IconTypeDefault;

IconCreateReport.propTypes = IconType;
