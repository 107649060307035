import React from 'react';
import DynamicIcon from '@bvt-icons';
import PropTypes from 'prop-types';
import StepContainer from '@bvt-features/mapp/step/container/StepContainer';
import { useStep } from '@bvt-features/mapp/step/hook';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 */

/**
 * @param {object} props
 * @param {object} props.item
 * @param {number} props.item.id
 * @param {string} props.item.code
 * @param {string} props.item.icon
 * @param {string} props.item.step
 * @param {boolean} props.item.active
 * @param {func} props.onClickCloseSubmenu
 */

export default function SelectedSubMenu(props) {
  const STEP = useStep();

  return (
    <div className='module-bar__submenu__selected--wrapper'>
      <div className='module-bar__submenu__selected'>
        <DynamicIcon
          className='module-bar__submenu__selected__icon'
          height='1.25rem'
          icon={props?.item?.icon}
          width='1.25rem'
        />
        {props?.item?.name}
        <div
          className='module-bar__submenu__selected__close'
          onClick={() =>
            props?.onClickCloseSubmenu && props?.onClickCloseSubmenu()
          }
        >
          <DynamicIcon
            className='module-bar__submenu__selected__close__icon'
            height='1rem'
            icon='close'
            width='1rem'
          />
        </div>
      </div>
      {STEP.state.step > 0 ? (
        <div className='module-bar__submenu__breadcrumbs'>
          {STEP.state?.status_GET === 'LOADING' ? (
            <svg
              className='module-bar__spinner'
              height='1rem'
              viewBox='0 0 15 15'
              width='1rem'
            >
              <g transform='translate(-1078.276 -305.421)'>
                <path
                  d='M7.5,15A7.5,7.5,0,0,1,0,7.5,7.5,7.5,0,0,1,12.8,2.2a7.5,7.5,0,0,1,0,10.607A7.445,7.445,0,0,1,7.5,15Zm0-12.858A5.356,5.356,0,1,0,12.856,7.5,5.362,5.362,0,0,0,7.5,2.142Z'
                  fill='#06534d'
                  transform='translate(1078.276 305.421)'
                />
                <path
                  d='M7.5,15A7.506,7.506,0,0,1,0,7.5,7.469,7.469,0,0,1,1.016,3.725L3,4.591A5.355,5.355,0,1,0,7.5,2.14V0a7.5,7.5,0,0,1,5.3,12.8A7.436,7.436,0,0,1,7.5,15Z'
                  fill='#fff'
                  transform='translate(1078.276 305.421)'
                />
              </g>
            </svg>
          ) : (
            <StepContainer
              activeStep={STEP.state.step}
              data={STEP.state?.data}
            />
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

SelectedSubMenu.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    code: PropTypes.string,
    icon: PropTypes.string,
    step: PropTypes.string,
    active: PropTypes.bool,
  }),
  onClickonOpenSubMenu: PropTypes.func,
};
