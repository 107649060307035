import ReactGA from 'react-ga4';

const GA_TAG = process.env.GA_TAG || 'G-W2P4FVNXH8'; // Hardcoded, set on env or param store;

export const GA_Utils = {
  initGA: () => {
    if (window.location.host.includes('li.lokasi')) {
      ReactGA.initialize(GA_TAG);
    }
  },
  /**
   * @param {object} param
   * @param {string} param.overrideLocation
   */
  trackPage: (param = {}) => {
    const { overrideLocation } = param;
    ReactGA.send({
      hitType: 'pageview',
      page: overrideLocation
        ? overrideLocation
        : window.location.pathname + window.location.search,
    });
  },
  /**
   * @param {object} param
   * @param {'DEV'|'UX'} param.type
   * @param {string} param.eventName
   * @param {Record<string,any>} param.properties
   */
  trackEvent: (param = {}) => {
    const { type, eventName, properties } = param;
    ReactGA.gtag(
      'event',
      (type === 'UX' ? 'UX_' : 'DEV_') + String(eventName).toUpperCase(),
      properties
    );
  },
  /**
   * @param {string} userId
   */
  setUserID: (userId) => {
    ReactGA.set({ userId, user_id: userId });
  },
  sendError: (err, info) => {
    ReactGA.ga('send', 'exception', {
      messsage: err,
      description: info,
    });
  },
};
