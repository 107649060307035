import { notification } from 'antd';
import _ from 'lodash';
import { SiproService } from '../service';
import { SIPRO_CONSTANT } from './SIPRO_CONSTANT';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta <renta.yustika@bvarta.com>
 */

const siproService = new SiproService();

export const SIPRO_ACTION = {
  setStep: (payload) => {
    const { step = 0 } = payload;
    return {
      type: SIPRO_CONSTANT.SET_STEP,
      payload: {
        step,
      },
    };
  },

  setStepOrder: (payload) => {
    const { step_order = 0 } = payload;
    return {
      type: SIPRO_CONSTANT.SET_STEP_ORDER,
      payload: {
        step_order,
      },
    };
  },

  saveProject: (param) => {
    return async (dispatch) => {
      dispatch({
        type: SIPRO_CONSTANT.SAVE_PROJECT_REQUESTED,
        payload: {
          status_POST: 'LOADING',
        },
      });
      try {
        let req = await siproService.saveProject(param);
        dispatch({
          type: SIPRO_CONSTANT.SAVE_PROJECT_SUCCESS,
          payload: {
            status_POST: 'SUCCESS',
            message: req.message,
            formSaveProject: {
              ...param,
              id: req.result?.id || req?.dataInsert?.id,
            },
          },
        });
      } catch (error) {
        dispatch({
          type: SIPRO_CONSTANT.SAVE_PROJECT_FAILED,
          payload: {
            status_POST: 'FAILED',
            message: error.response.data.message.replace(
              'Error Message : ',
              ''
            ),
          },
        });
      }
    };
  },

  doAnalyze: (projectData, toAnalyzeReq) => {
    return async (dispatch) => {
      dispatch({
        type: SIPRO_CONSTANT.DO_ANALYZE_REQUESTED,
        payload: {
          status_ANALYZE: 'LOADING',
        },
      });
      try {
        await siproService.saveProject(projectData);
        const analyzeReq = await siproService.doAnalyze(toAnalyzeReq);
        projectData.process_id = analyzeReq.result.job_id || '';
        await siproService.saveProject(projectData);
        dispatch({
          type: SIPRO_CONSTANT.DO_ANALYZE_SUCCESS,
          payload: {
            status_ANALYZE: 'SUCCESS',
          },
        });
      } catch (error) {
        notification.error({
          message: error.response?.data?.message,
        });
        dispatch({
          type: SIPRO_CONSTANT.DO_ANALYZE_FAILED,
          payload: { status_ANALYZE: 'FAILED' },
        });
      }
    };
  },

  doAnalyzeMandala: (projectData, toAnalyzeReq) => {
    return async (dispatch) => {
      dispatch({
        type: SIPRO_CONSTANT.DO_ANALYZE_MANDALA_REQUESTED,
        payload: {
          status_ANALYZE: 'LOADING',
        },
      });
      try {
        await siproService.saveProject(projectData);
        const analyzeReq = await siproService.doAnalyzeMandala(toAnalyzeReq);
        projectData.process_id = analyzeReq.result.job_id || '';
        await siproService.saveProject(projectData);
        dispatch({
          type: SIPRO_CONSTANT.DO_ANALYZE_MANDALA_SUCCESS,
          payload: {
            status_ANALYZE: 'SUCCESS',
          },
        });
      } catch (error) {
        notification.error({
          message: error.response?.data?.message,
        });
        dispatch({
          type: SIPRO_CONSTANT.DO_ANALYZE_MANDALA_FAILED,
          payload: { status_ANALYZE: 'FAILED' },
        });
      }
    };
  },

  getSitePoiCollections: (payload) => {
    return async (dispatch) => {
      dispatch({
        type: SIPRO_CONSTANT.GET_POI_COLLECTIONS_REQUESTED,
        payload: { status_GET_POI: 'LOADING', IS_SHOWN_ON_MAP: false },
      });
      try {
        const data = await siproService.sitePoiCollections(payload);
        dispatch({
          type: SIPRO_CONSTANT.GET_POI_COLLECTIONS_SUCCESS,
          payload: {
            status_GET_POI: 'SUCCESS',
            poi_collection: data.geoJson.features,
            IS_SHOWN_ON_MAP: true,
          },
        });
      } catch (error) {
        dispatch({
          type: SIPRO_CONSTANT.GET_POI_COLLECTIONS_FAILED,
          payload: {
            status_GET_POI: 'FAILED',
            IS_SHOWN_ON_MAP: false,
            message: error.response.data.message,
          },
        });
      }
    };
  },

  getSiteAreaCollections: (payload) => {
    return async (dispatch) => {
      dispatch({
        type: SIPRO_CONSTANT.GET_AREA_COLLECTIONS_REQUESTED,
        payload: { status_GET_AREA: 'LOADING', IS_SHOWN_ON_MAP: false },
      });
      try {
        const data = await siproService.siteAreaCollections(payload);
        if (data?.geoJson.length > 0) {
          const filteredArea = _.groupBy(data?.geoJson, (v) => v.site_name);
          const tempArrFilteredArea = [
            filteredArea[Object.keys(filteredArea)[0]][0],
          ].map((v) => {
            return {
              type: v?.features[0]?.type,
              geometry: {
                type: v?.features[0]?.geometry?.type,
                coordinates: v?.features[0]?.geometry?.coordinates,
              },
              properties: v?.features[0]?.properties,
            };
          });
          dispatch({
            type: SIPRO_CONSTANT.GET_AREA_COLLECTIONS_SUCCESS,
            payload: {
              status_GET_AREA: 'SUCCESS',
              area_collection: data.geoJson,
              filtered_area_collection: tempArrFilteredArea[0],
              IS_SHOWN_ON_MAP: true,
            },
          });
        }
      } catch (error) {
        dispatch({
          type: SIPRO_CONSTANT.GET_AREA_COLLECTIONS_FAILED,
          payload: {
            status_GET_AREA: 'FAILED',
            IS_SHOWN_ON_MAP: false,
            message: error?.response?.data?.message,
          },
        });
      }
    };
  },

  filterAreaCollection: (payload) => {
    return (dispatch) => {
      dispatch({
        type: SIPRO_CONSTANT.FILTERED_AREA,
        payload,
      });
    };
  },

  filterAreaCollectionRight: (payload) => {
    return (dispatch) => {
      dispatch({
        type: SIPRO_CONSTANT.FILTERED_AREA_RIGHT,
        payload,
      });
    };
  },

  setIsShownOnMap: (payload) => {
    const { IS_SHOWN_ON_MAP = false } = payload;
    return {
      type: SIPRO_CONSTANT.SET_IS_SHOWN_ON_MAP,
      payload: {
        IS_SHOWN_ON_MAP,
      },
    };
  },

  setLastStep: (payload) => {
    const { lastStep = 0 } = payload;
    return {
      type: SIPRO_CONSTANT.SET_LAST_STEP,
      payload: {
        lastStep,
      },
    };
  },
};
