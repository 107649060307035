import * as React from 'react';
import PropTypes from 'prop-types';

/**
 *
 * @param {object} props
 * @param {number} props.height
 * @param {string} props.value
 * @param {number} props.width
 * @param {string} props.title
 */

const IconPeopleDensityDashboard = (props) => (
  <svg
    height={props.height}
    viewBox='0 0 260 150'
    width={props.height}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    {...props}
  >
    <defs>
      <filter
        filterUnits='userSpaceOnUse'
        height={150}
        id='Navbar.BG'
        width={260}
        x={0}
        y={0}
      >
        <feOffset dy={5} />
        <feGaussianBlur result='blur' stdDeviation={5} />
        <feFlood floodColor='#00a8ff' floodOpacity={0.4} />
        <feComposite in2='blur' operator='in' />
        <feComposite in='SourceGraphic' />
      </filter>
    </defs>
    <g
      data-name='Group 11044'
      id='Group_11044'
      transform='translate(-350 -1114)'
    >
      <g data-name='Group 11043' id='Group_11043'>
        <path
          d='M17890-16182h-170a8.01,8.01,0,0,1-8-8v-10h186v10A8.01,8.01,0,0,1,17890-16182Z'
          data-name='Subtraction 73'
          fill='#00a8ff'
          id='Subtraction_73'
          opacity={0.35}
          transform='translate(-17325 17444)'
        />
        <path
          d='M17904-16191h-198a8.01,8.01,0,0,1-8-8v-1h214v1A8.01,8.01,0,0,1,17904-16191Z'
          data-name='Subtraction 72'
          fill='#00a8ff'
          id='Subtraction_72'
          opacity={0.35}
          transform='translate(-17325 17444)'
        />
      </g>
      <g data-name='Group 11035' id='Group_11035' transform='translate(0.027)'>
        <g data-name='Group 11031' id='Group_11031' transform='translate(26 6)'>
          <g
            filter='url(#Navbar.BG)'
            transform='matrix(1, 0, 0, 1, 323.97, 1108)'
          >
            <rect
              data-name='Navbar.BG'
              fill='#00a8ff'
              height={120}
              id='Navbar.BG-2'
              rx={8}
              transform='translate(15 10)'
              width={230}
            />
          </g>
          <g
            data-name='Group 11030'
            id='Group_11030'
            transform='translate(348.973 1166)'
          >
            <text
              data-name={25.447}
              fill='#fff'
              fontFamily='SourceSansPro-Bold, Source Sans Pro'
              fontSize={30}
              fontWeight={700}
              id='_25.447'
              transform='translate(0 54)'
            >
              <tspan x={0} y={0}>
                {props.value}
              </tspan>
            </text>
            <text
              data-name='title'
              fill='#fff'
              fontFamily='SourceSansPro-Regular, Source Sans Pro'
              fontSize={14}
              id='title'
              transform='translate(0 14)'
            >
              <tspan x={0} y={0}>
                {props.title}
              </tspan>
            </text>
          </g>
        </g>
        <g
          data-name='Path 5237'
          fill='rgba(255,255,255,0.3)'
          id='Path_5237'
          opacity={0.5}
          transform='translate(-17325.023 17444)'
        >
          <path
            d='M17894.031-16200v-35a8,8,0,0,1,8-8H17920v35a8,8,0,0,1-8,8Zm-38,0v-85a8,8,0,0,1,8-8h18a8,8,0,0,1,8,8v85Zm-38,0v-52a8,8,0,0,1,8-8h18a8,8,0,0,1,8,8v52Z'
            stroke='none'
          />
          <path
            d='M 17912 -16201 C 17915.859375 -16201 17919 -16204.140625 17919 -16208 L 17919 -16242.0009765625 L 17902.03125 -16242.0009765625 C 17898.171875 -16242.0009765625 17895.03125 -16238.8583984375 17895.03125 -16234.99609375 L 17895.03125 -16201 L 17912 -16201 M 17889.025390625 -16201 L 17889.025390625 -16285.001953125 C 17889.025390625 -16288.861328125 17885.884765625 -16292.001953125 17882.025390625 -16292.001953125 L 17864.029296875 -16292.001953125 C 17860.169921875 -16292.001953125 17857.029296875 -16288.861328125 17857.029296875 -16285.001953125 L 17857.029296875 -16201 L 17889.025390625 -16201 M 17851.029296875 -16201 L 17851.029296875 -16251.9990234375 C 17851.029296875 -16255.8583984375 17847.888671875 -16258.9990234375 17844.029296875 -16258.9990234375 L 17826.03125 -16258.9990234375 C 17822.169921875 -16258.9990234375 17819.02734375 -16255.8583984375 17819.02734375 -16251.9990234375 L 17819.02734375 -16201 L 17851.029296875 -16201 M 17912 -16200 L 17894.03125 -16200 L 17894.03125 -16234.99609375 C 17894.03125 -16239.4150390625 17897.611328125 -16243.0009765625 17902.03125 -16243.0009765625 L 17920 -16243.0009765625 L 17920 -16208 C 17920 -16203.58203125 17916.419921875 -16200 17912 -16200 Z M 17890.025390625 -16200 L 17856.029296875 -16200 L 17856.029296875 -16285.001953125 C 17856.029296875 -16289.416015625 17859.611328125 -16293.001953125 17864.029296875 -16293.001953125 L 17882.025390625 -16293.001953125 C 17886.4453125 -16293.001953125 17890.025390625 -16289.416015625 17890.025390625 -16285.001953125 L 17890.025390625 -16200 Z M 17852.029296875 -16200 L 17818.02734375 -16200 L 17818.02734375 -16251.9990234375 C 17818.02734375 -16256.41796875 17821.61328125 -16259.9990234375 17826.03125 -16259.9990234375 L 17844.029296875 -16259.9990234375 C 17848.447265625 -16259.9990234375 17852.029296875 -16256.41796875 17852.029296875 -16251.9990234375 L 17852.029296875 -16200 Z'
            fill='rgba(255,255,255,0.5)'
            stroke='none'
          />
        </g>
        <path
          d='M177.5,0h7.112A11.477,11.477,0,0,1,196.06,11.444v7.112A11.477,11.477,0,0,1,184.616,30H177.5A11.477,11.477,0,0,1,166.06,18.556V11.444A11.477,11.477,0,0,1,177.5,0Zm.093,7.091a1.037,1.037,0,0,0-1.036,1.036V21.87a1.037,1.037,0,0,0,1.036,1.036h6.924a1.037,1.037,0,0,0,1.036-1.036V8.127a1.037,1.037,0,0,0-1.036-1.036H177.6Zm3.462,12.621a.8.8,0,1,1-.8.8A.8.8,0,0,1,181.059,19.712Zm-3.364-1.46V8.223h6.731V18.252Zm-4.634-.914V10.1h2.358a.564.564,0,1,0,0-1.129h-2.575a.921.921,0,0,0-.646.268.907.907,0,0,0-.268.646V20.111a.92.92,0,0,0,.268.646.907.907,0,0,0,.646.268h2.575a.564.564,0,1,0,0-1.129h-2.358V18.472h2.358a.564.564,0,1,0,0-1.129h-2.358Zm16,0H186.7a.564.564,0,1,0,0,1.129h2.358v1.424H186.7a.564.564,0,1,0,0,1.129h2.575a.917.917,0,0,0,.914-.914V9.882a.921.921,0,0,0-.268-.646.911.911,0,0,0-.646-.268H186.7a.564.564,0,1,0,0,1.129h2.358v7.236Z'
          data-name='Path 5240'
          fill='#fff'
          fillRule='evenodd'
          id='Path_5240'
          transform='translate(388.916 1134)'
        />
      </g>
    </g>
  </svg>
);
export default IconPeopleDensityDashboard;

IconPeopleDensityDashboard.defaultProps = {
  height: 150,
  value: '25.447',
  width: 270,
};

IconPeopleDensityDashboard.propTypes = {
  height: PropTypes.number,
  value: PropTypes.string,
  width: PropTypes.number,
};
