import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Faisal <ahmad.faisal@bvarta.com>
 * @param {Object} props
 * @param {string} props.className
 * @param {string} props.onClick
 */

export default function IconConfig(props) {
  const { className, onClick } = props;

  return (
    <div className={`${className} icon-config`} onClick={onClick}>
      <svg
        height='18'
        id='Icon_filter_hor'
        viewBox='0 0 18 18'
        width='18'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M13,6a3,3,0,0,1-2.829-2H1A1,1,0,1,1,1,2h9.171a3,3,0,0,1,5.658,0H17a1,1,0,1,1,0,2H15.829A3.005,3.005,0,0,1,13,6Zm0-4a1,1,0,1,0,1,1A1,1,0,0,0,13,2Z'
          fill='#364961'
          id='Shape'
        />
        <path
          d='M5,6a3,3,0,0,1-2.83-2H1A1,1,0,1,1,1,2H2.171A3,3,0,0,1,7.829,2H17a1,1,0,1,1,0,2H7.829A3,3,0,0,1,5,6ZM5,2A1,1,0,1,0,6,3,1,1,0,0,0,5,2Z'
          data-name='Shape'
          fill='#364961'
          id='Shape-2'
          transform='translate(0 6)'
        />
        <path
          d='M13,6a3,3,0,0,1-2.829-2H1A1,1,0,1,1,1,2h9.171a3,3,0,0,1,5.658,0H17a1,1,0,1,1,0,2H15.829A3.005,3.005,0,0,1,13,6Zm0-4a1,1,0,1,0,1,1A1,1,0,0,0,13,2Z'
          data-name='Shape'
          fill='#364961'
          id='Shape-3'
          transform='translate(0 12)'
        />
      </svg>
    </div>
  );
}

IconConfig.defaultProps = {
  className: '',
  onClick: () => {},
};

IconConfig.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
