const MONTH_NAME = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
];

export function FormattingDatetime(date = '') {
  const now = new Date(date);
  const preDay = now.getDate();
  const preMonth = MONTH_NAME[now.getMonth()];
  const preYear = now.getFullYear();

  return `${preDay} ${preMonth} ${preYear}`;
}
