import React from 'react';
import { renderToString } from 'react-dom/server';

export const AdvanceSearchGeojsonPopupInternalComponent = (props) => {
  const { title, subTitle } = props;
  return renderToString(
    <div className='AdvanceSearchGeojsonPopupInternalComponent__wrap'>
      <div className='AdvanceSearchGeojsonPopupInternalComponent__icon'>
        <svg
          fill='none'
          height='16'
          viewBox='0 0 16 16'
          width='16'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M8.00159 0.888672C6.58796 0.888672 5.23224 1.4534 4.23265 2.45863C3.23307 3.46385 2.67151 4.82723 2.67151 6.24883C2.62406 7.2217 2.88501 8.1846 3.41674 8.99874L8.00159 15.1109L12.5864 9.00045C13.0923 8.17482 13.3511 7.22013 13.3317 6.25053C13.3319 5.54648 13.1942 4.84929 12.9264 4.19877C12.6587 3.54825 12.2661 2.95715 11.7711 2.45923C11.2762 1.96131 10.6885 1.56633 10.0417 1.29685C9.39493 1.02737 8.70169 0.888672 8.00159 0.888672V0.888672ZM8.00159 3.99371C8.44531 3.99371 8.87906 4.12605 9.24799 4.37397C9.61692 4.6219 9.90445 4.97428 10.0742 5.38656C10.244 5.79883 10.2884 6.25247 10.2017 6.69011C10.1151 7.12775 9.90136 7.52973 9.58755 7.84519C9.27373 8.16066 8.87393 8.37545 8.43872 8.46241C8.0035 8.54936 7.55242 8.50456 7.14252 8.33368C6.73263 8.1628 6.38233 7.87352 6.13593 7.50241C5.88954 7.13131 5.75811 6.69506 5.75828 6.24883C5.7585 5.65066 5.99495 5.07706 6.41563 4.65417C6.83631 4.23128 7.40677 3.99371 8.00159 3.99371Z'
            fill='url(#paint0_linear_8154_36471)'
          />
          <defs>
            <linearGradient
              gradientUnits='userSpaceOnUse'
              id='paint0_linear_8154_36471'
              x1='2.66602'
              x2='13.3327'
              y1='7.6906'
              y2='7.6906'
            >
              <stop stopColor='currentColor' />
              <stop offset='1' stopColor='#fff' />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className='AdvanceSearchGeojsonPopupInternalComponent__text-wrap'>
        <div className='AdvanceSearchGeojsonPopupInternalComponent__title'>
          {title}
        </div>
        <div>{subTitle}</div>
      </div>
    </div>
  );
};
