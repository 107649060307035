import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { Table } from 'antd';
import { tbodyData, theadData } from './dummyData';

export default function MainTable(props) {
  const { className, columns, dataSource, rowSelection, scroll } = props;

  return (
    <>
      <Table
        bordered={false}
        className={className}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        rowSelection={rowSelection}
        scroll={scroll}
        size='small'
      />
    </>
  );
}

MainTable.defaultProps = {
  className: 'main-table',
  columns: theadData,
  dataSource: tbodyData,
  loading: false,
  pageSize: 3,
  rowSelection: false,
  scroll: undefined,
};

MainTable.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  pageSize: PropTypes.number,
  rowSelection: oneOfType([PropTypes.bool, PropTypes.object]),
  scroll: PropTypes.shape({
    x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};
