import { useDispatch, useSelector } from 'react-redux';
import {
  doCreate,
  doDelete,
  doDetail,
  doDownload,
  doDownloadRow,
  doGetAll,
  doRename,
  doUpdate,
  doUpload,
} from '../store/gensetParameter.asyncAction';
import { GENSET_PARAMTER_ACTION } from '../store/gensetParameter.slice';

export const useParameter = () => {
  const state = useSelector((state) => state.features.GENSET.PARAMETER);
  const dispatch = useDispatch();

  const get = (param) => {
    dispatch(doGetAll(param));
  };

  const rename = (id, payload) => {
    const param = { id, payload };
    dispatch(doRename(param));
  };

  const remove = (id) => {
    dispatch(doDelete(id));
  };

  const download = (payload) => {
    dispatch(doDownload(payload));
  };

  const downloadRow = (payload) => {
    dispatch(doDownloadRow(payload));
  };

  const detail = (id) => {
    dispatch(doDetail(id));
  };

  const update = (id, payload) => {
    const param = { id, payload };
    dispatch(doUpdate(param));
  };

  const create = (payload) => {
    dispatch(doCreate(payload));
  };

  const upload = (query, payload) => {
    const param = {
      query,
      payload,
    };
    dispatch(doUpload(param));
  };

  const resetState = () => {
    dispatch(GENSET_PARAMTER_ACTION.resetState());
  };

  const resetStatus = () => {
    dispatch(GENSET_PARAMTER_ACTION.resetStatus());
  };

  return {
    state,
    get,
    rename,
    resetStatus,
    resetState,
    remove,
    download,
    downloadRow,
    detail,
    update,
    create,
    upload,
  };
};
