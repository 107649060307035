import { useDispatch, useSelector } from 'react-redux';
import {
  MAPP_TASK_ANALYZE_ACTION,
  MAPP_TASK_ANALYZE_CONSTANT,
} from '../../redux';

export function useMappTaskAnalyze() {
  const state = useSelector((state) => state.features.MAPP.TASK_ANALYZE);
  const dispatch = useDispatch();
  /**
   * @author Randa<m.randa@bvarta.com>
   * @copyright PT Bhumi Techonology
   * @param {object} param
   * @param {object} param.filter
   * @param {number} param.limit
   * @param {number} param.page
   * @param {object} param.search
   * @param {'ASC'|'DESC'} param.sort
   * @param {'ASC'|'DESC'} param.sort.by
   * @param {string} param.sort.name
   */
  const getListTask = (param) => {
    dispatch(MAPP_TASK_ANALYZE_ACTION.getListTask(param));
  };

  /**
   *
   * @param {string | number} jobId
   * @param {string} type
   */
  const cancelTask = (jobId, type) => {
    dispatch(MAPP_TASK_ANALYZE_ACTION.cancelOneTask(jobId, type));
  };

  /**
   *
   * @param {string | number} jobId
   */
  const deleteTask = (jobId) => {
    dispatch(MAPP_TASK_ANALYZE_ACTION.deleteOneTask(jobId));
  };

  const resetAll = () => {
    dispatch({ type: MAPP_TASK_ANALYZE_CONSTANT.RESET_ALL });
  };

  const resetStatus = () => {
    dispatch({ type: MAPP_TASK_ANALYZE_CONSTANT.RESET_STATUS });
  };

  /**
   *
   * @param {boolean} param
   */
  const setPopupVisibility = (param) => {
    dispatch(MAPP_TASK_ANALYZE_ACTION.setPopupVisibility(param));
  };

  return {
    state,
    getListTask,
    resetStatus,
    resetAll,
    cancelTask,
    deleteTask,
    setPopupVisibility,
  };
}
