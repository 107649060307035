import React from 'react';
import { Skeleton } from 'antd';

import './SharedBaseCardTypeOne.component.scss';
import { IconInfo } from '@bvt-assets/icon/icon-info';


/**
 * Props for SharedBaseCardTypeOneComponent.
 * @typedef {Object} ISharedBaseCardTypeOneComponentProps
 * @property {boolean} [showIcon] - Indicates whether to show the icon.
 * @property {React.ReactNode} [customIcon] - Custom icon to be displayed.
 * @property {React.ReactNode} [children] - Child components.
 * @property {React.ReactNode} [title] - Title to be displayed.
 * @property {boolean} [isLoading] - Indicates whether the component is in loading state.
 */

/**
 * SharedBaseCardTypeOneComponent component.
 * @param {ISharedBaseCardTypeOneComponentProps} props - Props for the component.
 * @param {React.Ref<HTMLDivElement>} ref - Reference to the HTML div element.
 * @returns {React.ReactElement} - SharedBaseCardTypeOneComponent component.
 */
export const SharedBaseCardTypeOneComponent = React.forwardRef((props, ref) => {
  const { children, showIcon, customIcon, title, isLoading } = props;

  return (
    <div className='SharedBaseCardTypeOneComponent__wrapper' ref={ref}>
      <div className='SharedBaseCardTypeOneComponent__top-bar'>
        {showIcon && (customIcon ? customIcon : <IconInfo fill='#007e75' />)}
        <div className='SharedBaseCardTypeOneComponent__top-title'>{title}</div>
      </div>
      <Skeleton active={true} loading={isLoading}>
        <div>{children}</div>
      </Skeleton>
    </div>
  );
});
SharedBaseCardTypeOneComponent.displayName = 'SharedBaseCardTypeOneComponent';
