import React from 'react';
import { ChartHourly } from './ChartHourly';
import { ChartDaily } from './ChartDaily';
import { ChartMonthly } from './ChartMonthly';


/**
 * @param {object} props
 * @param {'hourly'|'daily'|'monthly'} props.type
 * @param {{main: string, secondary: string, hover: string}} props.color
 * @param {{hour: number | undefined, time: string, counts: number | undefined, counts_event: number | undefined}[]} props.data
 */
export const Chart = (props) => {
  const { type, color, data = [] } = props;

  return (
    <React.Fragment>
      {type === 'hourly' && <ChartHourly color={color} data={data} />}
      {type === 'daily' && <ChartDaily color={color} data={data}  />}
      {type === 'monthly' && <ChartMonthly color={color} data={data} />}
    </React.Fragment>
  );
};
