export const LEGACY_AUTH_CONSTANT = {
  LOGIN_START: 'LI/AUTH/login/requested',
  LOGIN_FAILED: 'LI/AUTH/login/failed',
  LOGIN_SUCCESS: 'LI/AUTH/login/success',

  RESET_PASSWORD_START: 'LI/AUTH/reset-password/requested',
  RESET_PASSWORD_FAILED: 'LI/AUTH/reset-password/failed',
  RESET_PASSWORD_SUCCESS: 'LI/AUTH/reset-password/success',

  FORGOT_PASSWORD_START: 'LI/AUTH/forgot-password/requested',
  FORGOT_PASSWORD_FAILED: 'LI/AUTH/forgot-password/failed',
  FORGOT_PASSWORD_SUCCESS: 'LI/AUTH/forgot-password/success',

  CHECK_TOKEN_START: 'LI/AUTH/check-token/requested',
  CHECK_TOKEN_FAILED: 'LI/AUTH/check-token/failed',
  CHECK_TOKEN_SUCCESS: 'LI/AUTH/check-token/success',

  LOGOUT_START: 'LI/AUTH/logout/requested',
  LOGOUT_FAILED: 'LI/AUTH/logout/failed',
  LOGOUT_SUCCESS: 'LI/AUTH/logout/success',

  GET_SETTING_START: 'LI/AUTH/setting/get/requested',
  GET_SETTING_FAILED: 'LI/AUTH/setting/get/failed',
  GET_SETTING_SUCCESS: 'LI/AUTH/setting/get/success',

  UPDATE_SETTING_START: 'LI/AUTH/setting/update/requested',
  UPDATE_SETTING_FAILED: 'LI/AUTH/setting/update/failed',
  UPDATE_SETTING_SUCCESS: 'LI/AUTH/setting/update/success',

  RESET_STATUS: 'LI/AUTH/reset-status',
  RESET_ALL: 'LI/AUTH/reset-all',
  UNAUTHORIZED: 'LI/AUTH/unauthorized',
};
