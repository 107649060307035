import _ from 'lodash';
import React, { useRef, useEffect } from 'react';
import { TileLayer } from 'react-leaflet';
import { useMainMap } from '../../hook/useMainMap';

export const MainMapTileLayerContainer = () => {
  const mainmap = useMainMap();
  const tileRef = useRef(null);

  useEffect(() => {
    if (tileRef.current) {
      tileRef.current.setUrl(
        _.find(
          mainmap.state.baseMapList,
          (v) => v.key === mainmap.state.selectedBaseMap
        ).value
      );
    }
  }, [mainmap.state.selectedBaseMap]);

  return (
    <React.Fragment>
      <TileLayer
        keepBuffer={5}
        opacity={1}
        ref={tileRef}
        tileSize={256}
        url={`${
          _.find(
            mainmap.state.baseMapList,
            (v) => v.key === mainmap.state.selectedBaseMap
          )?.value
        }`}
      />
    </React.Fragment>
  );
};
