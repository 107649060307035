import { DomEvent } from 'leaflet';
import { createPortal } from 'react-dom';
import { useMap } from 'react-leaflet';


import { useDispatch, useSelector } from 'react-redux';
import { MAINMAP_LEFT_SIDE_PORTAL_CONSTANT } from '../../constant/MAINMAP_LEFT_SIDE_PORTAL_CONSTANT';
import { MAINMAP_TOP_SIDE_PORTAL_CONSTANT } from '../../constant/MAINMAP_TOP_SIDE_PORTAL_CONSTANT';
import { mainmapAction } from '../../store/mainmap.slice';

export const useMainMap = () => {
  const mapInstance = useMap();
  const state = useSelector((state) => state.features.SUBMODULE.mainMap);
  const dispatch = useDispatch();

  const disableMapEvent = (ref) => {
    DomEvent.disableScrollPropagation(ref);
    DomEvent.disableClickPropagation(ref);
  };

  const mountToPortalLeftSide = (param) => {
    return document.getElementById(MAINMAP_LEFT_SIDE_PORTAL_CONSTANT) && createPortal(
      param,
      document.getElementById(MAINMAP_LEFT_SIDE_PORTAL_CONSTANT)
    );
  };

  const mountToFloatTop = (param) => {
    return createPortal(
      param,
      document.getElementById(MAINMAP_TOP_SIDE_PORTAL_CONSTANT)
    );
  };

  const toggleBaseMapControl = (param) => {
    dispatch(mainmapAction.toggleBaseMapControl({ isOpen: param?.isOpen }));
  };

  const switchBaseMap = (param) => {
    dispatch(mainmapAction.changeBaseMap({ key: param?.key }));
  };

  return {
    mapInstance,
    disableMapEvent,
    mountToPortalLeftSide,
    mountToFloatTop,
    state,
    toggleBaseMapControl,
    switchBaseMap,
  };
};
