import React from 'react';
import PropTypes from 'prop-types';
import { Loading } from '@bvt-atoms';

export default function Button(props) {
  const {
    children,
    className,
    isLoading,
    name,
    onClick,
    type,
    src,
    svg,
    position,
  } = props;
  const customClass = `${className}`;
  return (
    <button
      className={customClass}
      name={name}
      onClick={onClick}
      src={src}
      type={type}
    >
      {position === 'left' && (
        <>
          {src !== '' ? <img src={src} /> : <></>}
          {svg !== null ? svg : <></>}
        </>
      )}
      {isLoading ? <Loading className='loading-small' /> : children}
      {position === 'right' && (
        <>
          {src !== '' ? <img src={src} /> : <></>}
          {svg !== null ? svg : <></>}
        </>
      )}
    </button>
  );
}

Button.defaultProps = {
  children: null,
  className: '',
  isLoading: false,
  name: '',
  onClick: () => {},
  position: 'right',
  src: '',
  svg: null,
  type: 'button',
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
  position: PropTypes.string,
  src: PropTypes.string,
  svg: PropTypes.node,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
};
