import { createSlice } from '@reduxjs/toolkit';
import {
  doCreate,
  doDelete,
  doDetail,
  doDownload,
  doDownloadRow,
  doGetAll,
  doRename,
  doUpdate,
  doUpload,
} from './gensetParameter.asyncAction';
import * as _ from 'lodash';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Ryfan <ryfan.aditya@bvarta.com>
 */

/**
 * @typedef initialStateModel
 * @type {Object}
 * @property {'IDLE'|'SUCCESS'|'FAILED'|'LOADING'} status_GET
 * @property {'IDLE'|'SUCCESS'|'FAILED'|'LOADING'} status_POST
 * @property {'IDLE'|'SUCCESS'|'FAILED'|'LOADING'} status_PUT
 * @property {'IDLE'|'SUCCESS'|'FAILED'|'LOADING'} status_PATCH
 * @property {'IDLE'|'SUCCESS'|'FAILED'|'LOADING'} status_DETAIL
 * @property {'IDLE'|'SUCCESS'|'FAILED'|'LOADING'} status_DELETE
 * @property {'IDLE'|'SUCCESS'|'FAILED'|'LOADING'} status_DOWNLOAD
 * @property {'IDLE'|'SUCCESS'|'FAILED'|'LOADING'} status_DOWNLOAD_ROW
 * @property {'IDLE'|'SUCCESS'|'FAILED'|'LOADING'} status_UPLOAD
 * @property {String} message
 * @property {Array<{id:Number,name:String,created_at:String,updated_at:String,project_type:String}>} list
 * @property {Array<{id:Number,name:String,created_at:String,updated_at:String,project_type:String}>} listAll
 */

/**
 * @type {initialStateModel} initialState
 */
const initialState = {
  status_GET: 'IDLE',
  status_POST: 'IDLE',
  status_PUT: 'IDLE',
  status_PATCH: 'IDLE',
  status_DETAIL: 'IDLE',
  status_DELETE: 'IDLE',
  status_DOWNLOAD: 'IDLE',
  status_DOWNLOADROW: 'IDLE',
  status_UPLOAD: 'IDLE',
  message: '',
  url: '',
  list: [],
  listAll: [],
  detail: {},
  properties: {},
  errorUploadField: ''
};

export const genset_parameter_slice = createSlice({
  name: 'LI/GENSET/PARAMETER/',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
    resetStatus(state, action) {
      switch (action.type) {
        case 'status_GET':
          state.status_GET = 'IDLE';
          break;
        case 'status_POST':
          state.status_POST = 'IDLE';
          break;
        case 'status_PUT':
          state.status_PUT = 'IDLE';
          break;
        case 'status_PATCH':
          state.status_PATCH = 'IDLE';
          break;
        case 'status_DETAIL':
          state.status_DETAIL = 'IDLE';
          break;
        case 'status_DELETE':
          state.status_DELETE = 'IDLE';
          break;
        case 'status_DOWNLOAD':
          state.status_DOWNLOAD = 'IDLE';
          break;
        case 'status_DOWNLOAD_ROW':
          state.status_DOWNLOAD_ROW = 'IDLE';
          break;
        case 'status_UPLOAD':
          state.status_UPLOAD = 'IDLE';
          break;
        default:
          state.status_GET = 'IDLE';
          state.status_POST = 'IDLE';
          state.status_PUT = 'IDLE';
          state.status_PATCH = 'IDLE';
          state.status_DETAIL = 'IDLE';
          state.status_DELETE = 'IDLE';
          state.status_DOWNLOAD = 'IDLE';
          state.status_DOWNLOAD_ROW = 'IDLE';
          state.status_UPLOAD = 'IDLE';
          break;
      }
      return state;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(doGetAll.pending, (state) => {
        state.status_GET = 'LOADING';
      })
      .addCase(doGetAll.fulfilled, (state, action) => {
        const { results, message, meta } = action.payload;
        state.list = results?.map((val, idx) => ({ ...val, key: idx })) || [];
        state.listAll = _.uniqBy([...state.listAll, ...results], 'id_parameter');
        state.status_GET = 'SUCCESS';
        state.message = message;
        state.properties = meta;
        state.detail = [];
      })
      .addCase(doGetAll.rejected, (state, action) => {
        const { message } = action.payload;
        state.status_GET = 'FAILED';
        state.message = message;
      })
      .addCase(doRename.pending, (state) => {
        state.status_PATCH = 'LOADING';
      })
      .addCase(doRename.fulfilled, (state, action) => {
        const { message } = action.payload;
        state.status_PATCH = 'SUCCESS';
        state.message = message;
      })
      .addCase(doRename.rejected, (state, action) => {
        const { response } = action.payload;
        state.status_PATCH = 'FAILED';
        state.message = response.data.explain[0].rule;
      })
      .addCase(doDelete.pending, (state) => {
        state.status_DELETE = 'LOADING';
      })
      .addCase(doDelete.fulfilled, (state, action) => {
        const { message } = action.payload;
        state.status_DELETE = 'SUCCESS';
        state.message = message;
      })
      .addCase(doDelete.rejected, (state, action) => {
        const { response } = action.payload;
        state.status_DELETE = 'FAILED';
        state.message = response.data.message;
      })
      .addCase(doDownload.pending, (state) => {
        state.status_DOWNLOAD = 'LOADING';
      })
      .addCase(doDownload.fulfilled, (state, action) => {
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        state.status_DOWNLOAD = 'SUCCESS';
        state.url = url;
      })
      .addCase(doDownload.rejected, (state, action) => {
        const { response } = action.payload;
        state.status_DOWNLOAD = 'FAILED';
        state.message = response.data.message;
      })
      .addCase(doDownloadRow.pending, (state) => {
        state.status_DOWNLOAD_ROW = 'LOADING';
      })
      .addCase(doDownloadRow.fulfilled, (state, action) => {
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        state.status_DOWNLOAD_ROW = 'SUCCESS';
        state.url = url;
      })
      .addCase(doDownloadRow.rejected, (state, action) => {
        const { response } = action.payload;
        state.status_DOWNLOAD_ROW = 'FAILED';
        state.message = response.data.message;
      })
      .addCase(doDetail.pending, (state) => {
        state.status_DETAIL = 'LOADING';
      })
      .addCase(doDetail.fulfilled, (state, action) => {
        const { message, result } = action.payload;
        state.status_DETAIL = 'SUCCESS';
        state.message = message;
        state.detail = result;
      })
      .addCase(doDetail.rejected, (state, action) => {
        const { response } = action.payload;
        state.status_DETAIL = 'FAILED';
        state.message = response.data.message;
      })
      .addCase(doUpdate.pending, (state) => {
        state.status_PUT = 'LOADING';
      })
      .addCase(doUpdate.fulfilled, (state, action) => {
        const { message } = action.payload;
        state.status_PUT = 'SUCCESS';
        state.message = message;
      })
      .addCase(doUpdate.rejected, (state, action) => {
        const { response } = action.payload;
        state.status_PUT = 'FAILED';
        state.message = response.data.explain[0].rule;
      })
      .addCase(doCreate.pending, (state) => {
        state.status_POST = 'LOADING';
      })
      .addCase(doCreate.fulfilled, (state, action) => {
        const { message } = action.payload;
        state.status_POST = 'SUCCESS';
        state.message = message;
      })
      .addCase(doCreate.rejected, (state, action) => {
        const { response } = action.payload;
        state.status_POST = 'FAILED';
        state.message = response.data.explain[0].rule;
      })
      .addCase(doUpload.pending, (state) => {
        state.status_UPLOAD = 'LOADING';
      })
      .addCase(doUpload.fulfilled, (state, action) => {
        const { message } = action.payload;
        state.status_UPLOAD = 'SUCCESS';
        state.message = message;
      })
      .addCase(doUpload.rejected, (state, action) => {
        const { response } = action.payload;
        state.status_UPLOAD = 'FAILED';
        const errorMessage = {
          type: 'Make sure the file is in .txt format, please review and reupload a new file.',
          name: 'A file with this name already exists. Please rename and reupload the file.',
          data: 'The file contains some incorrect information, please review and reupload a new file.',
          parameter: 'The file contains some incorrect information, please review and reupload a new file.',
        };
        const explain = response.data?.explain?.[0];
        const field = explain?.field;

        state.errorUploadField = field;
        state.message = field && errorMessage[field]
          ? errorMessage[field]
          : response.data?.message;
      }),
});

export const {
  reducer: GENSET_PARAMETER_REDUCER,
  actions: GENSET_PARAMTER_ACTION,
} = genset_parameter_slice;
