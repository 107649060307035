import { Skeleton } from 'antd';
import React from 'react';
import './BaseSelectedCategoryCard.scss';

/**
 * @copyright PT Bhumi Varta TEchnology
 * @author Malik<malik.ibrahim@bvarta.com>
 */

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {string} props.icon
 * @param {string} props.description
 * @param {()=>void} props.onClear
 * @param {boolean} props.showDescription
 * @param {()=>void} props.showAction
 * @param {()=>void} props.onSelectAll
 */

export const BaseSelectedCategoryCard = (props) => {
  const {
    title,
    icon,
    description,
    showDescription,
    showAction,
    onSelectAll,
    onClear,
  } = props;

  return (
    <div className='BaseSelectedCategoryCard'>
      <div className='BaseSelectedCategoryCard__header'>
        {'Currently Selected'}
      </div>
      <div className='BaseSelectedCategoryCard__body'>
        <div className='BaseSelectedCategoryCard__icon'>
          <Skeleton
            active={true}
            loading={!title}
            paragraph={false}
            style={{ width: '2rem', height: '2rem' }}
            type='image'
          >
            <img alt='icon datex' src={icon} />
          </Skeleton>
        </div>
        <div className='BaseSelectedCategoryCard__text'>
          <Skeleton
            active={true}
            loading={!title}
            paragraph={false}
            style={{ width: '10rem', height: '2rem' }}
            type='square'
          >
            <div className='BaseSelectedCategoryCard__text--title'>{title}</div>
          </Skeleton>
          {showDescription && (
            <div className='BaseSelectedCategoryCard__text--description'>
              <Skeleton
                active={true}
                loading={!description}
                paragraph={false}
                style={{ width: '5rem', height: '2rem' }}
                type='image'
              >
                <span className='BaseSelectedCategoryCard__text--description-item'>
                  {description}
                </span>
              </Skeleton>
            </div>
          )}
        </div>
      </div>
      <div className='BaseSelectedCategoryCard__body'>
        <div className='BaseSelectedCategoryCard__text'>
          {showAction && (
            <div className='BaseSelectedCategoryCard__text--action'>
              <span
                className='BaseSelectedCategoryCard__text--action-item'
                onClick={onSelectAll}
              >
                Select all
              </span>
              <span
                className='BaseSelectedCategoryCard__text--action-item'
                onClick={onClear}
              >
                Clear Selection
              </span>
            </div>
          )}
        </div>
      </div>
      <div className='BaseSelectedCategoryCard__footer'>{}</div>
    </div>
  );
};
