import React from 'react';
import './BaseSearchCardDatex.scss';
import { SearchBox } from '@bvt-shared/component/SearchBox';
/**
 * @company PT Bhumi Varta Technology
 * @author Malik<malik.ibrahim@bvarta.com>
 */

/**
 *
 * @param {Object} props
 * @param {(value:string) => void} props.onSearch
 * @param {string} props.placeholder
 */
export const BaseSearchCardDatex = (props) => {
  const { onSearch, placeholder } = props;
  return (
    <div className='BaseSearchCardDatex__search'>
      <SearchBox
        handler={{ onSearch, onClear: () => onSearch('') }}
        placeholder={placeholder ?? 'Search'}
        withButtonText={false}
      />
    </div>
  );
};
