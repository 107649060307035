import React from 'react';

import { SharedBaseCardTypeOneComponent } from '@bvt-shared/component/SharedBaseCardTypeOne.component';
import './AsmanCardDemographyCathment.component.scss';

export const AsmanCardDemographyCathmentComponent = props => {
  const { data, isLoading } = props;
  const percentageMale = (
    ((data.male || 0) / (data.female + data.male)) *
    100
  ).toPrecision(3);
  const percentageFemale = (
    ((data.female || 0) / (data.female + data.male)) *
    100
  ).toPrecision(3);
  return (
    <SharedBaseCardTypeOneComponent
      isLoading={isLoading}
      showIcon
      title={
        <React.Fragment>
          <div className='AsmanCardDemographyCathmentComponent__top-title'>
            <span>Demography catchment</span>
            <span className='AsmanCardDemographyCathmentComponent__title-accent'>
              {data.source ?? 'Dukcapil'}
            </span>
          </div>
        </React.Fragment>
      }
    >
      <div className='AsmanCardDemographyCathmentComponent__wrapper'>
        <div className='AsmanCardDemographyCathmentComponent__chart-total'>
          <div>TOTAL</div>
          <div>{(data.male + data.female).toLocaleString('id')}</div>
        </div>
        <div className='AsmanCardDemographyCathmentComponent__percent'>
          <div>
            {data.male.toLocaleString('id')} ({percentageMale}%)
          </div>
          <div>{(data.male + data.female).toLocaleString('id')}</div>
          <div>
            {data.female.toLocaleString('id')} ({percentageFemale}%)
          </div>
        </div>
        <div className='AsmanCardDemographyCathmentComponent__chart'>
          <div
            className='AsmanCardDemographyCathmentComponent__chart-male'
            style={{ width: `${percentageMale}%` }}
          >
            Male
          </div>
          <div
            className='AsmanCardDemographyCathmentComponent__chart-female'
            style={{ width: `${percentageFemale}%` }}
          >
            Female
          </div>
        </div>
      </div>
    </SharedBaseCardTypeOneComponent>
  );
};
