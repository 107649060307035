export const NOTIFICATION_DUMMY = [
  {
    id: 1,
    type: 'info',
    created_at: '2022-05-12 16:23:00',
    title: 'Free Trial for 30 Days',
    from: 'Administrator',
    body: '<p>Dear customers.</p><br /><p>Congratulations!,</p><p>For existing customers, we have provided a trial feature for our new module, which can be used for free. To try it, please click the following link:</p><br /><br /><p>Thanks,</p><p>Administrator</p>',
  },
  {
    id: 2,
    type: 'update',
    created_at: '2022-05-18 10:25:00',
    title: 'Update Message',
    from: 'Administrator',
    body: '<p>Dear customers.</p><br /><p>Congratulations!,</p><br /><p>You have got new updates</p>',
  },
  {
    id: 3,
    type: 'update',
    created_at: '2022-05-11 10:25:00',
    title: 'Update Message',
    from: 'Administrator',
    body: '<p>Dear customers.</p><br /><p>Congratulations!,</p><br /><p>You have got new updates</p>',
  },
];
