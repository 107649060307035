import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Select } from 'antd';
import { IconSearchTypeTwo } from '@bvt-assets/icon/icon-search-2';

import './AdvanceSearchMapSearchInput.component.scss';
import { useDebounceValue } from '@bvt-hooks';

export const AdvanceSearchMapSearchInputComponent = React.forwardRef(
  (props, ref) => {
    const {
      onAdvanceClick,
      onSearch,
      searchOptions,
      isAdvanceLoading,
      isSearchLoading,
      onSearchClick,
    } = props;
    const [state, setState] = useState({
      search: undefined,
      selected: undefined,
    });

    const debounceSearch = useDebounceValue(state, 500);

    useEffect(() => {
      if (debounceSearch.search) {
        typeof onSearch === 'function' && onSearch(debounceSearch.search);
      }
    }, [debounceSearch.search]);

    const internalHandleSearchClick = (e) => {
      onSearchClick && onSearchClick(e);
      setState({ ...state, selected: e });
    };

    return (
      <div className='AdvanceSearchMapSearchInputComponent__wrapper' ref={ref}>
        <Row gutter={[8, 8]}>
          <Col xs={{ span: 17 }}>
            <Select
              className='AdvanceSearchMapSearchInputComponent__select'
              disabled={isAdvanceLoading}
              filterOption={(inpt, opt) =>
                opt?.label?.toUpperCase()?.includes(inpt.toUpperCase())
                  ? true
                  : false
              }
              loading={isSearchLoading}
              notFoundContent={''}
              onChange={(e) => internalHandleSearchClick(e)}
              onSearch={(v) => setState({ ...state, search: v })}
              options={searchOptions}
              placeholder='Try Banten'
              searchValue={state.search}
              showSearch
              style={{ width: '100%', borderRadius: '1rem' }}
              suffixIcon={
                !isSearchLoading ? (
                  <span className='anticon'>
                    <IconSearchTypeTwo />
                  </span>
                ) : undefined
              }
              value={state.selected}
            />
          </Col>
          <Col xs={{ span: 7 }}>
            <Button
              className='AdvanceSearchMapSearchInputComponent__button'
              loading={isAdvanceLoading}
              onClick={() => {
                typeof onAdvanceClick === 'function' && onAdvanceClick();
              }}
            >
              Advance
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
);

AdvanceSearchMapSearchInputComponent.displayName =
  AdvanceSearchMapSearchInputComponent.name;
