import { useDispatch, useSelector } from 'react-redux';

import {
  doDeleteProject,
  doDuplicateProject,
  doGetAllProject,
  doGetRangeDateExistProject,
  doRenameProject,
} from '../store/mainOpen.asyncAction';
import { MAIN_OPEN_PROJECT_ACTION } from '../store/mainOpen.slice';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta <renta.yustika@bvarta.com>
 * @author Ryfan <ryfan.aditya@bvarta.com>
 * @author Riza <mochamad.riza@bvarta.com>
 */

export const useMainOpenProject = () => {
  const state = useSelector(
    /**
     * @param {ReturnType<typeof import('../../../../redux/rootStore').rootStore.getState>} state
     */
    (state) => state.features.MAPP.OPEN_PROJECT
  );

  const dispatch = useDispatch();

  /**
   * @description For meta project
   * @param {Object} param
   * @param {Object} param.meta
   * @param {Object} param.meta.filter
   * @param {String} param.meta.filter.name
   * @param {Object} param.meta.filter.rangeDate
   * @param {String} param.meta.filter.rangeDate.start
   * @param {String} param.meta.filter.rangeDate.end
   * @param {Number} param.meta.page
   * @param {Number} param.meta.page
   * @param {Object} param.meta.sort
   * @param {String} param.meta.sort.by
   * @param {String} param.meta.sort.type
   */
  const getAllProject = (param) => {
    dispatch(doGetAllProject(param));
  };

  /**
   * @description For duplicate payload
   * @param {Object} param
   * @param {Number} param.id
   * @param {Object} param.name
   * @param {String} param.payload.name
   */
  const duplicateProject = (id, payload) => {
    const param = {
      id,
      payload
    };
    dispatch(doDuplicateProject(param));
  };

  /**
   * @description For rename payload
   * @param {Object} param
   * @param {Number} param.id
   * @param {Object} param.payload
   * @param {String} param.payload.name
   */
  const renameProject = (id, payload) => {
    const param = {
      id,
      payload
    };
    dispatch(doRenameProject(param));
  };

  /**
   * @description For delete id
   * @param {Number} id
   */
  const deleteProject = (id) => {
    dispatch(doDeleteProject(id));
  };

  /**
   * @description For get range date param
   * @param {Object} param
   * @param {Object} param.meta
   * @param {Object} param.meta.filter
   * @param {String} param.meta.filter.type
   * @param {String} param.meta.filter.name
   * @param {Object} param.meta.filter.rangeDate
   * @param {String} param.meta.filter.rangeDate.start
   * @param {String} param.meta.filter.rangeDate.end
   * @param {String} param.meta.filter.project_type
   * @param {String} param.meta.filter.year_month
   */
  const getRangeDate = (param) => {
    dispatch(doGetRangeDateExistProject(param));
  };

  const resetState = () => {
    dispatch(MAIN_OPEN_PROJECT_ACTION.resetState());
  };

  const resetStatus = () => {
    dispatch(MAIN_OPEN_PROJECT_ACTION.resetStatus());
  };

  return {
    state,
    getAllProject,
    duplicateProject,
    renameProject,
    deleteProject,
    getRangeDate,
    resetState,
    resetStatus
  };
};
