import { MAPP_PROJECT_CONSTANT } from '@bvt-features/mapp/project/redux';
import { MappProjectService } from '../service';

const projectService = new MappProjectService();

export const MAPP_PROJECT_ACTIONS = {
  create: (payload) => {
    return async (dispatch) => {
      dispatch({
        type: MAPP_PROJECT_CONSTANT.CREATE_START,
        payload: { status_POST: 'LOADING' },
      });
      try {
        const data = await projectService.create(payload);
        dispatch({
          type: MAPP_PROJECT_CONSTANT.CREATE_SUCCESS,
          payload: {
            status_POST: 'SUCCESS',
            message: data.message,
          },
        });
      } catch (error) {
        dispatch({
          type: MAPP_PROJECT_CONSTANT.CREATE_FAILED,
          payload: {
            status_POST: 'FAILED',
            message: error.response.data.message,
          },
        });
      }
    };
  },

  getDetail: (id) => {
    return async (dispatch) => {
      dispatch({
        type: MAPP_PROJECT_CONSTANT.GET_DETAIL_START,
        payload: { status_DETAIL: 'LOADING' },
      });
      try {
        const data = await projectService.detail(id);
        dispatch({
          type: MAPP_PROJECT_CONSTANT.GET_DETAIL_SUCCESS,
          payload: {
            status_DETAIL: 'SUCCESS',
            detail: data.result,
            message: data.message,
          },
        });
      } catch (error) {
        dispatch({
          type: MAPP_PROJECT_CONSTANT.GET_DETAIL_FAILED,
          payload: {
            status_DETAIL: 'FAILED',
            message: error.response.data.message,
          },
        });
      }
    };
  },

  rename: (id, payload) => {
    return async (dispatch) => {
      dispatch({
        type: MAPP_PROJECT_CONSTANT.PATCH_RENAME_START,
        payload: { status_PATCH: 'LOADING' },
      });
      try {
        const data = await projectService.rename(id, payload);
        dispatch({
          type: MAPP_PROJECT_CONSTANT.PATCH_RENAME_SUCCESS,
          payload: {
            status_PATCH: 'SUCCESS',
            detail: data.result,
            message: data.message,
          },
        });
      } catch (error) {
        dispatch({
          type: MAPP_PROJECT_CONSTANT.PATCH_RENAME_FAILED,
          payload: {
            status_PATCH: 'FAILED',
            message: error.response.data.message,
          },
        });
      }
    };
  },

  /**
   * @param {object} param
   * @param {number | string} param.projectId
   * @param {string} param.base64
   * @param {'pdf' | 'csv' | "xlsx"} param.type
   */
  generateReport: (param = {}) => {
    return async () => {
      try {
        await projectService.generateReport(param);
      } catch (error) {}
    };
  },

  getAll: (payload) => {
    return async (dispatch) => {
      dispatch({
        type: MAPP_PROJECT_CONSTANT.GET_ALL_START,
        payload: {
          status_GET: 'LOADING',
        }
      });

      try {
        const data = await projectService.getAll(payload);
        dispatch({
          type: MAPP_PROJECT_CONSTANT.GET_ALL_SUCCESS,
          payload: {
            status_GET: 'SUCCESS',
            list: data.results,
            message: data.message,
            properties: data.meta,
            detail: [],
          }
        });
      } catch (error) {
        dispatch({
          type: MAPP_PROJECT_CONSTANT.GET_ALL_FAILED,
          payload: {
            status_GET: 'FAILED',
            message: error?.response?.data?.message
          }
        });
      }
    };
  },
};
