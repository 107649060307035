import React from 'react';
import { Marker } from 'react-leaflet';
import PropTypes from 'prop-types';
import L from 'leaflet';
import './MarkerSumPoi.scss';
import { renderToStaticMarkup } from 'react-dom/server';
import { Loading } from '@bvt-features/account/component';

export default function MarkerSumPoi(props) {
  const icon = new L.DivIcon({
    html: props.isLoading
      ? renderToStaticMarkup(<Loading />)
      : `<span>${props.total}</span>`,
    className: 'feature-main-map MarkerSumPoi',
    iconSize: [40, 40],
  });

  return (
    <React.Fragment>
      {props.position && props.total ? (
        <Marker
          eventHandlers={{
            click: () => props.onClick && props.onClick(props.gid),
          }}
          icon={icon}
          position={props.position}
        />
      ) : (
        ''
      )}
    </React.Fragment>
  );
}

MarkerSumPoi.propTypes = {
  gid: PropTypes.number,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  position: PropTypes.arrayOf(PropTypes.number),
  total: PropTypes.number,
};
