import React from 'react';
import { Table } from 'antd';

import { SharedBaseCardTypeOneComponent } from '@bvt-shared/component/SharedBaseCardTypeOne.component';
import './AsmanCardGenderByAge.component.scss';

const columns = [
  {
    title: 'GENDER',
    dataIndex: 'title',
    key: 'title',
    width: 100,
    className: 'AsmanCardGenderByAgeComponent__cell-gender',
    render: (_, { icon, title, rangeAge }) => (
      <React.Fragment>
        <div className='AsmanCardGenderByAgeComponent__wrapper-gender'>
          <div>{icon}</div>
          <div>{title}</div>
          <div>{rangeAge}</div>
        </div>
      </React.Fragment>
    )
  },
  {
    title: 'MALE',
    dataIndex: 'male',
    key: 'male',
    className: 'AsmanCardGenderByAgeComponent__cell-male',
    render: (_, { male, female }) => {
      const percentage = (((male || 0) / (female + male)) * 100).toPrecision(3);
      return (
        <React.Fragment>
          <div className='AsmanCardGenderByAgeComponent__wrapper-male'>
            <div>{male.toLocaleString('id')}</div>
            <div>{percentage}%</div>
          </div>
        </React.Fragment>
      );
    }
  },
  {
    title: 'FEMALE',
    dataIndex: 'female',
    key: 'female',
    className: 'AsmanCardGenderByAgeComponent__cell-female',
    render: (_, { female, male }) => {
      const percentage = (((female || 0) / (female + male)) * 100).toPrecision(
        3
      );
      return (
        <React.Fragment>
          <div className='AsmanCardGenderByAgeComponent__wrapper-female'>
            <div>{female.toLocaleString('id')}</div>
            <div>{percentage}%</div>
          </div>
        </React.Fragment>
      );
    }
  },
  {
    title: 'TOTAL',
    dataIndex: 'total',
    key: 'total',
    className: 'AsmanCardGenderByAgeComponent__cell-total',
    render: (_, { female, male }) => (
      <div>{(female + male).toLocaleString('id')}</div>
    )
  }
];

export const AsmanCardGenderByAgeComponent = props => {
  const { data, isLoading } = props;
  return (
    <SharedBaseCardTypeOneComponent
      isLoading={isLoading}
      showIcon
      title='Gender by Age'
    >
      <Table
        className='AsmanCardGenderByAgeComponent'
        columns={columns}
        dataSource={data}
        pagination={false}
        rowClassName='AsmanCardGenderByAgeComponent__row'
      />
    </SharedBaseCardTypeOneComponent>
  );
};
