import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './ResetPasswordForm.scss';
import AuthInput from '../AuthInput';
import { useLottie } from 'lottie-react';
import { UpdatePasswordComplete } from '@bvt-animations';
import { PasswordBar } from '@bvt-atoms';
import { useFormik } from 'formik';
import { passwordScore } from '@bvt-features/auth/helper';
import * as yup from 'yup';
import { Alert } from 'antd';
/**
 *
 * @param {object} props
 * @param {string} props.className
 * @param {Boolean} props.isLoading
 * @param {import('react').CSSProperties} props.style
 * @param {(e:{password:string,password_confirmation:string})=>void} props.onSubmit
 * @param {(e)=>void} props.onBack
 * @param {string} props.message
 * @param {(e)=>void} props.dismissMessage
 * @returns
 */
const ResetPasswordForm = (props) => {
  const {
    className,
    style,
    onSubmit,
    onBack,
    isLoading,
    onDismissMessage,
    message,
  } = props;

  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirmation: '',
    },
    validationSchema: yup.object().shape({
      password: yup
        .string()
        .required('Password is Required field')
        .test(
          'passowrd-str',
          'Password strength is not strong enough.',
          (value) => passwordScore(value).score > 1
        ),
      password_confirmation: yup
        .string()
        .required('Password confirmation is Required field')
        .oneOf(
          [yup.ref('password'), null],
          "Password confirmation doesn't match"
        ),
    }),
    onSubmit: (e) => {
      onSubmit && onSubmit(e);
    },
  });
  const Ani1 = useLottie({
    animationData: UpdatePasswordComplete,
    className: 'feature-auth ResetPasswordForm__reset-lottie',
    loop: false,
    autoplay: false,
  });

  useEffect(() => {}, []);

  let dt = passwordScore(formik.values.password);

  return (
    <div
      className={`
      feature-auth ResetPasswordForm feature-auth ResetPasswordForm__wrapper 
      ${className}`}
      style={style}
    >
      <div className='feature-auth ResetPasswordForm__heading'>
        Reset Password
      </div>
      <form
        className='feature-auth ResetPasswordForm__container'
        onSubmit={(e) => {
          e.preventDefault();
          formik.submitForm();
        }}
      >
        <div className='feature-auth ResetPasswordForm__reset-title-container'>
          <div className='feature-auth ResetPasswordForm__reset-title'>
            <div className='title'>Create a new password</div>
            <div className='description'>Enter you new password</div>
          </div>
          {Ani1.View}
        </div>
        <AuthInput
          icon='ic-password'
          onChange={(e) => formik.setFieldValue('password', e.target.value)}
          onClear={() => {
            formik.setFieldValue('password', '');
          }}
          showShowHide
          title={'New Password'}
          type={'password'}
          value={formik.values.password}
        />
        <PasswordBar
          barColor={formik.values.password && dt.color}
          height='0.25'
          progress={String(formik.values.password ? dt.percentage : 0)}
          status={formik.values.password && dt.str}
        />
        <AuthInput
          icon='ic-password'
          onChange={(e) =>
            formik.setFieldValue('password_confirmation', e.target.value)
          }
          onClear={() => {
            formik.setFieldValue('password_confirmation', '');
          }}
          showShowHide
          title='Confirm'
          type={'password'}
          value={formik.values.password_confirmation}
        />
        {message && (
          <Alert
            closable
            className='feature-auth ResetPasswordForm__password-warner has-error'
            message={message}
            onClose={() => onDismissMessage && onDismissMessage()}
            type='error'
          />
        )}
        <div
          className={`feature-auth ResetPasswordForm__password-warner ${
            (formik.errors.password && formik.touched.password) ||
            (formik.touched.password_confirmation &&
              formik.errors.password_confirmation)
              ? 'has-error'
              : ''
          }`}
        >
          {(formik.errors.password && formik.touched.password) ||
          (formik.errors.password_confirmation &&
            formik.touched.password_confirmation) ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <div>
                {(formik.errors.password || '') +
                  ' ' +
                  (formik.errors.password_confirmation || '')}
              </div>
              <button
                style={{
                  marginLeft: 'auto',
                  background: 'transparent',
                  border: 'none',
                }}
                onClick={() => {
                  formik.setErrors({});
                  formik.setTouched({});
                }}
              >
                <svg
                  viewBox='64 64 896 896'
                  focusable='false'
                  width='1em'
                  height='1em'
                  fill='currentColor'
                  aria-hidden='true'
                >
                  <path d='M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z'></path>
                </svg>
              </button>
            </div>
          ) : (
            'Please use six or more characters. We recommended using a mix of letters, number and symbols.'
          )}
        </div>
        <div className='feature-auth ResetPasswordForm__bottom-container'>
          <div>
            <button
              className='feature-auth ResetPasswordForm__submit-button back-to-login'
              onClick={() => onBack && onBack()}
              type='button'
            >
              Back to Login
            </button>
          </div>
          <div>
            <button
              className={`feature-auth ResetPasswordForm__submit-button
          ${isLoading && 'feature-auth ResetPasswordForm__button--loading'}`}
              disabled={isLoading}
              type='submit'
            >
              {isLoading ? (
                <svg
                  height='16'
                  style={{ marginTop: '2px', marginBottom: '2px' }}
                  viewBox='0 0 14.229 14.229'
                  width='16'
                >
                  <path
                    d='M9.115,14.1a4.98,4.98,0,1,0-4.98-4.98A4.98,4.98,0,0,0,9.115,14.1Zm0,2.134A7.115,7.115,0,1,0,2,9.115,7.115,7.115,0,0,0,9.115,16.229Z'
                    fill='#fff'
                    fillRule='evenodd'
                    opacity='0.2'
                    transform='translate(-2 -2)'
                  />
                  <path
                    d='M2,9.115A7.115,7.115,0,0,1,9.115,2V4.134a4.98,4.98,0,0,0-4.98,4.98Z'
                    fill='#fff'
                    transform='translate(-2 -2)'
                  />
                </svg>
              ) : (
                'Reset Password'
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

ResetPasswordForm.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  message: PropTypes.string,
  onBack: PropTypes.func,
  onDismissMessage: PropTypes.func,
  onSubmit: PropTypes.func,
  style: PropTypes.object,
};

ResetPasswordForm.defaultProps = {
  className: '',
};

export default ResetPasswordForm;
