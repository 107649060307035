import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Faisal <ahmad.faisal@bvarta.com>
 * @param {Object} props
 * @param {string} props.className
 * @param {string} props.onClick
 */

export default function IconDisplay(props) {
  const { className, onClick } = props;

  return (
    <div className={`${className} icon-display`} onClick={onClick}>
      <svg
        height='18'
        id='ic-actions-new-window'
        viewBox='0 0 18 18'
        width='18'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect
          data-name='Rectangle 46'
          fill='none'
          height='18'
          id='Rectangle_46'
          width='18'
        />
        <path
          d='M2.23,6.808H7.519a2.24,2.24,0,0,1,2.23,2.23V13.5a2.24,2.24,0,0,1-2.23,2.23H2.23A2.24,2.24,0,0,1,0,13.5V9.038a2.24,2.24,0,0,1,2.23-2.23ZM7,12.831H5.819A2.936,2.936,0,0,1,2.9,9.908V9.555a.238.238,0,0,1,.231-.231h1.55a.238.238,0,0,1,.231.231v.353a.911.911,0,0,0,.911.911H7a.238.238,0,0,1,.231.231V12.6A.238.238,0,0,1,7,12.831ZM2.9,6.074V2.933A2.927,2.927,0,0,1,5.819.01h8.266a2.936,2.936,0,0,1,2.923,2.923V9.908a2.936,2.936,0,0,1-2.923,2.923H10.415a.238.238,0,0,1-.231-.231V11.05a.238.238,0,0,1,.231-.231h3.671A.911.911,0,0,0,15,9.908V2.933a.911.911,0,0,0-.911-.911H5.819a.92.92,0,0,0-.911.911V6.074a.238.238,0,0,1-.231.231H3.127A.238.238,0,0,1,2.9,6.074ZM7.519,8.82H2.23a.215.215,0,0,0-.218.218V13.5a.215.215,0,0,0,.218.218H7.519a.215.215,0,0,0,.218-.218V9.038A.215.215,0,0,0,7.519,8.82Z'
          data-name='Path 3422'
          fill='#364961'
          id='Path_3422'
          transform='translate(0.443 1.135)'
        />
      </svg>
    </div>
  );
}

IconDisplay.defaultProps = {
  className: '',
  onClick: () => {},
};

IconDisplay.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
