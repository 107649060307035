export const USER_ACTION_LIST = [
  {
    id: 1,
    name: 'Personal Details',
    slug: 'personal-details',
    icon: 'user',
  },
  {
    id: 2,
    name: 'Security & Login',
    slug: 'security-and-login',
    icon: 'security',
  },
];
