import React from 'react';
import { IconType, IconTypeDefault } from '@bvt-types';

export default function IconDuplicate(props) {
  const { className, height, width } = props;

  return (
    <svg height={height} viewBox='0 0 15 15' width={width}>
      <path
        className={className}
        d='M2.364,3.727H1V14.636A1.368,1.368,0,0,0,2.364,16H13.273V14.636H2.364ZM14.636,1H5.091A1.368,1.368,0,0,0,3.727,2.364v9.545a1.368,1.368,0,0,0,1.364,1.364h9.545A1.368,1.368,0,0,0,16,11.909V2.364A1.368,1.368,0,0,0,14.636,1Zm0,10.909H5.091V2.364h9.545ZM11.909,9.182H9.182V7.818h1.364a1.363,1.363,0,0,0,1.364-1.364V5.091a1.363,1.363,0,0,0-1.364-1.364H7.818V5.091h2.727V6.455H9.182A1.363,1.363,0,0,0,7.818,7.818v2.727h4.091Z'
        transform='translate(-1 -1)'
      />
    </svg>
  );
}

IconDuplicate.defaultProps = IconTypeDefault;

IconDuplicate.propTypes = IconType;
