import { generateUUID } from '@bvt-utils';
import React from 'react';

/**
 * @copyright Bhumi Varta Technology
 * @author Malik<malik.ibrahim@bvarta.com>
 * @author Renta<renta.yustika@bvarta.com>
 */

/**
 * @param {Object} props
 * @param {String} props.classname
 * @param {String} props.title
 * @param {boolean} props.bordered
 * @param {boolean} props.outlined
 * @param {Array<{key:String,value:String}>} props.data
 */

export const CardMoreInformation = (props) => {
  const { className, data, title, bordered, outlined } = props;

  return (
    <div
      className={`CarDatexTelcoDataDetail__more${
        outlined === true ? '-outlined' : ''
      } ${className}`}
    >
      {title ? (
        <div className='CarDatexTelcoDataDetail__header'>
          <span className='CarDatexTelcoDataDetail__header-title'>
            {title}
          </span>
        </div>
      ) : null}

      {data?.map((v) => {
        return (
          <div
            className={`CarDatexTelcoDataDetail__more-wrapper${
              bordered === false ? '-noborder' : ''
            }`}
            key={generateUUID()}
          >
            <div className='CarDatexTelcoDataDetail__more-left'>
              <span className='CarDatexTelcoDataDetail__more-left-text'>
                {v?.key}
              </span>
            </div>
            <div className='CarDatexTelcoDataDetail__more-right'>
              <span className='CarDatexTelcoDataDetail__more-right-text'>
                {v?.value || '-'}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
