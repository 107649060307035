import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { poiSearchAsyncAction } from '../store/poiSearch.asyncAction';
import { poiSearchAction } from '../store/poiSearch.slice';

export const usePoiSearch = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.features.MAINMAP.POISEARCH);
  const list = useMemo(() => state.ids.map((v) => state.entities[v]), [state.entities, state.ids]);
  const selectedId = useMemo(() => state.selectedId, [state.selectedId]);
  const isShowAll = useMemo(() => state.isShowAll, [state.isShowAll]);
  const mapperListToDetail = (id) => state.entities[id];
  const status = useMemo(() => state.status, [state.status]);
  const message = useMemo(() => state.message, [state.message]);
  const meta = useMemo(() => state.meta, [state.meta]);

  /**
   * Get List POI (Point Of Interest)
   * @param {Object} payload
   */
  const getList = (payload) => dispatch(poiSearchAsyncAction.getList(payload));
  const resetStatusAndMessage = () => dispatch(poiSearchAsyncAction.resetStatusAndMessage({}));
  const removeAll = () => dispatch(poiSearchAsyncAction.removeAll());
  const setSelectedId = ({ id }) => dispatch(poiSearchAction.setSelectedId({ id }));
  const setIsShowAll = ({ isShowAll }) => dispatch(poiSearchAction.setIsShowAll({ isShowAll }));


  return {
    list,
    mapperListToDetail,
    status,
    message,
    meta,
    selectedId,
    isShowAll,
    getList,
    resetStatusAndMessage,
    removeAll,
    setSelectedId,
    setIsShowAll,
  };
};
