import { useDispatch, useSelector } from 'react-redux';
import { asyncActionCheckMarkerIsValid, asyncActionCheckMarkerIsValidMandala } from '../store/droppable_marker.asyncAction';
import { DROPPABLE_MARKER_ACTION } from '../store/droppable_marker.slice';

export const useDroppableMarker = () => {
  const state = useSelector(
    /**
     * @param {ReturnType<typeof import('../../../../redux/rootStore').rootStore.getState>} state
     */
    (state) => state.features.MAINMAP.DROPPABLE_MARKER
  );

  const dispatch = useDispatch();

  /**
   * @description For marker list and history
   * @typedef TMarkerObject
   * @type {object}
   * @property {number} id
   * @property {string} siteName
   * @property {string} address
   * @property {string} IconColor
   * @property {string} icon_type
   * @property {Array<number|string>} coordinate
   * @property {number} id_provinsi
   * @property {string} nama_provinsi
   * @property {number} id_kota
   * @property {string} nama_kota
   * @property {number} id_kecamatan
   * @property {string} nama_kecamatan
   * @property {number} id_desa
   * @property {string} nama_desa
   * @property {boolean} isChecked
   * @property {number} latitude
   * @property {number} longitude
   * @property {string} id_group
   * @property {Array<TMarkerHistoryObject>} history
   * @property {Array<string>} MarkerGroups
   */

  /**
   * @param {object} param
   * @param {TMarkerObject} param.marker
   */
  const addNewMarkerToList = (param) => {
    dispatch(DROPPABLE_MARKER_ACTION.addNewMarkerToList(param));
  };

  /**
   * @param {object} param
   * @param {import('../store/droppable_marker.slice').TMarkerHistoryObject} param.history
   */
  const addNewHistoryToList = (param) => {
    dispatch(DROPPABLE_MARKER_ACTION.addNewHistoryToList(param));
  };

  const clearHistoryList = (param) => {
    dispatch(DROPPABLE_MARKER_ACTION.clearHistoryLisst(param));
  };

  /**
   * @param {object} param
   * @param {number} param.id
   */
  const deleteMarkerById = (param) => {
    dispatch(DROPPABLE_MARKER_ACTION.deleteMarkerById(param));
  };

  /**
   * @description
   * @param {object} param
   * @param {number} param.id
   * @param {TMarkerObject} param.data
   */
  const saveMarkerOrEditById = (param) => {
    dispatch(DROPPABLE_MARKER_ACTION.saveMarkerOrEditById(param));
  };

  /**
   * @description
   * @param {object} param
   * @param {boolean} param.payload
   */
  const changeDragStatus = (param) => {
    dispatch(DROPPABLE_MARKER_ACTION.changeDragStatus(param));
  };

  /**
   * @description
   * @param {object} param
   * @param {boolean} param.payload
   */
  const changeMarkerColor = (param) => {
    dispatch(DROPPABLE_MARKER_ACTION.changeMarkerColor(param));
  };

  /**
   * @description
   * @param {object} param
   * @param {boolean} param.payload
   */
  const changeMarkerIcon = (param) => {
    dispatch(DROPPABLE_MARKER_ACTION.changeMarkerIcon(param));
  };

  /**
   * @description
   * @param {object} param
   * @param {boolean} param.payload
   */
  const setHistoryMarkerActiveIndex = (param) => {
    dispatch(DROPPABLE_MARKER_ACTION.setHistoryMarkerActiveIndex(param));
  };

  /**
   * @description
   * @param {object} param
   * @param {boolean} param.payload
   */
  const setMarkerActiveIndex = (param) => {
    dispatch(DROPPABLE_MARKER_ACTION.setMarkerActiveIndex(param));
  };

  // CEK VALID AREA
  /**
   * @description
   * @param {object} param
   * @param {number} param.longitude
   * @param {TMarkerObject} param.latitude
   * @param {TMarkerObject} param.id_source
   */
  const checkIfAreaIsValid = (param) => {
    const {countryId} = param
    if(countryId && countryId !== 1){
      dispatch(asyncActionCheckMarkerIsValidMandala(param || {}));
    }else{
      dispatch(asyncActionCheckMarkerIsValid(param || {}));
    }
  };

  const resetState = () => {
    dispatch(DROPPABLE_MARKER_ACTION.resetState());
  };

  const resetMarkerColor = () => {
    dispatch(DROPPABLE_MARKER_ACTION.resetMarkerColor());
  };

  const resetMarkerIcon = () => {
    dispatch(DROPPABLE_MARKER_ACTION.resetMarkerIcon());
  };

  const filterMarkerList = (param) => {
    dispatch(DROPPABLE_MARKER_ACTION.setFilterMarkerList(param));
  };

  const filterMarkerByArea = (param) => {
    dispatch(DROPPABLE_MARKER_ACTION.setFilterByArea(param));
  };

  /**
   * @param {{ x: number, y: number }} payload
   */
  const setPoint = (payload) => {
    dispatch(DROPPABLE_MARKER_ACTION.setPoint(payload));
  };

  /**
   * @param {object} payload
   * @param {number[]} payload.ids
   */
  const setActiveMarkers = (payload) => {
    dispatch(DROPPABLE_MARKER_ACTION.setActiveMarkers(payload));
  };
  /**
   * @param {object} payload
   * @param {number[]} payload.ids
   */
  const setActiveMarkersRight = (payload) => {
    dispatch(DROPPABLE_MARKER_ACTION.setActiveMarkersRight(payload));
  };

  /**
   * @param {[number, number]} coordinate
   */
  const setCoordinate = (coordinate) => {
    dispatch(DROPPABLE_MARKER_ACTION.setCoordinate(coordinate));
  };

  /**
   * @param {TMarkerObject[]} payload
   */
  const setMarkerList = (payload) => {
    dispatch(DROPPABLE_MARKER_ACTION.setMarkerList(payload));
  };

  return {
    state,
    addNewMarkerToList,
    addNewHistoryToList,
    clearHistoryList,
    deleteMarkerById,
    saveMarkerOrEditById,
    checkIfAreaIsValid,
    changeDragStatus,
    changeMarkerColor,
    changeMarkerIcon,
    setHistoryMarkerActiveIndex,
    setMarkerActiveIndex,
    resetState,
    resetMarkerColor,
    resetMarkerIcon,
    filterMarkerList,
    filterMarkerByArea,
    setPoint,
    setActiveMarkers,
    setActiveMarkersRight,
    setCoordinate,
    setMarkerList,
  };
};
