import { Progress } from 'antd';
import React from 'react';
import './PasswordStrengthBar.scss';

/**
 * @company PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

/**
 * @description For data object
 * @typedef IPropsData
 * @type {Object}
 * @property {number} value
 * @property {string} label
 * @property {string} color
 */

/**
 *
 * @param {Object} props
 * @param {IPropsData} props.data
 * @param {boolean} props.hideInfo
 */

export const PasswordStrengthBar = (props) => {
  const { data, hideInfo } = props;
  return (
    <div className='PasswordStrengthBar'>
      <Progress
        className='PasswordStrengthBar__value'
        percent={hideInfo && data?.value ? data?.value : 0}
        showInfo={false}
        style={{ '--ProgressBgColor': data?.color }}
      />
      <div className='PasswordStrengthBar__label'>
        <span className='PasswordStrengthBar__info-label'>
          Password strength:{' '}
        </span>
        {hideInfo && (
          <span
            className='PasswordStrengthBar__info-value'
            style={{ '--LabelColor': data?.color }}
          >
            {data?.label || ''}
          </span>
        )}
      </div>
    </div>
  );
};
