import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';

/**
 * @param {object} props
 * @param {{hour: number | undefined, time: string, counts: number | undefined, counts_event: number | undefined}[]} props.data
 * @param {{main: string, secondary: string, hover: color}} props.color
 */
export const ChartMonthly = (props) => {
  const { data = [], color } = props;

  /**
   * @type {(Highcharts.Options)}
   */
  const options = {
    chart: {
      type: 'column'
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    title: undefined,
    exporting: {
      enabled: false // Disables the exporting menu
    },
    xAxis: {
      type: 'datetime',
      title: undefined,
      labels: {
        formatter: function() {
          return moment(this.value).format('MMMM');
        }
      },
      tickInterval: 30.44 * 24 * 3600 * 1000,
      gridLineWidth: 0, // Remove vertical grid lines
      minorGridLineWidth: 0, // Optionally remove minor vertical grid lines
      tickLength: 0 // Optionally remove ticks
    },
    yAxis: {
      title: undefined,
    },
    tooltip: {
      useHTML: true,
      formatter: function() {
        return `
          <div style="border-radius: 4px;background: #FFF;border:none;">
            <span style="font-size:16px">${moment(this.x).format('MMMM')}</span>
            <br/><span style="color: #07827C;font-size: 24px;font-weight: 700;">${this.y.toLocaleString('id-ID')}</span>
          </div>
        `;
      },
      backgroundColor: '#fff',
      borderWidth: 0,
      borderRadius: 4,
    },
    series: [{
      name: 'counts',
      data: data?.map(item => [moment(item.time, 'YYYY-MM').valueOf(), (item.counts_event ?? item.counts)]),
      color: color.main,
      states: {
        hover: {
          lineWidth: 2,
          color: color.hover // Hover color
        }
      }
    }]
  };

  return (
    <HighchartsReact
      allowChartUpdate={true}
      highcharts={Highcharts}
      options={options}
    />
  );
};
