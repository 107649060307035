import React from 'react';
import './BaseSelectedPOICategoryCard.scss';
import { Skeleton } from '@bvt-shared/component/Skeleton';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta>
 * @author Malik<malik.ibrahim@bvarta>
 */

/**
 * @typedef IDescriptionObject
 * @type {Object}
 * @property {string|number} key
 * @property {string} value
 */

/**
 * @param {Object} props
 * @param {string} props.header
 * @param {string} props.title
 * @param {React.ReactElement} props.footer
 * @param {Array<IDescriptionObject} props.description
 * @param {React.ReactElement} props.icon
 * @param {()=>void} props.onClear
 * @param {boolean} props.showDescription
 * @param {()=>void} props.showAction
 * @param {()=>void} props.onSelectAll
 */

export const BaseSelectedPOICategoryCard = (props) => {
  const {
    description,
    icon,
    header,
    showDescription,
    title,
    showAction,
    onSelectAll,
    onClear,
  } = props;
  return (
    <div className='BaseSelectedPOICategoryCard'>
      <div className='BaseSelectedPOICategoryCard__header'>{header}</div>
      <div className='BaseSelectedPOICategoryCard__body'>
        <div className='BaseSelectedPOICategoryCard__icon'>
          <Skeleton height='2rem' loading={!title} type='image'>
            <img alt={title} src={icon} />
          </Skeleton>
        </div>
        <div className='BaseSelectedPOICategoryCard__text'>
          <Skeleton height='2rem' loading={!title} type='square' width='10rem'>
            <div className='BaseSelectedPOICategoryCard__text--title'>
              {title}
            </div>
          </Skeleton>
          {showDescription && (
            <div className='BaseSelectedPOICategoryCard__text--description'>
              {description?.map((v, i) => {
                return (
                  <span
                    className='BaseSelectedPOICategoryCard__text--description-item'
                    key={v?.key}
                  >
                    {v?.value} {i !== description?.length - 1 && `>`}{' '}
                  </span>
                );
              })}
            </div>
          )}
          {showAction && (
            <div className='BaseSelectedPOICategoryCard__text--action'>
              <span
                className='BaseSelectedPOICategoryCard__text--action-item'
                onClick={onSelectAll}
              >
                Select all
              </span>
              <span
                className='BaseSelectedPOICategoryCard__text--action-item'
                onClick={onClear}
              >
                Clear Selection
              </span>
            </div>
          )}
        </div>
      </div>
      <div className='BaseSelectedPOICategoryCard__footer'>{}</div>
    </div>
  );
};
