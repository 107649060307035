import { MainMapPlaceService } from '@bvt-features/mainmap/place/service';

const { createAsyncThunk } = require('@reduxjs/toolkit');

const mainMapPlaceService = new MainMapPlaceService();

const asyncActionCheckMarkerIsValid = createAsyncThunk(
  'LI/mainmap/droppable-marker/check-area',
  async ({ latitude, longitude, id_source }, { rejectWithValue }) => {
    try {
      const placeData = await mainMapPlaceService.get_coordinates({
        meta: {
          filter: {
            id_source: id_source,
            longitude: longitude,
            latitude: latitude,
          },
        },
      });
      return placeData;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

const asyncActionCheckMarkerIsValidMandala = createAsyncThunk(
  'LI/mainmap/droppable-marker/check-area-mandala',
  async ({ latitude, longitude, id_source,countryId }, { rejectWithValue }) => {
    try {
      const placeData = {
        id_desa: 1,
        id_kecamatan: 1,
        id_kota: 1,
        id_provinsi: 1,
        lat: latitude,
        long: longitude,
        nama_desa: "SUKA MAKMUR",
        nama_kecamatan: "GUMAY TALANG",
        nama_kota: "LAHAT",
        nama_provinsi: "SUMATERA SELATAN"
      }
      return placeData;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export { asyncActionCheckMarkerIsValidMandala,asyncActionCheckMarkerIsValid };
