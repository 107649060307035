import React from 'react';
import PropTypes from 'prop-types';

export const Loading = (props) => {
  const { className, size, color } = props;
  const customClass = `
    ${className} loading
    ${size && `loading__size--${size}`}
    ${color && `loading__color--${color}`}
  `;

  return <div className={customClass} />;
};

Loading.defaultProps = {
  className: '',
  color: '',
  size: '',
};

Loading.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
};
