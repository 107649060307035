export const GENSET_POI_CONSTANT = {
  GET_START: 'LI/POI/get/requested',
  GET_FAILED: 'LI/POI/get/failed',
  GET_SUCCESS: 'LI/POI/get/success',

  DETAIL_START: '/LI/POI/detail/requested',
  DETAIL_FAILED: '/LI/POI/detail/failed',
  DETAIL_SUCCESS: '/LI/POI/detail/success',

  CREATE_START: '/LI/POI/create/requested',
  CREATE_FAILED: '/LI/POI/create/failed',
  CREATE_SUCCESS: '/LI/POI/create/success',

  UPDATE_START: 'LI/POI/update/requested',
  UPDATE_FAILED: 'LI/POI/update/failed',
  UPDATE_SUCCESS: 'LI/POI/update/success',

  DELETE_START: 'LI/POI/delete/requested',
  DELETE_FAILED: 'LI/POI/delete/failed',
  DELETE_SUCCESS: 'LI/POI/delete/success',

  DOWNLOAD_START: 'LI/POI/download/requested',
  DOWNLOAD_FAILED: 'LI/POI/download/failed',
  DOWNLOAD_SUCCESS: 'LI/POI/download/success',

  UPLOAD_START: 'LI/POI/upload/requested',
  UPLOAD_FAILED: 'LI/POI/upload/failed',
  UPLOAD_SUCCESS: 'LI/POI/upload/success',

  PATCH_START: 'LI/POI/patch/requested',
  PATCH_FAILED: 'LI/POI/patch/failed',
  PATCH_SUCCESS: 'LI/POI/patch/success',

  RESET_STATUS: 'LI/POI/reset-status',
  RESET_ALL: 'LI/POI/reset-all',
};
