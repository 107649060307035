import * as React from 'react';
const IconTrash = (props) => (
  <svg
    fill='none'
    height={16}
    viewBox='0 0 14 16'
    width={14}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      clipRule='evenodd'
      d='M4.58835 11.4519C4.67817 11.5709 4.81017 11.6508 4.95703 11.6749C5.10389 11.699 5.25438 11.6656 5.37736 11.5815L6.87252 10.0756L8.36769 11.5815C8.49576 11.6455 8.64064 11.6676 8.78187 11.6445C8.92309 11.6214 9.05351 11.5544 9.15469 11.4528C9.25586 11.3513 9.32268 11.2205 9.3457 11.0788C9.36871 10.9371 9.34676 10.7918 9.28294 10.6633L7.77791 9.16332L9.27899 7.66333C9.36281 7.53996 9.39614 7.38899 9.37209 7.24165C9.34805 7.09431 9.26846 6.96189 9.1498 6.87178C9.05998 6.75273 8.92798 6.67289 8.78111 6.64876C8.63425 6.62464 8.48376 6.65808 8.36079 6.74216L6.86957 8.25205L5.3744 6.74513C5.25143 6.66104 5.10094 6.6276 4.95407 6.65173C4.80721 6.67586 4.67521 6.7557 4.58539 6.87475C4.46673 6.96485 4.38714 7.09728 4.36309 7.24462C4.33904 7.39195 4.37238 7.54293 4.45619 7.6663L5.96122 9.16332L4.45915 10.6633C4.37533 10.7867 4.342 10.9377 4.36605 11.085C4.3901 11.2323 4.46969 11.3648 4.58835 11.4549V11.4519Z'
      fill='#657991'
      fillRule='evenodd'
    />
    <path
      clipRule='evenodd'
      d='M12.6689 0.888672H1.32791C1.12284 0.888672 0.926168 0.970399 0.78116 1.11588C0.636152 1.26135 0.554688 1.45866 0.554688 1.66439V3.99156C0.554688 4.19729 0.636152 4.3946 0.78116 4.54008C0.926168 4.68555 1.12284 4.76728 1.32791 4.76728H1.84373V13.1716C1.84373 13.6859 2.04739 14.1792 2.40991 14.5429C2.77243 14.9066 3.26411 15.1109 3.77679 15.1109H10.22C10.7327 15.1109 11.2244 14.9066 11.5869 14.5429C11.9494 14.1792 12.1531 13.6859 12.1531 13.1716V4.76728H12.6689C12.874 4.76728 13.0706 4.68555 13.2157 4.54008C13.3607 4.3946 13.4421 4.19729 13.4421 3.99156V1.66439C13.4421 1.45866 13.3607 1.26135 13.2157 1.11588C13.0706 0.970399 12.874 0.888672 12.6689 0.888672V0.888672ZM10.865 13.1716C10.865 13.3429 10.7972 13.5073 10.6764 13.6285C10.5556 13.7496 10.3918 13.8177 10.221 13.8177H3.77679C3.60599 13.8177 3.44218 13.7496 3.3214 13.6285C3.20062 13.5073 3.13277 13.3429 3.13277 13.1716V4.76728H10.865V13.1716ZM12.1541 3.47507H1.84373V2.18187H12.1531L12.1541 3.47507Z'
      fill='#657991'
      fillRule='evenodd'
    />
  </svg>
);
export { IconTrash };
