import React from 'react';
import { useSIPRO } from '../../hook';
import GeoJsonSiproContainer from '../GeoJsonSiproContainer';
import POICollectionSiproContainer from '../POICollectionSiproContainer';

export default function SiproMapContainer() {
  const SIPRO = useSIPRO();

  return (
    <React.Fragment>
      {SIPRO?.state?.IS_SHOWN_ON_MAP ? (
        <React.Fragment>
          <GeoJsonSiproContainer />
          <POICollectionSiproContainer />
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}
