import * as React from "react"

export const IconTriangleUp = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={8}
    fill="none"
    {...props}
  >
    <path
      d="M3.857.6c.133-.186.306-.337.504-.442a1.373 1.373 0 0 1 1.278 0c.198.105.37.256.504.442l3.571 5a1.565 1.565 0 0 1 .135 1.57c-.118.25-.3.46-.527.606a1.378 1.378 0 0 1-.75.224H1.428c-.266 0-.526-.078-.751-.224a1.476 1.476 0 0 1-.527-.605A1.565 1.565 0 0 1 .286 5.6l3.571-5Z"
      fill="#4D4F5C"
    />
  </svg>
)
