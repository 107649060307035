import React from 'react';
import './CardLayerResult.scss';
import {
  CalendarOutlined,
  EnvironmentOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { CardMobileData } from '@bvt-shared/component/CardMobileData';
import { ImageBox } from '@bvt-shared/component/ImageBox';
import _ from 'lodash';

export const CardLayerResult = (props) => {
  const { data } = props;
  const mappingDataPeopleDensity = () => {
    if (
      Object.entries(data?.peopleDensity_time).length &&
      data?.peopleDensity_time?.type === 'daily'
    ) {
      const newEventTimeSeries =
        data?.peopleDensity_time?.event?.timeSeries?.map((v) => ({
          ...v,
          hour: Number(v.hour),
        }));
      const newUniqueTimeSeries =
        data?.peopleDensity_time?.unique?.timeSeries?.map((v) => ({
          ...v,
          hour: Number(v.hour),
        }));
      const newData = {
        ...data?.peopleDensity_time,
        event: {
          ...data?.peopleDensity_time.event,
          timeSeries: newEventTimeSeries,
        },
        unique: {
          ...data?.peopleDensity_time.unique,
          timeSeries: newUniqueTimeSeries,
        },
      };
      return newData;
    }
    return { ...data?.peopleDensity_time };
  };
  return (
    <div className='feature-datex-telco'>
      <div className='CardLayerResult__container'>
        <div className='CardLayerResult__header-info'>
          <InfoCircleOutlined size={30} style={{ color: '#07827C' }} />
          <span className='CardLayerResult__header-info--text'>
            Summary By Grid
          </span>
        </div>

        <div className='CardLayerResult__periode'>
          <span className='CardLayerResult__periode--text'>Periode</span>
          <span className='CardLayerResult__periode--value'>{_.capitalize(data?.peopleDensity_time?.type)}</span>
        </div>

        <div className='CardLayerResult__area-date'>
          <div className='CardLayerResult__area-date__header'>
            <span className='CardLayerResult__area-date__header--text'>
              Area and Date
            </span>
          </div>
          <div className='CardLayerResult__area-date__content'>
            <ImageBox className='CardLayerResult__area-date__content--icon' src='ic-grid-id'/>
            <span className='CardLayerResult__area-date__content--text'>
              {data?.index}
            </span>
          </div>
          <div className='CardLayerResult__area-date__content'>
            <EnvironmentOutlined size={30} style={{ color: '#07827C' }} />
            <span className='CardLayerResult__area-date__content--text'>
              {data?.administrative?.join(', ') ?? '-'}
            </span>
          </div>
          <div className='CardLayerResult__area-date__content'>
            <CalendarOutlined size={30} style={{ color: '#07827C' }} />
            <span className='CardLayerResult__area-date__content--text'>
              {data?.date}
            </span>
          </div>
        </div>

        {Object.entries(data?.peopleDensity_time).length && (
          <div className='CardLayerResult__mobile-data'>
            <CardMobileData
              colorEvent={{
                main: '#33CC33',
                secondary: '#ADEBAD',
                hover: '#1D941D',
              }}
              colorUnique={{
                main: '#2CAFFE',
                secondary: '#2CAFFE66',
                hover: '#149AEB',
              }}
              data={mappingDataPeopleDensity()}
              periodic={data?.peopleDensity_time?.type}
              title='People Traffic Data'
            />
          </div>
        )}
      </div>
    </div>
  );
};
