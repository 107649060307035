import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { LayoutMainMapRightSide } from '../../component/LayoutMainMapRightSide';
import { MainMapMapPickerBaseMapList } from '../../component/MainMapMapPickerBaseMapList';
import { MainMapRightSideTopBar } from '../../component/MainMapRightSideTopBar';
import { useMainMap } from '../../hook/useMainMap';
import { MainMapLayerVisibilityItemList } from '../../component/MainMapLayerVisibilityItemList';

/**
 * @author Randa<m.randa@bvarta.com>
 * @description For Right sidebar on map
 */
export const MainMapLayeringVisiblityAndBasemapContainer = () => {
  const initialmap = useMap();
  const mapp = useMainMap();

  const internalDisableHandleMapEvent = (param) => {
    if (param) {
      initialmap.scrollWheelZoom.disable();
      initialmap.doubleClickZoom.disable();
      initialmap.dragging.disable();
    } else {
      initialmap.scrollWheelZoom.enable();
      initialmap.doubleClickZoom.enable();
      initialmap.dragging.enable();
    }
  };

  useEffect(() => {
    mapp.clearLayer();
    return ()=>{
      mapp.clearLayer();
      mapp.setActiveMenu('');
    };
  }, []);

  return (
    <LayoutMainMapRightSide
      height={'100vh'}
      isActive={
        mapp.state.activeMenu === 'BASEMAP' ||
        mapp.state.activeMenu === 'LAYERING_VISIBLITY'
      }
      onMouseEnter={() => internalDisableHandleMapEvent(true)}
      onMouseLeave={() => internalDisableHandleMapEvent(false)}
      top={130}
      width={mapp.state.activeMenuWidth}
    >
      <MainMapRightSideTopBar
        activeMenu={mapp.state.activeMenu}
        onChange={(e) => mapp.setActiveMenu(e)}
        onClose={() => mapp.setActiveMenu(undefined)}
      />
      {mapp.state.activeMenu === 'BASEMAP' && (
        <MainMapMapPickerBaseMapList
          activeKey={mapp.state.selectedBaseMap}
          data={mapp.state.baseMapList}
          onClick={(v) => mapp.setBaseMap(v)}
          onMouseEnter={()=>internalDisableHandleMapEvent(true)}
          onMouseLeave={()=>internalDisableHandleMapEvent(false)}
        />
      )}
      {mapp.state.activeMenu === 'LAYERING_VISIBLITY' && (
        <MainMapLayerVisibilityItemList
          data={mapp.state.layerVisibilityList}
          onChange={(v) => mapp.updateLayer(v)}
        />
      )}
    </LayoutMainMapRightSide>
  );
};
