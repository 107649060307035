import React from 'react';
import DefaultImage from '@bvt-assets/images/streetsMap.png';
import './MainMapMapPickerBaseMapListItem.scss';

/**
 * @author Randa<m.randa@bvarta.com>
 * @description Must be a controlled component
 * @param {object} props;
 * @param {boolean} props.isActive;
 * @param {boolean} props.title;
 * @param {boolean} props.image;
 * @param {(e)=>void} props.onClick
 */
export const MainMapMapPickerBaseMapListItem = (props) => {
  const { image, title, isActive, onClick } = props;
  return (
    <div
      className={`feature-mainmap MainMapMapPickerBaseMapListItem ${
        isActive && 'MainMapMapPickerBaseMapListItem--active'
      }`}
      onClick={onClick}
    >
      <div className='MainMapMapPickerBaseMapListItem__left-side'>
        <div
          className={`MainMapMapPickerBaseMapListItem__checker ${
            isActive && 'MainMapMapPickerBaseMapListItem__checker--active'
          }`}
        >
          <div className='checker-item' />
        </div>
        <div className='MainMapMapPickerBaseMapListItem__title'>{title}</div>
      </div>
      <div className='MainMapMapPickerBaseMapListItem__right-side'>
        <img
          alt={title}
          className='MainMapMapPickerBaseMapListItem__image'
          src={image || DefaultImage}
        />
      </div>
    </div>
  );
};
