import { LEGACY_AUTH_CONSTANT } from '@bvt-features/auth/redux';
import { MAIN_MODULE_CONSTANT } from './MAIN_MODULE_CONSTANT';

const initialState = {
  status_GET: 'IDLE',
  status_POST: 'IDLE',
  status_PUT: 'IDLE',
  status_DELETE: 'IDLE',
  message: '',
  data: {},
};

export function MAIN_MODULE_REDUCER(state = initialState, action) {
  let returnData = state;
  Object.values(MAIN_MODULE_CONSTANT).map((ctx) => {
    if (action.type === MAIN_MODULE_CONSTANT.RESET_STATUS) {
      returnData = {
        ...returnData,
        status_GET: 'IDLE',
        status_POST: 'IDLE',
        status_PUT: 'IDLE',
        status_DELETE: 'IDLE',
        message: ' ',
      };
    } else if (
      action.type === LEGACY_AUTH_CONSTANT.UNAUTHORIZED ||
      action.type === MAIN_MODULE_CONSTANT.RESET_ALL
    ) {
      returnData = { ...initialState };
    } else if (ctx === action.type && !ctx.includes('[NO_STATE_UPDATE]')) {
      returnData = { ...returnData, ...action.payload };
    }
    return null;
  });

  return returnData;
}
