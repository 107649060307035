import Resource from '../../../../api/resource';

export class GensetPOIService extends Resource {
  constructor() {
    super('poi', '/api', 'api-poi', 'v3');
  }

  getAllPOI(_query) {
    this.setAdditionUri('');
    const paramString = JSON.stringify(_query);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }

  createPOI(_body) {
    const data = _body;
    this.setAdditionUri('/create');
    return this.create(data);
  }

  deletePOI(id) {
    this.setAdditionUri('');
    return this.destroy(id);
  }

  downloadPOI(payload, id) {
    this.setAdditionUri(`/download?user_id=${id}`);
    return this.download(payload);
  }

  uploadPOI(payload, id) {
    this.setAdditionUri('/upload');
    const query = {
      poi_for_user: id,
    };
    return this.upload(query, payload);
  }

  renamePOI(payload, id) {
    this.setAdditionUri('');
    return this.patch(id, payload);
  }

  getPOIById(_id) {
    this.setAdditionUri('');
    return this.get(`${_id}/edit`);
  }

  updatePOI(_id, _body) {
    const data = _body;
    this.setAdditionUri('');
    return this.update(`${_id}/update`, data);
  }
}
