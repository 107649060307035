import { useDispatch, useSelector } from 'react-redux';

import {
  doGetAllReport, doGetRangeDateExistReport,
} from '../store/mainOpen.asyncAction';
import { MAIN_OPEN_REPORT_ACTION } from '../store/mainOpen.slice';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Ryfan <ryfan.aditya@bvarta.com>
 */

export const useMainOpenReport = () => {
  const state = useSelector(
    /**
     * @param {ReturnType<typeof import('../../../../redux/rootStore').rootStore.getState>} state
     */
    (state) => state.features.MAPP.OPEN_REPORT
  );

  const dispatch = useDispatch();

  /**
   * @description For meta report
   * @param {Object} param
   * @param {Object} param.meta
   * @param {Object} param.meta.filter
   * @param {String} param.meta.filter.name
   * @param {Object} param.meta.filter.rangeDate
   * @param {String} param.meta.filter.rangeDate.start
   * @param {String} param.meta.filter.rangeDate.end
   * @param {Number} param.meta.page
   * @param {Number} param.meta.page
   * @param {Object} param.meta.sort
   * @param {String} param.meta.sort.by
   * @param {String} param.meta.sort.type
   */
  const getAllReport = (param) => {
    dispatch(doGetAllReport(param));
  };

  /**
   * @description For get range date param
   * @param {Object} param
   * @param {Object} param.meta
   * @param {Object} param.meta.filter
   * @param {String} param.meta.filter.type
   * @param {String} param.meta.filter.name
   * @param {Object} param.meta.filter.rangeDate
   * @param {String} param.meta.filter.rangeDate.start
   * @param {String} param.meta.filter.rangeDate.end
   * @param {String} param.meta.filter.project_type
   * @param {String} param.meta.filter.year_month
   */
  const getRangeDate = (param) => {
    dispatch(doGetRangeDateExistReport(param));
  };

  const resetState = () => {
    dispatch(MAIN_OPEN_REPORT_ACTION.resetState());
  };

  const resetStatus = () => {
    dispatch(MAIN_OPEN_REPORT_ACTION.resetStatus());
  };

  return {
    state,
    getAllReport,
    getRangeDate,
    resetState,
    resetStatus
  };
};
