import AuthGateContainer from '@bvt-features/auth/container/AuthGateContainer';
import React from 'react';
import { Navigate } from 'react-router';

import { RoutesAppMain } from './main/RoutesAppMain';
import { RoutesAppMap } from './map/RoutesMap';
import { RoutesAppMenu } from './menu/RoutesMenu';
import { RoutesAppDashboard } from './dashboard/RoutesDashboard';
import { RoutesPreviewPDF } from './preview-pdf/RoutesPreviewPDF';
import { RoutesSearch } from './search/RoutesSearch';
import { ErrorBoundary } from '../../shared/component/ErrorBoundary/ErrorBoundary';
import { RoutesAppMaps } from './map/RoutesMaps';

/** @type {import('react-router').RouteObject} */
export const RoutesApp = {
  path: 'app',
  element:
    <ErrorBoundary>
      <AuthGateContainer />
    </ErrorBoundary>,
  children: [
    RoutesAppMain,
    RoutesAppMap,
    RoutesAppMaps,
    RoutesAppMenu,
    RoutesAppDashboard,
    RoutesSearch,
    RoutesPreviewPDF,
    { index: true, element: <Navigate to={'main/home'} /> },
  ],
};
