import React from 'react';
import PropTypes from 'prop-types';
import * as BvtIcon from 'bvt-icon';
import './PopupContent.scss';
import { Svg } from '@bvt-atoms';
import { getIcon } from '@bvt-shared/utils/iconUtils';

/**
 * @param {object} props
 * @param {object} props.properties
 * @param {string} props.properties.industry_name
 * @param {string} props.properties.group_name
 * @param {string} props.properties.category_name
 * @param {string} props.properties.category_icon
 * @param {string} props.properties.brand_name
 * @param {string} props.properties.poi_name
 * @param {string} props.properties.address
 * @param {string} props.properties.longitude
 * @param {string} props.properties.latitude
 */
export function PopupContent(props) {
  return (
    <div className='PoiSearch__popup'>
      <div className='header'>
        <div className='icon'>
          {
            props.properties.category_icon
              ? <Svg src={getIcon('poi', props?.properties?.category_icon)} type='link' />
              : <BvtIcon.DefaultPoi height='22' width='22' />
          }
        </div>
        <div className='wrapper-title'>
          <div className='title'>
            {props.properties?.poi_name}
          </div>
          <div className='subtitle'>
            {props.properties?.industry_name}
          </div>
        </div>
      </div>
      <div className='info-wrapper'>
        <div className='content'>
          <div>Group</div>
          <div className='value'>{props.properties?.group_name}</div>
        </div>
        <div className='content'>
          <div>Category</div>
          <div className='value'>{props.properties?.category_name}</div>
        </div>
        <hr className='line'/>
        <div className='content'>
          <div>Longitude</div>
          <div className='value'>{props.properties?.longitude}</div>
        </div>
        <div className='content'>
          <div>Latitude</div>
          <div className='value'>{props.properties?.latitude}</div>
        </div>
        <div className='content'>
          <div>Address</div>
          <div className='value'>{props.properties?.address}</div>
        </div>
      </div>
    </div>
  );
}

PopupContent.propTypes = {
  properties: PropTypes.object,
};
