import React from 'react';
import PropTypes from 'prop-types';

export default function PasswordBar(props) {
  const { barColor, height, progress, status } = props;

  const Content = {
    height: `${height}rem`,
  };

  const Childdiv = {
    width: `${progress}%`,
    backgroundColor: barColor,
  };

  return (
    <div className='pwd-bar'>
      <div className='pwd-bar__content' style={Content}>
        <div className='pwd-bar__bar' style={Childdiv} />
      </div>

      <div className='pwd-bar__bar__text'>
        <h5>Password strength:&nbsp;</h5>
        {progress > 0 && (
          <h5 className='text-semi-bold' style={{ color: barColor }}>
            {status}
          </h5>
        )}
      </div>
    </div>
  );
}

PasswordBar.defaultProps = {
  barColor: '',
  height: '',
  progress: '',
  status: '',
};

PasswordBar.propTypes = {
  barColor: PropTypes.string,
  height: PropTypes.string,
  progress: PropTypes.string,
  status: PropTypes.string,
};
