import React, { useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { useAuth } from '@bvt-features/auth/hooks';
import { RoutesApp } from './app/RoutesApp';
import { RoutesAuth } from './auth/RoutesAuth';
import Pages from '../pages/bvt';
import { BasePageHeadTag } from '@bvt-shared/component/BasePageHeadTag';
import { GA_Utils } from '../shared/utils/GA-Utils/GA-Utils';
import { RoutesSubModule } from './sub-module/RoutesSubModule';

export default function Routes() {
  const auth = useAuth();

  useEffect(() => {
    auth.checkToken();
    const listenerUnAuth = () => {
      auth.unauthorized();
    };
    window.addEventListener('APP_AUTH_UNAUTHORIZED', listenerUnAuth);
    return () => {
      window.removeEventListener('APP_AUTH_UNAUTHORIZED', listenerUnAuth);
    };
  }, []);

  useEffect(() => {
    if (auth?.state?.data?.user?.id) {
      GA_Utils.setUserID(auth?.state?.data?.user?.id);
    }
  }, [auth.state.data.user]);

  const routeList = useRoutes([
    {
      index: true,
      element: <Navigate to={'/auth/loign'} />,
    },
    RoutesAuth,
    RoutesApp,
    RoutesSubModule,
    {
      path: '*',
      element: <Navigate to={'/404'} />,
    },
    {
      path: '401',
      element: (
        <React.Fragment>
          <BasePageHeadTag title='LI :: 401' />
          <Pages.ErrorFourOOne />
        </React.Fragment>
      ),
    },
    {
      path: '403',
      element: (
        <React.Fragment>
          <BasePageHeadTag title='LI :: 403' />
          <Pages.ErrorFourOThree />
        </React.Fragment>
      ),
    },
    {
      path: '404',
      element: (
        <React.Fragment>
          <BasePageHeadTag title='LI :: 404' />
          <Pages.ErrorFourOFour />
        </React.Fragment>
      ),
    },
    {
      path: '419',
      element: (
        <React.Fragment>
          <BasePageHeadTag title='LI :: 419' />
          <Pages.ErrorFourOneNine />
        </React.Fragment>
      ),
    },
    {
      path: '500',
      element: (
        <React.Fragment>
          <BasePageHeadTag title='LI :: 500' />
          <Pages.ErrorFiveHundred />
        </React.Fragment>
      ),
    },
    {
      path: '503',
      element: (
        <React.Fragment>
          <BasePageHeadTag title='LI :: 503' />
          <Pages.ErrorFiveOThree />
        </React.Fragment>
      ),
    },
  ]);

  return auth.state.data.isAuthReady ? routeList : 'Checking Browser';
}
