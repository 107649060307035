import React from 'react';
import { Button, Col, Row, Tooltip } from 'antd';
import './SharedPrintable.component.scss';
import { DownloadOutlined, SaveFilled, PrinterFilled, LeftOutlined } from '@ant-design/icons';

import logo from '@bvt-assets/images/logoLi.png';

/**
 * Props for SharedPrintableComponent.
 * @typedef {Object} SharedPrintableComponentProps
 * @property {() => void} [onPrint] - Function to trigger printing.
 * @property {() => void} [onSave] - Function to trigger saving.
 * @property {() => void} [onDownload] - Function to trigger downloading.
 * @property {() => void} [onBack] - Function to navigate back.
 * @property {string} title - Title of the component.
 * @property {string} [whiteLabelImage] - URL of the white label image.
 * @property {React.ReactNode} [children] - Child components.
 * @property {boolean} [isLoading] - Indicates whether the component is in loading state.
 */

/**
 * SharedPrintableComponent component.
 * @param {SharedPrintableComponentProps} props - Props for the component.
 * @param {React.Ref<HTMLDivElement>} ref - Reference to the HTML div element.
 * @returns {React.ReactElement} - SharedPrintableComponent component.
 */
export const SharedPrintableComponent = React.forwardRef((props, ref) => {
  const { title, children, isLoading, onSave, onDownload, onPrint, whiteLabelImage, onBack }
= props;

  return (
    <div className='SharedPrintableComponent__wrapper'>
      <div style={{ position: 'fixed', top: 32, left: 32 }}>
        <Button
          icon={
            <span className='anticon'>
              <LeftOutlined />
            </span>
          }
          onClick={onBack}
          size='large'
        />
      </div>
      <div className='SharedPrintableComponent__container' ref={ref}>
        <div className='SharedPrintableComponent__header'>
          <Row align='middle' gutter={[32, 16]} justify='space-between'>
            <Col order={2} xs={whiteLabelImage ? 12 : 8}>
              <img alt='' className='SharedPrintableComponent__header-img' src={logo} />
            </Col>
            {whiteLabelImage && (
              <Col order={2} style={{ textAlign: 'right' }} xs={12}>
                <img alt='' className='SharedPrintableComponent__header-img' src={whiteLabelImage} />
              </Col>
            )}
            <Col
              className='SharedPrintableComponent__header-title'
              order={whiteLabelImage ? 3 : 1}
              xs={whiteLabelImage ? 24 : 12}
            >
              {title}
            </Col>
          </Row>
        </div>
        <div className='SharedPrintableComponent__body'>{children}</div>
      </div>
      <div className='SharedPrintableComponent__action'>
        <Tooltip placement='left' title='Save as PDF'>
          <Button
            className='SharedPrintableComponent__action-btn'
            disabled={isLoading}
            icon={<SaveFilled style={{ fontSize: '18px' }} />}
            onClick={onSave}
            shape='circle'
          />
        </Tooltip>
        <Tooltip placement='left' title='Print'>
          <Button
            className='SharedPrintableComponent__action-btn'
            disabled={isLoading}
            icon={<PrinterFilled style={{ fontSize: '18px' }} />}
            onClick={onPrint}
            shape='circle'
          />
        </Tooltip>
        <Tooltip placement='left' title='Download'>
          <Button
            className='SharedPrintableComponent__action-btn'
            disabled={isLoading}
            icon={<DownloadOutlined style={{ fontSize: '18px' }} />}
            onClick={onDownload}
            shape='circle'
          />
        </Tooltip>
      </div>
    </div>
  );
});

SharedPrintableComponent.displayName = 'SharedPrintableComponent';
