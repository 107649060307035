import thunk from 'redux-thunk';
import { rootReducer } from './rootReducer';
import { configureStore } from '@reduxjs/toolkit';

const middleware = [thunk];

export const rootStore = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
});

if (module.hot && process.env.NODE_ENV !== 'DEVELOPMENT') {
  // Enabled webpack hot module replacement for reducers
  module.hot.accept('./rootReducer', () => {
    rootStore.replaceReducer(require('./rootReducer').rootReducer);
  });
}
