import React from 'react';
import { IconType, IconTypeDefault } from '@bvt-types';

export default function IconRemove(props) {
  const { className, height, width } = props;

  return (
    <svg className={className} height={height} width={width}>
      <path
        className={className}
        d='M8.137,14.763a.588.588,0,0,0,.8.131l1.516-1.522,1.516,1.522a.692.692,0,0,0,.928-.928L11.371,12.45l1.522-1.516a.588.588,0,0,0-.131-.8.588.588,0,0,0-.8-.131L10.45,11.529,8.934,10.006a.588.588,0,0,0-.8.131.588.588,0,0,0-.131.8L9.529,12.45,8.006,13.966a.588.588,0,0,0,.131.8Z'
        transform='translate(-4.047 -4.087)'
      />
      <path
        className={className}
        d='M14.283,1H2.784A.784.784,0,0,0,2,1.784V4.136a.784.784,0,0,0,.784.784h.523v8.494a1.96,1.96,0,0,0,1.96,1.96H11.8a1.96,1.96,0,0,0,1.96-1.96V4.92h.523a.784.784,0,0,0,.784-.784V1.784A.784.784,0,0,0,14.283,1ZM12.454,13.414a.653.653,0,0,1-.653.653H5.267a.653.653,0,0,1-.653-.653V4.92h7.84Zm1.307-9.8H3.307V2.307H13.76Z'
        transform='translate(-2 -1)'
      />
    </svg>
  );
}

IconRemove.defaultProps = IconTypeDefault;

IconRemove.propTypes = IconType;
