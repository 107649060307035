import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Faisal <ahmad.faisal@bvarta.com>
 * @param {Object} props
 * @param {string} props.className
 * @param {Function} props.onClick
 */

export default function IconXls(props) {
  const { className, onClick } = props;

  return (
    <div className={`${className} iconXls`} onClick={onClick}>
      <svg
        height='18.17'
        id='_1577463456'
        viewBox='0 0 15.033 18.17'
        width='15.033'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M10.4,200.9v1.924h1.925ZM.792,198.192H9.938l5.1,5.1V216.36H0V198.19H.792Zm8.024,1.584H1.587v15H13.449V204.409H8.817v-4.632Z'
          data-name='Path 758'
          fill='#4d4f5c'
          id='Path_758'
          transform='translate(0 -198.19)'
        />
        <path
          d='M6.94,246.03H18.8v5.664H6.94Z'
          data-name='Path 759'
          fill='#22a465'
          fillRule='evenodd'
          id='Path_759'
          transform='translate(-5.356 -235.108)'
        />
        <path
          d='M26.76,254.971l1-1.518-.911-1.393h.687l.573.868.571-.868h.687l-.911,1.395,1,1.516h-.705l-.646-.986-.646.986Z'
          data-name='Path 760'
          fill='#fff'
          id='Path_760'
          transform='translate(-20.651 -239.762)'
        />
      </svg>
    </div>
  );
}

IconXls.defaultProps = {
  className: '',
  onClick: () => {},
};

IconXls.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
