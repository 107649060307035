import React, { Fragment, useEffect } from 'react';
import { usePlace } from '../../hooks/usePlace';
import { GeoJSON, useMap, Popup } from 'react-leaflet';
import _, { isEmpty } from 'lodash';
import * as Leaflet from 'leaflet';
import { useMainMap } from '@bvt-features/mainmap/mainmap/hook/useMainMap';
import { useProject } from '@bvt-features/mapp/project/hooks/useProject';
import { useLocation } from 'react-router';

export function getBounds(value) {
  return Leaflet.geoJSON(value).getBounds();
}

export function GeoJsonMapAdvanceSearch() {
  const uLocation = useLocation();
  const PLACE = usePlace();
  const initialMap = useMap();
  const MAINMAP = useMainMap();
  const PROJECT = useProject();

  const styleFinder = _.find(
    MAINMAP.state.layerVisibilityList,
    (v) => v.key === 'ADM_BOUNDARY'
  );

  /**
   * @type {import('leaflet').PathOptions}
   */
  const Style = {
    color: '#748DA6',
    stroke: 1,
    fillColor: '#748DA6',
    fillOpacity: styleFinder?.isActive ? styleFinder?.opacity : 0,
    opacity: styleFinder?.isActive ? styleFinder?.opacity : 0,
  };

  useEffect(() => {
    const data = PLACE?.state.advance.list;
    const stepLast = PROJECT.state.detail.id_last_step;
    const isRestricted = PLACE.state.country_mandala.country_id !== 1 && stepLast === 7;

    // Helper function to process polygons
    const processPolygon = (polygonKey, geojsonKey) => {
      const polygonData = data?.[polygonKey];
      if (!isEmpty(polygonData)) {
        if (!isRestricted) {
          initialMap.flyToBounds(getBounds(polygonData));
        }
        PLACE.setGeojson({ [geojsonKey]: polygonData });
      }
    };

    // Process each polygon type
    processPolygon('polygon_provinsi', 'provinsi');
    processPolygon('polygon_kota', 'kota');
    processPolygon('polygon_kecamatan', 'kecamatan');
    processPolygon('polygon_desa', 'desa');
  }, [PLACE.state.advance]);


  useEffect(() => {
    if (PLACE?.state?.geojson?.provinsi === null) {
      MAINMAP.removeLayer('ADM_BOUNDARY');
    } else {
      MAINMAP.registerLayer({
        isActive: true,
        key: 'ADM_BOUNDARY',
        opacity: 0.3,
        title: 'Adminstrative boundary',
      });
    }

    return () => {
      MAINMAP.removeLayer('ADM_BOUNDARY');
    };
  }, [PLACE.state.geojson.provinsi]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window?.location?.search);
    const step = +urlParams?.get('step');
    const pathname = uLocation.pathname;
    if (
      (pathname.includes('grid-analysis') && step === 6) ||
      (pathname.includes('site-profiling') && step === 7)
    ) {
      ['provinsi', 'kota', 'kecamatan', 'desa'].forEach((placeType) =>
        PLACE.setGeojson({ [placeType]: null })
      );
    }
  }, [uLocation.search, PLACE.state?.advance?.list]);

  const renderGeojsonLayer = (geojsonData) => {
    if (isEmpty(geojsonData)) {
      return null;
    }

    return (
      <GeoJSON
        data={geojsonData}
        ref={(ref) => {
          if (ref && ref.setZIndex) {
            ref.setZIndex(1);
          }
        }}
        style={() => Style}
      >
        <Popup closeButton={false}>
          <center>{geojsonData.properties?.displayName || null}</center>
        </Popup>
      </GeoJSON>
    );
  };

  const renderGejson = () => {
    const { provinsi, kota, kecamatan, desa } = PLACE.state.geojson;

    return (
      <React.Fragment>
        {renderGeojsonLayer(provinsi)}
        {renderGeojsonLayer(kota)}
        {renderGeojsonLayer(kecamatan)}
        {renderGeojsonLayer(desa)}
      </React.Fragment>
    );
  };

  return (
    <Fragment>
      {initialMap && styleFinder?.isActive ? renderGejson() : null}
    </Fragment>
  );
}
