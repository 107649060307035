import React, { useEffect, useRef, useState } from 'react';
import * as L from 'leaflet';

import IconTelkom from '@bvt-assets/images/telkom-property.png';

import { AsmanMarkerGeojsonInternalComponent } from './AsmanMarkerGeojson.internal.component';
import './AsmanMarkerGeojson.component.scss';

export const AsmanMarkerGeojsonComponent = props => {
  const { mapInstance, data, onDashboardClick, isLoading } = props;
  const popRef = useRef(null);
  const [state, setState] = useState({
    popupLL: { lat: 0, lng: 0 },
    data: {}
  });
  const inernalLeafletClickEvt = e => {
    setState({
      popupLL: {
        lat: e.target?.feature?.geometry?.coordinates?.[1],
        lng: e.target?.feature?.geometry?.coordinates?.[0]
      },
      data: e.target?.feature?.properties
    });
  };
  useEffect(() => {
    const geom = L.geoJson(data, {
      pointToLayer(_geoJsonPoint, latlng) {
        const mkr = L.marker(latlng, {
          icon: L.icon({
            iconUrl: IconTelkom,
            iconSize: [32, 32],
            popupAnchor: [0, -16]
          })
        });
        return mkr;
      },
      onEachFeature(_feature, layer) {
        layer.on('click', inernalLeafletClickEvt);
      }
    });
    if (mapInstance && !isLoading) {
      geom.addTo(mapInstance);
    }
    return () => {
      if (mapInstance) {
        geom.removeFrom(mapInstance);
      }
      setState({ ...state, popupLL: {} });
    };
  }, [data, isLoading]);
  return (
    <AsmanMarkerGeojsonInternalComponent
      address={state.data?.address}
      area={state.data?.area}
      id={state.data?.id}
      idle_area={state.data?.idle_area}
      image={state.data?.image}
      lat={state.popupLL?.lat}
      lng={state.popupLL?.lng}
      name={state.data?.name}
      onDashboardClick={() => onDashboardClick && onDashboardClick(state.data)}
      propar={state.data?.propar}
      ref={popRef}
      tenant={state.data?.tenant}
      usage={state.data?.usage}
    />
  );
};
