import React from 'react';
import { Navigate, Outlet } from 'react-router';
import Pages from '../../../pages/bvt';
import { LayoutMainAppContainer } from '@bvt-features/mapp/container/LayoutMainAppContainer';
import { BasePageHeadTag } from '@bvt-shared/component/BasePageHeadTag';
import { AccountContainer } from '@bvt-features/auth/container/AccountContainer';
/** @type {import('react-router').RouteObject} */
export const RoutesAppMain = {
  path: 'main',
  element: (
    <LayoutMainAppContainer>
      <Outlet />
    </LayoutMainAppContainer>
  ),
  children: [
    {
      index: true,
      element: <Navigate to={'home'} />,
    },
    {
      path: 'home',
      element: (
        <React.Fragment>
          <BasePageHeadTag title='LI :: Home' />
          <Pages.MainHome />
        </React.Fragment>
      ),
    },
    {
      path: 'new',
      children: [
        {
          element: (
            <React.Fragment>
              <BasePageHeadTag title='LI :: New' />
              <Pages.MainNew />
            </React.Fragment>
          ),
          index: true,
        },
        {
          path: ':id',
          element: (
            <React.Fragment>
              <BasePageHeadTag title='LI :: New' />
              <Pages.MainNewDetail />
            </React.Fragment>
          ),
        },
      ],
    },
    {
      path: 'open',
      element: (
        <React.Fragment>
          <BasePageHeadTag title='LI :: Open' />
          <Pages.MainOpen />
        </React.Fragment>
      ),
    },
    {
      path: 'history',
      element: (
        <React.Fragment>
          <BasePageHeadTag title='LI :: History' />
          <Pages.MainHistory />
        </React.Fragment>
      ),
    },
    {
      path: 'account/:slug',
      element: (
        <React.Fragment>
          <BasePageHeadTag title='LI :: Account' />
          <AccountContainer />
        </React.Fragment>
      ),
    },
  ],
};
