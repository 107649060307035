import { createAsyncThunk } from '@reduxjs/toolkit';

import { MarkerService } from '../service';
import { MARKER_ACTION } from './marker.slice';

const markerService = new MarkerService();

export const MarkerAsyncAction = {
  getList: createAsyncThunk('LI/GENSET/MARKER/getList', async (param, thunk) => {
    try {
      const response = await markerService.getAll(param.query);
      thunk.dispatch(MARKER_ACTION.setAll(response.results.map((marker) => ({
        ...marker,
        id: marker.marker_id,
        IconColor: marker.icon_color,
      }))));

      return response;
    } catch (error) {
      return thunk.rejectWithValue(error);
    }
  }),

  create: createAsyncThunk('LI/GENSET/MARKER/create',async (param, thunk) => {
    try {
      return await markerService.add(param.payload);
    } catch (error) {
      return thunk.rejectWithValue(error);
    }
  }),

  update: createAsyncThunk('LI/GENSET/MARKER/update', async (param, thunk) => {
    try {
      const response = await markerService.edit(param.id, param.payload);
      thunk.dispatch(MARKER_ACTION.updateOne({ id: param.id, changes: {
        ...(response?.result || {}),
        id: response?.result?.marker_id,
        IconColor: response?.result?.icon_color,
      } }));
      return response;
    } catch (error) {
      return thunk.rejectWithValue(error);
    }
  }),

  delete: createAsyncThunk('LI/GENSET/MARKER/delete', async (param, thunk) => {
    try {
      thunk.dispatch(MARKER_ACTION.removeOne(param.id));
      return await markerService.delete(param.id);
    } catch (error) {
      return thunk.rejectWithValue(error);
    }
  }),

  resetStatusAndMessage: createAsyncThunk(
    'LI/PARSET/INDUSTRY/resetStatusAndMessage',
    async (_, thunk) => {
      thunk.dispatch(
        MARKER_ACTION.setStatus({ status: ['LIST', 'CREATE', 'UPDATE', 'DELETE'], type: 'IDLE' })
      );
      thunk.dispatch(MARKER_ACTION.setMessage({ message: '' }));
    }
  ),
  removeAll: createAsyncThunk(
    'LI/PARSET/INDUSTRY/removeAll',
    async (_, thunk) => {
      thunk.dispatch(MARKER_ACTION.removeAll());
    }
  ),
};
