import React from 'react';
import { MainMapMapControlButton } from '../MainMapMapControlButton';
import { generateUUID } from '@bvt-utils';

/**
 * @author Randa <m.randa@bvarta.com>
 * @description List of the item on the sidebar
 * @param {object} props
 * @param {Array<Array<{children:React.ReactElement,onClick:()=>void,disabled:boolean,title:string}>>} props.data
 * @returns
 */
export const MainMapMapControlButtonList = (props) => {
  const { data } = props;

  const classChecker = (currentIndex, length) => {
    if (length === 1) {
      return 'single';
    } else {
      if (currentIndex === 0) {
        return 'top';
      } else if (currentIndex === length - 1) {
        return 'bottom';
      } else {
        return 'middle';
      }
    }
  };

  return (
    data &&
    data.map((v) => (
      <div className='bvt-map__toolbar-group' key={generateUUID()}>
        {v?.map((v2, v2idx) => (
          <MainMapMapControlButton
            disabled={v2.disabled}
            key={generateUUID()}
            onClick={() => v2?.onClick && v2.onClick()}
            position={classChecker(v2idx, v.length)}
            title={v2.title}
          >
            {v2.children}
          </MainMapMapControlButton>
        ))}
      </div>
    ))
  );
};
