import * as React from 'react';

export const IconPOIData = (props) => (
  <svg
    fill='none'
    height={24}
    viewBox='0 0 16 24'
    width={16}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M7.99927 23.585C12.4175 23.585 15.9993 22.2766 15.9993 20.6626C15.9993 19.0486 12.4175 17.7402 7.99927 17.7402C3.58099 17.7402 -0.000732422 19.0486 -0.000732422 20.6626C-0.000732422 22.2766 3.58099 23.585 7.99927 23.585Z'
      fill='url(#paint0_radial_6236_23171)'
    />
    <path
      d='M7.99918 21.5696C10.7113 21.5696 12.91 20.8715 12.91 20.0104C12.91 19.1492 10.7113 18.4512 7.99918 18.4512C5.28702 18.4512 3.08838 19.1492 3.08838 20.0104C3.08838 20.8715 5.28702 21.5696 7.99918 21.5696Z'
      fill='url(#paint1_radial_6236_23171)'
    />
    <path
      clipRule='evenodd'
      d='M8 0.800049C9.05091 0.800049 10.0915 1.00711 11.0624 1.40939C12.0333 1.81168 12.9154 2.40132 13.6583 3.14461C14.4012 3.8879 14.9904 4.77029 15.3922 5.74135C15.794 6.71242 16.0005 7.75314 16 8.80405C16 13.5188 11.08 16.9896 8 20.012C4.92 16.9916 1.00011e-06 13.5212 1.00011e-06 8.80405C-0.000524456 7.75314 0.206011 6.71242 0.607813 5.74135C1.00962 4.77029 1.5988 3.8879 2.34172 3.14461C3.08464 2.40132 3.96676 1.81168 4.93762 1.40939C5.90849 1.00711 6.94909 0.800049 8 0.800049Z'
      fill='url(#paint2_linear_6236_23171)'
      fillRule='evenodd'
    />
    <path
      clipRule='evenodd'
      d='M5.00025 5.69446C5.74541 4.95021 6.74072 4.50967 7.79266 4.45851C8.84459 4.40734 9.87795 4.7492 10.6918 5.41762C11.5057 6.08604 12.0419 7.03325 12.1962 8.07506C12.3505 9.11687 12.1118 10.1788 11.5266 11.0545C11.1866 11.4884 10.7954 11.8796 10.3615 12.2197C9.48583 12.8059 8.42341 13.0454 7.38094 12.8916C6.33846 12.7378 5.39047 12.2017 4.72144 11.3876C4.05241 10.5735 3.71019 9.53954 3.76136 8.48702C3.81252 7.43451 4.25341 6.43866 4.99825 5.69326L5.00025 5.69446Z'
      fill='white'
      fillRule='evenodd'
    />
    <path
      clipRule='evenodd'
      d='M11.5266 11.0557L12.8254 12.3565C12.9256 12.4569 12.9818 12.593 12.9818 12.7349C12.9818 12.8767 12.9256 13.0128 12.8254 13.1133L12.417 13.5217C12.3167 13.6213 12.1812 13.6772 12.0398 13.6772C11.8985 13.6772 11.7629 13.6213 11.6626 13.5217L10.3618 12.2209C10.7951 11.8803 11.1862 11.4891 11.5266 11.0557Z'
      fill='url(#paint3_linear_6236_23171)'
      fillRule='evenodd'
    />
    <path
      clipRule='evenodd'
      d='M6.72229 6.48201C7.16013 6.22932 7.66305 6.11213 8.16748 6.14527C8.67192 6.1784 9.15522 6.36038 9.55623 6.66817C9.95724 6.97597 10.258 7.39576 10.4204 7.87448C10.5828 8.35319 10.5997 8.86932 10.4688 9.3576C10.3379 9.84588 10.0651 10.2844 9.68504 10.6176C9.30493 10.9509 8.83453 11.164 8.33333 11.2299C7.83213 11.2959 7.32263 11.2117 6.86927 10.9881C6.4159 10.7645 6.03904 10.4114 5.78631 9.97361C5.61828 9.68295 5.50918 9.36201 5.46529 9.02916C5.42139 8.69631 5.44354 8.35806 5.53047 8.03378C5.6174 7.70949 5.76742 7.40552 5.97193 7.13926C6.17643 6.87299 6.43141 6.64965 6.72229 6.48201Z'
      fill='url(#paint4_linear_6236_23171)'
      fillRule='evenodd'
    />
    <defs>
      <radialGradient
        cx={0}
        cy={0}
        gradientTransform='translate(2.86496 18.6748) rotate(0.169013) scale(7.81602 2.28245)'
        gradientUnits='userSpaceOnUse'
        id='paint0_radial_6236_23171'
        r={1}
      >
        <stop stopColor='#FF7506' />
        <stop offset={1} stopColor='#FFF1D4' />
      </radialGradient>
      <radialGradient
        cx={0}
        cy={0}
        gradientTransform='translate(4.63344 19.0302) rotate(0.203176) scale(7.48098 2.28256)'
        gradientUnits='userSpaceOnUse'
        id='paint1_radial_6236_23171'
        r={1}
      >
        <stop stopColor='#FF7506' />
        <stop offset={1} stopColor='#FFF1D4' />
      </radialGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='paint2_linear_6236_23171'
        x1={0.0479998}
        x2={16.2526}
        y1={14.9017}
        y2={4.56221}
      >
        <stop stopColor='#FF6700' />
        <stop offset={1} stopColor='#FFCB2E' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='paint3_linear_6236_23171'
        x1={12.0805}
        x2={11.2494}
        y1={12.8383}
        y2={11.7535}
      >
        <stop stopColor='white' />
        <stop offset={1} stopColor='#D7E5F5' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='paint4_linear_6236_23171'
        x1={6.90556}
        x2={8.10676}
        y1={11.1086}
        y2={5.55183}
      >
        <stop stopColor='#FF6700' />
        <stop offset={1} stopColor='#FFCB2E' />
      </linearGradient>
    </defs>
  </svg>
);
