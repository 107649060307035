const INTERACTIVE_MAP_CONSTANT = {
  BASELAYER: 'LI/MAP/BASELAYER',
  SIDEBAR_LEFT: 'LI/MAP/SIDEBAR_LEFT',
  SIDEBAR_RIGHT: 'LI/MAP/SIDEBAR_RIGHT',
  UUID: 'LI/MAP/UUID',
  DIRECTION: 'LI/MAP/DIRECTION',
  TILELAYER: 'LI/MAP/TILELAYER',
  FULLSCREEN: 'LI/MAP/FULLSCREEN',
  ZOOM_BOX: 'LI/MAP/ZOOM_BOX',
};

export default INTERACTIVE_MAP_CONSTANT;
