import Resource from '../../../../api/resource';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 * @author Ryfan <ryfan.aditya@bvarta.com>
 * @author Riza <mochamad.riza@bvarta.com>
 */

export class MappOpenProjectService extends Resource {
  constructor() {
    super('project', '/api', 'api-poi', 'v2');
  }

  /**
   * @description For meta project
   * @param {Object} param
   * @param {Object} param.meta
   * @param {Object} param.meta.filter
   * @param {String} param.meta.filter.name
   * @param {Object} param.meta.filter.rangeDate
   * @param {String} param.meta.filter.rangeDate.start
   * @param {String} param.meta.filter.rangeDate.end
   * @param {"Analysis - Site Profiling"|"Analysis - Business Profiling"|"Analysis - Grid Profiling"} param.meta.filter.project_type
   * @param {Number} param.meta.page
   * @param {Number} param.meta.pageSize
   * @param {Object} param.meta.sort
   * @param {'name'|'lastupdate'|'datecreate'} param.meta.sort.by
   * @param {'DESC'|'ASC'} param.meta.sort.type
   */
  getAll(query) {
    this.setAdditionUri('');
    const paramString = JSON.stringify(query);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }

  /**
   * @description For duplicate payload
   * @param {Number} id
   * @param {Object} payload
   * @param {String} payload.name
   */
  duplicate(id, payload) {
    this.setAdditionUri(`/duplicate/${id}`);
    return this.create(payload);
  }

  /**
   * @description For rename payload
   * @param {Number} id
   * @param {Object} payload
   * @param {String} payload.name
   */
  rename(id, payload) {
    this.setAdditionUri('/rename');
    return this.patch(id, payload);
  }

  /**
   * @description For delete project by id
   * @param {Number} id
   */
  delete(id) {
    this.setAdditionUri('');
    return this.destroy(id);
  }

  /**
   * @description For get range date param
   * @param {Object} param
   * @param {Object} param.meta
   * @param {Object} param.meta.filter
   * @param {String} param.meta.filter.type
   * @param {String} param.meta.filter.name
   * @param {Object} param.meta.filter.rangeDate
   * @param {String} param.meta.filter.rangeDate.start
   * @param {String} param.meta.filter.rangeDate.end
   * @param {String} param.meta.filter.project_type
   * @param {String} param.meta.filter.year_month
   */
  rangeDateExist(param) {
    this.setAdditionUri('/range-date');
    const paramString = JSON.stringify(param);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }
}
