import { MAPP_PROJECT_STEP_HISTORY_CONSTANT } from '@bvt-features/mapp/projectStepHistory/redux';
import { MappProjectStepHistoryService } from '../service';

const projectStepHistService = new MappProjectStepHistoryService();

export const MAPP_PROJECT_STEP_HISTORY_ACTIONS = {
  create: (payload) => {
    return async (dispatch) => {
      dispatch({
        type: MAPP_PROJECT_STEP_HISTORY_CONSTANT.CREATE_START,
        payload: { status_POST: 'LOADING' },
      });
      try {
        const data = await projectStepHistService.create_project_step_hist(
          payload
        );
        dispatch({
          type: MAPP_PROJECT_STEP_HISTORY_CONSTANT.CREATE_SUCCESS,
          payload: {
            status_POST: 'SUCCESS',
            message: data.message,
          },
        });
      } catch (error) {
        dispatch({
          type: MAPP_PROJECT_STEP_HISTORY_CONSTANT.CREATE_FAILED,
          payload: {
            status_POST: 'FAILED',
            message: error.response.data.message,
          },
        });
      }
    };
  },
};
