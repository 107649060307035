import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@bvt-shared/component/Skeleton';
import '../_linkBox.scss';
import { Popover } from '@bvt-atoms';
/**
 * @description For dataLayer object
 * @typedef TLinkBoxProps
 * @type {Object}
 * @property {JSX.Element} props.children
 * @property {String} props.background
 * @property {String} props.className
 * @property {String} props.src
 * @property {String} props.text
 * @property {String} props.type
 * @property {String} props.BvtIcon
 * @property {String} props.icon
 * @property {(val:string)=>void} props.onClick
 * @property {Boolean} props.isHovered
 * @property {Boolean} props.loading
 */

/**
 * @param {TLinkBoxProps} props
 */
export const LinkBoxSquare = (props) => {
  const {
    className,
    onClick,
    text,
    background,
    disabled,
    isHovered,
    loading,
    style,
    BvtIcon,
    imgHeight,
    imgWidth,
    icon,
    textHeight,
    textWidth,
  } = props;
  const popoverContent = (
    <div className='link-box container-popover'>
      <div className='link-box arrow arrow---down' />
      <p className='link-box container-popover-text'>
        Contact our sales to subscribe
      </p>
    </div>
  );

  const hoverClass = isHovered ? 'on-hover' : '';
  const customClass = `link-box ${className} ${hoverClass}`;
  const backgroundClass = background !== '' ? background : '';
  const disabledClass = disabled ? 'link-box--disabled' : '';
  return (
    <>
      <Skeleton
        height={imgHeight}
        loading={loading}
        type='image'
        width={imgWidth}
      >
        {disabled ? (
          <Popover
            className='link-box__popover-wrapper'
            content={popoverContent}
            header={false}
            placement='top'
            trigger='hover'
            visibility={true}
          >
            <div
              className={customClass}
              onClick={!disabled ? onClick : undefined}
            >
              <div
                className={`image-link-box-card${backgroundClass} ${disabledClass}`}
                style={{ ...style }}
              >
                {<img alt={icon} src={icon} />}
                {BvtIcon && <BvtIcon />}
              </div>
              <Skeleton
                className={`link-text-skeleton`}
                height={textHeight}
                loading={loading}
                type='square'
                width={textWidth}
              >
                <div
                  className={`image-link-box-text ${disabled && 'disabled'}`}
                >
                  {text}
                </div>
              </Skeleton>
            </div>
          </Popover>
        ) : (
          <div
            className={customClass}
            onClick={!disabled ? onClick : undefined}
          >
            <div
              className={`image-link-box-card${backgroundClass} ${disabledClass}`}
              style={{ ...style }}
            >
              {<img alt={icon} src={icon} />}
              {BvtIcon && <BvtIcon />}
            </div>
            <Skeleton
              className={`link-text-skeleton`}
              height={textHeight}
              loading={loading}
              type='square'
              width={textWidth}
            >
              <div className={`image-link-box-text ${disabled && 'disabled'}`}>
                {text}
              </div>
            </Skeleton>
          </div>
        )}
      </Skeleton>
    </>
  );
};

LinkBoxSquare.defaultProps = {
  background: '',
  BvtIcon: undefined,
  className: '',
  disabled: false,
  imgHeight: '5.5rem',
  imgWidth: '5.5rem',
  isHovered: false,
  loading: false,
  onClick: () => {},
  src: '',
  style: {},
  text: '',
  textHeight: '2rem',
  textWidth: '5.5rem',
  type: 'svg',
};

LinkBoxSquare.propTypes = {
  background: PropTypes.string,
  BvtIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  imgHeight: PropTypes.string,
  imgWidth: PropTypes.string,
  isHovered: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  src: PropTypes.string,
  style: PropTypes.object,
  text: PropTypes.string,
  textHeight: PropTypes.string,
  textWidth: PropTypes.string,
  type: PropTypes.oneOf(['svg', 'png', 'jpeg', 'link']),
};
