import { BrandService } from '../service';
import { GENSET_BRAND_CONSTANT } from './GENSET_BRAND_CONSTANT';

const brandService = new BrandService();

export const GENSET_BRAND_ACTIONS = {
  get: (_query) => {
    return async (dispatch) => {
      dispatch({
        type: GENSET_BRAND_CONSTANT.GET_BRAND_START,
        payload: {
          status_GET: 'LOADING',
        },
      });
      try {
        const data = await brandService.getAll(_query);
        dispatch({
          type: GENSET_BRAND_CONSTANT.GET_BRAND_SUCCESS,
          payload: {
            status_GET: 'SUCCESS',
            list: data.results,
            message: data.message,
            properties: data.meta,
          },
        });
      } catch (error) {
        dispatch({
          type: GENSET_BRAND_CONSTANT.GET_BRAND_FAILED,
          payload: {
            status_GET: 'FAILED',
            message: error.response.data.message,
          },
        });
      }
    };
  },
};
