import * as React from 'react';

export const IconProcessing = (props) => (
  <svg
    fill='none'
    height={12}
    width={12}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#a)'>
      <path
        d='M6 11.334a5.335 5.335 0 0 1-4.434-8.296A5.333 5.333 0 1 1 9.77 9.77 5.294 5.294 0 0 1 6 11.334ZM6 2.19A3.809 3.809 0 1 0 6 9.808 3.809 3.809 0 0 0 6 2.19Z'
        fill='#CACED5'
      />
      <path
        d='M6.001 11.333a5.292 5.292 0 0 1-3.77-1.563A5.294 5.294 0 0 1 .666 5.997a5.314 5.314 0 0 1 .722-2.681l1.414.615c-.399.615-.61 1.333-.61 2.066a3.814 3.814 0 0 0 3.808 3.81 3.813 3.813 0 0 0 3.809-3.81 3.811 3.811 0 0 0-3.809-3.809V.667a5.294 5.294 0 0 1 3.769 1.56 5.297 5.297 0 0 1 1.563 3.77A5.293 5.293 0 0 1 9.77 9.77a5.29 5.29 0 0 1-3.769 1.563Z'
        fill='#00A8FF'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path d='M0 0h12v12H0z' fill='#fff' />
      </clipPath>
    </defs>
  </svg>
);
