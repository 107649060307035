import * as React from 'react';

export const IconThematicData = (props) => (
  <svg
    fill='none'
    height={24}
    viewBox='0 0 25 24'
    width={25}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      clipRule='evenodd'
      d='M12.2505 0.800062C13.7264 0.775021 15.1926 1.0441 16.5635 1.59161C17.9344 2.13912 19.1826 2.9541 20.2352 3.98902C21.2878 5.02395 22.1238 6.25809 22.6945 7.61949C23.2652 8.98089 23.5591 10.4423 23.5591 11.9185C23.5591 13.3946 23.2652 14.856 22.6945 16.2174C22.1238 17.5788 21.2878 18.813 20.2352 19.8479C19.1826 20.8828 17.9344 21.6978 16.5635 22.2453C15.1926 22.7928 13.7264 23.0619 12.2505 23.0369V0.802062V0.800062Z'
      fill='url(#paint0_linear_6236_23150)'
      fillRule='evenodd'
    />
    <path
      clipRule='evenodd'
      d='M12.2512 0.800049C15.6968 0.800049 18.4876 5.77765 18.4876 11.92C18.4876 18.0625 15.6964 23.0401 12.2512 23.0401V0.802049V0.800049Z'
      fill='url(#paint1_linear_6236_23150)'
      fillRule='evenodd'
    />
    <path
      clipRule='evenodd'
      d='M12.252 0.800062C10.776 0.775021 9.30985 1.0441 7.93896 1.59161C6.56808 2.13912 5.31994 2.9541 4.26731 3.98902C3.21468 5.02395 2.37864 6.25809 1.80796 7.61949C1.23727 8.98089 0.943359 10.4423 0.943359 11.9185C0.943359 13.3946 1.23727 14.856 1.80796 16.2174C2.37864 17.5788 3.21468 18.813 4.26731 19.8479C5.31994 20.8828 6.56808 21.6978 7.93896 22.2453C9.30985 22.7928 10.776 23.0619 12.252 23.0369V0.802062V0.800062Z'
      fill='url(#paint2_linear_6236_23150)'
      fillRule='evenodd'
    />
    <path
      clipRule='evenodd'
      d='M12.2512 0.800049C8.8056 0.800049 6.0144 5.77765 6.0144 11.92C6.0144 18.0625 8.8056 23.0401 12.2512 23.0401V0.802049V0.800049Z'
      fill='url(#paint3_linear_6236_23150)'
      fillRule='evenodd'
    />
    <path
      clipRule='evenodd'
      d='M9.25099 8.91675C9.99616 8.1725 10.9915 7.73196 12.0434 7.6808C13.0954 7.62963 14.1287 7.97149 14.9426 8.63991C15.7565 9.30833 16.2927 10.2555 16.447 11.2974C16.6013 12.3392 16.3626 13.4011 15.7774 14.2767C15.4374 14.7107 15.0461 15.1019 14.6122 15.4419C13.7367 16.0277 12.6746 16.2669 11.6325 16.1131C10.5904 15.9592 9.64277 15.4233 8.97384 14.6096C8.30492 13.7959 7.96252 12.7624 8.01322 11.7103C8.06393 10.6581 8.50413 9.66239 9.24821 8.91675H9.25099Z'
      fill='white'
      fillRule='evenodd'
    />
    <path
      clipRule='evenodd'
      d='M15.7775 14.2804L17.0759 15.5812C17.1761 15.6817 17.2323 15.8177 17.2323 15.9596C17.2323 16.1015 17.1761 16.2375 17.0759 16.338L16.6675 16.7464C16.5673 16.846 16.4317 16.9019 16.2903 16.9019C16.149 16.9019 16.0134 16.846 15.9131 16.7464L14.6135 15.4444C15.0465 15.1041 15.4372 14.7133 15.7775 14.2804Z'
      fill='url(#paint4_linear_6236_23150)'
      fillRule='evenodd'
    />
    <path
      clipRule='evenodd'
      d='M12.2536 14.4742V9.36096C11.5758 9.36096 10.9258 9.63021 10.4465 10.1095C9.96724 10.5888 9.698 11.2388 9.698 11.9166C9.698 12.5943 9.96724 13.2444 10.4465 13.7236C10.9258 14.2029 11.5758 14.4722 12.2536 14.4722V14.4742Z'
      fill='url(#paint5_linear_6236_23150)'
      fillRule='evenodd'
    />
    <path
      clipRule='evenodd'
      d='M12.2536 14.4742V9.36096C11.5758 9.36096 10.9258 9.63021 10.4465 10.1095C9.96724 10.5888 9.698 11.2388 9.698 11.9166C9.698 12.5943 9.96724 13.2444 10.4465 13.7236C10.9258 14.2029 11.5758 14.4722 12.2536 14.4722V14.4742Z'
      fill='url(#paint6_linear_6236_23150)'
      fillRule='evenodd'
    />
    <path
      clipRule='evenodd'
      d='M12.2535 14.4742V9.36096C12.9315 9.36123 13.5817 9.63084 14.061 10.1105C14.5402 10.5901 14.8093 11.2405 14.8091 11.9186C14.8088 12.5966 14.5392 13.2468 14.0595 13.7261C13.5799 14.2053 12.9295 14.4744 12.2515 14.4742H12.2535Z'
      fill='url(#paint7_linear_6236_23150)'
      fillRule='evenodd'
    />
    <path
      clipRule='evenodd'
      d='M12.2535 14.4742V9.36096C12.9315 9.36123 13.5817 9.63084 14.061 10.1105C14.5402 10.5901 14.8093 11.2405 14.8091 11.9186C14.8088 12.5966 14.5392 13.2468 14.0595 13.7261C13.5799 14.2053 12.9295 14.4744 12.2515 14.4742H12.2535Z'
      fill='url(#paint8_linear_6236_23150)'
      fillRule='evenodd'
    />
    <defs>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='paint0_linear_6236_23150'
        x1={0.0823923}
        x2={23.0729}
        y1={14.9876}
        y2={14.9876}
      >
        <stop stopColor='#FF6700' />
        <stop offset={1} stopColor='#FFCB2E' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='paint1_linear_6236_23150'
        x1={5.54085}
        x2={18.2132}
        y1={14.9892}
        y2={14.9892}
      >
        <stop stopColor='#FF6700' />
        <stop offset={1} stopColor='#FFCB2E' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='paint2_linear_6236_23150'
        x1={12.252}
        x2={7.28749}
        y1={12.1631}
        y2={12.1574}
      >
        <stop stopColor='#FF7E0A' />
        <stop offset={1} stopColor='#FFAA1F' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='paint3_linear_6236_23150'
        x1={12.2512}
        x2={4.72963}
        y1={12.1425}
        y2={12.1425}
      >
        <stop stopColor='#FF7E0A' />
        <stop offset={1} stopColor='#FF9013' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='paint4_linear_6236_23150'
        x1={16.3314}
        x2={15.4976}
        y1={16.0604}
        y2={14.9786}
      >
        <stop stopColor='white' />
        <stop offset={1} stopColor='#D7E5F5' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='paint5_linear_6236_23150'
        x1={3.19913}
        x2={20.3293}
        y1={14.9855}
        y2={14.9829}
      >
        <stop stopColor='#FF6700' />
        <stop offset={1} stopColor='#FFCB2E' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='paint6_linear_6236_23150'
        x1={6.05117}
        x2={15.6602}
        y1={14.9804}
        y2={14.9804}
      >
        <stop stopColor='#FF6700' />
        <stop offset={1} stopColor='#FFCB2E' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='paint7_linear_6236_23150'
        x1={2.04657}
        x2={21.2671}
        y1={11.5494}
        y2={11.5481}
      >
        <stop stopColor='#FF6700' />
        <stop offset={1} stopColor='#FFCB2E' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='paint8_linear_6236_23150'
        x1={7.65031}
        x2={18.4332}
        y1={11.5494}
        y2={11.5481}
      >
        <stop stopColor='#FF6700' />
        <stop offset={1} stopColor='#FFCB2E' />
      </linearGradient>
    </defs>
  </svg>
);
