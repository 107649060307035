import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Alert, Space, Tabs } from 'antd';
import Masonry from 'react-responsive-masonry';

import { useAsman } from '@bvt-features/sub-module/asman/hook/useAsman';
import { AsmanCardAssetDetailsComponent } from '@bvt-features/sub-module/asman/component/AsmanCardAssetDetails.component';
import { AsmanCardRadiusInfoComponent } from '@bvt-features/sub-module/asman/component/AsmanCardRadiusInfo.component/AsmanCardRadiusInfo.component';
import { AsmanCardGenderByAgeComponent } from '@bvt-features/sub-module/asman/component/AsmanCardGenderByAge.component/AsmanCardGenderByAge.component';
import { AsmanCardEducationComponent } from '@bvt-features/sub-module/asman/component/AsmanCardEducation.component/AsmanCardEducation.component';
import { AsmanCardDemographyCathmentComponent } from '@bvt-features/sub-module/asman/component/AsmanCardDemographyCathment.component/AsmanCardDemographyCathment.component';
import { AsmanCardSESComponent } from '@bvt-features/sub-module/asman/component/AsmanCardSES.component/AsmanCardSES.component';
import { AsmanCardZoningComponent } from '@bvt-features/sub-module/asman/component/AsmanCardZoning.component/AsmanCardZoning.component';
import { AsmanCardProfessionChartComponent } from '@bvt-features/sub-module/asman/component/AsmanCardProfessionChart.component/AsmanCardProfessionChart.component';
import { AsmanCardLandValueComponent } from '@bvt-features/sub-module/asman/component/AsmanCardLandValue.component/AsmanCardLandValue.component';
import { AsmanCardTopPoiGetComponent } from '@bvt-features/sub-module/asman/component/AsmanCardTopPoiGet.component/AsmanCardTopPoiGet.component';
import { AsmanCardBrandByIndustryComponent } from '@bvt-features/sub-module/asman/component/AsmanCardBrandByIndustry.component/AsmanCardBrandByIndustry.component';
import { AsmanCardPropertyPriceComponent } from '@bvt-features/sub-module/asman/component/AsmanCardPropertyPrice.component';
import { AsmanMapViewComponent } from '@bvt-features/sub-module/asman/component/AsmanMapView.component';
import { SharedPrintableComponent } from '@bvt-shared/component/SharedPrintable.component';

import {
  isLoading,
  mapAssetDetails,
  mapRadiusInfo,
  mapGenderInfo,
  mapEducationInfo,
  mapSESInfo,
  mapZoningInfo,
  mapProfessionInfo,
  mapTopPoiInfo,
  mapBrandByIndustryInfo
} from '@bvt-features/sub-module/asman/utils/dataMappers';

import TELKOM_LOGO from '@bvt-assets/images/telkomlogo.png';

export const AsmanPDFContainer = () => {
  const nav = useNavigate();
  const [loader, setLoader] = useState({ total: 0, isGenerating: false });
  const { gsid } = useParams();

  const {
    getDashboardAvaliableRadius,
    getDashboardResultByRadiusAndSet,
    getDashboardPOICollection,
    resetDashboard,
    state: {
      status_DASHBOARD_RADIUS_LIST,
      status_DASHBOARD_RESULT,
      message,
      description,
      data: { dashboard }
    }
  } = useAsman();

  const pdfRef = useRef(null);

  const internalHandleDashboardRadiusChange = v => {
    getDashboardResultByRadiusAndSet({ gsid: gsid, radius: v });
    getDashboardPOICollection({ gsid: gsid, radius: v });
  };

  useEffect(() => {
    getDashboardAvaliableRadius({ gsid: gsid });

    return () => {
      resetDashboard();
    };
  }, []);

  useEffect(() => {
    if (status_DASHBOARD_RADIUS_LIST === 'SUCCESS') {
      getDashboardResultByRadiusAndSet({
        gsid: gsid,
        radius: dashboard.selectedRadius
      });
      getDashboardPOICollection({
        gsid: gsid,
        radius: dashboard.selectedRadius
      });
    }
  }, [status_DASHBOARD_RADIUS_LIST]);

  const ALL_LOADING = isLoading(status_DASHBOARD_RADIUS_LIST, status_DASHBOARD_RESULT);
  const DATA_ASSETDETAIL_MAPPER = mapAssetDetails(dashboard.assetDetail);
  const DATA_CARDRADIUSINFO_MAPPER = mapRadiusInfo(dashboard.radiusInfo);
  const DATA_GENDERBYAGE_MAPPER = mapGenderInfo(dashboard.genderInfo);
  const DATA_EDUCATION_MAPPER = mapEducationInfo(dashboard.education);
  const DATA_DEMOGRAPHYCATCHMENT_MAPPER = {
    female: dashboard.demographyCatchementInfo?.female || 0,
    male: dashboard.demographyCatchementInfo?.male || 0,
    source: 'Dukcapil',
  };
  const DATA_SES_MAPPER = mapSESInfo(dashboard.sesInfo);
  const DATA_ZONING_MAPPER = mapZoningInfo(dashboard.zoningInfo);
  const DATA_PROFFESION_MAPPER = mapProfessionInfo(dashboard.professionInfo);
  const DATA_LANDVALUE_MAPPER = {
    current: dashboard.landValueInfo?.current || 0,
    previous: dashboard.landValueInfo?.previous || 0,
    isLoading: ALL_LOADING,
  };
  const DATA_TOPPOI_MAPPER = mapTopPoiInfo(dashboard.topPoiInfo);
  const DATA_POIBRANDINDUSTRY_MAPPER = mapBrandByIndustryInfo(dashboard.brandByIndustryInfo);

  const makeElementRatioTwo = (jsPDFInstance, canvas) => {
    try {
      const imgData = canvas.toDataURL('image/png');
      const imgProps = jsPDFInstance.getImageProperties(imgData);
      const pdfWidth = jsPDFInstance.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      return {
        pdfWidth,
        pdfHeight
      };
    } catch (_err) {
      return {
        pdfWidth: 0,
        pdfHeight: 0
      };
    }
  };

  const exportPdf = async type => {
    setLoader({ ...loader, isGenerating: true });
    const jspdfInstance = new JsPDF('p', 'px', 'a4', true);
    let currentPage = 1;

    // DRAW HEADER
    const canvasHeaderElm = await html2canvas(
      document.querySelector('.SharedPrintableComponent__header')
    );
    const canvasHeaderRat = makeElementRatioTwo(jspdfInstance, canvasHeaderElm);

    const headerMargin = 16;
    const headerHeight = canvasHeaderRat.pdfHeight + headerMargin;

    jspdfInstance.addImage(
      canvasHeaderElm,
      'PNG',
      0,
      0,
      canvasHeaderRat.pdfWidth,
      canvasHeaderRat.pdfHeight
    );

    // DRAW CANVAS
    const arrOfElm = document.querySelectorAll(
      '.SharedBaseCardTypeOneComponent__wrapper'
    );
    const arrOfCanvas = [];

    const elementSpacing = 18;

    for (const element of arrOfElm) {
      const elm = element;
      const canvas = await html2canvas(elm, {
        imageTimeout: 0,
        allowTaint: true,
        useCORS: true
      });
      const cntCurrent = makeElementRatioTwo(jspdfInstance, canvas);
      const imageWidth = cntCurrent.pdfWidth - 48;
      const imageHeight = cntCurrent.pdfHeight;

      arrOfCanvas.push({ canvas, imageWidth, imageHeight, elm });
    }

    const pageWidth = jspdfInstance.internal.pageSize.getWidth();
    const pageHeight = jspdfInstance.internal.pageSize.getHeight();
    const marginLeft = 24;
    const marginTop = 24;

    let currentX = marginLeft;
    let currentY = headerHeight;
    let remainingHeight = pageHeight - currentY - elementSpacing;

    for (const element of arrOfCanvas) {
      const { canvas, imageWidth, imageHeight } = element;

      const availableWidth = pageWidth - marginLeft - currentX;

      const pageRatio = imageWidth / imageHeight;
      const targetWidth = Math.min(imageWidth, availableWidth);
      const targetHeight = targetWidth / pageRatio;

      if (currentY + targetHeight + elementSpacing > pageHeight) {
        jspdfInstance.addPage();
        currentPage = currentPage + 1;
        currentX = marginLeft;
        currentY = marginTop;
        remainingHeight = pageHeight - currentY - elementSpacing;
      }

      if (targetHeight + elementSpacing > pageHeight) {
        jspdfInstance.addImage(
          canvas.toDataURL('image/jpeg', 1.0),
          'JPEG',
          currentX,
          currentY,
          targetWidth,
          targetHeight - 128
        );
      } else {
        jspdfInstance.addImage(
          canvas.toDataURL('image/jpeg', 1.0),
          'JPEG',
          currentX,
          currentY,
          targetWidth,
          targetHeight
        );
      }

      currentY += targetHeight + elementSpacing;
      remainingHeight = remainingHeight - targetHeight + elementSpacing;
    }

    if (type === 'download') {
      jspdfInstance
        .save('asset-management-result.pdf', { returnPromise: true })
        .then(() => {
          setLoader({ ...loader, isGenerating: false });
        });
    } else if (type === 'print') {
      jspdfInstance.autoPrint();
      const blob = jspdfInstance.output('bloburl');
      window.open(blob, '_blank');
      setLoader({ ...loader, isGenerating: false });
    }
    else if (type === 'saveAs') {
      const blob = jspdfInstance.output('bloburl');
      window.open(blob, '_blank');
      setLoader({ ...loader, isGenerating: false });
    }
  };

  return (
    <SharedPrintableComponent
      isLoading={ALL_LOADING}
      onBack={() => nav(`/sub-module/dashboard/asset-management/${dashboard.gsid}`)}
      onDownload={() => exportPdf('download')}
      onPrint={() => exportPdf('print')}
      onSave={() => exportPdf('saveAs')}
      ref={pdfRef}
      title={'Dashboard Result'}
      whiteLabelImage={TELKOM_LOGO}
    >
      <Tabs
        activeKey={String(dashboard.selectedRadius)}
        items={dashboard.radiusList?.map(v => ({
          label: `${String(v)} m`,
          key: String(v),
          disabled: ALL_LOADING
        }))}
        onChange={v => internalHandleDashboardRadiusChange(parseInt(v))}
        tabBarGutter={7}
        type='card'
      />
      {status_DASHBOARD_RADIUS_LIST === 'FAILED' ||
      status_DASHBOARD_RESULT === 'FAILED' ? (
          <Alert description={description} message={message} type='error' />
        ) : (
          <React.Fragment>
            {loader.isGenerating && (
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  background: 'rgba(0, 0, 0, 0.5)',
                  zIndex: 9999,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#fff'
                }}
              >
                <Space align='center' direction='vertical'>
                  <div>Generating PDF Please Wait this take time...</div>
                  <small>Average 2 - 3 Minute When generating pdf</small>
                </Space>
              </div>
            )}
            <Masonry columnsCount={1} gutter='0.75rem'>
              <AsmanCardAssetDetailsComponent
                data={DATA_ASSETDETAIL_MAPPER}
                isLoading={ALL_LOADING}
              />

              <AsmanMapViewComponent
                GEOJSONBuffer={dashboard.geojson.radius}
                GEOJSONCenterAsset={{
                  type: 'FeatureCollection',
                  features: [
                    {
                      type: 'Feature',
                      geometry: {
                        type: 'Point',
                        coordinates: [
                          dashboard.assetDetail?.longitude || 0,
                          dashboard.assetDetail?.latitude || 0
                        ]
                      },
                      properties: {
                        ...dashboard.assetDetail,
                        image: dashboard.assetDetail?.image,
                        area: `${dashboard.assetDetail?.areaWidth} m2`
                      }
                    }
                  ]
                }}
                GEOJSONPOICollection={dashboard.geojson.poi}
                isLoading={ALL_LOADING}
              />

              <AsmanCardRadiusInfoComponent
                data={DATA_CARDRADIUSINFO_MAPPER}
                isLoading={ALL_LOADING}
              />

              <AsmanCardTopPoiGetComponent
                data={DATA_TOPPOI_MAPPER}
                isLoading={ALL_LOADING}
              />

              <AsmanCardBrandByIndustryComponent
                data={DATA_POIBRANDINDUSTRY_MAPPER}
                isLoading={ALL_LOADING}
              />

              <AsmanCardProfessionChartComponent
                data={DATA_PROFFESION_MAPPER}
                isLoading={ALL_LOADING}
              />

              <AsmanCardEducationComponent
                data={DATA_EDUCATION_MAPPER}
                isLoading={ALL_LOADING}
              />

              <AsmanCardGenderByAgeComponent
                data={DATA_GENDERBYAGE_MAPPER}
                isLoading={ALL_LOADING}
              />

              <AsmanCardDemographyCathmentComponent
                data={DATA_DEMOGRAPHYCATCHMENT_MAPPER}
                isLoading={ALL_LOADING}
              />

              <AsmanCardSESComponent
                data={DATA_SES_MAPPER}
                isLoading={ALL_LOADING}
              />

              <AsmanCardPropertyPriceComponent
                data={dashboard.propertyPriceInfo}
                isLoading={ALL_LOADING}
              />

              <AsmanCardLandValueComponent {...DATA_LANDVALUE_MAPPER} />

              <AsmanCardZoningComponent
                data={DATA_ZONING_MAPPER}
                isLoading={ALL_LOADING}
              />
            </Masonry>
          </React.Fragment>
        )}
    </SharedPrintableComponent>
  );
};
