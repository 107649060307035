import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { APP_STORE_PREFIX_CONSTANT } from '@bvt-shared/constant/APP_PREFIX_CONSTANT';
import { ADVANCE_SEARCH_STORE_CONSTANT } from '../constant/ADVANCE_SEARCH_CONSTANT';
import { AdvanceSearchService } from '../service/advanceSearchApi.datasource.js';

const ads = new AdvanceSearchService();
export const advanceSearchAsyncActionGetProvince = createAsyncThunk(
  APP_STORE_PREFIX_CONSTANT +
    ADVANCE_SEARCH_STORE_CONSTANT +
    '/advanceSearchAsyncActionGetProvince',
  async (arg) => {
    const resp = await ads.getProvince(arg);
    return {
      ...resp,
      data: resp.results.map((v) => ({
        value: v.id_provinsi,
        label: v.nama_provinsi,
      })),
    };
  }
);

export const advanceSearchAsyncActionGetRegency = createAsyncThunk(
  APP_STORE_PREFIX_CONSTANT +
    ADVANCE_SEARCH_STORE_CONSTANT +
    '/advanceSearchAsyncActionGetRegency',
  async (arg) => {
    const resp = await ads.getRegency(arg);
    return {
      ...resp,
      data: resp.results.map((v) => ({ value: v.id_kota, label: v.nama_kota })),
    };
  }
);

export const advanceSearchAsyncActionDistrict = createAsyncThunk(
  APP_STORE_PREFIX_CONSTANT +
    ADVANCE_SEARCH_STORE_CONSTANT +
    '/advanceSearchAsyncActionDistrict',
  async (arg) => {
    const resp = await ads.getDistrict(arg);
    return {
      ...resp,
      data: resp.results.map((v) => ({
        label: v.nama_kecamatan,
        value: v.id_kecamatan,
      })),
    };
  }
);

export const advanceSearchAsyncActionVillage = createAsyncThunk(
  APP_STORE_PREFIX_CONSTANT +
    ADVANCE_SEARCH_STORE_CONSTANT +
    '/advanceSearchAsyncActionVillage',
  async (arg) => {
    const resp = await ads.getVillage(arg);
    return {
      ...resp,
      data: resp.results.map((v) => ({ value: v.id_desa, label: v.nama_desa })),
    };
  }
);

export const advanceSearchAsyncActionGeoJson = createAsyncThunk(
  APP_STORE_PREFIX_CONSTANT +
    ADVANCE_SEARCH_STORE_CONSTANT +
    '/advanceSearchAsyncActionGeoJson',
  async (arg) => {
    const resp = await ads.getGeoJson(arg);

    const forgeResponse = {
      type: 'FeatureCollection',
      features: [],
    };

    if (resp.result.polygon_provinsi?.type === 'Feature') {
      forgeResponse.features.push(
        _.merge(resp.result.polygon_provinsi, {
          properties: { level: 'PROVINCE' },
        })
      );
    }
    if (resp.result.polygon_kota?.type === 'Feature') {
      forgeResponse.features.push(
        _.merge(resp.result.polygon_kota, { properties: { level: 'REGENCY' } })
      );
    }
    if (resp.result.polygon_kecamatan?.type === 'Feature') {
      forgeResponse.features.push(
        _.merge(resp.result.polygon_kecamatan, {
          properties: { level: 'DISTRICT' },
        })
      );
    }
    if (resp.result.polygon_desa?.type === 'Feature') {
      forgeResponse.features.push(
        _.merge(resp.result.polygon_desa, { properties: { level: 'VILLAGE' } })
      );
    }
    return {
      ...resp,
      data: forgeResponse,
    };
  }
);

export const advanceSearchAsyncActionSearch = createAsyncThunk(
  APP_STORE_PREFIX_CONSTANT +
    ADVANCE_SEARCH_STORE_CONSTANT +
    '/advanceSearchAsyncActionSearch',
  async (arg) => {
    const resp = await ads.getAll(arg);
    return {
      ...resp,
      data: resp.results.map((v) => {
        let mapedType;
        switch (v.type) {
          case 'provinsi':
            mapedType = 'PROVINCE';
            break;
          case 'kota':
            mapedType = 'REGENCY';
            break;
          case 'kecamatan':
            mapedType = 'DISTRICT';
            break;
          case 'desa':
            mapedType = 'VILLAGE';
            break;
          default:
            mapedType = 'PROVINCE';
            break;
        }
        return { label: v.displayname, value: v.id, type: mapedType };
      }),
    };
  }
);
