import React, { useMemo } from 'react';
import moment from 'moment';

import { BaseCard } from '../BaseCard';
import { QuestionCircleFilled } from '@ant-design/icons';
import { Chart } from './Chart';
import { Col, Row } from 'antd';

import './CardMobileData.scss';

/**
 * @typedef {object} IEvent
 * @property {{time: number, avgPeopleDensity: number, totalPeopleDensity: number}} summary
 * @property {{hour: number | undefined, time: string, counts_event: number}[]} timeSeries
 */

/**
 * @typedef {object} IUnique
 * @property {{time: number, avgPeopleDensity: number, totalPeopleDensity: number}} summary
 * @property {{hour: number | undefined, time: string, counts: number}[]} timeSeries
 */

/**
 * @param {object} props
 * @param {'Event'|'Unique'} props.type
 * @param {string} props.total
 * @param {string} props.average
 * @param {string} props.color
 */
const CardSummary = (props) => {
  const style = { backgroundColor: props?.color };

  return (
    <div className='SharedCardMobileData-summary'>
      <div className='SharedCardMobileData-summary-card' style={style}>
        <div className='text'>Total {props?.type} Device(s)</div>
        <div className='text'>{props?.total} Devices</div>
      </div>
      <div className='SharedCardMobileData-summary-card' style={style}>
        <div className='text'>Average {props?.type} Device(s)</div>
        <div className='text'>{props.average} Devices</div>
      </div>
    </div>
  );
};

/**
 * @param {object} props
 * @param {string} props.title
 * @param {boolean} props.isLoading
 * @param {object} props.style
 * @param {{event: IEvent, unique: IUnique}} props.data
 * @param {{main: string, secondary: string}} props.colorUnique
 * @param {{main: string, secondary: string}} props.colorEvent
 * @param {'daily'|'monthly'|'yearly'} props.periodic
 */
export const CardMobileData = (props) => {
  const {
    style,
    isLoading,
    title,
    periodic,
    colorUnique,
    colorEvent,
    data
  } = props;

  const getChartType = () => {
    const chartTypes = {
      daily: { chart: 'hourly', type: 'Hour', format: 'dddd DD/MM/YYYY' },
      monthly: { chart: 'daily', type: 'Day', format: 'MMMM YYYY' },
      yearly: { chart: 'monthly', type: 'Month', format: 'YYYY' },
    };
    return chartTypes[periodic] || { chart: '', type: '' };
  };

  const chartType = useMemo(() => getChartType(), [periodic]);
  const eventTime = data?.event?.summary?.time;
  const uniqueTime = data?.unique?.summary?.time;
  const momentEvent = moment(eventTime?.toString());
  const momentUnique = moment(uniqueTime?.toString());

  return (
    <div className='SharedCardMobileData' style={style}>
      <BaseCard className='CardMobileData' isLoading={isLoading} title={title} withPadding={true}>
        <div className='SharedCardMobileData-wrapper'>
          <Row gutter={16}>
            <Col lg={12} md={24} sm={24} xl={12} xs={24}>
              <CardSummary
                average={(data?.event?.summary?.avgPeopleDensity || 100_000_000)?.toLocaleString('id-ID')}
                color={colorEvent.main}
                total={(data?.event?.summary?.totalPeopleDensity || '')?.toLocaleString('id-ID')}
                type='Event'
              />
            </Col>
            <Col lg={12} md={24} sm={24} xl={12} xs={24}>
              <CardSummary
                average={(data?.unique?.summary?.avgPeopleDensity || '')?.toLocaleString('id-ID')}
                color={colorUnique.main}
                total={(data?.unique?.summary?.totalPeopleDensity || '')?.toLocaleString('id-ID')}
                type='Unique'
              />
            </Col>
          </Row>
        </div>
      </BaseCard>
      <div className='shared BaseCard chart'>
        <div className='SharedCardMobileData-chart-wrapper'>
          <div className='SharedCardMobileData-title-wrapper'>
            <div className='SharedCardMobileData-title'>Trend Duration per {chartType.type}</div>
            <QuestionCircleFilled className='SharedCardMobileData-title-btn' />
          </div>
          <div className='SharedCardMobileData-chart'>
            <div className='SharedCardMobileData-chart-title'>
              {(momentEvent.isValid()
                ? momentEvent : moment(eventTime)).format(chartType.format)}
            </div>
            <div className='SharedCardMobileData-chart-data'>
              <Chart
                color={colorEvent}
                data={data?.event?.timeSeries}
                type={chartType.chart}
              />
            </div>
          </div>
        </div>
        <div className='SharedCardMobileData-chart-wrapper'>
          <div className='SharedCardMobileData-title-wrapper'>
            <div className='SharedCardMobileData-title'>Trend Activity per {chartType.type}</div>
            <QuestionCircleFilled className='SharedCardMobileData-title-btn' />
          </div>
          <div className='SharedCardMobileData-chart'>
            <div className='SharedCardMobileData-chart-title'>
              {(momentUnique.isValid()
                ? momentUnique : moment(uniqueTime)).format(chartType.format)}
            </div>
            <div className='SharedCardMobileData-chart-data'>
              <Chart
                color={colorUnique}
                data={data?.unique?.timeSeries}
                type={chartType.chart}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
