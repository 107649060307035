import React from 'react';
import { MainMapMapPickerBaseMapListItem } from '../MainMapMapPickerBaseMapListItem';
import './MainMapMapPickerBaseMapList.scss';
/**
 * @author Randa<m.randa@bvarta.com>
 * @param {object} props
 * @param {Array<{key:string,value:string,title:string,image:string}>} props.data
 * @param {string} props.activeKey
 * @param {(activeKey:string)=>void} props.onClick
 * @param {()=>void} props.onMouseLeave
 * @param {()=>void} props.onMouseEnter
 */
export const MainMapMapPickerBaseMapList = (props) => {
  const { data, activeKey, onClick, onMouseEnter, onMouseLeave } = props;
  return (
    <div
      className='feature-mainmap MainMapMapPickerBaseMapList'
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {data.map((v) => (
        <MainMapMapPickerBaseMapListItem
          image={v.image}
          isActive={activeKey === v.key}
          key={v.key}
          onClick={() => onClick(v.key)}
          title={v.title}
        />
      ))}
    </div>
  );
};
