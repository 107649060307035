import { Button, Svg } from '@bvt-atoms';
import React from 'react';
import { CardMoreInformation } from '../CardMoreInformation';
import './CardDatexThematicDataDetail.scss';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta <renta.yustika@bvarta.com>
 * @param {Object} props
 * @param {Array<{key:string,value:string}>} props.data
 */

export const CardDatexThematicDataDetail = (props) => {
  const { data } = props;
  return (
    <div className='CardDatexThematicDataDetail__container'>
      <Button className='CardDatexThematicDataDetail__btn-summary'>
        <Svg
          className='CardDatexThematicDataDetail__btn-summary-img'
          src='ic-info'
        />
        <span className='CardDatexThematicDataDetail__btn-summary-text'>
          Layer Summary
        </span>
      </Button>

      <CardMoreInformation
        data={data}
      />
    </div>
  );
};
