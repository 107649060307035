import Resource from '../../../../../api/resource';

export class HistoryLogActiviesService extends Resource {
  constructor() {
    super('', '/api', 'api-poi', 'v2');
  }
  doGetList(param) {
    const parsed = JSON.stringify(param);

    this.setAdditionUri(
      'activity-history?params=' + new URLSearchParams(parsed).toString()
    );
    return this.get();
  }
}
