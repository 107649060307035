import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import Masonry from 'react-responsive-masonry';
import { AsmanMapViewComponent } from '@bvt-features/sub-module/asman/component/AsmanMapView.component';
import { useNavigate, useParams } from 'react-router';

import { AsmanCardAssetDetailsComponent } from '@bvt-features/sub-module/asman/component/AsmanCardAssetDetails.component';
import { AsmanCardEducationComponent } from '@bvt-features/sub-module/asman/component/AsmanCardEducation.component';
import { AsmanCardRadiusInfoComponent } from '@bvt-features/sub-module/asman/component/AsmanCardRadiusInfo.component';
import { AsmanCardGenderByAgeComponent } from '@bvt-features/sub-module/asman/component/AsmanCardGenderByAge.component';
import { AsmanCardTopPoiGetComponent } from '@bvt-features/sub-module/asman/component/AsmanCardTopPoiGet.component';
import { AsmanCardDemographyCathmentComponent } from '@bvt-features/sub-module/asman/component/AsmanCardDemographyCathment.component';
import { AsmanCardLandValueComponent } from '@bvt-features/sub-module/asman/component/AsmanCardLandValue.component';
import { AsmanCardSESComponent } from '@bvt-features/sub-module/asman/component/AsmanCardSES.component';
import { AsmanCardPropertyPriceComponent } from '@bvt-features/sub-module/asman/component/AsmanCardPropertyPrice.component/AsmanCardPropertyPrice.component';
import { AsmanCardBrandByIndustryComponent } from '@bvt-features/sub-module/asman/component/AsmanCardBrandByIndustry.component';
import { AsmanCardProfessionChartComponent } from '@bvt-features/sub-module/asman/component/AsmanCardProfessionChart.component';
import { useAsman } from '@bvt-features/sub-module/asman/hook/useAsman';
import { AsmanCardAnalysisResultErrorComponent } from '@bvt-features/sub-module/asman/component/AsmanCardAnalysisResultErrorComponent';
import { AsmanCardAnalysisResultLoadingComponent } from '@bvt-features/sub-module/asman/component/AsmanCardAnalysisResultLoadingComponent/AsmanCardAnalysisResultLoadingComponent';
import { AsmanCardZoningComponent } from '../../component/AsmanCardZoning.component/AsmanCardZoning.component';

import {
  isLoading,
  mapAssetDetails,
  mapRadiusInfo,
  mapGenderInfo,
  mapEducationInfo,
  mapSESInfo,
  mapZoningInfo,
  mapProfessionInfo,
  mapTopPoiInfo,
  mapBrandByIndustryInfo
} from '@bvt-features/sub-module/asman/utils/dataMappers';

export const AsmanDashboardBodyContainer = () => {
  const { gsid } = useParams();
  const navigate = useNavigate();

  const {
    getDashboardAvaliableRadius,
    getDashboardResultByRadiusAndSet,
    getDashboardPOICollection,
    resetDashboard,
    state: {
      status_DASHBOARD_RADIUS_LIST,
      status_DASHBOARD_RESULT,
      STATUS_DASHBOARD_POI_COLLECTION,
      message,
      description,
      data: { dashboard }
    }
  } = useAsman();

  const internalHandleDashboardRadiusChange = v => {
    getDashboardResultByRadiusAndSet({ gsid: gsid, radius: v });
    getDashboardPOICollection({ gsid: gsid, radius: v });
  };

  useEffect(() => {
    getDashboardAvaliableRadius({ gsid: gsid });

    return () => {
      resetDashboard();
    };
  }, []);

  useEffect(() => {
    if (status_DASHBOARD_RADIUS_LIST === 'SUCCESS') {
      getDashboardResultByRadiusAndSet({
        gsid: gsid,
        radius: dashboard.selectedRadius
      });
      getDashboardPOICollection({
        gsid: gsid,
        radius: dashboard.selectedRadius
      });
    }
  }, [status_DASHBOARD_RADIUS_LIST]);

  const ALL_LOADING = isLoading(status_DASHBOARD_RADIUS_LIST, status_DASHBOARD_RESULT);
  const DATA_ASSETDETAIL_MAPPER = mapAssetDetails(dashboard.assetDetail);
  const DATA_CARDRADIUSINFO_MAPPER = mapRadiusInfo(dashboard.radiusInfo);
  const DATA_GENDERBYAGE_MAPPER = mapGenderInfo(dashboard.genderInfo);
  const DATA_EDUCATION_MAPPER = mapEducationInfo(dashboard.education);
  const DATA_DEMOGRAPHYCATCHMENT_MAPPER = {
    female: dashboard.demographyCatchementInfo?.female || 0,
    male: dashboard.demographyCatchementInfo?.male || 0,
    source: 'Dukcapil',
  };
  const DATA_SES_MAPPER = mapSESInfo(dashboard.sesInfo);
  const DATA_ZONING_MAPPER = mapZoningInfo(dashboard.zoningInfo);
  const DATA_PROFFESION_MAPPER = mapProfessionInfo(dashboard.professionInfo);
  const DATA_LANDVALUE_MAPPER = {
    current: dashboard.landValueInfo?.current || 0,
    previous: dashboard.landValueInfo?.previous || 0,
    isLoading: ALL_LOADING,
  };
  const DATA_TOPPOI_MAPPER = mapTopPoiInfo(dashboard.topPoiInfo);
  const DATA_POIBRANDINDUSTRY_MAPPER = mapBrandByIndustryInfo(dashboard.brandByIndustryInfo);

  return (
    <React.Fragment>
      <Tabs
        activeKey={String(dashboard.selectedRadius)}
        items={dashboard.radiusList?.map(v => ({
          label: `${String(v)} m`,
          key: String(v),
          disabled: ALL_LOADING || STATUS_DASHBOARD_POI_COLLECTION === 'LOADING'
        }))}
        onChange={v => internalHandleDashboardRadiusChange(parseInt(v))}
        tabBarGutter={7}
        type='card'
      />
      {status_DASHBOARD_RADIUS_LIST === 'FAILED' && (
        <AsmanCardAnalysisResultLoadingComponent
          onClick={() => navigate('/sub-module/map/asset-management')}
        />
      )}
      {status_DASHBOARD_RESULT === 'FAILED' && (
        <AsmanCardAnalysisResultErrorComponent
          message={`'${message},${description}'`}
        />
      )}

      {status_DASHBOARD_RADIUS_LIST !== 'FAILED' &&
        status_DASHBOARD_RESULT !== 'FAILED' && (
        <Masonry columnsCount={2} gutter='0.75rem'>
          <AsmanCardAssetDetailsComponent
            data={DATA_ASSETDETAIL_MAPPER}
            isLoading={ALL_LOADING}
          />

          <AsmanMapViewComponent
            GEOJSONBuffer={dashboard.geojson.radius}
            GEOJSONCenterAsset={{
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [
                      dashboard.assetDetail?.longitude || 0,
                      dashboard.assetDetail?.latitude || 0
                    ]
                  },
                  properties: {
                    ...dashboard.assetDetail,
                    image: dashboard.assetDetail?.image,
                    area: `${dashboard.assetDetail?.areaWidth} m2`
                  }
                }
              ]
            }}
            GEOJSONPOICollection={dashboard.geojson.poi}
            isLoading={
              ALL_LOADING || STATUS_DASHBOARD_POI_COLLECTION === 'LOADING'
            }
          />

          <AsmanCardRadiusInfoComponent
            data={DATA_CARDRADIUSINFO_MAPPER}
            isLoading={ALL_LOADING}
          />

          <AsmanCardEducationComponent
            data={DATA_EDUCATION_MAPPER}
            isLoading={ALL_LOADING}
          />

          <AsmanCardTopPoiGetComponent
            data={DATA_TOPPOI_MAPPER}
            isLoading={ALL_LOADING}
          />

          <AsmanCardGenderByAgeComponent
            data={DATA_GENDERBYAGE_MAPPER}
            isLoading={ALL_LOADING}
          />

          <AsmanCardBrandByIndustryComponent
            data={DATA_POIBRANDINDUSTRY_MAPPER}
            isLoading={ALL_LOADING}
          />

          <AsmanCardDemographyCathmentComponent
            data={DATA_DEMOGRAPHYCATCHMENT_MAPPER}
            isLoading={ALL_LOADING}
          />

          <AsmanCardProfessionChartComponent
            data={DATA_PROFFESION_MAPPER}
            isLoading={ALL_LOADING}
          />

          <AsmanCardSESComponent
            data={DATA_SES_MAPPER}
            isLoading={ALL_LOADING}
          />

          <></>
          <AsmanCardPropertyPriceComponent
            data={dashboard.propertyPriceInfo}
            isLoading={ALL_LOADING}
          />

          <></>
          <AsmanCardLandValueComponent {...DATA_LANDVALUE_MAPPER} />

          <></>
          <AsmanCardZoningComponent
            data={DATA_ZONING_MAPPER}
            isLoading={ALL_LOADING}
          />
        </Masonry>
      )}
    </React.Fragment>
  );
};
