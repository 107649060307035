import PropTypes from 'prop-types';

const IconType = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

const IconTypeDefault = {
  width: '',
  height: '',
  className: '',
};

export { IconType, IconTypeDefault };
