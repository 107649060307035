/* eslint-disable consistent-return */
import { GeoJsonDatexMap } from '@bvt-features/datex/component/GeoJsonDatexMap';
import { useDatex } from '@bvt-features/datex/hooks';
import { useMainMap } from '@bvt-features/mainmap/mainmap/hook/useMainMap';
import { useMap } from 'react-leaflet';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { GeoJsonDatexPOIMap } from '@bvt-features/datex/component/GeoJsonDatexPOIMap';
import { GeoJsonAdvanceMap } from '@bvt-features/datex/component/GeoJsonAdvanceMap/GeoJsonAdvanceMap';
import { GeoJsonDatexThematicMap } from '@bvt-features/datex/component/GeoJsonDatexThematicMap';
/**
 * @author randa <m.randa@bvarta.com>
 * @description Geojson container for datex telco data flow and grid map
 */
export const GeoJsonDatexContainer = () => {
  const datex = useDatex();
  const mainmap = useMainMap();
  const mapInstance = useMap();

  return (
    <React.Fragment>
      {datex.state.dataLayerList.map((v) => {
        const layervisibility = _.find(
          mainmap.state?.layerVisibilityList,
          (item) => item?.key === `DATEX_${v?.id}`
        );
        if (
          v?.type === 'TELCO' &&
          v?.status_ANALYZE === 'SUCCESS' &&
          layervisibility?.isActive
        ) {
          return (
            <Fragment key={v.id}>
              <GeoJsonDatexMap
                flowMap={v?.result?.flow?.flow_line?.features || []}
                flowPoint={v?.result?.flow?.flow_point?.features || []}
                grid={v?.result?.grid?.grid_density?.features || []}
                key={v.id}
                layerIndex={v.id}
                MapInstance={mapInstance}
                opacity={layervisibility?.opacity}
              />
              <GeoJsonAdvanceMap
                polygon_desa={v?.advanceMap?.polygon_desa}
                polygon_kecamatan={v?.advanceMap?.polygon_kecamatan}
                polygon_kota={v?.advanceMap?.polygon_kota}
                polygon_provinsi={v?.advanceMap?.polygon_provinsi}
              />
            </Fragment>
          );
        } else if (
          (v?.type === 'POI' || v?.type === 'POI_RINJANI') &&
          v?.status_ANALYZE === 'SUCCESS' &&
          layervisibility?.isActive
        ) {
          return (
            <Fragment key={v.id}>
              <GeoJsonDatexPOIMap
                data={v?.result?.poi?.features?.map((el) => {
                  return {
                    position: [el.properties.latitude, el.properties.longitude],
                    icon: el?.properties?.category_icon,
                    popupContent: {
                      key: null,
                      ref: null,
                      props: {
                        properties: el.properties,
                      },
                    },
                  };
                })}
                opacity={layervisibility?.opacity}
              />
              <GeoJsonAdvanceMap
                polygon_desa={v?.advanceMap?.polygon_desa}
                polygon_kecamatan={v?.advanceMap?.polygon_kecamatan}
                polygon_kota={v?.advanceMap?.polygon_kota}
                polygon_provinsi={v?.advanceMap?.polygon_provinsi}
              />
            </Fragment>
          );
        } else if (
          ['THEMATIC', 'THEMATIC_RINJANI'].includes(v?.type) &&
          v?.status_ANALYZE === 'SUCCESS' &&
          layervisibility?.isActive
        ) {
          return (
            <Fragment key={v.id}>
              <GeoJsonDatexThematicMap
                data={v.result}
                opacity={layervisibility?.opacity}
                thematicSelectedYearIndex={v?.thematicSelectedYearIndex}
              />
              <GeoJsonAdvanceMap
                polygon_desa={v?.advanceMap?.polygon_desa}
                polygon_kecamatan={v?.advanceMap?.polygon_kecamatan}
                polygon_kota={v?.advanceMap?.polygon_kota}
                polygon_provinsi={v?.advanceMap?.polygon_provinsi}
              />
            </Fragment>
          );
        }
      })}
      {!_.isEmpty(datex.state.geojson) && (
        <GeoJsonAdvanceMap
          polygon_desa={datex?.state?.geojson?.village && datex.state.geojson.village}
          polygon_kecamatan={
            !_.isEmpty(datex.state?.geojson?.village)
              ? null
              : datex.state.geojson.district
          }
          polygon_kota={!_.isEmpty(datex.state?.geojson?.district)
            ? null
            : datex.state.geojson.city}
          polygon_provinsi={
            !_.isEmpty(datex.state?.geojson?.city)
              ? null
              : datex.state.geojson.province
          }
        />
      )}
    </React.Fragment>
  );
};
