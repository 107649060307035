import React, { useEffect, useState } from 'react';
import { Collapse, Radio, Checkbox } from 'antd';
import { Skeleton } from '@bvt-shared/component/Skeleton';
import { getIcon } from '@bvt-shared/utils/iconUtils';


/**
 * @typedef Brand
 * @type {Object}
 * @property {number} id
 * @property {string} name
 */

/**
 * @typedef Category
 * @type {Object}
 * @property {number} id
 * @property {number} code
 * @property {string} name
 * @property {string} icon
 * @property {Brand[]} brands
 * @property {string} group_name
 * @property {string} industry_name
 */

/**
 * @typedef PayloadOnSelectBrands
 * @property {string} categoryId
 * @property {string[]} brandIds
 * @property {string[]} brandLabels
 * @property {string} groupName
 * @property {string} categoryIcon
 * @property {string} categoryName
 * @property {string} industryName
 */

/**
 * @param {object} props
 * @param {Category[]} props.data
 * @param {boolean} props.isLoading
 * @param {(payload: PayloadOnSelectBrands) => void} props.onSelectBrands
 * @param {(e: boolean) => void} props.setIsValid
 */

export default function DatexPoiSearchResult(props) {
  const { data, onSelectBrands, setIsValid } = props;
  const [categoryId, setCategoryId] = useState('');
  const [selected, setSelected] = useState({});

  function handleCheckboxChange(brandId, categoryId, checked) {
    setSelected((prevSelected) => {
      const updatedSelected = { ...prevSelected };

      if (!updatedSelected[categoryId]) {
        updatedSelected[categoryId] = [];
      }

      if (checked) {
        updatedSelected[categoryId].push(brandId);
      } else {
        const index = updatedSelected[categoryId].indexOf(brandId);
        if (index !== -1) {
          updatedSelected[categoryId].splice(index, 1);
        }
      }

      return updatedSelected;
    });
  }

  useEffect(() => {
    const brandIds = selected[categoryId] || [];
    const category = data.find((category) => category.id === categoryId);
    const categoryIcon = category?.icon;
    const categoryName = category?.name;
    const groupName = category?.group_name;
    const brands = category?.brands || [];
    const brandLabels = brands.filter((brand) => brandIds.includes(brand.id))
      .map((brand) => brand.name);
    const industryName = category?.industry_name;

    onSelectBrands && onSelectBrands({
      categoryId,
      brandIds,
      categoryIcon,
      categoryName,
      groupName,
      brandLabels,
      industryName,
    });

    if (brandIds.length === 0) {
      setIsValid && setIsValid(false);
    } else {
      setIsValid && setIsValid(true);
    }
  }, [categoryId, selected]);

  return (
    <div className='POISearchResult'>
      {
        data?.map((category) => (
          <Radio.Group className='' key={category.id} value={categoryId}>
            <Collapse className=''>
              <Collapse.Panel
                className=''
                header={
                  <div className='' onClick={(e) => e.stopPropagation()}>
                    <Radio className='' onChange={() => setCategoryId(category.id)} onClick={(e) => e.stopPropagation()} value={category.id}>
                      <Skeleton height='2rem' loading={!category?.icon} type='image'>
                        <img alt={category?.name} src={getIcon('poi', category?.icon)} />
                      </Skeleton>
                      <div>{category.name}</div>
                    </Radio>
                  </div>
                }
                key={category.id}
              >
                <div className='BrandList'>
                  {category.brands.map((brand) => (
                    <Checkbox
                      key={brand.id}
                      onChange={(e) => {
                        handleCheckboxChange(brand.id, category.id, e.target.checked);
                      }}
                      style={{ border: (categoryId === category.id && selected?.[category.id]?.includes(brand.id)) ? '1px solid #07827c' : 'none' }}
                      value={brand.id}
                    >
                      {brand.name}
                    </Checkbox>
                  ))}
                </div>
              </Collapse.Panel>
            </Collapse>
          </Radio.Group>
        ))
      }
    </div>
  );
}
