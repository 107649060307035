import { MAINMAP_PLACE_ACTIONS } from '@bvt-features/mainmap/place/redux/MAINMAP_PLACE_ACTION';
import { MAINMAP_PLACE_CONSTANT } from '@bvt-features/mainmap/place/redux/MAINMAP_PLACE_CONSTANT';
import { useDispatch, useSelector } from 'react-redux';

export function usePlace() {
  const state = useSelector((state) => state.features.MAINMAP.PLACE);
  const dispatch = useDispatch();

  const get_all = (param) => {
    dispatch(MAINMAP_PLACE_ACTIONS.get_all(param));
  };
  const get_country = (param) => {
    dispatch(MAINMAP_PLACE_ACTIONS.get_country(param));
  };
  const get_province_inter = (param) => {
    dispatch(MAINMAP_PLACE_ACTIONS.get_province_inter(param));
  };
  const get_city_inter = (param) => {
    dispatch(MAINMAP_PLACE_ACTIONS.get_city_inter(param));
  };
  const get_district_inter = (param) => {
    dispatch(MAINMAP_PLACE_ACTIONS.get_district_inter(param));
  };
  const get_province = (param) => {
    dispatch(MAINMAP_PLACE_ACTIONS.get_province(param));
  };
  const get_city = (param) => {
    dispatch(MAINMAP_PLACE_ACTIONS.get_city(param));
  };
  const get_district = (param) => {
    dispatch(MAINMAP_PLACE_ACTIONS.get_district(param));
  };
  const get_village = (param) => {
    dispatch(MAINMAP_PLACE_ACTIONS.get_village(param));
  };
  const get_advance = (param) => {
    dispatch(MAINMAP_PLACE_ACTIONS.get_advance(param));
  };
  const get_advance_inter = (param) => {
    dispatch(MAINMAP_PLACE_ACTIONS.get_advance_inter(param));
  };
  const get_coordinates = (param) => {
    dispatch(MAINMAP_PLACE_ACTIONS.get_coordinates(param));
  };
  const get_search = (param) => {
    dispatch(MAINMAP_PLACE_ACTIONS.get_search(param));
  };
  const resetAll = () => {
    dispatch(MAINMAP_PLACE_ACTIONS.resetAll());
  };
  const resetStatus = () => {
    dispatch(MAINMAP_PLACE_ACTIONS.resetStatus());
  };
  const resetGetAll = () => {
    dispatch(MAINMAP_PLACE_ACTIONS.resetGetAll());
  };
  const setGeojson = (payload) => {
    dispatch({ type: MAINMAP_PLACE_CONSTANT.SET_GEOJSON, payload });
  };
  const setParams = (payload) => {
    dispatch({ type: MAINMAP_PLACE_CONSTANT.SET_PARAMS, payload });
  };
  const setCountryMandala = (payload) => {
    dispatch({ type: MAINMAP_PLACE_CONSTANT.SET_COUNTRY_MANDALA, payload });
  };
  const removeParams = (payload) => {
    dispatch({ type: MAINMAP_PLACE_CONSTANT.REMOVE_PARAMS, payload });
  };
  const resetPlaceParams = () => {
    removeParams({ provinsi: null });
    removeParams({ kota: null });
    removeParams({ kecamatan: null });
    removeParams({ desa: null });
  };

  const toggleAdvanceSearchDisabled = (payload) => {
    if (typeof payload === 'boolean') {
      dispatch(MAINMAP_PLACE_ACTIONS.setAdvanceSearchDisabled(payload));
    }
  };

  const toggleChangeCountryDisabled = (payload) => {
    if (typeof payload === 'boolean') {
      dispatch(MAINMAP_PLACE_ACTIONS.setChangeCountryDisable(payload));
    }
  };

  return {
    state,
    get_all,
    get_province_inter,
    get_city_inter,
    get_district_inter,
    get_province,
    get_city,
    get_district,
    get_village,
    get_advance,
    get_advance_inter,
    get_coordinates,
    get_search,
    get_country,
    resetAll,
    resetStatus,
    setGeojson,
    setParams,
    removeParams,
    toggleAdvanceSearchDisabled,
    toggleChangeCountryDisabled,
    resetPlaceParams,
    setCountryMandala,
    resetGetAll,
  };
}
