import React, { Fragment, useEffect } from 'react';
import { usePlace } from '../../../hooks/usePlace';
import { debounce, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import '../Dropdown.scss';
import Dropdown from '../../Dropdown';

export function SearchVillage(props) {
  const PLACE = usePlace();

  useEffect(() => {
    if (props.idDistrict) {
      getData('', props.idDistrict);
    }
  }, [props.idDistrict]);

  useEffect(() => {
    if (!isEmpty(PLACE.state.params.desa)) {
      props.isVillage && props.isVillage(PLACE.state.params.desa);
    }
  }, [PLACE.state.params.desa]);

  const getData = (value, idDistrict) => {
    const params = {
      meta: {
        filter: { keyword: value, id_district: idDistrict },
        page: 1,
        pageSize: 100,
      },
    };
    PLACE.get_village({ params });
  };

  const getDataDebounce = debounce(getData, 800);

  return (
    <Fragment>
      <Dropdown
        disabled={isEmpty(PLACE.state.params.kecamatan)}
        handler={{
          select: (ctx) => {
            PLACE.setParams({ type: 'desa', ...ctx });
            props.isVillage && props.isVillage(ctx);
          },
          clear: () => PLACE.removeParams({ desa: null }),
          search: (value) => {
            getDataDebounce(value, props.idDistrict);
          },
        }}
        isLoading={PLACE.state.village?.status_GET === 'LOADING'}
        options={PLACE.state.village?.list}
        placeholder='Village'
        selected={{
          id: PLACE.state.params.desa?.id_desa,
          name: PLACE.state.params.desa?.nama_desa,
          code: PLACE.state.params.desa?.kode_desa,
        }}
        type='desa'
      />
    </Fragment>
  );
}

SearchVillage.defaultProps = {
  data: undefined,
  isVillage: undefined,
};

SearchVillage.propTypes = {
  data: PropTypes.object,
  idDistrict: PropTypes.number,
  isVillage: PropTypes.func,
};
