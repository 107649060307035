import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 1.1
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Faisal <ahmad.faisal@bvarta.com>
 * @param {object} props
 * @param {string} props.className
 * @param {'default' | 'white'} props.background background icon
 */

export default function IconClose(props) {
  const { className, background, onClick } = props;
  return (
    <div
      className={`icon-close ${className} background_${background}`}
      onClick={onClick}
    >
      <svg
        height='12.263'
        viewBox='0 0 12.263 12.263'
        width='12.263'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M3.153,7.489V5.518H1.183a1.182,1.182,0,1,1,0-2.364h1.97V1.183a1.183,1.183,0,1,1,2.365,0V3.154H7.489a1.182,1.182,0,1,1,0,2.364H5.518V7.489a1.183,1.183,0,1,1-2.365,0Z'
          data-name='Union 24'
          fill='#616477'
          id='Union_24'
          transform='translate(6.132) rotate(45)'
        />
      </svg>
    </div>
  );
}

IconClose.defaultProps = {
  background: 'default',
  className: '',
  onClick: () => {},
};

IconClose.propTypes = {
  background: PropTypes.oneOf(['default', 'white']),
  className: PropTypes.string,
  onClick: PropTypes.func,
};
