import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '@bvt-features/mapp/step/component/Breadcrumbs';

/**
 * @param {object} props
 * @param {any} props.data
 * @param {number} props.activeStep
 */

export default function StepContainer(props) {
  const { activeStep, data } = props;

  return (
    <React.Fragment>
      <Breadcrumbs activeStep={activeStep} data={data} />
    </React.Fragment>
  );
}

StepContainer.propTypes = {
  activeStep: PropTypes.number,
  data: PropTypes.array,
};

StepContainer.defaultProps = {
  activeStep: 0,
  data: [
    {
      id: 1,
      id_parent: 10,
      step_order: 1,
      step_name: 'Step Example 1',
      step_code: 'STEP_EXAMPLE_1',
    },
    {
      id: 2,
      id_parent: 10,
      step_order: 2,
      step_name: 'Step Example 2',
      step_code: 'STEP_EXAMPLE_2',
    },
    {
      id: 3,
      id_parent: 10,
      step_order: 3,
      step_name: 'Step Example 3',
      step_code: 'STEP_EXAMPLE_3',
    },
    {
      id: 4,
      id_parent: 10,
      step_order: 4,
      step_name: 'Step Example 4',
      step_code: 'STEP_EXAMPLE_4',
    },
    {
      id: 5,
      id_parent: 10,
      step_order: 5,
      step_name: 'Step Example 5',
      step_code: 'STEP_EXAMPLE_5',
    },
  ],
};
