import _ from 'lodash';
import React from 'react';
import './AccountItemInfo.scss';

/**
 * @company PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

/**
 * @description For ata object
 * @typedef IDataObject
 * @type {Object}
 * @property {string} label
 * @property {string} value
 */

/**
 * @param {Object} props
 * @param {Array<IDataObject>} props.data
 */

export const AccountItemInfo = (props) => {
  const { data } = props;
  return (
    <div className='AccountItemInfo'>
      {_.map(data, (v, idx) => {
        return (
          <div className='AccountItemInfo--item' key={idx}>
            <div className='AccountItemInfo--label'>{v?.label || ''}</div>
            <div className='AccountItemInfo--value'>{v?.value || ''}</div>
          </div>
        );
      })}
    </div>
  );
};
