import React from 'react';
import { Button } from 'antd';

import './AsmanCardAnalysisResultLoadingComponent.scss';

export const AsmanCardAnalysisResultLoadingComponent = props => {
  const { onClick } = props;
  return (
    <div className='AsmanCardAnalysisResultLoadingComponent__wrapper'>
      <img alt='error503' src='/assets/images/error503.png' />
      <div className='AsmanCardAnalysisResultLoadingComponent__title'>
        Analysis Not Ready
      </div>
      <div className='AsmanCardAnalysisResultLoadingComponent__body'>
        We apologize for the inconvenience during your analysis. This could be
        due to a large dataset or complex analysis that requires additional
        processing time. You will receive a notification as soon as the analysis
        is complete and the results are available for you to view.
      </div>
      <Button onClick={() => onClick && onClick()} size='large' type='link'>
        Back to List Asset
      </Button>
    </div>
  );
};
