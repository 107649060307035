import React from 'react';
import { Col, Row } from 'antd';

import './MainMapTopSideLayout.scss';
import { AdvanceSearchFloatingContainer } from '@bvt-features/sub-module/advance-search/container/AdvanceSearchFloatingLabel.container';
import { MAINMAP_TOP_SIDE_PORTAL_CONSTANT } from '../../constant/MAINMAP_TOP_SIDE_PORTAL_CONSTANT';

export const MainMapTopSideLayout = () => {
  return (
    <div className='MainMapTopSideLayout__wrapper'>
      <Row className='MainMapTopSideLayout__row'>
        <Col>
          <AdvanceSearchFloatingContainer />
        </Col>
        <Col>
          <div id={MAINMAP_TOP_SIDE_PORTAL_CONSTANT} />
        </Col>
      </Row>
    </div>
  );
};
