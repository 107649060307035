import { MAPP_PROJECT_STEP_HISTORY_CONSTANT } from './MAPP_PROJECT_STEP_HISTORY_CONSTANT';

const initialState = {
  status_POST: 'IDLE',
  message: '',
  result: {},
};

export function MAPP_PROJECT_STEP_HISTORY_REDUCER(
  state = initialState,
  action
) {
  let returnData = state;
  Object.values(MAPP_PROJECT_STEP_HISTORY_CONSTANT).map((ctx) => {
    if (action.type === MAPP_PROJECT_STEP_HISTORY_CONSTANT.RESET_STATUS) {
      returnData = {
        ...returnData,
        status_POST: 'IDLE',
        message: '',
      };
    } else if (action.type === MAPP_PROJECT_STEP_HISTORY_CONSTANT.RESET_ALL) {
      returnData = {
        status_POST: 'IDLE',
        message: '',
        result: {},
      };
    } else if (ctx === action.type && !ctx.includes('[NO_STATE_UPDATE]')) {
      returnData = { ...returnData, ...action.payload };
    }
    return null;
  });

  return returnData;
}
