import { MappOpenProjectService, MappOpenReportService } from '../services';

const { createAsyncThunk } = require('@reduxjs/toolkit');

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta <renta.yustika@bvarta.com>
 * @author Ryfan <ryfan.aditya@bvarta.com>
 * @author Riza <mochamad.riza@bvarta.com>
 */

const mainOpenProjectService = new MappOpenProjectService();

const doGetAllProject = createAsyncThunk(
  'LI/MAPP/OPEN-PROJECT/get',
  /**
   * @description For meta project
   * @param {Object} param
   * @param {Object} param.meta
   * @param {Object} param.meta.filter
   * @param {String} param.meta.filter.name
   * @param {Object} param.meta.filter.rangeDate
   * @param {String} param.meta.filter.rangeDate.start
   * @param {String} param.meta.filter.rangeDate.end
   * @param {Number} param.meta.page
   * @param {Number} param.meta.page
   * @param {Object} param.meta.sort
   * @param {String} param.meta.sort.by
   * @param {String} param.meta.sort.type
   */
  async (param, { rejectWithValue }) => {
    try {
      const dataResponse = await mainOpenProjectService.getAll(param);
      return dataResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doDuplicateProject = createAsyncThunk(
  'LI/MAPP/OPEN-PROJECT/duplicate',

  /**
   * @description For duplicate payload
   * @param {Object} param
   * @param {Number} param.id
   * @param {Object} param.payload
   * @param {String} param.payload.name
   */
  async (param, { rejectWithValue }) => {
    try {
      const dataResponse = await mainOpenProjectService.duplicate(
        param?.id,
        param?.payload
      );
      return dataResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doRenameProject = createAsyncThunk(
  'LI/MAPP/OPEN-PROJECT/rename',
  /**
   * @description For rename payload
   * @param {Object} param
   * @param {Number} param.id
   * @param {Object} param.payload
   * @param {String} param.payload.name
   */
  async (param, { rejectWithValue }) => {
    try {
      const dataResponse = await mainOpenProjectService.rename(
        param?.id,
        param?.payload
      );
      return dataResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doDeleteProject = createAsyncThunk(
  'LI/MAPP/OPEN-PROJECT/delete',
  /**
   * @description For delete id
   * @param {Number} id
   */
  async (id, { rejectWithValue }) => {
    try {
      const dataResponse = await mainOpenProjectService.delete(id);
      return dataResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doGetRangeDateExistProject = createAsyncThunk(
  'LI/MAPP/OPEN-PROJECT/range-date-exist',
  /**
   * @description For get range date param
   * @param {Object} param
   * @param {Object} param.meta
   * @param {Object} param.meta.filter
   * @param {String} param.meta.filter.type
   * @param {String} param.meta.filter.name
   * @param {Object} param.meta.filter.rangeDate
   * @param {String} param.meta.filter.rangeDate.start
   * @param {String} param.meta.filter.rangeDate.end
   * @param {String} param.meta.filter.project_type
   * @param {String} param.meta.filter.year_month
   */
  async (param, { rejectWithValue }) => {
    try {
      const dataResponse = await mainOpenProjectService.rangeDateExist(param);
      return dataResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const mainOpenReportService = new MappOpenReportService();

const doGetAllReport = createAsyncThunk(
  'LI/MAPP/OPEN-REPORT/get',
  /**
  * @description For meta report
  * @param {Object} param
  * @param {Object} param.meta
  * @param {Object} param.meta.filter
  * @param {String} param.meta.filter.name
  * @param {Object} param.meta.filter.rangeDate
  * @param {String} param.meta.filter.rangeDate.start
  * @param {String} param.meta.filter.rangeDate.end
  * @param {Number} param.meta.page
  * @param {Number} param.meta.page
  * @param {Object} param.meta.sort
  * @param {String} param.meta.sort.by
  * @param {String} param.meta.sort.type
  */
  async (param, { rejectWithValue }) => {
    try {
      const dataResponse = await mainOpenReportService.getAll(param);
      return dataResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doGetRangeDateExistReport = createAsyncThunk(
  'LI/MAPP/OPEN-REPORT/range-date-exist',
  /**
  * @description For get range date param
  * @param {Object} param
  * @param {Object} param.meta
  * @param {Object} param.meta.filter
  * @param {String} param.meta.filter.type
  * @param {String} param.meta.filter.name
  * @param {Object} param.meta.filter.rangeDate
  * @param {String} param.meta.filter.rangeDate.start
  * @param {String} param.meta.filter.rangeDate.end
  * @param {String} param.meta.filter.project_type
  * @param {String} param.meta.filter.year_month
  */
  async (param, { rejectWithValue }) => {
    try {
      const dataResponse = await mainOpenReportService.rangeDateExist(param);
      return dataResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export {
  doGetAllProject,
  doDuplicateProject,
  doRenameProject,
  doDeleteProject,
  doGetRangeDateExistProject,
  doGetAllReport,
  doGetRangeDateExistReport
};
