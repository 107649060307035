import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Faisal <ahmad.faisal@bvarta.com>
 * @param {Object} props
 * @param {string} props.className
 * @param {string} props.onClick
 */

export default function IconZoomPass(props) {
  const { className, onClick } = props;

  return (
    <div className={`${className} icon-zoom-pass`} onClick={onClick}>
      <svg
        height='18'
        viewBox='0 0 18 18'
        width='18'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8ZM5,15H3v4a2.006,2.006,0,0,0,2,2H9V19H5ZM5,5H9V3H5A2.006,2.006,0,0,0,3,5V9H5ZM19,3H15V5h4V9h2V5A2.006,2.006,0,0,0,19,3Zm0,16H15v2h4a2.006,2.006,0,0,0,2-2V15H19Z'
          fill='#364961'
          id='ic_center_focus_strong_24px'
          transform='translate(-3 -3)'
        />
      </svg>
    </div>
  );
}

IconZoomPass.defaultProps = {
  className: '',
  onClick: () => {},
};

IconZoomPass.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
