import { useNavigate } from 'react-router';
import { useAuth } from '@bvt-features/auth/hooks';
import { useParameter } from '@bvt-features/genset/parameter/hooks/useParameter';
import { usePOI } from '@bvt-features/genset/poi/hooks/usePOI';
import { usePlace } from '@bvt-features/mainmap/place/hooks/usePlace';
import { useStep } from '@bvt-features/mapp/step/hook';
import { useMappTaskAnalyze } from '@bvt-features/mapp/taskAnalyze/hook/useMappTaskAnalyze';
import { useGRIANA } from '../useGRIANA';
import { useProject } from '@bvt-features/mapp/project/hooks/useProject';
import { useSearchParams } from 'react-router-dom';

const { useFormik } = require('formik');

export function useAddEditGrianaForm() {
  const GRIANA = useGRIANA();
  const POI = usePOI();
  const AUTH = useAuth();
  const STEP = useStep();
  const PLACE = usePlace();
  const PARAMETER = useParameter();
  const TASK_ANALYZE = useMappTaskAnalyze();
  const PROJECT = useProject();
  const NAVIGATE = useNavigate();
  const SEARCH_PARAM = useSearchParams();

  const FORMIK = useFormik({
    initialValues: {
      id: undefined,
      name: undefined,
      province: undefined,
      city: undefined,
      projectType: undefined,
      projectName: undefined,
      poi: undefined,
      parameter: undefined,
      peopleDensity: undefined,
      gridSize: undefined,
      switchPeopleDensity: false,
      score: {
        poi: 0,
        demography: 0,
        ses: 0,
        peopleDensity: 0,
      },
      id_step: undefined,
    },

    onSubmit: (p) => {
      GRIANA.doAnalyze({
        id: p.id,
        name: p.name,
        history: {
          form_set: {
            province: {
              id: p.province?.id_provinsi,
              code: p.province?.kode_provinsi,
            },
            regency: {
              id: p.city?.id_kota,
              code: p.city?.kode_kota,
            },
            scoring: {
              gridSize:
                p.gridSize?.gridSize === '1 Km' ? 1000 : p.gridSize?.gridSize,
              categories: {
                poi: p.score.poi,
                demography: p.score.demography,
                ses: p.score.ses,
                density: p.score.peopleDensity,
              },
            },
            project: {
              name: GRIANA.state.formSaveProject.name,
            },
            density: {
              timeType: p.peopleDensity?.typeTIme,
              isChecked: p.peopleDensity?.year ? true : false,
              year: p.peopleDensity?.year,
              month: p.peopleDensity?.month,
              day: p.peopleDensity?.date
                ? p.peopleDensity?.date?.split('-')[2]
                : '',
              hour: {
                min: '',
                max: '',
              },
              resultType: p.peopleDensity?.totalAverage,
            },
            poi: p.poi,
            parameter: p.parameter,
            id_step: p.id_step ? p.id_step : 14,
          },
        },
      });
    },
  });

  const GetPoiHandler = (param) => {
    POI.get({
      meta: {
        filter: {
          keyword: param?.meta?.filter?.keyword || '',
          poi_for_user: AUTH.state.data?.user?.id,
          rangeDate: {
            start: param?.meta?.filter?.rangeDate?.start || '',
            end: param?.meta?.filter?.rangeDate?.end || '',
          },
          type: param?.meta?.filter?.type || '',
        },
        page: param?.meta?.page || 1,
        pageSize: param?.meta?.pageSize || 10,
        sort: {
          by: param?.meta?.sort?.by || 'last_updated',
          name: param?.meta?.sort?.name || 'ASC',
        },
      },
    });
  };

  const GetDetailPOIHandler = (id) => {
    if (id === undefined) {
      POI.detail(POI.state?.detail?.id);
    } else {
      POI.detail(id);
    }
  };

  const getParameterHandler = (param) => {
    let payload = {
      meta: {
        filter: {
          keyword: param?.meta?.filter?.keyword,
          user_id: AUTH?.state?.data?.user?.id,
          rangeDate: param?.meta?.filter?.rangeDate,
        },
        page: param?.meta?.page || 1,
        pageSize: param?.meta?.pageSize || 10,
        sort: { by: param?.meta?.sort?.by, name: param?.meta?.sort?.name },
      },
    };
    PARAMETER.get(payload);
  };

  const nextStep = (formFn) => {
    if (typeof formFn === 'function') {
      formFn();
    }
    GRIANA.nextStep();
  };

  const prevStep = (formFn) => {
    if (typeof formFn === 'function') {
      formFn();
    }
    GRIANA.prevStep();
  };

  const doAnalyze = () => {
    FORMIK.submitForm();
  };

  const saveProjectName = (name) => {
    GRIANA.saveProject({ name });
  };

  const saveProject = () => {
    GRIANA.saveProject({
      id: FORMIK.values.id,
      name: FORMIK.values.name,
      history: {
        form_set: {
          province: {
            id: FORMIK.values.province?.id_provinsi,
            code: FORMIK.values.province?.kode_provinsi,
          },
          regency: {
            id: FORMIK.values.city?.id_kota,
            code: FORMIK.values.city?.kode_kota,
          },
          scoring: {
            gridSize:
              FORMIK.values.gridSize?.gridSize === '1 Km'
                ? 1000
                : FORMIK.values.gridSize?.gridSize,
            categories: {
              poi: FORMIK.values.score.poi,
              demography: FORMIK.values.score.demography,
              ses: FORMIK.values.score.ses,
              density: FORMIK.values.score.peopleDensity,
            },
          },
          project: {
            name: GRIANA.state.formSaveProject.name,
          },
          density: {
            timeType: FORMIK.values.peopleDensity?.typeTIme,
            isChecked: FORMIK.values.peopleDensity?.year ? true : false,
            year: FORMIK.values.peopleDensity?.year,
            month: FORMIK.values.peopleDensity?.month,
            day: FORMIK.values.peopleDensity?.date
              ? FORMIK.values.peopleDensity?.date?.split('-')[2]
              : '',
            hour: {
              min: '',
              max: '',
            },
            resultType: FORMIK.values.peopleDensity?.totalAverage,
          },
          poi: FORMIK.values.poi,
          parameter: FORMIK.values.parameter,
          id_step: FORMIK.values.id_step ? FORMIK.values.id_step : 14,
        },
      },
    });
  };

  const resetPlaceParams = () => {
    PLACE.removeParams({ provinsi: null });
    PLACE.removeParams({ kota: null });
    PLACE.removeParams({ kecamatan: null });
    PLACE.removeParams({ desa: null });
  };

  return {
    FORMIK,
    POI,
    GRIANA,
    STEP,
    PARAMETER,
    PLACE,
    TASK_ANALYZE,
    PROJECT,
    SEARCH_PARAM,
    NAVIGATE,
    GetPoiHandler,
    GetDetailPOIHandler,
    getParameterHandler,
    doAnalyze,
    nextStep,
    prevStep,
    saveProjectName,
    resetPlaceParams,
    saveProject,
  };
}
