import { Modal } from 'antd';
import React from 'react';
import './ForceLogout.scss';
import { ImageBox } from '@bvt-shared/component/ImageBox';

const ForceLogout = ({ open, onSubmit }) => {
  return (
    <Modal
      centered
      className='auth-gate Force-logout__container'
      closable={false}
      footer={null}
      open={open}
      width={'fit-content'}
    >
      <div className='auth-gate Force-logout__content'>
        <ImageBox
          className='auth-gate Force-logout__content--img'
          src='il-logout'
        />
        <h1 className='auth-gate Force-logout__content--title'>
          You’re forced to log out
        </h1>
        <p className='auth-gate Force-logout__content--desc'>
          Your account is being used on another device
        </p>
      </div>
      <div className='auth-gate Force-logout__footer'>
        <button
          className='auth-gate Force-logout__footer--button'
          onClick={onSubmit}
        >
          Okay
        </button>
      </div>
    </Modal>
  );
};

export default ForceLogout;
