import { GensetParameterService } from '../service';

const { createAsyncThunk } = require('@reduxjs/toolkit');

/**
 * @copyright PT Bhumi Varta Technology
 * @author Ryfan <ryfan.aditya@bvarta.com>
 */

const gensetParameterService = new GensetParameterService();

const doGetAll = createAsyncThunk(
  'LI/GENSET/PARAMETER/get',
  /**
   * @param {object} params - Params for get list parameter
   * @param {object} params.meta - Meta for filter parameter
   * @param {object} params.meta.filter
   * @param {string} params.meta.filter.keyword
   * @param {string} params.meta.filter.user_id
   * @param {number} params.meta.page
   * @param {number} params.meta.pageSize
   * @param {object} params.meta.sort
   * @param {string} params.meta.sort.by
   * @param {string} params.meta.sort.name
   */
  async (param, { rejectWithValue }) => {
    try {
      const dataResponse = await gensetParameterService.getAll(param);
      return dataResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doRename = createAsyncThunk(
  'LI/GENSET/PARAMETER/rename',
  /**
   * @description For rename payload
   * @param {Object} param
   * @param {Number} param.id
   * @param {Object} param.payload
   */
  async (param, { rejectWithValue }) => {
    try {
      const dataResponse = await gensetParameterService.edit(
        param?.id,
        param?.payload
      );
      return dataResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doDelete = createAsyncThunk(
  'LI/GENSET/PARAMETER/delete',
  /**
   * @description For delete id
   * @param {Number} id
   */
  async (id, { rejectWithValue }) => {
    try {
      const dataResponse = await gensetParameterService.delete(id);
      return dataResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doDownload = createAsyncThunk(
  'LI/GENSET/PARAMETER/download',
  /**
   * @description For download id
   * @param {Array} param
   */
  async (param, { rejectWithValue }) => {
    try {
      const arrayID = { arrayID: param };
      const dataResponse = await gensetParameterService.downloadParameter(
        arrayID
      );
      return dataResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doDownloadRow = createAsyncThunk(
  'LI/GENSET/PARAMETER/downloadrow',
  /**
   * @description For download id
   * @param {Array} param
   */
  async (param, { rejectWithValue }) => {
    try {
      const arrayID = { arrayID: param };
      const dataResponse = await gensetParameterService.downloadParameter(
        arrayID
      );
      return dataResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doDetail = createAsyncThunk(
  'LI/GENSET/PARAMETER/detail',
  /**
   * @description For detail id
   * @param {Number} id
   */
  async (id, { rejectWithValue }) => {
    try {
      const dataResponse = await gensetParameterService.getDetail(id);
      return dataResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doUpdate = createAsyncThunk(
  'LI/GENSET/PARAMETER/update',
  /**
   * @description For update payload
   * @param {Object} param
   * @param {Number} param.id
   * @param {Object} param.payload
   */
  async (param, { rejectWithValue }) => {
    try {
      const dataResponse = await gensetParameterService.edit(
        param?.id,
        param?.payload
      );
      return dataResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doCreate = createAsyncThunk(
  'LI/GENSET/PARAMETER/create',
  /**
   * @description For create payload
   * @param {Object} payload
   */
  async (payload, { rejectWithValue }) => {
    try {
      const dataResponse = await gensetParameterService.add(payload);
      return dataResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doUpload = createAsyncThunk(
  'LI/GENSET/PARAMETER/upload',
  /**
   * @description For create payload
   * @param {Object} param
   * @param {Object} param.query
   * @param {Object} param.payload
   */
  async (param, { rejectWithValue }) => {
    try {
      const dataResponse = await gensetParameterService.uploadParameter(
        param?.query,
        param?.payload
      );
      return dataResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export {
  doGetAll,
  doRename,
  doDelete,
  doDownload,
  doDownloadRow,
  doDetail,
  doUpdate,
  doCreate,
  doUpload,
};
