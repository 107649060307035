import Resource from '../../../../api/resource';

export class TaskAnalyzeService extends Resource {
  constructor() {
    super('task-for-analyze', '/api', 'api-poi', 'v2');
  }
  /**
   * @author Randa<m.randa@bvarta.com>
   * @copyright PT Bhumi Techonology
   * @param {object} param
   * @param {object} param.filter
   * @param {number} param.limit
   * @param {number} param.page
   * @param {object} param.search
   * @param {'ASC'|'DESC'} param.sort
   * @param {'ASC'|'DESC'} param.sort.by
   * @param {string} param.sort.name
   */
  doGetTask(param) {
    const meta = {
      meta: {
        filter: param?.filter || {},
        limit: param?.limit || 10,
        page: param?.page || 1,
        search: param?.search || '',
        sort: { by: param?.sort?.by || 'DESC', name: param?.sort?.name || '' },
      },
    };
    this.setAdditionUri('');
    return this.list(meta);
  }

  /**
   *
   * @param {string | number} jobId
   */
  doDeleteOneTask(jobId) {
    this.setAdditionUri('');
    return this.destroy(jobId);
  }
  /**
   *
   * @param {string | number} jobId
   * @param {string} type
   */
  doCancelOneTask(jobId, type) {
    this.setAdditionUri('/cancel-queue');
    return this.create({ jobid: jobId, type: type });
  }
}
