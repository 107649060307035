import Resource from '../../../../api/resource';

export class CustomPOIService extends Resource {
  constructor() {
    super('poi-custom', '/api', 'api-poi', null);
  }

  /**
   * @param {object} query
   * @param {number} query.page
   * @param {number} query.pageSize
   * @param {string} query.marker_name
   */
  getAll(query) {
    this.setAdditionUri('');
    return this.list(query);
  }
}
