import * as React from 'react';
const IconSecurity = (props) => (
  <svg
    fill='none'
    height={12}
    viewBox='0 0 12 12'
    width={12}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_7423_16827)'>
      <path
        d='M5.99916 11C5.41622 10.6819 4.86063 10.3138 4.33759 9.8992C3.91669 9.56995 3.51504 9.21423 3.13657 8.83399C2.80352 8.47921 2.53376 8.06661 2.33957 7.61498C2.09954 7.05647 1.95372 6.69203 1.85558 6.39454C1.71679 5.92235 1.63324 5.43465 1.606 4.94198C1.55235 4.35868 1.52074 3.84786 1.51086 3.41039C1.50521 3.16394 1.49707 2.65419 1.49707 1.62174C4.44279 0.788195 7.55139 0.788195 10.4971 1.62174C10.4971 2.47045 10.4918 3.0728 10.4847 3.41039C10.4776 3.77933 10.446 4.27775 10.3896 4.94198C10.361 5.43455 10.2766 5.92213 10.1386 6.39454C10.0299 6.71901 9.87208 7.11917 9.65322 7.61498C9.46127 8.06824 9.19076 8.48131 8.85484 8.83399C8.47842 9.21402 8.07943 9.56973 7.66072 9.8992C7.13721 10.3134 6.58074 10.6815 5.99778 11H5.99916ZM3.84532 5.15987V8.1191H8.29915V5.15987H5.51241V4.41721C5.51609 4.24503 5.58078 4.08042 5.69442 3.95438C5.75225 3.89354 5.82099 3.84568 5.89712 3.81269C5.97324 3.77969 6.05587 3.76213 6.13842 3.76213C6.22098 3.76213 6.30223 3.77969 6.37835 3.81269C6.45448 3.84568 6.52322 3.89354 6.58105 3.95438C6.63814 4.01083 6.6842 4.07921 6.7148 4.15447C6.74541 4.22972 6.75987 4.31067 6.75893 4.39229H7.65521C7.64158 3.975 7.4784 3.57737 7.19879 3.27581C6.9146 2.98964 6.53656 2.82453 6.1398 2.81298C5.93561 2.81094 5.73348 2.85408 5.54688 2.93973C5.36646 3.02327 5.20265 3.1399 5.06289 3.28435C4.78405 3.59283 4.62469 3.99604 4.61475 4.41792V5.16058L3.84532 5.15987Z'
        fill='#6C757D'
      />
    </g>
    <defs>
      <clipPath id='clip0_7423_16827'>
        <rect fill='white' height={10} transform='translate(1.5 1)' width={9} />
      </clipPath>
    </defs>
  </svg>
);
export { IconSecurity };
