export const GRIANA_CONSTANT = {
  SET_STEP: 'LI/MAPANA/GRIANA/set-step',
  SET_LAST_STEP: 'LI/MAPANA/GRIANA/set-last-step',

  SAVE_CURRENT_STEP_REQUESTED: 'LI/MAPANA/GRIANA/save-current-step/requested',
  SAVE_CURRENT_STEP_SUCCESS: 'LI/MAPANA/GRIANA/save-current-step/success',
  SAVE_CURRENT_STEP_FAILED: 'LI/MAPANA/GRIANA/save-current-step/failed',

  GRID_COLLECTIONS_REQUESTED: 'LI/MAPANA/GRIANA/get-grid-collections/requested',
  GRID_COLLECTIONS_SUCCESS: 'LI/MAPANA/GRIANA/get-grid-collections/success',
  GRID_COLLECTIONS_FAILED: 'LI/MAPANA/GRIANA/get-grid-collections/failed',
  GRID_COLLECTIONS_RIGHT_REQUESTED: 'LI/MAPANA/GRIANA/get-grid-collections/requested',
  GRID_COLLECTIONS_RIGHT_SUCCESS: 'LI/MAPANA/GRIANA/get-grid-collections/success',
  GRID_COLLECTIONS_RIGHT_FAILED: 'LI/MAPANA/GRIANA/get-grid-collections/failed',

  GRID_DETAIL_REQUESTED: 'LI/MAPANA/GRIANA/get-grid-detail/requested',
  GRID_DETAIL_SUCCESS: 'LI/MAPANA/GRIANA/get-grid-detail/success',
  GRID_DETAIL_FAILED: 'LI/MAPANA/GRIANA/get-grid-detail/failed',
  GRID_DETAIL_RIGHT_REQUESTED: 'LI/MAPANA/GRIANA/get-grid-detail-right/requested',
  GRID_DETAIL_RIGHT_SUCCESS: 'LI/MAPANA/GRIANA/get-grid-detail-right/success',
  GRID_DETAIL_RIGHT_FAILED: 'LI/MAPANA/GRIANA/get-grid-detail-right/failed',

  POI_COLLECTIONS_REQUESTED: 'LI/MAPANA/GRIANA/get-poi-collections/requested',
  POI_COLLECTIONS_SUCCESS: 'LI/MAPANA/GRIANA/get-poi-collections/success',
  POI_COLLECTIONS_FAILED: 'LI/MAPANA/GRIANA/get-poi-collections/failed',
  POI_COLLECTIONS_RIGHT_REQUESTED: 'LI/MAPANA/GRIANA/get-poi-collections-right/requested',
  POI_COLLECTIONS_RIGHT_SUCCESS: 'LI/MAPANA/GRIANA/get-poi-collections-right/success',
  POI_COLLECTIONS_RIGHT_FAILED: 'LI/MAPANA/GRIANA/get-poi-collections-right/failed',

  SELECT_GRID: 'LI/MAPANA/GRIANA/select-grid',
  SELECT_GRID_RIGHT: 'LI/MAPANA/GRIANA/select-grid-right',
  SET_WHITELIST_GID: 'LI/MAPANA/GRIANA/set-whitelist-gid',
  SET_WHITELIST_GID_RIGHT: 'LI/MAPANA/GRIANA/set-whitelist-gid-right',
  SET_ACCEPTABLE: 'LI/MAPANA/GRIANA/set-acceptable',
  SET_ACCEPTABLE_RIGHT: 'LI/MAPANA/GRIANA/set-acceptable-right',
  SELECT_GRID_TO_ARRAY: 'LI/MAPANA/GRIANA/select-grid-to-array',
  SELECT_GRID_RIGHT_TO_ARRAY: 'LI/MAPANA/GRIANA/select-grid-right-to-array',

  SAVE_PROJECT_REQUESTED: 'LI/MAPANA/GRIANA/save-project/requested',
  SAVE_PROJECT_SUCCESS: 'LI/MAPANA/GRIANA/save-project/success',
  SAVE_PROJECT_FAILED: 'LI/MAPANA/GRIANA/save-project/failed',

  DO_ANALYZE_REQUESTED: 'LI/MAPANA/GRIANA/do-analyze/requested',
  DO_ANALYZE_SUCCESS: 'LI/MAPANA/GRIANA/do-analyze/success',
  DO_ANALYZE_FAILED: 'LI/MAPANA/GRIANA/do-analyze/failed',

  DO_ANALYZE_INTER_REQUESTED: 'LI/MAPANA/GRIANA/do-analyze-inter/requested',
  DO_ANALYZE_INTER_SUCCESS: 'LI/MAPANA/GRIANA/do-analyze-inter/success',
  DO_ANALYZE_INTER_FAILED: 'LI/MAPANA/GRIANA/do-analyze-inter/failed',

  RESET_STATUS: 'LI/MAPANA/GRIANA/reset-status',
  RESET_ALL: 'LI/MAPANA/GRIANA/reset-all',

  SET_IS_SHOWN_ON_MAP: 'LI/MAPANA/GRIANA/is-shown-on-map',
};
