import React from 'react';
import MenuBarContainer from '@bvt-features/mainmap/menu-bar/container/MenuBarContainer';
import ModuleBarContainer from '@bvt-features/mainmap/module-bar/container/ModuleBarContainer';
import {
  GeoJsonMapAdvanceSearch,
  MapSearch,
  MapSearchBoundingMap,
} from '@bvt-features/mainmap/place/component/MapAdvancedSearch';
import { MapContainer } from 'react-leaflet';
import 'leaflet-arrowheads';
import 'leaflet/dist/leaflet.css';
import SiproMapContainer from '@bvt-features/mapana/sipro/container/SiproMapContainer';
import { DEFAULT_MAP_OPTIONS_DATA_CONSTANT } from '@bvt-features/mainmap/mainmap/constant/DEFAULT_MAP_OPTIONS_DATA_CONSTANT';
import { GrianaMapContainer } from '@bvt-features/mapana/griana/container/GrianaMapContainer';
import { MainMapToolbarContainer } from '../MainMapToolbarContainer';
import { MainMapTileLayerContainer } from '../MainMapTileLayerContainer';
import { MainMapLayeringVisiblityAndBasemapContainer } from '../MainMapLayeringVisiblityAndBasemapContainer';
import { DatexContainer } from '@bvt-features/datex/container/DatexContainer/DatexContainer';
import DroppableMarker from '@bvt-features/mainmap/droppable-marker/component/DroppableMarker';
import { LegendContainer } from '@bvt-features/mainmap/legend/container/LegendContainer';
import { PoiSearchMarkerContainer } from '@bvt-features/mainmap/poi-search/container';
/**
 * @author Randa<m.randa@bvarta.com>
 * @description New Base map all feature register in this component;
 * @param {object} props
 * @param {JSX.Element} props.children
 * @returns {JSX.Element}
 */
export const LayoutMainMapContainer = (props) => {
  const { children } = props;

  return (
    <React.Fragment>
      <MapContainer {...DEFAULT_MAP_OPTIONS_DATA_CONSTANT}>
        <MainMapTileLayerContainer />
        <MenuBarContainer />
        <ModuleBarContainer />
        <MapSearch />
        <PoiSearchMarkerContainer />
        <LegendContainer />
        <MainMapToolbarContainer />
        <MainMapLayeringVisiblityAndBasemapContainer />
        <GeoJsonMapAdvanceSearch />
        <MapSearchBoundingMap/>
        {/* START FEATURE */}
        <DatexContainer />
        <GrianaMapContainer />
        <SiproMapContainer />
        <DroppableMarker />
        {/* END FEATURE */}
      </MapContainer>
      <div>
        {/*
          for sidebar MAPANA,
          if you need interact with map,
          please create another component and put to inside of MapContainer,
          use redux and useeffect to trigger your function
        */}
        {children}
      </div>
    </React.Fragment>
  );
};
