import { createSlice } from '@reduxjs/toolkit';
import { doDeleteProject, doDuplicateProject, doGetAllProject, doGetAllReport, doRenameProject } from './mainOpen.asyncAction';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta <renta.yustika@bvarta.com>
 * @author Ryfan <ryfan.aditya@bvarta.com>
 * @author Riza <mochamad.riza@bvarta.com>
 */

/**
 * @typedef initialStateModel
 * @type {Object}
 * @property {'IDLE'|'SUCCESS'|'FAILED'|'LOADING'} status_GET
 * @property {'IDLE'|'SUCCESS'|'FAILED'|'LOADING'} status_POST
 * @property {'IDLE'|'SUCCESS'|'FAILED'|'LOADING'} status_PUT
 * @property {'IDLE'|'SUCCESS'|'FAILED'|'LOADING'} status_PATCH
 * @property {'IDLE'|'SUCCESS'|'FAILED'|'LOADING'} status_DETAIL
 * @property {'IDLE'|'SUCCESS'|'FAILED'|'LOADING'} status_DELETE
 * @property {'IDLE'|'SUCCESS'|'FAILED'|'LOADING'} status_DOWNLOAD
 * @property {'IDLE'|'SUCCESS'|'FAILED'|'LOADING'} status_UPLOAD
 * @property {String} message
 * @property {Object} detailDuplicate
 * @property {Array<{id:Number,name:String,created_at:String,updated_at:String,project_type:String}>} list
 */

/**
 * @type {initialStateModel} initialState
 */
const initialState = {
  status_GET: 'IDLE',
  status_POST: 'IDLE',
  status_PUT: 'IDLE',
  status_PATCH: 'IDLE',
  status_DETAIL: 'IDLE',
  status_DELETE: 'IDLE',
  status_DOWNLOAD: 'IDLE',
  status_UPLOAD: 'IDLE',
  list: [],
  detailDuplicate: {}
};

export const main_open_project_slice = createSlice({
  name: 'LI/MAPP/OPEN-PROJECT',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
    resetStatus(state, action) {
      switch (action.type) {
        case 'status_GET':
          state.status_GET = 'IDLE';
          break;
        case 'status_POST':
          state.status_POST = 'IDLE';
          break;
        case 'status_PUT':
          state.status_PUT = 'IDLE';
          break;
        case 'status_PATCH':
          state.status_PATCH = 'IDLE';
          break;
        case 'status_DETAIL':
          state.status_DETAIL = 'IDLE';
          break;
        case 'status_DELETE':
          state.status_DELETE = 'IDLE';
          break;
        case 'status_DOWNLOAD':
          state.status_DOWNLOAD = 'IDLE';
          break;
        case 'status_UPLOAD':
          state.status_UPLOAD = 'IDLE';
          break;
        default:
          state.status_GET = 'IDLE';
          state.status_POST = 'IDLE';
          state.status_PUT = 'IDLE';
          state.status_PATCH = 'IDLE';
          state.status_DETAIL = 'IDLE';
          state.status_DELETE = 'IDLE';
          state.status_DOWNLOAD = 'IDLE';
          state.status_UPLOAD = 'IDLE';
          break;
      }
      return state;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(doGetAllProject.pending, (state) => {
        state.status_GET = 'LOADING';
      })
      .addCase(doGetAllProject.fulfilled, (state, action) => {
        const { results, message, meta } = action.payload;
        state.list = results?.map((val, idx) => (
          { ...val, key: idx }
        )) || [];
        state.status_GET = 'SUCCESS';
        state.message = message;
        state.properties = meta;
        state.detail = [];
      })
      .addCase(doGetAllProject.rejected, (state, action) => {
        const { message } = action.payload;
        state.status_GET = 'FAILED';
        state.message = message;
      })
      .addCase(doDuplicateProject.pending, (state) => {
        state.status_POST = 'LOADING';
      })
      .addCase(doDuplicateProject.fulfilled, (state, action) => {
        const { message, result } = action.payload;
        state.status_POST = 'SUCCESS';
        state.message = message;
        state.detailDuplicate = result;
      })
      .addCase(doDuplicateProject.rejected, (state, action) => {
        const { response } = action.payload;
        state.status_POST = 'FAILED';
        state.message = response.data.message;
      })
      .addCase(doDeleteProject.pending, (state) => {
        state.status_DELETE = 'LOADING';
      })
      .addCase(doDeleteProject.fulfilled, (state, action) => {
        const { message } = action.payload;
        state.status_DELETE = 'SUCCESS';
        state.message = message;
      })
      .addCase(doDeleteProject.rejected, (state, action) => {
        const { response } = action.payload;
        state.status_DELETE = 'FAILED';
        state.message = response.data.message;
      })
      .addCase(doRenameProject.pending, (state) => {
        state.status_PATCH = 'LOADING';
      })
      .addCase(doRenameProject.fulfilled, (state, action) => {
        const { message } = action.payload;
        state.status_PATCH = 'SUCCESS';
        state.message = message;
      })
      .addCase(doRenameProject.rejected, (state, action) => {
        const { response } = action.payload;
        state.status_PATCH = 'FAILED';
        state.message = response.data.message;
      }),
});

export const {
  reducer: MAIN_OPEN_PROJECT_REDUCER,
  actions: MAIN_OPEN_PROJECT_ACTION,
} = main_open_project_slice;

export const main_open_report_slice = createSlice({
  name: 'LI/MAPP/OPEN-REPORT',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
    resetStatus(state, action) {
      switch (action.type) {
        case 'status_GET':
          state.status_GET = 'IDLE';
          break;
        case 'status_POST':
          state.status_POST = 'IDLE';
          break;
        case 'status_PUT':
          state.status_PUT = 'IDLE';
          break;
        case 'status_PATCH':
          state.status_PATCH = 'IDLE';
          break;
        case 'status_DETAIL':
          state.status_DETAIL = 'IDLE';
          break;
        case 'status_DELETE':
          state.status_DELETE = 'IDLE';
          break;
        case 'status_DOWNLOAD':
          state.status_DOWNLOAD = 'IDLE';
          break;
        case 'status_UPLOAD':
          state.status_UPLOAD = 'IDLE';
          break;
        default:
          state.status_GET = 'IDLE';
          state.status_POST = 'IDLE';
          state.status_PUT = 'IDLE';
          state.status_PATCH = 'IDLE';
          state.status_DETAIL = 'IDLE';
          state.status_DELETE = 'IDLE';
          state.status_DOWNLOAD = 'IDLE';
          state.status_UPLOAD = 'IDLE';
          break;
      }
      return state;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(doGetAllReport.pending, (state) => {
        state.status_GET = 'LOADING';
      })
      .addCase(doGetAllReport.fulfilled, (state, action) => {
        const { results, message, meta } = action.payload;
        state.list = results?.map((val, idx) => (
          { ...val, key: idx }
        )) || [];
        state.status_GET = 'SUCCESS';
        state.message = message;
        state.properties = meta;
        state.detail = [];
      })
      .addCase(doGetAllReport.rejected, (state, action) => {
        const { message } = action.payload;
        state.status_GET = 'FAILED';
        state.message = message;
      }),
});

export const {
  reducer: MAIN_OPEN_REPORT_REDUCER,
  actions: MAIN_OPEN_REPORT_ACTION,
} = main_open_report_slice;
