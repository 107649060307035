import DynamicIcon from './_dynamicicon';
export default DynamicIcon;
export { default as IcNotification } from './IcNotification/IcNotification';
export { default as IconArrowDown } from './IconArrowDown/IconArrowDown';
export { default as IconArrowUp } from './IconArrowUp/IconArrowUp';
export { default as IconCalender } from './IconCalender/IconCalender';
export { default as IconChecklist } from './IconChecklist/IconChecklist';
export { default as IconClose } from './IconClose/IconClose';
export { default as IconConfig } from './IconConfig/IconConfig';
export { default as IconCsv } from './IconCsv/IconCsv';
export { default as IconDisplay } from './IconDisplay/IconDisplay';
export { default as IconEdit } from './IconEdit/IconEdit';
export { default as IconFolderUp } from './IconFolderUp/IconFolderUp';
export { default as IconHide } from './IconHide/IconHide';
export { default as IconInfo } from './IconInfo/IconInfo';
export { default as IconFilter } from './IconFilter/IconFilter';
export { default as IconLayer } from './IconLayer/IconLayer';
export { default as IconMinus } from './IconMinus/IconMinus';
export { default as IconPadlock } from './IconPadlock/IconPadlock';
export { default as IconPdf } from './IconPdf/IconPdf';
export { default as IconPlus } from './IconPlus/IconPlus';
export { default as IconRoute } from './IconRoute/IconRoute';
export { default as IconSecurity } from './IconSecurity/IconSecurity';
export { default as IconShow } from './IconShow/IconShow';
export { default as IconStatistic } from './IconStatistic/IconStatistic';
export { default as IconUser } from './IconUser/IconUser';
export { default as IconView } from './IconView/IconView';
export { default as IconXls } from './IconXls/IconXls';
export { default as IconZoomPass } from './IconZoomPass/IconZoomPass';
export { default as IconCloud } from './IconCloud/IconCloud';
export { default as IconGridAnalysis } from './IconGridAnalysis/IconGridAnalysis';
export { default as IconPeopleDensity } from './IconPeopleDensity/IconPeopleDensity';
export { default as IconPeopleDensityDashboard } from './IconPeopleDensityDashboard/IconPeopleDensityDashboard';
export { default as IconInfoSimple } from './IconInfoSimple/IconInfoSimple';
export { default as EditIcon } from './edit';
export { default as ResumeIcon } from './resume';
export { default as DuplicateIcon } from './duplicate';
export { default as CreateReportIcon } from './createreport';
export { default as RemoveIcon } from './remove';
export { IconLightTheme } from './IconLightTheme/IconLightTheme';
export { IconDarkTheme } from './IconDarkTheme/IconDarkTheme';