import React from 'react';
import { Alert, Checkbox } from 'antd';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import { useFormik } from 'formik';
import * as yup from 'yup';
import AuthInput from '@bvt-features/auth/component/AuthInput';
import './LoginForm.scss';
/**
 * @typedef {object} ILoginForm
 * @property {({email:string,password:string,rememberMe:string})=>void} onSubmit
 * @property {()=>void} onForgotClick
 * @property {()=>void} onCompanyClick
 * @property {boolean} isLoading
 * @property {string} message
 * @property {()=>void} onDismissMessage
 *
 */

/**
 * @author Randa<m.randa@bvarta.com>
 * @author Bobi
 * @copyright PT Bhumi Varta Technology
 * @param {ILoginForm} props
 */
export default function LoginForm(props) {
  const {
    isLoading,
    onSubmit,
    onForgotClick,
    onCompanyClick,
    message,
    onDismissMessage,
  } = props;
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      rememberMe: false,
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email('E - Mail Not Valid')
        .required('E - Mail is required field'),
      password: yup.string().required('Password is required field'),
    }),
    onSubmit: (e) => {
      onSubmit && onSubmit(e);
    },
  });
  const isValidForm =
    !isLoading && formik.values.email && formik.values.password;
  return (
    <form
      className='LoginForm feature-auth LoginForm__form'
      onSubmit={(e) => {
        e.preventDefault();
        !isLoading && formik.submitForm();
      }}
    >
      <h2 className='feature-auth LoginForm__litya'>Login To Your Account</h2>
      <AuthInput
        error={formik.touched.email && formik.errors.email}
        icon='ic-email'
        onChange={(e) => formik.setFieldValue('email', e.target.value)}
        onClear={() => formik.setFieldValue('email', '')}
        title='Email Address'
        type='email'
        value={formik.values.email}
      />
      <AuthInput
        error={formik.touched.password && formik.errors.password}
        icon='ic-password'
        onChange={(e) => formik.setFieldValue('password', e.target.value)}
        onClear={() => formik.setFieldValue('password', '')}
        showShowHide
        title='Password'
        type='password'
        value={formik.values.password}
      />
      {message && (
        <Alert
          closable
          message={message}
          onClose={() => onDismissMessage()}
          type='error'
        />
      )}
      <div className='feature-auth LoginForm__remember-forgot-container'>
        <Checkbox
          checked={formik.values.rememberMe}
          onClick={() =>
            formik.setFieldValue('rememberMe', !formik.values.rememberMe)
          }
        >
          Remember Me
        </Checkbox>
        <div
          className='feature-auth LoginForm__remember-forgot-button'
          onClick={() => onForgotClick && onForgotClick()}
        >
          Forgot?
        </div>
      </div>
      <div className='feature-auth LoginForm__bottom-container'>
        <div
          className='feature-auth LoginForm__bottom-company'
          onClick={() => onCompanyClick && onCompanyClick()}
        >
          Bvarta.com
        </div>
        <button
          className={`
          feature-auth LoginForm__submit-button
          ${!isValidForm && ' feature-auth LoginForm__submit-button--not-valid'}
          ${isLoading && ' feature-auth LoginForm__submit-button--loading'}`}
          disabled={isLoading}
          formNoValidate
        >
          {isLoading ? (
            <svg
              height='16'
              style={{ marginTop: '2px', marginBottom: '2px' }}
              viewBox='0 0 14.229 14.229'
              width='16'
            >
              <path
                d='M9.115,14.1a4.98,4.98,0,1,0-4.98-4.98A4.98,4.98,0,0,0,9.115,14.1Zm0,2.134A7.115,7.115,0,1,0,2,9.115,7.115,7.115,0,0,0,9.115,16.229Z'
                fill='#fff'
                fillRule='evenodd'
                opacity='0.2'
                transform='translate(-2 -2)'
              />
              <path
                d='M2,9.115A7.115,7.115,0,0,1,9.115,2V4.134a4.98,4.98,0,0,0-4.98,4.98Z'
                fill='#fff'
                transform='translate(-2 -2)'
              />
            </svg>
          ) : (
            'Submit'
          )}
        </button>
      </div>
    </form>
  );
}
LoginForm.propTypes = {
  isLoading: PropTypes.oneOfType([PropTypes.bool, PropTypes.any]),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  onCompanyClick: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  onDismissMessage: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  onForgotClick: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  onSubmit: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};
