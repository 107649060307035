import React from 'react';

import { SharedBaseCardTypeOneComponent } from '@bvt-shared/component/SharedBaseCardTypeOne.component';
import './AsmanCardZoning.component.scss';

export const AsmanCardZoningComponent = props => {
  const { data, isLoading } = props;
  return (
    <SharedBaseCardTypeOneComponent
      isLoading={isLoading}
      showIcon
      title={
        <React.Fragment>
          <span>Zoning</span>
        </React.Fragment>
      }
    >
      <span className='AsmanCardZoningComponent__text'>
        According to zoning data, this area is a territory of
      </span>
      <div className='AsmanCardZoningComponent__content'>
        {data && data?.length > 1 ? (
          data.map((item) => (
            <div
              className='AsmanCardZoningComponent__content-item'
              key={item.name}
            >
              {item.name}
            </div>
          ))
        ) : (
          <div className='AsmanCardZoningComponent__content-item'>
            zoning data not available
          </div>
        )}
      </div>
    </SharedBaseCardTypeOneComponent>
  );
};
