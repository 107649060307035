import React from 'react';
import { Skeleton } from '@bvt-shared/component/Skeleton';
import './BaseCard.scss';

/**
 * @author Randa<m.randa@bvarta.com>
 * @author Renta <renta.yustika@bvarta.com>
 * @type {import('react').FC<{
 * title?:string
 * withPadding?:string
 * isLoading?:boolean
 * style?:React.CSSProperties
 * className?:string
 * skeletonHeight:number
 * }>}
 */
const BaseCard = (props) => {
  const { children, isLoading, title, withPadding, className, style, skeletonHeight = 256 } = props;
  return (
    <div className={`shared BaseCard${className ? ' ' + className : ''}`} style={style}>
      <div className='BaseCard__header'>
        <svg
          className='BaseCard__header-icon'
          height='16.167'
          viewBox='0 0 16.167 16.167'
          width='16.167'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M9.275,14.125h1.617V9.275H9.275ZM10.084,2a8.084,8.084,0,1,0,8.084,8.084A8.087,8.087,0,0,0,10.084,2Zm0,14.551a6.467,6.467,0,1,1,6.467-6.467A6.475,6.475,0,0,1,10.084,16.551ZM9.275,7.659h1.617V6.042H9.275Z'
            fill='#007e75'
            transform='translate(-2 -2)'
          />
        </svg>
        <div className='BaseCard__header-title'>{title || '-'}</div>
      </div>
      <div
        className={`BaseCard__content ${
          withPadding && 'BaseCard__content--with-padding'
        }`}
      >
        <Skeleton height={`${skeletonHeight}px`} loading={isLoading} width='100%'>
          {children}
        </Skeleton>
      </div>
    </div>
  );
};

BaseCard.propTypes = {};

export { BaseCard };
