import React from 'react';
import { Table } from 'antd';

import { SharedBaseCardTypeOneComponent } from '@bvt-shared/component/SharedBaseCardTypeOne.component';
import './AsmanCardTopPoiGet.component.scss';

const columns = [
  {
    title: 'No',
    dataIndex: 'key',
    key: 'key',
    width: 50,
    render(value, record, index) {
      return index + 1;
    }
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (_, { iconUrl, name }) => (
      <React.Fragment>
        <div className='AsmanCardTopPoiGetComponent__wrapper-name'>
          <img
            alt={name}
            src={iconUrl}
            style={{ width: '2rem', height: '2rem' }}
          />
          <div>{name}</div>
        </div>
      </React.Fragment>
    )
  },
  {
    title: 'Near',
    dataIndex: 'near',
    key: 'near',
    width: 200,
    className: 'AsmanCardTopPoiGetComponent__cell-near',
    sorter: (a, b) => (a?.near || 0) - (b?.near || 0),
    render: (_, { near }) => near?.toLocaleString('id') + ' m'
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    width: 150,
    sorter: (a, b) => (a?.total || 0) - (b?.total || 0),
    render: (_, { total }) => <div>{total?.toLocaleString('id')}</div>,
    showSorterTooltip: false
  }
];

export const AsmanCardTopPoiGetComponent = props => {
  const { data, isLoading } = props;
  return (
    <SharedBaseCardTypeOneComponent
      isLoading={isLoading}
      showIcon
      title={<span>Top 10 POI Get</span>}
    >
      <Table
        className='AsmanCardTopPoiGetComponent'
        columns={columns}
        dataSource={data}
        pagination={false}
        rowClassName='AsmanCardTopPoiGetComponent__row'
      />
    </SharedBaseCardTypeOneComponent>
  );
};
