import Resource from '../../../../api/resource';

export class ConsumePoiService extends Resource {
  constructor() {
    super('', '/api', 'api-poi', 'v2');
  }

  getListIndustries() {
    this.setAdditionUri(`/industry`);
    return this.list();
  }

  GetGroupsAndCategoriesByIndustries(id) {
    this.setAdditionUri('/industries/' + id);
    return this.list();
  }

  GetCategories({ id }) {
    this.setAdditionUri('/categories/' + id);
    return this.list();
  }

}
