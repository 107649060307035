import React from 'react';
import { useGRIANA } from '../../hook';
import GeoJsonGrianaContainer from '../GeoJsonGrianaContainer';
import PoiCollectionsGrianaContainer from '../PoiCollectionsGrianaContainer';

const GrianaMapContainer = () => {
  const GRIANA = useGRIANA();

  return (
    <React.Fragment>
      {GRIANA.state?.IS_SHOWN_ON_MAP ? (
        <React.Fragment>
          <GeoJsonGrianaContainer />
          <PoiCollectionsGrianaContainer />
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export { GrianaMapContainer };
