import Resource from '../../../../api/resource';

export class MainMapPlaceService extends Resource {
  constructor() {
    super('places', '/api', 'api-poi', 'v2');
  }
  /**
   *
   * place service
   * @param {object} params - Params for get list place
   */

  get_all(query) {
    this.setAdditionUri('');
    const paramString = JSON.stringify(query.params);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }

  get_country(query) {
    this.setAdditionUri('/country');
    const paramString = JSON.stringify(query.params);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }
  get_province_inter(query) {
    this.setAdditionUri('/region-province');
    const paramString = JSON.stringify(query.params);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }
  get_city_inter(query) {
    this.setAdditionUri('/prefecture-district');
    const paramString = JSON.stringify(query.params);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }
  get_district_Inter(query) {
    this.setAdditionUri('/district-commune');
    const paramString = JSON.stringify(query.params);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }
  get_province(query) {
    this.setAdditionUri('/province');
    const paramString = JSON.stringify(query.params);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }

  get_city(query) {
    this.setAdditionUri('/city');
    const paramString = JSON.stringify(query.params);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }
  get_district(query) {
    this.setAdditionUri('/district');
    const paramString = JSON.stringify(query.params);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }
  get_village(query) {
    this.setAdditionUri('/village');
    const paramString = JSON.stringify(query.params);

    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }
  get_advance(query) {
    this.setAdditionUri('/advance-search');
    const paramString = JSON.stringify(query.params);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }
  get_advance_inter(query) {
    this.setAdditionUri('/advance-search-internasional');
    const paramString = JSON.stringify(query.params);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }
  get_coordinates(query) {
    this.setAdditionUri('/coordinates');
    const paramString = JSON.stringify(query);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }
  get_search(query) {
    this.setAdditionUri('/search');
    const paramString = JSON.stringify(query.params);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }
}
