import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Faisal <ahmad.faisal@bvarta.com>
 * @param {Object} props
 * @param {string} props.className
 * @param {Function} props.onClick
 */

export default function IconPdf(props) {
  const { className, onClick } = props;

  return (
    <div className={`${className} iconPdf`} onClick={onClick}>
      <svg
        height='18.17'
        id='_1577460672'
        viewBox='0 0 15.033 18.17'
        width='15.033'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M10.4,2.7V4.624h1.925L10.4,2.7ZM.792-.008H9.938l5.1,5.1V18.16H0V-.01H.792ZM8.817,1.577H1.587v15H13.449V6.209H8.817V1.577Z'
          data-name='Path 752'
          fill='#4d4f5c'
          id='Path_752'
          transform='translate(0 0.01)'
        />
        <path
          d='M6.94,47.83H18.8v5.664H6.94Z'
          data-name='Path 753'
          fill='#cc0001'
          fillRule='evenodd'
          id='Path_753'
          transform='translate(-5.356 -36.909)'
        />
        <path
          d='M13.92,56.778V53.87h.943a3.4,3.4,0,0,1,.7.043.759.759,0,0,1,.418.285.907.907,0,0,1,.169.566.956.956,0,0,1-.1.45.829.829,0,0,1-.247.288.876.876,0,0,1-.3.137,3.376,3.376,0,0,1-.607.041h-.381v1.1h-.589Zm.589-2.418v.829h.32a1.539,1.539,0,0,0,.463-.046A.37.37,0,0,0,15.475,55a.393.393,0,0,0,.066-.226.381.381,0,0,0-.094-.263.4.4,0,0,0-.237-.13,2.687,2.687,0,0,0-.42-.021h-.283Zm2.639-.491h1.071a2.108,2.108,0,0,1,.552.055.98.98,0,0,1,.438.267,1.278,1.278,0,0,1,.279.473,2.126,2.126,0,0,1,.094.687,1.914,1.914,0,0,1-.089.619,1.276,1.276,0,0,1-.31.514,1.007,1.007,0,0,1-.411.233,1.77,1.77,0,0,1-.521.062h-1.1V53.87Zm.589.491v1.927h.436a1.622,1.622,0,0,0,.354-.027.534.534,0,0,0,.237-.121.6.6,0,0,0,.153-.281,1.846,1.846,0,0,0,.059-.532,1.677,1.677,0,0,0-.059-.516.669.669,0,0,0-.164-.283.559.559,0,0,0-.272-.137A2.661,2.661,0,0,0,18,54.363h-.263ZM20.6,56.778V53.87h2v.491H21.191v.692H22.41v.491H21.191v1.235H20.6Z'
          data-name='Path 754'
          fill='#fff'
          id='Path_754'
          transform='translate(-10.742 -41.57)'
        />
      </svg>
    </div>
  );
}

IconPdf.defaultProps = {
  className: '',
  onClick: () => {},
};

IconPdf.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
