import Resource from '../../../../api/resource';

export class MainMapMenuBarService extends Resource {
  constructor() {
    super('project', '/api', 'api-poi', 'v2');
  }
  /**
   * @param {number} id
   */
  getDetail(id) {
    return this.get(id);
  }
}
