import { HistoryLogActiviesService } from '../service';
import { HISTORY_LOG_ACTIVITY_CONSTANT } from './HISTORY_LOG_ACTIVITY_CONSTANT';
const svc = new HistoryLogActiviesService();
export const HISTORY_LOG_ACTIVITY_ACTION = {
  getListHistory: (param) => async (dispatch) => {
    let request = {
      meta: {
        filter: {
          start_date: param.filter.start_date,
          end_date: '',
          level: '',
          message: '',
          project_name: '',
          module_name: '',
          activity: '',
        },
        limit: param.limit,
        page: param.page,
        search: '',
        sort: { by: '', name: '' },
      },
    };
    dispatch({
      type: HISTORY_LOG_ACTIVITY_CONSTANT.GET_LIST_REQUESTED,
      payload: {
        status_GET: 'LOADING',
      },
    });
    try {
      let data = await svc.doGetList(request);
      param.total = data.meta.total;
      dispatch({
        type: HISTORY_LOG_ACTIVITY_CONSTANT.GET_LIST_SUCCESS,
        payload: {
          status_GET: 'SUCCESS',
          data: data.results || [],
          meta: param,
        },
      });
    } catch (error) {
      dispatch({
        type: HISTORY_LOG_ACTIVITY_CONSTANT.GET_LIST_FAILED,
        payload: {
          status_GET: 'FAILED',
          meta: param,
        },
      });
    }
  },
};
