import React from 'react';
import { DynamicIconType } from '@bvt-types';
import loadable from '@loadable/component';

export default function DynamicIcon(props) {
  const { className, height, icon, width, color } = props;
  const Component = loadable(() => import(`./${icon}.js`));

  return (
    <Component
      className={className}
      color={color}
      height={height}
      width={width}
    />
  );
}
DynamicIcon.defaultProps = {
  height: '15.13',
  width: '15.13',
};

DynamicIcon.propTypes = DynamicIconType;
