import Resource from '../../resource';
class User extends Resource {
  constructor() {
    let baseUrl = '/api';
    let gateWay = 'auth';
    super('', baseUrl, gateWay);
  }

  /**
   * user do login.
   * @param {Object} data - Data for login.
   * @param {string} data.email_username - The username or email.
   * @param {string} data.password - The password.
   */
  doLogin(data = {}) {
    this.setAdditionUri('users/login');
    return this.create(data);
  }

  doLogout() {
    this.setAdditionUri('users/logout');
    return this.create();
  }

  doCheckProfile(data = {}) {
    this.setAdditionUri('users/profile');
    return this.mockHttpRequest(data, 1000, false);
  }

  doForgotPassword(data = {}) {
    this.setAdditionUri('/forgot-password');
    return this.mockHttpRequest(data, 1000, false);
  }
  doResetPassword(data = {}) {
    this.setAdditionUri('/reset-password');
    return this.mockHttpRequest(data, 1000, false);
  }

  checkToken() {
    this.setAdditionUri('check-token');
    return this.list({});
  }

  refreshToken(data = { refreshToken: '' }) {
    this.setAdditionUri('refresh-token');
    return this.create(data);
  }
}

export default User;
