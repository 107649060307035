import * as React from 'react';

/**
 * @param {Object} props
 * @param {String} props.stroke
 */
const IconSearch = (props) => {
  return (
    <svg
      fill='none'
      height={14}
      viewBox='0 0 14 14'
      width={14}
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13.125 13.625L10.3212 10.8163L13.125 13.625ZM11.875 7.0625C11.875 8.47146 11.3153 9.82272 10.319 10.819C9.32272 11.8153 7.97146 12.375 6.5625 12.375C5.15354 12.375 3.80228 11.8153 2.806 10.819C1.80971 9.82272 1.25 8.47146 1.25 7.0625C1.25 5.65354 1.80971 4.30228 2.806 3.306C3.80228 2.30971 5.15354 1.75 6.5625 1.75C7.97146 1.75 9.32272 2.30971 10.319 3.306C11.3153 4.30228 11.875 5.65354 11.875 7.0625V7.0625Z'
        stroke={props?.stroke || '#A0A6A9'}
        strokeLinecap='round'
        strokeWidth={2}
      />
    </svg>
  );
};
export { IconSearch };
