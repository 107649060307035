import INTERACTIVE_MAP_CONSTANT from '../constant';

const initialState = {
  tileLayer: {
    url: 'https://api.mapbox.com/styles/v1/mapbox/light-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw',
    title: 'Light',
    mode: 'defaultSetting',
  },
  defaultTheme: 'light',
  defaultMap: 'defaultSetting',
};

export function TILE_LAYER(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case INTERACTIVE_MAP_CONSTANT.TILELAYER:
      return payload;
    case 'TILE_LAYER/SET/TILE_LAYER':
      return {
        ...state,
        tileLayer: payload,
      };
    case 'TILE_LAYER/SET/DEFAULT_THEME':
      return {
        ...state,
        defaultTheme: payload,
      };
    case 'TILE_LAYER/SET/DEFAULT_MAP':
      return {
        ...state,
        defaultMap: payload,
      };
    default:
      return state;
  }
}
