import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
/**
 * @copyright Bhumi Varta Technology
 * @author Randa <m.randa@bvarta.com>
 * @version 1
 * @memberof molecules
 * @param {Object} props
 * @param {string} props.className
 * @param {import('react').StyleHTMLAttributes} props.style
 * @param {string} props.currentActive
 * @param {Array<{key:string,value:string,render:()=>JSX.Element,variant:'default'|'new',isDisabled:boolean}>} props.item
 * @param {string} props.with
 * @param {Object} props.handler
 * @param {(key:string)=>void} props.handler.onChange
 */
function SubMenu(props) {
  const [navHeight, setNavHeight] = useState(0);
  const [active, setActive] = useState('');

  useEffect(() => {
    if (props.currentActive) {
      setActive(props.currentActive);
    }
  }, [props.currentActive]);

  const internalHandleChange = (ev) => {
    setActive(ev);
    if (props.handler?.onChange) {
      props.handler.onChange(ev);
    }
  };

  const interNalSetNavHeight = () => {
    let headSizing = document.querySelectorAll('.mn-head')[0]
      ? document.querySelectorAll('.mn-head')[0]?.clientHeight
      : 0;
    let headerSegmentSizing = document.querySelectorAll('.header-segment')[0]
      ? document.querySelectorAll('.header-segment')[0]?.clientHeight
      : 0;
    let windowSizing = window.innerHeight;
    setNavHeight(windowSizing - headSizing - headerSegmentSizing - 32);
  };

  useEffect(() => {
    interNalSetNavHeight();
    window.addEventListener('resize', interNalSetNavHeight);
    return () => {
      window.removeEventListener('resize', interNalSetNavHeight);
    };
  }, []);
  return (
    <div
      className={`SubMenu__wrapper ${props.className}`}
      style={{ ...{ height: navHeight + 'px' }, ...props.style }}
    >
      {props.item &&
        props.item.map((ctx) => (
          <SubMenuItem
            handler={{ onClick: () => internalHandleChange(ctx.key) }}
            isActive={active === ctx.key}
            isDisabled={ctx.isDisabled}
            key={ctx.key}
            render={ctx.render}
            value={ctx.value}
            variant={ctx.variant}
          />
        ))}
    </div>
  );
}

/**
 * @copyright Bhumi Varta Technology
 * @author Randa <m.randa@bvarta.com>
 * @version 1
 * @memberof molecules
 * @param {Object} props
 * @param {string} props.className
 * @param {import('react').StyleHTMLAttributes} props.style
 * @param {()=>JSX.Element} props.render
 * @param {'default'|'new'} props.variant
 * @param {boolean} props.isDisabled
 */
function SubMenuItem(props) {
  const [isHover, setIsHover] = useState(false);
  const CustRender = props.render;
  return (
    <React.Fragment>
      {props.variant === 'new' && (
        <div className='SubMenu__item--variant-new-divider' />
      )}
      <div
        className={`SubMenu__item SubMenu__item--variant-${
          props.variant || 'default'
        } ${(isHover || props.isActive) && 'hover-active'} ${
          props.isDisabled && 'isDisabled'
        }`}
        onClick={() => !props.isDisabled && props.handler.onClick()}
        onMouseEnter={() => !props.isDisabled && setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <div className='SubMenu__item-child'>
          {CustRender ? <CustRender value={props.value} /> : props.value}
        </div>
      </div>
    </React.Fragment>
  );
}

SubMenu.propTypes = {
  className: PropTypes.string,
  currentActive: PropTypes.string,
  handler: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  item: PropTypes.arrayOf(
    PropTypes.shape({
      isDisabled: PropTypes.bool,
      key: PropTypes.string,
      value: PropTypes.string,
      render: PropTypes.func,
      variant: PropTypes.oneOf(['default', 'new']),
    })
  ),
  style: PropTypes.object,
};

SubMenu.defaultProps = {
  className: '',
  currentActive: '',
  handler: {
    onChange: () => {},
  },
  item: [],
  style: {},
};

SubMenuItem.propTypes = {
  handler: PropTypes.shape({
    onClick: PropTypes.func,
  }),
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  render: PropTypes.func,
  value: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'new']),
};

SubMenuItem.defaultProps = {
  handler: {
    onClick: () => {},
  },
  isActive: false,
  isDisabled: false,
  render: undefined,
  value: '',
  variant: 'default',
};

export default SubMenu;
