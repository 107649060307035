import { MAINMAP_PLACE_CONSTANT } from './MAINMAP_PLACE_CONSTANT';

const initialState = {
  status_GET: 'IDLE',
  getall: {
    status_GET: 'IDLE',
    list: [],
    message: '',
    properties: {}
  },
  search: [],
  country: {},
  province: {},
  city: {},
  district: {},
  village: {},
  advance: {},
  country_mandala:{
    'country_id': 1,
    'country_code': 'IND',
    'country_name': 'Indonesia'
  },
  params: {
    provinsi: null,
    kota: null,
    kecamatan: null,
    desa: null,
  },
  geojson: {
    provinsi: null,
    kota: null,
    kecamatan: null,
    desa: null,
  },
  coordinateDetail: {},
  isAdvanceSearchDisabled: false,
  isChangeCountryDisabled: false,
};

const getVillage = (payload, STATE, elseValue) => {
  return Object.keys(payload)[0] === 'desa' ? payload.desa : elseValue;
};

const getDistrict = (payload, STATE, elseValue) => {
  return Object.keys(payload)[0] === 'kecamatan' ? payload.kecamatan : elseValue;
};

const getCity = (payload, STATE, elseValue) => {
  return Object.keys(payload)[0] === 'kota' ? payload.kota : elseValue;
};

const getProvince = (payload, STATE, elseValue) => {
  return Object.keys(payload)[0] === 'provinsi' ? payload.provinsi : elseValue;
};

export function MAINMAP_PLACE_REDUCER(state = initialState, action) {
  const { payload, type } = action;
  let STATE = { ...state };
  switch (type) {
    case MAINMAP_PLACE_CONSTANT.SET_GEOJSON:
      return {
        ...state,
        geojson: {
          ...state.geojson,
          provinsi: getProvince(payload, STATE, STATE.geojson.provinsi),
          kota: getCity(payload, STATE, STATE.geojson.kota),
          kecamatan: getDistrict(payload, STATE, STATE.geojson.kecamatan),
          desa: getVillage(payload, STATE, STATE.geojson.desa),
        },
      };
    case MAINMAP_PLACE_CONSTANT.SET_COUNTRY_MANDALA:
      return {
        ...state,
        country_mandala: payload
      };
    case MAINMAP_PLACE_CONSTANT.SET_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          provinsi:
            payload.type === 'provinsi' ? payload : STATE.params.provinsi,
          kota: payload.type === 'kota' ? payload : STATE.params.kota,
          kecamatan:
            payload.type === 'kecamatan' ? payload : STATE.params.kecamatan,
          desa: payload.type === 'desa' ? payload : STATE.params.desa,
        },
      };
    case MAINMAP_PLACE_CONSTANT.REMOVE_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          provinsi: getProvince(payload, STATE, STATE.params.provinsi),
          kota: getCity(payload, STATE, STATE.params.kota),
          kecamatan: getDistrict(payload, STATE, STATE.params.kecamatan),
          desa: getVillage(payload, STATE, STATE.params.desa),
        },
      };
    case MAINMAP_PLACE_CONSTANT.RESET_GET_ALL:
      return {
        ...state,
        getall: {
          status_GET: 'IDLE',
          list: [],
          message: '',
          properties: {}
        },
      };
  }
  let returnData = state;
  Object.values(MAINMAP_PLACE_CONSTANT).forEach((ctx) => {
    if (action.type === MAINMAP_PLACE_CONSTANT.RESET_STATUS) {
      returnData = {
        ...returnData,
        status_GET: 'IDLE',
        message: '',
      };
    }else if (action.type === MAINMAP_PLACE_CONSTANT.RESET_ALL) {
      returnData = { ...initialState,country_mandala:STATE.country_mandala };
    }else if (ctx === action.type && !ctx.includes('[NO_STATE_UPDATE]')) {
      returnData = { ...returnData, ...action.payload };
    }
  });

  return returnData;
}
