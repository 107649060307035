import Resource from '../../../../../api/resource';

export class SiproService extends Resource {
  constructor() {
    super('grid-analyze', '/api', 'api-poi', 'v2');
  }

  saveProject(param) {
    this.version = 'v2';
    this.uri = 'project';
    this.additonalUri = '';
    return this.create(param);
  }

  doAnalyze(param) {
    this.version = 'v2';
    this.uri = 'site-profiling';
    this.additonalUri = '/do-analyze/v3';
    return this.create(param);
  }

  doAnalyzeMandala(param) {
    this.version = 'v2';
    this.uri = 'site-profiling';
    this.additonalUri = '/do-analyze/internasional';
    return this.create(param);
  }

  /**
   *
   * @param {object} filter
   * @param {number} filter.project_id
   * @param {string} filter.process_id
   * @param {number} filter.page
   * @param {number} filter.pageSize
   */
  siteAreaCollections(filter) {
    const remapFilter = {
      meta: {
        filter: {
          process_id: filter.process_id,
          project_id: filter.SiproService,
        },
        pageSize: filter.pageSize,
        page: filter.page,
        search: filter.search,
      },
    };
    const paramString = JSON.stringify(remapFilter);
    const params = encodeURIComponent(paramString);
    this.version = 'v2';
    this.uri = 'site-profiling';
    this.setAdditionUri('/area-collection');
    return this.list({ params });
  }

  /**
   *
   * @param {object} filter
   * @param {number} filter.project_id
   * @param {string} filter.process_id
   * @param {number} filter.page
   * @param {number} filter.limit
   * @param {number} filter.gid
   */
  sitePoiCollections(filter) {
    const remapFilter = {
      meta: {
        filter: {
          process_id: filter.process_id,
          project_id: filter.SiproService,
        },
        pageSize: filter.pageSize,
        page: filter.page,
        search: filter.search,
      },
    };
    this.uri = 'site-profiling';
    this.setAdditionUri('/poi-collection');
    this.version = 'v2';
    const paramString = JSON.stringify(remapFilter);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }
}
