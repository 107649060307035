import React from 'react';
import GensetLayout from '@bvt-features/genset/component/GensetLayout';
import Page from '../../../pages/bvt';
import AuthGateContainer from '@bvt-features/auth/container/AuthGateContainer';
import { BasePageHeadTag } from '@bvt-shared/component/BasePageHeadTag';
import MenuBarContainer from '@bvt-features/mainmap/menu-bar/container/MenuBarContainer';

export const RoutesAppMenu = {
  path: 'menu',
  element: (
    <React.Fragment>
      <BasePageHeadTag title='LI :: Menu' />
      <div style={{ overflow: 'hidden' }}>
        <MenuBarContainer />
        <AuthGateContainer />
      </div>
    </React.Fragment>
  ),
  children: [
    {
      index: true,
    },
    {
      path: 'parameter-setup',
      element: (
        <React.Fragment>
          <BasePageHeadTag title='LI :: Menu :: Parameter Setup' />
          <GensetLayout>
            <AuthGateContainer />
          </GensetLayout>
        </React.Fragment>
      ),
      children: [
        {
          index: true,
          element: <Page.GeneralSetup />,
        },
        {
          path: 'poi',
          element: (
            <React.Fragment>
              <BasePageHeadTag title='LI :: Parameter Setup :: POI' />
              <Page.GeneralSetupPOI />
            </React.Fragment>
          ),
        },
        {
          path: 'thematic',
          element: (
            <React.Fragment>
              <BasePageHeadTag title='LI :: Parameter Setup :: Thematic' />
              <Page.GensetParameter />
            </React.Fragment>
          ),
        },
        {
          path: 'poi/create',
          element: (
            <React.Fragment>
              <BasePageHeadTag title='LI :: Parameter Setup :: POI - Create' />
              <Page.GeneralSetupPOIForm />
            </React.Fragment>
          ),
        },
        {
          path: 'poi/edit/:id',
          element: (
            <React.Fragment>
              <BasePageHeadTag title='LI :: Parameter Setup :: POI - Edit' />
              <Page.GeneralSetupPOIEditForm />
            </React.Fragment>
          ),
        },
        {
          path: 'thematic/create',
          element: (
            <React.Fragment>
              <BasePageHeadTag title='LI :: Parameter Setup :: Thematic - Create' />
              <Page.GensetParameterForm />
            </React.Fragment>
          ),
        },
        {
          path: 'thematic/edit/:id',
          element: (
            <React.Fragment>
              <BasePageHeadTag title='LI :: Parameter Setup :: POI - Edit' />
              <Page.GensetParameterEdit />
            </React.Fragment>
          ),
        },
      ],
    },
  ],
};
