/* eslint-disable max-len */
import React, { useRef, useState } from 'react';
import { IconClose } from '@bvt-icons';
import './LayoutDatexSidebarWrapper.scss';
import { Button } from 'antd';
import PropTypes from 'prop-types';
/**
 * @copyright Bhumi Varta Technology
 * @author Malik<malik.ibrahim@bvarta.com>
 * @param {object} props
 * @param {String} props.className
 * @param {import('react').CSSProperties} props.style
 * @param {Object} props.step
 * @param {Number} props.step.number
 * @param {String} props.step.title
 * @param {String} props.step.info
 * @param {String} props.height
 * @param {JSX.Element} props.children
 * @param {Function} props.onAddLayer
 * @param {Function} props.onBack
 * @param {Function} props.onClose
 * @param {Function} props.onMouseEnter
 * @param {Function} props.onMouseLeave
 * @param {Function} props.onNext
 * @param {Boolean} props.isNextShow
 * @param {Boolean} props.isBackShow
 * @param {Boolean} props.isNextDisabled
 * @param {Boolean} props.isBackDisabled
 * @param {Boolean} props.isShowButton
 * @param {Boolean} props.isBackShow
 * @param {Boolean} props.isActive
 * @param {Boolean} props.isAddDisabled
 * @param {String} props.cancelText
 * @param {String} props.nextText
 */

const LayoutDatexSidebarWrapper = (props) => {
  const {
    className,
    onMouseEnter,
    onMouseLeave,
    style,
    onClose,
    step,
    height,
    children,
    isNextShow,
    isBackShow,
    isNextDisabled,
    isBackDisabled,
    isShowButton,
    isActive,
    isAddDisabled,
    onNext,
    onBack,
    onAddLayer,
    cancelText,
    nextText,
  } = props;
  const elRef = useRef();
  const [isToolTipOpen, setIsToolTipOpen] = useState(false);

  return (
    <div
      className={'feature-map LayoutDatexSidebarWrapper' + className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={elRef}
      style={{
        ...style,
        height: height,
        transition: 'all 0.3s ease-in-out 0s',
        transform: isActive
          ? 'none'
          : `translateX(${elRef.current?.clientWidth}rem)`,
      }}
    >
      <div className='feature-map LayoutDatexSidebarWrapper__header'>
        <div className='feature-map LayoutDatexSidebarWrapper__header__title'>
          <p className='feature-map LayoutDatexSidebarWrapper__header__title__text'>
            Data Explorer
          </p>
          <IconClose
            background='white'
            className='feature-map LayoutDatexSidebarWrapper__header__title__btn'
            onClick={onClose}
          />
        </div>

        {step?.number === 0 && (
          <div className='feature-map LayoutDatexSidebarWrapper__header__subtitle'>
            <span className='feature-map LayoutDatexSidebarWrapper__header__subtitle__text'>
              Click “+ Add Layer Data” to start displaying layers on the map.
              You can explore up to 5 layers.
            </span>
            <Button
              className={`feature-map LayoutDatexSidebarWrapper__header__subtitle__btn${
                isAddDisabled ? '--disabled' : ''
              }`}
              disabled={isAddDisabled}
              onClick={onAddLayer}
            >
              + Add Layer Data
            </Button>
          </div>
        )}
        <div className='feature-map LayoutDatexSidebarWrapper__header__subheader'>
          <div className='feature-map LayoutDatexSidebarWrapper__header__subheader-left'>
            {step?.number > 0 && (
              <span className='feature-map LayoutDatexSidebarWrapper__header__subheader-left__step'>
                {step?.number}
              </span>
            )}
            <span className='feature-map LayoutDatexSidebarWrapper__header__subheader-left__title'>
              {step?.title}
            </span>
          </div>
          <div
            className={`feature-map LayoutDatexSidebarWrapper__header__subheader-right mainmap-sl-step-name-info-wrapper ${
              isToolTipOpen &&
              'feature-map LayoutDatexSidebarWrapper__header__subheader-right mainmap-sl-step-name-info-wrapper--active'
            }`}
          >
            <div
              className={`feature-map LayoutDatexSidebarWrapper__header__subheader-right mainmap-sl-step-name-info ${
                isToolTipOpen &&
                'feature-map LayoutDatexSidebarWrapper__header__subheader-right mainmap-sl-step-name-info--active'
              }`}
              // onBlur={() => setIsToolTipOpen(false)}
              onClick={() => {
                setIsToolTipOpen((previousClick) => {
                  return !previousClick;
                });
              }}
              tabIndex={-1}
            >
              {isToolTipOpen ? (
                <svg
                  height='24px'
                  viewBox='0 0 12.263 12.263'
                  width='24px'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M3.153,7.489V5.518H1.183a1.182,1.182,0,1,1,0-2.364h1.97V1.183a1.183,1.183,0,1,1,2.365,0V3.154H7.489a1.182,1.182,0,1,1,0,2.364H5.518V7.489a1.183,1.183,0,1,1-2.365,0Z'
                    data-name='Union 24'
                    fill='#616477'
                    id='Union_24'
                    transform='translate(6.132) rotate(45)'
                  />
                </svg>
              ) : (
                <svg
                  height='24px'
                  viewBox='0 0 24 24'
                  width='24px'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect fill='currentColor' height='24' rx='11' width='24' />
                  <g id='Group_8658' transform='translate(-371.36 -213.976)'>
                    <path
                      d='M-5981.013,23530.867a1.427,1.427,0,0,1-1.485-1.365v-4.773a1.425,1.425,0,0,1,1.485-1.359h.747a1.424,1.424,0,0,1,1.484,1.359v4.773a1.426,1.426,0,0,1-1.484,1.365Zm0-8.457a1.429,1.429,0,0,1-1.485-1.365v-.68a1.43,1.43,0,0,1,1.485-1.367h.747a1.429,1.429,0,0,1,1.484,1.367v.68a1.429,1.429,0,0,1-1.484,1.365Z'
                      fill='#fff'
                      transform='translate(6364 -23298.957)'
                    />
                  </g>
                </svg>
              )}
              <div className='feature-map LayoutDatexSidebarWrapper__header__subheader-right mainmap-sl-step-name-info-popup'>
                {step?.info}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='feature-map LayoutDatexSidebarWrapper__body'>
        {children}
      </div>
      <div
        className='feature-map LayoutDatexSidebarWrapper__footer'
        style={{
          justifyContent: step?.number === 0 ? 'flex-end' : 'space-between',
        }}
      >
        {isBackShow && (
          <Button
            className={`feature-map LayoutDatexSidebarWrapper__footer-btn-back${
              isBackDisabled ? '-disabled' : ''
            }`}
            disabled={isBackDisabled}
            onClick={onBack}
          >
            {cancelText}
          </Button>
        )}
        {isNextShow && (
          <Button
            className={`feature-map LayoutDatexSidebarWrapper__footer-btn-next${
              isNextDisabled ? '-disabled' : ''
            }`}
            disabled={isNextDisabled}
            onClick={onNext}
          >
            {isShowButton ? 'Show' : nextText}
          </Button>
        )}
      </div>
    </div>
  );
};

LayoutDatexSidebarWrapper.propTypes = {
  activeMenu: PropTypes.string,
  children: PropTypes.element,
  className: PropTypes.string,
  height: PropTypes.string,
  onClose: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  ref: PropTypes.any,
  step: PropTypes.object,
  style: PropTypes.object,
};

export { LayoutDatexSidebarWrapper };
