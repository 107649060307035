import React from 'react';
import IMAGE_CABINET from '@bvt-assets/images/CabinetIllustration.png';

export default function DatexPoiNoData() {
  return (
    <div className='no_data'>
      <img
        alt='Cabinet i'
        src={IMAGE_CABINET}
      />
      <span className='no_data__title'>
        Brand name not found
      </span>
      <span className='no_data__desc'>
        Try using a different search term
      </span>
    </div>
  );
}
