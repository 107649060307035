import React from 'react';

/**
 * @company PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

/**
 * @typedef {Object} IPropsCircleSymbol
 * @property {string} color
 * @property {string} borderColor
 */

/**
 * @author Renta<renta.yustika@bvarta.com>
 * @param {object} props
 * @param {IPropsCircleSymbol} props.data
 * @returns
 */
export const CircleSymbol = (props) => {
  const {
    data: { color, borderColor },
  } = props;
  return (
    <div
      style={{
        height: '1rem',
        width: '1rem',
        borderRadius: 999999,
        backgroundColor: color ? color : '#03D85D',
        border: `1px solid ${borderColor ? borderColor : '#03D85D'}`,
      }}
    />
  );
};
