import INTERACTIVE_MAP_CONSTANT from '../constant';

const initialState = {
  set: {},
};

export function DIRECTION(state = initialState, action) {
  if (action.type === INTERACTIVE_MAP_CONSTANT.DIRECTION) {
    return action.payload;
  } else {
    return state;
  }
}
