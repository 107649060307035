import React from 'react';
import { render } from 'react-dom';
import App, { default as NewApp } from './App';
import { rootStore } from './redux/rootStore';

render(
  <App history={history} store={rootStore} />,
  document.getElementById('app')
);

moduleHotAccept(module);

export function moduleHotAccept(mod) {
  if (mod.hot) {
    mod.hot.accept('./App', () => {
      render(
        <NewApp history={history} store={rootStore} />,
        document.getElementById('app')
      );
    });
  }
}
