import * as React from 'react';
const IconChart = (props) => (
  <svg
    fill='none'
    height={16}
    viewBox='0 0 16 16'
    width={16}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M2.66675 13.3334V6.00008H5.33341V13.3334H2.66675ZM6.66675 13.3334V2.66675H9.33342V13.3334H6.66675ZM10.6667 13.3334V8.66675H13.3334V13.3334H10.6667Z'
      fill='#6C757D'
    />
  </svg>
);
export { IconChart };
