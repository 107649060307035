export const STEP_CONSTANT = {
  CURRENT_STEP: 'LI/MAPP/STEP/step',
  STEP_CODE: 'LI/MAPP/STEP/code',

  GET_REQUESTED: 'LI/MAPP/STEP/get-by-code/requested',
  GET_SUCCESS: 'LI/MAPP/STEP/get-by-code/success',
  GET_FAILED: 'LI/MAPP/STEP/get-by-code/failed',

  RESET_STATUS: 'LI/MAPP/STEP/reset-status',
  RESET_ALL: 'LI/MAPP/STEP/reset-all',
};
