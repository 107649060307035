import { LEGACY_AUTH_CONSTANT } from '@bvt-features/auth/redux';
import { MAINMAP_MAP_MENUBAR_CONSTANT } from './MAINMAP_MAP_MENUBAR_CONSTANT';

const initialState = {
  status_GET: 'IDLE',
  message: '',
  topTitle: '',
};

export function MAINMAP_MAP_MENUBAR_REDUCER(state = initialState, action) {
  let returnData = state;
  Object.values(MAINMAP_MAP_MENUBAR_CONSTANT).map((ctx) => {
    if (action.type === MAINMAP_MAP_MENUBAR_CONSTANT.RESET_STATUS) {
      returnData = {
        ...returnData,
        status_GET: 'IDLE',
        message: ' ',
      };
    } else if (action.type === MAINMAP_MAP_MENUBAR_CONSTANT.RESET_ALL) {
      returnData = { ...initialState };
    } else if (action.type === LEGACY_AUTH_CONSTANT.UNAUTHORIZED) {
      returnData = { ...initialState };
    } else if (ctx === action.type && !ctx.includes('[NO_STATE_UPDATE]')) {
      returnData = { ...returnData, ...action.payload };
    }
    return null;
  });

  return returnData;
}
