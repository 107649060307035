import INTERACTIVE_MAP_CONSTANT from '../constant';


const initialState = {
  zoom: 5,
  animate: true,
  attributionControl: false,
  center: [-0.263671, 118.564453],
  lat: '-0.263671',
  lng: '118.564453',
  className: 'map-container',
  fadeAnimation: true,
  maxZoom: 18,
  maxBounds: [
    [-16.59408141271846, 63.25195312500001],
    [16.088042220148818, 163.83300781250003],
  ],
  maxBoundsViscosity: 1.0,
  minZoom: 5,
  keyboard: true,
  keyboardPanDelta: 20,
  trackResize: true,
  scrollWheelZoom: true,
  doubleClickZoom: false,
  zoomControl: false,
  zoomAnimation: true,
};

export function BASE_LAYER(state = initialState, action) {
  switch (action.type) {
    case INTERACTIVE_MAP_CONSTANT.BASELAYER:
      return action.payload;
    case 'SET_ZOOM':
      return {
        ...state,
        zoom: action.payload,
      };
    case 'SET_LAT':
      return {
        ...state,
        lat: action.payload,
      };
    case 'SET_LNG':
      return {
        ...state,
        lng: action.payload,
      };
    default:
      return state;
  }
}
