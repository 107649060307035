

import { useDispatch, useSelector } from 'react-redux';
import { advanceSearchSlice } from '../../store';
import { advanceSearchAsyncActionDistrict, advanceSearchAsyncActionGeoJson, advanceSearchAsyncActionGetProvince, advanceSearchAsyncActionGetRegency, advanceSearchAsyncActionSearch, advanceSearchAsyncActionVillage } from '../../store/advanceSearch.asyncAction';

const useAdvanceSearch = () => {
  const state = useSelector((state) => state.features.SUBMODULE.advanceSearch);
  const dispatch = useDispatch();

  const toggleModalOpen = (v) =>
    dispatch(
      advanceSearchSlice.actions.toggleAdvanceSearchModal({
        isAdvanceModalOpen: v,
      })
    );

  const getProvince = (param) => dispatch(advanceSearchAsyncActionGetProvince(param));

  const getRegency = (param) =>
    dispatch(
      advanceSearchAsyncActionGetRegency(param)
    );

  const getDistrict = (param) =>
    dispatch(
      advanceSearchAsyncActionDistrict(param)
    );

  const getVillage = (param) =>
    dispatch(
      advanceSearchAsyncActionVillage(param)
    );

  const setSelectedAdministrativeValue = (param) =>
    dispatch(advanceSearchSlice.actions.setSelectedFormValue(param));

  const removeLevelFromGeoJSON = (param) =>
    dispatch(advanceSearchSlice.actions.removeLevelFromGeoJSON(param));

  const getGeoJSON = (param) =>
    dispatch(advanceSearchAsyncActionGeoJson(param));

  const getSearchByKeyword = (param) => {
    dispatch(advanceSearchAsyncActionSearch(param));
  };

  const resetState = () => dispatch(advanceSearchSlice.actions.resetAll());

  const resetStatus = (e) =>
    dispatch(advanceSearchSlice.actions.resetStatus({ status: e }));

  return {
    state,
    toggleModalOpen,
    getProvince,
    getRegency,
    getDistrict,
    getVillage,
    getGeoJSON,
    setSelectedAdministrativeValue,
    removeLevelFromGeoJSON,
    getSearchByKeyword,
    resetState,
    resetStatus,
  };
};

export { useAdvanceSearch };
