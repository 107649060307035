import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Faisal <ahmad.faisal@bvarta.com>
 * @param {Object} props
 * @param {string} props.className
 */

export default function IconUser(props) {
  const { className } = props;

  return (
    <div className={(className, 'icon-user')}>
      <svg
        height='13.469'
        viewBox='0 0 11.673 13.469'
        width='11.673'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g id='user-solid' transform='translate(-5 -3)'>
          <path
            d='M16.5,21.477a7.7,7.7,0,0,0-11.323,0A.678.678,0,0,0,5,21.926V24.62a.673.673,0,0,0,.673.664H16a.673.673,0,0,0,.673-.673V21.917A.678.678,0,0,0,16.5,21.477Z'
            data-name='Path 2991'
            fill='#657991'
            id='Path_2991'
            transform='translate(0 -8.816)'
          />
          <path
            d='M17.285,6.143A3.143,3.143,0,1,1,14.143,3a3.143,3.143,0,0,1,3.143,3.143Z'
            data-name='Path 2992'
            fill='#657991'
            id='Path_2992'
            transform='translate(-3.306)'
          />
        </g>
      </svg>
    </div>
  );
}

IconUser.defaultProps = {
  className: '',
};

IconUser.propTypes = {
  className: PropTypes.string,
};
