import React from 'react';
import { Col, Row } from 'antd';


import './MainMapLeftSide.Layout.scss';
import { AdvanceSearchContainer } from '@bvt-features/sub-module/advance-search/container/AdvanceSearch.Container';
import { MAINMAP_LEFT_SIDE_PORTAL_CONSTANT } from '../../constant/MAINMAP_LEFT_SIDE_PORTAL_CONSTANT';

export const MainMapLeftSideLayout = React.memo(() => {
  return (
    <div className='MainMapLeftSideLayout__layout'>
      <Row gutter={[0, 8]}>
        <Col xs={{ span: 24 }}>
          <AdvanceSearchContainer />
        </Col>
        <Col xs={{ span: 24 }}>
          <div id={MAINMAP_LEFT_SIDE_PORTAL_CONSTANT} />
        </Col>
      </Row>
    </div>
  );
});

MainMapLeftSideLayout.displayName = 'MainMapLeftSideLayout';
