import React from 'react';
import PropTypes from 'prop-types';
import { DonutChart, Svg } from '@bvt-atoms';
import { generateUUID } from '@bvt-utils';

/*
  data =
    - color
    - count
    - percent
    - type
    - svg (optional)
*/
export default function ProgressBar(props) {
  const {
    className,
    data,
    marginBottom,
    percentBracket,
    round,
    title,
    fontWeight,
  } = props;
  const wight = fontWeight || 700;


  if (round) {
    return <DonutChart data={data} title={title} />;
  } else {
    return (
      <div className='progress-bar'>
        <div className={'progress-bar__inner ' + className}>
          {data.map((item) => {
            return (
              <div key={generateUUID()} style={{ marginBottom: `${marginBottom}rem` }}>
                <div
                  style={{
                    backgroundColor: '#c7d4e3',
                    height: '2rem',
                    borderRadius: '0.313rem',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: item.color,
                      width: item.percent + '%',
                      borderRadius: '0.313rem',
                    }}
                  />
                </div>
                <div
                  style={{
                    padding: '0.25rem 0.5rem',
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                    marginTop: '-2rem',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {item.svg && item.svg !== '' ? (
                      <Svg src={item.svg} style={{ marginRight: '0.25rem' }} />
                    ) : (
                      <span />
                    )}
                    <span style={{ color: '#ffffff', fontWeight: wight }}>
                      {item.type}
                    </span>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div>
                      <span
                        style={{
                          color: '#ffffff',
                          fontWeight: wight,
                          marginRight: '0.25rem',
                        }}
                      >
                        {item.count}
                      </span>
                    </div>
                    <div>
                      <span style={{ color: '#ffffff', fontWeight: wight }}>
                        {percentBracket
                          ? `(${item.percent}%)`
                          : `${item.percent}%`}
                        {/* ({item.percent}%) */}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

ProgressBar.defaultProps = {
  className: '',
  data: [],
  fontWeight: 400,
  marginBottom: '0',
  percentBracket: true,
  round: false,
  title: '',
};

ProgressBar.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  fontWeight: PropTypes.number,
  marginBottom: PropTypes.string,
  percentBracket: PropTypes.bool,
  round: PropTypes.bool,
  title: PropTypes.string,
};
