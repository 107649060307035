import React from 'react';
import { Col, Row } from 'antd';
import { IconClose } from '@bvt-assets/icon/icon-close';

import './AdvanceSearchLabelList.component.scss';
import { generateUUID } from '@bvt-utils';

export const AdvanceSearchLabelListComponent = React.forwardRef(
  (props, ref) => {
    const { data, isLoading, onRemove } = props;
    return (
      <div className='AdvanceSearchLabelListComponent__wrapper' ref={ref}>
        <Row gutter={[16, 16]}>
          {data &&
            !isLoading &&
            data.map((ctx) => (
              <Col key={generateUUID()}>
                <div className='AdvanceSearchLabelListComponent__label'>
                  <span className='AdvanceSearchLabelListComponent__label-text'>
                    {ctx?.label}
                  </span>
                  <span
                    className='anticon AdvanceSearchLabelListComponent__label-icon'
                    onClick={() => onRemove && onRemove(ctx)}
                  >
                    <IconClose />
                  </span>
                </div>
              </Col>
            ))}
        </Row>
      </div>
    );
  }
);

AdvanceSearchLabelListComponent.displayName = 'AdvanceSearchLabelListComponent';
