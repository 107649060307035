import { Button, Svg } from '@bvt-atoms';
import { IconClose } from '@bvt-icons';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import { CardDevice } from '../CardDevice';
import { CardMoreInformation } from '../CardMoreInformation';
import './CardDatexPOIDataDetail.scss';
import illustration from '@bvt-images/Illustration.png';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta <renta.yustika@bvarta.com>
 * @param {Object} props
 * @param {String} props.industry
 * @param {String} props.group
 * @param {String} props.category
 * @param {String} props.brand
 * @param {String} props.criteria
 * @param {String} props.total
 */

export const CardDatexPOIDataDetail = (props) => {
  const { brand, category, criteria, group, industry, total } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className='CardDatexPOIDataDetail__container'>
      <Button
        className='CardDatexPOIDataDetail__btn-summary'
        onClick={() => setIsModalOpen(true)}
      >
        <Svg
          className='CardDatexPOIDataDetail__btn-summary-img'
          src='ic-info'
        />
        <span className='CardDatexPOIDataDetail__btn-summary-text'>
          Layer Summary
        </span>
      </Button>

      <CardMoreInformation
        data={[
          { key: 'Data Type', value: 'POI' },
          { key: 'Industry', value: industry },
          { key: 'Group', value: group },
          { key: 'Category', value: category },
          { key: 'Brand', value: brand },
          { key: 'Criteria', value: criteria },
        ]}
      />
      <CardDevice
        image={illustration}
        measurement='POIs'
        title='Total POI in Selected Area'
        total={total}
      />

      <Modal
        centered
        className='CardDatexPOIDataDetail__modal'
        closeIcon={
          <IconClose
            background='white'
            className='feature-map LayoutDatexSidebarWrapper__header__title__button'
            onClick={() => setIsModalOpen(false)}
          />
        }
        footer={null}
        open={isModalOpen}
        style={{ zIndex: '999999 !important' }}
        title={'Layer Summary'}
      >
        <CardMoreInformation
          className={'CardDatexPOIDataDetail__modal-more'}
          data={[
            { key: 'Data Type', value: 'POI' },
            { key: 'Industry', value: industry },
            { key: 'Group', value: group },
            { key: 'Category', value: category },
            { key: 'Brand', value: brand },
            { key: 'Criteria', value: criteria },
          ]}
        />
        <CardDevice
          className={'CardDatexPOIDataDetail__modal-device'}
          image={illustration}
          measurement='POIs'
          title='Total POI in Selected Area'
          total={total}
        />
      </Modal>
    </div>
  );
};
