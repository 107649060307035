export const ACTIVITY_CONSTANT = {
  BY_MODULE_REQUESTED: 'LI/ACCOUNT/activity/by-module/get/requested',
  BY_MODULE_SUCCESS: 'LI/ACCOUNT/activity/by-module/get/success',
  BY_MODULE_FAILED: 'LI/ACCOUNT/activity/by-module/get/failed',

  CHART_BY_MODULE_REQUESTED:
    'LI/ACCOUNT/activity/chart-by-module/get/requested',
  CHART_BY_MODULE_SUCCESS: 'LI/ACCOUNT/activity/chart-by-module/get/success',
  CHART_BY_MODULE_FAILED: 'LI/ACCOUNT/activity/chart-by-module/get/failed',

  BY_TIME_REQUESTED: 'LI/ACCOUNT/activity/by-time/get/requested',
  BY_TIME_SUCCESS: 'LI/ACCOUNT/activity/by-time/get/success',
  BY_TIME_FAILED: 'LI/ACCOUNT/activity/by-time/get/failed',

  RESET_STATUS: 'LI/ACCOUNT/activity/reset-status',
  RESET_ALL: 'LI/ACCOUNT/activity/reset-all',
};
