import * as React from 'react';
const IconAverageGraph = (props) => (
  <svg
    fill='none'
    height={12}
    viewBox='0 0 20 12'
    width={20}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M1.17674 11.0869C1.02217 11.087 0.86869 11.0566 0.72587 10.998C0.583051 10.9394 0.453262 10.8538 0.34397 10.7454C0.234677 10.6371 0.148752 10.5084 0.0896567 10.3667C0.0305616 10.2251 -0.000115754 10.0734 3.28212e-07 9.9201V1.16682C-0.000115754 1.01356 0.0305616 0.861796 0.0896567 0.72018C0.148752 0.578564 0.234677 0.449869 0.34397 0.341498C0.453262 0.233127 0.583051 0.14707 0.72587 0.0884734C0.86869 0.0298764 1.02217 -0.000114774 1.17674 3.30063e-07H4.11729C4.42938 3.30063e-07 4.72851 0.122678 4.94919 0.341498C5.16987 0.560318 5.29402 0.857362 5.29402 1.16682V9.9201C5.29402 10.2296 5.16987 10.5262 4.94919 10.745C4.72851 10.9638 4.42938 11.0869 4.11729 11.0869H1.17674Z'
      fill='#07827C'
    />
    <path
      d='M8.47068 11.0869C8.31612 11.087 8.16264 11.0566 8.01982 10.998C7.877 10.9394 7.74721 10.8538 7.63791 10.7454C7.52862 10.6371 7.4427 10.5084 7.3836 10.3667C7.32451 10.2251 7.29383 10.0734 7.29395 9.9201V1.16682C7.29383 1.01356 7.32451 0.861796 7.3836 0.72018C7.4427 0.578564 7.52862 0.449869 7.63791 0.341498C7.74721 0.233127 7.877 0.14707 8.01982 0.0884734C8.16264 0.0298764 8.31612 -0.000114774 8.47068 3.30063e-07H11.4112C11.7233 3.30063e-07 12.0225 0.122678 12.2431 0.341498C12.4638 0.560318 12.588 0.857362 12.588 1.16682V9.9201C12.588 10.2296 12.4638 10.5262 12.2431 10.745C12.0225 10.9638 11.7233 11.0869 11.4112 11.0869H8.47068Z'
      fill='#07827C'
    />
    <path
      d='M15.7646 11.0869C15.6101 11.087 15.4566 11.0566 15.3138 10.998C15.1709 10.9394 15.0412 10.8538 14.9319 10.7454C14.8226 10.6371 14.7366 10.5084 14.6775 10.3667C14.6185 10.2251 14.5878 10.0734 14.5879 9.9201V1.16682C14.5878 1.01356 14.6185 0.861796 14.6775 0.72018C14.7366 0.578564 14.8226 0.449869 14.9319 0.341498C15.0412 0.233127 15.1709 0.14707 15.3138 0.0884734C15.4566 0.0298764 15.6101 -0.000114774 15.7646 3.30063e-07H18.7052C19.0173 3.30063e-07 19.3164 0.122678 19.5371 0.341498C19.7578 0.560318 19.8819 0.857362 19.8819 1.16682V9.9201C19.8819 10.2296 19.7578 10.5262 19.5371 10.745C19.3164 10.9638 19.0173 11.0869 18.7052 11.0869H15.7646Z'
      fill='#07827C'
    />
  </svg>
);
export { IconAverageGraph };
