import React from 'react';
import './AccountPhoto.scss';
import { UserPhotoOrnament } from '@bvt-assets/icon/icon-userPhotoOrnament';
import sampleAvatar from '@bvt-images/userDefaultPhoto.png';

/**
 * @company PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

/**
 *
 * @param {Object} props
 * @param {any} props.photo
 */

export const AccountPhoto = (props) => {
  const { photo } = props;
  const s3URL = '/api/api-uploads3/v2/photo-user-company/display-photo/';
  return (
    <div className='AccountPhoto'>
      <UserPhotoOrnament />
      <div className='AccountPhoto--item'>
        {photo === 'bnVsbA==' ? (
          <img
            alt='user default photo'
            className='AccountPhoto--item__photo'
            src={sampleAvatar}
          />
        ) : (
          <img
            alt='user default photo'
            className='AccountPhoto--item__photo'
            src={`${s3URL}${photo}`}
          />
        )}
      </div>
    </div>
  );
};
