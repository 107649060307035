import React from 'react';
import PropTypes from 'prop-types';

export default function Svg({ className, src, type, onClick, style }) {
  const customClassNameBg = className
    .split(' ')
    .map((newClass) => newClass + '--bg')
    .join(' ');

  return (
    <div className={customClassNameBg} onClick={onClick} style={{ ...style }}>
      <img
        className={className}
        src={type !== 'link' ? `/assets/bvt-${src}.${type}` : src}
      />
    </div>
  );
}

Svg.defaultProps = {
  className: '',
  onClick: () => {},
  src: '',
  style: {},
  type: 'svg',
};

Svg.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  src: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.oneOf(['svg', 'png', 'jpeg', 'link']),
};
