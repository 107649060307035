import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Faisal <ahmad.faisal@bvarta.com>
 * @param {Object} props
 * @param {string} props.className
 */

export default function IconPadlock(props) {
  const { className } = props;

  return (
    <div className={`${className} icon-padlock`}>
      <svg
        height='16'
        viewBox='0 0 14 16'
        width='14'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M7,8a2,2,0,1,1-2,2A2.006,2.006,0,0,1,7,8ZM7,2A2.006,2.006,0,0,0,5,4H9A2.006,2.006,0,0,0,7,2Zm5,14H2a2.006,2.006,0,0,1-2-2V6A2.006,2.006,0,0,1,2,4H3a4,4,0,0,1,8,0h1a2.006,2.006,0,0,1,2,2v8A2.006,2.006,0,0,1,12,16Z'
          data-name='Path 49'
          fill='#caced5'
          fillRule='evenodd'
          id='Path_49'
        />
      </svg>
    </div>
  );
}

IconPadlock.defaultProps = {
  className: '',
};

IconPadlock.propTypes = {
  className: PropTypes.string,
};
