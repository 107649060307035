import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 2.1
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Faisal <ahmad.faisal@bvarta.com>
 */

function IconChecklist({ className }) {
  const customClass = `${className} icon-checklist`;

  return (
    <div className={customClass}>
      <svg
        height='12.729'
        viewBox='0 0 12.73 12.729'
        width='12.73'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M-10544.364,22354a1.64,1.64,0,0,1-1.639-1.637c0-.006,0-.012,0-.018s0-.012,0-.018v-3.656a1.678,1.678,0,0,1,1.678-1.672,1.673,1.673,0,0,1,1.672,1.672v2.059h6.02a1.634,1.634,0,0,1,1.633,1.633,1.635,1.635,0,0,1-1.633,1.637Z'
          data-name='Union 37'
          fill='#007e75'
          id='Union_37'
          transform='translate(-8344.564 -23251.086) rotate(-45)'
        />
      </svg>
    </div>
  );
}

export default IconChecklist;

IconChecklist.defaultProps = {
  className: '',
};

IconChecklist.propTypes = {
  className: PropTypes.string,
};
