import Resource from '../../../../api/resource';

/**
 * Payload Marker
 * @typedef {object} PayloadMarker
 * @property {string} name
 * @property {string} address
 * @property {number} id_desa
 * @property {number} id_kota
 * @property {string} latitude
 * @property {string} longitude
 * @property {string} siteName
 * @property {string} icon_color
 * @property {string} icon_type
 * @property {string} nama_desa
 * @property {string} nama_kota
 * @property {string} nama_kecamatan
 * @property {string} nama_provinsi
 * @property {number[]} coordinate
 * @property {number} id_provinsi
 * @property {number} id_kecamatan
 */

export class MarkerService extends Resource {
  constructor() {
    super('marker', '/api', 'api-poi', 'v2');
  }
  /**
   * Marker Service
   * @param {object} params - Params for get list marker
   * @param {object} params.meta - Meta for filter marker
   * @param {object} params.meta.filter
   * @param {string} params.meta.filter.name
   * @param {number} params.meta.page
   * @param {number} params.meta.limit
   * @param {object} params.meta.sort
   * @param {string} params.meta.sort.by
   * @param {string} params.meta.sort.name
   */
  getAll(query) {
    this.setAdditionUri('');
    const paramString = JSON.stringify(query);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }

  /**
   * @param {number} id
   */
  getOne(id) {
    this.setAdditionUri('');
    return this.get(id);
  }

  /**
   * Payload Create
   * @param {PayloadMarker} payload
   */
  add(payload) {
    this.setAdditionUri('');
    return this.create(payload);
  }

  /**
   * Payload Create
   * @param {number} id
   * @param {PayloadMarker} payload
   */
  edit(id, payload) {
    this.setAdditionUri('');
    return this.update(id, payload);
  }

  /**
   * @param {number} id
   */
  delete(id) {
    this.setAdditionUri('');
    return this.destroy(id);
  }
}
