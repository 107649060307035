import INTERACTIVE_MAP_CONSTANT from '../constant';

const initialState = {
  project_id: null,
  uuid: '',
};

export function UUID(state = initialState, action) {
  if (action.type === INTERACTIVE_MAP_CONSTANT.UUID) {
    return action.payload;
  } else {
    return state;
  }
}
