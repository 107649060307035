import { IconSearch } from '@bvt-assets/icon/icon-search';
import { Checkbox, Input, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import './DatexStepPOIEditBrand.scss';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

/**
 * @description For brand object
 * @typedef IBrandObject
 * @type {Object}
 * @property {string|number} key
 * @property {string} value
 */

/**
 * @param {Object} params
 * @param {Array<IBrandObject>} params.data
 * @param {string} params.activeKey
 * @param {(key:string)=>void} params.changeActiveKey
 */
const TabFilter = (params) => {
  const { activeKey, data, changeActiveKey } = params;

  return (
    <Radio.Group
      className='DatexStepPOIEditBrand__tab'
      onChange={(e) => changeActiveKey && changeActiveKey(e.target.value)}
      value={activeKey}
    >
      {data?.map((v) => {
        return (
          <Radio.Button
            className='DatexStepPOIEditBrand__tab---item'
            key={v?.key}
            value={v?.key}
          >
            {v?.value}
          </Radio.Button>
        );
      })}
    </Radio.Group>
  );
};

/**
 * @description For criteria object
 * @typedef ICriteriaObject
 * @type {Object}
 * @property {string|number} key
 * @property {string} value
 */

/**
 * @param {Object} props
 * @param {Array<IBrandObject>} props.brandList
 * @param {Array<ICriteriaObject>} props.criteriaList
 * @param {(Array<String|Number>)=>void} props.onSelectBrands
 * @param {Array<String|Number>} props.selectedBrands
 * @param {({keyword:string,type:string})=>void} props.onFilter
 * @param {Array<String>} props.selectedCriterias
 * @param {(Array<String>)=>void} props.onSelectCriterias
 * @param {(valid:boolean)=>void} props.onValidOrInvalid
 * @param {({beforeSaveSelectedBrands:Array<String>,beforeSaveSelectedCriterias:Array<String>})=>void} props.onSetDefaultValue
 * @param {(e:string)=>void} props.onTabChange
 */

export const DatexStepPOIEditBrand = (props) => {
  const {
    brandList = [],
    criteriaList = [],
    onSelectBrands,
    onSelectCriterias,
    selectedCriterias,
    selectedBrands,
    onFilter,
    onValidOrInvalid,
    onSetDefaultValue,
    onTabChange,
  } = props;
  const [state, setState] = useState({
    activeTab: 'brand',
    keyword: '',
    detailList: [...brandList],
    beforeSaveSelectedBrands: [...selectedBrands],
    beforeSaveSelectedCriterias: [...selectedCriterias],
  });
  useEffect(() => {
    if(state?.activeTab === 'brand') {
      setState({ ...state, detailList: brandList });
    }
    if(state?.activeTab === 'criteria') {
      setState({ ...state, detailList: criteriaList });
    }
  }, [state?.activeTab, brandList, criteriaList]);

  useEffect(() => {
    if (selectedBrands?.length > 0 || selectedCriterias?.length > 0) {
      onValidOrInvalid && onValidOrInvalid(true);
    } else {
      onValidOrInvalid && onValidOrInvalid(false);
    }
  }, [state]);

  useEffect(() => {
    onSetDefaultValue && onSetDefaultValue({
      beforeSaveSelectedBrands: state.beforeSaveSelectedBrands,
      beforeSaveSelectedCriterias: state.beforeSaveSelectedCriterias
    });
  }, []);

  return (
    <div className='DatexStepPOIEditBrand'>
      <TabFilter
        activeKey={state?.activeTab}
        changeActiveKey={(e) => {
          setState({
            ...state,
            activeTab: e,
          });
          onTabChange && onTabChange(e);
          onFilter &&
            onFilter({
              type: e,
            });
        }}
        data={[
          { key: 'brand', value: 'Brand' },
          { key: 'criteria', value: 'Criteria' },
        ]}
      />
      <div className='DatexStepPOIEditBrand__search'>
        <Input
          addonAfter={
            <div className='icon-addonAfter'>
              <IconSearch />
            </div>
          }
          onChange={(e) =>{
            setState({
              ...state,
              keyword: e.target.value,
            });
            onFilter && onFilter({ keyword: e.target.value });
          }}
          placeholder='Search specific brands by name'
          value={state?.keyword}
        />
      </div>
      <Checkbox.Group
        className='DatexStepPOIEditBrand__list'
        onChange={(e) => {
          state.activeTab === 'brand' && onSelectBrands && onSelectBrands(e);
          state.activeTab === 'criteria' && onSelectCriterias && onSelectCriterias(e);
        }}
        options={state?.detailList?.map((v) => {
          return {
            value: v?.key,
            label: v?.value,
          };
        })}
        value={state.activeTab === 'brand' ? selectedBrands : selectedCriterias}
      />
      <div className='DatexStepPOICategoryCollection__footer'>
        <span className='DatexStepPOICategoryCollection__footer--text'>{selectedBrands?.length || 0} Brand and {selectedCriterias?.length || 0} Criteria Selected</span>
      </div>
    </div>
  );
};
