import Resource from '../../../../api/resource';

export class IndustriesService extends Resource {
  constructor() {
    super('poi/', '/api', 'api-poi', 'v3');
  }

  getDetailIndustry(id) {
    this.setAdditionUri(`${id}/by-industries-id`);
    return this.list();
  }
}
