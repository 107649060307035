import MarkerCluster from '@bvt-features/mainmap/cluster/component/MarkerCluster';
import React, { useEffect, useState } from 'react';
import { usePoiSearch } from '../hooks/usePoiSearch';
import { useMap } from 'react-leaflet';
import { PopupContent } from '../components/PopupContent';
import { useMainMap } from '@bvt-features/mainmap/mainmap/hook/useMainMap';
import _ from 'lodash';

export function PoiSearchMarkerContainer() {
  const POISEARCH = usePoiSearch();
  const initialMap = useMap();
  const MAINMAP = useMainMap();
  const [poi, setPoi] = useState([]);

  const ID = POISEARCH.selectedId;
  const SHOWALL = POISEARCH.isShowAll;
  const POILIST = POISEARCH.list;

  const styleFinder = _.find(
    MAINMAP.state.layerVisibilityList,
    (v) => v.key === 'POI_SEARCH'
  );

  useEffect(() => {
    if ((ID || SHOWALL) && poi.length > 0) {
      MAINMAP.registerLayer({
        key: 'POI_SEARCH',
        isActive: true,
        opacity: 1,
        title: 'POI Search',
        description: ['POI Search'],
      });
    }
    return () => {
      MAINMAP.removeLayer('POI_SEARCH');
    };
  }, [ID, SHOWALL, poi]);

  useEffect(() => {
    MAINMAP.updateLayer({
      key: 'POI_SEARCH',
      isActive: true,
      opacity: 1,
      title: 'POI Search',
      description: ['POI Search'],
    });
  }, [ID, SHOWALL]);

  // handle select list options
  useEffect(() => {
    if (ID) {
      const selected = POISEARCH.mapperListToDetail(ID);
      const icon = selected?.category_icon || '/Category/1.svg';
      const properties = {
        industry_name: selected?.industry_name,
        group_name: selected?.group_name,
        category_name: selected?.category_name,
        category_icon: icon,
        brand_name: selected?.brand_name,
        poi_name: selected?.poi_name,
        address: selected?.address,
        longitude: selected?.longitude,
        latitude: selected?.latitude,
      };
      const position = [+selected?.latitude, +selected?.longitude];
      initialMap?.setView(position);
      setPoi([
        {
          position,
          icon,
          popupContent: <PopupContent properties={properties} />,
        },
      ]);
    }
  }, [ID]);

  // handle press enter
  useEffect(() => {
    if (SHOWALL) {
      const coordinates = [];
      const data = POILIST?.map((item) => {
        const icon = item?.category_icon || '/Category/1.svg';
        const properties = {
          industry_name: item?.industry_name,
          group_name: item?.group_name,
          category_name: item?.category_name,
          category_icon: icon,
          brand_name: item?.brand_name,
          poi_name: item?.poi_name,
          address: item?.address,
          longitude: item?.longitude,
          latitude: item?.latitude,
        };
        const position = [+item?.latitude, +item?.longitude];
        coordinates.push(position);
        return {
          position,
          icon,
          popupContent: <PopupContent properties={properties} />,
        };
      });
      setPoi(data);
      if (coordinates.length > 0) {
        // Extract the bounding box (minLat, minLng, maxLat, maxLng)
        const latitudes = coordinates.map(coord => coord[0]);
        const longitudes = coordinates.map(coord => coord[1]);

        const minLat = Math.min(...latitudes);
        const maxLat = Math.max(...latitudes);
        const minLng = Math.min(...longitudes);
        const maxLng = Math.max(...longitudes);

        const bbox = [[minLat, minLng], [maxLat, maxLng]];
        initialMap?.fitBounds(bbox);
      }
    }
  }, [SHOWALL, POILIST]);

  return (
    <React.Fragment>
      {initialMap &&
        poi.length > 0 &&
        (POISEARCH.selectedId || POISEARCH?.isShowAll) &&
        (styleFinder?.isActive ? (
          <MarkerCluster data={poi} opacity={styleFinder?.opacity} />
        ) : null)}
    </React.Fragment>
  );
}
