import React, { useEffect, useState } from 'react';
import './DatexTelcoStepOnePoi.scss';
import { SearchBox } from '@bvt-shared/component/SearchBox';
import DatexPoiNoData from './DatexPoiNoData';
import DatexPoiSearchResult from './DatexPoiSearchResult';
import { Loading } from '@bvt-features/account/component';
import { Popover } from '@bvt-atoms';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

/**
 * @description For data poi object
 * @typedef TPOIDataGroupPOI
 * @property {number} key
 * @property {string} label
 * @property {any} icon
 */

/**
 * @description For data poi object
 * @typedef TPOIDataObject
 * @type {Object}
 * @property {number|string} key
 * @property {string} value
 * @property {React.ReactElement} icon
 */

/**
 * @typedef Brand
 * @type {Object}
 * @property {number} id
 * @property {string} name
 */

/**
 * @typedef Category
 * @type {Object}
 * @property {number} id
 * @property {number} code
 * @property {string} name
 * @property {string} icon
 * @property {string} group_name
 * @property {Brand[]} brands
 */

/**
 * @typedef PayloadOnSelectBrands
 * @property {string} categoryId
 * @property {string[]} brandIds
 * @property {string[]} brandLabels
 * @property {string} groupName
 * @property {string} categoryIcon
 * @property {string} categoryName
 * @property {string} industryName
 */

/**
 * @param {object} props
 * @param {Array<TPOIDataGroupPOI>} props.industryList
 * @param {(poiGroup:TPOIDataObject)=>void} props.onChange
 * @param {(key:string)=>void} props.onSearch
 * @param {(isValid:boolean)=>void} props.onValidOrInvalid
 * @param {string} props.activeKey
 * @param {Category[]} props.searchResult
 * @param {boolean} props.isLoadingSearch
 * @param {(payload: PayloadOnSelectBrands) => void} props.onSelectBrands
 */

export const DatexTelcoStepOnePoi = (props) => {
  const {
    industryList,
    onChange,
    onSearch,
    onValidOrInvalid,
    isTyping,
    searchResult,
    isLoadingSearch,
    onSelectBrands,
  } = props;
  const [state, setState] = useState({
    industry: {},
  });

  useEffect(() => {
    if (state.industry?.key && !isTyping && !isLoadingSearch) {
      onValidOrInvalid && onValidOrInvalid(true);
    } else {
      onValidOrInvalid && onValidOrInvalid(false);
    }
  }, [state, isTyping, isLoadingSearch]);
  function compareStatus(a, b) {
    const statusA = a.status || '';
    const statusB = b.status || '';

    return statusB.localeCompare(statusA);
  }
  const popoverContent = (
    <div className='DatexTelcoStepOnePoi container-popover'>
      <div className='DatexTelcoStepOnePoi arrow arrow---down' />
      <p className='DatexTelcoStepOnePoi container-popover-text'>
        Contact our sales to subscribe
      </p>
    </div>
  );

  return (
    <React.Fragment>
      <div className='DatexTelcoStepOnePoi__search'>
        <SearchBox
          handler={{ onSearch, onClear: () => onSearch('') }}
          placeholder='Search specific brands by name'
          withButtonText={false}
        />
      </div>
      <div className='DatexTelcoStepOnePoi__container scroll'>
        {industryList.length > 0 &&
          !isTyping &&
          industryList?.toSorted(compareStatus)?.map((v) => {
            return v.status ? (
              <div
                className={`DatexTelcoStepOnePoi__item ${
                  state?.industry?.key === v?.key
                    ? 'DatexTelcoStepOnePoi__item--active'
                    : ''
                }`}
                key={v?.key}
                onClick={(e) => {
                  e.stopPropagation();
                  setState({
                    ...state,
                    industry: {
                      key: v?.key,
                      value: v?.label,
                    },
                  });
                  onChange &&
                    onChange({
                      poiIndustry: {
                        key: v?.key,
                        value: v?.label,
                        icon: v?.icon,
                      },
                    });
                }}
              >
                <img
                  alt='industry-poi-img'
                  className='DatexTelcoStepOnePoi__item--image'
                  src={v?.icon}
                />
                <span className='DatexTelcoStepOnePoi__item--text'>
                  {v?.label}
                </span>
              </div>
            ) : (
              <Popover
                className='DatexTelcoStepOnePoi__popover-wrapper'
                content={popoverContent}
                header={false}
                key={v.key}
                placement='top'
                trigger='hover'
                visibility={v.status}
              >
                <div
                  className={`DatexTelcoStepOnePoi__item  ${
                    !v.status ? 'disabled' : ''
                  }`}
                  key={v?.key}
                  onClick={(e) => {
                    if (v.status) {
                      e.stopPropagation();
                      setState({
                        ...state,
                        industry: {
                          key: v?.key,
                          value: v?.label,
                        },
                      });
                      onChange &&
                        onChange({
                          poiIndustry: {
                            key: v?.key,
                            value: v?.label,
                            icon: v?.icon,
                          },
                        });
                    }
                  }}
                >
                  <img
                    alt='industry-poi-img'
                    className='DatexTelcoStepOnePoi__item--image'
                    src={v?.icon}
                  />
                  <span className='DatexTelcoStepOnePoi__item--text'>
                    {v?.label}
                  </span>
                </div>
              </Popover>
            );
          })}
        {isTyping && !isLoadingSearch && !searchResult.length && (
          <DatexPoiNoData />
        )}
        {isTyping && !isLoadingSearch && searchResult.length > 0 && (
          <DatexPoiSearchResult
            data={searchResult}
            onSelectBrands={onSelectBrands}
            setIsValid={onValidOrInvalid}
          />
        )}
        {isTyping && isLoadingSearch && (
          <div className='loading-search-brand'>
            <Loading height='2rem' width='2rem' />
            Searching POI...
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
