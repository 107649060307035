import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ResetPasswordForm } from '@bvt-features/auth/component';
import { useAuth } from '@bvt-features/auth/hooks';
import PropTypes from 'prop-types';
import LottiePlayer from 'lottie-react';
import { UpdatePasswordComplete } from '@bvt-animations';
import LinkExpired from '@bvt-features/auth/component/LinkExpired';
import './ResetPasswordContainer.scss';

/**
 *@author Randa <m.randa@bvarta.com>
 * @param {object} props
 * @param {string} props.token
 */
const ResetPasswordContainer = (props) => {
  const auth = useAuth();
  const { token } = props;
  const navigate = useNavigate();
  const [isAnimationDone, setIsAnimationDone] = useState(false);
  const postStatus = auth.state.status_POST;
  const postSuccess = postStatus === 'SUCCESS';
  const postLoading = postStatus === 'LOADING';
  const postFailed = postStatus === 'FAILED';
  const tokenResetPasswordStatusFailed = auth.state.tokenResetPassword.status === 'FAILED' || postFailed;

  useEffect(() => {
    auth.checkTokenResetPassword(token);
    return () => {
      auth.resetStatus();
    };
  }, []);

  return (
    <React.Fragment>
      {tokenResetPasswordStatusFailed && <LinkExpired />}
      {!tokenResetPasswordStatusFailed && postSuccess && (
        <React.Fragment>
          <LottiePlayer
            animationData={UpdatePasswordComplete}
            className='feature-auth ResetPasswordContainer__animation'
            loop={false}
            onEnterFrame={(e) =>
              e.currentTime > 150 && setIsAnimationDone(true)
            }
            speed={2}
            style={{
              height: '312px',
              ...(!isAnimationDone && {
                // marginTop: 'auto',
                marginBottom: 'auto',
              }),
            }}
          />
          {isAnimationDone && (
            <React.Fragment>
              <div
                className='feature-auth ResetPasswordContainer__success-text'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    fontSize: '1.25rem',
                    lineHeight: '1.25rem',
                    marginBottom: '0.5rem',
                  }}
                >
                  Success!
                </div>
                <div
                  style={{
                    fontSize: '0.875rem',
                    lineHeight: '1.25rem',
                  }}
                >
                  Your password has been updated
                </div>
              </div>
              <div style={{ marginTop: 'auto', padding: '1rem' }}>
                <div className='feature-auth ForgotPasswordForm__button-wrapper'>
                  <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                    <button
                      className='feature-auth ForgotPasswordForm__button back-to-login'
                      onClick={() => navigate({ pathname: '/auth' })}
                      type='button'
                    >
                      Back To Login
                    </button>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      {!tokenResetPasswordStatusFailed && !postSuccess && (
        <ResetPasswordForm
          isLoading={postLoading}
          message={postFailed ? auth.state.message : ''}
          onBack={() => navigate('/auth')}
          onDismissMessage={() => auth.resetStatus()}
          onSubmit={(e) => auth.resetPasswordUsingToken({ ...e, token })}
        />
      )}
    </React.Fragment>
  );
};

ResetPasswordContainer.propTypes = {
  token: PropTypes.string,
};

export default ResetPasswordContainer;
