import { useDispatch, useSelector } from 'react-redux';
import { MAINMAP_MAP_MODULEBAR_ACTION } from '../redux';

export function useModuleBar() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.features.MAINMAP.MODULEBAR);

  const setModuleSubMenuList = (data) => {
    dispatch(MAINMAP_MAP_MODULEBAR_ACTION.setModuleBarSubMenuList(data));
  };

  const setModuleMenuSelected = (data) => {
    dispatch(MAINMAP_MAP_MODULEBAR_ACTION.setModuleBarMenuSelected(data));
  };

  const setModuleSubMenuSelected = (data) => {
    dispatch(MAINMAP_MAP_MODULEBAR_ACTION.setModuleBarSubMenuSelected(data));
  };

  const resetState = () => {
    dispatch(MAINMAP_MAP_MODULEBAR_ACTION.resetState());
  };

  return {
    state,
    setModuleMenuSelected,
    setModuleSubMenuList,
    setModuleSubMenuSelected,
    resetState,
  };
}
