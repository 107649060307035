import AuthLayout from '@bvt-features/auth/component/AuthLayout';
import AuthenticatedGateContainer from '@bvt-features/auth/container/AuthenticatedGateContainer';
import React from 'react';
import { Navigate } from 'react-router';
import { BasePageHeadTag } from '@bvt-shared/component/BasePageHeadTag';
import Pages from '../../pages/bvt';
/**
 * Pages Import Using Loader
 */
/** @type {import('react-router').RouteObject} */
export const RoutesAuth = {
  path: 'auth',
  element: (
    <AuthLayout>
      <AuthenticatedGateContainer />
    </AuthLayout>
  ),
  children: [
    {
      path: 'login',
      element: (
        <React.Fragment>
          <BasePageHeadTag title='LI :: Auth :: Login' />
          <Pages.Login />
        </React.Fragment>
      ),
    },
    {
      path: 'reset-password/:token',
      element: (
        <React.Fragment>
          <BasePageHeadTag title='LI :: Auth :: Reset Password' />
          <Pages.ResetPassword />
        </React.Fragment>
      ),
    },
    {
      path: 'forgot-password',
      element: (
        <React.Fragment>
          <BasePageHeadTag title='LI :: Auth :: Forgot Password' />
          <Pages.ForgotPassword />
        </React.Fragment>
      ),
    },
    {
      path: 'logout',
      element: <React.Fragment />,
    },
    {
      path: '*',
      index: true,
      element: <Navigate to={'login'} />,
    },
  ],
};
