import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

/**
 * @version 1.1
 * @copyright Bhumi Varta Technology
 * @memberof molecules
 * @author Faisal <ahmad.faisal@bvarta.com>
 * @param {object} props
 * @param {string} props.className
 * @param {Object[]} props.dataSource array of object for radioGroup
 * @param {String} props.dataSource[].id id for the input radio and the label
 * @param {String} props.dataSource[].name name of radioGroup
 * @param {Boolean} props.dataSource[].disabled disabled of radioGroup
 * @param {import('react').CSSProperties} props.dataSource[].styleActive style for style active radio of radioGroup
 * @param {import('react').CSSProperties} props.dataSource[].styleUnactive style for style unactive radio of radioGroup
 * @param {String} props.dataSource[].text text for the label radio
 * @param {String} props.dataSource[].value text for the value radio
 */

export default function RadioGroup(props) {
  const { checked, className, dataSource, onChange } = props;

  return (
    <div className={`radioGroup ${className}`}>
      {dataSource.map((e) => (
        <Fragment key={e.id}>
          <input
            checked={checked === e.value}
            disabled={e.disable}
            id={e.id}
            name={e.name}
            onChange={() => onChange(e)}
            type='radio'
            value={e.value}
          />
          <label
            className={e.disable ? 'disable' : ''}
            htmlFor={e.id}
            style={checked === e.value ? e.styleActive : e.styleUnactive}
          >
            {e.text}
          </label>
        </Fragment>
      ))}
    </div>
  );
}

RadioGroup.defaultProps = {
  checked: '',
  className: '',
  dataSource: [
    {
      id: 'exampleRadioGroup1',
      name: 'exampleRadioGroup',
      disable: false,
      styleActive: {
        color: '#15A3EC',
      },
      styleUnactive: {
        color: '#878A92',
      },
      text: 'General POI',
      value: 'General POI',
    },
    {
      id: 'exampleRadioGroup2',
      name: 'exampleRadioGroup',
      disabled: true,
      styleActive: {
        color: '#FF3939',
      },
      styleUnactive: {
        color: '#878A92',
      },
      text: 'Competitor POI',
      value: 'Competitor POI',
    },
  ],
  onChange: undefined,
};

RadioGroup.propTypes = {
  checked: PropTypes.string,
  className: PropTypes.string,
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      disable: PropTypes.bool,
      styleActive: PropTypes.object,
      styleUnactive: PropTypes.object,
      text: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
};
