import moment from 'moment';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Ryfan <ryfan.aditya@bvarta.com>
 */

export const dataSortFilter = {
  filterList: [
    {
      name: 'Show All',
      value: '',
    },
    {
      name: 'Site Profiling',
      value: 'Analysis - Site Profiling',
    },
    {
      name: 'Grid Analysis',
      value: 'Analysis - Grid Profiling',
    },
  ],
  sortList: [
    {
      name: 'Last Updated',
      value: {
        column: 'lastupdate',
        type: 'DESC',
      },
    },
    {
      name: 'First Updated',
      value: {
        column: 'lastupdate',
        type: 'ASC',
      },
    },
    {
      name: 'A to Z',
      value: {
        column: 'name',
        type: 'ASC',
      },
    },
    {
      name: 'Z to A',
      value: {
        column: 'name',
        type: 'DESC',
      },
    },
    {
      name: 'Create Date',
      value: {
        column: 'datecreate',
        type: 'ASC',
      },
    },
  ],
};

export const rangeCalender = (dates) => {
  const newDates = JSON.parse(JSON.stringify(dates));
  return newDates.map(
    (date) => (date.created_at = moment(date.created_at).format('DD/MM/YYYY'))
  );
};

export const projectTypes = (project) => {
  let type = '';
  let color = '';
  switch (project) {
    case 'Analysis - Site Profiling':
      type = 'Site Profiling';
      color = '#477ffd';
      break;
    case 'Analysis - Grid Profiling':
      type = 'Grid Analysis';
      color = '#f425a3';
      break;
    case 'Analysis - Business Profiling':
      type = 'Business Profiling';
      color = '#007e75';
      break;
  }

  return { type, color };
};

export const projectTypeSlug = (project) => {
  const projectType = {
    'Analysis - Site Profiling': 'site-profiling',
    'Analysis - Grid Profiling': 'grid-analysis',
    'Analysis - Grid Profiling Lawu': 'grid-analysis-lawu',
    'Analysis - Site Profiling Mandala': 'site-profiling-mandala',
    'Analysis - Business Profiling': 'business-profiling',
  };

  return projectType[project] || 'grid-analysis';
};
