import _ from 'lodash';
import { StepService } from '../service';
import { STEP_CONSTANT } from './STEP_CONSTANT';

const stepService = new StepService();

export const STEP_ACTION = {
  setStepCode: (payload) => {
    return {
      type: STEP_CONSTANT.STEP_CODE,
      payload: {
        code: payload,
      },
    };
  },

  setStep: (payload) => {
    return {
      type: STEP_CONSTANT.CURRENT_STEP,
      payload: {
        step: payload.step,
      },
    };
  },

  getByCode: (code = '') => {
    return async (dispatch) => {
      dispatch({
        type: STEP_CONSTANT.GET_REQUESTED,
        payload: { status_GET: 'LOADING' },
      });
      try {
        const data = await stepService.getByCode({
          code_parent: code,
        });
        const tempData = _.filter(
          data.results,
          (v) => v.step_name !== 'Report'
        );
        dispatch({
          type: STEP_CONSTANT.GET_SUCCESS,
          payload: {
            status_GET: 'SUCCESS',
            data: tempData,
          },
        });
      } catch (e) {
        dispatch({
          type: STEP_CONSTANT.GET_SUCCESS,
          payload: {
            status_GET: 'FAILED',
            message: e.response.data.message,
          },
        });
      }
    };
  },

  resetAll: () => {
    return (dispatch) => {
      dispatch({ type: STEP_CONSTANT.RESET_ALL });
    };
  },

  resetStatus: () => {
    return (dispatch) => {
      dispatch({ type: STEP_CONSTANT.RESET_STATUS });
    };
  },
};
