import { LEGACY_AUTH_CONSTANT } from '@bvt-features/auth/redux';
import { GENSET_POI_CONSTANT } from './GENSET_POI_CONSTANT';

const initialState = {
  status_GET: 'IDLE',
  status_POST: 'IDLE',
  status_PUT: 'IDLE',
  status_DELETE: 'IDLE',
  status_DOWNLOAD: 'IDLE',
  status_DOWNLOAD_DOT: 'IDLE',
  status_UPLOAD: 'IDLE',
  status_PATCH: 'IDLE',
  status_DETAIL: 'IDLE',
  message: '',
  detail: {},
  list: [],
  property: {},
  url: '',
  errorUploadField: '',
};

export function GENSET_POI_REDUCER(state = initialState, action) {
  let returnData = state;
  Object.values(GENSET_POI_CONSTANT).map((ctx) => {
    if (action.type === GENSET_POI_CONSTANT.RESET_STATUS) {
      returnData = {
        ...returnData,
        status_GET: 'IDLE',
        status_POST: 'IDLE',
        status_PUT: 'IDLE',
        status_DELETE: 'IDLE',
        status_DOWNLOAD: 'IDLE',
        status_DOWNLOAD_DOT: 'IDLE',
        status_UPLOAD: 'IDLE',
        status_PATCH: 'IDLE',
        status_DETAIL: 'IDLE',
        message: '',
        errorUploadField: '',
      };
    } else if (
      action.type === GENSET_POI_CONSTANT.RESET_ALL ||
      action.type === LEGACY_AUTH_CONSTANT.UNAUTHORIZED
    ) {
      returnData = {
        status_GET: 'IDLE',
        status_POST: 'IDLE',
        status_PUT: 'IDLE',
        status_DELETE: 'IDLE',
        status_DOWNLOAD: 'IDLE',
        status_DOWNLOAD_DOT: 'IDLE',
        status_UPLOAD: 'IDLE',
        status_PATCH: 'IDLE',
        status_DETAIL: 'IDLE',
        message: '',
        detail: {},
        data: {},
        property: {},
        url: '',
        errorUploadField: '',
      };
    } else if (ctx === action.type && !ctx.includes('[NO_STATE_UPDATE]')) {
      returnData = { ...returnData, ...action.payload };
    }
    return null;
  });

  return returnData;
}
