import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import {
  advanceSearchAsyncActionDistrict,
  advanceSearchAsyncActionGeoJson,
  advanceSearchAsyncActionGetProvince,
  advanceSearchAsyncActionGetRegency,
  advanceSearchAsyncActionVillage,
  advanceSearchAsyncActionSearch,
} from './advanceSearch.asyncAction';
import { ADVANCE_SEARCH_STORE_CONSTANT } from '../constant/ADVANCE_SEARCH_CONSTANT';
import { APP_STORE_PREFIX_CONSTANT } from '@bvt-shared/constant/APP_PREFIX_CONSTANT';

const initialState = {
  status_GEOJSON: 'IDLE',
  status_GET: 'IDLE',
  status_SEARCH: 'IDLE',
  message: '',
  description: '',
  isAdvanceModalOpen: false,
  data: {
    geojson: { type: 'FeatureCollection', features: [] },
    options: {},
    selected: {
      district: undefined,
      province: undefined,
      regency: undefined,
      village: undefined,
    },
    searchOptions: [],
  },
};

export const advanceSearchSlice = createSlice({
  name: APP_STORE_PREFIX_CONSTANT + ADVANCE_SEARCH_STORE_CONSTANT,
  initialState,
  reducers: {
    resetAll: () => {
      return initialState;
    },
    resetStatus: (state, action) => {
      const { payload } = action;
      state.message = '';
      state.description = '';
      switch (payload.status) {
        case 'GEOJSON':
          state.status_GEOJSON = 'IDLE';
          break;
        case 'GET':
          state.status_GET = 'IDLE';
          break;
        default:
          state.status_GEOJSON = 'IDLE';
          state.status_GET = 'IDLE';
          break;
      }
    },
    toggleAdvanceSearchModal: (state, action) => {
      const { payload } = action;
      if (_.isBoolean(payload.isAdvanceModalOpen)) {
        state.isAdvanceModalOpen = payload.isAdvanceModalOpen;
      } else {
        state.isAdvanceModalOpen = !state.isAdvanceModalOpen;
      }
    },
    setSelectedFormValue: (state, action) => {
      const {
        payload: { level, value, manualSet },
      } = action;
      switch (level) {
        case 'province':
          if (manualSet) {
            state.data.selected.province = value;
          } else {
            state.data.selected.province = _.find(
              state.data.options.province,
              (v) => v.value === value?.value
            );
          }
          break;
        case 'regency':
          if (manualSet) {
            state.data.selected.regency = value;
          } else {
            state.data.selected.regency = _.find(
              state.data.options.regency,
              (v) => v.value === value?.value
            );
          }
          break;
        case 'district':
          if (manualSet) {
            state.data.selected.district = value;
          } else {
            state.data.selected.district = _.find(
              state.data.options.district,
              (v) => v.value === value?.value
            );
          }
          break;
        case 'village':
          if (manualSet) {
            state.data.selected.village = value;
          } else {
            state.data.selected.village = _.find(
              state.data.options.village,
              (v) => v.value === value?.value
            );
          }
          break;
        case 'reset':
          state.data.selected.province = {};
          state.data.selected.regency = {};
          state.data.selected.district = {};
          state.data.selected.village = {};
          break;
      }
    },
    removeLevelFromGeoJSON: (state, action) => {
      const { payload } = action;
      state.data.geojson.features = _.filter(
        state.data.geojson.features,
        (v) => v.properties.level !== payload.level?.toUpperCase()
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(advanceSearchAsyncActionGetProvince.pending, (state) => {
      state.status_GET = 'LOADING';
      state.data.options.province = [];
    });
    builder.addCase(advanceSearchAsyncActionGetProvince.rejected, (state) => {
      state.status_GET = 'FAILED';
    });
    builder.addCase(
      advanceSearchAsyncActionGetProvince.fulfilled,
      (state, action) => {
        state.status_GET = 'SUCCESS';
        state.data.options.province = action.payload.data;
      }
    );
    builder.addCase(advanceSearchAsyncActionGetRegency.pending, (state) => {
      state.status_GET = 'LOADING';
    });
    builder.addCase(advanceSearchAsyncActionGetRegency.rejected, (state) => {
      state.status_GET = 'FAILED';
    });
    builder.addCase(
      advanceSearchAsyncActionGetRegency.fulfilled,
      (state, action) => {
        state.status_GET = 'SUCCESS';
        state.data.options.regency = action.payload.data;
      }
    );
    builder.addCase(advanceSearchAsyncActionDistrict.pending, (state) => {
      state.status_GET = 'LOADING';
    });
    builder.addCase(advanceSearchAsyncActionDistrict.rejected, (state) => {
      state.status_GET = 'FAILED';
    });
    builder.addCase(
      advanceSearchAsyncActionDistrict.fulfilled,
      (state, action) => {
        state.status_GET = 'SUCCESS';
        state.data.options.district = action.payload.data;
      }
    );
    builder.addCase(advanceSearchAsyncActionVillage.pending, (state) => {
      state.status_GET = 'LOADING';
    });
    builder.addCase(advanceSearchAsyncActionVillage.rejected, (state) => {
      state.status_GET = 'FAILED';
    });
    builder.addCase(
      advanceSearchAsyncActionVillage.fulfilled,
      (state, action) => {
        state.status_GET = 'SUCCESS';
        state.data.options.village = action.payload.data;
      }
    );
    builder.addCase(advanceSearchAsyncActionGeoJson.pending, (state) => {
      state.status_GEOJSON = 'LOADING';
      state.data.geojson.features = [];
    });
    builder.addCase(advanceSearchAsyncActionGeoJson.rejected, (state) => {
      state.status_GEOJSON = 'FAILED';
    });
    builder.addCase(
      advanceSearchAsyncActionGeoJson.fulfilled,
      (state, { payload }) => {
        state.status_GEOJSON = 'SUCCESS';
        state.data.geojson = payload.data;
      }
    );
    builder.addCase(advanceSearchAsyncActionSearch.pending, (state) => {
      state.status_SEARCH = 'LOADING';
    });
    builder.addCase(
      advanceSearchAsyncActionSearch.rejected,
      (state, { error }) => {
        state.status_SEARCH = 'FAILED';
        state.message = error.message || '';
      }
    );
    builder.addCase(
      advanceSearchAsyncActionSearch.fulfilled,
      (state, { payload }) => {
        state.status_SEARCH = 'SUCCESS';
        state.data.searchOptions = payload.data;
      }
    );
  },
});

export const { reducer: advanceSearchReducer, action: advanceSearchAction } =
  advanceSearchSlice;
