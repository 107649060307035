import React from 'react';
import PropTypes from 'prop-types';

export default function InputField(props) {
  const {
    endadornment,
    className,
    input,
    label,
    placeholder,
    startadornment,
    type,
    variant,
  } = props;

  const customClass = `inputField__${variant}`;

  return (
    <div className={customClass}>
      <div className='label'>{label}</div>
      <div className='inputContainer'>
        {startadornment && <div className='adornment'>{startadornment}</div>}
        <input
          className={`input ${className}`}
          name={input.name}
          placeholder={placeholder}
          type={type}
          {...input}
        />
        {endadornment && <div className='adornment'>{endadornment}</div>}
      </div>
    </div>
  );
}

InputField.defaultProps = {
  className: '',
  endadornment: null,
  input: {},
  label: null,
  name: '',
  placeholder: '',
  startadornment: null,
  type: 'text',
  variant: 'outlined',
};

InputField.propTypes = {
  className: PropTypes.string,
  endadornment: PropTypes.node,
  input: PropTypes.object,
  label: PropTypes.node,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  startadornment: PropTypes.node,
  type: PropTypes.string,
  variant: PropTypes.oneOf(['contained', 'outlined']),
};
