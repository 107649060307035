import { createAsyncThunk } from '@reduxjs/toolkit';

import { CustomPOIService } from '../service';
import { CUSTOM_POI_ACTION } from './customPOI.slice';

const customPOIService = new CustomPOIService();

export const CustomPOIAsyncAction = {
  getList: createAsyncThunk('LI/GENSET/CustomPOI/getList', async (param, thunk) => {
    try {
      const response = await customPOIService.getAll(param.query);
      thunk.dispatch(CUSTOM_POI_ACTION.setAll(response.results));

      return response;
    } catch (error) {
      return thunk.rejectWithValue(error);
    }
  }),
};
