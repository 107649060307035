import React, { Fragment, useEffect } from 'react';
import { usePlace } from '../../../hooks/usePlace';
import { debounce, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import '../Dropdown.scss';
import Dropdown from '../../Dropdown';

export function SearchDistrict(props) {
  const PLACE = usePlace();

  useEffect(() => {
    if (props.idCity) {
      getData('', props.idCity);
    }
  }, [props.idCity]);

  useEffect(() => {
    if (!isEmpty(PLACE.state.params.kecamatan)) {
      props.isDistrict && props.isDistrict(PLACE.state.params.kecamatan);
    }
  }, [PLACE.state.params.kecamatan]);

  const getData = (value, idCity) => {
    const params = {
      meta: {
        filter: { keyword: value, id_city: idCity },
        page: 1,
        pageSize: 100,
      },
    };
    PLACE.get_district({ params });
  };

  const getDataDebounce = debounce(getData, 800);

  return (
    <Fragment>
      <Dropdown
        disabled={isEmpty(PLACE.state.params.kota)}
        handler={{
          select: (ctx) => {
            PLACE.setParams({ type: 'kecamatan', ...ctx });
            PLACE.removeParams({ desa: null });
            props.isDistrict && props.isDistrict(ctx);
            props?.setVillage?.(null);
          },
          clear: () => {
            PLACE.removeParams({ kecamatan: null });
            PLACE.removeParams({ desa: null });
            props.setVillage && props.setVillage(null);
          },
          search: (value) => {
            getDataDebounce(value, props.idCity);
          },
        }}
        isLoading={PLACE.state.district?.status_GET === 'LOADING'}
        options={PLACE.state.district?.list}
        placeholder='District'
        selected={{
          id: PLACE.state.params.kecamatan?.id_kecamatan,
          name: PLACE.state.params.kecamatan?.nama_kecamatan,
          code: PLACE.state.params.kecamatan?.kode_kecamatan,
        }}
        type='kecamatan'
      />
    </Fragment>
  );
}

SearchDistrict.defaultProps = {
  data: undefined,
  isDistrict: undefined,
};

SearchDistrict.propTypes = {
  data: PropTypes.object,
  idCity: PropTypes.number,
  isDistrict: PropTypes.func,
  setVillage: PropTypes.func,
};
