import React from 'react';

import { AsmanDashboardLayout } from '@bvt-features/sub-module/asman/layout/AsmanDashboard.layout';
import { AsmanPDFLayout } from '@bvt-features/sub-module/asman/layout/AsmanPDF.layout';
import { MainMapMapLayout } from '@bvt-features/sub-module/mainmap/layout/MainMapMap.Layout';
import { Navigate, Outlet } from 'react-router';
import { SubModuleGateContainer } from '@bvt-features/sub-module/container';
import Page from '../../pages/bvt';

/** @type {import('react-router').RouteObject} */
export const RoutesSubModule = {
  path: 'sub-module',
  element: <SubModuleGateContainer />,
  children: [
    {
      index: true,
      element: <Navigate to={'/sub-module/map'} />,
    },
    {
      path: 'map',
      element: <MainMapMapLayout />,
      children: [
        {
          index: true,
          element: <Navigate to={'/sub-module/map/asset-management'} />,
        },
        {
          path: 'asset-management',
          element: <Page.SubModuleAsman />,
        },
      ],
    },
    {
      path: 'dashboard',
      element: (
        <div style={{ overflow: 'hidden' }}>
          <AsmanDashboardLayout />
        </div>
      ),
      children: [
        {
          index: true,
          element: <Navigate to={'/sub-module/dashboard/asset-management'} />,
        },
        {
          path: 'asset-management',
          element: <Outlet />,
          children: [
            {
              path: ':gsid',
              element: <Outlet />,
            }
          ],
        },
      ],
    },
    {
      path: 'export',
      element: <AsmanPDFLayout />,
      children: [
        {
          index: true,
          element: <Navigate to={'/sub-module/export/asset-management'} />,
        },
        {
          path: 'asset-management',
          element: <Outlet />,
          children: [
            {
              path: ':gsid',
              element: <Outlet />,
            }
          ],
        },
      ],
    },
  ]
};
