import React from 'react';
import PropTypes from 'prop-types';

function IcNotification({ className, onClick, active }) {
  return (
    <div
      className={`
        ${className} icon__notification 
        ${active && 'icon__notification--active'}
      `}
      onClick={onClick}
    >
      <svg
        height='16'
        viewBox='0 0 15.918 16'
        width='15.918'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M15,14H10a2,2,0,0,1-4,0H1a.961.961,0,0,1-.9-.7,1.068,1.068,0,0,1,.3-1.1A4.026,4.026,0,0,0,2,9V6A6,6,0,0,1,14,6V9a4.026,4.026,0,0,0,1.6,3.2.947.947,0,0,1,.3,1.1A.961.961,0,0,1,15,14Z'
          data-name='Path 1'
          fill='#4d4f5c'
          fillRule='evenodd'
          id='Path_1'
          transform='translate(-0.063)'
        />
      </svg>
    </div>
  );
}

export default IcNotification;

IcNotification.defaultProps = {
  active: false,
  className: '',
  onClick: () => {},
};

IcNotification.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
