import React from 'react';

function IconInfoSimple() {
  return (
    <div className='iconInfoSimple'>
      <svg
        height='16.167'
        viewBox='0 0 16.167 16.167'
        width='16.167'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M9.275,14.125h1.617V9.275H9.275ZM10.084,2a8.084,8.084,0,1,0,8.084,8.084A8.087,8.087,0,0,0,10.084,2Zm0,14.551a6.467,6.467,0,1,1,6.467-6.467A6.475,6.475,0,0,1,10.084,16.551ZM9.275,7.659h1.617V6.042H9.275Z'
          fill='#007e75'
          id='ic_info_outline_24px'
          transform='translate(-2 -2)'
        />
      </svg>
    </div>
  );
}

export default IconInfoSimple;
