import React from 'react';
export const IconMinus = () => (
  <svg
    fill='none'
    height='1rem'
    viewBox='0 0 24 24'
    width='1rem'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_5244_27234)'>
      <path
        d='M19.8571 10H4.14286C2.95939 10 2 10.8954 2 12C2 13.1046 2.95939 14 4.14286 14H19.8571C21.0406 14 22 13.1046 22 12C22 10.8954 21.0406 10 19.8571 10Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_5244_27234'>
        <rect fill='white' height='4' transform='translate(2 10)' width='20' />
      </clipPath>
    </defs>
  </svg>
);
