import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@bvt-atoms';
import { ResumeIcon } from '@bvt-icons';

export default function ActionTableButton(props) {
  const { className, text, Icon, onClick } = props;
  return (
    <div>
      <Button className={`action-btn-table ` + className} onClick={onClick}>
        {Icon}
        <div className='action-btn-table__content'>{text}</div>
      </Button>
    </div>
  );
}

ActionTableButton.defaultProps = {
  className: '',
  height: '0.875rem',
  Icon: <ResumeIcon height='0.875rem' key={1} width='1.25rem' />,
  onClick: () => {},
  text: '',
  width: '1.25rem',
};

ActionTableButton.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  Icon: PropTypes.element,
  onClick: PropTypes.func,
  text: PropTypes.string,
  width: PropTypes.string,
};
