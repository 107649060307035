import React, { useEffect, useRef } from 'react';
import { TileLayer } from 'react-leaflet';
import _ from 'lodash';
import { useMainMap } from '../../hook/useMainMap';

export const MainMapTileLayerContainer = () => {
  const ref = useRef(null);
  const mainmap = useMainMap();

  useEffect(() => {
    if (ref.current) {
      ref.current.setUrl(
        _.find(
          mainmap.state.baseMapList,
          (v) => v.key === mainmap.state.selectedBaseMap
        )?.tile || '',
        false
      );
    }
  }, [mainmap.state.selectedBaseMap]);

  return (
    <TileLayer
      id='base'
      ref={ref}
      url='https://tile.openstreetmap.org/{z}/{x}/{y}.png'
    />
  );
};
