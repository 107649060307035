import { IconSecurity } from '@bvt-assets/icon/icon-security';
import { IconUser } from '@bvt-assets/icon/icon-user';
import React from 'react';

const ACCOUNT_TAB_ITEM = [
  {
    key: 'personal-details',
    icon: <IconUser />,
    label: 'Personal details',
  },
  {
    key: 'security-and-login',
    icon: <IconSecurity />,
    label: 'Security & Login',
  },
];

export { ACCOUNT_TAB_ITEM };
