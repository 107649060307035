import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Faisal <ahmad.faisal@bvarta.com>
 * @param {Object} props
 * @param {string} props.className
 */
export default function IconSecurity(props) {
  const { className } = props;

  return (
    <div className={`${className} icon-user`}>
      <svg
        height='13.713'
        viewBox='0 0 12.746 13.713'
        width='12.746'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M1.822,2.67q0,1.658.017,2.45t.133,2.1A8.74,8.74,0,0,0,2.329,9.21q.241.69.689,1.677a5.609,5.609,0,0,0,1.128,1.667,17.8,17.8,0,0,0,1.693,1.462A17.354,17.354,0,0,0,8.2,15.525a17.315,17.315,0,0,0,2.357-1.509,17.874,17.874,0,0,0,1.693-1.462,5.6,5.6,0,0,0,1.128-1.667q.448-.987.689-1.677a8.705,8.705,0,0,0,.357-1.993q.116-1.3.133-2.1t.017-2.45a23.354,23.354,0,0,0-6.373-.857,23.354,23.354,0,0,0-6.373.857Z'
          fill='#657991'
          id='security'
          transform='translate(-1.822 -1.813)'
        />
      </svg>
    </div>
  );
}

IconSecurity.defaultProps = {
  className: '',
};

IconSecurity.propTypes = {
  className: PropTypes.string,
};
