import React from 'react';
import PropTypes from 'prop-types';
import * as BvtIcon from 'bvt-icon';
import './PopupContent.scss';
import { Svg } from '@bvt-atoms';
import { getIcon } from '@bvt-shared/utils/iconUtils';

export default function PopupContent(props) {
  return (
    <div className='SiproPOI__popup'>
      <div className='header'>
        <div className='icon'>
          {
            props.properties.category_icon
              ? <Svg src={getIcon('poi', props?.properties?.category_icon)} type='link' />
              : <BvtIcon.DefaultPoi height='22' width='22' />
          }
        </div>
        <div className='wrapper-title'>
          <div className='title'>
            {props.properties?.poi_name}
          </div>
          <div className='subtitle'>
            {props.properties?.industry_name}
          </div>
        </div>
      </div>
      <p className='address'>
        {props.properties?.address}
      </p>
    </div>
  );
}

PopupContent.propTypes = {
  properties: PropTypes.object,
};
