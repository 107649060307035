import React from 'react';
import { IconPOIData } from '@bvt-assets/icon/icon-poiData';
import { IconTelcoData } from '@bvt-assets/icon/icon-telcoData';
import { IconThematicData } from '@bvt-assets/icon/icon-thematicData';
import { IconYourData } from '@bvt-assets/icon/icon-yourData';

export const BUTTON_GROUP_DATA = [
  {
    key: 'THEMATIC',
    label: 'Thematic',
    disabled: false,
    icon: <IconThematicData />,
    permission: 'DATEX.THEMATIC',
  },
  {
    key: 'POI',
    label: 'POI',
    disabled: false,
    icon: <IconPOIData />,
    permission: 'DATEX.POI',
  },
  {
    key: 'TELCO',
    label: 'Telco Data',
    disabled: false,
    icon: <IconTelcoData />,
    permission: 'DATEX.TELCO',
  },
  {
    key: 'YOUR_DATA',
    label: 'Your Data',
    disabled: true,
    icon: <IconYourData />,
    permission: '',
  },
  {
    key: 'THEMATIC_RINJANI',
    label: 'Thematic-R',
    disabled: false,
    icon: <IconThematicData />,
    permission: 'RND.THEMATIC-RINJANI',
  },
  {
    key: 'POI_RINJANI',
    label: 'POI-R',
    disabled: false,
    icon: <IconPOIData />,
    permission: 'RND.POI-RINJANI',
  },
];
