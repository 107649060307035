import * as React from 'react';
const IconChevron = (props) => (
  <svg
    fill='none'
    height={6}
    viewBox='0 0 8 6'
    width={8}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M4.69885 5.20664C4.61024 5.2991 4.50252 5.37292 4.38251 5.42342C4.26251 5.47392 4.13284 5.5 4.00175 5.5C3.87065 5.5 3.74099 5.47392 3.62098 5.42342C3.50098 5.37292 3.39325 5.2991 3.30465 5.20664L0.252352 2.02061C0.126993 1.89127 0.0438647 1.72969 0.0131879 1.55574C-0.0174885 1.38179 0.00562382 1.20305 0.0796833 1.0415C0.153742 0.879956 0.275521 0.74264 0.430045 0.646436C0.584569 0.550233 0.765104 0.499337 0.949452 0.500007L7.05404 0.500007C7.23766 0.49999 7.41731 0.551113 7.57107 0.647139C7.72484 0.743165 7.84607 0.879943 7.91998 1.04078C7.99389 1.20162 8.01729 1.37957 7.98731 1.55292C7.95732 1.72627 7.87527 1.88752 7.75114 2.01699L4.69885 5.20664Z'
      fill={props?.fill || '#6C757D'}
    />
  </svg>
);
export { IconChevron };
