import React from 'react';
import { MainMapLayerVisibilityItem } from '../MainMapLayerVisibilityItem';
import IMAGE_CABINET from '@bvt-assets/images/CabinetIllustration.png';
import './MainMapLayerVisibilityItemList.scss';

/**
 * @author randa<m.randa@bvarta.com>
 * @author Malik<malik.ibrahim@bvarta.com>
 * @param {object} props
 * @param {Array<{key:string,opacity:number,isActive:boolean,title:string,description:Array<string>}>} props.data
 * @param {(e:{key:string,opacity:number,isActive:boolean,title:string})=>void} props.onChange
 * @returns
 */
export const MainMapLayerVisibilityItemList = (props) => {
  const { data, onChange } = props;
  return (
    <div className='feature-mainmap MainMapLayerVisibilityItemList'>
      {data?.length > 0 ? (
        data?.map((v) => (
          <MainMapLayerVisibilityItem
            description={v.description}
            isActive={v.isActive}
            key={v.key}
            onChange={(v2) =>
              onChange({
                isActive: v2.isActive,
                key: v.key,
                opacity: v2.opacity,
                title: v2.title,
              })
            }
            opacity={v.opacity}
            title={v.title}
          />
        ))
      ) : (
        <div className='MainMapLayerVisibilityItemList__not-found-wrap'>
          <img alt='Cabinet i' className='MainMapLayerVisibilityItemList__not-found-image' src={IMAGE_CABINET} />
          <div className='MainMapLayerVisibilityItemList__not-found-title'>No layer present on map</div>
        </div>
      )}
    </div>
  );
};
