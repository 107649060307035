import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@bvt-atoms';
import { Col, Row } from 'antd';
import DOMPurify from 'dompurify';
import { useNavigate } from 'react-router';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import DynamicIcon from '@bvt-icons';
import { LinkBoxSquare } from '@bvt-shared/component/LinkBox/LinkBoxSquare';

export default function MainOverview(props) {
  const { data, parent, path } = props;
  const navigate = useNavigate();
  return (
    <div className='new-overview'>
      <Row className='new-overview__header'>
        <span>Overview</span>
      </Row>
      <Row className='new-overview__title'>
        <Col className='new-overview__title--text' span={6}>
          {data.name}
        </Col>
        <Col span={18}>
          <LinkBoxSquare
            className='new-overview__title--icon'
            src='ic-lb-site-profiling'
          />
        </Col>
      </Row>
      <Button
        className='new-overview__button button__contained button__large'
        key={'launch'}
        onClick={() => {
          if (parent === data.slug) {
            navigate(`/app/${path}/${parent}`);
          } else {
            navigate(`/app/${path}/${parent}/${data.slug}`);
          }
        }}
      >
        <h5 className='text-white'>LAUNCH</h5>
      </Button>
      <Row className='new-overview__video'>
        <Swiper
          className='new-overview__video__swiper'
          modules={[Navigation, Pagination]}
          navigation={{
            nextEl: '.new-overview__video__swiper--next',
            prevEl: '.new-overview__video__swiper--prev',
          }}
          slidesPerView={1}
        >
          <SwiperSlide className='new-overview__video__swiper__slide'>
            <img src={data.image} />
          </SwiperSlide>
          <SwiperSlide className='new-overview__video__swiper__slide'>
            <img src={data.image} />
          </SwiperSlide>
        </Swiper>
        <div className='new-overview__video__swiper__arrow'>
          <div className='new-overview__video__swiper--prev'>
            <DynamicIcon
              className='new-overview__video__swiper__icon--prev'
              height='1rem'
              icon='arrow'
              width='1rem'
            />
          </div>
          <div className='new-overview__video__swiper--next'>
            <DynamicIcon
              className='new-overview__video__swiper__icon--next'
              height='1rem'
              icon='arrow'
              width='1rem'
            />
          </div>
        </div>
        {/* <VideoPlayer
          src={
            'https://player.vimeo.com/external/528647765.sd.mp4?s=027ac6c8f8c1d0c04b4f4216fd7db55e2076b279&profile_id=165&oauth2_token_id=57447761'
          }
        /> */}
      </Row>
      <Row className='new-overview__desc'>Description</Row>
      <Row className='new-overview__desc--content'>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(data.description),
          }}
        />
      </Row>
    </div>
  );
}

MainOverview.defaultProps = {
  data: {},
  parent: '',
  path: '',
};

MainOverview.propTypes = {
  data: PropTypes.object,
  parent: PropTypes.string,
  path: PropTypes.string,
};
