import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Faisal <ahmad.faisal@bvarta.com>
 * @param {Object} props
 * @param {string} props.className
 * @param {string} props.disable
 * @param {string} props.onClick
 */

export default function IconEdit(props) {
  const { className, disable, onClick } = props;

  return (
    <div
      className={`${className} icon-edit ${disable ? 'disable' : ''}`}
      onClick={onClick}
    >
      <svg
        height='21'
        viewBox='0 0 21 21'
        width='21'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g id='ic-contact-edit' transform='translate(0 0.126)'>
          <rect
            data-name='Rectangle 86'
            fill='none'
            height='21'
            id='Rectangle_86'
            transform='translate(0 -0.126)'
            width='21'
          />
          <g id='Layer_x0020_1' transform='translate(2.954 2.821)'>
            <path
              d='M13.535,1.658l.852.852a2.8,2.8,0,0,1-.106,3.833L7.04,13.584c-.213.213-.426.213-.745.319L1.184,15.075A.985.985,0,0,1,.012,13.9L1.184,8.686c.106-.319.106-.532.319-.745L8.744.7a2.8,2.8,0,0,1,3.833.106l.852.852ZM6.4,11.561l4.9-4.9L8.531,3.894l-4.9,4.9L6.4,11.561Zm-1.917.852-1.7-1.7-.532,2.13,2.13-.532Zm5.431-9.9,2.769,2.769L13,4.959a.709.709,0,0,0,0-1.065c-.532-.532-1.065-1.171-1.7-1.7a.711.711,0,0,0-1.065,0Z'
              data-name='Path 3429'
              fill='#364961'
              id='Path_3429'
              transform='translate(0 0)'
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

IconEdit.defaultProps = {
  className: '',
  disable: false,
  onClick: () => {},
};

IconEdit.propTypes = {
  className: PropTypes.string,
  disable: PropTypes.bool,
  onClick: PropTypes.func,
};
