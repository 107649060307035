import Resource from '../../../../api/resource';

export class AuthService extends Resource {
  constructor() {
    super('users', '/api', 'auth', 'v2');
  }
  /**
   * user do login.
   * @param {Object} data - Data for login.
   * @param {string} data.email - The username or email.
   * @param {string} data.password - The password.
   */
  login(data) {
    const remapData = {
      email_username: data.email,
      password: data.password,
    };
    this.setAdditionUri('/login');
    return this.create(remapData);
  }

  logout() {
    this.setAdditionUri('/logout');
    return this.create();
  }

  checkProfile() {
    this.setAdditionUri('/profile');
    return this.get(undefined, undefined);
  }
  /**
   * user forgot password.
   * @param {Object} param - Data for login.
   * @param {string} param.email - The username or email.
   */
  forgetPassword(param) {
    const remapData = {
      request_from: 'LI',
      email_username: param.email,
      li_version: 'V3',
      fe_link: process.env.FRONTEND_URL,
    };
    this.setAdditionUri('/send-reset-email');
    return this.create(remapData);
  }
  /**
   * user reset password.
   * @param {Object} param - Data for login.
   * @param {string} param.password - The username or email.
   * @param {string} param.password_confirmation - The username or email.
   * @param {string} param.token - The username or email.
   */
  resetPasswordByToken(param) {
    const remapData = {
      password: param.password,
      confirm: param.password_confirmation,
      forgot_token: param.token,
    };
    this.setAdditionUri('/update-password');
    return this.create(remapData);
  }

  getSetting() {
    this.setAdditionUri('/setting');
    return this.get();
  }

  updateSetting(v) {
    this.setAdditionUri('/setting');
    return this.create(v);
  }

  checkTokenResetPassword(token) {
    this.setAdditionUri(`/reset-password/${token}`);
    return this.get();
  }
}
