import React, { useEffect } from 'react';
import { Button, Col, Modal, Row, Select, Typography } from 'antd';
import { useFormik } from 'formik';
import * as yup from 'yup';
import './AdvanceSearchMapSearchModal.scss';

export const AdvanceSearchMapSearchModalComponent = (props) => {
  const { isOpen, onCancel, onConfirm, options, value, onChange, isLoading } =
    props;
  const {
    values: formValue,
    errors: formError,
    setFieldValue: formSetValue,
    submitForm: formSubmit,
    resetForm: formReset,
  } = useFormik({
    initialValues: {
      province: undefined,
      regency: undefined,
      district: undefined,
      village: undefined,
    },
    validationSchema: yup.object({
      province: yup.number().required(),
    }),
    validateOnMount: true,
    onSubmit: (v) => {
      typeof onConfirm === 'function' && onConfirm(v);
    },
  });

  const internalHandleCancel = () => {
    formReset();
    typeof onCancel === 'function' && onCancel(formValue);
  };

  const internalHandleChangeProvince = async (v) => {
    formSetValue('province', v).then(() => {
      formSetValue('regency', undefined);
      formSetValue('district', undefined);
      formSetValue('village', undefined);
    });
  };

  const internalHandleChangeRegency = async (v) => {
    formSetValue('regency', v).then(() => {
      formSetValue('district', undefined);
      formSetValue('village', undefined);
    });
  };

  const internalHandleChangeDistrict = async (v) => {
    formSetValue('district', v).then(() => {
      formSetValue('village', undefined);
    });
  };

  const internalHandleChangeVillage = async (v) => {
    formSetValue('village', v);
  };

  useEffect(() => {
    if (value) {
      Object.keys(value).forEach((v) => {
        formSetValue(v, value[v]);
      });
    }
  }, [isOpen]);

  useEffect(() => {
    onChange && onChange(formValue);
  }, [formValue]);

  return (
    <Modal
      centered
      className='AdvanceSearchMapSearchModal'
      closable
      closeIcon={' '}
      footer={''}
      onCancel={() => internalHandleCancel()}
      open={isOpen}
      width={312}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Typography.Text className='title'>
            Advanced Search
          </Typography.Text>
        </Col>
        <Col xs={24}>
          <Select
            allowClear={true}
            disabled={isLoading}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            maxLength={1}
            maxTagCount={1}
            onChange={(v) => internalHandleChangeProvince(v)}
            options={options?.province}
            placeholder='Province'
            showSearch
            style={{ width: '100%' }}
            value={formValue.province}
          />
        </Col>
        <Col xs={24}>
          <Select
            allowClear={true}
            disabled={!formValue.province || isLoading}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            onChange={(v) => internalHandleChangeRegency(v)}
            options={options?.regency}
            placeholder='Regency'
            showSearch
            style={{ width: '100%' }}
            value={formValue.regency}
          />
        </Col>
        <Col xs={24}>
          <Select
            allowClear={true}
            disabled={!formValue.regency || isLoading}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            onChange={(v) => internalHandleChangeDistrict(v)}
            options={options?.district}
            placeholder='District'
            showSearch
            style={{ width: '100%' }}
            value={formValue.district}
          />
        </Col>
        <Col xs={24}>
          <Select
            allowClear={true}
            disabled={!formValue.district || isLoading}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            onChange={(v) => internalHandleChangeVillage(v)}
            options={options?.village}
            placeholder='Village'
            showSearch
            style={{ width: '100%' }}
            value={formValue.village}
          />
        </Col>
        <Col xs={12}>
          <Button block className='button' onClick={() => internalHandleCancel()}>
            Cancel
          </Button>
        </Col>
        <Col xs={12}>
          <Button
            block
            className={`button ${!formError.province && 'button-active'}`}
            disabled={!!formError.province}
            onClick={() => formSubmit()}
            type='primary'
          >
            Search
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
