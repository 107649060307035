import React from 'react';

import { SharedBaseCardTypeOneComponent } from '@bvt-shared/component/SharedBaseCardTypeOne.component';
import './AsmanCardRadiusInfo.component.scss';

export const AsmanCardRadiusInfoComponent = props => {
  const { data, isLoading } = props;
  return (
    <SharedBaseCardTypeOneComponent
      isLoading={isLoading}
      showIcon
      title={
        <React.Fragment>
          <span>Radius Info</span>
          <span className='AsmanCardRadiusInfoComponent__title-accent' />
        </React.Fragment>
      }
    >
      <div className='AsmanCardRadiusInfoComponent__list'>
        {data.map((item) => (
          <div
            className='AsmanCardRadiusInfoComponent__list--item'
            key={item.title}
          >
            <div className='AsmanCardRadiusInfoComponent__list--item-title'>
              {item.title}
            </div>
            <div className='AsmanCardRadiusInfoComponent__list--item-value'>
              <div className='AsmanCardRadiusInfoComponent__list--item-icon'>
                {item.icon}
              </div>
              <div>{item.value}</div>
            </div>
          </div>
        ))}
      </div>
    </SharedBaseCardTypeOneComponent>
  );
};
