import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Faisal <ahmad.faisal@bvarta.com>
 * @param {Object} props
 * @param {string} props.className
 * @param {string} props.onClick
 */

export default function IconMinus(props) {
  const { className, onClick } = props;

  return (
    <div className={`${className} icon-minus`} onClick={onClick}>
      <svg
        height='3'
        viewBox='0 0 14 3'
        width='14'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect
          data-name='Rectangle 15'
          fill='#364961'
          height='3'
          id='Rectangle_15'
          rx='1.5'
          width='14'
        />
      </svg>
    </div>
  );
}

IconMinus.defaultProps = {
  className: '',
  onClick: () => {},
};

IconMinus.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
