import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { APP_STORE_PREFIX_CONSTANT } from '@bvt-shared/constant/APP_PREFIX_CONSTANT';

import {
  asmanAsyncActionGetDashboardPoiCollection,
  asmanAsyncActionGetDashboardResultByRadiusAndSet,
  asmanAsyncActionList,
  asmanAsyncActiongAvailableRadiusInfoAndStatusAnalyze
} from './asman.asyncAction';
import { ASMAN_STORE_CONSTANT } from '../constant/ASMAN_CONSTANT';

const initialState = {
  status_LIST_ASSET: 'IDLE',
  status_POI_COLLECTION: 'IDLE',
  status_DASHBOARD_RESULT: 'IDLE',
  STATUS_DASHBOARD_POI_COLLECTION: 'IDLE',
  status_DASHBOARD_RADIUS_LIST: 'IDLE',
  description: '',
  message: '',
  data: {
    list: {
      geojson: {
        features: [],
        type: 'FeatureCollection'
      },
      filteredGeojson: {
        features: [],
        type: 'FeatureCollection'
      },
      activeTab: 'LIST',
      isShowAll: false,
      searchValue: '',
      page: 1,
      perPage: 10
    },
    dashboard: {
      geojson: {
        poi: [],
        radius: {
          type: 'FeatureCollection',
          features: []
        }
      }
    }
  }
};
export const asmanSlice = createSlice({
  name: APP_STORE_PREFIX_CONSTANT + ASMAN_STORE_CONSTANT,
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    resetDashboard: state => {
      state.status_DASHBOARD_RADIUS_LIST = 'IDLE';
      state.status_DASHBOARD_RESULT = 'IDLE';
      state.data.dashboard = {
        geojson: {
          poi: [],
          radius: {
            type: 'FeatureCollection',
            features: []
          }
        }
      };
    },
    setShowAll: (state, action) => {
      const { payload } = action;
      state.data.list.isShowAll = payload.isShowAll ? true : false;
    },
    setActiveTab: (state, action) => {
      const { payload } = action;
      if (payload.type) {
        state.data.list.activeTab = payload.type;
      }
    },
    setPagination: (state, action) => {
      const { payload } = action;

      if (payload.page) {
        state.data.list.page = payload.page;
      }
      if (payload.perPage) {
        state.data.list.perPage = payload.perPage;
      }
    },
    setSearch: (state, action) => {
      const { payload } = action;
      state.data.list.searchValue = payload.search;
      state.data.list.filteredGeojson = {
        type: 'FeatureCollection',
        features:
          _.filter(
            state.data.list.geojson.features,
            v =>
              v.properties.name
                ?.toUpperCase()
                .includes(payload.search?.toUpperCase() || '') ||
              v.properties.address
                ?.toUpperCase()
                .includes(payload.search?.toUpperCase() || '') ||
              v.properties.usage
                ?.toUpperCase()
                .includes(payload.search?.toUpperCase() || '')
          ) || []
      };
    },
    resetStatus: (state, action) => {
      const { payload } = action;
      state.message = '';
      state.description = '';

      switch (payload.type) {
        case 'status_DASHBOARD_RESULT':
          state.status_DASHBOARD_RESULT = 'IDLE';
          break;
        case 'status_LIST_ASSET':
          state.status_LIST_ASSET = 'IDLE';
          break;
        case 'status_POI_COLLECTION':
          state.status_POI_COLLECTION = 'IDLE';
          break;
        case 'status_DASHBOARD_RADIUS_LIST':
          state.status_DASHBOARD_RADIUS_LIST = 'IDLE';
          break;
        case 'STATUS_DASHBOARD_POI_COLLECTION':
          state.STATUS_DASHBOARD_POI_COLLECTION = 'IDLE';
          break;
        default:
          state.status_DASHBOARD_RESULT = 'IDLE';
          state.status_LIST_ASSET = 'IDLE';
          state.status_POI_COLLECTION = 'IDLE';
          break;
      }
      return state;
    }
  },
  extraReducers: builder =>
    builder
      .addCase(asmanAsyncActionList.pending, state => {
        state.status_LIST_ASSET = 'LOADING';
        state.message = 'Fetching Asset List';
        state.description = 'This might take a while';
        state.data.list.geojson = {
          features: [],
          type: 'FeatureCollection'
        };
        state.data.list.filteredGeojson = {
          features: [],
          type: 'FeatureCollection'
        };
      })
      .addCase(asmanAsyncActionList.rejected, (state, { payload }) => {
        state.status_LIST_ASSET = 'FAILED';
        state.description = `${payload?.message}, ${payload?.description}`;
        state.message = 'Cannot Fetch List Asset';
      })
      .addCase(asmanAsyncActionList.fulfilled, (state, { payload }) => {
        state.status_LIST_ASSET = 'SUCCESS';
        state.message = 'Successfully fetch asset list';
        state.description = '';
        state.data.list.geojson = payload.data;
        state.data.list.filteredGeojson = payload.data;
      })
      .addCase(
        asmanAsyncActiongAvailableRadiusInfoAndStatusAnalyze.pending,
        (state, { meta }) => {
          state.data.dashboard.gsid = meta.arg.gsid;
          state.status_DASHBOARD_RADIUS_LIST = 'LOADING';
          state.message = 'Fetching Radius List';
          state.description = 'Getting radius info';
        }
      )
      .addCase(
        asmanAsyncActiongAvailableRadiusInfoAndStatusAnalyze.rejected,
        (state, { payload }) => {
          state.data.dashboard.gsid = undefined;
          state.status_DASHBOARD_RADIUS_LIST = 'FAILED';
          state.message = 'Failed To fetch radius list';
          state.description = `${payload?.message}, ${payload?.description}`;
        }
      )
      .addCase(
        asmanAsyncActiongAvailableRadiusInfoAndStatusAnalyze.fulfilled,
        (state, { payload }) => {
          state.status_DASHBOARD_RADIUS_LIST = 'SUCCESS';
          state.message = 'Successfully fetch radius list';
          state.description = '';
          state.data.dashboard.radiusList = payload.data;
          state.data.dashboard.selectedRadius = payload.data?.[0];
        }
      )
      .addCase(
        asmanAsyncActionGetDashboardResultByRadiusAndSet.pending,
        (state, { meta }) => {
          state.status_DASHBOARD_RESULT = 'LOADING';
          state.message = 'Fetching Dashboard Result';
          state.data.dashboard.gsid = meta.arg.gsid;
          state.data.dashboard.selectedRadius = meta.arg.radius;
        }
      )
      .addCase(
        asmanAsyncActionGetDashboardResultByRadiusAndSet.rejected,
        (state, { payload }) => {
          state.status_DASHBOARD_RESULT = 'FAILED';
          state.message = 'Failed To Fetch Dashboard Result';
          state.description = `${payload?.message}, ${payload?.description}`;
        }
      )
      .addCase(
        asmanAsyncActionGetDashboardResultByRadiusAndSet.fulfilled,
        (state, { payload }) => {
          state.status_DASHBOARD_RESULT = 'SUCCESS';
          state.data.dashboard.assetDetail = payload.data.asmanDetail;
          state.data.dashboard.education = payload.data.education;
          state.data.dashboard.radiusInfo = payload.data.radiusInfo;
          state.data.dashboard.genderInfo = payload.data.genderInfo;
          state.data.dashboard.sesInfo = payload.data.sesInfo;
          state.data.dashboard.professionInfo = payload.data.professionInfo;
          state.data.dashboard.zoningInfo = payload.data.zoningInfo;
          state.data.dashboard.demographyCatchementInfo =
            payload.data.demographyCatchementInfo;
          state.data.dashboard.landValueInfo = payload.data.landValueInfo;
          state.data.dashboard.topPoiInfo = payload.data.topPoiInfo;
          state.data.dashboard.geojson.radius = payload.data.geojson;
          state.data.dashboard.brandByIndustryInfo =
            payload.data.brandByIndustryInfo;
          state.data.dashboard.propertyPriceInfo =
            payload.data.propertyPriceInfo;
        }
      )
      .addCase(asmanAsyncActionGetDashboardPoiCollection.pending, state => {
        state.STATUS_DASHBOARD_POI_COLLECTION = 'LOADING';
        state.message = 'Fetching Dashboard Result (POI Collection)';
        state.data.dashboard.geojson.poi = [];
      })
      .addCase(
        asmanAsyncActionGetDashboardPoiCollection.rejected,
        (state, { payload }) => {
          state.STATUS_DASHBOARD_POI_COLLECTION = 'FAILED';
          state.message = 'FAiled to fetch Dashboard Result (POI Collection)';
          state.description = `${payload?.message}, ${payload?.description}`;
        }
      )
      .addCase(
        asmanAsyncActionGetDashboardPoiCollection.fulfilled,
        (state, { payload }) => {
          state.STATUS_DASHBOARD_POI_COLLECTION = 'SUCCESS';
          state.message = 'Succesfullt fetch dashboard result (POI Collection)';
          state.description = `${payload?.message}, ${payload?.description}`;
          state.data.dashboard.geojson.poi = payload.data;
        }
      )
});

export const { reducer: asmanReducer } = asmanSlice;
