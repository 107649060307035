import Resource from '../../../api/resource';

/**
 * @copyright PT Bhumi Varta Techonology
 * @author Renta<malik.ibrahim@bvarta.com>
 */

export class DatexThematicService extends Resource {
  constructor() {
    super('datex/thematic', '/api', 'api-poi', 'v2');
  }


  /**
   * @param {Object} _body
   * @param {"red"|"blue"|"yellow"|"gray"|"random"} _body.color
   * @param {Array<Number>} _body.attribute
   * @param {Number} _body.region
   * @param {'province' | 'regency' | 'district' | 'village'} _body.levelData
   * @param {'national' | 'province' | 'regency' | 'district'} _body.levelRegion
   * @param {Number} _body.country_id
   */
  AnalyzeDatexThematic(_body) {
    const data = _body;
    this.setAdditionUri('/style');
    return this.create(data);
  }


  GetThematicCategoryList(keyword,countryId = 1) {
    let url = `/categories?country_id=${countryId}`;
    if (keyword) {
      url += `&name=${keyword}`;
    }
    this.setAdditionUri(url);
    return this.list();
  }

  GetThematicSubCategoryList({ id, keyword ,countryId = 1 }) {
    let url = `/categories/${id}/subcategories?country_id=${countryId}`;
    if (keyword) {
      url += `&name=${keyword}`;
    }
    this.setAdditionUri(url);
    return this.list();
  }

  GetThematicItemList({ id, keyword,countryId = 1 }) {
    let url = `/subcategories/${id}/items?country_id=${countryId}`;
    if (keyword) {
      url += `&name=${keyword}`;
    }
    this.setAdditionUri(url);
    return this.list();
  }
}
