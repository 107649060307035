import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import './AuthForgotMessage.scss';
import LottiePlayer from 'lottie-react';
import { ForgotPassworSendToMail } from '@bvt-animations';
import { useInterval } from 'ahooks';

const COUNTER_COUNT = 60;
/**
 * @author Randa<m.randa@bvarta.com>
 * @copyright PT Bhumi Varta Technology
 * @param {object} props
 * @param {Boolean} props.isLoading
 * @param {Boolean} props.isFailed
 * @param {string} props.email
 * @param {string} props.message
 * @param {string} props.onBack
 * @param {string} props.onResend
 * @returns
 */
const AuthForgotMessage = (props) => {
  const { isLoading, isFailed, email, message, onBack, onResend } = props;
  const [counter, setCounter] = useState(COUNTER_COUNT);
  const interval = useInterval(() => {
    setCounter(counter - 1);
  }, 1000);

  useEffect(() => {
    return () => {
      interval();
    };
  }, []);

  return (
    <React.Fragment>
      <div className='feature-auth AuthForgotMessage__wrapper'>
        {isLoading && (
          <>
            <LottiePlayer
              animationData={ForgotPassworSendToMail}
              className='feature-auth ResetPasswordContainer__animation'
              loop={false}
              speed={2}
            />
            <div className='feature-auth AuthForgotMessage__status-text-2'>
              Sending . . .
            </div>
          </>
        )}
        {!isLoading && (
          <div className='feature-auth AuthForgotMessage__status-text'>
            {isFailed ? 'Failed' : 'Success !'}
          </div>
        )}
        {!isLoading && (
          <div className='feature-auth AuthForgotMessage__bottom-container'>
            <div className='recovery-m'>
              we've {isFailed && 'failed to'} sent a recovery code to <br />
              <b>{email}</b>
            </div>
            <div className='recovery-mFail'>{message}</div>
            {!isFailed && (
              <React.Fragment>
                <div className='recovery-m'>
                  if you haven't received the email, simply click the{' '}
                  <b>Resend</b>
                  &nbsp; and we'll send it again.
                </div>
                <div className='recovery-m2'>
                  If you don't see it in your inbox shortly, check your spam
                  folder or contact our admin support@bvarta.com.
                </div>
                <div className='recovery-reset'>
                  Resend in{' '}
                  <span>
                    00:
                    {counter > 0 ? (
                      <>{counter < 10 ? `${'0' + counter}` : counter}</>
                    ) : (
                      '00'
                    )}
                  </span>
                </div>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
      <div
        className='feature-auth ForgotPasswordForm__button-wrapper'
        style={{ padding: '1.5rem', marginTop: 'auto' }}
      >
        <div>
          {!isLoading && (
            <button
              className='feature-auth ForgotPasswordForm__button back-to-login'
              onClick={() => onBack()}
              type='button'
            >
              {isFailed ? 'Back To Reset Password' : 'Back To login'}
            </button>
          )}
        </div>
        {!isFailed && (
          <button
            className={`feature-auth ForgotPasswordForm__button
              ${counter > 0 &&
              'feature-auth ForgotPasswordForm__button--not-valid'}
              ${isLoading && 'feature-auth ForgotPasswordForm__button--loading'}
              `}
            disabled={counter > 0}
            onClick={() => {
              onResend();
              setCounter(COUNTER_COUNT);
            }}
          >
            Resend
          </button>
        )}
      </div>
    </React.Fragment>
  );
};
AuthForgotMessage.propTypes = {
  email: propTypes.string,
  isFailed: propTypes.bool,
  isLoading: propTypes.bool,
  message: propTypes.string,
  onBack: propTypes.func,
  onResend: propTypes.func,
};
export default AuthForgotMessage;
