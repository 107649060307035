import React from 'react';

export const IconFilterOne = () => (
  <svg
    fill='none'
    height='1rem'
    viewBox='0 0 24 24'
    width='1rem'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_5244_27251)'>
      <path
        d='M16.4444 8.66651C15.755 8.66665 15.0825 8.45301 14.5196 8.05502C13.9566 7.65704 13.5309 7.09429 13.3011 6.44429H3.11111C2.81643 6.44429 2.53381 6.32723 2.32544 6.11886C2.11706 5.91048 2 5.62787 2 5.33318C2 5.0385 2.11706 4.75588 2.32544 4.54751C2.53381 4.33913 2.81643 4.22207 3.11111 4.22207H13.3011C13.5306 3.57174 13.9562 3.0086 14.5192 2.61028C15.0821 2.21195 15.7548 1.99805 16.4444 1.99805C17.1341 1.99805 17.8068 2.21195 18.3697 2.61028C18.9327 3.0086 19.3583 3.57174 19.5878 4.22207H20.8889C21.1836 4.22207 21.4662 4.33913 21.6746 4.54751C21.8829 4.75588 22 5.0385 22 5.33318C22 5.62787 21.8829 5.91048 21.6746 6.11886C21.4662 6.32723 21.1836 6.44429 20.8889 6.44429H19.5878C19.3573 7.09384 18.9314 7.65613 18.3686 8.054C17.8058 8.45188 17.1337 8.66584 16.4444 8.66651ZM16.4444 4.22207C16.2247 4.22207 16.0099 4.28724 15.8271 4.40933C15.6444 4.53142 15.502 4.70495 15.4179 4.90798C15.3338 5.11101 15.3118 5.33441 15.3547 5.54995C15.3976 5.76548 15.5034 5.96346 15.6588 6.11886C15.8142 6.27425 16.0121 6.38007 16.2277 6.42294C16.4432 6.46582 16.6666 6.44381 16.8696 6.35971C17.0727 6.27562 17.2462 6.1332 17.3683 5.95048C17.4904 5.76776 17.5556 5.55294 17.5556 5.33318C17.5556 5.0385 17.4385 4.75588 17.2301 4.54751C17.0217 4.33913 16.7391 4.22207 16.4444 4.22207Z'
        fill='currentColor'
      />
      <path
        d='M7.55556 15.3335C6.86594 15.3339 6.19319 15.1203 5.63002 14.7223C5.06685 14.3243 4.64098 13.7615 4.41111 13.1113H3.11111C2.81643 13.1113 2.53381 12.9942 2.32544 12.7858C2.11706 12.5775 2 12.2949 2 12.0002C2 11.7055 2.11706 11.4229 2.32544 11.2145C2.53381 11.0061 2.81643 10.8891 3.11111 10.8891H4.41222C4.64173 10.2387 5.0673 9.67559 5.63027 9.27727C6.19325 8.87894 6.86591 8.66504 7.55556 8.66504C8.2452 8.66504 8.91786 8.87894 9.48084 9.27727C10.0438 9.67559 10.4694 10.2387 10.6989 10.8891H20.8889C21.1836 10.8891 21.4662 11.0061 21.6746 11.2145C21.8829 11.4229 22 11.7055 22 12.0002C22 12.2949 21.8829 12.5775 21.6746 12.7858C21.4662 12.9942 21.1836 13.1113 20.8889 13.1113H10.6989C10.4691 13.7613 10.0434 14.324 9.48043 14.722C8.91748 15.12 8.24498 15.3336 7.55556 15.3335ZM7.55556 10.8891C7.3358 10.8891 7.12098 10.9542 6.93826 11.0763C6.75553 11.1984 6.61312 11.3719 6.52902 11.575C6.44493 11.778 6.42292 12.0014 6.46579 12.2169C6.50867 12.4325 6.61449 12.6305 6.76988 12.7858C6.92527 12.9412 7.12325 13.0471 7.33879 13.0899C7.55432 13.1328 7.77773 13.1108 7.98076 13.0267C8.18379 12.9426 8.35732 12.8002 8.47941 12.6175C8.6015 12.4348 8.66667 12.2199 8.66667 12.0002C8.66667 11.7055 8.5496 11.4229 8.34123 11.2145C8.13286 11.0061 7.85024 10.8891 7.55556 10.8891Z'
        fill='currentColor'
      />
      <path
        d='M16.4444 22C15.755 22.0001 15.0825 21.7865 14.5196 21.3885C13.9566 20.9905 13.5309 20.4278 13.3011 19.7778H3.11111C2.81643 19.7778 2.53381 19.6607 2.32544 19.4524C2.11706 19.244 2 18.9614 2 18.6667C2 18.372 2.11706 18.0894 2.32544 17.881C2.53381 17.6726 2.81643 17.5556 3.11111 17.5556H13.3011C13.5306 16.9052 13.9562 16.3421 14.5192 15.9438C15.0821 15.5454 15.7548 15.3315 16.4444 15.3315C17.1341 15.3315 17.8068 15.5454 18.3697 15.9438C18.9327 16.3421 19.3583 16.9052 19.5878 17.5556H20.8889C21.1836 17.5556 21.4662 17.6726 21.6746 17.881C21.8829 18.0894 22 18.372 22 18.6667C22 18.9614 21.8829 19.244 21.6746 19.4524C21.4662 19.6607 21.1836 19.7778 20.8889 19.7778H19.5878C19.3573 20.4273 18.9314 20.9896 18.3686 21.3875C17.8058 21.7854 17.1337 21.9993 16.4444 22ZM16.4444 17.5556C16.2247 17.5556 16.0099 17.6207 15.8271 17.7428C15.6444 17.8649 15.502 18.0384 15.4179 18.2415C15.3338 18.4445 15.3118 18.6679 15.3547 18.8834C15.3976 19.099 15.5034 19.297 15.6588 19.4524C15.8142 19.6077 16.0121 19.7136 16.2277 19.7564C16.4432 19.7993 16.6666 19.7773 16.8696 19.6932C17.0727 19.6091 17.2462 19.4667 17.3683 19.284C17.4904 19.1013 17.5556 18.8864 17.5556 18.6667C17.5556 18.372 17.4385 18.0894 17.2301 17.881C17.0217 17.6726 16.7391 17.5556 16.4444 17.5556Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_5244_27251'>
        <rect fill='white' height='20' transform='translate(2 2)' width='20' />
      </clipPath>
    </defs>
  </svg>
);
