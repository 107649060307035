import { MAINMAP_MAP_MODULEBAR_CONSTANT } from './MAINMAP_MAP_MODULEBAR_CONSTANT';

export const MAINMAP_MAP_MODULEBAR_ACTION = {
  setModuleBarMenuList: (data) => {
    return (dispatch) => {
      dispatch({
        type: MAINMAP_MAP_MODULEBAR_CONSTANT.MODULE_BAR_MENU_LIST,
        payload: {
          menu_list: data,
        },
      });
    };
  },
  setModuleBarMenuSelected: (data) => {
    return (dispatch) => {
      dispatch({
        type: MAINMAP_MAP_MODULEBAR_CONSTANT.MODULE_BAR_MENU_SELECTED,
        payload: {
          menu_selected: data,
        },
      });
    };
  },
  setModuleBarSubMenuList: (data) => {
    return (dispatch) => {
      dispatch({
        type: MAINMAP_MAP_MODULEBAR_CONSTANT.MODULE_BAR_SUBMENU_LIST,
        payload: {
          submenu_list: data,
        },
      });
    };
  },
  setModuleBarSubMenuSelected: (data) => {
    return (dispatch) => {
      dispatch({
        type: MAINMAP_MAP_MODULEBAR_CONSTANT.MODULE_BAR_MENU_SELECTED,
        payload: {
          submenu_selected: data,
        },
      });
    };
  },
  resetState: () => {
    return (dispatch) => {
      dispatch({
        type: MAINMAP_MAP_MODULEBAR_CONSTANT.MODULE_BAR_RESET_STATE,
      });
    };
  },
};
