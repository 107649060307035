import React from 'react';
import { ImageBox } from '@bvt-shared/component/ImageBox';
import { getIcon } from '@bvt-shared/utils/iconUtils';
import _ from 'lodash';

import IconTelkom from '@bvt-assets/images/telkom-property.png';

// Utility function to check loading status
export const isLoading = (...statuses) => statuses.includes('LOADING');

// Mapping functions
export const mapAssetDetails = (assetDetail) => ({
  icon: IconTelkom,
  name: assetDetail?.name || '-',
  address: assetDetail?.address,
  area: (assetDetail?.areaWidth || ' - ') + 'm2',
  buildingUsage: assetDetail?.usage,
  idleArea: assetDetail?.idleArea,
  administrative: assetDetail?.administrative,
  image: assetDetail?.image || [],
  latitude: assetDetail?.latitude,
  longitude: assetDetail?.longitude,
});

export const mapRadiusInfo = (radiusInfo) =>
  radiusInfo?.map(v => ({
    title: v.label || '-',
    value: String(v.value || '-'),
    icon: <ImageBox src={`/assets/${v.iconKey}.svg`} type='link' />,
  })) || [];

export const mapGenderInfo = (genderInfo) =>
  genderInfo?.map(v => ({
    key: v.key || '',
    icon: <ImageBox src={`/assets/${v.iconKey}.svg`} type='link' />,
    title: v.label || '',
    rangeAge: v.ageRange || '',
    male: v.male || 0,
    female: v.female || 0,
  })) || [];

export const mapEducationInfo = (education) =>
  education?.map(v => ({
    title: v.label || '-',
    value: v.value || 0,
    icon: <ImageBox src={`/assets/${v.iconKey}.svg`} type='link' />,
    color: v.color || '',
  })) || [];

export const mapSESInfo = (sesInfo) =>
  sesInfo?.map(v => ({
    color: v.color || '',
    title: v.label?.toUpperCase() || '',
    value: v.value || 0,
  })) || [];

export const mapZoningInfo = (zoningInfo) =>
  zoningInfo?.map(v => ({
    key: v,
    name: v,
  }));

export const mapProfessionInfo = (professionInfo) =>
  professionInfo?.map(v => ({
    icon: <></>,
    title: v.label || '',
    total: v.value || 0,
  })) || [];

export const mapTopPoiInfo = (topPoiInfo) =>
  topPoiInfo?.map((v, index) => ({
    key: String(index + 1),
    iconUrl: getIcon('poi', v.image.replace('/sub-module/poi-v3-icon', '')),
    name: v.name,
    near: v.near,
    total: v.total,
  }));

export const mapBrandByIndustryInfo = (brandByIndustryInfo) =>
  brandByIndustryInfo?.map((v, index) => ({
    brands: v.brand,
    key: String(index + 1),
    icon: getIcon('poi', v.image.replace('/sub-module/poi-v3-icon', '')),
    title: v.name,
  }));

export const resultByRadiusMapper = {
  mapAsmanDetail: (detailFeatureAsset) => ({
    name: detailFeatureAsset.nama_asset,
    address: detailFeatureAsset.address,
    areaWidth: parseInt(detailFeatureAsset.area),
    administrative: [
      detailFeatureAsset.kecamatan || '-',
      detailFeatureAsset.kabupaten || '-',
      detailFeatureAsset.provinsi || '-'
    ],
    usage: detailFeatureAsset.building_usage,
    image: detailFeatureAsset.link_foto?.map(uri => `/gw?uri=${uri}`),
    latitude: parseFloat(detailFeatureAsset.latitude || ''),
    longitude: parseFloat(detailFeatureAsset.longitude || '')
  }),
  mapEducation: (detailFeatureElse) => (
    _.orderBy(
      Object.keys(detailFeatureElse.education || {}).map(v => {
        switch (v) {
          case 'tidak sekolah':
            return {
              label: 'NON SCHOOL',
              value: detailFeatureElse?.education?.[v].total,
              color: '#16C512',
              iconKey: 'icon-non-school',
              order: 1
            };
          case 'sd':
            return {
              label: 'ELEMENTARY SCHOOL',
              value: detailFeatureElse?.education?.[v].total,
              color: '#FCD719',
              iconKey: 'icon-elementary',
              order: 2
            };
          case 'sltp':
            return {
              label: 'JUNIOR HIGHSCHOOL',
              value: detailFeatureElse?.education?.[v].total,
              color: '#E7071E',
              iconKey: 'icon-junior-high-school',
              order: 3
            };
          case 'slta':
            return {
              label: 'SENIOR HIGHSCHOOL',
              value: detailFeatureElse?.education?.[v].total,
              color: '#2077CD',
              iconKey: 'icon-senior-high-school',
              order: 4
            };
          case 'diploma':
            return {
              label: 'DIPLOMA',
              value: detailFeatureElse?.education?.[v].total,
              color: '#F425A3',
              iconKey: 'icon-diploma',
              order: 5
            };
          case 'university':
            return {
              label: 'UNIVERSITY',
              value: detailFeatureElse?.education?.[v].total,
              color: '#B520CD',
              iconKey: 'icon-university',
              order: 6
            };
          default:
            throw new Error('Data Key changes on education mapper');
        }
      }),
      ['order'],
      ['asc']
    ) || []
  ),
  mapRadiusInfo: (detailFeatureElse) => (
    _.orderBy(
      Object.keys(detailFeatureElse.radius_info || {}).map(v => {
        switch (v) {
          case 'vehicle_type':
            return {
              label: 'Vehicle type',
              value: detailFeatureElse?.radius_info?.[v],
              iconKey: 'icon-car',
              order: 1
            };
          case 'radius_by':
            return {
              label: 'Radius by',
              value: detailFeatureElse?.radius_info?.[v],
              iconKey: 'icon-direction',
              order: 2
            };
          case 'unit_distance':
            return {
              label: 'Unit distance',
              value: detailFeatureElse?.radius_info?.[v],
              iconKey: 'icon-distance',
              order: 3
            };
          case 'buffer_size_m2':
            return {
              label: 'Buffer size',
              value: detailFeatureElse?.radius_info?.[v],
              iconKey: 'icon-rule',
              order: 4
            };
          default:
            throw new Error('Data Key changes on radiusInfo mapper');
        }
      }),
      ['order'],
      ['asc']
    )
  ),
  mapGenderInfo: (detailFeatureElse) => (
    Object.keys(detailFeatureElse.gender_by_age || {}).map(
      v => {
        const labelAndIcon = {
          label: '',
          iconKey: ''
        };

        if (v === '15') {
          labelAndIcon.iconKey = 'icon-teen';
          labelAndIcon.label = 'Teen';
        } else if (v === '20' || v === '25' || v === '30' || v === '35') {
          labelAndIcon.iconKey = 'icon-adult';
          labelAndIcon.label = 'Adult';
        } else {
          labelAndIcon.iconKey = 'icon-mature-1';
          labelAndIcon.label = 'Mature';
        }

        return {
          ...labelAndIcon,
          ageRange: `${v} - ${parseInt(v) + 4}`,
          female: detailFeatureElse?.gender_by_age?.[v]?.total_female,
          male: detailFeatureElse?.gender_by_age?.[v]?.total_male
        };
      }
    )
  ),
  mapSesInfo: (detailFeatureElse) => (
    _.orderBy(
      Object.keys(detailFeatureElse.ses || {}).map(v => {
        switch (v) {
          case 'high':
            return {
              key: v,
              label: v.toUpperCase(),
              value: detailFeatureElse?.ses?.[v],
              color: '#FF3760',
              order: 4
            };
          case 'medium-high':
            return {
              key: v,
              label: v.toUpperCase(),
              value: detailFeatureElse?.ses?.[v],
              color: '#FC679F',
              order: 3
            };
          case 'medium-low':
            return {
              key: v,
              label: v.toUpperCase(),
              value: detailFeatureElse?.ses?.[v],
              color: '#FFAF0E',
              order: 2
            };
          case 'low':
            return {
              key: v,
              label: v.toUpperCase(),
              value: detailFeatureElse?.ses?.[v],
              color: '#12B2A7',
              order: 1
            };
          default:
            throw new Error('Data Key changes on sesInfo mapper');
        }
      }),
      ['order'],
      ['asc']
    )
  ),
  mapProfessionInfo: (detailFeatureElse) => (
    Object.keys(
      detailFeatureElse.profession || {}
    ).map(v => ({
      key: v,
      label: v.split('_').join(' '),
      value: detailFeatureElse?.profession?.[v].total
    }))
  ),
  mapZoningInfo: (detailFeatureElse) => detailFeatureElse.zoning || [],
  mapDemoCatchInfo: (detailFeatureElse) => ({
    male: detailFeatureElse.demography_catchment?.male?.total,
    female: detailFeatureElse.demography_catchment?.female?.total
  }),
  mapLandValueInfo: (detailFeatureElse) => ({
    current: detailFeatureElse.land_value?.current_value,
    previous: _.toNumber(detailFeatureElse.land_value?.previous_value)
  }),
  mapTopPoiInfo: (detailFeatureElse) => (
    Object.keys(
      detailFeatureElse.top_10_poi_get || {}
    ).map(v => {
      const catId = detailFeatureElse?.top_10_poi_get?.[v].category_id || 0;
      return {
        id: catId,
        image: `/sub-module/poi-v3-icon/Category/${catId}.svg`,
        name: v,
        near: detailFeatureElse?.top_10_poi_get?.[v].min_dist,
        total: detailFeatureElse?.top_10_poi_get?.[v].total
      };
    })
  ),
  mapBrandByIndustryInfo: (detailFeatureElse) => (
    Object.keys(
      detailFeatureElse.poi_brand_variation_by_industry || {}
    ).map(v => {
      const brandId =
        detailFeatureElse?.poi_brand_variation_by_industry?.[v]
          ?.industry_id || 0;

      return {
        id: brandId,
        name: v,
        brand:
          detailFeatureElse?.poi_brand_variation_by_industry?.[v]?.brand ||
          [],
        image: `/sub-module/poi-v3-icon/Industry/${brandId}.svg`
      };
    })
  ),
  mapPropertyPrice: (detailFeatureElse, propertyType) => {
    const propertyList = detailFeatureElse?.[propertyType] || {};

    if (typeof propertyList?.[0] === 'string') {
      return [];
    }
    return Object.values(propertyList).map(property => ({
      name: property?.property,
      unit: property?.satuan,
      type: property?.type,
      price: {
        min: property?.price?.min,
        max: property?.price?.max
      },
      list: Object.values(property?.description || {}).map(description => ({
        name: description?.poi_name,
        address: description?.address,
        price: description?.price,
        floorArea: description?.floor_count,
        landArea: description?.building_area,
        facilities: description?.facilities
      })) || []
    }));
  },
};
