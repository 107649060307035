import Resource from '../../../api/resource';

/**
 * @copyright PT Bhumi Varta Techonology
 * @author Renta<renta.yustika@bvarta.com>
 */

export class DatexPOIService extends Resource {
  constructor() {
    super('', '/api', 'api-poi', 'v2');
  }


  /**
   * @param {Object} _body
   * @param {Number} _body.category_id
   * @param {Array<Number>} _body.brands
   * @param {Array<String>} _body.criterias
   * @param {Number} _body.province_id
   * @param {Number|undefined} _body.city_id
   * @param {Number|undefined} _body.district_id
   * @param {Number|undefined} _body.village_id
   */
  AnalyzeDatexPoi(_body) {
    this.version = 'v2';
    const data = _body;
    this.setAdditionUri('datex/poi');
    return this.create(data);
  }


  GetIndustryList() {
    this.version = 'v2';
    this.setAdditionUri(`industry`);
    return this.list();
  }

  GetIndustryListRinjani(countryId=1) {
    this.version = 'v2';
    this.setAdditionUri(`datex/poi/industries?country_id=${countryId}`);
    return this.list();
  }

  GetGroupAndCategoryByIndustry({ id,countryId=1 }) {
    this.version = 'v3';
    this.setAdditionUri(`poi/${id}/by-industries-id?country_id=${countryId}`);
    return this.list();
  }

  GetBrandV3(query) {
    this.version = 'v2';
    const paramString = JSON.stringify(query);
    const params = encodeURIComponent(paramString);
    this.setAdditionUri('brand/v3/all');
    return this.list({ params });
  }
}
