import { asmanSlice } from '@bvt-features/sub-module/asman/store/asman.slice';

import {
  asmanAsyncActionGetDashboardPoiCollection,
  asmanAsyncActionGetDashboardResultByRadiusAndSet,
  asmanAsyncActionList,
  asmanAsyncActiongAvailableRadiusInfoAndStatusAnalyze,
} from '../../store/asman.asyncAction';
import { useDispatch, useSelector } from 'react-redux';

export const useAsman = () => {
  const state = useSelector((state) => state.features.SUBMODULE.asman);
  const dispatch = useDispatch();

  /**
	 *
	 * @param {object} param
	 * @param {number} param.province_id
	 * @param {number} param.regency_id
	 * @param {number} param.district_id
	 * @param {number} param.village_id
	 */
  const getListAsset = (param) => dispatch(asmanAsyncActionList(param));

  const resetState = () => dispatch(asmanSlice.actions.resetState());

  /**
	 *
	 * @param {object} param
	 * @param {'status_LIST_ASSET'|'status_DASHBOARD_RESULT'|'status_DASHBOARD_RADIUS_LIST'|'STATUS_DASHBOARD_POI_COLLECTION'|'status_POI_COLLECTION'} param.type
	 */
  const resetStatus = (param) =>
    dispatch(asmanSlice.actions.resetStatus({ type: param.type }));

  const resetDashboard = () => dispatch(asmanSlice.actions.resetDashboard());

  /**
	 *
	 * @param {object} param
	 * @param {boolean} param.isShowAll
	 */
  const setShowAll = (param) => dispatch(asmanSlice.actions.setShowAll(param));

  /**
	 *
	 * @param {object} param
	 * @param {string} param.activeTab
	 */
  const setActiveTab = (param) =>
    dispatch(asmanSlice.actions.setActiveTab({ type: param.activeTab }));

  /**
	 *
	 * @param {object} param
	 * @param {string} param.search
	 */
  const setSearch = (param) => dispatch(asmanSlice.actions.setSearch(param));

  /**
	 *
	 * @param {object} param
	 * @param {number} param.page
	 * @param {number} param.perPage
	 */
  const setPagination = (param) =>
    dispatch(asmanSlice.actions.setPagination(param));

  /**
	 *
	 * @param {object} param
	 * @param {string} param.gsid
	 */
  const getDashboardAvaliableRadius = (param) =>
    dispatch(asmanAsyncActiongAvailableRadiusInfoAndStatusAnalyze(param));

  /**
	 *
	 * @param {object} param
	 * @param {string} param.gsid
	 * @param {number} param.radius
	 */
  const getDashboardResultByRadiusAndSet = (param) =>
    dispatch(asmanAsyncActionGetDashboardResultByRadiusAndSet(param));

  /**
	 *
	 * @param {object} param
	 * @param {string} param.gsid
	 * @param {number} param.radius
	 */
  const getDashboardPOICollection = (param) =>
    dispatch(asmanAsyncActionGetDashboardPoiCollection(param));
  return {
    resetState,
    resetDashboard,
    getListAsset,
    setShowAll,
    setActiveTab,
    setSearch,
    setPagination,
    getDashboardAvaliableRadius,
    getDashboardResultByRadiusAndSet,
    getDashboardPOICollection,
    resetStatus,
    state,
  };
};
