import { getIcon } from '@bvt-shared/utils/iconUtils';
import { ConsumePoiService } from '../service';
import { GENSET_CONSUME_POI_CONSTANT } from './GENSET_CONSUME_POI_CONSTANT';

const consumePoiService = new ConsumePoiService();

export const JSONcombine = [
  {
    id: 1,
    code: '11',
    initial: 'Bank & Finance',
    color: '#976ADA',
  },
  {
    id: 2,
    code: '12',
    initial: 'Commercial',
    color: '#2f7ccb',
  },
  {
    id: 3,
    code: '13',
    initial: 'Education',
    color: '#808FC7'
  },
  {
    id: 4,
    code: '14',
    initial: 'Food & Beverage',
    color: '#EA8A22'
  },
  {
    id: 5,
    code: '15',
    initial: 'Government',
    color: '#808F9A'
  },
  {
    id: 6,
    code: '16',
    initial: 'Hotel',
    color: '#A0D14E'
  },
  {
    id: 7,
    code: '17',
    initial: 'Industry',
    color: '#00C69D'
  },
  {
    id: 8,
    code: '18',
    initial: 'Leisure',
    color: '#FF3CA2'
  },
  {
    id: 9,
    code: '19',
    initial: 'Medical',
    color: '#FB7575'
  },
  {
    id: 10,
    code: '20',
    initial: 'Security',
    color: '#ABBAC0'
  },
  {
    id: 11,
    code: '21',
    initial: 'Shop & Retail',
    color: '#188FB4'
  },
  {
    id: 12,
    code: '22',
    initial: 'Social Culture',
    color: '#4cb446'
  },
  {
    id: 13,
    code: '23',
    initial: 'Sport',
    color: '#f8d003'
  },
  {
    id: 14,
    code: '24',
    initial: 'Tourism',
    color: '#83a939'
  },
  {
    id: 15,
    code: '25',
    initial: 'Transportation',
    color: '#37ce91'
  },
  {
    id: 16,
    code: '26',
    initial: 'Utility',
    color: '#0dcfee'
  }
];

export const GENSET_CONSUME_POI_ACTIONS = {
  get: () => {
    return async (dispatch) => {
      dispatch({
        type: GENSET_CONSUME_POI_CONSTANT.GET_START,
        payload: {
          status_GET: 'LOADING',
        },
      });
      try {
        let { message, meta, results } = await consumePoiService.getListIndustries();
        const combinedData = await results.map(industryItem => {
          const correspondingColorItem = JSONcombine.find(
            colorItem => colorItem.id === industryItem.industry_id);
          if (correspondingColorItem) {
            return {
              id: industryItem.industry_id,
              code: industryItem.industry_code,
              name: industryItem.industry_name,
              icon: getIcon('poi', industryItem.industry_icon),
              status: industryItem.industry_status,
              initial: correspondingColorItem.initial,
              color: correspondingColorItem.color,
            };
          }
          return industryItem;
        });
        dispatch({
          type: GENSET_CONSUME_POI_CONSTANT.GET_SUCCESS,
          payload: {
            status_GET: 'SUCCESS',
            list: combinedData,
            message: message,
            properties: meta,
          },
        });
      } catch (error) {
        dispatch({
          type: GENSET_CONSUME_POI_CONSTANT.GET_FAILED,
          payload: {
            status_GET: 'FAILED',
            message: error.response?.data?.message,
          },
        });
      }
    };
  },
};

