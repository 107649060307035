export const GENSET_CATEGORY_CONSTANT = {
  GET_START: 'LI/POI/category/get/requested',
  GET_FAILED: 'LI/POI/category/get/failed',
  GET_SUCCESS: 'LI/POI/category/get/success',

  DETAIL_START: '/LI/POI/category/detail/requested',
  DETAIL_FAILED: '/LI/POI/category/detail/failed',
  DETAIL_SUCCESS: '/LI/POI/category/detail/success',

  CREATE_START: '/LI/POI/category/create/requested',
  CREATE_FAILED: '/LI/POI/category/create/failed',
  CREATE_SUCCESS: '/LI/POI/category/create/success',

  UPDATE_START: 'LI/POI/category/update/requested',
  UPDATE_FAILED: 'LI/POI/category/update/failed',
  UPDATE_SUCCESS: 'LI/POI/category/update/success',

  DELETE_START: 'LI/POI/category/delete/requested',
  DELETE_FAILED: 'LI/POI/category/delete/failed',
  DELETE_SUCCESS: 'LI/POI/category/delete/success',

  DOWNLOAD_START: 'LI/POI/category/download/requested',
  DOWNLOAD_FAILED: 'LI/POI/category/download/failed',
  DOWNLOAD_SUCCESS: 'LI/POI/category/download/success',

  UPLOAD_START: 'LI/POI/category/upload/requested',
  UPLOAD_FAILED: 'LI/POI/category/upload/failed',
  UPLOAD_SUCCESS: 'LI/POI/category/upload/success',

  RESET_STATUS: 'LI/POI/category/reset-status',
  RESET_ALL: 'LI/POI/category/reset-all',
};
