import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Malik <malik.ibrahim@bvarta.com>
 * @param {Object} props
 * @param {string} props.height
 * @param {string} props.width
 * @param {Object} props.style
 */

const IconDarkTheme = (props) => {
  const { height, width, style } = props;

  return (
    <svg
      height={height}
      style={{ ...style }}
      viewBox='0 0 18 18'
      width={width}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.511 2.179a6.336 6.336 0 0 0 5.871 11.21A8.448 8.448 0 0 1 4.934 1.942c-.141.078-.282.148-.423.237Zm-3.428 9.4A7.744 7.744 0 0 1 6.313.014a.7.7 0 0 1 .7 1.105 7.04 7.04 0 0 0 5.322 11.179.7.7 0 0 1 .415 1.239 7.744 7.744 0 0 1-11.667-1.964Z'
        data-name='Path 519'
        fill='currentColor'
      />
    </svg>
  );
};

IconDarkTheme.defaultProps = {
  height: 24,
  width: 24,
  style: {},
};

IconDarkTheme.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  style: PropTypes.object,
};

export { IconDarkTheme }
