import * as React from 'react';

export const IconPencilOutlined = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      d="m7.123 14.414 7.3-7.301a2.352 2.352 0 0 0 0-3.324l-2.22-2.212a2.351 2.351 0 0 0-3.325 0l-7.3 7.3a2.352 2.352 0 0 0-.69 1.663v4.57H5.46c.623 0 1.221-.247 1.662-.689v-.007Zm-4.704-3.92c0-.205.082-.402.227-.548l7.306-7.307a.784.784 0 0 1 1.105 0l2.219 2.219a.784.784 0 0 1 0 1.105L6.01 13.309a.784.784 0 0 1-.55.227H2.459l-.04-3.041Z"
      fill="currentColor"
    />
  </svg>
);
