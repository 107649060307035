import React, { useEffect, useState, useRef } from 'react';
import { useGRIANA } from '../../hook';
import { GeoJSON, useMap } from 'react-leaflet';
import { GRIDPRO_STYLE } from '../../constant/GRIDPRO_STYLE';
import * as Leaflet from 'leaflet';
import _ from 'lodash';
import { useMainMap } from '@bvt-features/mainmap/mainmap/hook/useMainMap';
import { useProject } from '@bvt-features/mapp/project/hooks/useProject';
const geom = {};

export default function GeoJsonGrianaContainer() {
  const GRIANA = useGRIANA();
  const initialMap = useMap();
  const MAINMAP = useMainMap();
  const PROJECT = useProject();
  const [geojson, setGeojson] = useState({});
  const idMap = initialMap.getContainer().id;
  const gid = useRef();
  const gidRight = useRef();
  const acc = useRef();
  const accRight = useRef();

  const styleFinder = _.find(
    MAINMAP.state.layerVisibilityList,
    (v) => v.key === 'GEOJSON_GRIANA'
  );

  function getBounds(value) {
    return Leaflet.geoJSON(value).getBounds();
  }

  useEffect(() => {
    MAINMAP.registerLayer({
      key: 'GEOJSON_GRIANA',
      isActive: true,
      opacity: 0.3,
      title: 'Grid Analysis',
      description: ['Grid'],
    });
    gid.current = GRIANA.state.gid;
    gidRight.current = GRIANA.state.gid_right;
    acc.current = GRIANA.state.acc;
    accRight.current = GRIANA.state.accRight;
    return () => {
      MAINMAP.removeLayer('GEOJSON_GRIANA');
    };
  }, []);

  useEffect(() => {
    MAINMAP.updateLayer({
      key: 'GEOJSON_GRIANA',
      isActive: true,
      opacity: 0.3,
      title: 'Grid Analysis',
      description: [PROJECT?.state?.detail?.name, 'Grid'],
    });
  }, [PROJECT.state.detail]);

  useEffect(() => {
    if (!idMap?.includes('right')) {
      const geojsonData = {
        ...GRIANA.state.geojson,
        features: GRIANA.state.geojson?.features?.filter(({ properties }) =>
          GRIANA.state.acceptable.includes(properties.skala_result)
        ),
      };
      setGeojson(geojsonData);
      if (GRIANA.state.status_GET_AREA === 'SUCCESS' && acc.current !== GRIANA.state.acceptable) {
        const first = _.maxBy(geojsonData?.features, (feature) => {
          return feature.properties.score;
        });

        const other = geojsonData?.features?.find((feature) =>
          GRIANA.state.acceptable.includes(feature.properties.skala_result)
        );
        if (first) {
          GRIANA.setGid(first.properties.gid);
          const bounds = getBounds(first);
          initialMap.fitBounds(bounds, { maxZoom: 17 });
        } else if (other) {
          GRIANA.setGid(other.properties.gid);
          const bounds = getBounds(other);
          initialMap.fitBounds(bounds, { maxZoom: 17 });
        }
        acc.current = GRIANA.state.acceptable;
      }
    }
    if (idMap?.includes('right')) {
      const geojsonData = {
        ...GRIANA.state.geojsonRight,
        features: GRIANA.state.geojsonRight?.features?.filter(
          ({ properties }) =>
            GRIANA.state.acceptableRight.includes(properties.skala_result)
        ),
      };
      setGeojson(geojsonData);
      if (GRIANA.state.status_GET_AREA_RIGHT === 'SUCCESS' && accRight.current !== GRIANA.state.acceptableRight) {
        const first = _.maxBy(geojsonData?.features, (feature) => {
          return feature.properties.score;
        });

        const other = geojsonData?.features?.find((feature) =>
          GRIANA.state.acceptableRight.includes(feature.properties.skala_result)
        );
        if (first) {
          GRIANA.setGidRight(first.properties.gid);
          const bounds = getBounds(first);
          initialMap.fitBounds(bounds, { maxZoom: 17 });
        } else if (other) {
          GRIANA.setGidRight(first.properties.gid);
          const bounds = getBounds(other);
          initialMap.fitBounds(bounds, { maxZoom: 17 });
        }
        accRight.current = GRIANA.state.acceptableRight;
      }
    }
  }, [
    GRIANA.state.status_GET_AREA,
    GRIANA.state.acceptable,
    GRIANA.state.status_GET_AREA_RIGHT,
    GRIANA.state.acceptableRight,
  ]);

  useEffect(() => {
    if (
      !idMap?.includes('right') &&
      GRIANA.state.status_GET_AREA === 'SUCCESS' &&
      GRIANA.state.gid !== gid.current
    ) {
      initialMap.eachLayer((layer) => {
        if (
          layer.feature &&
          layer.feature.properties.gid === GRIANA.state.gid
        ) {
          const style = GRIDPRO_STYLE.find(
            (item) =>
              item.gridStatus === geom.layer.feature.properties.skala_result
          ).style;

          geom.layer.setStyle(style.unselected);
          layer.setStyle(style.selected);

          const bounds = layer.getBounds();
          initialMap.fitBounds(bounds, { maxZoom: 17 });

          gid.current = GRIANA.state.gid;
        }
      });
    }
    if (
      idMap?.includes('right') &&
      GRIANA.state.status_GET_AREA_RIGHT === 'SUCCESS' &&
      GRIANA.state.gid_right !== gidRight.current
    ) {
      initialMap.eachLayer((layer) => {
        if (
          layer.feature &&
          layer.feature.properties.gid === GRIANA.state.gid_right
        ) {
          const style = GRIDPRO_STYLE.find(
            (item) =>
              item.gridStatus === geom.layer.feature.properties.skala_result
          ).style;

          geom.layer.setStyle(style.unselected);
          layer.setStyle(style.selected);

          const bounds = layer.getBounds();
          initialMap.fitBounds(bounds, { maxZoom: 17 });

          gidRight.current = GRIANA.state.gid_right;
        }
      });
    }
  }, [GRIANA.state.gid, GRIANA.state.gid_right]);

  useEffect(() => {
    if (!idMap?.includes('right') && GRIANA.state.status_DETAIL === 'SUCCESS') {
      initialMap.eachLayer((layer) => {
        if (
          layer.feature &&
          layer.feature.properties.gid === GRIANA.state.gid
        ) {
          layer.openPopup();
        }
      });
    }
    if (
      idMap?.includes('right') &&
      GRIANA.state.status_DETAIL_RIGHT === 'SUCCESS'
    ) {
      initialMap.eachLayer((layer) => {
        if (
          layer.feature &&
          layer.feature.properties.gid === GRIANA.state.gid_right
        ) {
          layer.openPopup();
        }
      });
    }
  }, [GRIANA.state.gridDetail, GRIANA.state.gridDetailRight]);

  const renderPopup = (value) => {
    return `<div style="font-size: 1rem; text-align: center;">${value}</div>
  `;
  };

  const setStyle = (feature) => {
    return {
      ...GRIDPRO_STYLE.find(
        (item) => item.gridStatus === feature.properties.skala_result
      ).style.unselected,
      fillOpacity: styleFinder.opacity,
      opacity: styleFinder.opacity,
    };
  };

  const onClick = (feature, layer) => {
    const style = GRIDPRO_STYLE.find(
      (item) => item.gridStatus === feature.properties.skala_result
    ).style;
    layer.setStyle({
      ...style.selected,
      fillOpacity: styleFinder.opacity * 1.5,
      opacity: styleFinder.opacity * 1.5,
    });
    geom.layer = layer;
    if (
      !idMap?.includes('right') &&
      GRIANA.state.gid !== feature.properties.gid
    ) {
      const bounds = layer.getBounds();
      initialMap.fitBounds(bounds, { maxZoom: 17 });
      GRIANA.setGid(feature.properties.gid);
    }
    if (
      idMap?.includes('right') &&
      GRIANA.state.gid_right !== feature.properties.gid
    ) {
      const bounds = layer.getBounds();
      initialMap.fitBounds(bounds, { maxZoom: 17 });
      GRIANA.setGidRight(feature.properties.gid);
    }
  };

  const onEachFeature = (feature, layer) => {
    layer.bindPopup(renderPopup(feature.properties.gid), {
      closeButton: false,
    });
    if (
      !idMap?.includes('right') &&
      feature.properties.gid === GRIANA.state.gid
    ) {
      layer.setStyle({
        ...GRIDPRO_STYLE.find(
          (item) => item.gridStatus === feature.properties.skala_result
        ).style.selected,
        fillOpacity: styleFinder.opacity * 2,
        opacity: styleFinder.opacity * 2,
      });
      geom.layer = layer;
    }
    if (
      idMap?.includes('right') &&
      feature.properties.gid === GRIANA.state.gid_right
    ) {
      layer.setStyle({
        ...GRIDPRO_STYLE.find(
          (item) => item.gridStatus === feature.properties.skala_result
        ).style.selected,
        fillOpacity: styleFinder.opacity * 2,
        opacity: styleFinder.opacity * 2,
      });
      geom.layer = layer;
    }
    layer.on('click', () => onClick(feature, layer));
  };

  const Geojson = () => {
    /**
     * @type {React.MutableRefObject<import('leaflet').GeoJSON>}
     */
    const ref = useRef();

    if (ref.current) {
      ref.current.setZIndex(101);
    }
    return (
      <React.Fragment>
        <GeoJSON
          data={geojson?.type ? geojson : null}
          onEachFeature={onEachFeature}
          ref={ref}
          style={setStyle}
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {initialMap && styleFinder?.isActive ? <Geojson /> : null}
    </React.Fragment>
  );
}
