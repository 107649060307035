import React from 'react';

export const AsmanMapViewPopupInternalComponent = props => {
  const { data } = props;
  return (
    <div className='AsmanMapViewPopupInternalComponent__wrapper'>
      <div className='AsmanMapViewPopupInternalComponent__top'>
        <img alt={data?.title} src={data?.icon} />
        <div className='AsmanMapViewPopupInternalComponent__top-wrap'>
          <div className='AsmanMapViewPopupInternalComponent__top-title'>
            {data?.title}
          </div>
          <div className='AsmanMapViewPopupInternalComponent__top-sub-title'>
            {data?.group || data?.category || '-'}
          </div>
        </div>
      </div>
      <div className='AsmanMapViewPopupInternalComponent__middle-section'>
        {data?.group && (
          <div className='AsmanMapViewPopupInternalComponent__middle-section-inner'>
            <div className='AsmanMapViewPopupInternalComponent__middle-section-left'>
              Group
            </div>
            <div className='AsmanMapViewPopupInternalComponent__middle-section-right'>
              {data.group}
            </div>
          </div>
        )}
        {data?.category && (
          <div className='AsmanMapViewPopupInternalComponent__middle-section-inner'>
            <div className='AsmanMapViewPopupInternalComponent__middle-section-left'>
              Category
            </div>
            <div className='AsmanMapViewPopupInternalComponent__middle-section-right'>
              {data.category}
            </div>
          </div>
        )}
        {data?.address && (
          <div className='AsmanMapViewPopupInternalComponent__middle-section-inner'>
            <div className='AsmanMapViewPopupInternalComponent__middle-section-left'>
              Address
            </div>
            <div className='AsmanMapViewPopupInternalComponent__middle-section-right'>
              {data.address}
            </div>
          </div>
        )}

        <div className='AsmanMapViewPopupInternalComponent__middle-section-divider' />

        {data?.price && (
          <div className='AsmanMapViewPopupInternalComponent__middle-section-inner'>
            <div className='AsmanMapViewPopupInternalComponent__middle-section-left'>
              Price
            </div>
            <div className='AsmanMapViewPopupInternalComponent__middle-section-right'>
              {data.price?.toLocaleString('id', {
                currency: 'IDR',
                style: 'currency'
              })}
            </div>
          </div>
        )}
        {!!data?.nearestPublicTransport && (
          <div className='AsmanMapViewPopupInternalComponent__middle-section-inner'>
            <div className='AsmanMapViewPopupInternalComponent__middle-section-left'>
              Nearest Public Transport
            </div>
            <div className='AsmanMapViewPopupInternalComponent__middle-section-right'>
              {data.nearestPublicTransport}
            </div>
          </div>
        )}
        {data?.buildingArea && (
          <div className='AsmanMapViewPopupInternalComponent__middle-section-inner'>
            <div className='AsmanMapViewPopupInternalComponent__middle-section-left'>
              Building Area
            </div>
            <div className='AsmanMapViewPopupInternalComponent__middle-section-right'>
              {data.buildingArea}
            </div>
          </div>
        )}
        {data?.height && (
          <div className='AsmanMapViewPopupInternalComponent__middle-section-inner'>
            <div className='AsmanMapViewPopupInternalComponent__middle-section-left'>
              Building Height
            </div>
            <div className='AsmanMapViewPopupInternalComponent__middle-section-right'>
              {data.height}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
