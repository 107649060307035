import React, { useEffect } from 'react';
import { usePlace } from '../../../hooks/usePlace';
import { debounce, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import '../Dropdown.scss';
import DropdownInter from '../../DropdownInter';

export function SearchDistrictInter(props) {
  const PLACE = usePlace();

  useEffect(() => {
    if (props.idCity) {
      getData('', props.idCity);
    }
  }, [props.idCity]);

  useEffect(() => {
    if (!isEmpty(PLACE.state.params.kecamatan)) {
      props.isDistrict && props.isDistrict(PLACE.state.params.kecamatan);
    }
  }, [PLACE.state.params.kecamatan]);

  const getData = (value, idCity) => {
    const params = {
      meta: {
        filter: { keyword: value, parent_id: idCity, country_id:PLACE.state.country_mandala.country_id }
      },
    };
    PLACE.get_district_inter({ params });
  };

  const getDataDebounce = debounce(getData, 800);

  const getPlaceholderDistrict = () => {
    const countryId = PLACE.state.country_mandala.country_id
    const listNameRegion = ["District","District","Commune"]
    return listNameRegion[countryId-1]
  }

  return (
    <DropdownInter
      disabled={isEmpty(PLACE.state.params.kota)}
      handler={{
        select: (ctx) => {
          PLACE.setParams({ type: 'kecamatan', ...ctx });
          PLACE.removeParams({ desa: null });
          props.isDistrict && props.isDistrict(ctx);
          props?.setVillage?.(null);
        },
        clear: () => {
          PLACE.removeParams({ kecamatan: null });
          PLACE.removeParams({ desa: null });
          props.setVillage && props.setVillage(null);
        },
        search: (value) => {
          getDataDebounce(value, props.idCity);
        },
      }}
      isLoading={PLACE.state.district?.status_GET === 'LOADING'}
      options={PLACE.state.district?.list}
      dispayName={`name`}
      displayId={`id`}
      placeholder={getPlaceholderDistrict()}
      selected={PLACE.state.params.kecamatan && {
        id: PLACE.state.params.kecamatan[`id`],
        name: PLACE.state.params.kecamatan[`name`],
        code: PLACE.state.params.kecamatan[`code`],
      }}
      type='kecamatan'
    />
  );
}

SearchDistrictInter.defaultProps = {
  data: undefined,
  isDistrict: undefined,
};

SearchDistrictInter.propTypes = {
  data: PropTypes.object,
  idCity: PropTypes.number,
  isDistrict: PropTypes.func,
  setVillage: PropTypes.func,
};
