import React, { useState } from 'react';
import { Button, Col, Image, Row } from 'antd';
import { Popup } from 'react-leaflet';

export const AsmanMarkerGeojsonInternalComponent = React.forwardRef(
  (props, ref) => {
    const [state, setState] = useState({ isVisible: false });
    const {
      address,
      area,
      idle_area,
      image,
      name,
      propar: propart,
      tenant,
      usage: ussage,
      lat,
      lng,
      onDashboardClick
    } = props;
    return lat && lng ? (
      <Popup
        className='AsmanMarkerGeojsonInternalComponent__popup'
        closeButton={false}
        position={{ lat, lng }}
        ref={ref}
      >
        <div className='AsmanMarkerGeojsonInternalComponent__popup-wrap'>
          <Row align={'middle'} gutter={[16, 16]}>
            <Col xs={{ span: 8 }}>
              <Image
                fallback='https://via.placeholder.com/512'
                height={258}
                onClick={() => setState({ isVisible: true })}
                preview={{ visible: false }}
                src={image && image[0]}
                style={{ objectFit: 'contain' }}
                width={'100%'}
              />
              <div style={{ display: 'none' }}>
                <Image.PreviewGroup
                  preview={{
                    visible: state.isVisible,
                    onVisibleChange: vis =>
                      setState({ ...state, isVisible: vis })
                  }}
                >
                  {image &&
                    image.map((v) => (
                      <Image
                        fallback='https://via.placeholder.com/512'
                        key={v}
                        src={v}
                      />
                    ))}
                </Image.PreviewGroup>
              </div>
            </Col>
            <Col xs={{ span: 16 }}>
              <table className='AsmanMarkerGeojsonInternalComponent__popup-table'>
                <tr>
                  <td width={128}>Asset Name</td>
                  <td>:</td>
                  <td> {name} </td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>:</td>
                  <td>{address}</td>
                </tr>
                <tr>
                  <td>Area</td>
                  <td>:</td>
                  <td>{area}</td>
                </tr>
                <tr>
                  <td>Building Usage</td>
                  <td>:</td>
                  <td>{ussage}</td>
                </tr>
                <tr>
                  <td>Idle Area</td>
                  <td>:</td>
                  <td>{idle_area}</td>
                </tr>
                <tr>
                  <td>Propar</td>
                  <td>:</td>
                  <td>{propart}</td>
                </tr>
                <tr>
                  <td>Existing Tenant</td>
                  <td>:</td>
                  <td>{tenant}</td>
                </tr>
              </table>
            </Col>
            <Col xs={{ span: 24 }}>
              <Button block onClick={onDashboardClick} type='primary'>
                Dashboard
              </Button>
            </Col>
          </Row>
        </div>
      </Popup>
    ) : (
      <></>
    );
  }
);

AsmanMarkerGeojsonInternalComponent.displayName =
  'AsmanMarkerGeojsonInternalComponent';
