import { IconChevron } from '@bvt-assets/icon/icon-chevron';
import { Calendar } from 'antd';
import { debounce, find } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import './DatexTelcoStepOneTelco.scss';
import moment from 'moment';
import { SelectOption } from '@bvt-shared/component/SelectOption';
import { YEAR_LIST } from '@bvt-features/datex/constant/YEAR_LIST';
import { MONTH_LIST } from '@bvt-features/datex/constant/MONTH_LIST';
import { useAreaSelection } from '@bvt-features/datex/hooks';

moment.updateLocale('en', {
  weekdaysMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
});

const TYPE_LIST = [
  { label: 'Daily', value: 'daily' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Yearly', value: 'yearly' },
];

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

/**
 * @description For list time type
 * @typedef TTelcoTimeType
 * @type {Object}
 * @property {string|number} key
 * @property {string} label
 */

/**
 * @description For list time type
 * @typedef TCatchmentType
 * @type {Object}
 * @property {string|number} key
 * @property {string} label
 * @property {any} icon
 */

/**
 * @description For list select object
 * @typedef TSelectObject
 * @type {Object}
 * @property {number} value
 * @property {string} label
 */

/**
 * @description For data poi object
 * @typedef TTelcoDataObject
 * @type {object}
 * @property {number} province
 * @property {number} cityId
 * @property {string} city
 * @property {'daily'|'monthly'|'yearly'} dataType
 * @property {number} year
 * @property {number} month
 * @property {number} day
 */

/**
 * @param {object} props
 * @param {TTelcoDataObject} props.data
 * @param {(search:string)=>void} props.onSearchProvince
 * @param {TSelectObject} props.provinceList
 * @param {TCityTSelectObjectObject} props.cityList
 * @param {boolean} props.loadingProvince
 * @param {boolean} props.loadingCity
 * @param {(data:TTelcoDataObject)=>void} props.onChange
 * @param {object} props.unavailableDate
 * @param {(search:string,id_province:number)=>void} props.onSearchCity
 * @param {(e:boolean) => void} props.onValidOrInvalid
 */

export const DatexTelcoStepOneTelco = ({
  data,
  onSearchProvince,
  onSearchCity,
  onChange,
  onValidOrInvalid,
  provinceList,
  cityList,
  loadingCity,
  loadingProvince,
}) => {
  const now = moment().subtract(8, 'days');
  const disabled = !data?.province || !data?.city;
  const showCalendar = data?.dataType === 'daily' && data?.month && data?.day;

  const debouncedSearchProvince = useCallback(debounce(onSearchProvince, 300), [
    onSearchProvince,
  ]);

  const debouncedSearchCity = useCallback(debounce(onSearchCity, 300), [
    onSearchCity,
  ]);

  const handleOnChangeDataType = (e) => {
    const update = { dataType: e };
    if (e === 'yearly') {
      update.month = undefined;
      update.day = undefined;
    } else if (e === 'monthly') {
      update.day = undefined;
    } else if (e === 'daily' && data?.year && data?.month) {
      update.day = 1;
    }
    onChange(update);
  };

  const handleYearChange = (e) => {
    const isCurrentYear = e === now.year();
    const isFutureMonth = data?.month >= now.month() + 1;

    if (isCurrentYear && isFutureMonth) {
      const update = { year: e, month: now.month() + 1 };
      if (data?.dataType === 'daily') {
        update.day = Math.min(data?.day, now.date());
      }
      onChange(update);
    } else {
      onChange({ year: e });
    }
  };

  const handleMonthChange = (e) => {
    const isCurrentYear = data?.year === now.year();
    const isFutureMonth = e >= now.month() + 1;

    if (data?.dataType === 'daily') {
      if (isCurrentYear && isFutureMonth && data?.day > now.date()) {
        onChange({ month: e, day: now.date() });
      } else {
        const date = moment(`${data?.year}-${data?.month}-${data?.day}`);
        date.month(e - 1);
        onChange({ month: e, day: date.date() || 1 });
      }
    } else {
      onChange({ month: e });
    }
  };

  useEffect(() => {
    const { province, city, dataType, year, month, day } = data;
    const isValid =
      province &&
      city &&
      dataType &&
      year &&
      (dataType === 'yearly' ||
        (dataType === 'monthly' && month) ||
        (dataType === 'daily' && month && day));

    onValidOrInvalid?.(isValid);
  }, [data]);

  const rulesYear = (year) => {
    const yearNow = moment().year();
    const monthNow = moment().month() + 1;
    const dateNow = moment().date();
    if (
      data.dataType === 'yearly' &&
      year === yearNow - 1 &&
      monthNow >= 2 &&
      dateNow < 9
    ) {
      return true;
    }
    if (data.dataType === 'yearly' && year === yearNow) {
      return true;
    }
    return year > yearNow;
  };

  const ruleMonth = (month) => {
    const yearNow = moment().year();
    const monthNow = moment().month() + 1;
    const dateNow = moment().date();
    return (
      yearNow === data.year &&
      ((month === monthNow -1 && dateNow < 9) || month >= monthNow)
    );
  };

  // Define the search functions that will be passed to the custom hook
  const searchFunctions = {
    province: debouncedSearchProvince,
    city: (value) => debouncedSearchCity(value, data?.province),
  };

  // Use the custom hook to get the render functions
  const {
    renderProvinceDropdown,
    renderCityDropdown,
    setInputSearch,
  } = useAreaSelection(searchFunctions, 'DatexTelcoStepOneTelco__select--search');

  return (
    <div className='DatexTelcoStepOneTelco__container'>
      <div className='DatexTelcoStepOneTelco__search'>
        <span>Select province and city/regency you want to explore.</span>
        <SelectOption
          disabled={loadingProvince}
          dropdownRender={renderProvinceDropdown}
          loading={loadingProvince}
          onChange={(e) => {
            onChange({ province: e, cityId: undefined });
            debouncedSearchCity('', e);
            setInputSearch((prev) => ({ ...prev, city: '' }));
          }}
          options={provinceList || []}
          placeholder='Select Province'
          suffixIcon={<IconChevron />}
          value={find(provinceList, (v) => v?.value === data?.province)}
        />
        <SelectOption
          disabled={!data?.province || loadingCity}
          dropdownRender={renderCityDropdown}
          loading={loadingCity}
          onChange={(e) => {
            const selectedCity = find(cityList, (v) => v?.value === e);
            onChange({
              province: data?.province,
              city: e,
              cityId: selectedCity?.city_id,
            });
          }}
          options={cityList || []}
          placeholder='Select City/Regency'
          suffixIcon={<IconChevron />}
          value={find(cityList, (v) => v?.value === data?.city)}
        />
      </div>
      <div className='DatexTelcoDataType'>
        <div className='DatexTelcoDataType_title'>Select Data Type</div>
        <div className='DatexTelcoDataType_select'>
          <SelectOption
            disabled={disabled}
            onChange={handleOnChangeDataType}
            options={TYPE_LIST}
            placeholder='Data Type'
            suffixIcon={<IconChevron />}
            value={data?.dataType}
          />
          <SelectOption
            disabled={!data.dataType}
            onChange={handleYearChange}
            options={
              YEAR_LIST?.map((ctx) => ({
                ...ctx,
                disabled: rulesYear(ctx.value),
              })) || []
            }
            placeholder='Year'
            suffixIcon={<IconChevron />}
            value={data?.year}
          />
          <SelectOption
            disabled={
              disabled || !data?.dataType || data?.dataType === 'yearly'
            }
            onChange={handleMonthChange}
            options={MONTH_LIST?.map((ctx) => ({
              ...ctx,
              disabled: ruleMonth(ctx.value),
              withoutPopup: true,
            }))}
            placeholder='Month'
            suffixIcon={<IconChevron />}
            value={data?.month}
          />
        </div>
        {showCalendar ? (
          <>
            <div className='DatexTelcoDataType_date-info'>
              You can only use data from 8 days prior to the current date
            </div>
            <Calendar
              className='DatexTelcoDataType_date'
              disabledDate={(cd) => cd.isAfter(now)}
              fullscreen={false}
              headerRender={() => null}
              onChange={(val) => {
                if (!val.isAfter(now)) {
                  onChange({ day: val.date() });
                }
              }}
              value={moment(
                `${data?.year}-${data?.month}-${data?.day}`,
                'YYYY-MM-DD'
              )}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};
