import * as React from 'react';
export const IconYourData = (props) => (
  <svg fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path clipRule='evenodd' d='M1 15.9784V19.8434C1 20.6216 4.81965 21.2502 9.53448 21.2502C14.2493 21.2502 18.069 20.6216 18.069 19.8434V15.9784H1Z' fill='url(#paint0_linear_7718_16085)' fillRule='evenodd'/>
    <path d='M9.53448 17.3851C14.2479 17.3851 18.069 16.7553 18.069 15.9783C18.069 15.2014 14.2479 14.5715 9.53448 14.5715C4.82102 14.5715 1 15.2014 1 15.9783C1 16.7553 4.82102 17.3851 9.53448 17.3851Z' fill='#FF7C12'/>
    <path clipRule='evenodd' d='M1 10.7029V14.5678C1 15.3461 4.81965 15.9746 9.53448 15.9746C14.2493 15.9746 18.069 15.3461 18.069 14.5678V10.7029H1Z' fill='url(#paint1_linear_7718_16085)' fillRule='evenodd'/>
    <path d='M9.53448 12.1096C14.2479 12.1096 18.069 11.4798 18.069 10.7028C18.069 9.92587 14.2479 9.29602 9.53448 9.29602C4.82102 9.29602 1 9.92587 1 10.7028C1 11.4798 4.82102 12.1096 9.53448 12.1096Z' fill='#FF7C12'/>
    <path clipRule='evenodd' d='M1 5.43103V9.296C1 10.0742 4.81965 10.7028 9.53448 10.7028C14.2493 10.7028 18.069 10.0742 18.069 9.296V5.43103H1Z' fill='url(#paint2_linear_7718_16085)' fillRule='evenodd'/>
    <path d='M9.53448 6.8379C14.2479 6.8379 18.069 6.20805 18.069 5.43109C18.069 4.65414 14.2479 4.02429 9.53448 4.02429C4.82102 4.02429 1 4.65414 1 5.43109C1 6.20805 4.82102 6.8379 9.53448 6.8379Z' fill='#FF6700'/>
    <path clipRule='evenodd' d='M21.5371 10.5357C20.8362 11.2365 19.9448 11.7126 18.9771 11.9031C18.0057 12.0973 17 11.9983 16.0857 11.6174C15.1714 11.2404 14.3905 10.5967 13.8419 9.77395C13.2933 8.95125 13 7.98381 13 6.99732C13 6.01083 13.2933 5.04339 13.8419 4.22069C14.3905 3.39798 15.1714 2.7581 16.0857 2.37722C17 2.00014 18.0057 1.90492 18.9771 2.09536C19.9486 2.28961 20.84 2.76572 21.5371 3.46273C22.4743 4.3997 23 5.67185 23 6.99732C23 8.32279 22.4743 9.59494 21.5371 10.5319V10.5357Z' fill='#FEE18C' fillRule='evenodd'/>
    <path d='M20.0167 6.96243H20.6638C20.7348 6.96245 20.804 6.94125 20.8614 6.90188C20.9188 6.86252 20.9616 6.807 20.9835 6.74329C21.0055 6.67958 21.0055 6.61095 20.9836 6.54724C20.9616 6.48353 20.9189 6.428 20.8614 6.38863L18.1974 4.56063C18.14 4.52122 18.0708 4.5 17.9998 4.5C17.9288 4.5 17.8596 4.52122 17.8021 4.56063L15.1381 6.38894C15.0809 6.42833 15.0383 6.48378 15.0164 6.54737C14.9945 6.61096 14.9945 6.67944 15.0164 6.74304C15.0382 6.80664 15.0808 6.86209 15.138 6.90149C15.1953 6.94089 15.2642 6.96222 15.3351 6.96243H15.9829V9.1828C15.9829 9.26693 16.0183 9.34761 16.0813 9.4071C16.1444 9.46658 16.2299 9.5 16.319 9.5H17.3275V7.59683H18.6721V9.5H19.6805C19.7697 9.5 19.8552 9.46658 19.9182 9.4071C19.9813 9.34761 20.0167 9.26693 20.0167 9.1828V6.96243Z' fill='url(#paint3_linear_7718_16085)'/>
    <defs>
      <linearGradient gradientUnits='userSpaceOnUse' id='paint0_linear_7718_16085' x1='2.903' x2='16.147' y1='18.6124' y2='18.6124'>
        <stop stopColor='#FD8828'/>
        <stop offset='1' stopColor='#FEE28D'/>
      </linearGradient>
      <linearGradient gradientUnits='userSpaceOnUse' id='paint1_linear_7718_16085' x1='-0.5' x2='17' y1='13' y2='14'>
        <stop stopColor='#FD7F22'/>
        <stop offset='1' stopColor='#FFD65C'/>
      </linearGradient>
      <linearGradient gradientUnits='userSpaceOnUse' id='paint2_linear_7718_16085' x1='1' x2='10' y1='8.06879' y2='8.99994'>
        <stop stopColor='#FD7F22'/>
        <stop offset='1' stopColor='#FFD65C'/>
      </linearGradient>
      <linearGradient gradientUnits='userSpaceOnUse' id='paint3_linear_7718_16085' x1='18' x2='18' y1='4.5' y2='9.5'>
        <stop stopColor='#FFD65C'/>
        <stop offset='1' stopColor='#FF6700'/>
      </linearGradient>
    </defs>
  </svg>

);
