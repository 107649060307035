import { GENSET_CATEGORY_CONSTANT } from './GENSET_CATEGORY_CONSTANT';

const initialState = {
  status_GET: 'IDLE',
  status_POST: 'IDLE',
  status_PUT: 'IDLE',
  status_DELETE: 'IDLE',
  message: '',
  list: [],
  detail: {},
  properties: {},
};

export function GENSET_CATEGORY_REDUCER(state = initialState, action) {
  let returnData = state;
  Object.values(GENSET_CATEGORY_CONSTANT).map((ctx) => {
    if (action.type === GENSET_CATEGORY_CONSTANT.RESET_STATUS) {
      returnData = {
        ...returnData,
        status_GET: 'IDLE',
        status_POST: 'IDLE',
        status_PUT: 'IDLE',
        status_DELETE: 'IDLE',
        message: '',
      };
    } else if (action.type === GENSET_CATEGORY_CONSTANT.RESET_ALL) {
      returnData = {
        status_GET: 'IDLE',
        status_POST: 'IDLE',
        status_PUT: 'IDLE',
        status_DELETE: 'IDLE',
        message: '',
        data: {},
      };
    } else if (ctx === action.type && !ctx.includes('[NO_STATE_UPDATE]')) {
      returnData = { ...returnData, ...action.payload };
    }
    return null;
  });

  return returnData;
}
