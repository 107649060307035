import React from 'react';
import _ from 'lodash';

import { SharedBaseCardTypeOneComponent } from '@bvt-shared/component/SharedBaseCardTypeOne.component';
import './AsmanCardEducation.component.scss';

export const AsmanCardEducationComponent = props => {
  const { data, isLoading } = props;

  const sumTotal = _.sumBy(data, v => v.value || 0);

  return (
    <SharedBaseCardTypeOneComponent
      isLoading={isLoading}
      showIcon
      title='Education'
    >
      <div className='AsmanCardEducationComponent__chart-wrapper'>
        {data?.map((v) => {
          const percentage = (((v.value || 0) / sumTotal) * 100).toPrecision(3);
          return (
            <div
              className='AsmanCardEducationComponent__chart-bar-wrap'
              key={`${v.title}`}
            >
              <div className='AsmanCardEducationComponent__chart-icon'>
                {v.icon}
              </div>
              <div className='AsmanCardEducationComponent__chart-wrap'>
                <div className='AsmanCardEducationComponent__chart-title'>
                  {percentage}%
                </div>
                <div className='AsmanCardEducationComponent__chart-bar-item'>
                  <div
                    className='AsmanCardEducationComponent__chart-bar'
                    style={{
                      width: `${percentage}%`,
                      backgroundColor: `${v.color}`
                    }}
                  >
                    {v.title}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </SharedBaseCardTypeOneComponent>
  );
};
