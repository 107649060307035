import moment from 'moment';

const generateYearList = () => {
  const currentYear = moment().year();
  const yearList = [];
  for (let i = 0; i < 5; i++) {
    yearList.push({
      label: `${currentYear - i}`,
      value: currentYear - i,
    });
  }
  return yearList;
};

export const YEAR_LIST = generateYearList();

