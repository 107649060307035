import Resource from '../../../../../api/resource';

export class StepService extends Resource {
  constructor() {
    super('step', '/api', 'api-poi', 'v2');
  }

  /**
   *
   * @param {object} filter
   * @param {string} filter.code_parent
   * @param {number} filter.page
   */

  getByCode(filter) {
    const remapFilter = {
      meta: {
        filter: {
          code_parent: filter.code_parent,
        },
      },
      page: 1,
      sort: {
        by: 'DESC',
        name: '',
      },
    };

    const paramString = JSON.stringify(remapFilter);
    const params = encodeURIComponent(paramString);

    this.setAdditionUri('');
    return this.list({ params });
  }
}
