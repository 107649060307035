import React from 'react';
import './LayoutContentMainApp.scss';

/**
 * @author Ivan <tubagus.ivan@bvarta.com>
 * @param { object } props
 * @param { import('react').reactNode } props.children
 */
export function LayoutContentMainApp(props) {
  const { children } = props;

  return (
    <div className='LayoutContentMainApp'>
      <div className='LayoutContentMainApp__children'>
        {children}
      </div>
    </div>
  );
}
