import React from 'react';
import PropTypes from 'prop-types';
/**
 * @typedef {object} ISesSelectedBox
 * @property {string} className
 * @property {import('react').CSSProperties} style
 * @property {string} title - the ses title
 * @property {Boolean} isActive = Card is active
 * @property {string} activeColor - Active Color for card if isActive true
 * @property {JSX.Element} image - Card Image
 * @property {Function} onClick - click event
 * @property {Function} onHover - click event
 */

/**
 * @version 1
 * @author Randa <m.randa@bvarta.com>
 * @copyright PT Bhumi Varta Technology
 * @summary SesSelectedBox
 * @description -_ ¯\＿(ツ)＿/¯
 * @param {...ISesSelectedBox} props props derived from {@link {ISesSelectedBox}}
 */
export default function SesSelectedBox(props) {
  const {
    title,
    activeColor,
    image,
    isActive,
    className,
    style,
    onClick,
    onHover,
  } = props;
  const internalHandleClick = (e) => {
    onClick && onClick(e);
  };
  const internalHandleHover = (e) => {
    onClick && onHover(e);
  };
  return (
    <div
      className={`SesSelectedBox__card ${
        isActive && 'SesSelectedBox__card--active'
      } ${className}`}
      onClick={internalHandleClick}
      onMouseEnter={internalHandleHover}
      style={{ background: isActive && activeColor && activeColor, ...style }}
    >
      <div className='SesSelectedBox__header'>
        <div className='SesSelectedBox__ses-text'>SES</div>
        <div className='SesSelectedBox__ses-title'>{title || '-'}</div>
      </div>
      <div className='SesSelectedBox__ses-check'>
        <svg height='18' viewBox='0 0 17.341 17.341' width='18'>
          <g transform='translate(-364 -720)'>
            <path
              d='M16.19,2.69a8.67,8.67,0,1,1-8.67,8.67A8.719,8.719,0,0,1,16.19,2.69Z'
              fill='#fff'
              fillRule='evenodd'
              transform='translate(356.48 717.31)'
            />
            <path
              d='M13732.6,6189.381a1.6,1.6,0,0,1-1.6-1.6v-3.191a1.6,1.6,0,0,1,3.191,0v1.6h4.783a1.6,1.6,0,1,1,0,3.19Z'
              fill='#3c3'
              transform='translate(-13714.293 6066.657) rotate(-45)'
            />
          </g>
        </svg>
      </div>
      <div className='SesSelectedBox__body'>
        <div className='SesSelectedBox__image'>{image}</div>
      </div>
    </div>
  );
}

SesSelectedBox.defaultProps = {
  activeColor: undefined,
  className: undefined,
  image: undefined,
  isActive: undefined,
  onClick: undefined,
  onHover: undefined,
  style: {},
  title: undefined,
};

SesSelectedBox.propTypes = {
  activeColor: PropTypes.string,
  className: PropTypes.string,
  image: PropTypes.element,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
  style: PropTypes.object,
  title: PropTypes.string,
};
