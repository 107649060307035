import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useAdvanceSearch } from '../../hook/useAdvanceSearch';
import { useMainMap } from '@bvt-features/sub-module/mainmap/hook/useMainMap';
import { AdvanceSearchLabelListComponent } from '../../component/AdvanceSearchLabelList.component';


export const AdvanceSearchFloatingContainer = () => {
  const compRef = useRef(null);
  const [state, setState] = useState({ val: [] });
  const {
    state: {
      data: { geojson: dataGeojson },
      status_GEOJSON,
    },
    removeLevelFromGeoJSON,
    setSelectedAdministrativeValue,
  } = useAdvanceSearch();
  const mainmap = useMainMap();

  useEffect(() => {
    mainmap.disableMapEvent(compRef.current || undefined);
  }, []);

  useEffect(() => {
    const tmpArr = [];
    const filteredProvince = _.find(
      dataGeojson.features,
      (v) => v.properties.level === 'PROVINCE'
    )?.properties;
    const regencyProvince = _.find(
      dataGeojson.features,
      (v) => v.properties.level === 'REGENCY'
    )?.properties;
    const districtProvince = _.find(
      dataGeojson.features,
      (v) => v.properties.level === 'DISTRICT'
    )?.properties;
    const villageProvince = _.find(
      dataGeojson.features,
      (v) => v.properties.level === 'VILLAGE'
    )?.properties;

    if (filteredProvince) {
      tmpArr.push({
        label: filteredProvince.displayName,
        value: filteredProvince.id,
        level: 'province',
      });
    }
    if (regencyProvince) {
      tmpArr.push({
        label: regencyProvince.displayName,
        value: regencyProvince.id,
        level: 'regency',
      });
    }
    if (districtProvince) {
      tmpArr.push({
        label: districtProvince.displayName,
        value: districtProvince.id,
        level: 'district',
      });
    }
    if (villageProvince) {
      tmpArr.push({
        label: villageProvince.displayName,
        value: villageProvince.id,
        level: 'village',
      });
    }

    setState({ ...state, val: tmpArr });
  }, [dataGeojson]);

  const internalHandleRemove = (level) => {
    const scopedRemoveProvince = () => {
      removeLevelFromGeoJSON({ level: 'province' });
      setSelectedAdministrativeValue({
        level: 'province',
        manualSet: true,
        value: undefined,
      });
    };
    const scopedRemoveRegency = () => {
      removeLevelFromGeoJSON({ level: 'regency' });
      setSelectedAdministrativeValue({
        level: 'regency',
        manualSet: true,
        value: undefined,
      });
    };
    const scopedRemoveDistrict = () => {
      removeLevelFromGeoJSON({ level: 'district' });
      setSelectedAdministrativeValue({
        level: 'district',
        manualSet: true,
        value: undefined,
      });
    };
    const scopedRemoveVillage = () => {
      removeLevelFromGeoJSON({ level: 'village' });
      setSelectedAdministrativeValue({
        level: 'village',
        manualSet: true,
        value: undefined,
      });
    };

    if (level === 'province') {
      scopedRemoveProvince();
      scopedRemoveRegency();
      scopedRemoveDistrict();
      scopedRemoveVillage();
    }
    if (level === 'regency') {
      scopedRemoveRegency();
      scopedRemoveDistrict();
      scopedRemoveVillage();
    }
    if (level === 'district') {
      scopedRemoveDistrict();
      scopedRemoveVillage();
    }
    if (level === 'village') {
      scopedRemoveVillage();
    }
  };

  return (
    <AdvanceSearchLabelListComponent
      data={state.val}
      isLoading={status_GEOJSON === 'LOADING'}
      onRemove={(ctx) => internalHandleRemove(ctx.level)}
      ref={compRef}
    />
  );
};
