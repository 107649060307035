import { combineReducers } from 'redux';
import { GENSET_POI_REDUCER } from '../poi/redux';
import { GENSET_BRAND_REDUCER } from '../poi/redux/GENSET_BRAND_REDUCER';
import { GENSET_CATEGORY_REDUCER } from '../poi/redux/GENSET_CATEGORY_REDUCER';
import { GENSET_SUB_KATEGORI_REDUCER } from '../poi/redux/GENSET_SUBKATEGORI_REDUCER';
import { GENSET_CONSUME_POI_REDUCER } from '../poi/redux/GENSET_CONSUME_POI_REDUCER';
import { industryReducer } from '../poi/store/industry.slice';

export const GENSET_POI_COMBINE = combineReducers({
  KATEGORI: GENSET_CATEGORY_REDUCER,
  SUB_KATEGORI: GENSET_SUB_KATEGORI_REDUCER,
  BRAND: GENSET_BRAND_REDUCER,
  CONPOI: GENSET_CONSUME_POI_REDUCER,
  POI: GENSET_POI_REDUCER,
  INDUSTRY: industryReducer,
});
