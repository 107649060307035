// example geojson to long
/* eslint-disable */
export const PREVIEW_DATEX_GRID_DATA_CONSTANT = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        gid: 651424,
        center: {
          lat: '-6.205337379',
          lng: '106.8465911812',
        },
        sum_poi: 4,
        skala_result: 'Acceptable 4',
        score: 16.64,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.841591181194, -6.21033737896694],
              [106.841591181194, -6.20033737896694],
              [106.851591181194, -6.20033737896694],
              [106.851591181194, -6.21033737896694],
              [106.841591181194, -6.21033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 651509,
        center: {
          lat: '-6.165337379',
          lng: '106.8665911812',
        },
        sum_poi: 2,
        skala_result: 'Acceptable 3',
        score: 30,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.861591181194, -6.17033737896694],
              [106.861591181194, -6.16033737896694],
              [106.871591181194, -6.16033737896694],
              [106.871591181194, -6.17033737896694],
              [106.861591181194, -6.17033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 651510,
        center: {
          lat: '-6.165337379',
          lng: '106.8765911812',
        },
        sum_poi: 13,
        skala_result: 'Acceptable 3',
        score: 32.48,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.871591181194, -6.17033737896694],
              [106.871591181194, -6.16033737896694],
              [106.881591181194, -6.16033737896694],
              [106.881591181194, -6.17033737896694],
              [106.871591181194, -6.17033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 651511,
        center: {
          lat: '-6.165337379',
          lng: '106.8565911812',
        },
        sum_poi: 2,
        skala_result: 'Acceptable 4',
        score: 16.93,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.851591181194, -6.17033737896694],
              [106.851591181194, -6.16033737896694],
              [106.861591181194, -6.16033737896694],
              [106.861591181194, -6.17033737896694],
              [106.851591181194, -6.17033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 651512,
        center: {
          lat: '-6.155337379',
          lng: '106.8565911812',
        },
        sum_poi: 2,
        skala_result: 'Acceptable 4',
        score: 18.53,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.851591181194, -6.16033737896694],
              [106.851591181194, -6.15033737896694],
              [106.861591181194, -6.15033737896694],
              [106.861591181194, -6.16033737896694],
              [106.851591181194, -6.16033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 651513,
        center: {
          lat: '-6.175337379',
          lng: '106.8165911812',
        },
        sum_poi: 25,
        skala_result: 'Acceptable 2',
        score: 37.82,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.811591181194, -6.18033737896694],
              [106.811591181194, -6.17033737896694],
              [106.821591181194, -6.17033737896694],
              [106.821591181194, -6.18033737896694],
              [106.811591181194, -6.18033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 651514,
        center: {
          lat: '-6.175337379',
          lng: '106.8265911812',
        },
        sum_poi: 1,
        skala_result: 'Unacceptable',
        score: 5.38,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.821591181194, -6.18033737896694],
              [106.821591181194, -6.17033737896694],
              [106.831591181194, -6.17033737896694],
              [106.831591181194, -6.18033737896694],
              [106.821591181194, -6.18033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306738,
        center: {
          lat: '-6.205337379',
          lng: '106.8365911812',
        },
        sum_poi: 8,
        skala_result: 'Acceptable 3',
        score: 27.53,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.831591181194, -6.21033737896694],
              [106.831591181194, -6.20033737896694],
              [106.841591181194, -6.20033737896694],
              [106.841591181194, -6.21033737896694],
              [106.831591181194, -6.21033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306772,
        center: {
          lat: '-6.225337379',
          lng: '106.7965911812',
        },
        sum_poi: 4,
        skala_result: 'Unacceptable',
        score: 4.47,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.791591181194, -6.23033737896694],
              [106.791591181194, -6.22033737896694],
              [106.801591181194, -6.22033737896694],
              [106.801591181194, -6.23033737896694],
              [106.791591181194, -6.23033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306773,
        center: {
          lat: '-6.215337379',
          lng: '106.7965911812',
        },
        sum_poi: 2,
        skala_result: 'Unacceptable',
        score: 1.9,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.791591181194, -6.22033737896694],
              [106.791591181194, -6.21033737896694],
              [106.801591181194, -6.21033737896694],
              [106.801591181194, -6.22033737896694],
              [106.791591181194, -6.22033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306774,
        center: {
          lat: '-6.215337379',
          lng: '106.8065911812',
        },
        sum_poi: 6,
        skala_result: 'Unacceptable',
        score: 7.04,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.801591181194, -6.22033737896694],
              [106.801591181194, -6.21033737896694],
              [106.811591181194, -6.21033737896694],
              [106.811591181194, -6.22033737896694],
              [106.801591181194, -6.22033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306775,
        center: {
          lat: '-6.195337379',
          lng: '106.8065911812',
        },
        sum_poi: 6,
        skala_result: 'Acceptable 2',
        score: 41.96,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.801591181194, -6.20033737896694],
              [106.801591181194, -6.19033737896694],
              [106.811591181194, -6.19033737896694],
              [106.811591181194, -6.20033737896694],
              [106.801591181194, -6.20033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306776,
        center: {
          lat: '-6.185337379',
          lng: '106.8165911812',
        },
        sum_poi: 5,
        skala_result: 'Acceptable 3',
        score: 28.23,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.811591181194, -6.19033737896694],
              [106.811591181194, -6.18033737896694],
              [106.821591181194, -6.18033737896694],
              [106.821591181194, -6.19033737896694],
              [106.811591181194, -6.19033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306777,
        center: {
          lat: '-6.195337379',
          lng: '106.8265911812',
        },
        sum_poi: 19,
        skala_result: 'Acceptable 3',
        score: 36.38,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.821591181194, -6.20033737896694],
              [106.821591181194, -6.19033737896694],
              [106.831591181194, -6.19033737896694],
              [106.831591181194, -6.20033737896694],
              [106.821591181194, -6.20033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306778,
        center: {
          lat: '-6.195337379',
          lng: '106.8365911812',
        },
        sum_poi: 34,
        skala_result: 'Acceptable 1',
        score: 73.29,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.831591181194, -6.20033737896694],
              [106.831591181194, -6.19033737896694],
              [106.841591181194, -6.19033737896694],
              [106.841591181194, -6.20033737896694],
              [106.831591181194, -6.20033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306779,
        center: {
          lat: '-6.185337379',
          lng: '106.8265911812',
        },
        sum_poi: 13,
        skala_result: 'Acceptable 4',
        score: 21.29,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.821591181194, -6.19033737896694],
              [106.821591181194, -6.18033737896694],
              [106.831591181194, -6.18033737896694],
              [106.831591181194, -6.19033737896694],
              [106.821591181194, -6.19033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306780,
        center: {
          lat: '-6.185337379',
          lng: '106.8365911812',
        },
        sum_poi: 18,
        skala_result: 'Acceptable 3',
        score: 27.7,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.831591181194, -6.19033737896694],
              [106.831591181194, -6.18033737896694],
              [106.841591181194, -6.18033737896694],
              [106.841591181194, -6.19033737896694],
              [106.831591181194, -6.19033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306781,
        center: {
          lat: '-6.195337379',
          lng: '106.8465911812',
        },
        sum_poi: 14,
        skala_result: 'Acceptable 4',
        score: 20.83,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.841591181194, -6.20033737896694],
              [106.841591181194, -6.19033737896694],
              [106.851591181194, -6.19033737896694],
              [106.851591181194, -6.20033737896694],
              [106.841591181194, -6.20033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306782,
        center: {
          lat: '-6.185337379',
          lng: '106.8565911812',
        },
        sum_poi: 7,
        skala_result: 'Acceptable 2',
        score: 49.87,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.851591181194, -6.19033737896694],
              [106.851591181194, -6.18033737896694],
              [106.861591181194, -6.18033737896694],
              [106.861591181194, -6.19033737896694],
              [106.851591181194, -6.19033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306783,
        center: {
          lat: '-6.185337379',
          lng: '106.8665911812',
        },
        sum_poi: 1,
        skala_result: 'Acceptable 4',
        score: 19.04,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.861591181194, -6.19033737896694],
              [106.861591181194, -6.18033737896694],
              [106.871591181194, -6.18033737896694],
              [106.871591181194, -6.19033737896694],
              [106.861591181194, -6.19033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306784,
        center: {
          lat: '-6.165337379',
          lng: '106.8465911812',
        },
        sum_poi: 7,
        skala_result: 'Acceptable 3',
        score: 35.91,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.841591181194, -6.17033737896694],
              [106.841591181194, -6.16033737896694],
              [106.851591181194, -6.16033737896694],
              [106.851591181194, -6.17033737896694],
              [106.841591181194, -6.17033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306802,
        center: {
          lat: '-6.155337379',
          lng: '106.8465911812',
        },
        sum_poi: 4,
        skala_result: 'Acceptable 1',
        score: 68.57,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.841591181194, -6.16033737896694],
              [106.841591181194, -6.15033737896694],
              [106.851591181194, -6.15033737896694],
              [106.851591181194, -6.16033737896694],
              [106.841591181194, -6.16033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306803,
        center: {
          lat: '-6.175337379',
          lng: '106.8365911812',
        },
        sum_poi: 7,
        skala_result: 'Acceptable 4',
        score: 16.36,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.831591181194, -6.18033737896694],
              [106.831591181194, -6.17033737896694],
              [106.841591181194, -6.17033737896694],
              [106.841591181194, -6.18033737896694],
              [106.831591181194, -6.18033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306805,
        center: {
          lat: '-6.165337379',
          lng: '106.8365911812',
        },
        sum_poi: 13,
        skala_result: 'Acceptable 3',
        score: 26.58,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.831591181194, -6.17033737896694],
              [106.831591181194, -6.16033737896694],
              [106.841591181194, -6.16033737896694],
              [106.841591181194, -6.17033737896694],
              [106.831591181194, -6.17033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306807,
        center: {
          lat: '-6.155337379',
          lng: '106.8365911812',
        },
        sum_poi: 5,
        skala_result: 'Acceptable 2',
        score: 40.57,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.831591181194, -6.16033737896694],
              [106.831591181194, -6.15033737896694],
              [106.841591181194, -6.15033737896694],
              [106.841591181194, -6.16033737896694],
              [106.831591181194, -6.16033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306818,
        center: {
          lat: '-6.145337379',
          lng: '106.8365911812',
        },
        sum_poi: 6,
        skala_result: 'Acceptable 4',
        score: 14.22,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.831591181194, -6.15033737896694],
              [106.831591181194, -6.14033737896694],
              [106.841591181194, -6.14033737896694],
              [106.841591181194, -6.15033737896694],
              [106.831591181194, -6.15033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306820,
        center: {
          lat: '-6.145337379',
          lng: '106.8265911812',
        },
        sum_poi: 10,
        skala_result: 'Acceptable 2',
        score: 43.02,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.821591181194, -6.15033737896694],
              [106.821591181194, -6.14033737896694],
              [106.831591181194, -6.14033737896694],
              [106.831591181194, -6.15033737896694],
              [106.821591181194, -6.15033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306935,
        center: {
          lat: '-6.205337379',
          lng: '106.8065911812',
        },
        sum_poi: 9,
        skala_result: 'Acceptable 2',
        score: 44.26,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.801591181194, -6.21033737896694],
              [106.801591181194, -6.20033737896694],
              [106.811591181194, -6.20033737896694],
              [106.811591181194, -6.21033737896694],
              [106.801591181194, -6.21033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306936,
        center: {
          lat: '-6.215337379',
          lng: '106.8165911812',
        },
        sum_poi: 25,
        skala_result: 'Acceptable 2',
        score: 40.67,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.811591181194, -6.22033737896694],
              [106.811591181194, -6.21033737896694],
              [106.821591181194, -6.21033737896694],
              [106.821591181194, -6.22033737896694],
              [106.811591181194, -6.22033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306937,
        center: {
          lat: '-6.205337379',
          lng: '106.8165911812',
        },
        sum_poi: 19,
        skala_result: 'Acceptable 3',
        score: 32.97,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.811591181194, -6.21033737896694],
              [106.811591181194, -6.20033737896694],
              [106.821591181194, -6.20033737896694],
              [106.821591181194, -6.21033737896694],
              [106.811591181194, -6.21033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306938,
        center: {
          lat: '-6.195337379',
          lng: '106.8165911812',
        },
        sum_poi: 3,
        skala_result: 'Acceptable 3',
        score: 25.46,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.811591181194, -6.20033737896694],
              [106.811591181194, -6.19033737896694],
              [106.821591181194, -6.19033737896694],
              [106.821591181194, -6.20033737896694],
              [106.811591181194, -6.20033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306939,
        center: {
          lat: '-6.195337379',
          lng: '106.8565911812',
        },
        sum_poi: 13,
        skala_result: 'Acceptable 3',
        score: 28.33,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.851591181194, -6.20033737896694],
              [106.851591181194, -6.19033737896694],
              [106.861591181194, -6.19033737896694],
              [106.861591181194, -6.20033737896694],
              [106.851591181194, -6.20033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306940,
        center: {
          lat: '-6.185337379',
          lng: '106.8465911812',
        },
        sum_poi: 12,
        skala_result: 'Acceptable 3',
        score: 29.59,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.841591181194, -6.19033737896694],
              [106.841591181194, -6.18033737896694],
              [106.851591181194, -6.18033737896694],
              [106.851591181194, -6.19033737896694],
              [106.841591181194, -6.19033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306941,
        center: {
          lat: '-6.175337379',
          lng: '106.8465911812',
        },
        sum_poi: 9,
        skala_result: 'Acceptable 3',
        score: 25.39,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.841591181194, -6.18033737896694],
              [106.841591181194, -6.17033737896694],
              [106.851591181194, -6.17033737896694],
              [106.851591181194, -6.18033737896694],
              [106.841591181194, -6.18033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306942,
        center: {
          lat: '-6.175337379',
          lng: '106.8565911812',
        },
        sum_poi: 4,
        skala_result: 'Acceptable 4',
        score: 16.22,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.851591181194, -6.18033737896694],
              [106.851591181194, -6.17033737896694],
              [106.861591181194, -6.17033737896694],
              [106.861591181194, -6.18033737896694],
              [106.851591181194, -6.18033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306943,
        center: {
          lat: '-6.175337379',
          lng: '106.8665911812',
        },
        sum_poi: 8,
        skala_result: 'Acceptable 3',
        score: 26.6,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.861591181194, -6.18033737896694],
              [106.861591181194, -6.17033737896694],
              [106.871591181194, -6.17033737896694],
              [106.871591181194, -6.18033737896694],
              [106.861591181194, -6.18033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306944,
        center: {
          lat: '-6.165337379',
          lng: '106.8265911812',
        },
        sum_poi: 15,
        skala_result: 'Acceptable 2',
        score: 39.35,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.821591181194, -6.17033737896694],
              [106.821591181194, -6.16033737896694],
              [106.831591181194, -6.16033737896694],
              [106.831591181194, -6.17033737896694],
              [106.821591181194, -6.17033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306945,
        center: {
          lat: '-6.165337379',
          lng: '106.8165911812',
        },
        sum_poi: 26,
        skala_result: 'Acceptable 2',
        score: 51.23,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.811591181194, -6.17033737896694],
              [106.811591181194, -6.16033737896694],
              [106.821591181194, -6.16033737896694],
              [106.821591181194, -6.17033737896694],
              [106.811591181194, -6.17033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306946,
        center: {
          lat: '-6.165337379',
          lng: '106.8065911812',
        },
        sum_poi: 22,
        skala_result: 'Acceptable 1',
        score: 54.41,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.801591181194, -6.17033737896694],
              [106.801591181194, -6.16033737896694],
              [106.811591181194, -6.16033737896694],
              [106.811591181194, -6.17033737896694],
              [106.801591181194, -6.17033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306982,
        center: {
          lat: '-6.205337379',
          lng: '106.7965911812',
        },
        sum_poi: 5,
        skala_result: 'Unacceptable',
        score: 5.75,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.791591181194, -6.21033737896694],
              [106.791591181194, -6.20033737896694],
              [106.801591181194, -6.20033737896694],
              [106.801591181194, -6.21033737896694],
              [106.791591181194, -6.21033737896694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1306987,
        center: {
          lat: '-6.175337379',
          lng: '106.8065911812',
        },
        sum_poi: 18,
        skala_result: 'Acceptable 3',
        score: 28.98,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.801591181194, -6.18033737896694],
              [106.801591181194, -6.17033737896694],
              [106.811591181194, -6.17033737896694],
              [106.811591181194, -6.18033737896694],
              [106.801591181194, -6.18033737896694],
            ],
          ],
        ],
      },
    },
  ],
};
