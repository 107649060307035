export const MAPP_PROJECT_CONSTANT = {
  CREATE_START: 'LI/MAPP/PROJECT/create/requested',
  CREATE_FAILED: 'LI/MAPP/PROJECT/create/failed',
  CREATE_SUCCESS: 'LI/MAPP/PROJECT/create/success',

  GET_DETAIL_START: 'LI/MAPP/PROJECT/get-detail/requested',
  GET_DETAIL_FAILED: 'LI/MAPP/PROJECT/get-detail/failed',
  GET_DETAIL_SUCCESS: 'LI/MAPP/PROJECT/get-detail/success',

  PATCH_RENAME_START: 'LI/MAPP/PROJECT/rename/requested',
  PATCH_RENAME_FAILED: 'LI/MAPP/PROJECT/rename/failed',
  PATCH_RENAME_SUCCESS: 'LI/MAPP/PROJECT/rename/success',

  RESET_ALL: 'LI/MAPP/PROJECT/reset-state',
  RESET_STATUS: 'LI/MAPP/PROJECT/reset-status',

  GET_ALL_START: 'LI/MAPP/PROJECT/get-all/requested',
  GET_ALL_FAILED: 'LI/MAPP/PROJECT/get-all/failed',
  GET_ALL_SUCCESS: 'LI/MAPP/PROJECT/get-all/success',

};
