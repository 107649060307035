import * as React from 'react';

export const IconTelcoData = (props) => (
  <svg
    fill='none'
    height={24}
    width={24}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#a)'>
      <path
        d='M17.427 21.172 12.662 8.254a.637.637 0 0 0-.627-.41.635.635 0 0 0-.627.41L6.643 21.172a.61.61 0 0 0 .273.74.65.65 0 0 0 .8-.117l4.32-4.671 4.319 4.671a.656.656 0 0 0 .8.117.61.61 0 0 0 .273-.74ZM12.035 10.22l1.332 3.611-1.33 1.44-1.333-1.44 1.332-3.61h-.001Zm-3.1 8.404 1.28-3.47.965 1.043-2.244 2.427Zm3.957-2.427.963-1.041 1.28 3.467-2.244-2.426h.001Z'
        fill='url(#b)'
      />
      <rect fill='#FF880F' height={6} rx={3} width={6} x={9} y={4} />
      <path
        clipRule='evenodd'
        d='M10.731 5.766c.28-.272.655-.434 1.051-.452.396-.018.785.107 1.09.351a1.522 1.522 0 0 1 .314 2.062 2.538 2.538 0 0 1-.437.427 1.63 1.63 0 0 1-2.123-.305 1.523 1.523 0 0 1 .104-2.082l.001-.001Z'
        fill='#fff'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='m13.186 7.729.489.476c.037.036.059.086.059.138a.193.193 0 0 1-.06.138l-.153.15a.204.204 0 0 1-.142.056.204.204 0 0 1-.143-.056l-.488-.476c.163-.124.31-.268.438-.426Z'
        fill='url(#c)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M11.861 7.8V5.929c-.255 0-.5.098-.68.273a.922.922 0 0 0-.28.661c0 .248.1.486.28.66.18.176.425.274.68.274V7.8Z'
        fill='url(#d)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M11.862 7.8V5.929c.255 0 .5.098.68.274a.918.918 0 0 1-.001 1.323.977.977 0 0 1-.682.274h.003Z'
        fill='url(#e)'
        fillRule='evenodd'
      />
      <path
        d='M17.116 2a.49.49 0 0 1 .28.089C19.028 3.22 20 5.057 20 7c0 1.945-.97 3.778-2.597 4.91a.494.494 0 0 1-.676-.11.463.463 0 0 1 .111-.657C18.21 10.188 19.03 8.64 19.03 7c0-1.638-.82-3.19-2.197-4.145a.464.464 0 0 1-.113-.658.49.49 0 0 1 .396-.198Z'
        fill='url(#f)'
      />
      <path
        d='M15.485 3.586a.49.49 0 0 1 .282.088A4.065 4.065 0 0 1 17.529 7a4.062 4.062 0 0 1-1.758 3.324.494.494 0 0 1-.677-.109.463.463 0 0 1 .112-.658 3.128 3.128 0 0 0 1.354-2.557c0-1.01-.508-1.97-1.357-2.56a.464.464 0 0 1-.113-.657.49.49 0 0 1 .395-.198Z'
        fill='url(#g)'
      />
      <path
        d='M6.885 11.998a.49.49 0 0 1-.282-.088C4.973 10.78 4 8.942 4 6.998c0-1.945.97-3.778 2.597-4.91a.494.494 0 0 1 .677.11.463.463 0 0 1-.113.657C5.79 3.811 4.97 5.36 4.97 6.998c0 1.638.82 3.19 2.197 4.145.218.151.268.445.113.658a.49.49 0 0 1-.395.198Z'
        fill='url(#h)'
      />
      <path
        d='M8.515 10.413a.49.49 0 0 1-.281-.088A4.064 4.064 0 0 1 6.47 6.998c0-1.317.657-2.557 1.758-3.324a.494.494 0 0 1 .677.109.463.463 0 0 1-.112.658A3.128 3.128 0 0 0 7.44 6.998c0 1.01.508 1.97 1.357 2.56.218.15.268.445.113.657a.49.49 0 0 1-.395.198Z'
        fill='url(#i)'
      />
    </g>
    <defs>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='b'
        x1={17.024}
        x2={9.779}
        y1={10.533}
        y2={23.438}
      >
        <stop stopColor='#FF6700' />
        <stop offset={1} stopColor='#FFCB2E' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='c'
        x1={13.38}
        x2={13.077}
        y1={8.389}
        y2={7.986}
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#D7E5F5' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='d'
        x1={9.527}
        x2={13.143}
        y1={6.863}
        y2={6.863}
      >
        <stop stopColor='#FF6700' />
        <stop offset={1} stopColor='#FFCB2E' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='e'
        x1={10.13}
        x2={14.187}
        y1={6.863}
        y2={6.863}
      >
        <stop stopColor='#FF6700' />
        <stop offset={1} stopColor='#FFCB2E' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='f'
        x1={15.081}
        x2={19.412}
        y1={3.07}
        y2={10.785}
      >
        <stop stopColor='#FF6700' />
        <stop offset={1} stopColor='#FFCB2E' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='g'
        x1={14.078}
        x2={17.053}
        y1={9.695}
        y2={4.393}
      >
        <stop stopColor='#FF6700' />
        <stop offset={1} stopColor='#FFCB2E' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='h'
        x1={8.919}
        x2={4.588}
        y1={10.93}
        y2={3.214}
      >
        <stop stopColor='#FF6700' />
        <stop offset={1} stopColor='#FFCB2E' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='i'
        x1={9.922}
        x2={6.947}
        y1={4.304}
        y2={9.606}
      >
        <stop stopColor='#FF6700' />
        <stop offset={1} stopColor='#FFCB2E' />
      </linearGradient>
      <clipPath id='a'>
        <path d='M0 0h16v20H0z' fill='#fff' transform='translate(4 2)' />
      </clipPath>
    </defs>
  </svg>
);

