import React from 'react';
export const IconPlus = () => (
  <svg
    fill='none'
    height='1rem'
    viewBox='0 0 24 24'
    width='1rem'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      clipRule='evenodd'
      d='M14.1431 14.1429V19.8572C14.1431 21.0407 13.1837 22.0001 12.0002 22.0001C10.8167 22.0001 9.85735 21.0407 9.85735 19.8572V14.1429H4.14274C2.95927 14.1429 1.99988 13.1835 1.99988 12.0001C1.99988 10.8166 2.95927 9.85721 4.14274 9.85721H9.85735V4.14292C9.85735 2.95945 10.8167 2.00006 12.0002 2.00006C13.1837 2.00006 14.1431 2.95945 14.1431 4.14292V9.85721H19.857C21.0405 9.85721 21.9999 10.8166 21.9999 12.0001C21.9999 13.1835 21.0405 14.1429 19.857 14.1429H14.1431Z'
      fill='currentColor'
      fillRule='evenodd'
    />
  </svg>
);
