import React, { useEffect, useState } from 'react';
import ForgotPasswordForm from '@bvt-features/auth/component/ForgotPasswordForm';
import { useAuth } from '@bvt-features/auth/hooks';
import { useNavigate } from 'react-router';
import { useLottie } from 'lottie-react';
import { SendMailFailedToSend, ForgotPassworSendToMail } from '@bvt-animations';
import AuthForgotMessage from '@bvt-features/auth/component/AuthForgotMessage';

/**
 * @author Bobi
 * @author Faisal
 * @author Randa <m.randa@bvarta.com>
 * @copyright PT Bhumi varta Technology
 */
const ForgotPasswordContainer = () => {
  const navigate = useNavigate();
  const [tmpEmail, setTmpEmail] = useState('');
  const auth = useAuth();

  const Lani = useLottie({
    animationData: SendMailFailedToSend,
    autoplay: false,
    loop: false,
  });
  const Lani2 = useLottie({
    animationData: ForgotPassworSendToMail,
    autoplay: false,
    loop: false,
  });

  useEffect(() => {
    if (auth.state.status_POST === 'FAILED') {
      Lani.goToAndPlay(0, false);
    }
    if (auth.state.status_POST === 'SUCCESS') {
      Lani2.goToAndPlay(0, true);
    }
  }, [auth.state.status_POST]);

  useEffect(() => {
    return () => {
      auth.resetStatus();
    };
  }, []);
  return (
    <React.Fragment>
      <div
        style={{
          display: auth.state.status_POST === 'FAILED' ? 'block' : 'none',
        }}
      >
        {Lani.View}
      </div>
      <div
        style={{
          display: auth.state.status_POST === 'SUCCESS' ? 'block' : 'none',
        }}
      >
        {Lani2.View}
      </div>
      {auth.state.status_POST === 'IDLE' && (
        <ForgotPasswordForm
          isLoading={auth.state.status_POST === 'LOADING'}
          onBackClick={() => navigate({ pathname: '/auth' })}
          onSubmit={(e) => {
            auth.forgetPassword(e);
            setTmpEmail(e.email);
          }}
        />
      )}
      {auth.state.status_POST !== 'IDLE' && (
        <AuthForgotMessage
          email={tmpEmail}
          isFailed={auth.state.status_POST === 'FAILED'}
          isLoading={auth.state.status_POST === 'LOADING'}
          message={auth.state.message}
          onBack={() =>
            auth.state.status_POST === 'SUCCESS'
              ? navigate('/auth')
              : auth.resetStatus()
          }
          onResend={() => auth.forgetPassword({ email: tmpEmail })}
        />
      )}
    </React.Fragment>
  );
};

export default ForgotPasswordContainer;
