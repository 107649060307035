import { combineReducers } from 'redux';
import { BASE_LAYER } from './reducer/baselayer';
import { DIRECTION } from './reducer/direction';
import { FULL_SCREEN } from './reducer/fullscreen';
import { SIDEBAR_LEFT } from './reducer/sidebar_left';
import { SIDEBAR_RIGHT } from './reducer/sidebar_right';
import { UUID } from './reducer/uuid';
import { TILE_LAYER } from './reducer/tile_layer';
import { ZOOM_BOX } from './reducer/zoom_box';

export const interactiveMapReducer = combineReducers({
  BASE_LAYER,
  DIRECTION,
  FULL_SCREEN,
  SIDEBAR_LEFT,
  SIDEBAR_RIGHT,
  UUID,
  TILE_LAYER,
  ZOOM_BOX,
});
