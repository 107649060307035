import { LEGACY_AUTH_CONSTANT } from '@bvt-features/auth/redux';
import moment from 'moment';
import { HISTORY_LOG_ACTIVITY_CONSTANT } from './HISTORY_LOG_ACTIVITY_CONSTANT';

const initialState = {
  status_GET: 'IDLE',
  status_POST: 'IDLE',
  status_PUT: 'IDLE',
  status_DELETE: 'IDLE',
  message: '',
  data: [],
  meta: {
    total: 0,
    limit: 10,
    page: 1,
    filter: {
      start_date: moment().format('YYYY-MM-DD'),
    },
  },
};

export function HISTORY_LOG_ACTIVITY_REDUCER(state = initialState, action) {
  let returnData = state;
  Object.values(HISTORY_LOG_ACTIVITY_CONSTANT).map((ctx) => {
    if (action.type === HISTORY_LOG_ACTIVITY_CONSTANT.RESET_STATUS) {
      returnData = {
        ...returnData,
        status_GET: 'IDLE',
        status_POST: 'IDLE',
        status_PUT: 'IDLE',
        status_DELETE: 'IDLE',
        message: ' ',
      };
    } else if (
      action.type === LEGACY_AUTH_CONSTANT.UNAUTHORIZED ||
      action.type === HISTORY_LOG_ACTIVITY_CONSTANT.RESET_ALL
    ) {
      returnData = { ...initialState };
    } else if (ctx === action.type && !ctx.includes('[NO_STATE_UPDATE]')) {
      returnData = { ...returnData, ...action.payload };
    }
    return null;
  });

  return returnData;
}
