import * as React from 'react';
const IconRegionPolygon = (props) => (
  <svg
    fill='none'
    height={704}
    viewBox='0 0 359 704'
    width={359}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M124.183 95.9263L136.325 78.1236V70.3358L144.197 64.0327L136.325 49.5694L138.948 46.6001L147.478 49.5694L154.369 46.6001L164.864 57.7262L174.05 59.582L194.721 49.5694L206.204 34.7339L208.499 23.979L217.029 14.3353H221.294L237.699 0.611084L246.229 2.83807L272.146 38.809L331.853 126.701L353.177 165.273L345.308 178.996L325.296 181.221L306.265 177.143L294.458 186.042V207.925L296.759 229.065L299.708 267.637L296.759 281.728L289.541 302.498L292.164 312.884L307.91 332.906L312.503 347.372L314.144 368.14L312.503 392.248L320.049 406.712L331.857 413.387L354.824 420.06L358.43 426.734L353.178 438.603L333.823 456.036L320.045 452.696H287.565L275.429 459.369L271.489 469.754L269.521 503.877L274.114 542.449L269.521 554.689L226.541 635.166L243.931 691.541L240.325 700.073L204.563 703.407L197.017 696.733L163.555 691.541L156.337 696.733H142.229V678.19L127.792 667.806L123.2 651.485L117.298 642.585L119.924 635.165L111.394 619.591L119.924 593.259V584.357L111.394 578.423L102.861 559.136L104.504 548.752L97.6135 542.447L94.6624 535.4L104.504 530.582L100.24 512.407L78.9144 505.732V495.349L88.0978 489.046V472.728L82.526 467.904V459.373L85.8054 452.7L82.526 447.508L66.7779 438.232V410.044L71.3706 401.145L52.6715 370.36L49.7165 361.084L52.6715 358.117L49.7165 342.168L42.8275 338.461L32.0024 326.593L35.9404 319.546L21.5037 310.647L11.007 307.307L14.6147 302.486L12.3203 292.844L15.928 285.428L14.6147 273.928L7.72366 268.736V257.978L0.506287 253.901V245.371L7.72366 238.326L9.36726 228.312L21.5037 211.622H24.785V219.781H29.0494L54.6395 192.335L45.1238 183.059L60.5436 163.772L71.0423 130.393L85.8073 126.313L91.383 112.966L85.8073 104.807L88.0998 91.0813L95.3191 92.567L100.897 98.1301H115.99L124.183 95.9263Z'
      fill='#364961'
      fillOpacity={0.1}
      stroke='#364961'
      strokeOpacity={0.5}
    />
  </svg>
);
export { IconRegionPolygon };
