import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Faisal <ahmad.faisal@bvarta.com>
 * @param {Object} props
 * @param {string} props.className
 * @param {Function} props.onClick
 */

export default function IconView(props) {
  const { className, onClick } = props;

  return (
    <div className={`${className} iconPdf`} onClick={onClick}>
      <svg
        height='14.411'
        viewBox='0 0 18.445 14.411'
        width='18.445'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g data-name='Group 8449' id='Group_8449' transform='translate(0 0)'>
          <g data-name='Group 648' id='Group_648' transform='translate(0 0)'>
            <path
              d='M17.768,3H2.677A1.645,1.645,0,0,0,1,4.6v9.607a1.645,1.645,0,0,0,1.677,1.6H6.869v1.6h6.707v-1.6h4.192a1.638,1.638,0,0,0,1.668-1.6L19.445,4.6A1.645,1.645,0,0,0,17.768,3Zm0,11.208H2.677V4.6H17.768Z'
              fill='#4d4f5c'
              id='ic_tv_24px'
              transform='translate(-1 -3)'
            />
          </g>
          <g data-name='Group 8448' id='Group_8448' transform='translate(3 3)'>
            <rect
              data-name='Rectangle 4660'
              fill='#caced5'
              height='7'
              id='Rectangle_4660'
              width='3'
            />
            <rect
              data-name='Rectangle 4661'
              fill='#00a8ff'
              height='7'
              id='Rectangle_4661'
              transform='translate(4)'
              width='8'
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

IconView.defaultProps = {
  className: '',
  onClick: () => {},
};

IconView.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
