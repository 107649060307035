import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Faisal <ahmad.faisal@bvarta.com>
 * @param {Object} props
 * @param {string} props.className
 * @param {string} props.onClick
 */

export default function IconCsv(props) {
  const { className, onClick } = props;

  return (
    <div className={`${className} iconCsv`} onClick={onClick}>
      <svg
        height='18.17'
        id='_1577462336'
        viewBox='0 0 15.033 18.17'
        width='15.033'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M10.4,100.79v1.925h1.925ZM.792,98.082H9.938l5.1,5.1V116.25H0V98.08H.792Zm8.024,1.584H1.587v15H13.449V104.3H8.817V99.667Z'
          data-name='Path 755'
          fill='#4d4f5c'
          id='Path_755'
          transform='translate(0 -98.08)'
        />
        <path
          d='M6.94,145.92H18.8v5.664H6.94Z'
          data-name='Path 756'
          fill='#669800'
          fillRule='evenodd'
          id='Path_756'
          transform='translate(-5.356 -134.999)'
        />
        <path
          d='M14.918,153.61l.568.176a1.312,1.312,0,0,1-.436.715,1.236,1.236,0,0,1-.772.233,1.251,1.251,0,0,1-.954-.4,1.514,1.514,0,0,1-.374-1.082,1.584,1.584,0,0,1,.377-1.128,1.281,1.281,0,0,1,.986-.4,1.2,1.2,0,0,1,.868.317,1.157,1.157,0,0,1,.3.536l-.582.137a.609.609,0,0,0-.215-.356.62.62,0,0,0-.4-.13.664.664,0,0,0-.525.233,1.139,1.139,0,0,0-.2.753,1.217,1.217,0,0,0,.2.785.64.64,0,0,0,.516.235.593.593,0,0,0,.4-.148.867.867,0,0,0,.242-.47Zm1.436.13.573-.057a.7.7,0,0,0,.208.422.636.636,0,0,0,.425.135.652.652,0,0,0,.427-.119.357.357,0,0,0,.144-.281.273.273,0,0,0-.059-.176.494.494,0,0,0-.21-.126c-.068-.025-.224-.066-.468-.128a1.542,1.542,0,0,1-.66-.29.76.76,0,0,1-.267-.587.771.771,0,0,1,.126-.418.785.785,0,0,1,.363-.3,1.427,1.427,0,0,1,.571-.1,1.21,1.21,0,0,1,.822.242.856.856,0,0,1,.29.646l-.589.021a.5.5,0,0,0-.16-.324.579.579,0,0,0-.368-.1.657.657,0,0,0-.4.105.215.215,0,0,0-.094.183.231.231,0,0,0,.087.178,1.526,1.526,0,0,0,.539.2,2.853,2.853,0,0,1,.632.208.825.825,0,0,1,.322.3.925.925,0,0,1-.025.934.835.835,0,0,1-.393.324,1.651,1.651,0,0,1-.635.107,1.252,1.252,0,0,1-.847-.256,1.107,1.107,0,0,1-.354-.74Zm4.125.943-1.041-2.908h.637l.74,2.153.71-2.153h.626l-1.036,2.908h-.635Z'
          data-name='Path 757'
          fill='#fff'
          id='Path_757'
          transform='translate(-9.994 -139.474)'
        />
      </svg>
    </div>
  );
}

IconCsv.defaultProps = {
  className: '',
  onClick: () => {},
};

IconCsv.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
