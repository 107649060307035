import Resource from '../../../../api/resource';

export class GensetParameterService extends Resource {
  constructor() {
    super('parameter', '/api', 'api-poi', 'v2');
  }
  /**
   *
   * parameter service
   * @param {object} params - Params for get list parameter
   * @param {object} params.meta - Meta for filter parameter
   * @param {object} params.meta.filter
   * @param {string} params.meta.filter.keyword
   * @param {string} params.meta.filter.user_id
   * @param {number} params.meta.page
   * @param {number} params.meta.pageSize
   * @param {object} params.meta.sort
   * @param {string} params.meta.sort.by
   * @param {string} params.meta.sort.name
   */
  getAll(query) {
    this.setAdditionUri('');
    const paramString = JSON.stringify(query);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }

  getDetail(id) {
    this.setAdditionUri('');
    return this.get(id);
  }

  add(payload) {
    this.setAdditionUri('');
    return this.create(payload);
  }

  edit(id, payload) {
    this.setAdditionUri('');
    return this.update(id, payload);
  }

  delete(id) {
    this.setAdditionUri('');
    return this.destroy(id);
  }

  downloadParameter(payload) {
    this.setAdditionUri('/download');
    return this.download(payload);
  }

  uploadParameter(id, payload) {
    this.setAdditionUri('/upload');
    const query = {
      parameter_for_user: id,
    };
    return this.upload(query, payload);
  }
}
