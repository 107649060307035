import React from 'react';
import PropTypes from 'prop-types';

/**
 *
 * @param {object} props
 * @param {HTMLElement | JSX.Element} props.children
 * @param {string} props.headerBgColor
 * @param {string} props.headerColor
 * @param {HTMLElement | JSX.Element} props.headerIcon CircleInfo from 'bvt-icon' <CircleInfo height='1.25rem' width='1.25rem' />
 * @param {string | HTMLElement} props.headerText
 */

export default function AnalyzeCard(props) {
  return (
    <div className='AnalyzeCard'>
      <div
        className='AnalyzeCard--header'
        style={{ backgroundColor: props.headerBgColor }}
      >
        {props?.headerIcon}
        <div
          className='AnalyzeCard--header_text'
          style={{
            color: props.headerColor,
          }}
        >
          {props.headerText}
        </div>
      </div>
      <div className='AnalyzeCard--body'>{props.children}</div>
    </div>
  );
}

AnalyzeCard.defaultProps = {
  children: <b>Children HTML Element</b>,
  headerText: 'Zoning',
};

AnalyzeCard.propTypes = {
  children: PropTypes.node,
  headerBgColor: PropTypes.string,
  headerColor: PropTypes.string,
  headerIcon: PropTypes.node,
  headerText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
