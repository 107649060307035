import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Faisal <ahmad.faisal@bvarta.com>
 * @param {Object} props
 * @param {string} props.className
 * @param {string} props.disable
 * @param {string} props.onClick
 */

export default function IconFolderUp(props) {
  const { className, disable, onClick } = props;

  return (
    <div
      className={`${className} icon-file  ${disable ? 'disable' : ''}`}
      onClick={onClick}
    >
      <svg
        height='21'
        viewBox='0 0 21 21'
        width='21'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g id='ic-contact-edit' transform='translate(0 0.126)'>
          <rect
            data-name='Rectangle 86'
            fill='none'
            height='21'
            id='Rectangle_86'
            transform='translate(0 -0.126)'
            width='21'
          />
          <g id='Layer_x0020_1' transform='translate(1.681 3.088)'>
            <path
              d='M1.8,14.519H1.765a2.305,2.305,0,0,1-1.2-.756A2.361,2.361,0,0,1,0,12.25V1.913A1.884,1.884,0,0,1,.567.557,1.961,1.961,0,0,1,1.923-.01H7.186A1.884,1.884,0,0,1,8.541.557a1.961,1.961,0,0,1,.567,1.355v.536h7.028a3.473,3.473,0,0,1,.567.063,1.973,1.973,0,0,1,1.04.693,2.006,2.006,0,0,1,.378,1.2,1.979,1.979,0,0,1-.095.6L16.01,12.5A2.986,2.986,0,0,1,15,13.983a2.708,2.708,0,0,1-1.67.6H2.3a2.081,2.081,0,0,1-.441-.032l-.095-.032Zm.378-1.8H13.332a.894.894,0,0,0,.536-.221.92.92,0,0,0,.347-.5l2.017-7.5c0-.032.032-.095.032-.126V4.276a.031.031,0,0,1-.032-.032H5.106a.894.894,0,0,0-.536.221.92.92,0,0,0-.347.5L2.206,12.534V12.6a.116.116,0,0,0,.032.095Zm8.163-3.94v2.143a1.135,1.135,0,0,1-2.269,0V8.783h0A1.114,1.114,0,0,1,6.492,7.207L8.383,5.316h0l.032-.032a1.141,1.141,0,0,1,1.607,0h0l.032.032,1.891,1.891a1.114,1.114,0,0,1-1.576,1.576h0ZM1.859,6.672l.567-2.143A2.986,2.986,0,0,1,3.435,3.047a2.708,2.708,0,0,1,1.67-.6H7.249V1.913a.031.031,0,0,0-.032-.032l-.032-.032H1.923a.031.031,0,0,0-.032.032l-.032.032V6.672Z'
              data-name='Path 3423'
              fill='#364961'
              fillRule='evenodd'
              id='Path_3423'
              transform='translate(0 0)'
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

IconFolderUp.defaultProps = {
  className: '',
  disable: false,
  onClick: () => {},
};

IconFolderUp.propTypes = {
  className: PropTypes.string,
  disable: PropTypes.bool,
  onClick: PropTypes.func,
};
