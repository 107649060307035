import { LEGACY_AUTH_CONSTANT } from '@bvt-features/auth/redux';
import { STEP_CONSTANT } from './STEP_CONSTANT';

const initialState = {
  status_GET: 'IDLE',
  message: '',
  data: [],
  step: 0,
  code: '',
};

export function STEP_REDUCER(state = initialState, action) {
  let returnData = state;
  Object.values(STEP_CONSTANT).map((ctx) => {
    if (action.type === STEP_CONSTANT.RESET_STATUS) {
      returnData = {
        ...returnData,
        status_GET: 'IDLE',
        status_POST: 'IDLE',
        status_PUT: 'IDLE',
        status_DELETE: 'IDLE',
        message: ' ',
      };
    } else if (action.type === STEP_CONSTANT.CURRENT_STEP) {
      returnData = {
        ...returnData,
        step: action.payload.step,
      };
    } else if (action.type === STEP_CONSTANT.STEP_CODE) {
      returnData = {
        ...returnData,
        code: action.payload.code,
      };
    } else if (action.type === STEP_CONSTANT.RESET_ALL) {
      returnData = { ...initialState };
    } else if (action.type === LEGACY_AUTH_CONSTANT.UNAUTHORIZED) {
      returnData = { ...initialState };
    } else if (ctx === action.type && !ctx.includes('[NO_STATE_UPDATE]')) {
      returnData = { ...returnData, ...action.payload };
    }
  });
  return returnData;
}
