export const CATEGORIES_FILTER = ['demographics', 'economics'];
export const SUBCATEGORIES_ECONOMICS_FILTER = ['inflation', 'investment', 'grdp', 'social economic status', 'land value'];

export const STEP3MULTIPLE = [];
export const STEP3SINGLE = ['land value', 'grdp', 'investment', 'inflation'];
export const HAS4STEP = [];
export const nationalLevelRegion = ['investment', 'inflation'];
export const provinceLevelRegion = ['grdp'];
export const cityLevelRegion = [];
export const districtLevelRegion = ['social economic status', 'land value'];
export const levelDataShouldVillage = ['social economic status','land value'];
export const excludeLandValue = ['min', 'max', 'mean'];
export const percentageValueLegend = ['inflation'];
export const generalCurrencyValueLegend = ['investment', 'land value'];
export const billionCurrencyValueLegend = ['grdp'];
