import * as _ from 'lodash';
import { produce } from 'immer';

/**
 * @description For dataLayer object
 * @typedef TRoleKeymapper
 * @type {Object}
 * @property {string} key
 * @property {boolean} isAllowed
 * @property {Array<TRoleKeymapper>} children
 */

/**
 * @description For mapping company role to key value type
 * @type {Array<TRoleKeymapper>} initialdraft
 */

let initialDraft = [
  {
    key: 'MAPANA',
    isAllowed: false,
    children: [
      {
        key: 'GRIANA',
        isAllowed: false,
        children: [
          {
            key: 'GENERATE_REPORT',
            isAllowed: true,
            children: [],
          },
          {
            key: 'GENERATE_REPORT_FULL',
            isAllowed: false,
            children: [],
          },
        ],
      },
      {
        key: 'SIPRO',
        isAllowed: false,
        children: [
          {
            key: 'GENERATE_REPORT',
            isAllowed: true,
            children: [],
          },
          {
            key: 'GENERATE_REPORT_FULL',
            isAllowed: false,
            children: [],
          },
        ],
      },
      {
        key: 'ASMANTELKOM',
        isAllowed: false,
        children: [],
      },
    ],
  },
  {
    key: 'GENSET',
    isAllowed: true, // default true
    children: [
      {
        key: 'POI',
        isAllowed: true, // default true
        children: [],
      },
      {
        key: 'PARAMETER',
        isAllowed: true, // default true
        children: [],
      },
    ],
  },
  {
    key: 'DATEX',
    isAllowed: false,
    children: [
      {
        key: 'THEMATIC',
        isAllowed: false,
        children: [],
      },
      {
        key: 'POI',
        isAllowed: false,
        children: [],
      },
      {
        key: 'TELCO',
        isAllowed: false,
        children: [],
      },
    ],
  },
  {
    key: 'RND',
    isAllowed: false,
    children: [
      {
        key: 'SIPRO-MANDALA',
        isAllowed: true, // default true
        children: [],
      },
      {
        key: 'GRIANA-LAWU',
        isAllowed: true, // default true
        children: [],
      },
      {
        key: 'POI-RINJANI',
        isAllowed: true, // default true
        children: [],
      },
      {
        key: 'THEMATIC-RINJANI',
        isAllowed: true, // default true
        children: [],
      },
    ],
  },
  {
    key: 'TELCO_DATA',
    isAllowed: false,
  },
  {
    key: 'THEMATIC_DATA',
    isAllowed: false,
  },
];

const defaultPermission = {
  superAdmin: 'true',
  map_analytic: {
    gridAnalysis: 'true',
    siteProfiling: 'true',
    analyticAccess: 'true',
    asmanTelkom: 'false',
    generalReportSipro: 'true',
    generalReportGriana: 'true',
  },
  data_explorer: {
    thematic: 'true',
    telcoData: 'true',
    pointOfInterest: 'true',
    dataExplorerAccess: 'true',
  },
  rnd_module: {
    gridAnalysisLuwu: 'true',
    pointOfInterestRinjani: 'true',
    rndModuleAccess: 'true',
    siteProfilingMandala: 'true',
    thematicRinjani: 'true',
  },
  telco_data: 'true',
};

// Utility function to find a draft item or provide a fallback
const findDraftItem = (draft, key) => _.find(draft, (v) => v.key === key) || {};

// Utility function to set permission
const setPermission = (item, condition, value) => {
  if (item && condition) {
    item.isAllowed = value;
  }
};

const checkerMAPANA = (role, draft) => {
  if (role?.map_analytic?.analyticAccess === 'true') {
    let draftMapana = findDraftItem(draft, 'MAPANA');
    draftMapana.isAllowed = true;

    // GRIANA CHECKER
    const draftGriana = findDraftItem(draftMapana.children, 'GRIANA');
    setPermission(draftGriana, role?.map_analytic?.gridAnalysis === 'true', true);

    if (draftGriana.isAllowed) {
      // GENERATE REPORT CHECKER
      const draftGrianaGenerateReport = findDraftItem(draftGriana.children, 'GENERATE_REPORT');
      setPermission(draftGrianaGenerateReport, role?.map_analytic?.generalReportGriana === 'false', false);

      // FULL DATA CHECKER
      const draftGrianaGenerateReportFull = findDraftItem(draftGriana.children, 'GENERATE_REPORT_FULL');
      setPermission(draftGrianaGenerateReportFull, role?.map_analytic?.fullDataGriana === 'true', true);
    }

    // SIPRO CHECKER
    const draftSipro = findDraftItem(draftMapana.children, 'SIPRO');
    setPermission(draftSipro, role?.map_analytic?.siteProfiling === 'true', true);

    if (draftSipro.isAllowed) {
      // GENERATE REPORT CHECKER
      const draftGenerateReport = findDraftItem(draftSipro.children, 'GENERATE_REPORT');
      setPermission(draftGenerateReport, role?.map_analytic?.generalReportSipro === 'false', false);

      // FULL DATA CHECKER
      const draftSiproGenerateReportFull = findDraftItem(draftSipro.children, 'GENERATE_REPORT_FULL');
      setPermission(draftSiproGenerateReportFull, role?.map_analytic?.fullDataSipro === 'true', true);
    }


    // SIPROMANDALA CHECKER
    if (
      role?.map_analytic?.siteProfilingMandala === 'true' &&
      process.env.MODE !== 'production'
    ) {
      let draftSiproMandala = findDraftItem(draftMapana.children, 'MANDALA');
      draftSiproMandala.isAllowed = true;
    }

    // GRIANALAWU CHECKER
    if (
      role?.map_analytic?.gridAnalysisLawu === 'true' &&
      process.env.MODE !== 'production'
    ) {
      let draftGrianaLawu = findDraftItem(draftMapana.children, 'LAWU');
      draftGrianaLawu.isAllowed = true;
    }

    // ASMANTELKOM CHECKER
    if (role?.map_analytic?.asmanTelkom === 'true') {
      let draftSipro = findDraftItem(draftMapana.children, 'ASMANTELKOM');
      draftSipro.isAllowed = true;
    }
  }
};

const checkerGENSET = (role, draft) => {
  if (role?.general_setup?.generalSetupAccess === 'true') {
    let draftGenset = _.find(draft, (v) => v.key === 'GENSET') || {};
    draftGenset.isAllowed = true;

    // PARAMETER CHECKER
    if (role?.general_setup?.manageParameter === 'true') {
      _.find(
        draftGenset.children,
        (v) => v.key === 'PARAMETER'
      ).isAllowed = true;
    }

    // POI CHECKER
    if (role?.general_setup?.customizePOI === 'true') {
      _.find(draftGenset.children, (v) => v.key === 'POI').isAllowed = true;
    }
  }
};

const checkerDATEX = (role, draft) => {
  if (role?.data_explorer?.dataExplorerAccess === 'true') {
    let draftDatex = _.find(draft, (v) => v.key === 'DATEX') || {};
    draftDatex.isAllowed = true;

    // TELCO CHECKER
    if (role?.data_explorer?.telcoData === 'true') {
      _.find(draftDatex.children, (v) => v.key === 'TELCO').isAllowed = true;
    }

    // POI CHECKER
    if (role?.data_explorer?.pointOfInterest === 'true') {
      _.find(draftDatex.children, (v) => v.key === 'POI').isAllowed = true;
    }

    // THEMATIC CHECKER
    if (role?.data_explorer?.thematic === 'true') {
      _.find(draftDatex.children, (v) => v.key === 'THEMATIC').isAllowed = true;
    }

    // POI RINJANI CHECKER
    if (
      role?.data_explorer?.pointOfInterestRinjani === 'true' &&
      process.env.MODE !== 'production'
    ) {
      _.find(
        draftDatex.children,
        (v) => v.key === 'POI_RINJANI'
      ).isAllowed = true;
    }

    // THEMATIC RINJANI CHECKER
    if (
      role?.data_explorer?.thematicRinjani === 'true' &&
      process.env.MODE !== 'production'
    ) {
      _.find(
        draftDatex.children,
        (v) => v.key === 'THEMATIC_RINJANI'
      ).isAllowed = true;
    }
  }
};

const checkerRND = (role, draft) => {
  if (role?.data_explorer?.dataExplorerAccess === 'true') {
    let draftRND = _.find(draft, (v) => v.key === 'RND') || {};
    draftRND.isAllowed = true;

    if (role?.rnd_module?.siteProfilingMandala === 'true') {
      _.find(
        draftRND.children,
        (v) => v.key === 'SIPRO-MANDALA'
      ).isAllowed = true;
    }

    if (role?.rnd_module?.gridAnalysisLuwu === 'true') {
      _.find(
        draftRND.children,
        (v) => v.key === 'GRIANA-LAWU'
      ).isAllowed = true;
    }

    if (role?.rnd_module?.pointOfInterestRinjani === 'true') {
      _.find(
        draftRND.children,
        (v) => v.key === 'POI-RINJANI'
      ).isAllowed = true;
    }

    if (role?.rnd_module?.thematicRinjani === 'true') {
      _.find(
        draftRND.children,
        (v) => v.key === 'THEMATIC-RINJANI'
      ).isAllowed = true;
    }
  }
};

const checkTelcoData = (role, draft) => {
  if (role?.telco_data === 'true') {
    let draftTelco = _.find(draft, (v) => v.key === 'TELCO_DATA') || {};
    draftTelco.isAllowed = true;
  }
};

const checkerThematicData = (role, draft) => {
  const category = role?.thematic_data?.category || [];
  if (category?.find((v) => Number(v.id) === 2)) {
    const subcategory = role?.thematic_data?.subcategory || [];
    const sesAccess = subcategory?.find((v) => Number(v.id) === 14);
    const draftThematic = _.find(draft, (v) => v.key === 'THEMATIC_DATA') || {};
    draftThematic.isAllowed = !!sesAccess;
  }
};

export const permissionMapper = (role = defaultPermission) => {
  return produce(initialDraft, (draft) => {
    // MAPANA CHECKER
    checkerMAPANA(role, draft);

    // GENSET CHECKER
    checkerGENSET(role, draft);

    // DATEX CHECKER
    checkerDATEX(role, draft);

    // DATA ACCESSIBILITY TELCO DATA
    checkTelcoData(role, draft);

    // DATA ACCESSIBILITY THEMATIC DATA
    checkerThematicData(role, draft);

    checkerRND(role, draft);

    return draft;
  });
};
