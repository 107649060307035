import { IconBaseMap } from '@bvt-assets/icon/icon-baseMap';
import { IconCross } from '@bvt-assets/icon/icon-cross';
import { IconFilterOne } from '@bvt-assets/icon/icon-filterOne';
import React from 'react';
import './MainMapRightSideTopBar.scss';

/**
 * @param {object} param
 * @param {'LAYERING_VISIBLITY'| 'BASEMAP'} param.activeMenu
 * @param {(e:'LAYERING_VISIBLITY'| 'BASEMAP')=>void} param.onChange
 * @param {()=>void} param.onClose
 */
export const MainMapRightSideTopBar = (param) => {
  const { onClose, activeMenu, onChange } = param;
  return (
    <div className='feature-mainmap MainMapRightSideTopBar'>
      <div className='MainMapRightSideTopBar__btn-group'>
        <button
          className={`MainMapRightSideTopBar__btn ${
            activeMenu === 'BASEMAP' && 'MainMapRightSideTopBar__btn--active'
          }`}
          onClick={() => onChange('BASEMAP')}
        >
          <IconBaseMap />
          <span>Base Map</span>
        </button>
        <button
          className={`MainMapRightSideTopBar__btn ${
            activeMenu === 'LAYERING_VISIBLITY' &&
            'MainMapRightSideTopBar__btn--active'
          }`}
          onClick={() => onChange('LAYERING_VISIBLITY')}
        >
          <IconFilterOne />
          <span>Layer Visibility</span>
        </button>
      </div>
      <button className='MainMapRightSideTopBar__btn-close' onClick={onClose}>
        <IconCross />
      </button>
    </div>
  );
};
