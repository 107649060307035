import { useDispatch, useSelector } from 'react-redux';
import { USER_ACTION } from '../redux/user';
export function useUser() {
  const state = useSelector((state) => state.features.ACCOUNT.USER);
  const dispatch = useDispatch();

  /**
   * @param {object} param object
   * @param {string} param.password pwd
   * @param {string} param.confirm pwd confirmation
   */
  const updatePassword = (param) => {
    dispatch(USER_ACTION.updatePassword(param));
  };

  const resetStatus = () => {
    dispatch(USER_ACTION.resetStatus());
  };

  return {
    state,
    resetStatus,
    updatePassword,
  };
}
