import { useProject } from '@bvt-features/mapp/project/hooks/useProject';
import { useStep } from '@bvt-features/mapp/step/hook';
import React, { useEffect } from 'react';

import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useMainMapMenuBar } from '../../hook/useMainMapMenuBar';
import './ProjectName.scss';

export default function ProjectName() {
  const navigate = useNavigate();
  const ctrl = useMainMapMenuBar();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('project_id');
  const processId = searchParams.get('process');
  const STEP = useStep();
  const PROJECT = useProject();

  let forgedParams = '?step=0';
  forgedParams += projectId ? '&project_id=' + projectId : '';
  forgedParams += processId ? '&process=' + processId : '';

  useEffect(() => {
    if (projectId) {
      ctrl.getProject(projectId);
    } else {
      ctrl.removeProjectName();
    }
  }, [projectId, PROJECT?.state?.detail]);

  const openProjectName = (forgedParams) => {
    STEP.setCurrentStep(0);
    navigate(forgedParams);
  };

  const boolTitle = ctrl?.state?.topTitle ? true : false;
  return (
    <div
      className='menu-bar__project-name'
      onClick={() => boolTitle && openProjectName(forgedParams)}
      style={{ cursor: boolTitle && 'pointer' }}
    >
      {boolTitle && (
        <>
          <span className='features'>
            {ctrl?.state?.detail?.project_type.split('-')[1]}
          </span>
          <span className='features'>/</span>
          <span className='title'>{ctrl?.state?.topTitle}</span>
        </>
      )}
    </div>
  );
}
