import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Malik <malik.ibrahim@bvarta.com>
 * @param {Object} props
 * @param {string} props.height
 * @param {string} props.width
 * @param {Object} props.style
 */

export const IconLightTheme = (props) => {
  const { height, width, style } = props;

  return (
    <svg
      height={height}
      style={{ ...style }}
      viewBox='0 0 18 18'
      width={width}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.452 7.834h-1.621a6.174 6.174 0 0 0-1.269-3.055l1.324-1.348A.783.783 0 0 0 13.8 2.35l-1.343 1.324A6.268 6.268 0 0 0 9.77 2.46a.548.548 0 0 0 0-.11V.783a.784.784 0 1 0-1.567 0V2.35a6.2 6.2 0 0 0-3.424 1.324L3.431 2.35A.783.783 0 0 0 2.35 3.431l1.324 1.348a6.174 6.174 0 0 0-1.269 3.055H.783a.784.784 0 0 0 0 1.567h1.622a6.174 6.174 0 0 0 1.269 3.055L2.35 13.8a.783.783 0 0 0 1.081 1.081l1.348-1.324a6.2 6.2 0 0 0 3.455 1.324v1.567a.784.784 0 1 0 1.567 0v-1.563a.548.548 0 0 0 0-.11 6.268 6.268 0 0 0 2.687-1.214l1.312 1.324a.783.783 0 0 0 1.085-1.085l-1.324-1.348a6.174 6.174 0 0 0 1.27-3.052h1.622a.784.784 0 1 0 0-1.567Zm-7.834 5.485a4.7 4.7 0 1 1 4.7-4.7 4.7 4.7 0 0 1-4.7 4.7Z'
        fill='#616477'
      />
    </svg>
  );
};

IconLightTheme.defaultProps = {
  height: 24,
  width: 24,
  style: {},
};

IconLightTheme.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  style: PropTypes.object,
};
