import { createAsyncThunk } from '@reduxjs/toolkit';
import { MainMapPoiSearch } from '../service';
import { poiSearchAction } from './poiSearch.slice';

const poiSearchService = new MainMapPoiSearch();

/**
 * Asynchronous actions for POI (Point of Interest) search.
 */
export const poiSearchAsyncAction = {
  getList: createAsyncThunk('LI/PLACE/POI-SEARCH/getList', async (payload, thunk) => {
    try {
      thunk.dispatch(poiSearchAction.removeAll());
      const data = await poiSearchService.getList(payload);
      thunk.dispatch(poiSearchAction.setAll(data?.result));
    } catch (error) {
      throw error.response?.data?.message || 'Failed get poi list';
    }
  }),
  resetStatusAndMessage: createAsyncThunk(
    'LI/PLACE/POI-SEARCH/resetStatusAndMessage',
    async (_, thunk) => {
      thunk.dispatch(
        poiSearchAction.setStatus({ status: ['LIST'], type: 'IDLE' })
      );
      thunk.dispatch(poiSearchAction.setMessage({ message: '' }));
    }
  ),
  removeAll: createAsyncThunk(
    'LI/PLACE/POI-SEARCH/removeAll',
    async (_, thunk) => {
      thunk.dispatch(poiSearchAction.removeAll());
      thunk.dispatch(poiSearchAction.setSelectedId({ id: null }));
      thunk.dispatch(poiSearchAction.setIsShowAll({ isShowAll: false }));
    }
  ),
};
