import DynamicIcon from '@bvt-icons';
import React, { useEffect, useRef, useState } from 'react';
import './LayoutSideBarMainApp.scss';
import { useLocation, useNavigate } from 'react-router';

/**
 * @version 1
 * @copyright Bhumi Varta Technology
 * @author Malik <malik.ibrahim@bvarta.com>
 * @param { object } props
 * @param { Array<{key?:string, label?:string, icon?:string }> } props.menuList
 * @param { string } props.activeMenu
 * @param { boolean=false } props.showVersion
 */

export function LayoutSideBarMainApp(props) {
  const { menuList, activeMenu, showVersion } = props;
  const [activeItem, setActiveItem] = useState(activeMenu);
  const navigate = useNavigate();
  const [elActivePositon, setElActivePositon] = useState(0);
  const menuRefs = useRef([]);
  /**
   *
   * @param {unknown} key
   * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} e
   */

  const location = useLocation();
  const handleMenuItemClicked = (key, e) => {
    setActiveItem(key);
    let path = `/app/main/${key}`;
    if (key === 'map') {
      path = `/app/maps`;
    } else if (key === 'account') {
      path = '/app/main/account/personal-details';
    }
    setElActivePositon(e);

    navigate({
      pathname: path,
      replace: true,
    });
  };

  const addMenuRef = (el) => {
    if (el && !menuRefs.current.includes(el)) {
      menuRefs.current.push(el);
    }
  };

  useEffect(() => {
    let arrPathname = location.pathname.split('/');
    let key = arrPathname[arrPathname.length - 1];
    let indexActiveMenu = 0;

    if (location.pathname.includes('/app/main/account/')) {
      setActiveItem('account');
      indexActiveMenu = menuList.findIndex((item) => item.key === 'account');
    } else {
      indexActiveMenu = menuList.findIndex((item) => item.key === key);
      setActiveItem(key);
    }

    setElActivePositon(menuRefs?.current[indexActiveMenu]?.offsetTop);
  }, [location]);

  return (
    <div className='LayoutSideBarMainApp'>
      <div className='LayoutSideBarMainApp__logo'>
        <DynamicIcon height='72px' icon='lilogo' width='126px' />
      </div>
      <div className='LayoutSideBarMainApp__container_menu_list'>
        <div className='LayoutSideBarMainApp__menu-list'>
          <div
            className='LayoutSideBarMainApp__active-indicator'
            style={{ top: elActivePositon }}
          />
          {menuList.map((item) => (
            <div
              className={
                item.key === activeItem
                  ? `LayoutSideBarMainApp__menu-nav-item LayoutSideBarMainApp__menu-nav-item-active`
                  : `LayoutSideBarMainApp__menu-nav-item`
              }
              key={item.key}
              onClick={(e) =>
                handleMenuItemClicked(item.key, e.currentTarget.offsetTop)
              }
              ref={addMenuRef}
            >
              <div className='LayoutSideBarMainApp__menu-nav-icon'>
                <img src={`/assets/bvt-ic-main-${item.icon}.svg`} />
              </div>
              <div className='LayoutSideBarMainApp__menu-nav-label'>
                {item.label}
              </div>
            </div>
          ))}
        </div>
        {showVersion && (
          <div className='LayoutSideBarMainApp__text-version'>
            Version {process.env.VERSION}
          </div>
        )}
      </div>
    </div>
  );
}
