import DynamicIcon from '@bvt-icons';
import { Divider } from 'antd';
import React from 'react';
import './AboutModal.scss';

export default function AboutModal() {
  return (
    <div className='menu-bar__about-modal'>
      <div className='menu-bar__about-modal--header'>
        <DynamicIcon
          className='menu-bar__about-modal--logo'
          height='4.5rem'
          icon='poiGridBg'
          width='4.5rem'
        />
        <DynamicIcon
          className='menu-bar__about-modal--logo'
          // height='4rem'
          icon='lilogo'
          width='12rem'
        />
      </div>

      <div className='menu-bar__about-modal--body'>
        <div className='menu-bar__about-modal--body--table'>
          <table>
            <tbody>
              <tr>
                <td>Version</td>
                <td>{process.env.VERSION}</td>
              </tr>
              <tr>
                <td>Copyright &copy;</td>
                <td>2018-2022</td>
              </tr>
              <tr>
                <td>by</td>
                <td>PT BHUMI VARTA TECHNOLOGY</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='menu-bar__about-modal--body--license'>
          <Divider />
          <div className='menu-bar__about-modal--body--license--about'>
            About license
          </div>
          <div className='menu-bar__about-modal--body--table'>
            <table>
              <tbody>
                <tr>
                  <td>Registered to</td>
                  <td>PT BHUMI VARTA TECHNOLOGY</td>
                </tr>
                <tr>
                  <td>License</td>
                  <td>Subscribe Version</td>
                </tr>
              </tbody>
            </table>
          </div>
          <Divider />
        </div>
      </div>
    </div>
  );
}
