export const GRIDPRO_STYLE = [
  {
    style: {
      selected: {
        fillColor: '#33CC66',
        color: '#33CC66',
        weight: 2,
        opacity: 0.7,
        fillOpacity: 0.7,
      },
      unselected: {
        fillColor: '#33CC66',
        color: '#FFFFFF',
        weight: 2,
        opacity: 0.4,
        fillOpacity: 0.4,
      },
    },
    gridStatus: 'Acceptable 1',
  },
  {
    style: {
      selected: {
        fillColor: '#FFEB99',
        color: '#FFEB99',
        weight: 2,
        opacity: 0.7,
        fillOpacity: 0.7,
      },
      unselected: {
        fillColor: '#FFEB99',
        color: '#FFFFFF',
        weight: 2,
        opacity: 0.4,
        fillOpacity: 0.4,
      },
    },
    gridStatus: 'Acceptable 2',
  },
  {
    style: {
      selected: {
        fillColor: '#FF9966',
        color: '#FF9966',
        weight: 2,
        opacity: 0.7,
        fillOpacity: 0.7,
      },
      unselected: {
        fillColor: '#FF9966',
        color: '#FFFFFF',
        weight: 2,
        opacity: 0.4,
        fillOpacity: 0.4,
      },
    },
    gridStatus: 'Acceptable 3',
  },
  {
    style: {
      selected: {
        fillColor: '#FF0066',
        color: '#FF0066',
        weight: 2,
        opacity: 0.7,
        fillOpacity: 0.7,
      },
      unselected: {
        fillColor: '#FF0066',
        color: '#FFFFFF',
        weight: 2,
        opacity: 0.4,
        fillOpacity: 0.4,
      },
    },
    gridStatus: 'Acceptable 4',
  },
  {
    style: {
      selected: {
        fillColor: '#657991',
        color: '#657991',
        weight: 2,
        opacity: 0.7,
        fillOpacity: 0.7,
      },
      unselected: {
        fillColor: '#657991',
        color: '#FFFFFF',
        weight: 2,
        opacity: 0.4,
        fillOpacity: 0.4,
      },
    },
    gridStatus: 'Unacceptable',
  },
];
