import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 1.1
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Faisal <ahmad.faisal@bvarta.com>
 * @param {object} props
 * @param {string} props.className
 */

export default function IconCloud(props) {
  const { className, onClick } = props;
  return (
    <div className={`icon-cloud ${className}`} onClick={onClick}>
      <svg
        height='14'
        id='Icon_upload'
        viewBox='0 0 20 14'
        width='20'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M16,6a4,4,0,0,1,0,8H11V9.418l1.121,1.121a1,1,0,1,0,1.414-1.414L10.759,6.348a1,1,0,0,0-1.517,0L6.464,9.125A1,1,0,0,0,7.879,10.54L9,9.418V14H5a5,5,0,0,1-.674-9.955A6,6,0,0,1,16,6Z'
          fill='#d7dae2'
          id='Path'
        />
      </svg>
    </div>
  );
}

IconCloud.defaultProps = {
  className: '',
  onClick: () => {},
};

IconCloud.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
