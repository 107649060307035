import React from 'react';
import './_imageBox.scss';
/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 * @author Malik <malik.ibrahim@bvarta.com>
 * @param {Object} props
 * @param {'svg' | 'png' | 'jpeg' | 'link'} props.type
 * @param {String} props.src
 * @param {String} props.className
 * @param {()=>void} props.onClick
 */

export const ImageBox = (props) => {
  const { className, src, type, onClick } = props;
  const customClass = `imageBox ${className}`;

  return (
    <img
      alt={src}
      className={customClass}
      onClick={onClick}
      src={type !== 'link' ? `/assets/bvt-${src}.${type}` : src}
    />
  );
};

ImageBox.defaultProps = {
  className: '',
  onClick: () => {},
  src: '',
  type: 'svg',
};
