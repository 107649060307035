import React, { useEffect } from 'react';
import { PopupCardTaskAnalyzeProcessStatus } from '@bvt-features/mapp/taskAnalyze/component/PopupCardTaskAnalyzeProcessStatus';
import { useMappTaskAnalyze } from '../../hook/useMappTaskAnalyze';
import moment from 'moment';
import { toTitleCase } from '@bvt-utils';
import { useNavigate, useLocation } from 'react-router';
import { message, notification } from 'antd';
import { useInterval } from 'ahooks';
import { useSound } from '@bvt-hooks';
import { useAuth } from '@bvt-features/auth/hooks';

export function PopupTaskAnalyzeProcessStatusContainer() {
  const taskAnalyze = useMappTaskAnalyze();
  const AUTH = useAuth();
  const SOUND = useSound(AUTH.state.data?.setting?.sound_url);
  const nav = useNavigate();
  const location = useLocation();
  const prevValueRef = React.useRef([]);

  useInterval(() => {
    taskAnalyze.getListTask({
      limit: 100,
    });
  }, 5000);

  useEffect(() => {
    if (
      taskAnalyze.state?.data[0]?.status !== prevValueRef.current[0]?.status &&
      prevValueRef.current?.length > 0 && location.pathname.includes('map')
    ) {
      let statusFromArray = taskAnalyze.state?.data[0]?.status;
      let nameFromArray = taskAnalyze.state?.data[0]?.project_name;
      let displayFromArray = taskAnalyze.state?.data[0]?.display_name;
      let forgeString = `feature name ${displayFromArray} and project name ${nameFromArray}`;
      SOUND.playSound();
      taskAnalyze.setPopupVisibility(true);
      if (statusMapper(statusFromArray) === 'PROCESING') {
        notification.info({
          message: 'New Proccessing List',
          description: forgeString,
          placement: 'bottomRight',
        });
      }
      if (statusMapper(statusFromArray) === 'FAILED') {
        notification.error({
          message: 'Proccessing Failed',
          description: forgeString,
          placement: 'bottomRight',
        });
      }
      if (statusMapper(statusFromArray) === 'SUCCESS') {
        notification.success({
          message: 'Proccessing Success',
          description: forgeString,
          placement: 'bottomRight',
        });
      }
    }
    prevValueRef.current = taskAnalyze.state?.data;
  }, [taskAnalyze.state.data]);

  useEffect(() => {
    if (taskAnalyze.state?.isPopUpVisible) {
      taskAnalyze.getListTask({
        limit: 100,
      });
    } else {
      taskAnalyze.resetStatus();
    }
  }, [taskAnalyze.state?.isPopUpVisible]);

  useEffect(() => {
    if (
      taskAnalyze.state.status_DELETE === 'SUCCESS' ||
      taskAnalyze.state.status_POST === 'SUCCESS'
    ) {
      taskAnalyze.getListTask({
        limit: 100,
      });
    }
    if (
      taskAnalyze.state.status_DELETE === 'FAILED' ||
      taskAnalyze.state.status_POST === 'FAILED'
    ) {
      message.error(taskAnalyze.state.message);
    }
    if (
      taskAnalyze.state.status_DELETE === 'SUCCESS' ||
      taskAnalyze.state.status_POST === 'SUCCESS'
    ) {
      message
        .loading('On processing')
        .then(() => message.success(taskAnalyze.state.message, 2.5));
    }
  }, [taskAnalyze.state.status_DELETE, taskAnalyze.state.status_POST]);

  useEffect(() => {
    return () => {
      taskAnalyze.resetAll();
    };
  }, []);

  const statusMapper = (status) => {
    switch (status) {
      case 'SUCCESS':
        return 'SUCCESS';
      case 'PROGRESS':
        return 'PROCESING';
      case 'FAILED':
        return 'FAILED';
      case 'CANCELED':
        return 'CANCELED';
      case 'PROCESING':
        return 'WAITING';
      case 'WAITING':
        return 'WAITING';
      case 'WARNING':
        return 'WARNING';
      default:
        return 'FAILED';
    }
  };

  const HandleClick = (v) => {
    const { original } = v || {};
    const { status, analyze_type, project_id, UUID } = original || {};

    const generateUrl = (basePath, step, uuid, projectId) => {
      let url = `/app/map/${basePath}?`;
      if (projectId) {
        url += `project_id=${projectId}`;
      }
      url += `&step=${step}`;
      if (uuid) {
        url += `&process=${uuid}`;
      }
      return url;
    };

    const navigateBasedOnAnalyzeType = (type) => {
      const mapPaths = {
        grid_analitic: 'map-analytic/grid-analysis',
        grid_analitic_v3: 'map-analytic/grid-analysis',
        grid_analitic_jpn: 'map-analytic/grid-analysis-lawu',
        grid_analitic_vn: 'map-analytic/grid-analysis-lawu',
        site_profiling: 'map-analytic/site-profiling',
        site_profiling_v3: 'map-analytic/site-profiling',
        site_profiling_int_jpn: 'map-analytic/site-profiling-mandala',
        site_profiling_int_vn: 'map-analytic/site-profiling-mandala',
      };

      const stepMapping = {
        grid_analitic: 6,
        grid_analitic_v3: 6,
        grid_analitic_jpn: 6,
        grid_analitic_vn: 6,
        site_profiling: 7,
        site_profiling_v3: 7,
        site_profiling_int_jpn: 7,
        site_profiling_int_vn: 7,
      };

      const basePath = mapPaths[type];
      const step = stepMapping[type];

      if (basePath && step !== undefined) {
        const urlString = generateUrl(basePath, step, UUID, project_id);
        nav(urlString);
      } else {
        message.error('Feature Not yet Developed');
      }
    };

    if (status === 'SUCCESS' || status === 'WARNING') {
      navigateBasedOnAnalyzeType(analyze_type);
    } else if (status === 'PROGRESS' || status === 'PROCESING') {
      taskAnalyze.cancelTask(UUID, analyze_type);
    } else {
      taskAnalyze.deleteTask(UUID);
    }

    taskAnalyze.setPopupVisibility(false);
  };


  return (
    <div style={{ display:'flex',height:'100%',padding:'0px' }}>
      <PopupCardTaskAnalyzeProcessStatus
        data={taskAnalyze.state?.data?.map((v) => ({
          id: v?.UUID,
          projectType: toTitleCase((v?.analyze_type || '').split('_').join(' ')),
          projectName: v?.project_name,
          elapsedTime: moment(v?.created_at).fromNow(),
          status: statusMapper(v?.status),
          original: { ...v },
        }))}
        isLoading={
          taskAnalyze.state.status_GET === 'LOADING' ||
            taskAnalyze.state.status_POST === 'LOADING' ||
            taskAnalyze.state.status_DELETE === 'LOADING'
        }
        onClick={(v) => HandleClick(v)}
        onTogglePop={() =>
          taskAnalyze.setPopupVisibility(!taskAnalyze.state?.isPopUpVisible)
        }
        visible={taskAnalyze.state?.isPopUpVisible}
      />
    </div>
  );
}
