import { MAINMAP_MAP_MENUBAR_CONSTANT } from './MAINMAP_MAP_MENUBAR_CONSTANT';
import { MainMapMenuBarService } from '../service';

const mainMapMenuBarService = new MainMapMenuBarService();

export const MAINMAP_MAP_MENUBAR_ACTION = {
  /**
   *
   * @param {string} param
   */
  setProjectName: function (param) {
    return (dispatch) => {
      dispatch({
        type: MAINMAP_MAP_MENUBAR_CONSTANT.setProjectName,
        payload: {
          topTitle: param,
        },
      });
    };
  },
  getDetail: (id) => {
    return async (dispatch) => {
      dispatch({
        type: MAINMAP_MAP_MENUBAR_CONSTANT.PROJECT_GET,
        payload: { status_GET: 'LOADING' },
      });
      try {
        const data = await mainMapMenuBarService.getDetail(id);
        dispatch({
          type: MAINMAP_MAP_MENUBAR_CONSTANT.PROJECT_GET,
          payload: {
            status_GET: 'SUCCESS',
            message: data.message,
            detail: data.result,
            topTitle: data.result?.name || '',
          },
        });
      } catch (error) {
        dispatch({
          type: MAINMAP_MAP_MENUBAR_CONSTANT.PROJECT_GET,
          payload: {
            status_GET: 'FAILED',
            message: error?.response?.data?.message,
            topTitle: '',
          },
        });
      }
    };
  },
  resetState: () => {
    return (dispatch) => {
      dispatch({
        type: MAINMAP_MAP_MENUBAR_CONSTANT.RESET_ALL,
      });
    };
  },
};
