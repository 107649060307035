import { useAuth } from '@bvt-features/auth/hooks';
import React, { useEffect, useState } from 'react';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import socket from '../../../../utils/socket';
import { ForceLogout } from '@bvt-features/auth/component';

/**
 *
 */
export default function AuthGateContainer() {
  const [isOpen, setOpen] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.state?.data?.user?.id) {
      socket.connect();
      socket.on('dual-login-detected-' + auth.state.data.user?.id, () => {
        setOpen(true);
        auth.doLogout();
      });
    }
    return () => {
      socket.disconnect();
    };
  }, [auth.state?.data?.user]);

  return (
    <>
      {auth.state.data.isAuthenticated &&
      auth.state.data.lokasiintelligenceAccess ? (
          <Outlet />
        ) : (
          <Navigate to={'/401'} />
        )}
      <ForceLogout onSubmit={() => navigate('/auth/logout')} open={isOpen} />
    </>
  );
}
