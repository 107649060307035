import DynamicIcon from '@bvt-icons';
import React from 'react';
/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 */

export default function EmptySubMenu() {
  return (
    <div className='module-bar__info'>
      <DynamicIcon
        className='module-bar__submenu__icon'
        height='1.063rem'
        icon='info'
        width='1.063rem'
      />
      <div className='module-bar__submenu__text info'>
        Please select one of the above modules based on your business needs
      </div>
    </div>
  );
}
