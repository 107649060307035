import { ButtonGroupDatex } from '@bvt-features/datex/component/ButtonGroupDatex';
import { DatexTelcoStepOnePoi } from '@bvt-features/datex/component/DatexTelcoStepOne/components/DatexTelcoStepOnePoi';
import { DatexTelcoStepOneTelco } from '@bvt-features/datex/component/DatexTelcoStepOne/components/DatexTelcoStepOneTelco';
import { DatexTelcoStepOneThematic } from '@bvt-features/datex/component/DatexTelcoStepOne/components/DatexTelcoStepOneThematic';
import { DatexTelcoStepOneThematicRinjani } from '@bvt-features/datex/component/DatexTelcoStepOne/components/DatexTelcoStepOneThematicRinjani';

import { BUTTON_GROUP_DATA } from '@bvt-features/datex/constant/BUTTON_GROUP_DATA';
import { CATCHMENT_TYPE } from '@bvt-features/datex/constant/CATCHMENT_TYPE';
import { POPULAR_TIME_LIST } from '@bvt-features/datex/constant/POPULAR_TIME_LIST';
import { TIME_TYPE } from '@bvt-features/datex/constant/TIME_TYPE';
import { usePlace } from '@bvt-features/mainmap/place/hooks/usePlace';
import IMAGE_UNDER_DEVELOPMENT from '@bvt-assets/images/UnderDevelopment.png';
import _, { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import './DatexTelcoStepOne.scss';
import { useAuth } from '@bvt-features/auth/hooks';
import { useDatex } from '@bvt-features/datex/hooks';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

/**
 * @description For data thematic list object
 * @typedef TThematicListObject
 * @property {number} key
 * @property {string} label
 * @property {any} icon
 */

/**
 * @description For data poi list object
 * @typedef TPoiListObject
 * @property {number} key
 * @property {string} label
 * @property {any} icon
 */

/**
 * @description For data object
 * @typedef TStepOneDataObjectThematic
 * @type {Object}
 * @property {Object} thematicCategory
 * @property {string|number} thematicCategory.key
 * @property {string} thematicCategory.value
 * @property {React.ReactElement} thematicCategory.icon
 */

/**
 * @description For data object
 * @typedef TStepOneDataObjectPoi
 * @type {Object}
 * @property {Object} poiGroup
 * @property {string|number} poiGroup.key
 * @property {string} poiGroup.value
 * @property {React.ReactElement} poiGroup.icon
 */

/**
 * @description For data object
 * @typedef TStepOneDataObjectTelco
 * @type {Object}
 * @property {number} province
 * @property {string} city
 * @property {'total'|'average'} catchmentType
 * @property {boolean} showCalendar
 * @property {Array} timeList
 * @property {'popular'|'specific'} timeType
 * @property {Array} dateList
 * @property {Number} year
 * @property {Number} month
 */

/**
 * @description For data object
 * @typedef TStepOneDataObject
 * @type {Object}
 * @property {'THEMATIC'|'POI'|'TELCO'|'YOUR_DATA'} type
 * @property {TStepOneDataObjectThematic|TStepOneDataObjectPoi|TStepOneDataObjectTelco} data
 */

/**
 * @typedef PayloadOnSelectBrands
 * @property {string} categoryId
 * @property {string[]} brandIds
 * @property {string[]} brandLabels
 * @property {string} groupName
 * @property {string} categoryIcon
 * @property {string} categoryName
 * @property {string} industryName
 */

/**
 * @param {object} props
 * @param {'THEMATIC'|'POI'|'TELCO'|'THEMATIC_RINJANI'|'YOUR_DATA'} props.activeKey
 * @param {TStepOneDataObjectThematic|TStepOneDataObjectPoi|TStepOneDataObjectTelco} props.data
 * @param {(data:TStepOneDataObject)=>void}props.onChange
 * @param {(isValid:boolean)=>void} props.onValidOrInvalid
 * @param {Array<TThematicListObject>} props.thematicList
 * @param {Array<TThematicListObject>} props.thematicListRinjani
 * @param {Array<TPoiListObject>} props.industryList
 * @param {Array<TPoiListObject>} props.industryListRinjani
 * @param {object} props.unavailableDate
 * @param {(key:Boolean)=>void} props.setShowPoiResult
 * @param {Boolean} props.showPoiResult
 * @param {(e: string) => void} props.onSearchBrand
 * @param {(payload: PayloadOnSelectBrands) => void} props.onSelectBrands
 * @param {boolean} props.isLoadingSearchBrand
 * @param {boolean} props.isLoadingThematicCategory
 */

export const DatexTelcoStepOne = (props) => {
  const place = usePlace();
  const AUTH = useAuth();
  const datex = useDatex();

  const {
    onChange,
    activeKey,
    onValidOrInvalid,
    data,
    industryList,
    industryListRinjani,
    thematicList,
    thematicListRinjani,
    unavailableDate,
    onSelectBrands,
    showPoiResult,
    setShowPoiResult,
    onSearchBrand,
    isLoadingSearchBrand,
    isLoadingThematicCategory,
  } = props;
  const [state, setState] = useState({
    type: activeKey,
    thematic: {
      thematicCategory: data?.thematicCategory || null,
    },
    poi: {
      poiGroup: data?.poiGroup,
    },
    telco: {
      province: null,
      city: null,
      cityId: null,
      dataType: null,
      year: null,
      month: null,
      day: null,
    },
  });

  useEffect(() => {
    setShowPoiResult(false);
  }, [state.type]);

  const internalHandleSearchThematic = (e) => {
    datex.getThematicCategoryList({ keyword: e });
  };

  const internalDebouncedSearchThematic = useCallback(
    debounce(internalHandleSearchThematic, 300),
    []
  );

  const internalHandleSearchThematicRinjani = (e) => {
    datex.getThematicCategoryListRinjani({ keyword: e,countryId:2 });
  };

  const internalDebouncedSearchThematicRinjani = useCallback(
    debounce(internalHandleSearchThematicRinjani, 300),
    []
  );

  const internalHandleSearchPOI = (e) => {
    if (e) {
      onSearchBrand(e);
      setShowPoiResult(true);
    } else {
      setShowPoiResult(false);
    }
  };

  const internalHandleSearchProvince = (e) => {
    const countryId = place.state.country_mandala.country_id;
    if(countryId === 1){
      const params = {
        meta: {
          filter: { keyword: e },
          page: 1,
          pageSize: 100,
        },
      };
      place.get_province({ params });
    }else{
      const params = {
        meta: {
          filter: { keyword: '',country_id:countryId },
        },
      };
      place.get_province_inter({ params });
    }
  };

  const internalHandleSearchCity = (e, province) => {
    const params = {
      meta: {
        filter: {
          keyword: e,
          id_province: province,
        },
        page: 1,
        pageSize: 100,
      },
    };
    place.get_city({ params });
  };

  useEffect(() => {
    internalHandleSearchProvince();
  }, [place.state.country_mandala]);

  useEffect(() => {
    setState({
      ...state,
      telco: {
        ...state.telco,
        province: data?.province,
        city: data?.city,
        cityId: data?.cityId,
        dataType: data?.dataType,
        year: data?.year,
        month: data?.month,
        day: data?.day,
      },
    });
  }, [data]);

  return (
    <div className='DatexTelcoStepOne'>
      <div className='DatexTelcoStepOne--wrapper'>
        <div className='DatexTelcoStepOne--buttonType'>
          <ButtonGroupDatex
            activeKey={state?.type}
            data={BUTTON_GROUP_DATA.filter(item => AUTH.hasAccess(item.permission)).map((item) => {
              if(item.permission === 'DATEX.TELCO' && !AUTH.hasAccess('TELCO_DATA')){
                item.disabled = true;
              }
              // eslint-disable-next-line consistent-return
              return item;
            }
            )}
            onChange={(e) => {
              setState({
                ...state,
                type: e?.key,
              });
              onChange &&
                onChange({
                  type: e?.key,
                  data: state?.[`${e?.key}`],
                });
            }}
          />
        </div>
        <div className='DatexTelcoStepOne--item'>
          {state.type === 'THEMATIC' && (
            <DatexTelcoStepOneThematic
              activeKey={state?.type}
              categoryList={thematicList}
              data={state?.thematic}
              isLoading={isLoadingThematicCategory}
              onChange={(e) => {
                setState({
                  ...state,
                  thematic: {
                    ...state.thematic,
                    ...e,
                  },
                });
                onChange &&
                  onChange({
                    type: state?.type,
                    data: { ...state?.thematic, ...e },
                  });
              }}
              onSearch={(e) => internalDebouncedSearchThematic(e)}
              onValidOrInvalid={(e) => onValidOrInvalid(e)}
            />
          )}
          {state.type === 'THEMATIC_RINJANI' && (
            <DatexTelcoStepOneThematicRinjani
              activeKey={state?.type}
              categoryList={thematicListRinjani}
              data={state?.thematic}
              isLoading={isLoadingThematicCategory}
              onChange={(e) => {
                setState({
                  ...state,
                  thematic: {
                    ...state.thematic,
                    ...e,
                  },
                });
                onChange &&
                  onChange({
                    type: state?.type,
                    data: { ...state?.thematic, ...e },
                  });
              }}
              onSearch={(e) => internalDebouncedSearchThematicRinjani(e)}
              onValidOrInvalid={(e) => onValidOrInvalid(e)}
            />
          )}
          {state.type === 'POI' && (
            <DatexTelcoStepOnePoi
              industryList={industryList}
              isLoadingSearch={isLoadingSearchBrand}
              isTyping={showPoiResult}
              onChange={(e) => {
                setState({
                  ...state,
                  poi: {
                    ...state.poi,
                    ...e,
                  },
                });
                onChange &&
                  onChange({
                    type: state?.type,
                    data: { ...state?.poi, ...e },
                  });
              }}
              onSearch={(e) => internalHandleSearchPOI(e)}
              onSelectBrands={(e) => {
                onSelectBrands && onSelectBrands(e);
              }}
              onValidOrInvalid={(e) => {
                onValidOrInvalid(e);
              }}
              searchResult={datex.mapperBrandsByCategory()}
              searchValue={state?.search}
            />
          )}
          {state.type === 'POI_RINJANI' && (
            <DatexTelcoStepOnePoi
              industryList={industryListRinjani}
              isLoadingSearch={isLoadingSearchBrand}
              isTyping={showPoiResult}
              onChange={(e) => {
                setState({
                  ...state,
                  poi: {
                    ...state.poi,
                    ...e,
                  },
                });
                onChange &&
                  onChange({
                    type: state?.type,
                    data: { ...state?.poi, ...e },
                  });
              }}
              onSearch={(e) => internalHandleSearchPOI(e)}
              onSelectBrands={(e) => {
                onSelectBrands && onSelectBrands(e);
              }}
              onValidOrInvalid={(e) => {
                onValidOrInvalid(e);
              }}
              searchResult={datex.mapperBrandsByCategory()}
              searchValue={state?.search}
            />
          )}
          {state.type === 'TELCO' && (
            <DatexTelcoStepOneTelco
              catchmentTypeList={CATCHMENT_TYPE}
              cityList={_.map(place.state?.city?.list, (v) => {
                return {
                  value: v?.kode_kota,
                  label: v?.nama_kota,
                  city_id: v?.id_kota,
                  disabled: v.status ? false: true,
                };
              })}
              data={state?.telco}
              loadingCity={place?.state?.city?.status_GET === 'LOADING'}
              loadingProvince={place?.state?.province?.status_GET === 'LOADING'}
              onChange={(e) => {
                setState({
                  ...state,
                  telco: {
                    ...state?.telco,
                    ...e,
                  },
                });
                onChange &&
                  onChange({
                    type: state?.type,
                    data: { ...state?.telco, ...e },
                  });
              }}
              onSearchCity={(e, id) => internalHandleSearchCity(e, id)}
              onSearchProvince={(e) => internalHandleSearchProvince(e)}
              onValidOrInvalid={(e) => onValidOrInvalid(e)}
              popularTimeList={POPULAR_TIME_LIST}
              provinceList={_.map(place.state?.province?.list, (v) => {
                return {
                  value: v?.id_provinsi,
                  label: v?.nama_provinsi,
                  disabled: v.status ? false: true,
                };
              })}
              timeTypeList={TIME_TYPE}
              unavailableDate={unavailableDate || []}
            />
          )}
        </div>
      </div>
      {!['THEMATIC', 'POI', 'TELCO', 'YOUR_DATA','THEMATIC_RINJANI','POI_RINJANI'].includes(state.type) && (
        <div className='DatexTelcoStepOne-container__no-selected'>
          <img
            alt='Under Development'
            className='DatexTelcoStepOne-container__no-selected__img'
            src={IMAGE_UNDER_DEVELOPMENT}
          />
          <span className='DatexTelcoStepOne-container__no-selected__title'>
            Select data
          </span>
          <span className='DatexTelcoStepOne-container__no-selected__desc'>
            Get started by selecting one of the four data types available to
            begin exploring.
          </span>
        </div>
      )}
    </div>
  );
};
