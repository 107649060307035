import Resource from '../../../../api/resource';

export class BrandService extends Resource {
  constructor() {
    super('brand', '/api', 'api-poi', 'v2');
  }
  /**
   *
   * parameter service
   * @param {object} params - Params for get list parameter
   * @param {object} params.meta - Meta for filter parameter
   * @param {object} params.meta.filter
   * @param {string} params.meta.filter.keyword
   * @param {string} params.meta.filter.user_id
   * @param {number} params.meta.page
   * @param {number} params.meta.pageSize
   * @param {object} params.meta.sort
   * @param {string} params.meta.sort.by
   * @param {string} params.meta.sort.name
   */

  getAll(_query) {
    const paramString = JSON.stringify(_query);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }
}
