import React from 'react';
import Proptypes from 'prop-types';
/**
 * @version 1.1
 * @copyright Bhumi Varta Technology
 * @memberof molecules
 * @author Randa <m.randa@bvarta.com>
 * @param {Object} props
 * @param {import('react').CSSProperties} props.style
 * @param {string} props.className
 * @param {object} props.handler
 * @param {Function} props.handler.onClick
 * @param {string} props.img
 * @param {boolean} props.isActive
 * @param {boolean} props.isDisabled
 * @param {string} props.text
 */
export default function SingleSelectedCard(props) {
  return (
    <div
      className={`SingleSelectedCard SingleSelectedCard__wrapper ${
        props.isActive && 'SingleSelectedCard__wrapper--active'
      } ${props.isDisabled && 'SingleSelectedCard__wrapper--disabled'} ${
        props.className
      }`}
      onClick={() => !props.isDisabled && props.handler.onClick()}
      style={{ ...props.style }}
    >
      <div className='SingleSelectedCard__image-container'>
        <img
          alt={props.text}
          className='SingleSelectedCard__image'
          src={props.img}
        />
      </div>
      <div className='SingleSelectedCard__bottom-container'>
        <div className='SingleSelectedCard__checkbox'>
          <div
            className='SingleSelectedCard__checkbox-item'
            style={{
              display: props.isActive ? 'block' : 'none',
            }}
          />
        </div>
        <div
          className={`SingleSelectedCard__text ${
            props.isDisabled && 'SingleSelectedCard__text--disabled'
          }`}
        >
          {props.text}
        </div>
      </div>
    </div>
  );
}
SingleSelectedCard.propTypes = {
  className: Proptypes.string,
  handler: Proptypes.shape({
    onClick: Proptypes.func,
  }),
  img: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
  isActive: Proptypes.bool,
  isDisabled: Proptypes.bool,
  style: Proptypes.object,
  text: Proptypes.oneOfType([Proptypes.element, Proptypes.string]),
};

SingleSelectedCard.defaultProps = {
  className: '-',
  handler: {
    onClick: () => {},
  },
  img: 'https://via.placeholder.com/150',
  isActive: false,
  isDisabled: false,
  style: {},
  text: 'Light mode',
};
