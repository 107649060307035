import React from 'react';
import { useAdvanceSearch } from '../../hook/useAdvanceSearch';
import { AdvanceSearchGeojsonComponent } from '../../component/AdvanceSearchGeojson.component';
import { useMainMap } from '@bvt-features/sub-module/mainmap/hook/useMainMap';

export const AdvanceSearchGeojsonContainer = () => {
  const mainmap = useMainMap();

  const {
    state: { data },
  } = useAdvanceSearch();

  return (
    <AdvanceSearchGeojsonComponent
      data={data.geojson}
      mapInstance={mainmap.mapInstance}
    />
  );
};
