import React from 'react';
import PropTypes from 'prop-types';
import './statusCountry.scss';
import { usePlace } from '@bvt-features/mainmap/place/hooks/usePlace';
/**
 *
 * @param {object} props
 * @param {()=>void} props.onClick
 * @param {boolean} props.visible
 * @param {boolean} props.isLoading
 */
export function StatusCountry(props) {
  const { onClick, visible,disable } = props;

  const PLACE = usePlace()

  return (
    <button className='task-country StatusButton' onClick={!disable && onClick}>
      <div className={`statusButton__text ${disable && "disable"}`}>{PLACE.state?.country_mandala?.country_name}</div>
      <svg
        className={`statusButton__drop-icon ${
          visible && 'statusButton__drop-icon--active'
        } `}
        fill='#fff'
        height='0.375rem'
        viewBox='0 0 11 6'
        width='0.75rem'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          color='#fff'
          d='M4.763.8A1,1,0,0,1,6.237.8l3.227,3.52A1,1,0,0,1,8.727,6H2.273a1,1,0,0,1-.737-1.676Z'
          transform='translate(11 6) rotate(180)'
        />
      </svg>
    </button>
  );
}
StatusCountry.propTypes = {
  onClick: PropTypes.func,
  visible: PropTypes.bool,
  disable: PropTypes.bool,
};
