// example geojson to long
/* eslint-disable */
export const PREVIEW_DATEX_AREA_DATA_CONSTANT = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.830699998207, -6.13690000043277],
              [106.830699998207, -6.1369999978498],
              [106.830699998207, -6.13709999976327],
              [106.830699998207, -6.1373000008925],
              [106.830699998207, -6.13749999932373],
              [106.830699998207, -6.13769999955353],
              [106.830699998207, -6.13789999798466],
              [106.830699998207, -6.13799999989818],
              [106.830699998207, -6.13809999731516],
              [106.830699998207, -6.13820000012808],
              [106.830699998207, -6.13830000204167],
              [106.830699998207, -6.13839999945858],
              [106.830699998207, -6.13850000137206],
              [106.830699998207, -6.13859999968837],
              [106.830699998207, -6.1387999990189],
              [106.830699998207, -6.1389000009324],
              [106.830699998207, -6.13910000026293],
              [106.830699998207, -6.13919999857919],
              [106.830699998207, -6.13929999869415],
              [106.830699998207, -6.13940000240636],
              [106.83080000102, -6.13949999982332],
              [106.83080000102, -6.13960000083751],
              [106.83080000102, -6.13969999915386],
              [106.830899998437, -6.13980000016805],
              [106.831000000351, -6.14000000039784],
              [106.831000000351, -6.14009999871417],
              [106.831099997768, -6.14029999804467],
              [106.831199999681, -6.14039999995816],
              [106.831399999911, -6.14059999928869],
              [106.831500000925, -6.14070000120232],
              [106.831600000141, -6.14079999951848],
              [106.831600000141, -6.14090000053271],
              [106.831700001155, -6.14090000053271],
              [106.831799998572, -6.14099999794971],
              [106.831900000486, -6.14099999794971],
              [106.831900000486, -6.1411000016619],
              [106.831999997903, -6.1411000016619],
              [106.832099999816, -6.14119999817951],
              [106.832199998132, -6.14119999817951],
              [106.832199998132, -6.14130000009315],
              [106.83240000106, -6.14130000009315],
              [106.832600000391, -6.14139999840941],
              [106.832700000506, -6.14139999840941],
              [106.832800000621, -6.14149999942353],
              [106.832899998937, -6.14149999942353],
              [106.83300000085, -6.14160000223652],
              [106.833099998267, -6.14160000223652],
              [106.833599999741, -6.14169999965344],
              [106.833900000985, -6.14180000156696],
              [106.834199998632, -6.1420000008975],
              [106.834399998862, -6.14209999921379],
              [106.834699999207, -6.142200000228],
              [106.834899999436, -6.14240000045777],
              [106.835200000681, -6.14260000068759],
              [106.835399999112, -6.14280000001807],
              [106.835600000241, -6.14299999934864],
              [106.835700002154, -6.14299999934864],
              [106.835700002154, -6.14310000036284],
              [106.835900001485, -6.14330000149198],
              [106.836100001715, -6.14359999734018],
              [106.836199999132, -6.14380000116731],
              [106.836399998462, -6.14400000139707],
              [106.836599997793, -6.1442999990439],
              [106.836599997793, -6.14440000095739],
              [106.836699999706, -6.14469999860423],
              [106.83680000162, -6.14510000086246],
              [106.83700000095, -6.14559999963834],
              [106.837099998367, -6.14600000189678],
              [106.83720000118, -6.14600000189678],
              [106.83720000118, -6.14579999806963],
              [106.83720000118, -6.14559999963834],
              [106.837099998367, -6.14539999850932],
              [106.83720000118, -6.14519999917876],
              [106.83720000118, -6.14479999871906],
              [106.837299998597, -6.14449999927367],
              [106.83740000141, -6.1442999990439],
              [106.837499998827, -6.14400000139707],
              [106.837699997258, -6.14370000015306],
              [106.83780000097, -6.14319999777984],
              [106.838100001315, -6.14260000068759],
              [106.838300000646, -6.14209999921379],
              [106.838399998962, -6.14180000156696],
              [106.838500000875, -6.14180000156696],
              [106.838799998522, -6.1420000008975],
              [106.838899999536, -6.1420000008975],
              [106.839200000781, -6.142200000228],
              [106.839299999097, -6.142200000228],
              [106.839499999327, -6.14229999854426],
              [106.839899997988, -6.14260000068759],
              [106.8400000008, -6.1427000017019],
              [106.840100001815, -6.1427000017019],
              [106.840399999461, -6.14290000103244],
              [106.840900000036, -6.14310000036284],
              [106.841099999366, -6.14319999777984],
              [106.841099999366, -6.14330000149198],
              [106.84140000151, -6.14339999890895],
              [106.841499998028, -6.14349999992317],
              [106.841999999501, -6.14380000116731],
              [106.842099997818, -6.14380000116731],
              [106.842399999062, -6.14400000139707],
              [106.842999998852, -6.1442999990439],
              [106.843199997283, -6.14449999927367],
              [106.84340000201, -6.14460000028792],
              [106.843800000671, -6.14479999871906],
              [106.8440000009, -6.14490000063266],
              [106.844099998317, -6.14490000063266],
              [106.844099998317, -6.1450000007476],
              [106.843800000671, -6.14549999952353],
              [106.843699997858, -6.14559999963834],
              [106.84340000201, -6.14589999998311],
              [106.843300000096, -6.14609999931366],
              [106.843199997283, -6.14620000212659],
              [106.843099999866, -6.14659999988826],
              [106.84340000201, -6.14689999933371],
              [106.843500000326, -6.14710000136215],
              [106.843500000326, -6.14719999967837],
              [106.84340000201, -6.14730000249125],
              [106.843300000096, -6.14739999900891],
              [106.843099999866, -6.14759999923879],
              [106.842900001435, -6.14790000138211],
              [106.842799998622, -6.14799999789974],
              [106.842599999292, -6.14820000082759],
              [106.842500000975, -6.14829999824448],
              [106.842399999062, -6.14840000105736],
              [106.842399999062, -6.14849999937363],
              [106.842199999731, -6.14900000084747],
              [106.842099997818, -6.14930000119233],
              [106.841999999501, -6.14939999950852],
              [106.841999999501, -6.1495000014221],
              [106.841899998487, -6.14959999883904],
              [106.841899998487, -6.14970000075259],
              [106.841899998487, -6.14979999906885],
              [106.84180000107, -6.14990000098246],
              [106.84180000107, -6.14999999839934],
              [106.841700000056, -6.15010000031295],
              [106.84160000084, -6.15019999772986],
              [106.84160000084, -6.15030000054277],
              [106.841499998028, -6.15040000065768],
              [106.841499998028, -6.15049999987328],
              [106.841299999596, -6.15060000088747],
              [106.841299999596, -6.15070000010306],
              [106.841899998487, -6.15119999977834],
              [106.842199999731, -6.15129999809463],
              [106.842199999731, -6.15140000000808],
              [106.842500000975, -6.15159999933861],
              [106.842700000306, -6.15170000125223],
              [106.842799998622, -6.15179999956847],
              [106.843099999866, -6.15199999889898],
              [106.843800000671, -6.15249999857425],
              [106.843899998087, -6.15260000138712],
              [106.8440000009, -6.15260000138712],
              [106.84460000069, -6.15300000094744],
              [106.844900000136, -6.15320000027791],
              [106.845100000366, -6.15340000050774],
              [106.845199999581, -6.15340000050774],
              [106.845199999581, -6.15349999792476],
              [106.845300000596, -6.15349999792476],
              [106.84540000071, -6.15349999792476],
              [106.845499999926, -6.15349999792476],
              [106.84560000094, -6.15349999792476],
              [106.845699999257, -6.15349999792476],
              [106.84580000117, -6.15349999792476],
              [106.845899999486, -6.15349999792476],
              [106.84620000073, -6.15340000050774],
              [106.846299999047, -6.15340000050774],
              [106.846400000061, -6.15340000050774],
              [106.846599998492, -6.15340000050774],
              [106.846799999621, -6.15329999859421],
              [106.847099999966, -6.15329999859421],
              [106.847300000196, -6.15320000027791],
              [106.848200002129, -6.15309999926372],
              [106.848299997748, -6.15309999926372],
              [106.848799999222, -6.15300000094744],
              [106.849100002264, -6.15300000094744],
              [106.849199997883, -6.15289999993317],
              [106.849399999012, -6.15289999993317],
              [106.849500000925, -6.15289999993317],
              [106.850100000715, -6.15280000161687],
              [106.85020000083, -6.15280000161687],
              [106.850300000046, -6.15280000161687],
              [106.850400000161, -6.15280000161687],
              [106.852699999207, -6.15239999935866],
              [106.853899999686, -6.1521999982295],
              [106.855200000281, -6.15199999889898],
              [106.855500001525, -6.15199999889898],
              [106.855999997603, -6.15190000058268],
              [106.856400002559, -6.15179999956847],
              [106.856499999976, -6.15179999956847],
              [106.85660000189, -6.15179999956847],
              [106.856699999307, -6.15179999956847],
              [106.85680000122, -6.15190000058268],
              [106.857000000551, -6.15199999889898],
              [106.85720000078, -6.15209999991318],
              [106.857500002024, -6.15230000014298],
              [106.857999999901, -6.15249999857425],
              [106.858500000476, -6.15280000161687],
              [106.85860000149, -6.15289999993317],
              [106.859200000381, -6.15320000027791],
              [106.859900001185, -6.15359999983825],
              [106.860100001415, -6.15370000175183],
              [106.860300000746, -6.15379999826941],
              [106.860399999062, -6.15379999826941],
              [106.86120000088, -6.15419999782973],
              [106.861799999771, -6.15459999918875],
              [106.861900002584, -6.15459999918875],
              [106.861900002584, -6.15470000020304],
              [106.862199999332, -6.15480000121726],
              [106.862199999332, -6.15489999953354],
              [106.862199999332, -6.15500000144706],
              [106.862399998662, -6.15500000144706],
              [106.862599998892, -6.15520000077757],
              [106.862700000805, -6.15520000077757],
              [106.86360000184, -6.15569999775483],
              [106.863699999257, -6.15580000056775],
              [106.863800000271, -6.15580000056775],
              [106.864100000616, -6.15599999989826],
              [106.864500001075, -6.1562000001281],
              [106.865099999067, -6.15650000047281],
              [106.865300000196, -6.15659999878914],
              [106.865400002109, -6.15669999980329],
              [106.865499999526, -6.15669999980329],
              [106.865499999526, -6.15680000081757],
              [106.865899999087, -6.15700000194663],
              [106.866900001135, -6.15749999982325],
              [106.867499999127, -6.15790000298084],
              [106.867699999357, -6.1580000003978],
              [106.86780000127, -6.1580000003978],
              [106.86780000127, -6.15810000141206],
              [106.868199999931, -6.15830000074255],
              [106.868900000736, -6.15859999838936],
              [106.86960000154, -6.15900000154701],
              [106.870199999531, -6.15929999919381],
              [106.870299998747, -6.15940000110731],
              [106.870400000661, -6.15949999852434],
              [106.871000000451, -6.15980000066758],
              [106.871599998442, -6.16010000191173],
              [106.871700000356, -6.16020000022792],
              [106.87180000137, -6.16020000022792],
              [106.872499998577, -6.16059999888897],
              [106.872699998807, -6.16079999911876],
              [106.873199999381, -6.16110000036285],
              [106.873599998043, -6.16130000059266],
              [106.873799998272, -6.16149999902391],
              [106.8740000021, -6.16149999902391],
              [106.874200000531, -6.16169999925371],
              [106.87440000166, -6.1618000020666],
              [106.874499998177, -6.16189999858423],
              [106.874699997508, -6.16200000049786],
              [106.874999999651, -6.16219999982825],
              [106.875500001125, -6.16250000107237],
              [106.875599999441, -6.16250000107237],
              [106.875799998772, -6.1627000013022],
              [106.875999998102, -6.16279999871909],
              [106.876299999347, -6.16299999984825],
              [106.876600000591, -6.16329999929369],
              [106.876699998907, -6.16340000210654],
              [106.87680000082, -6.16340000210654],
              [106.876899998237, -6.16350000042283],
              [106.877000000151, -6.16350000042283],
              [106.877200000381, -6.16350000042283],
              [106.877500001625, -6.16350000042283],
              [106.877899999386, -6.16340000210654],
              [106.878299998947, -6.16329999929369],
              [106.87860000109, -6.1631999991788],
              [106.879199999981, -6.16310000086249],
              [106.879700001455, -6.16289999973336],
              [106.879999999102, -6.16289999973336],
              [106.88080000092, -6.1627000013022],
              [106.881299998797, -6.16250000107237],
              [106.881400000711, -6.16250000107237],
              [106.881299998797, -6.1627000013022],
              [106.880899999237, -6.16329999929369],
              [106.880499999676, -6.16389999908387],
              [106.880100001015, -6.16459999988819],
              [106.879700001455, -6.16519999967843],
              [106.879199999981, -6.16580000126715],
              [106.878899998737, -6.16640000105724],
              [106.87880000132, -6.16670000050278],
              [106.878699999407, -6.1669000025312],
              [106.87860000109, -6.16699999904885],
              [106.878500000076, -6.16730000119221],
              [106.87840000176, -6.16750000142205],
              [106.878299998947, -6.16759999883907],
              [106.878299998947, -6.16780000176685],
              [106.878199998832, -6.16800000109741],
              [106.878099999616, -6.16820000132724],
              [106.878099999616, -6.16839999975839],
              [106.877999998602, -6.1684999989739],
              [106.877999998602, -6.16869999830435],
              [106.877700000955, -6.16979999866916],
              [106.877500001625, -6.17059999958847],
              [106.877399999711, -6.17080000071764],
              [106.877399999711, -6.17089999903387],
              [106.877099998467, -6.17179999826946],
              [106.876899998237, -6.17269999840435],
              [106.876699998907, -6.17350000112226],
              [106.876600000591, -6.17360000033792],
              [106.876600000591, -6.17390000068265],
              [106.876500000476, -6.17410000001317],
              [106.876500000476, -6.17419999832938],
              [106.876400000361, -6.17440000125716],
              [106.876400000361, -6.17449999957349],
              [106.876400000361, -6.17460000058773],
              [106.876299999347, -6.17469999980328],
              [106.876299999347, -6.17480000171681],
              [106.876299999347, -6.17489999913378],
              [106.876299999347, -6.17500000104733],
              [106.87620000103, -6.17509999846431],
              [106.87620000103, -6.17539999970836],
              [106.87620000103, -6.17550000162196],
              [106.876100000915, -6.17570000095244],
              [106.876100000915, -6.17590000118227],
              [106.876100000915, -6.17599999949855],
              [106.876100000915, -6.17619999882907],
              [106.876100000915, -6.17629999984331],
              [106.876100000915, -6.17650000097245],
              [106.875999998102, -6.17660000198668],
              [106.875999998102, -6.17669999940362],
              [106.875999998102, -6.17680000131724],
              [106.875999998102, -6.17700000154701],
              [106.875999998102, -6.17709999896397],
              [106.875999998102, -6.17729999829447],
              [106.875999998102, -6.17779999976838],
              [106.875899999786, -6.17830000034292],
              [106.875899999786, -6.17850000057272],
              [106.875899999786, -6.17880000181682],
              [106.875899999786, -6.17890000013301],
              [106.875899999786, -6.17900000114725],
              [106.875899999786, -6.17920000137716],
              [106.875799998772, -6.17980000116725],
              [106.875799998772, -6.17999999959839],
              [106.875799998772, -6.18010000151197],
              [106.875799998772, -6.18030000084253],
              [106.875799998772, -6.18050000107229],
              [106.875799998772, -6.18079999871913],
              [106.875799998772, -6.18140000030791],
              [106.875799998772, -6.18169999885402],
              [106.875799998772, -6.18189999818449],
              [106.875700000456, -6.18250000067263],
              [106.875700000456, -6.18299999854919],
              [106.875700000456, -6.18360000103744],
              [106.875599999441, -6.18399999969846],
              [106.875599999441, -6.18430000184177],
              [106.875599999441, -6.18439999925873],
              [106.875599999441, -6.18459999948855],
              [106.875599999441, -6.18470000050279],
              [106.875599999441, -6.18510000096246],
              [106.875599999441, -6.18560000153702],
              [106.875599999441, -6.18609999851426],
              [106.875500001125, -6.18670000100238],
              [106.875500001125, -6.18719999977831],
              [106.875500001125, -6.18769999945358],
              [106.875500001125, -6.18789999968336],
              [106.875500001125, -6.18809999901387],
              [106.875500001125, -6.18829999924367],
              [106.875500001125, -6.18890000263113],
              [106.875400000111, -6.18929999949349],
              [106.875400000111, -6.18969999995312],
              [106.875400000111, -6.18989999928371],
              [106.875400000111, -6.19020000232647],
              [106.875400000111, -6.1904000007576],
              [106.875400000111, -6.19060000098739],
              [106.875300000895, -6.19090000133217],
              [106.875300000895, -6.19110000246134],
              [106.875300000895, -6.19130000089249],
              [106.875199999881, -6.19170000045278],
              [106.875199999881, -6.19200000079753],
              [106.875100001565, -6.19230000024301],
              [106.874899998637, -6.19230000024301],
              [106.874699997508, -6.19220000102743],
              [106.874499998177, -6.19220000102743],
              [106.874200000531, -6.19220000102743],
              [106.8740000021, -6.19220000102743],
              [106.873799998272, -6.19210000001316],
              [106.873199999381, -6.19200000079753],
              [106.872999999152, -6.19200000079753],
              [106.872799998922, -6.19200000079753],
              [106.872600000491, -6.19200000079753],
              [106.872499998577, -6.19200000079753],
              [106.872400000261, -6.19200000079753],
              [106.872299998347, -6.19200000079753],
              [106.87180000137, -6.19200000079753],
              [106.870599999092, -6.19210000001316],
              [106.870199999531, -6.19210000001316],
              [106.8700000011, -6.19210000001316],
              [106.869499999626, -6.19220000102743],
              [106.868199999931, -6.19230000024301],
              [106.867999999701, -6.19230000024301],
              [106.867100001365, -6.19240000125718],
              [106.866399999661, -6.19249999777484],
              [106.865400002109, -6.19260000058775],
              [106.864900000636, -6.1926999989039],
              [106.864300001745, -6.1928000008175],
              [106.864199999831, -6.1928000008175],
              [106.864000000501, -6.19289999733522],
              [106.863899998587, -6.19289999733522],
              [106.863699999257, -6.19300000014805],
              [106.86360000184, -6.19300000014805],
              [106.863499999027, -6.19310000206156],
              [106.863399999811, -6.19310000206156],
              [106.863299999696, -6.19310000206156],
              [106.86320000138, -6.19319999947857],
              [106.862799998222, -6.19330000139206],
              [106.862500000576, -6.19350000072258],
              [106.862399998662, -6.19359999903893],
              [106.862199999332, -6.19370000005307],
              [106.862000000001, -6.19379999926867],
              [106.861799999771, -6.19390000028293],
              [106.86140000201, -6.19420000062765],
              [106.861299998297, -6.19420000062765],
              [106.86120000088, -6.19420000062765],
              [106.86100000155, -6.19440000175684],
              [106.860599998392, -6.19460000018796],
              [106.859499998028, -6.19539999930869],
              [106.859200000381, -6.19559999953853],
              [106.858399998562, -6.19619999932861],
              [106.858199998332, -6.19629999944359],
              [106.858100000915, -6.19629999944359],
              [106.858100000915, -6.19640000135705],
              [106.857999999901, -6.19640000135705],
              [106.857899999786, -6.1964999987741],
              [106.857799999671, -6.19660000158698],
              [106.857600000341, -6.1966999990039],
              [106.857500002024, -6.1966999990039],
              [106.857400000111, -6.19680000091736],
              [106.857299997298, -6.19689999923369],
              [106.857099999766, -6.19700000024792],
              [106.856899998637, -6.19709999856419],
              [106.856699999307, -6.19729999789475],
              [106.85660000189, -6.19740000070767],
              [106.856400002559, -6.19750000172178],
              [106.856199997833, -6.19759999913884],
              [106.856100000416, -6.19770000015308],
              [106.855999997603, -6.19779999936865],
              [106.855900001985, -6.19790000218152],
              [106.855699999956, -6.19799999779986],
              [106.855599998942, -6.19810000061267],
              [106.855500001525, -6.19810000061267],
              [106.855400000511, -6.19819999802967],
              [106.855300002194, -6.19830000084252],
              [106.855099998367, -6.19839999915876],
              [106.854899999037, -6.19850000017302],
              [106.854800000721, -6.19860000118731],
              [106.854600002289, -6.1986999995035],
              [106.854600002289, -6.19880000141712],
              [106.854499998577, -6.19880000141712],
              [106.85440000116, -6.19889999883402],
              [106.854299999247, -6.19900000074761],
              [106.854200002729, -6.19909999996318],
              [106.854099999916, -6.19920000097741],
              [106.853999997103, -6.19929999749505],
              [106.853899999686, -6.19939999940855],
              [106.852799999322, -6.2003000004428],
              [106.852299999646, -6.20080000281597],
              [106.851900000086, -6.20109999956339],
              [106.851799999072, -6.20120000057774],
              [106.851699999856, -6.20129999889396],
              [106.851500000526, -6.20140000080755],
              [106.851100001865, -6.2015999992387],
              [106.851100001865, -6.20169999935365],
              [106.851199999282, -6.20190000138209],
              [106.851199999282, -6.20199999969841],
              [106.851300001195, -6.20210000161186],
              [106.851399999511, -6.20230000094238],
              [106.851500000526, -6.20239999925868],
              [106.851599998842, -6.20250000027291],
              [106.851599998842, -6.20239999925868],
              [106.851699999856, -6.20239999925868],
              [106.851799999072, -6.20239999925868],
              [106.851900000086, -6.20239999925868],
              [106.852599999991, -6.20190000138209],
              [106.852699999207, -6.20190000138209],
              [106.852899998537, -6.20190000138209],
              [106.853199999781, -6.20199999969841],
              [106.853399999112, -6.20210000161186],
              [106.853500001925, -6.20219999902892],
              [106.854200002729, -6.20230000094238],
              [106.85440000116, -6.20230000094238],
              [106.854499998577, -6.20239999925868],
              [106.855000000051, -6.20239999925868],
              [106.855200000281, -6.20250000027291],
              [106.855300002194, -6.20259999858923],
              [106.855500001525, -6.20270000050274],
              [106.855599998942, -6.20309999916372],
              [106.855699999956, -6.20329999849428],
              [106.855699999956, -6.20340000220641],
              [106.855799999172, -6.20379999996818],
              [106.855799999172, -6.20419999952844],
              [106.855799999172, -6.20430000144214],
              [106.855799999172, -6.20439999885896],
              [106.855799999172, -6.2045000007726],
              [106.855699999956, -6.20470000100243],
              [106.855699999956, -6.20479999841928],
              [106.855400000511, -6.20529999989326],
              [106.855300002194, -6.20560000203656],
              [106.855200000281, -6.20569999945362],
              [106.855099998367, -6.2058999987841],
              [106.855000000051, -6.2058999987841],
              [106.854800000721, -6.20609999811459],
              [106.854499998577, -6.2063000019417],
              [106.853999997103, -6.20650000127218],
              [106.853899999686, -6.20659999868915],
              [106.853799998672, -6.20659999868915],
              [106.853700001255, -6.20659999868915],
              [106.853599999342, -6.20659999868915],
              [106.853500001925, -6.20650000127218],
              [106.853399999112, -6.20650000127218],
              [106.853300001695, -6.20639999935863],
              [106.853199999781, -6.20639999935863],
              [106.853199999781, -6.2063000019417],
              [106.853100001465, -6.20620000002814],
              [106.853000000451, -6.20609999811459],
              [106.852699999207, -6.2058999987841],
              [106.852599999991, -6.2058999987841],
              [106.852499999876, -6.2058999987841],
              [106.85240000156, -6.2058999987841],
              [106.852099999416, -6.2058999987841],
              [106.852000002899, -6.20599999979832],
              [106.851900000086, -6.20599999979832],
              [106.851599998842, -6.20599999979832],
              [106.851500000526, -6.20599999979832],
              [106.851199999282, -6.2058999987841],
              [106.850900000736, -6.20580000046784],
              [106.850800000621, -6.20569999945362],
              [106.850600000391, -6.20560000203656],
              [106.850400000161, -6.20560000203656],
              [106.850300000046, -6.20549999922379],
              [106.850100000715, -6.20560000203656],
              [106.850000000601, -6.20560000203656],
              [106.849900000486, -6.20569999945362],
              [106.849800002169, -6.20569999945362],
              [106.849800002169, -6.20580000046784],
              [106.849700000256, -6.20580000046784],
              [106.849700000256, -6.2058999987841],
              [106.849599998342, -6.20599999979832],
              [106.849500000925, -6.20609999811459],
              [106.849399999012, -6.2063000019417],
              [106.849300000695, -6.20669999970337],
              [106.849199997883, -6.20709999926372],
              [106.849100002264, -6.20730000039289],
              [106.849100002264, -6.20760000163689],
              [106.848999999451, -6.20760000163689],
              [106.848900001135, -6.20760000163689],
              [106.848799999222, -6.20760000163689],
              [106.848700001805, -6.20760000163689],
              [106.84860000079, -6.20769999905383],
              [106.848400000561, -6.20780000006805],
              [106.848299997748, -6.20789999928371],
              [106.848299997748, -6.20800000029793],
              [106.848200002129, -6.20800000029793],
              [106.848000001, -6.20820000052771],
              [106.847899999087, -6.20820000052771],
              [106.84780000167, -6.20829999704536],
              [106.847699999756, -6.20839999985827],
              [106.847399998512, -6.20850000267116],
              [106.847300000196, -6.20859999918879],
              [106.847099999966, -6.20859999918879],
              [106.846999998952, -6.20859999918879],
              [106.846900002434, -6.20870000200166],
              [106.846700001305, -6.20870000200166],
              [106.846599998492, -6.20870000200166],
              [106.846500002874, -6.20870000200166],
              [106.846299999047, -6.20859999918879],
              [106.84620000073, -6.20859999918879],
              [106.846099998817, -6.20859999918879],
              [106.845899999486, -6.20850000267116],
              [106.845699999257, -6.20839999985827],
              [106.845199999581, -6.20820000052771],
              [106.845100000366, -6.20820000052771],
              [106.844500001475, -6.20800000029793],
              [106.843800000671, -6.20769999905383],
              [106.84340000201, -6.20760000163689],
              [106.843199997283, -6.20749999972331],
              [106.843099999866, -6.20749999972331],
              [106.841899998487, -6.20699999824948],
              [106.84140000151, -6.20690000083256],
              [106.841099999366, -6.20679999981824],
              [106.840999998352, -6.20669999970337],
              [106.840500000476, -6.20650000127218],
              [106.840300000246, -6.20650000127218],
              [106.839899997988, -6.2063000019417],
              [106.839099999766, -6.20599999979832],
              [106.838700000206, -6.2058999987841],
              [106.838500000875, -6.20580000046784],
              [106.838399998962, -6.20580000046784],
              [106.838100001315, -6.20569999945362],
              [106.837699997258, -6.20549999922379],
              [106.837299998597, -6.20540000000821],
              [106.83720000118, -6.20540000000821],
              [106.837099998367, -6.20529999989326],
              [106.83700000095, -6.20529999989326],
              [106.836899999936, -6.20529999989326],
              [106.83680000162, -6.20529999989326],
              [106.836500000376, -6.20520000157694],
              [106.836100001715, -6.20520000157694],
              [106.835499998327, -6.20510000056273],
              [106.835000000451, -6.20510000056273],
              [106.834899999436, -6.20499999774973],
              [106.83460000179, -6.20499999774973],
              [106.834299999646, -6.20499999774973],
              [106.834000000201, -6.20490000033293],
              [106.833700000755, -6.20479999841928],
              [106.833500001425, -6.20479999841928],
              [106.832700000506, -6.20470000100243],
              [106.832099999816, -6.20459999908884],
              [106.831799998572, -6.20459999908884],
              [106.831600000141, -6.20459999908884],
              [106.831300001595, -6.2045000007726],
              [106.831099997768, -6.2045000007726],
              [106.83080000102, -6.2045000007726],
              [106.830499998877, -6.20439999885896],
              [106.829300001095, -6.20419999952844],
              [106.82840000096, -6.20400000019799],
              [106.828000002299, -6.20389999828438],
              [106.827799998472, -6.20379999996818],
              [106.827700000156, -6.20379999996818],
              [106.827399998912, -6.20369999805458],
              [106.826399999561, -6.20349999872408],
              [106.825399998412, -6.20329999849428],
              [106.824999998852, -6.20320000107735],
              [106.823899999387, -6.20289999983328],
              [106.823100000266, -6.20279999791974],
              [106.822700002504, -6.20270000050274],
              [106.822700002504, -6.20289999983328],
              [106.822599999691, -6.20320000107735],
              [106.822500000476, -6.20369999805458],
              [106.822500000476, -6.20410000121222],
              [106.822400000361, -6.20419999952844],
              [106.822400000361, -6.20430000144214],
              [106.822299998447, -6.20479999841928],
              [106.822299998447, -6.20549999922379],
              [106.822200000131, -6.2063000019417],
              [106.822099998217, -6.20669999970337],
              [106.822099998217, -6.20709999926372],
              [106.822099998217, -6.20760000163689],
              [106.8220000008, -6.20809999861412],
              [106.821799999671, -6.20859999918879],
              [106.821699999556, -6.20890000133218],
              [106.821699999556, -6.2089999987491],
              [106.821699999556, -6.20910000156201],
              [106.821699999556, -6.20919999807963],
              [106.82160000124, -6.20919999807963],
              [106.82160000124, -6.20939999830938],
              [106.821500000226, -6.20960000123723],
              [106.82140000191, -6.20969999955347],
              [106.82140000191, -6.20980000146707],
              [106.821299999097, -6.20989999888396],
              [106.821299999097, -6.21000000079754],
              [106.821199998982, -6.21009999911378],
              [106.821199998982, -6.21020000012806],
              [106.821199998982, -6.2102999984443],
              [106.821100000665, -6.2102999984443],
              [106.821100000665, -6.21040000125716],
              [106.821100000665, -6.2104999968755],
              [106.820799998522, -6.21099999924868],
              [106.820399999861, -6.21170000005313],
              [106.820000000301, -6.21249999917379],
              [106.820000000301, -6.21259999928868],
              [106.81980000187, -6.21290000143211],
              [106.81980000187, -6.21300000064765],
              [106.819699999057, -6.21300000064765],
              [106.81960000074, -6.21310000076257],
              [106.81960000074, -6.21319999907882],
              [106.819499999726, -6.21330000099241],
              [106.819499999726, -6.21339999840933],
              [106.81940000141, -6.21339999840933],
              [106.819300000396, -6.21350000032295],
              [106.819300000396, -6.21359999863915],
              [106.818999998252, -6.2138999989839],
              [106.818599998692, -6.21440000045779],
              [106.818500001275, -6.21449999877413],
              [106.818399999362, -6.21460000068763],
              [106.818300001045, -6.21469999900392],
              [106.817899997888, -6.21510000126215],
              [106.81700000135, -6.21579999846928],
              [106.816799998422, -6.21590000128217],
              [106.816199999531, -6.21640000185673],
              [106.815400000411, -6.21699999984827],
              [106.815200000181, -6.21720000007804],
              [106.814699999606, -6.2175999996384],
              [106.814099999816, -6.2181000011123],
              [106.813099998667, -6.21880000101735],
              [106.812399996963, -6.21939999990821],
              [106.812300000446, -6.21950000182176],
              [106.812199997633, -6.21959999833941],
              [106.811999999202, -6.21970000115232],
              [106.811799998073, -6.21989999958343],
              [106.811599999641, -6.21999999879903],
              [106.811200000081, -6.22040000195665],
              [106.811099999067, -6.22040000195665],
              [106.810699999506, -6.2206999996035],
              [106.810499998377, -6.220899998934],
              [106.809799999372, -6.22150000142202],
              [106.809099998567, -6.22199999929863],
              [106.808800000021, -6.22230000054277],
              [106.808600000691, -6.22239999885899],
              [106.807199999981, -6.22329999899389],
              [106.80700000065, -6.22340000090742],
              [106.806499999177, -6.22370000215155],
              [106.806100000516, -6.22399999979826],
              [106.805599999042, -6.22439999845929],
              [106.804999999252, -6.22480000161688],
              [106.804299999347, -6.2253000003928],
              [106.80380000147, -6.22569999815449],
              [106.803299999996, -6.22610000131215],
              [106.803000000551, -6.22630000244129],
              [106.802499999077, -6.22670000020305],
              [106.802099998617, -6.2270000023464],
              [106.801699999956, -6.22729999819452],
              [106.801100000166, -6.2278000005677],
              [106.800400000261, -6.22830000114224],
              [106.8000000007, -6.22859999788977],
              [106.799699999456, -6.22899999745011],
              [106.799499999227, -6.22920000217646],
              [106.799100001465, -6.22899999745011],
              [106.798999998652, -6.22890000003319],
              [106.798799999322, -6.22890000003319],
              [106.798599999092, -6.22879999901882],
              [106.798500001675, -6.22879999901882],
              [106.798399999761, -6.22879999901882],
              [106.798300001445, -6.22890000003319],
              [106.798099998517, -6.22899999745011],
              [106.797899999187, -6.22910000026297],
              [106.797600000641, -6.22930000049276],
              [106.797499999626, -6.22930000049276],
              [106.797400002209, -6.22930000049276],
              [106.796700000506, -6.22930000049276],
              [106.796399999262, -6.22930000049276],
              [106.795899997788, -6.22930000049276],
              [106.795800000371, -6.22920000217646],
              [106.795800000371, -6.22910000026297],
              [106.795800000371, -6.22899999745011],
              [106.795800000371, -6.22890000003319],
              [106.795899997788, -6.22879999901882],
              [106.795899997788, -6.22870000160194],
              [106.795899997788, -6.22859999788977],
              [106.795800000371, -6.22850000047275],
              [106.795699999357, -6.22850000047275],
              [106.795699999357, -6.22839999945853],
              [106.795699999357, -6.22830000114224],
              [106.795699999357, -6.228200000128],
              [106.795699999357, -6.22810000181181],
              [106.795800000371, -6.2279999998982],
              [106.795899997788, -6.2278000005677],
              [106.796100000715, -6.22729999819452],
              [106.796300001845, -6.2270000023464],
              [106.796399999262, -6.22670000020305],
              [106.796399999262, -6.22639999895894],
              [106.796399999262, -6.22619999962848],
              [106.796399999262, -6.22590000198168],
              [106.796399999262, -6.2255999998383],
              [106.796300001845, -6.22540000050772],
              [106.796300001845, -6.22500000184671],
              [106.796300001845, -6.22480000161688],
              [106.79620000083, -6.22450000037285],
              [106.79620000083, -6.22430000104241],
              [106.79620000083, -6.22410000081249],
              [106.796100000715, -6.22379999956848],
              [106.796100000715, -6.22349999742508],
              [106.796100000715, -6.22329999899389],
              [106.796100000715, -6.22309999876406],
              [106.79620000083, -6.2230000013471],
              [106.79620000083, -6.22289999943349],
              [106.79620000083, -6.22270000010307],
              [106.796300001845, -6.22230000054277],
              [106.796300001845, -6.22210000121221],
              [106.796300001845, -6.22190000188169],
              [106.796399999262, -6.22170000165189],
              [106.796399999262, -6.22150000142202],
              [106.796399999262, -6.22140000040779],
              [106.796500001175, -6.22120000017805],
              [106.796500001175, -6.22100000174684],
              [106.796500001175, -6.22080000151695],
              [106.796599999491, -6.22060000128717],
              [106.796599999491, -6.22040000195665],
              [106.796700000506, -6.22019999902887],
              [106.796700000506, -6.21999999879903],
              [106.796700000506, -6.21970000115232],
              [106.796799998822, -6.21950000182176],
              [106.796799998822, -6.21930000249128],
              [106.796799998822, -6.21909999866419],
              [106.796900000736, -6.21889999843431],
              [106.796900000736, -6.2186999991038],
              [106.796900000736, -6.21860000078759],
              [106.796900000736, -6.21849999977326],
              [106.796900000736, -6.21840000055763],
              [106.796900000736, -6.21819999762992],
              [106.796799998822, -6.21799999829941],
              [106.796799998822, -6.21779999896893],
              [106.796700000506, -6.2175999996384],
              [106.796599999491, -6.2175999996384],
              [106.796500001175, -6.21720000007804],
              [106.796399999262, -6.21709999816449],
              [106.796399999262, -6.21699999984827],
              [106.796100000715, -6.21649999837433],
              [106.796100000715, -6.21640000185673],
              [106.79620000083, -6.21629999994319],
              [106.79620000083, -6.21620000162695],
              [106.79620000083, -6.21609999971334],
              [106.796300001845, -6.21599999779981],
              [106.796300001845, -6.21590000128217],
              [106.796300001845, -6.21579999846928],
              [106.796300001845, -6.21570000105237],
              [106.796300001845, -6.21559999823948],
              [106.795999999701, -6.21500000024795],
              [106.795800000371, -6.21460000068763],
              [106.795800000371, -6.21449999877413],
              [106.795699999357, -6.21429999854431],
              [106.795600000141, -6.21420000202661],
              [106.795600000141, -6.21409999921375],
              [106.795499999127, -6.21400000179674],
              [106.79540000081, -6.2138999989839],
              [106.79540000081, -6.21379999796967],
              [106.79540000081, -6.21359999863915],
              [106.795300000695, -6.21350000032295],
              [106.79540000081, -6.21350000032295],
              [106.79540000081, -6.21339999840933],
              [106.79540000081, -6.21330000099241],
              [106.795499999127, -6.21330000099241],
              [106.795499999127, -6.21319999907882],
              [106.795499999127, -6.21310000076257],
              [106.795499999127, -6.21300000064765],
              [106.795499999127, -6.21290000143211],
              [106.795499999127, -6.21279999951855],
              [106.795600000141, -6.21279999951855],
              [106.795600000141, -6.2126999985043],
              [106.795699999357, -6.2126999985043],
              [106.795800000371, -6.2126999985043],
              [106.795800000371, -6.21259999928868],
              [106.795800000371, -6.21249999917379],
              [106.795800000371, -6.2124000008575],
              [106.795699999357, -6.2124000008575],
              [106.795699999357, -6.21229999894397],
              [106.795600000141, -6.21229999894397],
              [106.79540000081, -6.21229999894397],
              [106.79520000148, -6.21219999972839],
              [106.795099999566, -6.21210000051272],
              [106.794900000236, -6.21200000129716],
              [106.794799998322, -6.21200000129716],
              [106.794799998322, -6.21189999938366],
              [106.794700000006, -6.21189999938366],
              [106.794599998092, -6.21189999938366],
              [106.794500000676, -6.21180000196673],
              [106.794399997863, -6.21189999938366],
              [106.794300001345, -6.21189999938366],
              [106.794199998532, -6.21189999938366],
              [106.794100002015, -6.21189999938366],
              [106.794000001, -6.21180000196673],
              [106.794000001, -6.21170000005313],
              [106.793900000885, -6.21170000005313],
              [106.793799999871, -6.21170000005313],
              [106.793699998857, -6.21170000005313],
              [106.79360000144, -6.21170000005313],
              [106.793499998627, -6.21170000005313],
              [106.79340000121, -6.21170000005313],
              [106.79340000121, -6.21159999813957],
              [106.793499998627, -6.21159999813957],
              [106.79360000144, -6.21149999982324],
              [106.793699998857, -6.21149999982324],
              [106.793699998857, -6.21139999790965],
              [106.793799999871, -6.21139999790965],
              [106.793699998857, -6.21110000116221],
              [106.793699998857, -6.21099999924868],
              [106.793699998857, -6.21090000093244],
              [106.793699998857, -6.21080000081746],
              [106.79360000144, -6.21080000081746],
              [106.79360000144, -6.21070000160192],
              [106.793299999297, -6.21059999968838],
              [106.79320000098, -6.2104999968755],
              [106.793099999067, -6.2104999968755],
              [106.79280000142, -6.2102999984443],
              [106.792699999506, -6.2102999984443],
              [106.792699999506, -6.21020000012806],
              [106.792699999506, -6.21009999911378],
              [106.792699999506, -6.21000000079754],
              [106.79280000142, -6.21000000079754],
              [106.792699999506, -6.20989999888396],
              [106.792699999506, -6.20980000146707],
              [106.792699999506, -6.20969999955347],
              [106.792699999506, -6.20960000123723],
              [106.792599998492, -6.20949999932365],
              [106.792599998492, -6.20939999830938],
              [106.792599998492, -6.20929999999312],
              [106.792499999277, -6.20929999999312],
              [106.792499999277, -6.20919999807963],
              [106.792400000061, -6.20919999807963],
              [106.792400000061, -6.20910000156201],
              [106.792199998033, -6.20910000156201],
              [106.792100001515, -6.2089999987491],
              [106.791999999601, -6.2089999987491],
              [106.791900002184, -6.20890000133218],
              [106.791900002184, -6.20879999851926],
              [106.791900002184, -6.20870000200166],
              [106.791999999601, -6.20850000267116],
              [106.791999999601, -6.20839999985827],
              [106.792100001515, -6.20829999704536],
              [106.792100001515, -6.20820000052771],
              [106.792199998033, -6.20820000052771],
              [106.792199998033, -6.20809999861412],
              [106.792300001745, -6.20809999861412],
              [106.792300001745, -6.20800000029793],
              [106.792400000061, -6.20789999928371],
              [106.792400000061, -6.20780000006805],
              [106.792499999277, -6.20780000006805],
              [106.792599998492, -6.20780000006805],
              [106.794000001, -6.20769999905383],
              [106.794100002015, -6.20769999905383],
              [106.795300000695, -6.20760000163689],
              [106.79540000081, -6.20760000163689],
              [106.795499999127, -6.20760000163689],
              [106.795600000141, -6.20760000163689],
              [106.795899997788, -6.20760000163689],
              [106.795999999701, -6.20760000163689],
              [106.796100000715, -6.20760000163689],
              [106.796100000715, -6.20749999972331],
              [106.79620000083, -6.20749999972331],
              [106.79620000083, -6.20740000230642],
              [106.79620000083, -6.20690000083256],
              [106.796300001845, -6.20679999981824],
              [106.796300001845, -6.20659999868915],
              [106.796300001845, -6.20650000127218],
              [106.796399999262, -6.2063000019417],
              [106.796399999262, -6.20620000002814],
              [106.796399999262, -6.20609999811459],
              [106.796599999491, -6.20569999945362],
              [106.796599999491, -6.20560000203656],
              [106.796799998822, -6.20510000056273],
              [106.796799998822, -6.20499999774973],
              [106.796799998822, -6.20490000033293],
              [106.796900000736, -6.20470000100243],
              [106.796999998152, -6.2045000007726],
              [106.797200001979, -6.20400000019799],
              [106.797299998497, -6.20389999828438],
              [106.797299998497, -6.20379999996818],
              [106.797499999626, -6.20360000063756],
              [106.797499999626, -6.20340000220641],
              [106.797699998957, -6.20329999849428],
              [106.797699998957, -6.20320000107735],
              [106.797799999971, -6.20309999916372],
              [106.798000000201, -6.20289999983328],
              [106.798099998517, -6.20279999791974],
              [106.798300001445, -6.20259999858923],
              [106.798300001445, -6.20250000027291],
              [106.798500001675, -6.20239999925868],
              [106.798599999092, -6.20230000094238],
              [106.798700001005, -6.20230000094238],
              [106.798700001005, -6.20219999902892],
              [106.798799999322, -6.20219999902892],
              [106.798900000336, -6.20219999902892],
              [106.798999998652, -6.20210000161186],
              [106.799100001465, -6.20210000161186],
              [106.799199997983, -6.20199999969841],
              [106.799299999896, -6.20199999969841],
              [106.799299999896, -6.20190000138209],
              [106.79940000181, -6.20180000036784],
              [106.79940000181, -6.20169999935365],
              [106.79940000181, -6.2015999992387],
              [106.79940000181, -6.20149999822446],
              [106.799499999227, -6.20140000080755],
              [106.799499999227, -6.20129999889396],
              [106.799699999456, -6.20109999956339],
              [106.79980000137, -6.20089999933362],
              [106.79980000137, -6.20080000281597],
              [106.79980000137, -6.20070000000312],
              [106.799899997888, -6.20059999808962],
              [106.8000000007, -6.20050000067256],
              [106.800099999017, -6.2003999987591],
              [106.800200000031, -6.2003000004428],
              [106.800299998347, -6.20019999852925],
              [106.800400000261, -6.20019999852925],
              [106.800500002174, -6.20010000111228],
              [106.800700001505, -6.20000000009817],
              [106.800900000835, -6.19989999998316],
              [106.801300001295, -6.19970000155201],
              [106.801499999726, -6.19960000053775],
              [106.801699999956, -6.19960000053775],
              [106.8020000012, -6.19939999940855],
              [106.802099998617, -6.19939999940855],
              [106.802200000531, -6.19929999749505],
              [106.80240000076, -6.19920000097741],
              [106.802499999077, -6.19920000097741],
              [106.802600000091, -6.19909999996318],
              [106.802700002904, -6.19909999996318],
              [106.802799998522, -6.19900000074761],
              [106.802900000436, -6.19889999883402],
              [106.803000000551, -6.19889999883402],
              [106.803000000551, -6.19880000141712],
              [106.803100000666, -6.1986999995035],
              [106.803199998982, -6.19860000118731],
              [106.803199998982, -6.19850000017302],
              [106.803299999996, -6.19830000084252],
              [106.803399998312, -6.19819999802967],
              [106.803399998312, -6.19810000061267],
              [106.803500000226, -6.19799999779986],
              [106.803500000226, -6.19790000218152],
              [106.803599999441, -6.19790000218152],
              [106.803599999441, -6.19779999936865],
              [106.803700000456, -6.19779999936865],
              [106.80380000147, -6.19770000015308],
              [106.803899999786, -6.19770000015308],
              [106.8040000017, -6.19759999913884],
              [106.804099999117, -6.19759999913884],
              [106.804099999117, -6.19750000172178],
              [106.80420000193, -6.19750000172178],
              [106.804299999347, -6.19740000070767],
              [106.804299999347, -6.19729999789475],
              [106.80440000126, -6.19709999856419],
              [106.804499997778, -6.19689999923369],
              [106.804599999691, -6.19680000091736],
              [106.804599999691, -6.1966999990039],
              [106.804699998008, -6.19660000158698],
              [106.804699998008, -6.1964999987741],
              [106.804799999921, -6.1964999987741],
              [106.804799999921, -6.19640000135705],
              [106.804900001835, -6.19629999944359],
              [106.804900001835, -6.19619999932861],
              [106.804999999252, -6.19610000011302],
              [106.804999999252, -6.19589999988323],
              [106.804999999252, -6.19579999886901],
              [106.805100001165, -6.19550000212147],
              [106.805199999481, -6.19539999930869],
              [106.805199999481, -6.1953000018917],
              [106.805199999481, -6.19519999997815],
              [106.805199999481, -6.19509999806458],
              [106.805199999481, -6.19500000064763],
              [106.805300002294, -6.19469999850429],
              [106.805300002294, -6.19450000007312],
              [106.805300002294, -6.19440000175684],
              [106.805300002294, -6.19420000062765],
              [106.805300002294, -6.19409999961342],
              [106.805399998812, -6.19390000028293],
              [106.805399998812, -6.19370000005307],
              [106.805399998812, -6.19359999903893],
              [106.805399998812, -6.19350000072258],
              [106.805399998812, -6.19339999970837],
              [106.805399998812, -6.19330000139206],
              [106.805500000725, -6.19319999947857],
              [106.805500000725, -6.19310000206156],
              [106.805500000725, -6.19289999733522],
              [106.805500000725, -6.1928000008175],
              [106.805500000725, -6.1926999989039],
              [106.805599999042, -6.19260000058775],
              [106.805599999042, -6.19249999777484],
              [106.805700000056, -6.19240000125718],
              [106.805700000056, -6.19230000024301],
              [106.805700000056, -6.19220000102743],
              [106.805799998372, -6.19210000001316],
              [106.805799998372, -6.19200000079753],
              [106.805900000286, -6.19190000068265],
              [106.805900000286, -6.19179999786979],
              [106.8060000013, -6.19159999853926],
              [106.806100000516, -6.19150000022298],
              [106.806100000516, -6.19139999920879],
              [106.806100000516, -6.19130000089249],
              [106.806200000631, -6.19119999807959],
              [106.806299998947, -6.19110000246134],
              [106.806299998947, -6.19090000133217],
              [106.80640000086, -6.1907999994186],
              [106.80640000086, -6.19069999840442],
              [106.80640000086, -6.19060000098739],
              [106.80660000199, -6.1895999980396],
              [106.806699997608, -6.18919999937858],
              [106.806800000421, -6.189100000163],
              [106.806800000421, -6.18899999914872],
              [106.806899998737, -6.18899999914872],
              [106.80700000065, -6.18890000263113],
              [106.807100000765, -6.18890000263113],
              [106.807199999981, -6.18890000263113],
              [106.807999998202, -6.18879999981821],
              [106.808100000116, -6.18879999981821],
              [106.808699999906, -6.18879999981821],
              [106.809099998567, -6.18870000150203],
              [106.809300001495, -6.18870000150203],
              [106.809399999811, -6.18870000150203],
              [106.809500002624, -6.18870000150203],
              [106.809599999142, -6.18870000150203],
              [106.809700001055, -6.18870000150203],
              [106.809799999372, -6.18870000150203],
              [106.809900000386, -6.18879999981821],
              [106.810099999716, -6.18879999981821],
              [106.810299999946, -6.18890000263113],
              [106.81060000119, -6.18890000263113],
              [106.810699999506, -6.18879999981821],
              [106.810699999506, -6.18870000150203],
              [106.810699999506, -6.18859999868916],
              [106.810699999506, -6.18849999947359],
              [106.81060000119, -6.18800000069756],
              [106.81060000119, -6.18739999731019],
              [106.810499998377, -6.18729999989326],
              [106.810499998377, -6.18719999977831],
              [106.810499998377, -6.18670000100238],
              [106.810499998377, -6.18639999885902],
              [106.810400002759, -6.18589999918377],
              [106.810400002759, -6.18569999805469],
              [106.810400002759, -6.18540000220652],
              [106.810400002759, -6.18530000029294],
              [106.810400002759, -6.18519999748005],
              [106.810199998033, -6.18349999822451],
              [106.810199998033, -6.18340000170688],
              [106.810199998033, -6.18329999979333],
              [106.810199998033, -6.18270000090254],
              [106.810099999716, -6.18259999898887],
              [106.810099999716, -6.18230000044279],
              [106.810099999716, -6.18200000009809],
              [106.810099999716, -6.18189999818449],
              [106.810099999716, -6.18169999885402],
              [106.810099999716, -6.18129999929363],
              [106.809999998702, -6.18050000107229],
              [106.809999998702, -6.18039999915869],
              [106.809900000386, -6.18010000151197],
              [106.809900000386, -6.17990000218144],
              [106.809799999372, -6.17969999745509],
              [106.809700001055, -6.17940000160693],
              [106.809599999142, -6.17929999879406],
              [106.809599999142, -6.17920000137716],
              [106.809500002624, -6.17909999856427],
              [106.808699999906, -6.17799999819948],
              [106.808699999906, -6.17790000168189],
              [106.807999998202, -6.17700000154701],
              [106.807599998642, -6.17639999905887],
              [106.807399999312, -6.17619999882907],
              [106.806899998737, -6.17550000162196],
              [106.806299998947, -6.17460000058773],
              [106.805900000286, -6.17399999809957],
              [106.805500000725, -6.17350000112226],
              [106.804799999921, -6.17240000165694],
              [106.804499997778, -6.17199999849934],
              [106.80420000193, -6.1715999989389],
              [106.803700000456, -6.17089999903387],
              [106.803700000456, -6.17080000071764],
              [106.803100000666, -6.16989999968342],
              [106.802499999077, -6.16899999954858],
              [106.80240000076, -6.16899999954858],
              [106.802299998847, -6.16880000021806],
              [106.801499999726, -6.16759999883907],
              [106.800800000721, -6.16670000050278],
              [106.800500002174, -6.16620000082749],
              [106.800400000261, -6.16600000149696],
              [106.800099999017, -6.16549999912376],
              [106.8000000007, -6.16530000069261],
              [106.79980000137, -6.16499999854923],
              [106.799600002039, -6.16470000270106],
              [106.799499999227, -6.16450000157195],
              [106.799199997983, -6.16389999908387],
              [106.798999998652, -6.1631999991788],
              [106.798900000336, -6.16299999984825],
              [106.798799999322, -6.16289999973336],
              [106.798199999531, -6.16140000160689],
              [106.799699999456, -6.16070000080247],
              [106.8000000007, -6.16059999888897],
              [106.800200000031, -6.16050000147203],
              [106.800400000261, -6.16039999955848],
              [106.800600000491, -6.16020000022792],
              [106.800800000721, -6.1599999999981],
              [106.801000000051, -6.15969999875412],
              [106.801100000166, -6.1596000013371],
              [106.801199997583, -6.15940000110731],
              [106.801199997583, -6.15920000267613],
              [106.801300001295, -6.15900000154701],
              [106.80160000074, -6.15900000154701],
              [106.80160000074, -6.15929999919381],
              [106.80160000074, -6.15969999875412],
              [106.80160000074, -6.16010000191173],
              [106.80180000097, -6.16010000191173],
              [106.8020000012, -6.16010000191173],
              [106.802099998617, -6.16030000124215],
              [106.802099998617, -6.16039999955848],
              [106.802200000531, -6.16050000147203],
              [106.802299998847, -6.16059999888897],
              [106.802299998847, -6.16070000080247],
              [106.802499999077, -6.16090000013302],
              [106.802799998522, -6.1612000013771],
              [106.802900000436, -6.16130000059266],
              [106.803100000666, -6.16140000160689],
              [106.803199998982, -6.16149999902391],
              [106.803299999996, -6.16149999902391],
              [106.803399998312, -6.16160000093741],
              [106.803599999441, -6.16160000093741],
              [106.80380000147, -6.16169999925371],
              [106.803899999786, -6.16169999925371],
              [106.804099999117, -6.1618000020666],
              [106.804299999347, -6.1618000020666],
              [106.804499997778, -6.1618000020666],
              [106.804599999691, -6.1618000020666],
              [106.804699998008, -6.1618000020666],
              [106.804799999921, -6.1618000020666],
              [106.804999999252, -6.16169999925371],
              [106.805199999481, -6.16169999925371],
              [106.805500000725, -6.16160000093741],
              [106.805900000286, -6.16160000093741],
              [106.8060000013, -6.16160000093741],
              [106.806200000631, -6.16160000093741],
              [106.80640000086, -6.16160000093741],
              [106.807199999981, -6.16140000160689],
              [106.807500001225, -6.16140000160689],
              [106.807799999771, -6.16140000160689],
              [106.807899999886, -6.16130000059266],
              [106.807999998202, -6.16130000059266],
              [106.808100000116, -6.16140000160689],
              [106.80820000203, -6.16140000160689],
              [106.808400000461, -6.16130000059266],
              [106.808600000691, -6.16130000059266],
              [106.808899998337, -6.16130000059266],
              [106.809199999581, -6.1612000013771],
              [106.809399999811, -6.1612000013771],
              [106.809700001055, -6.16110000036285],
              [106.809799999372, -6.16110000036285],
              [106.809999998702, -6.16110000036285],
              [106.810099999716, -6.16110000036285],
              [106.810099999716, -6.16099999844928],
              [106.810199998033, -6.16099999844928],
              [106.810499998377, -6.16090000013302],
              [106.81060000119, -6.16090000013302],
              [106.810699999506, -6.16079999911876],
              [106.810800002319, -6.16079999911876],
              [106.81100000075, -6.16079999911876],
              [106.811599999641, -6.16070000080247],
              [106.811700001555, -6.16070000080247],
              [106.811799998073, -6.16070000080247],
              [106.811900000885, -6.16070000080247],
              [106.811999999202, -6.16070000080247],
              [106.812199997633, -6.16070000080247],
              [106.812300000446, -6.16070000080247],
              [106.812399996963, -6.16070000080247],
              [106.812600002589, -6.16070000080247],
              [106.812700000006, -6.16070000080247],
              [106.81280000102, -6.16070000080247],
              [106.812899999336, -6.16070000080247],
              [106.81300000125, -6.16070000080247],
              [106.813600000141, -6.16059999888897],
              [106.814399998362, -6.16050000147203],
              [106.814899999836, -6.16039999955848],
              [106.815099999167, -6.16039999955848],
              [106.815900002784, -6.16030000124215],
              [106.815999999302, -6.16030000124215],
              [106.816100001215, -6.16030000124215],
              [106.816100001215, -6.16020000022792],
              [106.816199999531, -6.16020000022792],
              [106.816300001445, -6.16020000022792],
              [106.816399998862, -6.16020000022792],
              [106.816499999876, -6.16020000022792],
              [106.816599998192, -6.16020000022792],
              [106.816700000106, -6.16020000022792],
              [106.816700000106, -6.16010000191173],
              [106.816799998422, -6.16010000191173],
              [106.816900000336, -6.16010000191173],
              [106.81700000135, -6.16010000191173],
              [106.817099999666, -6.16010000191173],
              [106.81720000158, -6.16010000191173],
              [106.817999999801, -6.15989999808454],
              [106.818200000031, -6.15989999808454],
              [106.818799998922, -6.15980000066758],
              [106.818899999936, -6.15980000066758],
              [106.818999998252, -6.15980000066758],
              [106.819100000166, -6.15969999875412],
              [106.819300000396, -6.15969999875412],
              [106.819699999057, -6.1596000013371],
              [106.820000000301, -6.1596000013371],
              [106.820099997718, -6.1596000013371],
              [106.820500000875, -6.15949999852434],
              [106.820599999192, -6.15949999852434],
              [106.820799998522, -6.1596000013371],
              [106.82140000191, -6.15980000066758],
              [106.821500000226, -6.15989999808454],
              [106.821699999556, -6.15989999808454],
              [106.8220000008, -6.1599999999981],
              [106.822099998217, -6.16010000191173],
              [106.823399997913, -6.16050000147203],
              [106.82460000109, -6.16099999844928],
              [106.824999998852, -6.16110000036285],
              [106.825399998412, -6.1612000013771],
              [106.82580000157, -6.16140000160689],
              [106.825899999886, -6.16140000160689],
              [106.826099999217, -6.16149999902391],
              [106.826399999561, -6.16160000093741],
              [106.826600000691, -6.16169999925371],
              [106.826699998107, -6.16169999925371],
              [106.826800000021, -6.1618000020666],
              [106.827100001265, -6.16189999858423],
              [106.827500000825, -6.16200000049786],
              [106.827899999486, -6.16209999791476],
              [106.828099998817, -6.16219999982825],
              [106.828299999047, -6.16230000174187],
              [106.828299999047, -6.16219999982825],
              [106.828200002529, -6.16209999791476],
              [106.828099998817, -6.16200000049786],
              [106.828099998817, -6.1618000020666],
              [106.828000002299, -6.16169999925371],
              [106.827899999486, -6.16149999902391],
              [106.827899999486, -6.16130000059266],
              [106.827799998472, -6.1612000013771],
              [106.827700000156, -6.16099999844928],
              [106.827700000156, -6.16079999911876],
              [106.827700000156, -6.16070000080247],
              [106.827599998242, -6.16059999888897],
              [106.827599998242, -6.16050000147203],
              [106.827599998242, -6.16039999955848],
              [106.827599998242, -6.16030000124215],
              [106.827599998242, -6.16020000022792],
              [106.827500000825, -6.16010000191173],
              [106.827500000825, -6.1599999999981],
              [106.827500000825, -6.15989999808454],
              [106.827500000825, -6.15980000066758],
              [106.827500000825, -6.15969999875412],
              [106.827500000825, -6.1596000013371],
              [106.827500000825, -6.15949999852434],
              [106.827500000825, -6.15940000110731],
              [106.827500000825, -6.15929999919381],
              [106.827500000825, -6.15920000267613],
              [106.827500000825, -6.15909999986329],
              [106.827500000825, -6.15900000154701],
              [106.827500000825, -6.15890000053274],
              [106.827500000825, -6.15879999861917],
              [106.827500000825, -6.15859999838936],
              [106.827500000825, -6.15850000007305],
              [106.827500000825, -6.15839999905881],
              [106.827500000825, -6.15830000074255],
              [106.827500000825, -6.1581999997283],
              [106.827500000825, -6.1580000003978],
              [106.827500000825, -6.15790000298084],
              [106.827500000825, -6.15779999926872],
              [106.827500000825, -6.15769999825451],
              [106.827399998912, -6.15760000083746],
              [106.827399998912, -6.15749999982325],
              [106.827399998912, -6.15740000060767],
              [106.827399998912, -6.15729999959348],
              [106.827399998912, -6.15720000127715],
              [106.827399998912, -6.15690000003313],
              [106.827399998912, -6.15680000081757],
              [106.827399998912, -6.15669999980329],
              [106.827399998912, -6.15659999878914],
              [106.827399998912, -6.15650000047281],
              [106.827399998912, -6.15639999855923],
              [106.827399998912, -6.1563000020416],
              [106.827399998912, -6.1562000001281],
              [106.827399998912, -6.15610000001314],
              [106.827399998912, -6.15599999989826],
              [106.827399998912, -6.15590000158198],
              [106.827399998912, -6.15580000056775],
              [106.827399998912, -6.15569999775483],
              [106.827399998912, -6.15559999943861],
              [106.827399998912, -6.15549999752509],
              [106.827399998912, -6.15540000010804],
              [106.827399998912, -6.15529999909385],
              [106.827399998912, -6.15520000077757],
              [106.827399998912, -6.15509999886407],
              [106.827399998912, -6.15500000144706],
              [106.827399998912, -6.15489999953354],
              [106.827399998912, -6.15480000121726],
              [106.827399998912, -6.15459999918875],
              [106.827399998912, -6.15439999716024],
              [106.827399998912, -6.15430000064271],
              [106.827399998912, -6.15419999782973],
              [106.827399998912, -6.15410000131217],
              [106.827399998912, -6.15399999939865],
              [106.827399998912, -6.15390000108239],
              [106.827399998912, -6.15370000175183],
              [106.827399998912, -6.15359999983825],
              [106.827399998912, -6.15340000050774],
              [106.827399998912, -6.15329999859421],
              [106.827399998912, -6.15320000027791],
              [106.827399998912, -6.15309999926372],
              [106.827399998912, -6.15300000094744],
              [106.827300000596, -6.15289999993317],
              [106.827300000596, -6.15280000161687],
              [106.827300000596, -6.15269999970341],
              [106.827300000596, -6.15260000138712],
              [106.827300000596, -6.15249999857425],
              [106.827300000596, -6.15239999935866],
              [106.827300000596, -6.15230000014298],
              [106.827300000596, -6.1521999982295],
              [106.827300000596, -6.15209999991318],
              [106.827300000596, -6.15199999889898],
              [106.827300000596, -6.15190000058268],
              [106.827300000596, -6.15179999956847],
              [106.827300000596, -6.15170000125223],
              [106.827300000596, -6.15159999933861],
              [106.827300000596, -6.15150000192173],
              [106.827300000596, -6.15140000000808],
              [106.827200000481, -6.15140000000808],
              [106.827200000481, -6.15129999809463],
              [106.827200000481, -6.15119999977834],
              [106.827200000481, -6.15109999876411],
              [106.827200000481, -6.15100000044781],
              [106.827200000481, -6.15089999853431],
              [106.827200000481, -6.15080000021802],
              [106.827200000481, -6.15070000010306],
              [106.827100001265, -6.15070000010306],
              [106.827100001265, -6.15060000088747],
              [106.827100001265, -6.15049999987328],
              [106.827100001265, -6.15040000065768],
              [106.827100001265, -6.15030000054277],
              [106.827100001265, -6.15010000031295],
              [106.826999999352, -6.14999999839934],
              [106.826999999352, -6.14990000098246],
              [106.826999999352, -6.14979999906885],
              [106.826999999352, -6.14970000075259],
              [106.826999999352, -6.14959999883904],
              [106.826900001035, -6.14939999950852],
              [106.826900001035, -6.14909999826449],
              [106.826900001035, -6.14900000084747],
              [106.826800000021, -6.14889999893396],
              [106.826800000021, -6.1488000006177],
              [106.826800000021, -6.14869999780479],
              [106.826800000021, -6.14860000218651],
              [106.826800000021, -6.14849999937363],
              [106.826800000021, -6.14840000105736],
              [106.826699998107, -6.14840000105736],
              [106.826699998107, -6.14829999824448],
              [106.826699998107, -6.14820000082759],
              [106.826699998107, -6.14809999981331],
              [106.826699998107, -6.14799999789974],
              [106.826600000691, -6.14790000138211],
              [106.826600000691, -6.14779999856929],
              [106.826600000691, -6.14770000025303],
              [106.826600000691, -6.14759999923879],
              [106.826499998777, -6.14759999923879],
              [106.826499998777, -6.14750000092247],
              [106.826499998777, -6.14739999900891],
              [106.826499998777, -6.14730000249125],
              [106.826399999561, -6.14719999967837],
              [106.826399999561, -6.14710000136215],
              [106.826399999561, -6.14699999854928],
              [106.826299999446, -6.14699999854928],
              [106.826299999446, -6.14689999933371],
              [106.826299999446, -6.14680000011803],
              [106.826299999446, -6.14669999820447],
              [106.82620000113, -6.14669999820447],
              [106.82620000113, -6.14659999988826],
              [106.82620000113, -6.14649999797468],
              [106.826099999217, -6.14649999797468],
              [106.826099999217, -6.14640000055774],
              [106.826099999217, -6.14629999954355],
              [106.8260000009, -6.14629999954355],
              [106.8260000009, -6.14620000212659],
              [106.8260000009, -6.14609999931366],
              [106.825899999886, -6.14609999931366],
              [106.825899999886, -6.14600000189678],
              [106.825899999886, -6.14589999998311],
              [106.82580000157, -6.14589999998311],
              [106.82580000157, -6.14579999806963],
              [106.825699999656, -6.14570000065258],
              [106.825699999656, -6.14559999963834],
              [106.825599998642, -6.14559999963834],
              [106.825599998642, -6.14549999952353],
              [106.825599998642, -6.14539999850932],
              [106.825500000326, -6.14539999850932],
              [106.825500000326, -6.14530000019299],
              [106.825500000326, -6.14519999917876],
              [106.825399998412, -6.14519999917876],
              [106.825399998412, -6.14510000086246],
              [106.825300000995, -6.1450000007476],
              [106.825300000995, -6.14490000063266],
              [106.825199998182, -6.14490000063266],
              [106.825199998182, -6.14479999871906],
              [106.825100000765, -6.14469999860423],
              [106.825100000765, -6.14460000028792],
              [106.824999998852, -6.14449999927367],
              [106.824900001435, -6.14440000095739],
              [106.824900001435, -6.1442999990439],
              [106.824799999521, -6.1442000007276],
              [106.824799999521, -6.14409999881403],
              [106.824700000306, -6.14409999881403],
              [106.824700000306, -6.14400000139707],
              [106.82460000109, -6.14389999948344],
              [106.82460000109, -6.14380000116731],
              [106.824499998277, -6.14370000015306],
              [106.824499998277, -6.14359999734018],
              [106.82440000086, -6.14359999734018],
              [106.82440000086, -6.14349999992317],
              [106.82440000086, -6.14339999890895],
              [106.824299998947, -6.14339999890895],
              [106.824299998947, -6.14330000149198],
              [106.824200000631, -6.14330000149198],
              [106.824200000631, -6.14319999777984],
              [106.824200000631, -6.14310000036284],
              [106.824099998717, -6.14310000036284],
              [106.824099998717, -6.14299999934864],
              [106.8240000013, -6.14290000103244],
              [106.8240000013, -6.14280000001807],
              [106.823899999387, -6.14280000001807],
              [106.823899999387, -6.1427000017019],
              [106.823899999387, -6.14260000068759],
              [106.82380000107, -6.14260000068759],
              [106.82380000107, -6.14249999787482],
              [106.823699998257, -6.14249999787482],
              [106.823699998257, -6.14240000045777],
              [106.823699998257, -6.14229999854426],
              [106.82360000174, -6.14229999854426],
              [106.82360000174, -6.142200000228],
              [106.82360000174, -6.14209999921379],
              [106.823499998927, -6.14209999921379],
              [106.823499998927, -6.1420000008975],
              [106.823399997913, -6.1420000008975],
              [106.823399997913, -6.14189999808461],
              [106.823399997913, -6.14180000156696],
              [106.823300000496, -6.14169999965344],
              [106.823199999481, -6.14160000223652],
              [106.823199999481, -6.14149999942353],
              [106.823100000266, -6.14139999840941],
              [106.822999998352, -6.14119999817951],
              [106.822900001835, -6.1411000016619],
              [106.822900001835, -6.14099999794971],
              [106.822900001835, -6.14090000053271],
              [106.822799999022, -6.14090000053271],
              [106.822799999022, -6.14079999951848],
              [106.822700002504, -6.14059999928869],
              [106.822700002504, -6.14050000187179],
              [106.822599999691, -6.14039999995816],
              [106.822599999691, -6.14029999804467],
              [106.822599999691, -6.14020000062767],
              [106.822500000476, -6.14020000062767],
              [106.822500000476, -6.14009999871417],
              [106.822500000476, -6.14000000039784],
              [106.822500000476, -6.13989999938367],
              [106.822400000361, -6.13989999938367],
              [106.822400000361, -6.13980000016805],
              [106.822400000361, -6.13969999915386],
              [106.822400000361, -6.13960000083751],
              [106.822299998447, -6.13949999982332],
              [106.822299998447, -6.13929999869415],
              [106.822299998447, -6.13919999857919],
              [106.822200000131, -6.13919999857919],
              [106.822200000131, -6.13910000026293],
              [106.822200000131, -6.13899999924872],
              [106.822099998217, -6.1387999990189],
              [106.822099998217, -6.1387000007026],
              [106.822099998217, -6.13850000137206],
              [106.8220000008, -6.13830000204167],
              [106.8220000008, -6.13820000012808],
              [106.8220000008, -6.13809999731516],
              [106.821899998887, -6.13799999989818],
              [106.821899998887, -6.13780000056774],
              [106.821799999671, -6.13769999955353],
              [106.821799999671, -6.13760000033783],
              [106.821799999671, -6.13749999932373],
              [106.821699999556, -6.13740000100742],
              [106.821699999556, -6.1373000008925],
              [106.82160000124, -6.13720000167687],
              [106.821500000226, -6.13709999976327],
              [106.82140000191, -6.1369999978498],
              [106.82140000191, -6.13690000043277],
              [106.821299999097, -6.13690000043277],
              [106.821500000226, -6.13679999851929],
              [106.821799999671, -6.13679999851929],
              [106.822099998217, -6.13670000020301],
              [106.822200000131, -6.13670000020301],
              [106.822700002504, -6.13670000020301],
              [106.822700002504, -6.13659999828949],
              [106.823300000496, -6.13659999828949],
              [106.823399997913, -6.13650000087244],
              [106.824799999521, -6.13630000154195],
              [106.824900001435, -6.13630000154195],
              [106.824999998852, -6.13630000154195],
              [106.82580000157, -6.13619999872919],
              [106.8260000009, -6.13619999872919],
              [106.82620000113, -6.1361000022115],
              [106.826299999446, -6.1361000022115],
              [106.826399999561, -6.1361000022115],
              [106.826800000021, -6.13600000119718],
              [106.827100001265, -6.13600000119718],
              [106.827500000825, -6.13589999748508],
              [106.827599998242, -6.13589999748508],
              [106.827700000156, -6.13589999748508],
              [106.828099998817, -6.13580000006812],
              [106.828200002529, -6.13580000006812],
              [106.828299999047, -6.13580000006812],
              [106.828599999392, -6.13569999815453],
              [106.829100001765, -6.135600001637],
              [106.829199999182, -6.13549999882403],
              [106.829799998072, -6.13540000140712],
              [106.829999998302, -6.13529999949356],
              [106.83040000146, -6.13520000117728],
              [106.830699998207, -6.13509999926369],
              [106.830699998207, -6.13520000117728],
              [106.830699998207, -6.13529999949356],
              [106.830699998207, -6.13540000140712],
              [106.830699998207, -6.135600001637],
              [106.830699998207, -6.13569999815453],
              [106.830699998207, -6.13589999748508],
              [106.830699998207, -6.13600000119718],
              [106.830699998207, -6.1361000022115],
              [106.830699998207, -6.13630000154195],
              [106.830699998207, -6.13650000087244],
              [106.830699998207, -6.13670000020301],
              [106.830699998207, -6.13690000043277],
            ],
          ],
        ],
      },
    },
  ],
};
/* eslint-enable */
