import React from 'react';
import { IconType, IconTypeDefault } from '@bvt-types';

export default function IconResume(props) {
  const { className, height, width } = props;

  return (
    <svg height={height} viewBox='0 0 20 20' width={width}>
      <path
        className={className}
        d='M9.994,1a8.994,8.994,0,1,0,8.994,8.994A8.994,8.994,0,0,0,9.994,1Zm0,16.353a7.359,7.359,0,1,1,7.359-7.359,7.359,7.359,0,0,1-7.359,7.359Z'
        data-name='Path 388'
        transform='translate(-1 -1)'
      />
      <path
        className={className}
        d='M15.613,10.241,9.972,6.971A.981.981,0,0,0,8.5,7.788v6.541a.981.981,0,0,0,1.472.818l5.642-3.271a.981.981,0,0,0,0-1.635Zm-5.478,2.943V8.933l3.679,2.126Z'
        data-name='Path 389'
        transform='translate(-2.368 -2.065)'
      />
    </svg>
  );
}

IconResume.defaultProps = IconTypeDefault;

IconResume.propTypes = IconType;
