import { ACTIVITY_CONSTANT } from './ACTIVITY_CONSTANT';

const initialState = {
  status_BY_MODULE: 'IDLE',
  status_BY_MODULE_CHART: 'IDLE',
  status_BY_TIME: 'IDLE',
  message: '',
  chartByModule: [],
  byModule: [],
  byTime: {},
};

export function ACTIVITY_REDUCER(state = initialState, action) {
  let returnData = state;
  Object.values(ACTIVITY_CONSTANT).map((ctx) => {
    if (action.type === ACTIVITY_CONSTANT.RESET_STATUS) {
      returnData = {
        ...returnData,
        status_BY_MODULE: 'IDLE',
        status_BY_TIME: 'IDLE',
        message: '',
      };
    } else if (action.type === ACTIVITY_CONSTANT.RESET_ALL) {
      returnData = {
        status_BY_MODULE: 'IDLE',
        status_BY_TIME: 'IDLE',
        message: '',
        chartByModule: [],
        byModule: [],
        byTime: {},
      };
    } else if (ctx === action.type && !ctx.includes('[NO_STATE_UPDATE]')) {
      returnData = { ...returnData, ...action.payload };
    }
    return null;
  });
  return returnData;
}
