import React from 'react';
import { useMap } from 'react-leaflet';
import { MainMapMapControlButtonList } from '../../component/MainMapMapControlButtonList';
import { IconPlus } from '@bvt-assets/icon/icon-plus';
import { IconMinus } from '@bvt-assets/icon/icon-minus';
import { IconFocus } from '@bvt-assets/icon/icon-focus';
import { IconBaseMap } from '@bvt-assets/icon/icon-baseMap';
import { IconFilterOne } from '@bvt-assets/icon/icon-filterOne';
import { IconFullScreen } from '@bvt-assets/icon/icon-fullScreen';
import { IconOpenLayer } from '@bvt-assets/icon/icon-openLayer';
import { useMainMap } from '../../hook/useMainMap';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '@bvt-features/auth/hooks';

/**
 * @author Randa <m.randa@bvarta.com>
 * @description Layout Sidebar for tooling
 * @type {import('react').FC}
 */
export const MainMapToolbarContainer = () => {
  const mapp = useMainMap();
  const map = useMap();
  const AUTH = useAuth();
  const dispatch = useDispatch();
  const fullscreen = useSelector(
    (state) => state.interactive.MAP.FULL_SCREEN.value
  );

  const internalDisableHandleMapEvent = (param) => {
    if (param) {
      map.scrollWheelZoom.disable();
      map.doubleClickZoom.disable();
    } else {
      map.scrollWheelZoom.enable();
      map.doubleClickZoom.enable();
    }
  };

  const handleOpenFullscreen = () => {
    document.documentElement.requestFullscreen();
    dispatch({ type: 'SET_FULLSCREEN', payload: !fullscreen });
  };

  return (
    <div
      className='bvt-map__toolbar'
      onMouseEnter={() => internalDisableHandleMapEvent(true)}
      onMouseLeave={() => internalDisableHandleMapEvent(false)}
      style={{
        top: (window.innerHeight * 0.22) / 16 + 'rem',
        position: 'fixed',
        zIndex: 4024,
        right: `${
          mapp.state.activeMenu ? mapp.state.activeMenuWidth + 'px' : '0.5rem'
        }`,
        padding: '0.5rem',
        transition: '0.25s ease-in-out',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <MainMapMapControlButtonList
        data={[
          [
            {
              children: <IconPlus />,
              onClick: () => map.zoomIn(3),
              title: 'Zoom In',
            },
            {
              children: <IconMinus />,
              onClick: () => map.zoomOut(3),
              title: 'Zoom Out',
            },
            {
              children: <IconFocus />,
              disabled: true,
            },
          ],
          [
            {
              children: <IconBaseMap />,
              onClick: () => mapp.setActiveMenu('BASEMAP'),
              title: 'Base Map',
            },
            {
              children: <IconFilterOne />,
              onClick: () => mapp.setActiveMenu('LAYERING_VISIBLITY'),
              title: 'Layer Visiblity',
            },
            {
              children: <IconFullScreen />,
              onClick: () => handleOpenFullscreen(),
              title: 'Fullscreen'
            },
          ],
          (AUTH.hasAccess('DATEX') && [
            {
              children: <IconOpenLayer />,
              disabled: false,
              onClick: () => mapp.setActiveMenu('DATEX'),
              title: 'Data Explorer',
            },
          ]) || [],
        ]}
      />
    </div>
  );
};
