/* eslint-disable import/namespace */
import React from 'react';
import { IconRegionPolygon } from '@bvt-assets/icon/icon-regionPolygon';

/**
 * @company PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

export const PolySymbol = () => {
  return (
    <div className='legend-symbol__poly'>
      <IconRegionPolygon />
    </div>
  );
};
