import { IconAverageGraph } from '@bvt-assets/icon/icon-averageGraph';
import { IconTotalGraph } from '@bvt-assets/icon/icon-totalGraph';
import React from 'react';

export const CATCHMENT_TYPE = [
  {
    key: 'total',
    label: 'Total',
    icon: <IconTotalGraph />,
  },
  {
    key: 'average',
    label: 'Average',
    icon: <IconAverageGraph />,
  },
];
