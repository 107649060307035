import Resource from '../../../../api/resource';

export class MappProjectStepHistoryService extends Resource {
  constructor() {
    super('project-step-history', '/api', 'api-poi', 'v2');
  }

  create_project_step_hist(payload) {
    return this.create(payload);
  }
}
