import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { GA_Utils } from '@bvt-shared/utils/GA-Utils';

/**
 * @description BasePageHeadTag
 * @param {object} props
 * @param {string} props.title
 */
export const BasePageHeadTag = (props = {}) => {
  const { title } = props;
  useEffect(() => {
    GA_Utils.trackPage();
  }, []);
  return <Helmet>{title && <title>{title}</title>}</Helmet>;
};
