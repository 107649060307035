import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Dropdown.scss';
import { isEmpty } from 'lodash';
import Loading from '../../../../../components/bvt/atoms/Loading';
import { Popover } from '@bvt-atoms';
import { generateUUID } from '@bvt-utils';

const IconClose = () => {
  return (
    <svg
      className='close'
      height='1rem'
      viewBox='0 0 12.263 12.263'
      width='1rem'
    >
      <path
        d='M3.153,7.489V5.518H1.183a1.182,1.182,0,1,1,0-2.364h1.97V1.183a1.183,1.183,0,1,1,2.365,0V3.154H7.489a1.182,1.182,0,1,1,0,2.364H5.518V7.489a1.183,1.183,0,1,1-2.365,0Z'
        fill='currentColor'
        transform='translate(6.132) rotate(45)'
      />
    </svg>
  );
};

const ArrowUp = () => {
  return (
    <svg
      height='0.65rem'
      viewBox='0 0 11 6'
      width='0.65rem'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.763.8A1,1,0,0,1,6.237.8l3.227,3.52A1,1,0,0,1,8.727,6H2.273a1,1,0,0,1-.737-1.676Z'
        data-name='Polygon 133'
        fill='#4d4f5c'
        id='Polygon_133'
      />
    </svg>
  );
};

const ArrowDown = () => {
  return (
    <svg
      height='0.65rem'
      viewBox='0 0 11 6'
      width='0.65rem'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.763.8A1,1,0,0,1,6.237.8l3.227,3.52A1,1,0,0,1,8.727,6H2.273a1,1,0,0,1-.737-1.676Z'
        data-name='Polygon 133'
        fill='#4d4f5c'
        id='Polygon_133'
        transform='translate(11 6) rotate(180)'
      />
    </svg>
  );
};

export default function DropdownInter(props) {
  const [list, setList] = useState([]);
  const [input, setInput] = useState('');
  const handleSearch = (value) =>
    props.handler.search && props.handler.search(value);
  const handleResetInput = () => {
    if (toggle && input.length > 0) {
      setInput('');
      handleSearch('');
    }
  };
  const handleMouseLeave = () => {
    handleResetInput();
    setToggle(false);
  };

  useEffect(() => {
    return () => {
      setList([]);
    };
  }, []);

  useEffect(() => {
    setList(props.options);
  }, [props.options]);

  const renderDisplayInput = () => (
    <React.Fragment>
      {props.selected?.name ? (
        <div className='feature-mainmap-place dropdown-place__display-box-input'>
          <div className='feature-mainmap-place dropdown-place__display-box-input_text'>
            {props.selected.name}
          </div>
          <div
            className='feature-mainmap-place dropdown-place__display-box-input_button'
            onClick={(e) => {
              e.stopPropagation();
              props.handler.select && props.handler.select({});
              props.handler.clear && props.handler.clear();
            }}
          >
            <IconClose />
          </div>
        </div>
      ) : (
        <div className='feature-mainmap-place dropdown-place__display-box-input_placeholder'>
          {props.placeholder}
        </div>
      )}
    </React.Fragment>
  );

  const popoverContent = (
    <div className='feature-mainmap-place container-popover'>
      <div className='arrow arrow---down' />
      <p className='feature-mainmap-place container-popover-text'>
        Contact our sales to subscribe
      </p>
    </div>
  );

  function compareStatus() {
    return true;
  }

  const [toggle, setToggle] = useState(false);
  const suggestions = () => {
    if (!isEmpty(list) && !props.isLoading) {
      return (
        <div className='feature-mainmap-place dropdown-place__options-list-container'>
          {props.validation
            ? list.toSorted(compareStatus).map((ctx) =>
              ctx.status ? (
                <div
                  className={`feature-mainmap-place dropdown-place__options-list ${
                    ctx[`id_${props.type}`] === props.selected?.id
                      ? 'active'
                      : ''
                  }}`}
                  key={generateUUID()}
                  onClick={() => {
                    props.handler.select && props.handler.select(ctx);
                    setToggle(false);
                    handleResetInput();
                  }}
                >
                  {ctx[props.dispayName]}
                </div>
              ) : (
                <Popover
                  className='feature-mainmap-place__popover-wrapper'
                  content={popoverContent}
                  header={false}
                  key={generateUUID()}
                  placement='top-left'
                  trigger='hover'
                  visibility={false}
                >
                  <div
                    className={`feature-mainmap-place dropdown-place__options-list ${
                      ctx[`id_${props.type}`] === props.selected?.id
                        ? 'active'
                        : ''
                    } ${ctx.status ? '' : 'disabled'}`}
                    key={generateUUID()}
                    onClick={() => {
                      props.handler.select &&
                          ctx.status &&
                          props.handler.select(ctx);
                      handleResetInput();
                    }}
                  >
                    {ctx[props.dispayName]}
                  </div>
                </Popover>
              )
            )
            : list.map((ctx) => (
              <div
                className={`feature-mainmap-place dropdown-place__options-list ${
                  ctx[props.dispayId] === props.selected?.id ? 'active' : ''
                }}`}
                key={generateUUID()}
                onClick={() => {
                  props.handler.select && props.handler.select(ctx);
                  setToggle(false);
                  handleResetInput();
                }}
              >
                {ctx[props.dispayName]}
              </div>
            ))}
        </div>
      );
    } else if (props.isLoading) {
      return (
        <div className='feature-mainmap-place dropdown-place__options-not-found'>
          <Loading color={'green'} size={'medium'} />
        </div>
      );
    } else {
      return (
        <div className='feature-mainmap-place dropdown-place__options-not-found'>
          {props.placeholder} not found
        </div>
      );
    }
  };

  return (
    <div
      className='feature-mainmap-place dropdown-place'
      style={{ width: '100%' }}
    >
      <div
        className='feature-mainmap-place dropdown-place__container'
        onMouseLeave={handleMouseLeave}
      >
        <div
          className={`feature-mainmap-place dropdown-place__display-box ${
            props.disabled ? 'disabled' : ''
          } ${props.selected?.name || toggle ? 'active' : ''}`}
          onClick={() => setToggle((prev) => !prev)}
        >
          {renderDisplayInput()}
          {toggle ? <ArrowUp /> : <ArrowDown />}
        </div>
        {toggle && (
          <div className='feature-mainmap-place dropdown-place__options'>
            <div className='feature-mainmap-place dropdown-place__options-search'>
              <input
                onChange={(e) => {
                  setInput(e.target.value);
                  handleSearch(e.target.value);
                }}
                placeholder='Search'
                value={input}
              />
            </div>
            {suggestions()}
          </div>
        )}
      </div>
    </div>
  );
}

DropdownInter.propTypes = {
  disabled: PropTypes.bool,
  dispayId: PropTypes.string,
  dispayName: PropTypes.string,
  handler: PropTypes.shape({
    search: PropTypes.func,
    select: PropTypes.func,
    clear: PropTypes.func,
  }),
  inputValue: PropTypes.string,
  isLoading: PropTypes.bool,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  selected: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    code: PropTypes.string,
  }),
  type: PropTypes.oneOf(['country', 'provinsi', 'kota', 'kecamatan', 'desa']),
  validation: PropTypes.bool,
};
