/* eslint-disable indent */
import { useAuth } from '@bvt-features/auth/hooks';
import { Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import { AccountDetailCard } from './components/AccountDetailCard';
import { AccountItemInfo } from './components/AccountItemInfo';
import { AccountPhoto } from './components/AccountPhoto';
import { BusinessDetailSummary } from './components/BusinessDetailSummary';
import './PersonalDetailContainer.scss';

/**
 * @company PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

export const PersonalDetailContainer = () => {
  const { user } = useAuth().state.data;
  const PERSONAL_DETAIL = [
    {
      label: 'Name',
      value: `${user?.firstname} ${user?.lastname}`,
    },
    {
      label: 'User-ID',
      value: `${user?.id_employee || '-'}`,
    },
    {
      label: 'Email',
      value: `${user?.email || '-'}`,
    },
    {
      label: 'Mobile phone',
      value: `${user?.telp || '-'}`,
    },
  ];

  const BUSINESS_DETAIL = [
    {
      label: 'Division, Department',
      value: `${user?.department || '-'}`,
    },
    {
      label: 'Registered at',
      value: user?.created_at
        ? moment(user?.created_at).format('MMM, DD - YYYY')
        : '-',
    },
    {
      label: 'Role',
      value: `${user?.role?.name || '-'}`,
    },
    {
      label: 'Access Area',
      value: '-',
    },
  ];

  return (
    <Row className='PersonalDetailContainer' gutter={[16, 16]}>
      <Col lg={8} md={8} sm={24} span={8} xs={24}>
        <div className='PersonalDetailContainer--card'>
          <AccountDetailCard
            bodyContent={<AccountItemInfo data={PERSONAL_DETAIL} />}
            headContent={<AccountPhoto photo={user?.photo_path} />}
            title='Personal details'
          />
        </div>
      </Col>
      <Col lg={16} md={16} sm={24} span={16} xs={24}>
        <div className='PersonalDetailContainer--card'>
          <AccountDetailCard
            bodyContent={<AccountItemInfo data={BUSINESS_DETAIL} />}
            headContent={
              <BusinessDetailSummary
                data={{
                  left: [
                    {
                      label: 'Company name',
                      value: user?.users_group?.group_name,
                    },
                    {
                      label: 'Industry',
                      value: '-',
                    },
                  ],
                  right: [
                    {
                      label: 'Head Office (BSD)',
                      value: '-',
                    },
                    {
                      label: 'Registered at',
                      value: user?.users_group?.created_at
                        ? moment(user?.users_group?.created_at).format(
                            'MMM, DD - YYYY'
                          )
                        : '-',
                    },
                  ],
                }}
              />
            }
            title='Business details'
          />
        </div>
      </Col>
    </Row>
  );
};
