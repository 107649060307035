import { USER_CONSTANT } from './USER_CONSTANT';
import { UserService } from '../../service';

const userService = new UserService();

export const USER_ACTION = {
  /**
   *
   * @param {object} body
   * @param {string} body.password
   */
  updatePassword: (body) => {
    return async (dispatch) => {
      dispatch({
        type: USER_CONSTANT.UPDATE_PASSWORD_START,
        payload: { status_PUT: 'LOADING' },
      });
      try {
        const DATA = await userService.updatePassword(body);
        dispatch({
          type: USER_CONSTANT.UPDATE_PASSWORD_SUCCESS,
          payload: { status_PUT: 'SUCCESS', message: DATA?.message },
        });
      } catch (error) {
        dispatch({
          type: USER_CONSTANT.UPDATE_PASSWORD_FAILED,
          payload: { status_PUT: 'FAILED' },
        });
      }
    };
  },
  resetStatus: () => {
    return {
      type: USER_CONSTANT.RESET_STATUS,
    };
  },
};
