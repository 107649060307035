import React, { useEffect, useState } from 'react';
import { Image } from 'antd';

import { SharedBaseCardTypeOneComponent } from '@bvt-shared/component/SharedBaseCardTypeOne.component';
import './AsmanCardAssetDetails.component.scss';

export const AsmanCardAssetDetailsComponent = props => {
  const [state, setState] = useState({ isVisible: false });
  const [images, setImages] = useState([]);
  const [isInitialMount, setIsInitialMount] = useState(true);
  const { data, isLoading } = props;


  // create image bloburl from gateway (if direct from the gateway it takes a long time)
  const fetchAllImages = async (urls) => {
    try {
      const responses = await Promise.all(urls.map(url => fetch(url)));
      const blobs = await Promise.all(responses.map(response => {
        if (!response.ok) {
          throw new Error(`Failed to fetch image: ${response.url}`);
        }
        return response.blob();
      }));
      return blobs.map(blob => URL.createObjectURL(blob));
    } catch (error) {
      console.error('Error fetching images:', error);
      throw error;
    }
  };

  useEffect(() => {
    const getImages = async () => {
      const images = await fetchAllImages(data.image);
      setImages(images);
    };
    if (data?.image?.length > 0 && isInitialMount) {
      getImages();
    }
    return () => {
      if (data?.image?.length > 0) {
        setIsInitialMount(false); // Ensure it runs only once
      }
    };
  }, [data.image]);

  useEffect(() => {
    return () => {
      // remove blobUrl
      images?.map((blobUrl) => URL.revokeObjectURL(blobUrl));
    };
  }, [images]);

  return (
    <SharedBaseCardTypeOneComponent
      isLoading={isLoading}
      showIcon={true}
      title='Asset Details'
    >
      <Image
        fallback='https://via.placeholder.com/512'
        height={258}
        onClick={() => setState({ isVisible: true })}
        preview={{ visible: false }}
        src={images.length > 0 ? images?.[0] : 'https://via.placeholder.com/512'}
        style={{ objectFit: 'contain' }}
        width={'100%'}
      />
      <div style={{ display: 'none' }}>
        <Image.PreviewGroup
          preview={{
            visible: state.isVisible,
            onVisibleChange: vis => setState({ ...state, isVisible: vis })
          }}
        >
          {images.length > 0 && state.isVisible &&
            images.map((v) => (
              <Image
                fallback='https://via.placeholder.com/512'
                key={v}
                src={v}
              />
            ))}
        </Image.PreviewGroup>
      </div>
      <div className='AsmanCardAssetDetailsComponent__wrapper'>
        <div className='AsmanCardAssetDetailsComponent__wrapper-name'>
          <div className='AsmanCardAssetDetailsComponent__wrapper-name__name'>
            Name
          </div>
          <div>
            <strong>{data.name}</strong>
          </div>
        </div>
        {data.icon && (
          <div className='AsmanCardAssetDetailsComponent__wrapper-icon'>
            <div className='AsmanCardAssetDetailsComponent__wrapper-icon__icon'>
              Icon
            </div>
            <div>
              <img alt={data.name} src={data.icon} width={50} />
            </div>
          </div>
        )}
      </div>
      <div className='AsmanCardAssetDetailsComponent__list'>
        <div className='AsmanCardAssetDetailsComponent__list-item'>
          <div className='AsmanCardAssetDetailsComponent__list-item--key'>
            Address
          </div>
          <div className='AsmanCardAssetDetailsComponent__list-item--value'>
            {data?.address ?? '-'}
          </div>
        </div>
        <div className='AsmanCardAssetDetailsComponent__list-item'>
          <div className='AsmanCardAssetDetailsComponent__list-item--key'>
            Latitude
          </div>
          <div className='AsmanCardAssetDetailsComponent__list-item--value'>
            {data.latitude ? data.latitude.toString() : '-'}
          </div>
        </div>
        <div className='AsmanCardAssetDetailsComponent__list-item'>
          <div className='AsmanCardAssetDetailsComponent__list-item--key'>
            Longitude
          </div>
          <div className='AsmanCardAssetDetailsComponent__list-item--value'>
            {data.longitude ? data.longitude.toString() : '-'}
          </div>
        </div>
        <div className='AsmanCardAssetDetailsComponent__list-item'>
          <div className='AsmanCardAssetDetailsComponent__list-item--key'>
            Area
          </div>
          <div className='AsmanCardAssetDetailsComponent__list-item--value'>
            {data?.area ?? '-'}
          </div>
        </div>
        <div className='AsmanCardAssetDetailsComponent__list-item'>
          <div className='AsmanCardAssetDetailsComponent__list-item--key'>
            Building Usage
          </div>
          <div className='AsmanCardAssetDetailsComponent__list-item--value'>
            {data?.buildingUsage ?? '-'}
          </div>
        </div>
        <div className='AsmanCardAssetDetailsComponent__list-item'>
          <div className='AsmanCardAssetDetailsComponent__list-item--key'>
            Idle Area
          </div>
          <div className='AsmanCardAssetDetailsComponent__list-item--value'>
            {data?.idleArea ?? '-'}
          </div>
        </div>
        <div className='AsmanCardAssetDetailsComponent__list-item'>
          <div className='AsmanCardAssetDetailsComponent__list-item--key'>
            Propar
          </div>
          <div className='AsmanCardAssetDetailsComponent__list-item--value'>
            {data?.propar ?? '-'}
          </div>
        </div>
        <div className='AsmanCardAssetDetailsComponent__list-item'>
          <div className='AsmanCardAssetDetailsComponent__list-item--key'>
            Administrative
          </div>
          <div className='AsmanCardAssetDetailsComponent__list-item--value'>
            {data?.administrative &&
              data?.administrative.map(item => (
                <div
                  className='AsmanCardAssetDetailsComponent__list-item--value-label'
                  key={item}
                >
                  {item}
                </div>
              ))}
          </div>
        </div>
      </div>
    </SharedBaseCardTypeOneComponent>
  );
};
AsmanCardAssetDetailsComponent.displayName = 'AsmanCardAssetDetailsComponent';
