import React from 'react';

export const IconBaseMap = () => (
  <svg
    fill='none'
    height='1rem'
    viewBox='0 0 24 24'
    width='1rem'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_5244_27246)'>
      <path
        d='M16.166 13.153L16.1841 13.1412L18.1217 12.0032L16.5606 11.0872L12.3857 13.5426C12.2679 13.6127 12.1335 13.6497 11.9965 13.6497C11.8596 13.6497 11.7252 13.6127 11.6074 13.5426L7.43246 11.0872L5.87138 12.0032L7.8089 13.1412L7.82681 13.153L11.9921 15.5998L16.1574 13.153H16.166ZM16.166 8.97445L16.1841 8.96271L18.1237 7.82254L11.9996 4.22373L5.87553 7.82254L7.81409 8.96271L7.83227 8.97445L12.0017 11.4181L16.167 8.97125L16.166 8.97445ZM18.5596 9.91178L20.5088 11.0562C20.7276 11.2081 20.8872 11.4315 20.9603 11.6883C21.0334 11.9451 21.0155 12.2193 20.9097 12.4644C20.8316 12.6634 20.6908 12.8314 20.5088 12.9426H20.5026L18.5607 14.0871L20.5099 15.2315C20.7287 15.3834 20.8882 15.6068 20.9613 15.8636C21.0344 16.1203 21.0165 16.3946 20.9107 16.6397C20.8326 16.8387 20.6919 17.0067 20.5099 17.1179H20.5036L12.3932 21.8847C12.2756 21.9553 12.1409 21.9926 12.0038 21.9926C11.8667 21.9926 11.7323 21.9553 11.6146 21.8847L3.49563 17.1126C3.27604 16.9614 3.11604 16.738 3.04286 16.481C2.96968 16.224 2.98795 15.9493 3.09478 15.7045C3.1733 15.5057 3.31394 15.3378 3.49563 15.2262L5.44172 14.0871L3.49251 12.9416C3.27284 12.7907 3.11268 12.5675 3.03948 12.3106C2.96628 12.0537 2.9847 11.7792 3.09166 11.5345C3.16952 11.3348 3.31026 11.166 3.49251 11.0541L5.43315 9.91393L3.48395 8.76841C3.26451 8.61706 3.1046 8.39369 3.03143 8.13671C2.95827 7.87972 2.97648 7.60518 3.0831 7.36027C3.16162 7.16147 3.30225 6.99364 3.48395 6.882L11.5944 2.11527C11.712 2.04463 11.8467 2.00732 11.9838 2.00732C12.1209 2.00732 12.2553 2.04463 12.373 2.11527L20.492 6.8852C20.7107 7.0371 20.8703 7.26048 20.9434 7.51725C21.0165 7.77403 20.9986 8.0483 20.8928 8.29334C20.8141 8.49206 20.6735 8.65986 20.492 8.77162H20.4855L18.5448 9.91393L18.5596 9.91178ZM7.43661 15.2614L5.87657 16.1753L12.0007 19.7741L18.1248 16.1753L16.5647 15.2614L12.3898 17.7168C12.2724 17.7879 12.1378 17.8255 12.0007 17.8255C11.8635 17.8255 11.729 17.7879 11.6115 17.7168L7.43661 15.2614Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_5244_27246'>
        <rect fill='white' height='20' transform='translate(3 2)' width='18' />
      </clipPath>
    </defs>
  </svg>
);
