import { Tooltip } from 'antd';
import React from 'react';
import './MainMapMapControlButton.scss';

/**
 * @author Randa<m.randa@bvarta.com>
 * @description Default mainmap control button on right side of the map
 * @param {object} props
 * @param {React.ReactElement} props.children
 * @param {'middle'|'top'|'bottom'} props.position
 * @param {boolean} props.disabled
 * @param {string} props.title
 * @param {object} props.onClick
 * @returns
 */
export const MainMapMapControlButton = (props) => {
  const { children, disabled, onClick, position, title } = props;
  return (
    <Tooltip placement='left' title={title}>
      <button
        className={`feature-mainmap MainMapMapControlButton MainMapMapControlButton--${
          position || 'middle'
        } ${disabled && 'MainMapMapControlButton--disabled'}`}
        disabled={disabled}
        onClick={() => onClick && onClick()}
      >
        {children}
      </button>
    </Tooltip>
  );
};
