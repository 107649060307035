import React from 'react';

// Helper function to interpolate between two colors
const interpolateColor = (color1, color2, factor) => {
  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
  };

  const rgbToHex = (r, g, b) => {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
  };

  const [r1, g1, b1] = hexToRgb(color1);
  const [r2, g2, b2] = hexToRgb(color2);

  const r = Math.round(r1 + factor * (r2 - r1));
  const g = Math.round(g1 + factor * (g2 - g1));
  const b = Math.round(b1 + factor * (b2 - b1));

  return rgbToHex(r, g, b);
};

// Function to calculate evenly spaced gradient stops between colors
const generateGradientColors = (colors, numSquares = 4) => {
  const steps = numSquares - 1;
  const gradientColors = [];

  for (let i = 0; i < steps; i++) {
    const startColor = colors[Math.floor(i * (colors.length - 1) / steps)].color;
    const endColor = colors[Math.ceil(i * (colors.length - 1) / steps)].color;
    const factor = (i * (colors.length - 1)) % steps / steps;
    gradientColors.push(interpolateColor(startColor, endColor, factor));
  }

  gradientColors.push(colors[colors.length - 1].color); // Add the last color
  return gradientColors;
};

// Function to generate the linear gradient for the background
const generateBackgroundGradient = (colors) => {
  const gradientSteps = colors.map((c, i) => `${c.color} ${(i / (colors.length - 1)) * 100}%`).join(', ');
  return `linear-gradient(45deg, ${gradientSteps})`;
};

export const SquareGradientColor = (props) => {
  const { colorStops, borderColor, size } = props;

  // Handle empty colorStops by providing default fallback colors
  const fallbackColors = [
    { color: '#D6F2F0' }, // light green (very soft)
    { color: '#9CE0DB' }, // light-medium green
    { color: '#50B8B3' }, // medium green
    { color: '#07827C' }, // dark green (base color)
  ];

  const effectiveColors = colorStops.length > 0 ? colorStops : fallbackColors;

  const colors = generateGradientColors(effectiveColors, 4); // Generate dynamic gradient colors based on available steps

  const isGradient = effectiveColors.length <= 3; // Use gradient only if there are 3 or fewer colors
  const gradientBackground = isGradient ? generateBackgroundGradient(effectiveColors) : null;

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${borderColor || '#E5E5E5'}`,
    borderRadius: '6px',
    padding: '5px',
    backgroundColor: 'transparent',
  };

  const squareStyle = {
    width: size,
    height: size,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    backgroundColor: gradientBackground,
    borderRadius: '4px',
    gap: '1px',
    padding: '1px',
  };

  const squarePartStyle = {
    width: '100%',
    height: '100%',
  };

  return (
    <div style={containerStyle}>
      <div style={squareStyle}>
        {/* Render individual square colors if more than 3, otherwise use gradient */}
        <div style={{ ...squarePartStyle, backgroundColor: isGradient ? colors[3] : effectiveColors[3].color, borderTopLeftRadius: '4px' }} />
        <div style={{ ...squarePartStyle, backgroundColor: isGradient ? colors[2] : effectiveColors[2].color, borderTopRightRadius: '4px' }} />
        <div style={{ ...squarePartStyle, backgroundColor: isGradient ? colors[1] : effectiveColors[1].color, borderBottomLeftRadius: '4px' }} />
        <div style={{ ...squarePartStyle, backgroundColor: isGradient ? colors[0] : effectiveColors[0].color, borderBottomRightRadius: '4px' }} />
      </div>
    </div>
  );
};
