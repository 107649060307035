import { Button } from '@bvt-atoms';
import DynamicIcon from '@bvt-icons';
import React from 'react';
import './LayoutTopBarMainApp.scss';
import defaultPhoto from '@bvt-images/userDefaultPhoto.png';

/**
 * @author Ivan <tubagus.ivan@bvarta.com>
 * @param { object } props
 * @param { object } props.data
 * @param { string } props.data.userName
 * @param { string } props.data.userPhoto
 * @param { ()=>void } props.onLogoutClick
 */
export function LayoutTopBarMainApp(props) {
  const { data, onLogoutClick } = props;

  const userName = data.userName ? data.userName : 'User';
  const userPhoto = data.userPhoto ? data.userPhoto : defaultPhoto;

  return (
    <div className='LayoutTopBarMainApp'>
      <div className='LayoutTopBarMainApp__left-side'>
        <div className='LayoutTopBarMainApp__left-side-info'>
          <div className='LayoutTopBarMainApp__text-welcome'>Welcome!</div>
          <div className='LayoutTopBarMainApp__text-user'>{userName}</div>
          <img
            className='LayoutTopBarMainApp__display-photo'
            src={userPhoto}
          />
        </div>
      </div>
      <div className='LayoutTopBarMainApp__right-side'>
        <Button
          className='LayoutTopBarMainApp__action-logout'
          onClick={()=>onLogoutClick && onLogoutClick()}
        >
          <h5 className='LayoutTopBarMainApp__action-logout-text'>Logout</h5>
          <DynamicIcon
            className='LayoutTopBarMainApp__action-logout-icon'
            height='1rem'
            icon='logout'
            width='1rem'
          />
        </Button>
      </div>
    </div>
  );
}
