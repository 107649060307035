import React from 'react';
import { MapContainer, ScaleControl } from 'react-leaflet';
import { Outlet } from 'react-router';
import 'leaflet/dist/leaflet.css';


import { MainMapLeftSideLayout } from '../MainMapLeftSide.Layout';
import { MainMapTopSideLayout } from '../MainMapTopSide.Layout';
import { MainMapTileLayerContainer } from '../../container/MainMapTileLayer.container';
import { MainMapControlContainer } from '../../container/MainMapControl.container';
import { BaseMapControlContainer } from '../../container/BaseMapControl.container';
import { AdvanceSearchGeojsonContainer } from '@bvt-features/sub-module/advance-search/container/AdvanceSearchGeojson.container';
import { DEFAULT_MAP_OPTIONS_DATA_CONSTANT } from '@bvt-features/mainmap/mainmap/constant/DEFAULT_MAP_OPTIONS_DATA_CONSTANT';
import MenuBarContainer from '@bvt-features/mainmap/menu-bar/container/MenuBarContainer';
import ModuleBarContainer from '@bvt-features/mainmap/module-bar/container/ModuleBarContainer';

export const MainMapMapLayout = () => {
  return (
    <React.Fragment>
      {/* <MainAppContainer /> */}
      <MapContainer
        {...DEFAULT_MAP_OPTIONS_DATA_CONSTANT}
      >
        <MainMapTileLayerContainer />
        <MenuBarContainer />
        <ModuleBarContainer />
        <ScaleControl imperial position='bottomright' />

        <MainMapTopSideLayout />
        <MainMapLeftSideLayout />

        <MainMapControlContainer />

        <BaseMapControlContainer />

        <AdvanceSearchGeojsonContainer />
        <Outlet />
      </MapContainer>
    </React.Fragment>
  );
};
