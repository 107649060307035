import INTERACTIVE_MAP_CONSTANT from '../constant';

const initialState = {
  toggle: false,
  step_code: '',
  step_id: 0,
};

export function SIDEBAR_LEFT(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case INTERACTIVE_MAP_CONSTANT.SIDEBAR_LEFT:
      return { ...state, ...payload };
    case 'MapAsideLeft/setToggle':
      return { ...state, toggle: payload };
    case 'MapAsideLeft/setStepCode':
      return { ...state, step_code: payload };
    case 'MapAsideLeft/setStepId':
      return { ...state, step_id: payload };
    default:
      return state;
  }
}
