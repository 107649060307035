import Resource from '../../../../api/resource';

export class AsmanApiDatasource extends Resource {
  constructor() {
    super('', '/api', 'api-asman-telkom', null);
  }
  /**
     * Retrieves list of ASMAN assets based on provided parameters.
     * @param {Object} param - Parameters for filtering assets.
     * @param {number} [param.province_id] - Province ID.
     * @param {number} [param.regency_id] - Regency ID.
     * @param {number} [param.district_id] - District ID.
     * @param {number} [param.village_id] - Village ID.
     * @returns {Promise<FeatureCollection<Point, AsmanAssetInfo>>} - Promise resolving to GeoJSON feature collection of assets.
     */
  getListAsman(param) {
    const forgeBody = {
      province_id: param.province_id ? param.province_id: null,
      regency_id: param.regency_id ? param.regency_id: null,
      district_id: param.district_id ? param.district_id: null,
      village_id: param.village_id ? param.village_id: null,
    };
    this.setAdditionUri('de/get-poi-coollection-telkom');
    return this.create(forgeBody);
  }

  /**
     * Retrieves available radius information and status for analysis.
     * @param {Object} [query] - Optional parameters.
     * @param {string} [query.gsid] - Gsid for radius information.
     * @returns {Promise<{ radius_list?: Array<number>, detail?: string }>} - Promise resolving to object containing radius list and detail.
     */
  getAvailableRadiusInfoAndStatusAnalyze(query) {
    this.setAdditionUri(`de/radius_list/${query.gsid}`);
    return this.list({});
  }

  /**
     * Retrieves dashboard result and typing.
     * @param {Object} params - Parameters for the dashboard result.
     * @param {string} [params.query] - Query string.
     * @param {string} [params.gsid] - Gsid for dashboard result.
     * @returns {Promise<{
     *   asset_sima: FeatureCollection<Point, AssetSima>,
     *   site_radius: FeatureCollection<Polygon, SiteRadius>,
     *   poi_radius: FeatureCollection<Point, PoiRadius>,
     *   poi_property: FeatureCollection<Point, PoiProperty>
     * }>} - Promise resolving to object containing dashboard result.
     */
  getDashboardResult(params) {
    this.setAdditionUri(`de/analyze-result/${params.gsid}${params.query}`);
    return this.list({});
  }
}
