import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Faisal <ahmad.faisal@bvarta.com>
 * @param {Object} props
 * @param {string} props.className
 * @param {string} props.onClick
 */

export default function IconHide(props) {
  const { className, onClick } = props;

  return (
    <div className={`${className} iconHide`} onClick={onClick}>
      <svg
        height='19'
        viewBox='0 0 14 19'
        width='14'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M4.763.8A1,1,0,0,1,6.237.8l3.227,3.52A1,1,0,0,1,8.727,6H2.273a1,1,0,0,1-.737-1.676Z'
          data-name='Polygon 134'
          fill='#657991'
          id='Polygon_134'
          transform='translate(14 2.723) rotate(90)'
        />
        <rect
          data-name='Rectangle 4785'
          fill='#657991'
          height='16.5'
          id='Rectangle_4785'
          rx='1'
          width='4'
        />
      </svg>
    </div>
  );
}

IconHide.defaultProps = {
  className: '',
  onClick: () => {},
};

IconHide.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
