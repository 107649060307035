import * as React from 'react';
const UserPhotoOrnament = (props) => (
  <svg
    fill='none'
    height={96}
    viewBox='0 0 324 96'
    width={324}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx={96} cy={48} fill='#093D5B' opacity={0.5} r={48} />
    <circle cx={40} cy={48} fill='#093D5B' opacity={0.5} r={40} />
    <circle cx={228} cy={48} fill='#093D5B' opacity={0.5} r={48} />
    <circle cx={284} cy={48} fill='#093D5B' opacity={0.5} r={40} />
  </svg>
);
export { UserPhotoOrnament };
