import React, { useState } from 'react';
import { IconSearchTypeTwo } from '@bvt-assets/icon/icon-search-2';
import { Input } from 'antd';

// Custom Hook for handling dropdown search logic and rendering
export const useAreaSelection = (searchFunctions, classNameInput) => {
  // Define the initial state for each dropdown
  const [inputSearch, setInputSearch] = useState({
    province: '',
    city: '',
    district: '',
    village: '',
  });

  // Function to handle search change for any dropdown
  const handleSearchChange = (type, value) => {
    setInputSearch((prev) => ({ ...prev, [type]: value }));

    // Call the corresponding search function if defined
    if (searchFunctions[type]) {
      searchFunctions[type](value);
    }
  };

  // Function to render the dropdown with search functionality
  const createDropdownRender = (menu, value, type) => (
    <React.Fragment>
      <Input
        className={classNameInput}
        onChange={(e) => handleSearchChange(type, e.target.value)}
        placeholder='Search'
        suffix={<IconSearchTypeTwo />}
        value={value}
      />
      {menu}
    </React.Fragment>
  );

  // Dropdown render functions for each location type (province, city, district, village)
  const renderProvinceDropdown = (menu) => createDropdownRender(
    menu,
    inputSearch.province,
    'province',
  );

  const renderCityDropdown = (menu) => createDropdownRender(
    menu,
    inputSearch.city,
    'city',
  );

  const renderDistrictDropdown = (menu) => createDropdownRender(
    menu,
    inputSearch.district,
    'district',
  );

  const renderVillageDropdown = (menu) => createDropdownRender(
    menu,
    inputSearch.village,
    'village',
  );

  // Return all the functions needed for rendering
  return {
    renderProvinceDropdown,
    renderCityDropdown,
    renderDistrictDropdown,
    renderVillageDropdown,
    setInputSearch,
  };
};
