import React from 'react';

import './PopupLine.scss';

/**
 * @author Joko<joko.priyono@bvarta.com>
 * @author Randa<m.randa@bvarta.com>
 * @description Popup data for line datex telco data;
 * @param {object|undefined} props
 * @param {import('geojson').Polygon|undefined} props.feature
 * @param {string} props.id
 * @param {object} props.properties
 * @param {string} props.properties.origin
 * @param {string} props.properties.destination
 * @param {number} props.properties.flows
 */
export const PopupLine = (props) => {
  const { feature } = props;


  return (
    <div className='datex-popup-line'>
      <div className='datex-popup-line-body'>
        <div className='datex-popup-line-body-flow-padding'>
          <div className='datex-popup-line-body-flow-row'>
            <svg
              fill='none'
              height='16'
              viewBox='0 0 16 16'
              width='16'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle cx='8' cy='8' fill='#FF6700' r='7.5' stroke='#FF6700' />
            </svg>
            <div className='datex-popup-line-body-flow-text-bold'>
              Point {feature.properties.origin}
            </div>
            <div className='datex-popup-line-body-flow-text'>
              Count(s) : {feature.properties.flows}
            </div>
          </div>
        </div>
        <svg
          fill='none'
          height='24'
          viewBox='0 0 24 24'
          width='24'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            clipRule='evenodd'
            d='M12 0C12.375 0 12.679 0.279095 12.679 0.623377V21.8717L15.8409 18.9687C16.1061 18.7252 16.536 18.7252 16.8011 18.9687C17.0663 19.2121 17.0663 19.6068 16.8011 19.8503L12.4801 23.8174C12.215 24.0609 11.785 24.0609 11.5199 23.8174L7.19887 19.8503C6.93371 19.6068 6.93371 19.2121 7.19887 18.9687C7.46403 18.7252 7.89394 18.7252 8.1591 18.9687L11.321 21.8717V0.623377C11.321 0.279095 11.625 0 12 0Z'
            fill='#FF6700'
            fillRule='evenodd'
          />
        </svg>
        <div className='datex-popup-line-body-flow'>
          <div className='datex-popup-line-body-flow-row'>
            <svg
              fill='none'
              height='16'
              viewBox='0 0 16 16'
              width='16'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle cx='8' cy='8' r='7.5' stroke='#FF6700' />
            </svg>
            <div className='datex-popup-line-body-flow-text-bold'>
              Point {feature.properties.destination}
            </div>
            <div className='datex-popup-line-body-flow-text'>Count(s) : -</div>
          </div>
        </div>
      </div>
    </div>
  );
};
