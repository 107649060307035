/* eslint-disable */
import { ActivityService } from '../../service/activityService';
import { ACTIVITY_CONSTANT } from './ACTIVITY_CONSTANT';

const activityService = new ActivityService();

export const ACTIVITY_ACTION = {
  chartByModule: (query) => {
    return async (dispatch) => {
      dispatch({
        type: ACTIVITY_CONSTANT.BY_MODULE_REQUESTED,
        payload: {
          status_BY_MODULE_CHART: 'LOADING',
        },
      });
      try {
        let response = await activityService.by_module(query);
        dispatch({
          type: ACTIVITY_CONSTANT.BY_MODULE_SUCCESS,
          payload: {
            status_BY_MODULE_CHART: 'SUCCESS',
            chartByModule: response.result.filter(
              (el) => el.module_name !== 'Login'
            ),
            message: response.message,
          },
        });
      } catch (error) {
        dispatch({
          type: ACTIVITY_CONSTANT.BY_MODULE_FAILED,
          payload: {
            status_BY_MODULE_CHART: 'FAILED',
            message: error.response.data.message,
          },
        });
      }
    };
  },
  byModule: (query) => {
    return async (dispatch) => {
      dispatch({
        type: ACTIVITY_CONSTANT.BY_MODULE_REQUESTED,
        payload: {
          status_BY_MODULE: 'LOADING',
        },
      });
      try {
        let response = await activityService.by_module(query);
        dispatch({
          type: ACTIVITY_CONSTANT.BY_MODULE_SUCCESS,
          payload: {
            status_BY_MODULE: 'SUCCESS',
            byModule: response.result.filter(
              (el) => el.module_name !== 'Login'
            ),
            message: response.message,
          },
        });
      } catch (error) {
        dispatch({
          type: ACTIVITY_CONSTANT.BY_MODULE_FAILED,
          payload: {
            status_BY_MODULE: 'FAILED',
            message: error.response.data.message,
          },
        });
      }
    };
  },
  byTime: (query) => {
    return async (dispatch) => {
      dispatch({
        type: ACTIVITY_CONSTANT.BY_MODULE_REQUESTED,
        payload: {
          status_BY_TIME: 'LOADING',
        },
      });
      try {
        let response = await activityService.by_time(query);
        dispatch({
          type: ACTIVITY_CONSTANT.BY_MODULE_SUCCESS,
          payload: {
            status_BY_TIME: 'SUCCESS',
            byTime: response.result.total_activity_by_time,
            message: response.message,
          },
        });
      } catch (error) {
        dispatch({
          type: ACTIVITY_CONSTANT.BY_MODULE_FAILED,
          payload: {
            status_BY_TIME: 'FAILED',
            message: error.response.data.message,
          },
        });
      }
    };
  },
  resetStatus: () => {
    return (dispatch) => {
      dispatch({ type: ACTIVITY_CONSTANT.RESET_STATUS });
    };
  },
  resetAll: () => {
    return (dispatch) => {
      dispatch({ type: ACTIVITY_CONSTANT.RESET_ALL });
    };
  },
};
