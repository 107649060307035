import * as React from "react"

export const IconHighlight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
  >
    <path
      d="m15.5 14.793-2.345-2.344a4.014 4.014 0 1 0-.707.707l2.345 2.344.707-.707ZM10 13a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm-6 0H2a1.001 1.001 0 0 1-1-1v-2h1v2h2v1ZM1 6h1v2H1V6Zm12-2h-1V2h-2V1h2a1.001 1.001 0 0 1 1 1v2ZM6 1h2v1H6V1ZM2 4H1V2a1 1 0 0 1 1-1h2v1H2v2Z"
      fill="currentColor"
    />
  </svg>
)
