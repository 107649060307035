import { GensetPOIService } from '../service';
import { GENSET_POI_CONSTANT } from './GENSET_POI_CONSTANT';

const poiService = new GensetPOIService();

export const GENSET_POI_ACTIONS = {
  get: (query) => {
    return async (dispatch) => {
      dispatch({
        type: GENSET_POI_CONSTANT.GET_START,
        payload: { status_GET: 'LOADING' },
      });
      try {
        const DATA = await poiService.getAllPOI(query);
        dispatch({
          type: GENSET_POI_CONSTANT.GET_SUCCESS,
          payload: {
            status_GET: 'SUCCESS',
            list: DATA,
            message: DATA.message,
            property: DATA.meta,
          },
        });
      } catch (error) {
        dispatch({
          type: GENSET_POI_CONSTANT.GET_FAILED,
          payload: {
            status_GET: 'FAILED',
            message: error.response.data.message,
          },
        });
      }
    };
  },
  detail: (id) => {
    return async (dispatch) => {
      dispatch({
        type: GENSET_POI_CONSTANT.DETAIL_START,
        payload: { status_DETAIL: 'LOADING' },
      });
      try {
        const data = await poiService.getPOIById(id);
        dispatch({
          type: GENSET_POI_CONSTANT.DETAIL_SUCCESS,
          payload: {
            status_DETAIL: 'SUCCESS',
            detail: data.result,
            message: data.message,
          },
        });
      } catch (error) {
        dispatch({
          type: GENSET_POI_CONSTANT.DETAIL_FAILED,
          payload: {
            status_DETAIL: 'FAILED',
            message: error.response.data.message,
          },
        });
      }
    };
  },
  create: (data) => {
    return async (dispatch) => {
      dispatch({
        type: GENSET_POI_CONSTANT.CREATE_START,
        payload: { status_POST: 'LOADING' },
      });
      try {
        const postData = await poiService.createPOI(data);
        dispatch({
          type: GENSET_POI_CONSTANT.CREATE_SUCCESS,
          payload: {
            status_POST: 'SUCCESS',
            message: postData.message,
          },
        });
      } catch (error) {
        dispatch({
          type: GENSET_POI_CONSTANT.CREATE_FAILED,
          payload: {
            status_POST: 'FAILED',
            message: JSON.parse(error.request.response).message
              ? JSON.parse(error.request.response).message.replace('Error Message : ', '')
              : 'Validation Error',
          },
        });
      }
    };
  },
  update: (id, body) => {
    return async (dispatch) => {
      dispatch({
        type: GENSET_POI_CONSTANT.UPDATE_START,
        payload: { status_PUT: 'LOADING' },
      });
      try {
        const data = await poiService.updatePOI(id, body);
        dispatch({
          type: GENSET_POI_CONSTANT.UPDATE_SUCCESS,
          payload: {
            status_PUT: 'SUCCESS',
            message: data.message,
          },
        });
      } catch (error) {
        dispatch({
          type: GENSET_POI_CONSTANT.UPDATE_FAILED,
          payload: {
            status_PUT: 'FAILED',
            message: error.response.data.message
              ? error.response.data.message.replace('Error Message : ', '')
              : 'Validation Error',
          },
        });
      }
    };
  },
  delete: (id) => {
    return async (dispatch) => {
      dispatch({
        type: GENSET_POI_CONSTANT.DELETE_START,
        payload: { status_DELETE: 'LOADING' },
      });
      try {
        const DATA = await poiService.deletePOI(id);
        dispatch({
          type: GENSET_POI_CONSTANT.DELETE_SUCCESS,
          payload: {
            status_DELETE: 'SUCCESS',
            message: DATA.message,
          },
        });
      } catch (error) {
        dispatch({
          type: GENSET_POI_CONSTANT.DELETE_FAILED,
          payload: {
            status_DELETE: 'FAILED',
            message: error.response.data.message,
          },
        });
      }
    };
  },
  download: (payload, id) => {
    return async (dispatch) => {
      dispatch({
        type: GENSET_POI_CONSTANT.DOWNLOAD_START,
        payload: { status_DOWNLOAD: 'LOADING' },
      });
      try {
        const data = await poiService.downloadPOI(
          {
            arrayID: payload,
          },
          id
        );
        const url = window.URL.createObjectURL(new Blob([data]));
        dispatch({
          type: GENSET_POI_CONSTANT.DOWNLOAD_SUCCESS,
          payload: {
            status_DOWNLOAD: 'SUCCESS',
            message: data.message,
            url: url,
          },
        });
      } catch (error) {
        dispatch({
          type: GENSET_POI_CONSTANT.DOWNLOAD_FAILED,
          payload: {
            status_DOWNLOAD: 'FAILED',
            message: error.response.data?.message || 'Download failed',
          },
        });
      }
    };
  },
  download_dot: (payload, id) => {
    return async (dispatch) => {
      dispatch({
        type: GENSET_POI_CONSTANT.DOWNLOAD_START,
        payload: { status_DOWNLOAD_DOT: 'LOADING' },
      });
      try {
        const data = await poiService.downloadPOI(
          {
            arrayID: payload,
          },
          id
        );
        const url = window.URL.createObjectURL(new Blob([data]));
        dispatch({
          type: GENSET_POI_CONSTANT.DOWNLOAD_SUCCESS,
          payload: {
            status_DOWNLOAD_DOT: 'SUCCESS',
            message: data.message,
            url: url,
          },
        });
      } catch (error) {
        dispatch({
          type: GENSET_POI_CONSTANT.DOWNLOAD_FAILED,
          payload: {
            status_DOWNLOAD_DOT: 'FAILED',
            message: error.response.data?.message || 'Download failed',
          },
        });
      }
    };
  },
  uploadPOI: (payload, id) => {
    return async (dispatch) => {
      dispatch({
        type: GENSET_POI_CONSTANT.UPLOAD_START,
        payload: { status_UPLOAD: 'LOADING' },
      });
      try {
        const data = await poiService.uploadPOI(payload, id);
        dispatch({
          type: GENSET_POI_CONSTANT.UPLOAD_SUCCESS,
          payload: {
            status_UPLOAD: 'SUCCESS',
            message: data.message,
          },
        });
      } catch (error) {
        const errorResponse = error?.response;
        const errorMessage = {
          type: 'Make sure the file is in .txt format, please review and reupload a new file.',
          name: 'A file with this name already exists. Please rename and reupload the file.',
          data: 'The file contains some incorrect information, please review and reupload a new file.',
          parameter: 'The file contains some incorrect information, please review and reupload a new file.',
        };
        const explain = errorResponse?.data?.explain?.[0];
        const field = explain?.field;
        const message = errorResponse?.status === 500
          ? 'The file contains some incorrect information, please review and reupload a new file.'
          : errorResponse?.data?.message;

        dispatch({
          type: GENSET_POI_CONSTANT.UPLOAD_FAILED,
          payload: {
            status_UPLOAD: 'FAILED',
            message: field && errorMessage[field]
              ? errorMessage[field]
              : message,
            errorUploadField: errorResponse?.status === 500 ? 'data' : field,
          },
        });
      }
    };
  },
  renamePOI: (payload, id) => {
    return async (dispatch) => {
      dispatch({
        type: GENSET_POI_CONSTANT.PATCH_START,
        payload: { status_PATCH: 'LOADING' },
      });
      try {
        const data = await poiService.renamePOI(payload, id);
        dispatch({
          type: GENSET_POI_CONSTANT.PATCH_SUCCESS,
          payload: {
            status_PATCH: 'SUCCESS',
            message: data.message,
          },
        });
      } catch (error) {
        dispatch({
          type: GENSET_POI_CONSTANT.PATCH_FAILED,
          payload: {
            status_PATCH: 'FAILED',
            message:
              error.response.data && error.response.data.explain.length > 0
                ? error.response.data.explain[0].rule
                : error.response.data.message,
          },
        });
      }
    };
  },
  reset_status: () => {
    return (dispatch) => {
      dispatch({ type: GENSET_POI_CONSTANT.RESET_STATUS });
    };
  },
  reset_all: () => {
    return (dispatch) => {
      dispatch({ type: GENSET_POI_CONSTANT.RESET_ALL });
    };
  },
};
