import React from 'react';
import Page from '../../../pages/bvt';
import { AuthGateContainer } from '@bvt-features/auth/container';
import { BasePageHeadTag } from '@bvt-shared/component/BasePageHeadTag';

/** @type {import('react-router').RouteObject} */
export const RoutesPreviewPDF = {
  path: 'preview-pdf',
  element: (
    <>
      <BasePageHeadTag title='LI :: Preview PDF' />
      <AuthGateContainer />
    </>
  ),
  children: [
    {
      index: true,
      element: <Page.PreviewPdf/>,
    },
  ],
};
