import INTERACTIVE_MAP_CONSTANT from '../constant';

const initialState = {
  bounds: null,
  active: false,
};

export function ZOOM_BOX(state = initialState, action) {
  switch (action.type) {
    case INTERACTIVE_MAP_CONSTANT.ZOOM_BOX:
      return action.payload;
    case 'SET_BOUNDS':
      return {
        ...state,
        bounds: action.payload,
      };
    case 'SET_MOUSE_ENTER':
      return {
        ...state,
        active: action.payload,
      };

    default:
      return state;
  }
}
