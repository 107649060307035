import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Faisal <ahmad.faisal@bvarta.com>
 * @param {Object} props
 * @param {string} props.className
 * @param {string} props.disable
 * @param {string} props.onClick
 */

export default function IconRoute(props) {
  const { className, disable, onClick } = props;

  return (
    <div
      className={`${className} icon-route ${disable ? 'disable' : ''}`}
      onClick={onClick}
    >
      <svg
        height='21'
        viewBox='0 0 21 21'
        width='21'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g id='ic-contact-edit' transform='translate(0 0.126)'>
          <rect
            data-name='Rectangle 86'
            fill='none'
            height='21'
            id='Rectangle_86'
            transform='translate(0 -0.126)'
            width='21'
          />
          <g id='Layer_x0020_1' transform='translate(1.278 0.354)'>
            <g id='_2135162654096' transform='translate(-0.01)'>
              <g data-name='Group 9339' id='Group_9339'>
                <path
                  d='M3.351,2.68A3.3,3.3,0,0,1,6.666,6a3.376,3.376,0,0,1-2.3,3.177c-.737,1.2-1.059,1.98-2.072,0A3.327,3.327,0,0,1,3.305,2.68Zm0,2.164A1.105,1.105,0,1,1,2.246,5.949,1.12,1.12,0,0,1,3.351,4.844Z'
                  data-name='Path 3425'
                  fill='#364961'
                  fillRule='evenodd'
                  id='Path_3425'
                  transform='translate(0.01 9.66)'
                />
                <path
                  d='M5.911,0A3.3,3.3,0,0,1,9.226,3.315a3.376,3.376,0,0,1-2.3,3.177c-.737,1.2-1.059,1.98-2.072,0A3.327,3.327,0,0,1,5.865,0Zm0,2.164A1.105,1.105,0,1,1,4.806,3.269,1.12,1.12,0,0,1,5.911,2.164Z'
                  data-name='Path 3426'
                  fill='#364961'
                  fillRule='evenodd'
                  id='Path_3426'
                  transform='translate(9.237)'
                />
              </g>
              <path
                d='M8.848.75a1.243,1.243,0,0,1,0,2.486H4.014a1.427,1.427,0,1,0,0,2.855h9.669a3.914,3.914,0,1,1,0,7.828H8.848a1.243,1.243,0,1,1,0-2.486h4.835a1.427,1.427,0,1,0,0-2.855H4.014a3.914,3.914,0,1,1,0-7.828H8.848Z'
                data-name='Path 3427'
                fill='#364961'
                id='Path_3427'
                transform='translate(0.406 2.703)'
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

IconRoute.defaultProps = {
  className: '',
  disable: false,
  onClick: () => {},
};

IconRoute.propTypes = {
  className: PropTypes.string,
  disable: PropTypes.bool,
  onClick: PropTypes.func,
};
