import Resource from '../../../api/resource';

export class UserService extends Resource {
  constructor() {
    super('users', '/api', 'auth', 'v2');
  }
  updatePassword(body) {
    const remapData = {
      password: body.password,
    };
    this.setAdditionUri('/reset-password');
    return this.update('', remapData);
  }
}
