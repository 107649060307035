import React, { Fragment, useLayoutEffect, useState } from 'react';
import { SearchCity } from './SearchCity/SearchCity';
import { SearchProvince } from './SearchProvince/SearchProvince';
import { SearchDistrict } from './SearchDistrict/SearchDistrict';
import { SearchVillage } from './SearchVillage/SearchVillage';
import { useMap } from 'react-leaflet';
import PropTypes from 'prop-types';
import { usePlace } from '../../hooks/usePlace';
import { isEmpty } from 'lodash';
import './MapAdvancedSearch.scss';
import { ID_SOURCE_CONSTANT } from '@bvt-shared/constant/ID_SOURCE_CONSTANT';
import { SearchProvinceInter } from './SearchProvinceInter/SearchProvinceInter';
import { SearchCityInter } from './SearchCityInter/SearchCityInter';
import { SearchDistrictInter } from './SearchDistrictInter/SearchDistrictInter';

const IconCloseTemporary = () => {
  return (
    <svg
      className='close-advanced'
      height='1rem'
      viewBox='0 0 12.263 12.263'
      width='1rem'
    >
      <path
        d='M3.153,7.489V5.518H1.183a1.182,1.182,0,1,1,0-2.364h1.97V1.183a1.183,1.183,0,1,1,2.365,0V3.154H7.489a1.182,1.182,0,1,1,0,2.364H5.518V7.489a1.183,1.183,0,1,1-2.365,0Z'
        data-name='Union 24'
        fill='currentColor'
        id='Union_24'
        transform='translate(6.132) rotate(45)'
      />
    </svg>
  );
};

export default function MapAdvancedSearch(props) {
  const { isAdvanced, advanced } = props;
  const [province, isProvince] = useState(null);
  const [city, isCity] = useState(null);
  const [district, isDistrict] = useState(null);
  const [village, isVillage] = useState(null);
  const initialMap = useMap();

  const PLACE = usePlace();

  useLayoutEffect(() => {
    if (advanced === true) {
      initialMap.dragging.disable();
      initialMap.doubleClickZoom.disable();
      initialMap.scrollWheelZoom.disable();
    } else {
      initialMap.dragging.enable();
      initialMap.doubleClickZoom.enable();
      initialMap.scrollWheelZoom.enable();
    }
  }, [advanced, province, city, district, village]);

  const getData = () => {
    const countryId = PLACE.state.country_mandala.country_id;
    if(countryId  === 1){
      const params = {
        meta: {
          filter: {
            id_province: PLACE.state.params.provinsi?.id_provinsi,
            id_city: PLACE.state.params.kota?.id_kota,
            id_district: PLACE.state.params.kecamatan?.id_kecamatan,
            id_village: PLACE.state.params.desa?.id_desa,
            id_source: ID_SOURCE_CONSTANT,
          },
        },
      };
      PLACE.get_advance({ params });
    }else {
      let keyRegion = {};
      if(countryId === 2){
        keyRegion['provinsi'] = 'region_id';
        keyRegion['kota'] = 'prefecture_id';
        keyRegion['kecamatan'] = 'district_id';
      }else{
        keyRegion['provinsi'] = 'province_id';
        keyRegion['kota'] = 'district_id';
        keyRegion['kecamatan'] = 'commune_id';
      }
      const params = {
        meta: {
          filter: {
            country_id :countryId,
            [keyRegion['provinsi']]: PLACE.state.params.provinsi?.id,
            ...(PLACE.state.params.kota?.id && { [keyRegion['kota']]: PLACE.state.params.kota.id }),
            ...(PLACE.state.params.kecamatan?.id && { [keyRegion['kecamatan']]: PLACE.state.params.kecamatan.id })
          },
        },
      };
      PLACE.get_advance_inter({ params });
    }
  };

  return (
    <div className='bvt-advanced'>
      <div className='bvt-advanced__card'>
        <div>
          <div className='bvt-advanced__card__header'>
            <div className='bvt-advanced__card__header__title'>
              Advanced searching
            </div>
            <button
              className='bvt-advanced__card__header__button'
              onClick={() => isAdvanced(false)}
            >
              <IconCloseTemporary />
            </button>
          </div>
        </div>
        <div className='bvt-advanced__card--dropdown-container'>
          {process.env.MODE === 'production' || PLACE.state.country_mandala.country_id === 1 ?
            <Fragment>
              <SearchProvince
                isProvince={isProvince}
                province={province}
                validation={true}
              />
              <SearchCity
                data={city}
                idProvince={province?.id_provinsi}
                isCity={isCity}
                validation={true}
              />
              <SearchDistrict
                data={district}
                idCity={city?.id_kota}
                isDistrict={isDistrict}
              />
              <SearchVillage
                data={village}
                idDistrict={district?.id_kecamatan}
                isVillage={isVillage}
              />
            </Fragment>

            :

            <Fragment>
              <SearchProvinceInter
                isProvince={isProvince}
                province={province}
                show='all'
              />
              <SearchCityInter
                data={city}
                idProvince={province?.id}
                isCity={isCity}
                show='all'
              />
              <SearchDistrictInter
                data={district}
                idCity={city?.id}
                isDistrict={isDistrict}
              />
            </Fragment>
          }

        </div>
        <div className='bvt-advanced__card__footer'>
          <div
            className='bvt-advanced__card__footer__button__active'
            onClick={() => isAdvanced(false)}
          >
            Cancel
          </div>
          <div
            className={
              !isEmpty(province)
                ? 'bvt-advanced__card__footer__button__active'
                : 'bvt-advanced__card__footer__button'
            }
            onClick={() => {
              if (!isEmpty(province)) {
                getData();
                isAdvanced(false);
              }
            }}
          >
            Apply
          </div>
        </div>
      </div>
    </div>
  );
}

MapAdvancedSearch.defaultProps = {
  advanced: false,
  isAdvanced: undefined,
};

MapAdvancedSearch.propTypes = {
  advanced: PropTypes.bool,
  isAdvanced: PropTypes.func,
};
