import { createSlice } from '@reduxjs/toolkit';


/**
 * @copyright PT Bhumi Varta Techonology
 * @author Malik<malik.ibrahim@bvarta.com>
 */

/**
 * @description For step tour data
 * @typedef TDataTourStep
 * @type {Object}
 * @property {number} id
 * @property {number} order
 * @property {string} selector
 * @property {object} content
 * @property {string} content.description
 * @property {string} content.position for arrow card
 * @property {string} content.showArrow
 * @property {string} content.subtitle
 * @property {string} content.title
 * @property {string} position for card
 * @property {StyleSheet} style for card
*/

/**
 * @description For tour Object
 * @typedef TDataTourObject
 * @type {Object}
 * @property {number} id
 * @property {string} name
 * @property {string} path
 * @property {Boolean} isFinished
 * @property {Array<TDataTourStep>} tourStep
*/

/**
 * @typedef initialState
 * @type {object}
 * @property {Array<TDataTourObject>} tourList
 * @property {TDataTourObject} currentTour
 * @property {boolean} isOpen
 */

/**
 * @type {initialState}
 */
const initialState  = {
  tourList: [],
  currentTour: null,
  isOpen: false,
};

export const tour_slice = createSlice({
  name: 'LI/tours',
  initialState,
  reducers: {
    /**
     * @param {object} action
     * @param {TDataTourStep} action.payload
     */
    addTour(state, action) {
      const { payload } = action;
      state.tourList.push(payload);
    },
    /**
     * @param {object} action
     * @param {TDataTourStep} action.payload
     */
    setCurrentTour(state, action) {
      const { payload } = action;
      state.currentTour = payload;
    },
    /**
     * @param {object} action
     * @param {number} action.id
     */
    startTour(state) {
      state.isOpen = true;
    },
    /**
     * @param {object} action
     * @param {number} action.id
     */
    stopTour(state) {
      state.isOpen = false;
    },

    /**
     * @param {object} action
     * @param {number} action.id
     */
    finishTour(state, action){
      const { payload }  = action;
      const newState = state.tourList.map(el => {
        if(el.id === payload.id) {
          return { ...el, isFinished: true };
        }else{
          return el;
        }
      });
      state.tourList = newState;
      state.currentTour = null;
    },

    flushLocalStep(state){
      state.tourList.map(el => {
        localStorage.removeItem(el.name);
      });
    },

    resetState(state){
      state.tourList = [];
      state.currentTour = null;
      state.isOpen = false;
    },
  },
});

export const  { reducer: TOUR_REDUCER, actions: TOUR_ACTION } = tour_slice;
