import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

/**
 * @version 1.0
 * @copyright PT Bhumi Varta Technology
 * @memberof atoms
 * @author Renta <renta.yustika@bvarta.com>
 * @param {Object} props
 * @param {Object} props.options
 */

export default function Chart(props) {
  return <HighchartsReact highcharts={Highcharts} options={props.options} />;
}

Chart.defaultProps = {
  options: {},
};

Chart.propTypes = {
  options: PropTypes.object,
};
