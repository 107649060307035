import { combineReducers } from 'redux';
import { MAPP_PROJECT_REDUCER } from '../project/redux';
import { MAPP_PROJECT_STEP_HISTORY_REDUCER } from '../projectStepHistory/redux';
import { STEP_REDUCER } from '../step/redux';
import { MAPP_TASK_ANALYZE_REDUCER } from '../taskAnalyze/redux';
import { MAIN_OPEN_PROJECT_REDUCER, MAIN_OPEN_REPORT_REDUCER } from '../mainOpen/store/mainOpen.slice';

export const MAPP_REDUCER = combineReducers({
  OPEN_PROJECT: MAIN_OPEN_PROJECT_REDUCER,
  OPEN_REPORT: MAIN_OPEN_REPORT_REDUCER,
  PROJECT: MAPP_PROJECT_REDUCER,
  PROJECT_STEP_HISTORY: MAPP_PROJECT_STEP_HISTORY_REDUCER,
  TASK_ANALYZE: MAPP_TASK_ANALYZE_REDUCER,
  STEP: STEP_REDUCER,
});
