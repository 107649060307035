import React from 'react';
import propTypes from 'prop-types';
import bg from '@bvt-images/authBackground.png';
import logoli from '@bvt-images/logoLi.png';
import logoBVTLong from '@bvt-images/logoBVTLong.png';
import moment from 'moment';
import './AuthLayout.scss';

export default function AuthLayout(props) {
  const { children } = props;
  return (
    <div className='AuthLayout feature-auth AuthLayout__container'>
      <img
        alt='logo lokasi intellegence'
        className='feature-auth AuthLayout__logo-li'
        src={logoli}
      />
      <img
        alt='auth background'
        className='feature-auth AuthLayout__background'
        src={bg}
      />
      <div className='feature-auth AuthLayout__card'>{children}</div>
      {/* <div className='feature-auth AuthLayout__version-text'>Version {process.env.VERSION}</div> */}
      <img
        alt={'logo bhumi varta tech'}
        className='feature-auth AuthLayout__logo-bvt'
        src={logoBVTLong}
      />
      <div className='feature-auth AuthLayout__version-text'>
        © {moment().year()} PT. BHUMI VARTA TECHNOLOGY
        {process.env.FE_REGION && <div>{process.env.FE_REGION}</div>}
      </div>
    </div>
  );
}
AuthLayout.propTypes = {
  children: propTypes.oneOfType([propTypes.element]),
};
