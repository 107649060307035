import { TaskAnalyzeService } from '../service/taskAnalyzeService';
import { MAPP_TASK_ANALYZE_CONSTANT } from './MAPP_TASK_ANALYZE_CONSTANT';

const svc = new TaskAnalyzeService();
export const MAPP_TASK_ANALYZE_ACTION = {
  /**
   * @author Randa<m.randa@bvarta.com>
   * @copyright PT Bhumi Techonology
   * @param {object} param
   * @param {object} param.filter
   * @param {number} param.limit
   * @param {number} param.page
   * @param {object} param.search
   * @param {'ASC'|'DESC'} param.sort
   * @param {'ASC'|'DESC'} param.sort.by
   * @param {string} param.sort.name
   */
  getListTask: (param) => {
    return async (dispatch) => {
      dispatch({
        type: MAPP_TASK_ANALYZE_CONSTANT.GET_LIST_START,
        payload: {
          status_GET: 'LOADING',
        },
      });
      try {
        let data = await svc.doGetTask(param);
        dispatch({
          type: MAPP_TASK_ANALYZE_CONSTANT.GET_LIST_SUCCESS,
          payload: {
            status_GET: 'SUCCESS',
            message: data.message,
            data: data.results || [],
            meta: param,
          },
        });
      } catch (error) {
        dispatch({
          type: MAPP_TASK_ANALYZE_CONSTANT.GET_LIST_FAILED,
          payload: {
            status_GET: 'FAILED',
            message:
              error?.response?.data?.message ||
              error?.message ||
              'Unknown Error',
          },
        });
      }
    };
  },
  /**
   * @param {string | number} jobId
   */
  deleteOneTask: (jobId) => {
    return async (dispatch) => {
      dispatch({
        type: MAPP_TASK_ANALYZE_CONSTANT.DELETE_ONE_START,
        payload: {
          status_DELETE: 'LOADING',
        },
      });
      try {
        let data = await svc.doDeleteOneTask(jobId);
        dispatch({
          type: MAPP_TASK_ANALYZE_CONSTANT.DELETE_ONE_SUCCESS,
          payload: {
            status_DELETE: 'SUCCESS',
            message: data.message,
          },
        });
      } catch (error) {
        dispatch({
          type: MAPP_TASK_ANALYZE_CONSTANT.DELETE_ONE_FAILED,
          payload: {
            status_DELETE: 'FAILED',
            message:
              error?.response?.data?.message ||
              error?.message ||
              'Unknown Error',
          },
        });
      }
    };
  },
  /**
   * @param {string | number} jobId
   * @param {string} type
   */
  cancelOneTask: (jobId, type) => {
    return async (dispatch) => {
      dispatch({
        type: MAPP_TASK_ANALYZE_CONSTANT.CANCEL_ONE_START,
        payload: {
          status_DELETE: 'LOADING',
        },
      });
      try {
        let data = await svc.doCancelOneTask(jobId, type);
        await new Promise((resolve) =>
          setTimeout(() => {
            resolve();
          }, 1000)
        );
        dispatch({
          type: MAPP_TASK_ANALYZE_CONSTANT.CANCEL_ONE_SUCCESS,
          payload: {
            status_DELETE: 'SUCCESS',
            message: data.message,
          },
        });
      } catch (error) {
        dispatch({
          type: MAPP_TASK_ANALYZE_CONSTANT.CANCEL_ONE_FAILED,
          payload: {
            status_DELETE: 'FAILED',
            message:
              error?.response?.data?.message ||
              error?.message ||
              'Unknown Error',
          },
        });
      }
    };
  },
  /**
   * @param {boolean} param
   */
  setPopupVisibility: (param) => {
    return (dispatch) => {
      dispatch({
        type: MAPP_TASK_ANALYZE_CONSTANT.TOGGLE_POPUP,
        payload: { isPopUpVisible: param },
      });
    };
  },
};
