import { MAPP_TASK_ANALYZE_CONSTANT } from './MAPP_TASK_ANALYZE_CONSTANT';
const initialState = {
  status_GET: 'IDLE',
  status_POST: 'IDLE',
  status_PUT: 'IDLE',
  status_DELETE: 'IDLE',
  message: '',
  data: [],
  meta: {},
  properties: {},
  isPopUpVisible: false,
};

export function MAPP_TASK_ANALYZE_REDUCER(state = initialState, action) {
  let returnData = state;
  Object.values(MAPP_TASK_ANALYZE_CONSTANT).map((ctx) => {
    if (action.type === MAPP_TASK_ANALYZE_CONSTANT.RESET_STATUS) {
      returnData = {
        ...returnData,
        status_GET: 'IDLE',
        status_POST: 'IDLE',
        status_PUT: 'IDLE',
        status_DELETE: 'IDLE',
        message: ' ',
        meta: {},
      };
    } else if (
      action.type === MAPP_TASK_ANALYZE_CONSTANT.UNAUTHORIZED ||
      action.type === MAPP_TASK_ANALYZE_CONSTANT.RESET_ALL
    ) {
      returnData = {
        status_GET: 'IDLE',
        status_POST: 'IDLE',
        status_PUT: 'SUCCESS',
        status_DELETE: 'IDLE',
        message: ' ',
        data: [],
        meta: {},
      };
    } else if (ctx === action.type && !ctx.includes('[NO_STATE_UPDATE]')) {
      returnData = { ...returnData, ...action.payload };
    }
    return null;
  });

  return returnData;
}
