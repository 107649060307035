import INTERACTIVE_PROJECT_CONSTANT from './constant';

const initialState = {
  name: '',
  project_type: '',
  history: {
    form_set: {},
    analyze_result: {},
    id_step: 0,
  },
};

export function TEMP_PROJECT(state = initialState, action) {
  if (action.type === INTERACTIVE_PROJECT_CONSTANT.TEMP_PROJECT) {
    return action.payload;
  } else {
    return state;
  }
}
