import * as React from 'react';

const IconUser = (props) => (
  <svg
    fill='none'
    height={12}
    viewBox='0 0 12 12'
    width={12}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10.3666 8.17324C9.81065 7.59243 9.13574 7.12888 8.38446 6.81181C7.63317 6.49475 6.8218 6.33105 6.00154 6.33105C5.18128 6.33105 4.36992 6.49475 3.61863 6.81181C2.86734 7.12888 2.19243 7.59243 1.63647 8.17324C1.55006 8.26448 1.50149 8.38313 1.5 8.50662V10.5069C1.50182 10.6383 1.5573 10.7637 1.65441 10.8559C1.75152 10.9482 1.88247 10.9999 2.01889 10.9999H9.98111C10.0493 10.9999 10.1167 10.987 10.1797 10.9619C10.2426 10.9368 10.2998 10.9 10.348 10.8536C10.3962 10.8072 10.4344 10.7521 10.4605 10.6915C10.4866 10.6308 10.5 10.5659 10.5 10.5002V8.49994C10.4977 8.37915 10.4503 8.2632 10.3666 8.17324Z'
      fill='#6C757D'
    />
    <path
      d='M8.42372 3.33368C8.42372 3.79527 8.28158 4.24649 8.01527 4.63028C7.74896 5.01407 7.37045 5.31318 6.92761 5.48979C6.48476 5.6664 5.99748 5.71257 5.52739 5.62246C5.0573 5.53236 4.62551 5.31002 4.28664 4.98358C3.94777 4.65713 3.71703 4.24124 3.62362 3.7885C3.5302 3.33576 3.5783 2.86652 3.76183 2.4401C3.94536 2.01369 4.25608 1.64927 4.65469 1.39294C5.0533 1.1366 5.52189 0.999853 6.0012 1C6.6439 1 7.26027 1.24587 7.71473 1.68352C8.16918 2.12117 8.42449 2.71475 8.42449 3.33368H8.42372Z'
      fill='#6C757D'
    />
  </svg>
);
export { IconUser };
