import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 2.1
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Faisal <ahmad.faisal@bvarta.com>
 */

function IconArrowDown({ className, onClick }) {
  const customClass = `${className} icon-arrow-down`;

  return (
    <div className={customClass} onClick={onClick}>
      <svg
        height='6'
        viewBox='0 0 9 6'
        width='9'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M3.7,1.067a1,1,0,0,1,1.6,0L7.8,4.4A1,1,0,0,1,7,6H2a1,1,0,0,1-.8-1.6Z'
          data-name='Polygon 37'
          fill='#007e75'
          id='Polygon_37'
          transform='translate(9 6) rotate(180)'
        />
      </svg>
    </div>
  );
}

export default IconArrowDown;

IconArrowDown.defaultProps = {
  className: '',
};

IconArrowDown.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
