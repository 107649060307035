import * as React from 'react';
const IconInfo = (props) => (
  <svg
    fill='none'
    height={14}
    viewBox='0 0 14 14'
    width={14}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M6.33338 10.3331H7.66696V6.33319H6.33338V10.3331ZM7.00058 0.333313C5.68195 0.333313 4.39294 0.72433 3.29655 1.45692C2.20015 2.18951 1.34562 3.23076 0.841 4.44901C0.336385 5.66726 0.204354 7.00779 0.461605 8.30107C0.718856 9.59436 1.35383 10.7823 2.28624 11.7147C3.21865 12.6471 4.40661 13.2821 5.6999 13.5394C6.99318 13.7966 8.33371 13.6646 9.55196 13.16C10.7702 12.6554 11.8115 11.8008 12.5441 10.7044C13.2766 9.60803 13.6677 8.31901 13.6677 7.00039C13.667 5.23237 12.9644 3.53696 11.7142 2.28678C10.464 1.0366 8.7686 0.333969 7.00058 0.333313ZM7.00058 12.3339C5.94571 12.3339 4.91453 12.0211 4.03745 11.435C3.16036 10.849 2.47675 10.016 2.07307 9.04143C1.66939 8.06686 1.56377 6.99447 1.76956 5.95988C1.97536 4.92528 2.48332 3.97494 3.22922 3.22904C3.97513 2.48314 4.92547 1.97517 5.96006 1.76938C6.99466 1.56358 8.06705 1.6692 9.04162 2.07288C10.0162 2.47656 10.8492 3.16017 11.4352 4.03726C12.0213 4.91435 12.3341 5.94552 12.3341 7.00039C12.3323 8.41439 11.7698 9.76997 10.77 10.7698C9.77016 11.7697 8.41457 12.3321 7.00058 12.3339ZM6.33338 5.00043H7.66696V3.66685H6.33338V5.00043Z'
      fill={props?.fill ?? '#A0A6A9'}
    />
  </svg>
);
export { IconInfo };
