import INTERACTIVE_MAP_CONSTANT from '../constant';

const initialState = {
  toggle: false,
  slug: '',
  items: [
    { icon: 'layer', text: 'Base map', slug: 'base_map' },
    { icon: 'setting', text: 'Layer visibility', slug: 'layer_visibility' },
  ],
  layer_visibility: {
    administrative_boundary: {
      value: 50,
      active: true,
      label: 'Administrative boundary',
      max: 100,
      min: 0,
      name: 'administrative_boundary',
      step: 1,
    },
    places_of_interest: {
      value: 50,
      active: false,
      label: 'Places of Interest',
      max: 100,
      min: 0,
      name: 'places_of_interest',
      step: 1,
    },
  },
  // base_map: {},
};
export function SIDEBAR_RIGHT(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case INTERACTIVE_MAP_CONSTANT.SIDEBAR_RIGHT:
      return { ...state, ...payload };
    case 'MapAsideRight/setToggle':
      return { ...state, toggle: payload };
    case 'MapAsideRight/setSlug':
      return { ...state, slug: payload };
    case 'MapAsideRight/setItems':
      return { ...state, items: payload };
    case 'MapAsideRight/updateLayerVisibility':
      return {
        ...state,
        layer_visibility: { ...state.layer_visibility, ...payload },
      };
    default:
      return state;
  }
}
