import React, { useState } from 'react';
import { Table, Modal } from 'antd';

import { SharedBaseCardTypeOneComponent } from '@bvt-shared/component/SharedBaseCardTypeOne.component';

import './AsmanCardBrandByIndustry.component.scss';

export const AsmanCardBrandByIndustryComponent = props => {
  const { data, isLoading } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [brandSelected, setBrandSelected] = useState();
  const [modalTitle, setModalTitle] = useState();

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'key',
      key: 'key',
      width: 50,
      render(value, record, index) {
        return index + 1;
      }
    },
    {
      title: 'Industry',
      dataIndex: 'industry',
      key: 'industry',
      render: (_, { icon, title }) => (
        <React.Fragment>
          <div className='AsmanCardBrandByIndustryComponent__wrapper-name'>
            <img
              alt={title}
              src={icon}
              style={{ width: '2rem', height: '2rem' }}
            />
            <div>{title}</div>
          </div>
        </React.Fragment>
      )
    },
    {
      title: 'Total Brand Variation',
      dataIndex: 'brands',
      key: 'brands',
      className: 'AsmanCardBrandByIndustryComponent__total-th',
      sorter: (a, b) => (a?.brands?.length || 0) - (b?.brands?.length || 0),
      render: (_, { brands, title }) => (
        <div className='AsmanCardBrandByIndustryComponent__wrapper-total'>
          {brands?.length?.toLocaleString('id')}
          <svg
            className='AsmanCardBrandByIndustryComponent__pointer'
            fill='none'
            height='25'
            onClick={() => onClickDetail(brands, title)}
            viewBox='0 0 24 25'
            width='24'>
            <circle cx='6' cy='12.9912' fill='#07827C' r='2'/>
            <circle cx='12' cy='12.9912' fill='#07827C' r='2'/>
            <circle cx='18' cy='12.9912' fill='#07827C' r='2'/>
            <rect height='23' rx='3.5' stroke='#D7DAE2' width='23' x='0.5' y='1.49121'/>
          </svg>
        </div>
      ),
      showSorterTooltip: false
    }
  ];

  const onClickDetail = (brands, title) => {
    setBrandSelected(brands);
    setModalTitle(title);
    setIsModalOpen(true);
  };

  return (
    <SharedBaseCardTypeOneComponent
      isLoading={isLoading}
      showIcon={true}
      title='POI Brand Variations by Industry'
    >
      <Table
        className='AsmanCardBrandByIndustryComponent'
        columns={columns}
        dataSource={data}
        pagination={false}
        rowClassName='AsmanCardBrandByIndustryComponent__row'
      />
      <Modal
        className='AsmanCardBrandByIndustryComponent__modal'
        footer={null}
        onCancel={handleCancel}
        open={isModalOpen}
        title={
          <div className='AsmanCardBrandByIndustryComponent__modal-title'>
            All Brand for <strong>{modalTitle}</strong> Category
          </div>
        }
      >
        <div className='AsmanCardBrandByIndustryComponent__modal-body'>
          {brandSelected?.map((item) => (
            <div
              className='AsmanCardBrandByIndustryComponent__modal-item'
              key={item}
            >
              {item}
            </div>
          ))}
        </div>
      </Modal>
    </SharedBaseCardTypeOneComponent>
  );
};
