import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SubMenu } from '@bvt-organisms';
import { useLocation, useNavigate } from 'react-router';
import MenuItem from '@bvt-features/genset/component/MenuItem';
import './GensetLayout.scss';
import { HeaderSegment } from '@bvt-shared/component/HeaderSegment';
import { useAuth } from '@bvt-features/auth/hooks';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 * @memberof GensetLayout Layout for Parameter setup page
 * @author Amiruriza <amiruriza@bvarta.com>
 * @author Aldrich <aldrich.neil@bvarta.com>
 */

const listMenu = [
  {
    key: '/app/menu/parameter-setup/poi',
    value: 'POI',
    render: (ctx) => (
      <MenuItem
        icon={
          <svg height='2rem' viewBox='0 0 29.446 29.446' width='2rem'>
            <g
              data-name='Group 9817'
              id='Group_9817'
              transform='translate(-118 -141.277)'
            >
              <rect
                data-name='Rectangle 5201'
                fill='none'
                height='29.446'
                id='Rectangle_5201'
                rx='0.64'
                transform='translate(118 141.277)'
                width='29.446'
              />
              <path
                d='M10.295,16.441c2.131.323,3.552,1.1,3.552,1.937,0,1.162-2.841,2.131-6.328,2.131S1.19,19.541,1.19,18.379c0-.839,1.485-1.614,3.552-1.937.71.71,1.485,1.356,2.131,2a.9.9,0,0,0,1.227,0c.646-.646,1.421-1.291,2.131-2ZM8.487.75h5.3a1.5,1.5,0,0,1,1.485,1.485v5.3a1.5,1.5,0,0,1-1.485,1.485H11.134L8.1,10.049c-.71.258-1.1.194-1.1-.581V2.235A1.5,1.5,0,0,1,8.487.75ZM12.813,4.5,9.778,7.53c-.065.065-.065.065-.129.065l-.775.065c-.065,0-.129,0-.129-.065-.065-.065-.065-.065-.065-.129l.065-.775c0-.065,0-.065.065-.129l3.035-3.035a.2.2,0,0,1,.258,0l.71.71A.2.2,0,0,1,12.813,4.5Zm-.129-1.808.258-.258a.2.2,0,0,1,.258,0l.71.71a.2.2,0,0,1,0,.258l-.258.258a.2.2,0,0,1-.258,0l-.71-.71A.2.2,0,0,1,12.684,2.687Zm.71,7.168c-.258,3.293-3.681,5.812-5.876,7.943-2.26-2.26-5.941-4.778-5.941-8.265A5.9,5.9,0,0,1,6.1,3.785V7.207A2.771,2.771,0,0,0,4.742,9.532a2.7,2.7,0,0,0,5.3.775L11.2,9.919H13.33Z'
                data-name='Path 4012'
                fill='#007e75'
                fillRule='evenodd'
                id='Path_4012'
                transform='translate(124.494 145.37)'
              />
            </g>
          </svg>
        }
        value={ctx.value}
      />
    ),
    permission: 'GENSET.POI',
  },
  {
    key: '/app/menu/parameter-setup/thematic',
    value: 'Thematic',
    render: (ctx) => (
      <MenuItem
        icon={
          <svg height='2rem' viewBox='0 0 29.446 29.446' width='2rem'>
            <g
              data-name='Group 9818'
              id='Group_9818'
              transform='translate(-157.455 -141.277)'
            >
              <rect
                data-name='Rectangle 5202'
                fill='none'
                height='29.446'
                id='Rectangle_5202'
                rx='0.64'
                transform='translate(157.455 141.277)'
                width='29.446'
              />
              <path
                d='M17.808,8.36v2.066h4a3.544,3.544,0,0,1,2.389.9,3.093,3.093,0,0,1,1.033,2.26v.71H23.425v-.71a1.373,1.373,0,0,0-.452-.969,1.721,1.721,0,0,0-1.162-.452h-4V14.3H16V12.17H12a1.856,1.856,0,0,0-1.162.452,1.373,1.373,0,0,0-.452.969v.71H8.573v-.71a2.988,2.988,0,0,1,1.033-2.26,3.317,3.317,0,0,1,2.389-.9h4V8.36a3.875,3.875,0,1,1,1.808,0ZM11.092,20.629H7.928a1.088,1.088,0,0,1-1.1-1.1V16.367a1.132,1.132,0,0,1,1.1-1.1h3.164a1.088,1.088,0,0,1,1.1,1.1v3.164A1.132,1.132,0,0,1,11.092,20.629Zm.323-4.133a.624.624,0,0,1,0,.9l-2,2H9.284l-.969-.969a.639.639,0,0,1,.9-.9l.517.517,1.55-1.55a.624.624,0,0,1,.9,0Zm14.465,4.133H22.715a1.088,1.088,0,0,1-1.1-1.1V16.367a1.132,1.132,0,0,1,1.1-1.1h3.164a1.088,1.088,0,0,1,1.1,1.1v3.164A1.132,1.132,0,0,1,25.879,20.629ZM26.2,16.5a.624.624,0,0,1,0,.9l-2,2h-.129l-.969-.969a.639.639,0,0,1,.9-.9l.517.517,1.55-1.55a.624.624,0,0,1,.9,0Zm-7.749,4.133H15.289a1.088,1.088,0,0,1-1.1-1.1V16.367a1.132,1.132,0,0,1,1.1-1.1h3.164a1.132,1.132,0,0,1,1.1,1.1v3.164A1.132,1.132,0,0,1,18.453,20.629ZM18.324,19.4a.624.624,0,0,1-.9,0l-.517-.517-.517.517a.639.639,0,1,1-.9-.9L16,17.981l-.517-.517a.639.639,0,0,1,.9-.9l.517.517.517-.517a.639.639,0,0,1,.9.9l-.517.517.517.517A.624.624,0,0,1,18.324,19.4ZM16.9,2.419a2.288,2.288,0,0,1,2.26,2.26,2.26,2.26,0,0,1-4.52,0A2.288,2.288,0,0,1,16.9,2.419Z'
                data-name='Path 4013'
                fill='#007e75'
                fillRule='evenodd'
                id='Path_4013'
                transform='translate(155.274 145.315)'
              />
            </g>
          </svg>
        }
        value={ctx.value}
      />
    ),
    permission: 'GENSET.PARAMETER',
  },
];

export default function GensetLayout(props) {
  const { children } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const AUTH = useAuth();

  const filteredListMenu = listMenu
    .filter((item) => AUTH.hasAccess(item.permission))
    .map(({ key, value, render }) => ({ key, value, render }));

  const [state, setState] = useState('');
  const [totalHeight, setTotalHeight] = useState(0);

  const internalHandleSidbearHeight = () => {
    let docHeight = window.innerHeight - 32;
    let menubarHeight = document.querySelector('.menu-bar').clientHeight;
    let headerSegmentHeight =
      document.querySelector('.header-segment').clientHeight;
    setTotalHeight(docHeight - menubarHeight - headerSegmentHeight);
  };
  const internalHandleClick = (e) => {
    navigate({ pathname: e });
  };
  const handlerSubMenu = {
    onChange: internalHandleClick,
  };
  const styleSubMenu = {
    height: `${totalHeight}px`,
  };

  useEffect(() => {
    internalHandleSidbearHeight();
    window.addEventListener('resize', internalHandleSidbearHeight);
    return () => {
      window.removeEventListener('resize', internalHandleSidbearHeight);
    };
  }, []);
  useEffect(() => {
    const path = location.pathname.split('/').slice(0, 5).join('/');
    setState(path);
  }, [location.pathname]);

  return (
    <div style={{ overflowY: 'hidden' }}>
      <div className='GensetLayout'>
        <HeaderSegment style={{ marginBottom: '0rem' }} title='Parameter Setup' />
        <div className='GensetLayout--content'>
          <SubMenu
            className='GensetLayout--side_menu'
            currentActive={state}
            handler={handlerSubMenu}
            item={filteredListMenu}
            style={styleSubMenu}
          />
          <div className='GensetLayout--main'>{children}</div>
        </div>
      </div>
    </div>
  );
}

GensetLayout.defaultProps = {
  children: null,
};

GensetLayout.propTypes = {
  children: PropTypes.node,
};
