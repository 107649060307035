import _ from 'lodash';
import React from 'react';
import './BusinessDetailSummary.scss';

/**
 * @company PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

/**
 * @description For ata object
 * @typedef IDataObject
 * @type {Object}
 * @property {string} label
 * @property {string} value
 */

/**
 *
 * @param {Object} props
 * @param {Object} props.data
 * @param {Array<IDataObject>} props.data.left
 * @param {Array<IDataObject>} props.data.right
 */

export const BusinessDetailSummary = (props) => {
  const { data } = props;
  return (
    <div className='BusinessDetailSummary'>
      <div className='BusinessDetailSummary--left'>
        {_.map(data?.left, (v, idx) => {
          return (
            <div className='BusinessDetailSummary--item' key={idx}>
              <div className='BusinessDetailSummary--label'>
                {v?.label || ''}
              </div>
              <div className='BusinessDetailSummary--value'>
                {v?.value || ''}
              </div>
            </div>
          );
        })}
      </div>
      <div className='BusinessDetailSummary--right'>
        {_.map(data?.right, (v, idx) => {
          return (
            <div className='BusinessDetailSummary--item' key={idx}>
              <div className='BusinessDetailSummary--label'>
                {v?.label || ''}
              </div>
              <div className='BusinessDetailSummary--value'>
                {v?.value || ''}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
