export default function passwordScore(text = '') {
  const MAX_SCORE = 3;
  let score = 0;
  let str;
  let color;

  const MUST_STRONG =
    text.match(/[A-Z]/) &&
    text.match(/[a-z]/) &&
    text.match(/[!"`'#%&,:;<>=@{}~$()*+\\?[\]^|]/) &&
    text.match(/\d/);

  if (text.length >= 6) {
    score++;
  }
  if (text.length >= 6 && text.match(/[A-Z]/) && text.match(/[a-z]/)) {
    score++;
  }
  if (text.length >= 6 && MUST_STRONG) {
    score++;
  }

  if (score === 1) {
    str = 'Fair';
    color = '#00A8FF';
  } else if (score === 2) {
    str = 'Good';
    color = 'green';
  } else if (score === 3) {
    str = 'Strong';
    color = '#007e75';
  } else {
    str = 'Weak';
    color = 'red';
  }

  const percentage = score > 0 ? Math.round((score / MAX_SCORE) * 100) : 15;

  return {
    score,
    str,
    color,
    percentage,
  };
}
