import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './_skeleton.scss';
/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 * @author Malik <malik.ibrahim@bvarta.com>
 * @param {Object} props
 * @param {string} props.height
 * @param {'square' | 'circle' | 'image'} props.type
 * @param {string} props.children
 * @param {bool} props.loading
 * @param {string} props.className
 * @param {string} props.width
 */

export const Skeleton = (props) => {
  const { height, type, children, loading, className, width } = props;
  return (
    <Fragment>
      {type === 'circle' && (
        <div
          className={
            loading === true ? `bvt-skeleton__circle ${className}` : ''
          }
          style={{ height: height, width: height }}
        >
          {loading === false && children}
        </div>
      )}
      {type === 'square' && (
        <div
          className={
            loading === true
              ? `bvt-skeleton__square ${className}`
              : `${className}`
          }
          style={
            loading === true
              ? { height: height, width: width }
              : { height: 'auto' }
          }
        >
          {loading === false && children}
        </div>
      )}
      {type === 'image' && (
        <div
          className={
            loading === true ? `bvt-skeleton__picture ${className}` : ''
          }
          style={
            loading === true
              ? { height: height, width: height }
              : { height: 'auto', width: 'auto' }
          }
        >
          {loading && (
            <svg
              className='bvt-skeleton__picture__svg'
              style={{ height: '2rem', width: '2rem' }}
              viewBox='0 0 1098 1024'
            >
              <path
                className='bvt-skeleton__picture__svg__path'
                d='M365.714286 329.142857q0 45.714286-32.036571 77.677714t-77.677714 32.036571-77.677714-32.036571-32.036571-77.677714 32.036571-77.677714 77.677714-32.036571 77.677714 32.036571 32.036571 77.677714zM950.857143 548.571429l0 256-804.571429 0 0-109.714286 182.857143-182.857143 91.428571 91.428571 292.571429-292.571429zM1005.714286 146.285714l-914.285714 0q-7.460571 0-12.873143 5.412571t-5.412571 12.873143l0 694.857143q0 7.460571 5.412571 12.873143t12.873143 5.412571l914.285714 0q7.460571 0 12.873143-5.412571t5.412571-12.873143l0-694.857143q0-7.460571-5.412571-12.873143t-12.873143-5.412571zM1097.142857 164.571429l0 694.857143q0 37.741714-26.843429 64.585143t-64.585143 26.843429l-914.285714 0q-37.741714 0-64.585143-26.843429t-26.843429-64.585143l0-694.857143q0-37.741714 26.843429-64.585143t64.585143-26.843429l914.285714 0q37.741714 0 64.585143 26.843429t26.843429 64.585143z'
              />
            </svg>
          )}
          {loading === false && children}
        </div>
      )}
    </Fragment>
  );
};
Skeleton.defaultProps = {
  children: null,
  className: '',
  height: '10px',
  loading: false,
  type: 'square',
  width: '100%',
};
Skeleton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  height: PropTypes.string,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(['square', 'circle', 'image']),
  width: PropTypes.string,
};
