import React from 'react';
import './AccountNoteInfo.scss';

/**
 * @company PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

/**
 * @param {Object} props
 * @param {any} props.content
 * @param {boolean} props.bordered
 */

export const AccountNoteInfo = (props) => {
  const { content, bordered } = props;
  return (
    <div className={`AccountNoteInfo ${bordered ? 'bordered' : ''}`}>
      {content}
    </div>
  );
};
