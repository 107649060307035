import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

/**
 * @author Malik <malik.ibrahim@bvarta.com>
 */

/**
 * @typedef IBaseLegend
 * @type {Object}
 * @property {string} value
 * @property {string} color
 */

/**
 * @typedef IBaseRecord
 * @type {Object}
 * @property {string} code
 * @property {string} name
 * @property {number} value
 */

/**
 * @typedef IBaseParams
 * @type {Object}
 * @property {string} env
 * @property {string} layers
 * @property {string} query_layers
 * @property {string} viewParams
 */

/**
 * @typedef IBaseResult
 * @type {Object}
 * @property {Array<number>} items
 * @property {Array<IBaseLegend>} legend
 * @property {string} note
 * @property {IBaseParams} params
 * @property {IBaseRecord} record
 */

/**
 * @param {object} props
 * @param {Array<IBaseResult>} props.data
 * @param {Number} props.opacity
 * @param {Number} props.thematicSelectedYearIndex
 */
export const GeoJsonDatexThematicMap = (props) => {
  const map = useMap();
  const WMS_THEMATIC_URL= process.env.WMS_THEMATIC_URL;

  const config = {
    service: 'WMS',
    request: 'GetMap',
    version: '1.1.0',
    format: 'image/png',
    transparent: true,
    maxZoom: 18,
  };

  useEffect(() => {
    const selectedWms = props?.data[props.thematicSelectedYearIndex];
    const wms = L.tileLayer.wms(
      WMS_THEMATIC_URL,
      {
        ...selectedWms?.params,
        ...config,
        opacity: isNaN(props.opacity) ? 1 : props.opacity,
      }
    );
    wms.addTo(map);
    wms.openPopup();
    return () => {
      // Remove Layer On Remount
      wms.removeFrom(map);
    };
  }, [props.data, props.opacity, props.thematicSelectedYearIndex]);

  return <React.Fragment />;
};
