/* eslint-disable max-len */
import React, { useState } from 'react';
import './AsmanDashboardSidebar.component.scss';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import DynamicIcon from '@bvt-icons';
import { IconReport } from '@bvt-assets/icon/icon-report';

export const AsmanDashboardSidebarComponent = props => {
  const { onBackToMapClick, onExportPdfClick } = props;
  const [collapsed, setCollapsed] = useState(false);
  const internalHandleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div
      className={
        collapsed
          ? 'AsmanDashboardSidebarComponent__wrapper active'
          : 'AsmanDashboardSidebarComponent__wrapper'
      }
    >
      <div className='AsmanDashboardSidebarComponent__header'>
        <Button
          ghost
          onClick={() => onBackToMapClick && onBackToMapClick()}
          type='primary'
        >
          Back to map view
        </Button>
        <Button
          ghost
          icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
          onClick={() => internalHandleCollapsed()}
          type='primary'
        />
      </div>
      <ul className='AsmanDashboardSidebarComponent__list'>
        <p className='AsmanDashboardSidebarComponent__list-title'>
          Report Type
        </p>
        <Tooltip title='Asset Management'>
          <li
            className={`AsmanDashboardSidebarComponent__list-item AsmanDashboardSidebarComponent__list-item--active`}
          >
            <DynamicIcon
              className={
                collapsed
                  ? 'AsmanDashboardSidebarComponent__list-item-icon--collapsed'
                  : ''
              }
              color='#07827C'
              height='1rem'
              icon={'assetManagement'}
              width='1rem'
            />
            <span className='AsmanDashboardSidebarComponent__list-item--title'>
              Asset Management
            </span>
          </li>
        </Tooltip>
        <p className='AsmanDashboardSidebarComponent__list-title'>
          Generate report
        </p>
        <Tooltip title='Export to PDF'>
          <li
            className='AsmanDashboardSidebarComponent__list-item'
            onClick={() => onExportPdfClick && onExportPdfClick()}
          >
            <IconReport
              className={
                collapsed
                  ? 'AsmanDashboardSidebarComponent__list-item-icon--collapsed'
                  : ''
              }
              fill='#07827C'
            />
            <span className='AsmanDashboardSidebarComponent__list-item--title'>
              Export to <strong>PDF</strong>
            </span>
          </li>
        </Tooltip>
      </ul>
    </div>
  );
};
