import React, { useEffect, useState } from 'react';
import MarkerCluster from '@bvt-features/mainmap/cluster/component/MarkerCluster';
import PopupContent from '@bvt-features/mapana/griana/component/PopupContent';
import { useMap } from 'react-leaflet';
import { useSIPRO } from '../../hook';

import { notification } from 'antd';
import { useMainMap } from '@bvt-features/mainmap/mainmap/hook/useMainMap';
import _ from 'lodash';
import { useProject } from '@bvt-features/mapp/project/hooks/useProject';

export default function POICollectionSiproContainer() {
  const initialMap = useMap();
  const SIPRO = useSIPRO();
  const idMap = initialMap.getContainer().id;
  const PROPERTIESLEFT = SIPRO.state.filtered_area_collection;
  const PROPERTIESRIGHT = SIPRO.state.filtered_area_collection_right;
  const PROPERTIES = !idMap.includes('right') ? PROPERTIESLEFT: PROPERTIESRIGHT;
  const MAINMAP = useMainMap();
  const PROJECT = useProject();
  const [state, setState] = useState([]);

  const styleFinder = _.find(
    MAINMAP.state.layerVisibilityList,
    (v) => v.key === 'AREA_COLLECTION_SIPRO'
  );

  const poiSearch = _.find(
    MAINMAP.state.layerVisibilityList,
    (v) => v.key === 'POI_SEARCH'
  );

  useEffect(() => {
    MAINMAP.registerLayer({
      key: 'AREA_COLLECTION_SIPRO',
      isActive: true,
      opacity: !poiSearch ? 1 : 0.4,
      title: 'Site Profling',
      description: ['POI']
    });
    return () => {
      MAINMAP.removeLayer('AREA_COLLECTION_SIPRO');
    };
  }, [poiSearch]);

  useEffect(() => {
    MAINMAP.updateLayer({
      key: 'AREA_COLLECTION_SIPRO',
      isActive: true,
      opacity: !poiSearch ? 1 : 0.4,
      title: 'Site Profiling',
      description: [PROJECT?.state?.detail?.name, 'POI']
    });
  }, [PROJECT.state.detail, poiSearch]);

  useEffect(() => {
    const poiCollectionAsyncChange = async () => {
      if (SIPRO.state.status_GET_POI === 'SUCCESS') {
        return _.filter(
          SIPRO.state?.poi_collection,
          (v) =>
            v.properties?.name ===
            PROPERTIES?.properties?.name
        )
          .filter(
            (feature) =>
              +feature.id <=
              PROPERTIES?.properties?.durasi_distance
          )
          ?.map((v) => ({
            position: [v?.properties?.latitude, v?.properties?.longitude],
            icon: v?.properties?.category_icon || '',
            popupContent: <PopupContent properties={v?.properties} />,
          }));
      }
      return [];
    };
    poiCollectionAsyncChange()
      .then((v) => {
        setTimeout(() => {
          setState(v);
        }, 100);
      })
      .catch((err) =>
        notification.error({
          message: 'Error Filtering POI',
          description: err.message,
          placement: 'bottomRight',
        })
      );
  }, [PROPERTIESLEFT, PROPERTIESRIGHT, SIPRO.state.status_GET_POI]);

  return (
    <React.Fragment>
      {initialMap &&
      SIPRO.state.status_GET_POI === 'SUCCESS' &&
      styleFinder?.isActive ? (
          <MarkerCluster data={state} opacity={styleFinder.opacity} />
        ) : null}
    </React.Fragment>
  );
}
