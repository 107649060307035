import React from 'react';
import { CircleSymbol } from '../CircleSymbol';
import { ColorSymbol } from '../ColorSymbol/ColorSymbol';
import { POISymbol } from '../POISymbol';
import { PolySymbol } from '../PolySymbol';

/**
 * @author Renta <renta.yustika@bvarta.com>
 * @copyright PT Bhumi Varta Technology
 */

/**
 * @param {object} props
 * @param {'CIRCLE'|'SQUARE'|'POLY'|'ICON'} props.type
 * @param {React.ReactElement} props.icon
 * @param {string} props.color
 */

export const SymbolType = (props) => {
  const { color, icon, type } = props;

  return (
    <div>
      {type === 'ICON' && <POISymbol icon={icon} />}
      {type === 'SQUARE' && <ColorSymbol color={color} />}
      {type === 'CIRCLE' && (
        <CircleSymbol data={{ color: color, borderColor: color }} />
      )}
      {type === 'POLY' && <PolySymbol />}
    </div>
  );
};
