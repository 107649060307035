import { AccountTab } from '@bvt-features/auth/component/AccountTab';
import { ACCOUNT_TAB_ITEM } from '@bvt-features/auth/constant/ACCOUNT_TAB_ITEM';
import { HeaderSegment } from '@bvt-shared/component/HeaderSegment';
import { Col, Row } from 'antd';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { PersonalDetailContainer } from '../PersonalDetailContainer';
import { SecurityAndLoginContainer } from '../SecurityAndLoginContainer';
import './AccountContainer.scss';

/**
 * @company PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

export const AccountContainer = () => {
  const PARAMS = useParams();
  const NAVIGATE = useNavigate();
  const [state, setState] = useState({
    accountTab: PARAMS?.slug,
  });

  const internalHandleChangeTab = (e) => {
    setState({ ...state, accountTab: e });
    NAVIGATE(`/app/main/account/${e}`);
  };

  return (
    <div className='AccountContainer'>
      <HeaderSegment title='Account' />
      <Row className='AccountContainer--content' gutter={[16, 16]}>
        <Col lg={4} md={4} sm={24} span={4} xs={24}>
          <div className='AccountContainer--tab'>
            <AccountTab
              activeKey={state.accountTab}
              data={ACCOUNT_TAB_ITEM}
              onChangeTab={(e) => internalHandleChangeTab(e)}
            />
          </div>
        </Col>
        <Col span={20} lg={20} md={20} sm={24} xs={24}>
          {state?.accountTab === 'personal-details' && (
            <PersonalDetailContainer />
          )}
          {state?.accountTab === 'security-and-login' && (
            <SecurityAndLoginContainer />
          )}
        </Col>
      </Row>
    </div>
  );
};
