import React from 'react';
import PropTypes from 'prop-types';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 * @memberof icons
 * @author Faisal <ahmad.faisal@bvarta.com>
 * @param {Object} props
 * @param {string} props.className
 * @param {string} props.onClick
 */

export default function IconLayer(props) {
  const { className, onClick } = props;

  return (
    <div className={`${className} icon-layer`} onClick={onClick}>
      <svg
        height='18.734'
        viewBox='0 0 16.927 18.734'
        width='16.927'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g
          data-name='Group 529'
          id='Group_529'
          transform='translate(-11.053 -11.64)'
        >
          <path
            d='M2179.616,75.125l.017-.011,0,0,1.822-1.066-1.468-.858-3.926,2.3a.718.718,0,0,1-.732,0l-3.926-2.3-1.468.858,1.822,1.066,0,0,.017.011,3.917,2.292,3.917-2.292Zm0-3.914.017-.011h0l1.824-1.068-5.759-3.371-5.759,3.371,1.823,1.068h0l.017.011L2175.7,73.5l3.917-2.292Zm2.251.878,1.833,1.072a1.081,1.081,0,0,1,.377,1.319.919.919,0,0,1-.377.448l-.006,0v0L2181.868,76l1.833,1.072a1.081,1.081,0,0,1,.377,1.319.919.919,0,0,1-.377.448l-.006,0v0l-7.627,4.465a.713.713,0,0,1-.732,0l-7.635-4.47a1.076,1.076,0,0,1-.377-1.319.925.925,0,0,1,.377-.448l0,0,0,0L2169.531,76l-1.833-1.073a1.074,1.074,0,0,1-.377-1.318.921.921,0,0,1,.377-.45l0,0,0,0,1.825-1.068-1.833-1.073a1.077,1.077,0,0,1-.377-1.319.925.925,0,0,1,.377-.448l0,0h0l7.627-4.465a.713.713,0,0,1,.732,0l7.635,4.468a1.081,1.081,0,0,1,.377,1.319.927.927,0,0,1-.377.448l-.006,0h0l-1.825,1.07ZM2171.407,77.1l-1.467.856,5.759,3.371,5.759-3.371-1.467-.856-3.926,2.3a.709.709,0,0,1-.732,0l-3.926-2.3Z'
            data-name='Path 76'
            fill='#364961'
            id='Path_76'
            transform='translate(-2156.182 -53.038)'
          />
        </g>
      </svg>
    </div>
  );
}

IconLayer.defaultProps = {
  className: '',
  onClick: () => {},
};

IconLayer.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
