import React, { useEffect } from 'react';
import { geoJson as LeafletGeojson, popup } from 'leaflet';
import _ from 'lodash';


import { AdvanceSearchGeojsonPopupInternalComponent } from './AdvanceSearchGeojsonPopup.internal.component';

import './AdvanceSearchGeojson.component.scss';

/**
 * @author Randa <m.randa@bvarta.com>
 * @description Component for showing administrative boundary from advancesearch
 */
export const AdvanceSearchGeojsonComponent = (props) => {
  const { data, mapInstance } = props;
  const internalGetStringOfJoinDisplayName = (p) => {
    const str = _.filter(data?.features, (v) =>
      p?.includes(v.properties.level || '--')
    )
      .map((v) => v.properties.displayName)
      .reverse();
    str.push('Indonesia');
    return str.join(', ');
  };
  const geojsonInstance = LeafletGeojson([], {
    onEachFeature(f, l) {
      if (f.properties.level === 'VILLAGE') {
        l.bindPopup(
          popup({
            className: 'AdvanceSearchGeojsonComponent__popup-wrapper',
            content: AdvanceSearchGeojsonPopupInternalComponent({
              title: f.properties.displayName,
              subTitle: internalGetStringOfJoinDisplayName([
                'DISTRICT',
                'REGENCY',
                'PROVINCE',
              ]),
            }),
          })
        );
        mapInstance?.fitBounds(l.getBounds());
      } else if (f.properties.level === 'DISTRICT') {
        l.bindPopup(
          popup({
            className: 'AdvanceSearchGeojsonComponent__popup-wrapper',
            content: AdvanceSearchGeojsonPopupInternalComponent({
              title: f.properties.displayName,
              subTitle: internalGetStringOfJoinDisplayName([
                'REGENCY',
                'PROVINCE',
              ]),
            }),
          })
        );
        mapInstance?.fitBounds(l.getBounds(), { animate: true });
      } else if (f.properties.level === 'REGENCY') {
        l.bindPopup(
          popup({
            className: 'AdvanceSearchGeojsonComponent__popup-wrapper',
            content: AdvanceSearchGeojsonPopupInternalComponent({
              title: f.properties.displayName,
              subTitle: internalGetStringOfJoinDisplayName(['PROVINCE']),
            }),
          })
        );
        mapInstance?.fitBounds(l.getBounds());
      } else if (f.properties.level === 'PROVINCE') {
        l.bindPopup(
          popup({
            className: 'AdvanceSearchGeojsonComponent__popup-wrapper',
            content: AdvanceSearchGeojsonPopupInternalComponent({
              title: f.properties.displayName,
              subTitle: internalGetStringOfJoinDisplayName([]),
            }),
          })
        );
        mapInstance?.fitBounds(l.getBounds());
      }
    },
  });

  useEffect(() => {
    if (mapInstance) {
      if (data) {
        geojsonInstance.addData(data);
        geojsonInstance.setStyle({
          color: '#748DA6',
          fillColor: '#748DA6',
          stroke: true,
          weight: 2,
          opacity: 1,
          fillOpacity: 50 / 100,
        });
      }
      geojsonInstance.addTo(mapInstance);
      geojsonInstance.setZIndex(10001);
    }
    return () => {
      if (mapInstance) {
        geojsonInstance.removeFrom(mapInstance);
      }
    };
  }, [data]);

  return <></>;
};
