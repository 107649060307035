import React from 'react';
import IMAGE_CABINET from '@bvt-assets/images/CabinetIllustration.png';
import './DatexNoData.scss';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Malik<malik.ibrahim@bvarta.com>
 */

/**
 * @param {Object} props
 * @param {boolean} props.title
 * @param {boolean} props.desc
 */
export const DatexNoData = (props) => {
  const { title, desc } = props;

  return (
    <div className='no_data'>
      <img alt='Cabinet i' src={IMAGE_CABINET} />
      <span className='no_data__title'>{title}</span>
      <span className='no_data__desc'>
        {desc ?? 'Try using a different search term'}
      </span>
    </div>
  );
};
