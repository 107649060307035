// example geojson to long
/* eslint-disable */
/**
 * @typedef {import('geojson').FeatureCollection} PREVIEW_DATEX_MOVEMENT_DATA_CONSTANT
 */
export const PREVIEW_DATEX_MOVEMENT_DATA_CONSTANT = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.84659099999999, -6.201306320950484],
            [106.8461935989601, -6.201325729623736],
            [106.84580002502241, -6.201383768731626],
            [106.84541406843532, -6.201479879338439],
            [106.84503944609457, -6.20161313586699],
            [106.84467976575047, -6.201782255011655],
            [106.84433849126582, -6.201985608096234],
            [106.84401890925916, -6.202221236757721],
            [106.84372409745454, -6.202486871804915],
            [106.8434568950422, -6.202779955070391],
            [106.84321987533595, -6.203097664045382],
            [106.84301532099052, -6.203436939060412],
            [106.84284520201732, -6.203794512749995],
            [106.84271115681051, -6.204166941517672],
            [106.84261447636638, -6.204550638698435],
            [106.84255609184764, -6.204941909099211],
            [106.84253656561285, -6.205336984584798],
            [106.84255608579748, -6.205732060366582],
            [106.84261446449857, -6.206123331644571],
            [106.8427111395811, -6.206507030249853],
            [106.84284518008843, -6.206879460934608],
            [106.8430152952049, -6.207237036960158],
            [106.84321984668449, -6.2075763146402725],
            [106.84345686462598, -6.2078940265070495],
            [106.84372406644246, -6.208187112779881],
            [106.84401887884296, -6.208452750834432],
            [106.84433846261436, -6.208688382387705],
            [106.84467973996483, -6.208891738137371],
            [106.8450394241657, -6.209060859618002],
            [106.8454140512059, -6.209194118063632],
            [106.84580001315459, -6.209290230094962],
            [106.84619359290996, -6.209348270080064],
            [106.84659099999999, -6.209367679049517],
            [106.84698840709002, -6.209348270080064],
            [106.84738198684539, -6.209290230094962],
            [106.84776794879407, -6.209194118063632],
            [106.84814257583427, -6.209060859618002],
            [106.84850226003515, -6.208891738137371],
            [106.84884353738563, -6.208688382387705],
            [106.84916312115702, -6.208452750834432],
            [106.84945793355752, -6.208187112779881],
            [106.84972513537402, -6.2078940265070495],
            [106.84996215331549, -6.2075763146402725],
            [106.85016670479507, -6.207237036960158],
            [106.85033681991155, -6.206879460934608],
            [106.85047086041888, -6.206507030249853],
            [106.85056753550141, -6.206123331644571],
            [106.8506259142025, -6.205732060366582],
            [106.85064543438713, -6.205336984584798],
            [106.85062590815234, -6.204941909099211],
            [106.85056752363359, -6.204550638698435],
            [106.85047084318947, -6.204166941517672],
            [106.85033679798266, -6.203794512749995],
            [106.85016667900946, -6.203436939060412],
            [106.84996212466403, -6.203097664045382],
            [106.84972510495778, -6.202779955070391],
            [106.84945790254544, -6.202486871804915],
            [106.84916309074082, -6.202221236757721],
            [106.84884350873416, -6.201985608096234],
            [106.84850223424951, -6.201782255011655],
            [106.8481425539054, -6.20161313586699],
            [106.84776793156466, -6.201479879338439],
            [106.84738197497757, -6.201383768731626],
            [106.84698840103988, -6.201325729623736],
            [106.84659099999999, -6.201306320950484],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.866591, -6.1623229904132435],
            [106.86629385843858, -6.162337503610957],
            [106.86599957846445, -6.162380903436439],
            [106.86571099410813, -6.16245277193266],
            [106.86543088455184, -6.162552416978101],
            [106.86516194736615, -6.162678878951833],
            [106.86490677253231, -6.1628309399745955],
            [106.86466781750059, -6.163007135636924],
            [106.86444738352439, -6.163205769101399],
            [106.86424759349866, -6.163424927443221],
            [106.86407037151533, -6.163662500071806],
            [106.86391742433304, -6.1639161990560005],
            [106.8637902249397, -6.1641835811572205],
            [106.86368999836584, -6.164462071358349],
            [106.86361770988573, -6.164748987661854],
            [106.86357405571978, -6.165041566918287],
            [106.86355945632805, -6.165336991436495],
            [106.86357405235903, -6.165632416119249],
            [106.86361770329334, -6.165924995862993],
            [106.86368998879523, -6.166211912957851],
            [106.86379021275863, -6.166490404223962],
            [106.86391741000962, -6.166757787622865],
            [106.86407035559998, -6.167011488087577],
            [106.86424757660302, -6.167249062322617],
            [106.86444736629774, -6.1674682223350965],
            [106.86466780060493, -6.167666857470227],
            [106.86490675661696, -6.167843054739011],
            [106.8651619330427, -6.167995117242291],
            [106.86543087237075, -6.168121580513707],
            [106.86571098453753, -6.16822122662413],
            [106.8659995718721, -6.168293095911703],
            [106.86629385507784, -6.168336496224497],
            [106.866591, -6.1683510095867575],
            [106.86688814492216, -6.168336496224497],
            [106.8671824281279, -6.168293095911703],
            [106.86747101546247, -6.16822122662413],
            [106.86775112762925, -6.168121580513707],
            [106.8680200669573, -6.167995117242291],
            [106.86827524338304, -6.167843054739011],
            [106.86851419939507, -6.167666857470227],
            [106.86873463370227, -6.1674682223350965],
            [106.86893442339698, -6.167249062322617],
            [106.86911164440002, -6.167011488087577],
            [106.86926458999038, -6.166757787622865],
            [106.86939178724137, -6.166490404223962],
            [106.86949201120477, -6.166211912957851],
            [106.86956429670666, -6.165924995862993],
            [106.86960794764097, -6.165632416119249],
            [106.86962254367195, -6.165336991436495],
            [106.86960794428023, -6.165041566918287],
            [106.86956429011428, -6.164748987661854],
            [106.86949200163417, -6.164462071358349],
            [106.8693917750603, -6.1641835811572205],
            [106.86926457566696, -6.1639161990560005],
            [106.86911162848467, -6.163662500071806],
            [106.86893440650134, -6.163424927443221],
            [106.8687346164756, -6.163205769101399],
            [106.86851418249941, -6.163007135636924],
            [106.86827522746769, -6.1628309399745955],
            [106.86802005263385, -6.162678878951833],
            [106.86775111544816, -6.162552416978101],
            [106.86747100589187, -6.16245277193266],
            [106.86718242153555, -6.162380903436439],
            [106.86688814156142, -6.162337503610957],
            [106.866591, -6.1623229904132435],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.87659099999999, -6.162586136905699],
            [106.87631980107544, -6.162599382994862],
            [106.87605121390447, -6.162638993697244],
            [106.87578782508965, -6.162704587545972],
            [106.8755321711735, -6.1627955328459105],
            [106.87528671421168, -6.162910953756878],
            [106.87505381806325, -6.163049738727962],
            [106.8748357256263, -6.1632105512017805],
            [106.8746345372385, -6.16339184248556],
            [106.87445219044999, -6.16359186666517],
            [106.874290441364, -6.163808697418455],
            [106.87415084772438, -6.1640402465659925],
            [106.87403475391329, -6.164284284180675],
            [106.87394327800331, -6.164538460062432],
            [106.8738773009887, -6.164800326371357],
            [106.87383745829992, -6.165067361201238],
            [106.87382413368259, -6.165336992866539],
            [106.87383745550038, -6.165606624668909],
            [106.87387729549721, -6.165873659904726],
            [106.87394327003092, -6.166135526872852],
            [106.87403474376637, -6.166389703641747],
            [106.87415083579286, -6.166633742337409],
            [106.8742904281064, -6.166865292718229],
            [106.8744521763758, -6.167082124809699],
            [106.87463452288856, -6.16728215038098],
            [106.87483571155212, -6.1674634430564295],
            [106.87505380480565, -6.167624256868436],
            [106.87528670228015, -6.167763043072801],
            [106.87553216102657, -6.167878465064748],
            [106.87578781711726, -6.167969411251825],
            [106.876051208413, -6.168035005759755],
            [106.87631979827592, -6.168074616868072],
            [106.87659099999999, -6.168087863094303],
            [106.87686220172408, -6.168074616868072],
            [106.87713079158699, -6.168035005759755],
            [106.87739418288274, -6.167969411251825],
            [106.87764983897344, -6.167878465064748],
            [106.87789529771985, -6.167763043072801],
            [106.87812819519435, -6.167624256868436],
            [106.87834628844789, -6.1674634430564295],
            [106.87854747711143, -6.16728215038098],
            [106.8787298236242, -6.167082124809699],
            [106.8788915718936, -6.166865292718229],
            [106.87903116420713, -6.166633742337409],
            [106.87914725623362, -6.166389703641747],
            [106.87923872996909, -6.166135526872852],
            [106.87930470450279, -6.165873659904726],
            [106.87934454449962, -6.165606624668909],
            [106.87935786631742, -6.165336992866539],
            [106.8793445417001, -6.165067361201238],
            [106.87930469901131, -6.164800326371357],
            [106.8792387219967, -6.164538460062432],
            [106.87914724608669, -6.164284284180675],
            [106.87903115227562, -6.1640402465659925],
            [106.87889155863601, -6.163808697418455],
            [106.87872980955001, -6.16359186666517],
            [106.8785474627615, -6.16339184248556],
            [106.8783462743737, -6.1632105512017805],
            [106.87812818193675, -6.163049738727962],
            [106.8778952857883, -6.162910953756878],
            [106.8776498288265, -6.1627955328459105],
            [106.87739417491035, -6.162704587545972],
            [106.8771307860955, -6.162638993697244],
            [106.87686219892457, -6.162599382994862],
            [106.87659099999999, -6.162586136905699],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.85659100000001, -6.163289812710769],
            [106.8563891739894, -6.1632996704391685],
            [106.8561892916513, -6.163329148690145],
            [106.85599327794039, -6.163377963575265],
            [106.85580302055611, -6.163445644985752],
            [106.85562035176372, -6.1635315411197],
            [106.85544703074932, -6.163634824759002],
            [106.85528472667845, -6.163754501235584],
            [106.85513500262151, -6.16388941801022],
            [106.85499930050086, -6.164038275771717],
            [106.85487892720425, -6.164199640949556],
            [106.85477504199886, -6.1643719595195305],
            [106.85468864536656, -6.16455357196945],
            [106.85462056936838, -6.164742729280764],
            [106.85457146963076, -6.164937609772236],
            [106.85454181903077, -6.165136336643459],
            [106.85453190314135, -6.16533699604927],
            [106.8545418174803, -6.165537655530991],
            [106.85457146658942, -6.165736382627034],
            [106.85462056495304, -6.165931263483594],
            [106.85468863974688, -6.16612042128623],
            [106.8547750353908, -6.166302034334831],
            [106.85487891986179, -6.166474353587835],
            [106.85499929270614, -6.166635719506803],
            [106.85513499467409, -6.16678457803905],
            [106.85528471888372, -6.166919495584437],
            [106.85544702340685, -6.167039172802148],
            [106.85562034515567, -6.167142457124479],
            [106.85580301493641, -6.167228353857106],
            [106.85599327352503, -6.16729603575892],
            [106.85618928860993, -6.167344851009125],
            [106.85638917243894, -6.167374329484921],
            [106.85659100000001, -6.167384187289231],
            [106.85679282756108, -6.167374329484921],
            [106.85699271139006, -6.167344851009125],
            [106.85718872647497, -6.16729603575892],
            [106.85737898506358, -6.167228353857106],
            [106.85756165484433, -6.167142457124479],
            [106.85773497659315, -6.167039172802148],
            [106.85789728111627, -6.166919495584437],
            [106.85804700532591, -6.16678457803905],
            [106.85818270729388, -6.166635719506803],
            [106.85830308013823, -6.166474353587835],
            [106.8584069646092, -6.166302034334831],
            [106.85849336025312, -6.16612042128623],
            [106.85856143504698, -6.165931263483594],
            [106.8586105334106, -6.165736382627034],
            [106.85864018251971, -6.165537655530991],
            [106.85865009685864, -6.16533699604927],
            [106.85864018096925, -6.165136336643459],
            [106.85861053036923, -6.164937609772236],
            [106.85856143063161, -6.164742729280764],
            [106.85849335463344, -6.16455357196945],
            [106.85840695800115, -6.1643719595195305],
            [106.85830307279575, -6.164199640949556],
            [106.85818269949915, -6.164038275771717],
            [106.85804699737848, -6.16388941801022],
            [106.85789727332154, -6.163754501235584],
            [106.85773496925069, -6.163634824759002],
            [106.85756164823628, -6.1635315411197],
            [106.85737897944391, -6.163445644985752],
            [106.85718872205962, -6.163377963575265],
            [106.85699270834871, -6.163329148690145],
            [106.8567928260106, -6.1632996704391685],
            [106.85659100000001, -6.163289812710769],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.85659100000001, -6.153137427532058],
            [106.85637415494048, -6.153148019030955],
            [106.85615939819169, -6.153179691526982],
            [106.8559487979538, -6.153232140000419],
            [106.85574438239942, -6.153304859349849],
            [106.85554812014209, -6.153397149256341],
            [106.85536190127824, -6.1535081209275715],
            [106.85518751918517, -6.153636705657019],
            [106.8550266532502, -6.153781665115744],
            [106.85488085269773, -6.153941603277703],
            [106.8547515216693, -6.154114979863754],
            [106.8546399057009, -6.154300125174898],
            [106.85454707972751, -6.15449525617191],
            [106.8544739377304, -6.154698493646558],
            [106.85442118412698, -6.154907880319014],
            [106.85438932698604, -6.155121399687221],
            [106.85437867313405, -6.155336995446677],
            [106.85438932519911, -6.155552591293623],
            [106.8544211806218, -6.155766110920941],
            [106.8544739326417, -6.155975498014169],
            [106.85454707325081, -6.156178736055081],
            [106.8546398980851, -6.156373867742088],
            [106.85475151320706, -6.1565590138404405],
            [106.85488084371428, -6.1567323912806655],
            [106.85502664409076, -6.156892330330933],
            [106.8551875102017, -6.157037290677968],
            [106.855361892816, -6.157165876261586],
            [106.85554811252628, -6.157276848720028],
            [106.8557443759227, -6.157369139316512],
            [106.85594879286508, -6.157441859232207],
            [106.85615939468651, -6.157494308126417],
            [106.85637415315355, -6.157525980881554],
            [106.85659100000001, -6.157536572467942],
            [106.85680784684645, -6.157525980881554],
            [106.85702260531349, -6.157494308126417],
            [106.85723320713493, -6.157441859232207],
            [106.8574376240773, -6.157369139316512],
            [106.85763388747372, -6.157276848720028],
            [106.85782010718398, -6.157165876261586],
            [106.8579944897983, -6.157037290677968],
            [106.85815535590926, -6.156892330330933],
            [106.85830115628573, -6.1567323912806655],
            [106.85843048679294, -6.1565590138404405],
            [106.8585421019149, -6.156373867742088],
            [106.8586349267492, -6.156178736055081],
            [106.85870806735832, -6.155975498014169],
            [106.8587608193782, -6.155766110920941],
            [106.85879267480088, -6.155552591293623],
            [106.85880332686595, -6.155336995446677],
            [106.85879267301395, -6.155121399687221],
            [106.85876081587303, -6.154907880319014],
            [106.85870806226959, -6.154698493646558],
            [106.85863492027248, -6.15449525617191],
            [106.85854209429911, -6.154300125174898],
            [106.85843047833069, -6.154114979863754],
            [106.85830114730227, -6.153941603277703],
            [106.8581553467498, -6.153781665115744],
            [106.85799448081485, -6.153636705657019],
            [106.85782009872176, -6.1535081209275715],
            [106.85763387985791, -6.153397149256341],
            [106.8574376176006, -6.153304859349849],
            [106.8572332020462, -6.153232140000419],
            [106.85702260180831, -6.153179691526982],
            [106.85680784505954, -6.153148019030955],
            [106.85659100000001, -6.153137427532058],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.816591, -6.1726061584643865],
            [106.81632176984992, -6.172619308145156],
            [106.8160551324936, -6.172658630550797],
            [106.81579365575628, -6.172723746990813],
            [106.8155398577666, -6.1728140303676575],
            [106.81529618270704, -6.172928611215663],
            [106.81506497727628, -6.173066386073985],
            [106.81484846809015, -6.173226028112945],
            [106.81464874023888, -6.173405999911455],
            [106.8144677172069, -6.173604568262489],
            [106.8143071423489, -6.173819820864054],
            [106.81416856210012, -6.174049684734949],
            [106.81405331108296, -6.174291946177967],
            [106.81396249925307, -6.174544272098347],
            [106.81389700120891, -6.174804232472153],
            [106.81385744776769, -6.175069323748249],
            [106.81384421988896, -6.175336992958508],
            [106.8138574450042, -6.17560466230407],
            [106.81389699578813, -6.175869753980867],
            [106.81396249138332, -6.176129715005376],
            [106.81405330106665, -6.176382041801455],
            [106.81416855032221, -6.176624304311518],
            [106.81430712926198, -6.1768541693997925],
            [106.81446770331391, -6.177069423322293],
            [106.81464872607368, -6.177267993047054],
            [106.81484845419715, -6.177447966219291],
            [106.81506496418936, -6.177607609579187],
            [106.81529617092912, -6.177745385654889],
            [106.8155398477503, -6.177859967569938],
            [106.81579364788654, -6.177950251822482],
            [106.81605512707284, -6.1780153689132025],
            [106.81632176708646, -6.178054691719544],
            [106.816591, -6.178067841535614],
            [106.81686023291357, -6.178054691719544],
            [106.81712687292716, -6.1780153689132025],
            [106.81738835211347, -6.177950251822482],
            [106.8176421522497, -6.177859967569938],
            [106.81788582907089, -6.177745385654889],
            [106.81811703581064, -6.177607609579187],
            [106.81833354580286, -6.177447966219291],
            [106.81853327392632, -6.177267993047054],
            [106.8187142966861, -6.177069423322293],
            [106.81887487073803, -6.1768541693997925],
            [106.8190134496778, -6.176624304311518],
            [106.81912869893335, -6.176382041801455],
            [106.8192195086167, -6.176129715005376],
            [106.81928500421189, -6.175869753980867],
            [106.8193245549958, -6.17560466230407],
            [106.81933778011104, -6.175336992958508],
            [106.81932455223232, -6.175069323748249],
            [106.81928499879109, -6.174804232472153],
            [106.81921950074694, -6.174544272098347],
            [106.81912868891705, -6.174291946177967],
            [106.81901343789988, -6.174049684734949],
            [106.81887485765111, -6.173819820864054],
            [106.81871428279311, -6.173604568262489],
            [106.81853325976113, -6.173405999911455],
            [106.81833353190986, -6.173226028112945],
            [106.81811702272373, -6.173066386073985],
            [106.81788581729296, -6.172928611215663],
            [106.81764214223342, -6.1728140303676575],
            [106.81738834424372, -6.172723746990813],
            [106.8171268675064, -6.172658630550797],
            [106.81686023015008, -6.172619308145156],
            [106.816591, -6.1726061584643865],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.82659100000002, -6.1735324527889865],
            [106.82641309170485, -6.173541142147503],
            [106.82623689674644, -6.1735671265406316],
            [106.82606411196187, -6.173610155726979],
            [106.82589640134766, -6.173669815316051],
            [106.82573538003521, -6.173745530758895],
            [106.82558259873669, -6.173836572881112],
            [106.82543952881133, -6.173942064904953],
            [106.82530754809562, -6.174060990892862],
            [106.8251879276343, -6.174192205531199],
            [106.82508181943952, -6.174334445159907],
            [106.82499024539625, -6.174486339941907],
            [106.82491408742081, -6.174646427055072],
            [106.82485407896722, -6.174813164779699],
            [106.82481079796318, -6.1749849473458545],
            [106.82478466124383, -6.175160120397619],
            [106.82477592053677, -6.175336996925261],
            [106.82478466003714, -6.175513873511986],
            [106.82481079559615, -6.175689046738719],
            [106.82485407553082, -6.175860829589013],
            [106.82491408304709, -6.176027567696022],
            [106.8249902402533, -6.176187655275121],
            [106.82508181372498, -6.176339550588704],
            [106.82518792156779, -6.176481790794211],
            [106.82530754191025, -6.1766130060324],
            [106.82543952274482, -6.176731932620161],
            [106.82558259302216, -6.176837425220801],
            [106.82573537489226, -6.176928467874601],
            [106.82589639697393, -6.177004183783379],
            [106.82606410852547, -6.177063843754834],
            [106.8262368943794, -6.177106873225317],
            [106.82641309049815, -6.177132857793416],
            [106.82659100000002, -6.177141547211013],
            [106.82676890950188, -6.177132857793416],
            [106.82694510562062, -6.177106873225317],
            [106.82711789147456, -6.177063843754834],
            [106.8272856030261, -6.177004183783379],
            [106.82744662510777, -6.176928467874601],
            [106.82759940697785, -6.176837425220801],
            [106.82774247725521, -6.176731932620161],
            [106.8278744580898, -6.1766130060324],
            [106.82799407843224, -6.176481790794211],
            [106.82810018627505, -6.176339550588704],
            [106.82819175974674, -6.176187655275121],
            [106.82826791695294, -6.176027567696022],
            [106.8283279244692, -6.175860829589013],
            [106.82837120440388, -6.175689046738719],
            [106.82839733996289, -6.175513873511986],
            [106.82840607946326, -6.175336996925261],
            [106.82839733875618, -6.175160120397619],
            [106.82837120203685, -6.1749849473458545],
            [106.82832792103282, -6.174813164779699],
            [106.82826791257921, -6.174646427055072],
            [106.8281917546038, -6.174486339941907],
            [106.82810018056053, -6.174334445159907],
            [106.82799407236573, -6.174192205531199],
            [106.82787445190439, -6.174060990892862],
            [106.8277424711887, -6.173942064904953],
            [106.82759940126334, -6.173836572881112],
            [106.82744661996482, -6.173745530758895],
            [106.82728559865237, -6.173669815316051],
            [106.82711788803816, -6.173610155726979],
            [106.82694510325359, -6.1735671265406316],
            [106.8267689082952, -6.173541142147503],
            [106.82659100000002, -6.1735324527889865],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.836591, -6.203424022454465],
            [106.8364023910098, -6.203433233930861],
            [106.83621559840769, -6.203460779649457],
            [106.83603242108963, -6.203506394332767],
            [106.83585462313596, -6.203569638691076],
            [106.83568391682292, -6.203649903652856],
            [106.83552194613311, -6.203746416230223],
            [106.83537027092352, -6.203858246962939],
            [106.83523035190362, -6.203984318869294],
            [106.83510353656814, -6.204123417817662],
            [106.83499104621997, -6.204274204218888],
            [106.83489396420842, -6.204435225926874],
            [106.83481322549558, -6.204604932223197],
            [106.83474960765193, -6.2047816887510265],
            [106.8347037233673, -6.204963793254594],
            [106.8346760145498, -6.2051494919726045],
            [106.83466674806941, -6.205336996527738],
            [106.834676013187, -6.20552450114959],
            [106.83470372069405, -6.205710200065192],
            [106.834749603771, -6.205892304889632],
            [106.83481322055611, -6.2060690618492815],
            [106.83489395840022, -6.206238768671778],
            [106.83499103976627, -6.206399790980074],
            [106.8351035297169, -6.206550578032671],
            [106.83523034491817, -6.206689677658445],
            [106.8353702640723, -6.206815750242203],
            [106.83552193967938, -6.206927581626293],
            [106.8356839110147, -6.207024094803968],
            [106.83585461819648, -6.207104360291924],
            [106.83603241720871, -6.207167605082051],
            [106.83621559573447, -6.207213220086234],
            [106.836402389647, -6.20724076600242],
            [106.836591, -6.207249977545535],
            [106.83677961035298, -6.20724076600242],
            [106.83696640426552, -6.207213220086234],
            [106.83714958279126, -6.207167605082051],
            [106.83732738180349, -6.207104360291924],
            [106.83749808898527, -6.207024094803968],
            [106.83766006032059, -6.206927581626293],
            [106.83781173592767, -6.206815750242203],
            [106.8379516550818, -6.206689677658445],
            [106.83807847028307, -6.206550578032671],
            [106.83819096023373, -6.206399790980074],
            [106.83828804159978, -6.206238768671778],
            [106.83836877944388, -6.2060690618492815],
            [106.83843239622897, -6.205892304889632],
            [106.83847827930592, -6.205710200065192],
            [106.83850598681296, -6.20552450114959],
            [106.83851525193056, -6.205336996527738],
            [106.83850598545017, -6.2051494919726045],
            [106.8384782766327, -6.204963793254594],
            [106.83843239234807, -6.2047816887510265],
            [106.8383687745044, -6.204604932223197],
            [106.83828803579156, -6.204435225926874],
            [106.83819095378, -6.204274204218888],
            [106.83807846343186, -6.204123417817662],
            [106.83795164809635, -6.203984318869294],
            [106.83781172907645, -6.203858246962939],
            [106.83766005386688, -6.203746416230223],
            [106.83749808317705, -6.203649903652856],
            [106.83732737686402, -6.203569638691076],
            [106.83714957891037, -6.203506394332767],
            [106.8369664015923, -6.203460779649457],
            [106.83677960899017, -6.203433233930861],
            [106.836591, -6.203424022454465],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.796591, -6.2213248848422396],
            [106.79619541420757, -6.221344204126164],
            [106.79580363803603, -6.22140197592691],
            [106.79541944442082, -6.221497643883001],
            [106.79504653327973, -6.221630286680102],
            [106.7946884958837, -6.22179862692299],
            [106.79434878027334, -6.222001043436516],
            [106.7940306580547, -6.222235586877136],
            [106.79373719289335, -6.22249999850467],
            [106.79347121101067, -6.222791731933637],
            [106.79323527396608, -6.223107977654671],
            [106.79303165398755, -6.223445690089987],
            [106.79286231208775, -6.223801616922365],
            [106.7927288791769, -6.22417233041528],
            [106.79263264035414, -6.224554260422595],
            [106.79257452252862, -6.224943728769955],
            [106.79255508549005, -6.225336984676847],
            [106.79257451651436, -6.2257302408781685],
            [106.79263262855675, -6.226119710097506],
            [106.79272886204973, -6.226501641520831],
            [106.792862290289, -6.226872356919377],
            [106.79303162835494, -6.227228286073773],
            [106.79323524548465, -6.227566001158259],
            [106.79347118077493, -6.227882249753811],
            [106.79373716206524, -6.228173986172176],
            [106.79403062781897, -6.228438400789109],
            [106.7943487517919, -6.228672947104247],
            [106.79468847025106, -6.228875366266944],
            [106.79504651148098, -6.22904370883185],
            [106.79541942729364, -6.229176353534581],
            [106.79580362623865, -6.229272022906684],
            [106.79619540819331, -6.229329795579405],
            [106.796591, -6.229349115157759],
            [106.79698659180669, -6.229329795579405],
            [106.79737837376138, -6.229272022906684],
            [106.79776257270636, -6.229176353534581],
            [106.79813548851902, -6.22904370883185],
            [106.79849352974894, -6.228875366266944],
            [106.79883324820811, -6.228672947104247],
            [106.79915137218106, -6.228438400789109],
            [106.79944483793476, -6.228173986172176],
            [106.7997108192251, -6.227882249753811],
            [106.79994675451537, -6.227566001158259],
            [106.80015037164507, -6.227228286073773],
            [106.800319709711, -6.226872356919377],
            [106.80045313795027, -6.226501641520831],
            [106.80054937144327, -6.226119710097506],
            [106.80060748348563, -6.2257302408781685],
            [106.80062691450995, -6.225336984676847],
            [106.80060747747137, -6.224943728769955],
            [106.80054935964587, -6.224554260422595],
            [106.8004531208231, -6.22417233041528],
            [106.80031968791226, -6.223801616922365],
            [106.80015034601244, -6.223445690089987],
            [106.79994672603392, -6.223107977654671],
            [106.79971078898933, -6.222791731933637],
            [106.79944480710667, -6.22249999850467],
            [106.79915134194532, -6.222235586877136],
            [106.79883321972666, -6.222001043436516],
            [106.79849350411632, -6.22179862692299],
            [106.79813546672028, -6.221630286680102],
            [106.79776255557918, -6.221497643883001],
            [106.79737836196398, -6.22140197592691],
            [106.79698658579242, -6.221344204126164],
            [106.796591, -6.2213248848422396],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.796591, -6.211191710084319],
            [106.79618229134013, -6.211211670631426],
            [106.79577751867582, -6.211271360046282],
            [106.7953805801004, -6.211370203500565],
            [106.79499529826758, -6.211507249099493],
            [106.79462538358035, -6.21168117704826],
            [106.79427439846054, -6.21189031236125],
            [106.793945723043, -6.212132640991715],
            [106.79364252262472, -6.212405829226562],
            [106.7933677171824, -6.21270724615961],
            [106.79312395325172, -6.2130339890268855],
            [106.79291357843944, -6.213382911160071],
            [106.7927386188137, -6.213750652288932],
            [106.79260075939013, -6.214133670901015],
            [106.792501327902, -6.214528278346957],
            [106.79244128201064, -6.214930674363087],
            [106.79242120007954, -6.2153369836692],
            [106.79244127560099, -6.215743293289105],
            [106.79250131532903, -6.216145690234552],
            [106.79260074113702, -6.216540299189621],
            [106.7927385955819, -6.216923319832648],
            [106.79291355112173, -6.217291063436223],
            [106.7931239228979, -6.217639988392786],
            [106.79336768495898, -6.21796673432361],
            [106.79364248977001, -6.218268154442638],
            [106.79394569081957, -6.218541345863465],
            [106.79427436810673, -6.218783677557474],
            [106.79462535626266, -6.218992815693847],
            [106.79499527503577, -6.219166746117326],
            [106.7953805618473, -6.219303793747198],
            [106.79577750610287, -6.219402638710609],
            [106.79618228493051, -6.219462329054782],
            [106.796591, -6.219482289915681],
            [106.7969997150695, -6.219462329054782],
            [106.79740449389715, -6.219402638710609],
            [106.7978014381527, -6.219303793747198],
            [106.79818672496422, -6.219166746117326],
            [106.79855664373737, -6.218992815693847],
            [106.79890763189329, -6.218783677557474],
            [106.79923630918044, -6.218541345863465],
            [106.79953951022999, -6.218268154442638],
            [106.79981431504103, -6.21796673432361],
            [106.8000580771021, -6.217639988392786],
            [106.80026844887827, -6.217291063436223],
            [106.8004434044181, -6.216923319832648],
            [106.80058125886298, -6.216540299189621],
            [106.80068068467097, -6.216145690234552],
            [106.800740724399, -6.215743293289105],
            [106.80076079992045, -6.2153369836692],
            [106.80074071798936, -6.214930674363087],
            [106.80068067209801, -6.214528278346957],
            [106.80058124060987, -6.214133670901015],
            [106.80044338118631, -6.213750652288932],
            [106.80026842156056, -6.213382911160071],
            [106.8000580467483, -6.2130339890268855],
            [106.79981428281761, -6.21270724615961],
            [106.79953947737529, -6.212405829226562],
            [106.79923627695702, -6.212132640991715],
            [106.79890760153948, -6.21189031236125],
            [106.79855661641967, -6.21168117704826],
            [106.79818670173245, -6.211507249099493],
            [106.7978014198996, -6.211370203500565],
            [106.79740448132418, -6.211271360046282],
            [106.79699970865987, -6.211211670631426],
            [106.796591, -6.211191710084319],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.80659100000001, -6.213026799216443],
            [106.80636322284522, -6.213037923415934],
            [106.80613763928031, -6.213071188883686],
            [106.80591642177073, -6.213126275259216],
            [106.80570170073666, -6.213202652037361],
            [106.80549554403676, -6.213299583677072],
            [106.80529993705447, -6.213416136684742],
            [106.80511676357838, -6.21355118860381],
            [106.80494778766082, -6.213703438824169],
            [106.80479463662948, -6.213871421107214],
            [106.80465878541527, -6.2140535177059775],
            [106.804541542348, -6.214247974944367],
            [106.80444403655609, -6.214452920105508],
            [106.80436720709191, -6.214666379466526],
            [106.80431179388742, -6.214886297306176],
            [106.80427833062748, -6.215110555702193],
            [106.80426713960915, -6.2153369949277915],
            [106.80427832863671, -6.21556343425085],
            [106.80431178998238, -6.215787692935505],
            [106.80436720142265, -6.216007611243877],
            [106.80444402934052, -6.21622107123569],
            [106.80454153386336, -6.216426017165455],
            [106.80465877598762, -6.216620475280763],
            [106.80479462662116, -6.216802572831038],
            [106.80494777745643, -6.216970556103621],
            [106.80511675357006, -6.217122807313518],
            [106.80529992762683, -6.217257860184098],
            [106.80549553555211, -6.217374414068685],
            [106.80570169352106, -6.217471346477022],
            [106.80591641610147, -6.2175477238859616],
            [106.80613763537526, -6.217602810730215],
            [106.80636322085445, -6.217636076486603],
            [106.80659100000001, -6.2176472007835555],
            [106.80681877914554, -6.217636076486603],
            [106.80704436462476, -6.217602810730215],
            [106.80726558389853, -6.2175477238859616],
            [106.80748030647894, -6.217471346477022],
            [106.8076864644479, -6.217374414068685],
            [106.80788207237318, -6.217257860184098],
            [106.80806524642995, -6.217122807313518],
            [106.80823422254358, -6.216970556103621],
            [106.80838737337885, -6.216802572831038],
            [106.80852322401239, -6.216620475280763],
            [106.80864046613665, -6.216426017165455],
            [106.80873797065951, -6.21622107123569],
            [106.80881479857734, -6.216007611243877],
            [106.80887021001763, -6.215787692935505],
            [106.8089036713633, -6.21556343425085],
            [106.80891486039086, -6.2153369949277915],
            [106.80890366937251, -6.215110555702193],
            [106.80887020611259, -6.214886297306176],
            [106.8088147929081, -6.214666379466526],
            [106.8087379634439, -6.214452920105508],
            [106.80864045765199, -6.214247974944367],
            [106.80852321458475, -6.2140535177059775],
            [106.80838736337053, -6.213871421107214],
            [106.80823421233917, -6.213703438824169],
            [106.80806523642163, -6.21355118860381],
            [106.80788206294554, -6.213416136684742],
            [106.80768645596325, -6.213299583677072],
            [106.80748029926336, -6.213202652037361],
            [106.80726557822926, -6.213126275259216],
            [106.80704436071969, -6.213071188883686],
            [106.80681877715477, -6.213037923415934],
            [106.80659100000001, -6.213026799216443],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.80659100000001, -6.193820506956239],
            [106.806441484908, -6.193827809263816],
            [106.80629340971578, -6.1938496458619365],
            [106.80614820045652, -6.193885806454007],
            [106.80600725556386, -6.193935942796657],
            [106.80587193240463, -6.193999572053401],
            [106.80574353420694, -6.194076081444464],
            [106.80562329750981, -6.194164734148011],
            [106.80551238025478, -6.194264676395924],
            [106.80541185063444, -6.194374945695844],
            [106.80532267680518, -6.194494480100253],
            [106.8052457175633, -6.194622128433376],
            [106.80518171407422, -6.194756661377407],
            [106.80513128273438, -6.194896783311308],
            [106.80509490923471, -6.195041144788157],
            [106.8050729438828, -6.195188355530919],
            [106.80506559822884, -6.1953369978214505],
            [106.80507294302778, -6.195485640153841],
            [106.8050949075575, -6.195632851020575],
            [106.80513128029948, -6.195777212698744],
            [106.80518171097519, -6.195917334903576],
            [106.8052457139192, -6.196051868177737],
            [106.80532267275608, -6.1961795168875025],
            [106.80541184633594, -6.196299051700591],
            [106.80551237587208, -6.196409321425526],
            [106.80562329321133, -6.196509264098454],
            [106.80574353015784, -6.19659791721068],
            [106.80587192876055, -6.196674426978387],
            [106.80600725246484, -6.196738056565261],
            [106.80614819802162, -6.196788193178842],
            [106.8062934080386, -6.196824353972231],
            [106.80644148405298, -6.196846190694324],
            [106.80659100000001, -6.196853493043762],
            [106.80674051594703, -6.196846190694324],
            [106.80688859196142, -6.196824353972231],
            [106.80703380197838, -6.196788193178842],
            [106.80717474753517, -6.196738056565261],
            [106.80731007123946, -6.196674426978387],
            [106.80743846984214, -6.19659791721068],
            [106.80755870678868, -6.196509264098454],
            [106.80766962412791, -6.196409321425526],
            [106.80777015366407, -6.196299051700591],
            [106.80785932724393, -6.1961795168875025],
            [106.8079362860808, -6.196051868177737],
            [106.80800028902483, -6.195917334903576],
            [106.80805071970053, -6.195777212698744],
            [106.80808709244249, -6.195632851020575],
            [106.80810905697223, -6.195485640153841],
            [106.80811640177119, -6.1953369978214505],
            [106.8081090561172, -6.195188355530919],
            [106.8080870907653, -6.195041144788157],
            [106.80805071726563, -6.194896783311308],
            [106.80800028592579, -6.194756661377407],
            [106.80793628243673, -6.194622128433376],
            [106.80785932319485, -6.194494480100253],
            [106.80777014936558, -6.194374945695844],
            [106.80766961974521, -6.194264676395924],
            [106.80755870249018, -6.194164734148011],
            [106.80743846579307, -6.194076081444464],
            [106.80731006759538, -6.193999572053401],
            [106.80717474443614, -6.193935942796657],
            [106.80703379954348, -6.193885806454007],
            [106.80688859028423, -6.1938496458619365],
            [106.806740515092, -6.193827809263816],
            [106.80659100000001, -6.193820506956239],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.816591, -6.181333148738668],
            [106.81619625894898, -6.181352428231227],
            [106.81580531938441, -6.1814100810410455],
            [106.81542194618567, -6.181505551952464],
            [106.81504983137033, -6.1816379215485755],
            [106.81469255854095, -6.181805915064943],
            [106.81435356837561, -6.182007914665237],
            [106.81403612549425, -6.182241975020602],
            [106.8137432870204, -6.182505842042806],
            [106.81347787314019, -6.1827969745907545],
            [106.81324243994274, -6.18311256894144],
            [106.81303925480344, -6.1834495857896945],
            [106.81287027454677, -6.183804779516779],
            [106.81273712659959, -6.1841747294460205],
            [106.81264109331589, -6.184555872784523],
            [106.81258309962463, -6.184944538933773],
            [106.81256370411913, -6.1853369848387265],
            [106.81258309367435, -6.185729431034999],
            [106.81264108164402, -6.186118098047014],
            [106.81273710965462, -6.186499242786568],
            [106.81287025297995, -6.186869194601308],
            [106.81303922944352, -6.187224390625879],
            [106.81324241176432, -6.187561410095317],
            [106.81347784322612, -6.1878770072901546],
            [106.81374325652031, -6.188168142795921],
            [106.81403609558019, -6.188432012775942],
            [106.81435354019717, -6.1886660759754575],
            [106.81469253318105, -6.188868078196936],
            [106.81504980980348, -6.189036074010792],
            [106.8154219292407, -6.1891684454924025],
            [106.81580530771252, -6.189263917804871],
            [106.8161962529987, -6.189321571477453],
            [106.816591, -6.189340851261332],
            [106.81698574700131, -6.189321571477453],
            [106.81737669228748, -6.189263917804871],
            [106.8177600707593, -6.1891684454924025],
            [106.81813219019652, -6.189036074010792],
            [106.81848946681896, -6.188868078196936],
            [106.81882845980283, -6.1886660759754575],
            [106.81914590441981, -6.188432012775942],
            [106.8194387434797, -6.188168142795921],
            [106.81970415677388, -6.1878770072901546],
            [106.81993958823568, -6.187561410095317],
            [106.82014277055649, -6.187224390625879],
            [106.82031174702006, -6.186869194601308],
            [106.82044489034539, -6.186499242786568],
            [106.820540918356, -6.186118098047014],
            [106.82059890632566, -6.185729431034999],
            [106.82061829588088, -6.1853369848387265],
            [106.82059890037537, -6.184944538933773],
            [106.82054090668412, -6.184555872784523],
            [106.82044487340042, -6.1841747294460205],
            [106.82031172545324, -6.183804779516779],
            [106.82014274519658, -6.1834495857896945],
            [106.81993956005726, -6.18311256894144],
            [106.81970412685982, -6.1827969745907545],
            [106.8194387129796, -6.182505842042806],
            [106.81914587450575, -6.182241975020602],
            [106.8188284316244, -6.182007914665237],
            [106.81848944145905, -6.181805915064943],
            [106.81813216862967, -6.1816379215485755],
            [106.81776005381434, -6.181505551952464],
            [106.8173766806156, -6.1814100810410455],
            [106.81698574105103, -6.181352428231227],
            [106.816591, -6.181333148738668],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.82659100000002, -6.191373361419167],
            [106.82620021610825, -6.191392447279357],
            [106.82581319559493, -6.191449521056772],
            [106.82543366559831, -6.191544033111927],
            [106.82506528112522, -6.191675073261853],
            [106.8247115898542, -6.191841379544908],
            [106.82437599797196, -6.19204135037313],
            [106.82406173737192, -6.192273059955162],
            [106.82377183453089, -6.192534276841244],
            [106.8235090813634, -6.192822485411762],
            [106.82327600833437, -6.193134910102428],
            [106.82307486008898, -6.193468542132876],
            [106.82290757383456, -6.193820168481321],
            [106.8227757606828, -6.194186402826285],
            [106.82268069013166, -6.194563718157485],
            [106.8226232778371, -6.194948480741838],
            [106.8226040767917, -6.195336985117533],
            [106.82262327199612, -6.195725489779188],
            [106.8226806786742, -6.196110253210439],
            [106.82277574404912, -6.196487569916926],
            [106.82290755266393, -6.196853806112717],
            [106.82307483519496, -6.1972054347164],
            [106.82327598067361, -6.197539069319831],
            [106.8235090519989, -6.197851496802346],
            [106.82377180459108, -6.198139708276288],
            [106.82406170800739, -6.198400928065796],
            [106.8243759703112, -6.198632640439675],
            [106.82471156496018, -6.198832613840882],
            [106.82506525995457, -6.198998922379175],
            [106.82543364896463, -6.199129964379925],
            [106.82581318413747, -6.1992244778103665],
            [106.8262002102673, -6.199281552434681],
            [106.82659100000002, -6.1993006385808345],
            [106.82698178973274, -6.199281552434681],
            [106.82736881586256, -6.1992244778103665],
            [106.8277483510354, -6.199129964379925],
            [106.82811674004544, -6.198998922379175],
            [106.82847043503985, -6.198832613840882],
            [106.82880602968883, -6.198632640439675],
            [106.82912029199264, -6.198400928065796],
            [106.82941019540895, -6.198139708276288],
            [106.82967294800113, -6.197851496802346],
            [106.82990601932643, -6.197539069319831],
            [106.83010716480509, -6.1972054347164],
            [106.8302744473361, -6.196853806112717],
            [106.8304062559509, -6.196487569916926],
            [106.83050132132583, -6.196110253210439],
            [106.83055872800391, -6.195725489779188],
            [106.83057792320832, -6.195336985117533],
            [106.83055872216293, -6.194948480741838],
            [106.83050130986837, -6.194563718157485],
            [106.83040623931723, -6.194186402826285],
            [106.83027442616547, -6.193820168481321],
            [106.83010713991105, -6.193468542132876],
            [106.82990599166565, -6.193134910102428],
            [106.82967291863662, -6.192822485411762],
            [106.82941016546914, -6.192534276841244],
            [106.82912026262812, -6.192273059955162],
            [106.82880600202807, -6.19204135037313],
            [106.82847041014583, -6.191841379544908],
            [106.82811671887482, -6.191675073261853],
            [106.82774833440172, -6.191544033111927],
            [106.8273688044051, -6.191449521056772],
            [106.82698178389178, -6.191392447279357],
            [106.82659100000002, -6.191373361419167],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.836591, -6.194241619837501],
            [106.83648300346388, -6.194246894381463],
            [106.836376046987, -6.194262667216917],
            [106.83627116061255, -6.194288786443772],
            [106.83616935444797, -6.194325000521103],
            [106.83607160893726, -6.194370960689586],
            [106.83597886541905, -6.1944262243301536],
            [106.83589201706104, -6.194490259226565],
            [106.83581190025856, -6.194562448690832],
            [106.83573928657955, -6.194642097502148],
            [106.8356748753341, -6.194728438602108],
            [106.83561928683956, -6.194820640481791],
            [106.83557305644658, -6.194917815189525],
            [106.83553662938318, -6.195019026882231],
            [106.83551035646693, -6.195123300838018],
            [106.83549449072602, -6.195229632843202],
            [106.83548918496241, -6.195336998863376],
            [106.83549449027991, -6.195444364905391],
            [106.83551035559188, -6.195550696975255],
            [106.83553662811282, -6.195654971036078],
            [106.83557305482971, -6.195756182870139],
            [106.83561928493833, -6.195853357750113],
            [106.83567487322156, -6.195945559826303],
            [106.8357392843369, -6.196031901139485],
            [106.83581189797195, -6.196111550172544],
            [106.83589201481837, -6.196183739858557],
            [106.83597886330648, -6.1962477749681915],
            [106.83607160703602, -6.196303038805265],
            [106.83616935283109, -6.196348999145989],
            [106.83627115934219, -6.196385213364674],
            [106.83637604611195, -6.196411332696562],
            [106.83648300301778, -6.196427105596698],
            [106.836591, -6.1964323801624985],
            [106.83669899698221, -6.196427105596698],
            [106.83680595388803, -6.196411332696562],
            [106.8369108406578, -6.196385213364674],
            [106.83701264716889, -6.196348999145989],
            [106.83711039296395, -6.196303038805265],
            [106.83720313669349, -6.1962477749681915],
            [106.83728998518161, -6.196183739858557],
            [106.83737010202802, -6.196111550172544],
            [106.83744271566309, -6.196031901139485],
            [106.83750712677842, -6.195945559826303],
            [106.83756271506167, -6.195853357750113],
            [106.83760894517027, -6.195756182870139],
            [106.83764537188715, -6.195654971036078],
            [106.83767164440809, -6.195550696975255],
            [106.83768750972006, -6.195444364905391],
            [106.83769281503756, -6.195336998863376],
            [106.83768750927396, -6.195229632843202],
            [106.83767164353306, -6.195123300838018],
            [106.8376453706168, -6.195019026882231],
            [106.8376089435534, -6.194917815189525],
            [106.83756271316041, -6.194820640481791],
            [106.83750712466586, -6.194728438602108],
            [106.83744271342042, -6.194642097502148],
            [106.83737009974143, -6.194562448690832],
            [106.83728998293893, -6.194490259226565],
            [106.83720313458095, -6.1944262243301536],
            [106.83711039106271, -6.194370960689586],
            [106.83701264555201, -6.194325000521103],
            [106.83691083938741, -6.194288786443772],
            [106.83680595301298, -6.194262667216917],
            [106.83669899653611, -6.194246894381463],
            [106.836591, -6.194241619837501],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.82659100000002, -6.184232075711283],
            [106.82648206454613, -6.184237396212647],
            [106.82637417819366, -6.184253306477722],
            [106.82626837994094, -6.184279653282906],
            [106.82616568867716, -6.1843161828955955],
            [106.82606709337028, -6.1843625435177225],
            [106.82597354354283, -6.184418288673667],
            [106.82588594012769, -6.18448288150998],
            [106.82580512679175, -6.184555699965458],
            [106.82573188181097, -6.1846360427618094],
            [106.8256669105752, -6.184723136157228],
            [106.8256108387948, -6.184816141397826],
            [106.82556420647485, -6.184914162795169],
            [106.82552746271419, -6.185016256352138],
            [106.8255009613804, -6.185121438854034],
            [106.8254849577016, -6.185228697337404],
            [106.82547960580821, -6.185336998845364],
            [106.82548495724845, -6.185445300375512],
            [106.82550096049151, -6.185552558924587],
            [106.82552746142373, -6.185657741533182],
            [106.8255642048324, -6.185759835233744],
            [106.82561083686349, -6.1858578568060585],
            [106.82566690842921, -6.185950862246277],
            [106.82573187953281, -6.186037955858299],
            [106.82580512446897, -6.186118298879907],
            [106.82588593784953, -6.186191117560643],
            [106.82597354139683, -6.186255710613557],
            [106.82606709143896, -6.186311455969124],
            [106.8261656870347, -6.186357816766219],
            [106.82626837865047, -6.186394346522503],
            [106.82637417730479, -6.186420693434386],
            [106.82648206409296, -6.186436603765167],
            [106.82659100000002, -6.186441924288717],
            [106.82669993590707, -6.186436603765167],
            [106.82680782269526, -6.186420693434386],
            [106.82691362134956, -6.186394346522503],
            [106.82701631296533, -6.186357816766219],
            [106.82711490856107, -6.186311455969124],
            [106.8272084586032, -6.186255710613557],
            [106.8272960621505, -6.186191117560643],
            [106.82737687553106, -6.186118298879907],
            [106.82745012046722, -6.186037955858299],
            [106.82751509157082, -6.185950862246277],
            [106.82757116313654, -6.1858578568060585],
            [106.82761779516763, -6.185759835233744],
            [106.82765453857631, -6.185657741533182],
            [106.82768103950853, -6.185552558924587],
            [106.8276970427516, -6.185445300375512],
            [106.82770239419182, -6.185336998845364],
            [106.82769704229842, -6.185228697337404],
            [106.82768103861963, -6.185121438854034],
            [106.82765453728584, -6.185016256352138],
            [106.82761779352518, -6.184914162795169],
            [106.82757116120521, -6.184816141397826],
            [106.82751508942484, -6.184723136157228],
            [106.82745011818906, -6.1846360427618094],
            [106.82737687320828, -6.184555699965458],
            [106.82729605987234, -6.18448288150998],
            [106.82720845645721, -6.184418288673667],
            [106.82711490662976, -6.1843625435177225],
            [106.82701631132286, -6.1843161828955955],
            [106.82691362005909, -6.184279653282906],
            [106.82680782180637, -6.184253306477722],
            [106.82669993545392, -6.184237396212647],
            [106.82659100000002, -6.184232075711283],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.836591, -6.184356925730616],
            [106.8364943735962, -6.1843616450473755],
            [106.83639867775237, -6.184375757548311],
            [106.83630483406685, -6.184399127323067],
            [106.83621374630107, -6.184431529309142],
            [106.83612629167604, -6.184472651459329],
            [106.83604331242434, -6.184522097746821],
            [106.83596560767906, -6.184579391979102],
            [106.83589392577791, -6.1846439823838475],
            [106.83582895705625, -6.184715246922704],
            [106.83577132719893, -6.184792499281755],
            [106.83572159121442, -6.18487499548101],
            [106.83568022808991, -6.184961941039231],
            [106.8356476361782, -6.185052498625146],
            [106.83562412936111, -6.185145796121327],
            [106.83560993402678, -6.185240935023094],
            [106.83560518688893, -6.1853369990915565],
            [106.83560993367026, -6.185433063177476],
            [106.83562412866175, -6.1855282021309375],
            [106.83564763516286, -6.185621499711068],
            [106.83568022679768, -6.185712057409961],
            [106.8357215896949, -6.185799003105845],
            [106.83577132551049, -6.185881499462156],
            [106.83582895526385, -6.185958751991627],
            [106.8358939239504, -6.1860300167077105],
            [106.83596560588664, -6.186094607289686],
            [106.83604331073593, -6.186151901692384],
            [106.83612629015651, -6.186201348136934],
            [106.83621374500882, -6.186242470424782],
            [106.83630483305154, -6.1862748725238355],
            [106.83639867705301, -6.18629824238254],
            [106.83649437323967, -6.18631235493517],
            [106.836591, -6.186317074269386],
            [106.83668762676031, -6.18631235493517],
            [106.83678332294696, -6.18629824238254],
            [106.83687716694845, -6.1862748725238355],
            [106.83696825499115, -6.186242470424782],
            [106.83705570984347, -6.186201348136934],
            [106.83713868926405, -6.186151901692384],
            [106.83721639411333, -6.186094607289686],
            [106.83728807604959, -6.1860300167077105],
            [106.83735304473613, -6.185958751991627],
            [106.83741067448948, -6.185881499462156],
            [106.83746041030508, -6.185799003105845],
            [106.83750177320232, -6.185712057409961],
            [106.83753436483711, -6.185621499711068],
            [106.83755787133822, -6.1855282021309375],
            [106.83757206632973, -6.185433063177476],
            [106.83757681311104, -6.1853369990915565],
            [106.8375720659732, -6.185240935023094],
            [106.83755787063886, -6.185145796121327],
            [106.8375343638218, -6.185052498625146],
            [106.83750177191006, -6.184961941039231],
            [106.83746040878555, -6.18487499548101],
            [106.83741067280107, -6.184792499281755],
            [106.83735304294372, -6.184715246922704],
            [106.83728807422206, -6.1846439823838475],
            [106.83721639232091, -6.184579391979102],
            [106.83713868757565, -6.184522097746821],
            [106.83705570832393, -6.184472651459329],
            [106.8369682536989, -6.184431529309142],
            [106.83687716593313, -6.184399127323067],
            [106.83678332224761, -6.184375757548311],
            [106.83668762640377, -6.1843616450473755],
            [106.836591, -6.184356925730616],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.84659099999999, -6.1932383495371175],
            [106.84638408868314, -6.193248455072636],
            [106.84617917001182, -6.193278674358517],
            [106.84597821744218, -6.1933287163699315],
            [106.84578316623642, -6.193398099180507],
            [106.84559589482595, -6.193486154603316],
            [106.8454182067218, -6.1935920346256035],
            [106.84525181314643, -6.19371471957526],
            [106.84509831655399, -6.193853027940418],
            [106.84495919519827, -6.194005627747636],
            [106.84483578889629, -6.194171049389068],
            [106.84472928612509, -6.194347699775148],
            [106.84464071257572, -6.194533877676477],
            [106.84457092127494, -6.194727790107197],
            [106.84452058436938, -6.194927569592066],
            [106.8444901866519, -6.19513129215099],
            [106.84448002089175, -6.195336995827782],
            [106.8444901850144, -6.195542699584742],
            [106.84452058115734, -6.19574642238109],
            [106.84457091661177, -6.1959462022515135],
            [106.84464070664066, -6.196140115201102],
            [106.8447292791462, -6.196326293734675],
            [106.84483578114175, -6.196502944842076],
            [106.84495918696608, -6.196668367266187],
            [106.84509830816053, -6.196820967887366],
            [106.84525180491424, -6.196959277066483],
            [106.84541819896728, -6.197081962798818],
            [106.84559588784704, -6.1971878435424275],
            [106.84578316030135, -6.197275899597481],
            [106.84597821277902, -6.197345282926925],
            [106.8461791667998, -6.197395325323895],
            [106.84638408704566, -6.197425544847197],
            [106.84659099999999, -6.197435650462885],
            [106.84679791295432, -6.197425544847197],
            [106.84700283320018, -6.197395325323895],
            [106.84720378722096, -6.197345282926925],
            [106.84739883969863, -6.197275899597481],
            [106.84758611215294, -6.1971878435424275],
            [106.8477638010327, -6.197081962798818],
            [106.84793019508571, -6.196959277066483],
            [106.84808369183945, -6.196820967887366],
            [106.84822281303387, -6.196668367266187],
            [106.84834621885823, -6.196502944842076],
            [106.84845272085379, -6.196326293734675],
            [106.84854129335932, -6.196140115201102],
            [106.8486110833882, -6.1959462022515135],
            [106.84866141884264, -6.19574642238109],
            [106.84869181498559, -6.195542699584742],
            [106.84870197910823, -6.195336995827782],
            [106.84869181334808, -6.19513129215099],
            [106.8486614156306, -6.194927569592066],
            [106.84861107872504, -6.194727790107197],
            [106.84854128742425, -6.194533877676477],
            [106.84845271387489, -6.194347699775148],
            [106.84834621110367, -6.194171049389068],
            [106.84822280480171, -6.194005627747636],
            [106.84808368344599, -6.193853027940418],
            [106.84793018685355, -6.19371471957526],
            [106.84776379327818, -6.1935920346256035],
            [106.84758610517403, -6.193486154603316],
            [106.84739883376356, -6.193398099180507],
            [106.8472037825578, -6.1933287163699315],
            [106.84700282998816, -6.193278674358517],
            [106.84679791131684, -6.193248455072636],
            [106.84659099999999, -6.1932383495371175],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.85659100000001, -6.184300904564657],
            [106.85648885042984, -6.184305893637619],
            [106.85638768461013, -6.184320812809284],
            [106.85628847681755, -6.184345518400706],
            [106.85619218247223, -6.184379772484885],
            [106.85609972893693, -6.184423245178081],
            [106.85601200658607, -6.184475517816713],
            [106.85592986023113, -6.184536086989219],
            [106.85585408098474, -6.184604369384102],
            [106.8557853986419, -6.184679707407421],
            [106.85572447465167, -6.184761375515683],
            [106.85567189574708, -6.184848587203103],
            [106.85562816829442, -6.184940502575981],
            [106.85559371341661, -6.185036236441249],
            [106.85556886293745, -6.185134866831267],
            [106.8555538561857, -6.1852354438828145],
            [106.85554883769008, -6.185336998984735],
            [106.85555385578722, -6.185438554106164],
            [106.85556886215585, -6.185539131215486],
            [106.85559371228192, -6.185637761699325],
            [106.85562816685021, -6.185733495690854],
            [106.85567189404887, -6.185825411217581],
            [106.85572447276472, -6.185912623080527],
            [106.85578539663871, -6.185994291379246],
            [106.85585407894233, -6.186069629600634],
            [106.85592985822795, -6.186137912193585],
            [106.85601200469912, -6.1861984815565485],
            [106.85609972723871, -6.186250754370706],
            [106.85619218102802, -6.1862942272177515],
            [106.85628847568283, -6.186328481428191],
            [106.85638768382853, -6.186353187113434],
            [106.85648885003138, -6.186368106342872],
            [106.85659100000001, -6.186373095435343],
            [106.85669314996863, -6.186368106342872],
            [106.85679431617146, -6.186353187113434],
            [106.85689352431716, -6.186328481428191],
            [106.85698981897197, -6.1862942272177515],
            [106.85708227276128, -6.186250754370706],
            [106.85716999530088, -6.1861984815565485],
            [106.85725214177204, -6.186137912193585],
            [106.85732792105767, -6.186069629600634],
            [106.8573966033613, -6.185994291379246],
            [106.8574575272353, -6.185912623080527],
            [106.85751010595115, -6.185825411217581],
            [106.85755383314981, -6.185733495690854],
            [106.8575882877181, -6.185637761699325],
            [106.85761313784417, -6.185539131215486],
            [106.85762814421277, -6.185438554106164],
            [106.85763316230991, -6.185336998984735],
            [106.8576281438143, -6.1852354438828145],
            [106.85761313706254, -6.185134866831267],
            [106.85758828658338, -6.185036236441249],
            [106.85755383170559, -6.184940502575981],
            [106.85751010425292, -6.184848587203103],
            [106.85745752534832, -6.184761375515683],
            [106.85739660135812, -6.184679707407421],
            [106.85732791901526, -6.184604369384102],
            [106.85725213976887, -6.184536086989219],
            [106.85716999341393, -6.184475517816713],
            [106.85708227106306, -6.184423245178081],
            [106.85698981752778, -6.184379772484885],
            [106.85689352318245, -6.184345518400706],
            [106.85679431538986, -6.184320812809284],
            [106.85669314957016, -6.184305893637619],
            [106.85659100000001, -6.184300904564657],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.866591, -6.181944749675711],
            [106.86625655650748, -6.18196108418368],
            [106.86592533382712, -6.182009930400356],
            [106.86560052175531, -6.182090817918881],
            [106.86528524835536, -6.182202967762709],
            [106.86498254983469, -6.182345299887015],
            [106.86469534130633, -6.182516443579375],
            [106.86442638871628, -6.182714750659554],
            [106.86417828220706, -6.18293831135135],
            [106.86395341117392, -6.183184972673658],
            [106.86375394125386, -6.183452359173669],
            [106.86358179346917, -6.183737895802614],
            [106.86343862572625, -6.18403883271376],
            [106.86332581684786, -6.184352271743891],
            [106.863244453293, -6.184675194323302],
            [106.8631953186916, -6.185004490545514],
            [106.8631788862959, -6.185336989116825],
            [106.86319531442034, -6.185669487897253],
            [106.8632444449146, -6.185998784738781],
            [106.86332580468432, -6.186321708323905],
            [106.86343861024497, -6.1866351487074995],
            [106.86358177526515, -6.186936087267844],
            [106.86375392102663, -6.187221625778346],
            [106.8639533897008, -6.187489014319965],
            [106.86417826031325, -6.187735677765475],
            [106.86442636724317, -6.187959240580474],
            [106.86469532107908, -6.188157549702261],
            [106.86498253163066, -6.188328695276177],
            [106.86528523287409, -6.188471029049683],
            [106.86560050959176, -6.188583180246974],
            [106.86592532544874, -6.188664068771214],
            [106.86625655223621, -6.188712915607203],
            [106.866591, -6.188729250324289],
            [106.86692544776379, -6.188712915607203],
            [106.86725667455126, -6.188664068771214],
            [106.86758149040824, -6.188583180246974],
            [106.86789676712591, -6.188471029049683],
            [106.86819946836934, -6.188328695276177],
            [106.86848667892092, -6.188157549702261],
            [106.86875563275683, -6.187959240580474],
            [106.86900373968675, -6.187735677765475],
            [106.8692286102992, -6.187489014319965],
            [106.86942807897337, -6.187221625778346],
            [106.86960022473485, -6.186936087267844],
            [106.86974338975503, -6.1866351487074995],
            [106.86985619531568, -6.186321708323905],
            [106.8699375550854, -6.185998784738781],
            [106.86998668557966, -6.185669487897253],
            [106.8700031137041, -6.185336989116825],
            [106.8699866813084, -6.185004490545514],
            [106.86993754670699, -6.184675194323302],
            [106.86985618315214, -6.184352271743891],
            [106.86974337427375, -6.18403883271376],
            [106.86960020653083, -6.183737895802614],
            [106.86942805874614, -6.183452359173669],
            [106.86922858882608, -6.183184972673658],
            [106.86900371779294, -6.18293831135135],
            [106.86875561128372, -6.182714750659554],
            [106.86848665869367, -6.182516443579375],
            [106.8681994501653, -6.182345299887015],
            [106.86789675164464, -6.182202967762709],
            [106.86758147824469, -6.182090817918881],
            [106.86725666617288, -6.182009930400356],
            [106.86692544349252, -6.18196108418368],
            [106.866591, -6.181944749675711],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.84659099999999, -6.162446743453868],
            [106.84630605878876, -6.162460660753482],
            [106.84602386167263, -6.162502278623299],
            [106.84574712632138, -6.16257119626693],
            [106.84547851780829, -6.162666749980267],
            [106.84522062294559, -6.162788019542913],
            [106.84497592537343, -6.162933837079861],
            [106.84474678164214, -6.163102798308116],
            [106.84453539851809, -6.1632932760599495],
            [106.84434381173197, -6.163503435952603],
            [106.84417386637362, -6.163731254053543],
            [106.84402719912268, -6.163974536371188],
            [106.84390522248604, -6.1642309399834305],
            [106.84380911119374, -6.164497995600505],
            [106.84373979088464, -6.164773131344937],
            [106.84369792919063, -6.165053697519588],
            [106.84368392930544, -6.16533699212528],
            [106.84369792610018, -6.165620286882283],
            [106.84373978482252, -6.1659008535050495],
            [106.84380910239292, -6.166175989977184],
            [106.8439052112847, -6.16644304657358],
            [106.8440271859513, -6.166699451379131],
            [106.84417385173838, -6.166942735058211],
            [106.84434379619529, -6.167170554636394],
            [106.84453538267704, -6.16738071606533],
            [106.84474676610546, -6.167571195353445],
            [106.8449759107382, -6.167740158058943],
            [106.84522060977423, -6.167885976957327],
            [106.84547850660697, -6.16800724771328],
            [106.84574711752057, -6.16810280240594],
            [106.84602385561054, -6.1681717207772735],
            [106.8463060556983, -6.168213339095208],
            [106.84659099999999, -6.168227256546132],
            [106.84687594430167, -6.168213339095208],
            [106.84715814438945, -6.1681717207772735],
            [106.84743488247942, -6.16810280240594],
            [106.84770349339301, -6.16800724771328],
            [106.84796139022575, -6.167885976957327],
            [106.84820608926178, -6.167740158058943],
            [106.84843523389452, -6.167571195353445],
            [106.84864661732294, -6.16738071606533],
            [106.84883820380469, -6.167170554636394],
            [106.8490081482616, -6.166942735058211],
            [106.84915481404867, -6.166699451379131],
            [106.84927678871524, -6.16644304657358],
            [106.84937289760705, -6.166175989977184],
            [106.84944221517746, -6.1659008535050495],
            [106.8494840738998, -6.165620286882283],
            [106.84949807069454, -6.16533699212528],
            [106.84948407080937, -6.165053697519588],
            [106.84944220911534, -6.164773131344937],
            [106.84937288880622, -6.164497995600505],
            [106.84927677751395, -6.1642309399834305],
            [106.84915480087732, -6.163974536371188],
            [106.84900813362636, -6.163731254053543],
            [106.848838188268, -6.163503435952603],
            [106.84864660148187, -6.1632932760599495],
            [106.84843521835785, -6.163102798308116],
            [106.84820607462653, -6.162933837079861],
            [106.84796137705439, -6.162788019542913],
            [106.84770348219169, -6.162666749980267],
            [106.8474348736786, -6.16257119626693],
            [106.84715813832734, -6.162502278623299],
            [106.84687594121122, -6.162460660753482],
            [106.84659099999999, -6.162446743453868],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.84659099999999, -6.152423463327123],
            [106.84630376909591, -6.152437492725813],
            [106.84601930433733, -6.152479445813313],
            [106.84574034523197, -6.152548918564999],
            [106.84546957826852, -6.15264524193092],
            [106.84520961104579, -6.15276748827871],
            [106.84496294716148, -6.152914480326634],
            [106.84473196210224, -6.1530848024808],
            [106.84451888036727, -6.153276814467328],
            [106.84432575404576, -6.153488667128261],
            [106.84415444305415, -6.153718320229102],
            [106.8440065972241, -6.153963562106528],
            [106.84388364041313, -6.154222030967081],
            [106.84378675679135, -6.1544912376317775],
            [106.843716879436, -6.15476858950758],
            [106.84367468134425, -6.155051415554949],
            [106.84366056895018, -6.155336992010992],
            [106.84367467820903, -6.155622568620539],
            [106.84371687328603, -6.155905395122529],
            [106.84378674786295, -6.1561827477365965],
            [106.84388362904946, -6.156451955394829],
            [106.8440065838618, -6.156710425466009],
            [106.84415442820676, -6.156955668724627],
            [106.84432573828389, -6.157185323324152],
            [106.84451886429662, -6.157397177543663],
            [106.84473194634035, -6.157589191088769],
            [106.8449629323141, -6.157759514741618],
            [106.84520959768352, -6.157906508170737],
            [106.84546956690484, -6.158028755729152],
            [106.84574033630359, -6.1581250800886105],
            [106.84601929818734, -6.158194553578559],
            [106.84630376596068, -6.15823650712068],
            [106.84659099999999, -6.158250536672876],
            [106.8468782340393, -6.15823650712068],
            [106.84716270181264, -6.158194553578559],
            [106.84744166369639, -6.1581250800886105],
            [106.84771243309514, -6.158028755729152],
            [106.84797240231646, -6.157906508170737],
            [106.84821906768587, -6.157759514741618],
            [106.84845005365962, -6.157589191088769],
            [106.84866313570336, -6.157397177543663],
            [106.84885626171611, -6.157185323324152],
            [106.84902757179321, -6.156955668724627],
            [106.84917541613815, -6.156710425466009],
            [106.84929837095052, -6.156451955394829],
            [106.84939525213703, -6.1561827477365965],
            [106.84946512671395, -6.155905395122529],
            [106.84950732179094, -6.155622568620539],
            [106.84952143104978, -6.155336992010992],
            [106.84950731865571, -6.155051415554949],
            [106.84946512056398, -6.15476858950758],
            [106.84939524320863, -6.1544912376317775],
            [106.84929835958684, -6.154222030967081],
            [106.84917540277588, -6.153963562106528],
            [106.84902755694584, -6.153718320229102],
            [106.84885624595422, -6.153488667128261],
            [106.8486631196327, -6.153276814467328],
            [106.84845003789773, -6.1530848024808],
            [106.8482190528385, -6.152914480326634],
            [106.84797238895419, -6.15276748827871],
            [106.84771242173146, -6.15264524193092],
            [106.847441654768, -6.152548918564999],
            [106.84716269566263, -6.152479445813313],
            [106.84687823090405, -6.152437492725813],
            [106.84659099999999, -6.152423463327123],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.836591, -6.171669450565981],
            [106.83622942166086, -6.171687110696955],
            [106.83587132544706, -6.171739921016672],
            [106.83552015995187, -6.171827372943287],
            [106.83517930702745, -6.1719486242838295],
            [106.83485204921799, -6.17210250734434],
            [106.83454153814934, -6.172287540174522],
            [106.8342507641789, -6.172501940838675],
            [106.833982527598, -6.172743644575494],
            [106.8337394116644, -6.173010323681533],
            [106.83352375772455, -6.173299409926886],
            [106.83333764266466, -6.173608119287283],
            [106.83318285890847, -6.173933478754443],
            [106.8330608971539, -6.174272354966556],
            [106.83297293201502, -6.1746214843831995],
            [106.83291981070779, -6.174977504714151],
            [106.83290204488847, -6.175336987299419],
            [106.83291980572335, -6.175696470128726],
            [106.83297292223769, -6.176052491182414],
            [106.83306088295939, -6.176401621772716],
            [106.83318284084231, -6.176740499564309],
            [106.83333762142111, -6.1770658609560725],
            [106.83352373411996, -6.177374572512233],
            [106.8337393866059, -6.1776636611401265],
            [106.83398250204856, -6.1779303427239265],
            [106.8342507391204, -6.178172048938507],
            [106.83454151454477, -6.178386451985201],
            [106.8348520279744, -6.178571487011145],
            [106.83517928896127, -6.178725371996257],
            [106.83552014575739, -6.17884662491628],
            [106.83587131566972, -6.178934078016555],
            [106.83622941667643, -6.178986889059009],
            [106.836591, -6.17900454943402],
            [106.83695258332357, -6.178986889059009],
            [106.83731068433025, -6.178934078016555],
            [106.83766185424258, -6.17884662491628],
            [106.8380027110387, -6.178725371996257],
            [106.83832997202559, -6.178571487011145],
            [106.83864048545522, -6.178386451985201],
            [106.83893126087958, -6.178172048938507],
            [106.83919949795141, -6.1779303427239265],
            [106.83944261339407, -6.1776636611401265],
            [106.83965826588002, -6.177374572512233],
            [106.83984437857889, -6.1770658609560725],
            [106.83999915915767, -6.176740499564309],
            [106.84012111704058, -6.176401621772716],
            [106.84020907776231, -6.176052491182414],
            [106.84026219427662, -6.175696470128726],
            [106.84027995511153, -6.175336987299419],
            [106.84026218929219, -6.174977504714151],
            [106.84020906798497, -6.1746214843831995],
            [106.8401211028461, -6.174272354966556],
            [106.83999914109151, -6.173933478754443],
            [106.83984435733532, -6.173608119287283],
            [106.83965824227543, -6.173299409926886],
            [106.83944258833557, -6.173010323681533],
            [106.839199472402, -6.172743644575494],
            [106.83893123582108, -6.172501940838675],
            [106.83864046185063, -6.172287540174522],
            [106.83832995078201, -6.17210250734434],
            [106.83800269297255, -6.1719486242838295],
            [106.8376618400481, -6.171827372943287],
            [106.83731067455292, -6.171739921016672],
            [106.83695257833911, -6.171687110696955],
            [106.836591, -6.171669450565981],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.836591, -6.162321311721901],
            [106.83629369294269, -6.1623358330028815],
            [106.83599924906648, -6.1623792570003175],
            [106.83571050398021, -6.1624511655243985],
            [106.83543023841402, -6.162550866068131],
            [106.8351611514409, -6.162677398476123],
            [106.83490583448462, -6.162829544190813],
            [106.83466674636409, -6.16300583798713],
            [106.83444618961445, -6.163204582082598],
            [106.83424628831301, -6.163423862487005],
            [106.83406896762334, -6.1636615674342705],
            [106.83391593525472, -6.16391540771896],
            [106.83378866501546, -6.164182938741711],
            [106.83368838261839, -6.164461584051237],
            [106.83361605387545, -6.164748660156241],
            [106.83357237539485, -6.165041402368312],
            [106.83355776787087, -6.165336991426953],
            [106.83357237203033, -6.165632580650323],
            [106.83361604727574, -6.1659253233502485],
            [106.8336883730371, -6.166212400247488],
            [106.83378865282081, -6.1664910466231815],
            [106.83391592091533, -6.166758578945063],
            [106.83406895169027, -6.167012420711918],
            [106.83424627139856, -6.167250127267428],
            [106.83444617236862, -6.1674694093443545],
            [106.83466672944962, -6.167668155112341],
            [106.83490581855153, -6.167844450516904],
            [106.83516113710152, -6.16799659771376],
            [106.83543022621937, -6.168123131420881],
            [106.83571049439894, -6.168222833030782],
            [106.83599924246677, -6.168294742347097],
            [106.8362936895782, -6.168338166832389],
            [106.836591, -6.168352688278098],
            [106.83688831042177, -6.168338166832389],
            [106.83718275753323, -6.168294742347097],
            [106.83747150560104, -6.168222833030782],
            [106.83775177378061, -6.168123131420881],
            [106.83802086289847, -6.16799659771376],
            [106.83827618144844, -6.167844450516904],
            [106.83851527055036, -6.167668155112341],
            [106.83873582763137, -6.1674694093443545],
            [106.83893572860143, -6.167250127267428],
            [106.8391130483097, -6.167012420711918],
            [106.83926607908465, -6.166758578945063],
            [106.83939334717918, -6.1664910466231815],
            [106.83949362696286, -6.166212400247488],
            [106.83956595272426, -6.1659253233502485],
            [106.83960962796964, -6.165632580650323],
            [106.83962423212913, -6.165336991426953],
            [106.83960962460512, -6.165041402368312],
            [106.83956594612455, -6.164748660156241],
            [106.83949361738159, -6.164461584051237],
            [106.83939333498452, -6.164182938741711],
            [106.83926606474525, -6.16391540771896],
            [106.83911303237663, -6.1636615674342705],
            [106.83893571168696, -6.163423862487005],
            [106.83873581038553, -6.163204582082598],
            [106.8385152536359, -6.16300583798713],
            [106.83827616551535, -6.162829544190813],
            [106.83802084855908, -6.162677398476123],
            [106.83775176158595, -6.162550866068131],
            [106.83747149601977, -6.1624511655243985],
            [106.83718275093352, -6.1623792570003175],
            [106.83688830705728, -6.1623358330028815],
            [106.836591, -6.162321311721901],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.836591, -6.154103711891956],
            [106.83646941595532, -6.154109650497537],
            [106.83634900282338, -6.154127409122656],
            [106.8362309202407, -6.154156816743218],
            [106.83611630539988, -6.1541975901496695],
            [106.83600626209824, -6.154249336674375],
            [106.83590185010777, -6.154311557973139],
            [106.83580407496922, -6.154383654824405],
            [106.8357138783082, -6.154464932899948],
            [106.8356321287671, -6.154554609451489],
            [106.83555961363946, -6.15465182084884],
            [106.83549703128801, -6.154755630896965],
            [106.8354449844188, -6.154865039851917],
            [106.83540397427677, -6.154978994048778],
            [106.83537439581832, -6.155096396048897],
            [106.83535653390733, -6.155216115208743],
            [106.83535056057158, -6.155336998568534],
            [106.83535653334557, -6.155457881955829],
            [106.83537439471638, -6.155577601197134],
            [106.835403972677, -6.1556950033295355],
            [106.83544498238265, -6.155808957704417],
            [106.83549702889377, -6.155918366876289],
            [106.83555961097913, -6.156022177171896],
            [106.8356321259429, -6.156119388837779],
            [106.83571387542865, -6.156209065668586],
            [106.83580407214501, -6.156290344023394],
            [106.83590184744745, -6.156362441143194],
            [106.836006259704, -6.156424662689438],
            [106.83611630336375, -6.156476409431064],
            [106.8362309186409, -6.156517183015534],
            [106.83634900172144, -6.15654659076838],
            [106.83646941539355, -6.156564349474958],
            [106.836591, -6.156570288108042],
            [106.83671258460642, -6.156564349474958],
            [106.83683299827854, -6.15654659076838],
            [106.83695108135908, -6.156517183015534],
            [106.83706569663624, -6.156476409431064],
            [106.83717574029596, -6.156424662689438],
            [106.83728015255252, -6.156362441143194],
            [106.83737792785496, -6.156290344023394],
            [106.83746812457132, -6.156209065668586],
            [106.83754987405707, -6.156119388837779],
            [106.83762238902084, -6.156022177171896],
            [106.83768497110621, -6.155918366876289],
            [106.83773701761733, -6.155808957704417],
            [106.837778027323, -6.1556950033295355],
            [106.8378076052836, -6.155577601197134],
            [106.8378254666544, -6.155457881955829],
            [106.83783143942841, -6.155336998568534],
            [106.83782546609264, -6.155216115208743],
            [106.83780760418166, -6.155096396048897],
            [106.83777802572321, -6.154978994048778],
            [106.83773701558118, -6.154865039851917],
            [106.83768496871198, -6.154755630896965],
            [106.83762238636051, -6.15465182084884],
            [106.8375498712329, -6.154554609451489],
            [106.83746812169178, -6.154464932899948],
            [106.83737792503076, -6.154383654824405],
            [106.8372801498922, -6.154311557973139],
            [106.83717573790173, -6.154249336674375],
            [106.83706569460008, -6.1541975901496695],
            [106.83695107975927, -6.154156816743218],
            [106.83683299717659, -6.154127409122656],
            [106.83671258404466, -6.154109650497537],
            [106.836591, -6.154103711891956],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.836591, -6.143985830274095],
            [106.83645779709794, -6.143992336509157],
            [106.83632587700295, -6.144011792556223],
            [106.83619651016834, -6.144044011044325],
            [106.83607094245886, -6.144088681694093],
            [106.83595038315266, -6.144145374305823],
            [106.8358359932956, -6.144213542902413],
            [106.83572887451994, -6.1442925309872845],
            [106.8356300584352, -6.144381577866634],
            [106.83554049669334, -6.144479825975154],
            [106.83546105182386, -6.144586329134665],
            [106.83539248892713, -6.144700061666161],
            [106.83533546830587, -6.144819928267456],
            [106.83529053910603, -6.144944774561401],
            [106.83525813402791, -6.145073398213007],
            [106.83523856515869, -6.145204560508479],
            [106.8352320209666, -6.14533699828462],
            [106.83523856448551, -6.1454694360937205],
            [106.83525813270742, -6.145600598486809],
            [106.835290537189, -6.145729222296932],
            [106.83533546586594, -6.145854068804206],
            [106.83539248605807, -6.145973935665444],
            [106.83546104863595, -6.146087668493506],
            [106.83554049330905, -6.146194171974812],
            [106.83563005498462, -6.146292420417984],
            [106.83572887113566, -6.146381467631987],
            [106.83583599010768, -6.146460456038652],
            [106.83595038028362, -6.146528624931809],
            [106.83607094001893, -6.146585317803482],
            [106.83619650825129, -6.146629988666579],
            [106.83632587568246, -6.1466622073132],
            [106.83645779642475, -6.14668166345788],
            [106.836591, -6.146688169725904],
            [106.83672420357523, -6.14668166345788],
            [106.83685612431752, -6.1466622073132],
            [106.83698549174869, -6.146629988666579],
            [106.83711105998104, -6.146585317803482],
            [106.83723161971636, -6.146528624931809],
            [106.8373460098923, -6.146460456038652],
            [106.83745312886431, -6.146381467631987],
            [106.83755194501538, -6.146292420417984],
            [106.83764150669091, -6.146194171974812],
            [106.83772095136402, -6.146087668493506],
            [106.8377895139419, -6.145973935665444],
            [106.83784653413403, -6.145854068804206],
            [106.83789146281097, -6.145729222296932],
            [106.83792386729256, -6.145600598486809],
            [106.83794343551446, -6.1454694360937205],
            [106.83794997903337, -6.14533699828462],
            [106.83794343484129, -6.145204560508479],
            [106.83792386597206, -6.145073398213007],
            [106.83789146089394, -6.144944774561401],
            [106.8378465316941, -6.144819928267456],
            [106.83778951107286, -6.144700061666161],
            [106.83772094817611, -6.144586329134665],
            [106.83764150330663, -6.144479825975154],
            [106.83755194156478, -6.144381577866634],
            [106.83745312548005, -6.1442925309872845],
            [106.83734600670437, -6.144213542902413],
            [106.8372316168473, -6.144145374305823],
            [106.83711105754112, -6.144088681694093],
            [106.83698548983163, -6.144044011044325],
            [106.83685612299703, -6.144011792556223],
            [106.83672420290205, -6.143992336509157],
            [106.836591, -6.143985830274095],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.82659100000002, -6.141469865760099],
            [106.82620976656167, -6.141488486933492],
            [106.8258322045304, -6.141544171125477],
            [106.82546194995858, -6.14163638207835],
            [106.82510256852986, -6.141764231768917],
            [106.82475752122217, -6.141926488959913],
            [106.82443013097908, -6.142121591056534],
            [106.82412355071, -6.142347659153901],
            [106.82384073292738, -6.142602516130594],
            [106.82358440131318, -6.142883707614069],
            [106.82335702448879, -6.143188525616086],
            [106.82316079224044, -6.143514034610606],
            [106.82299759442954, -6.143857099803052],
            [106.8228690027908, -6.144214417318746],
            [106.82277625579363, -6.144582546019851],
            [106.82272024671268, -6.144957940644451],
            [106.82270151502233, -6.145336985948625],
            [106.82272024119841, -6.145716031522793],
            [106.822776244977, -6.146091426946996],
            [106.8228689870875, -6.146459556946589],
            [106.82299757444304, -6.14681687620975],
            [106.82316076873882, -6.147159943531492],
            [106.82335699837516, -6.147485454955311],
            [106.82358437359112, -6.147790275593269],
            [106.8238407046622, -6.14807146981803],
            [106.82412352298795, -6.14832632953601],
            [106.82443010486546, -6.148552400269318],
            [106.82475749772053, -6.148747504795237],
            [106.82510254854336, -6.148909764115528],
            [106.82546193425529, -6.1490376155535635],
            [106.82583219371377, -6.1491298278049245],
            [106.82620976104742, -6.149185512796513],
            [106.82659100000002, -6.149204134239898],
            [106.8269722389526, -6.149185512796513],
            [106.82734980628625, -6.1491298278049245],
            [106.82772006574473, -6.1490376155535635],
            [106.82807945145667, -6.148909764115528],
            [106.8284245022795, -6.148747504795237],
            [106.82875189513457, -6.148552400269318],
            [106.82905847701208, -6.14832632953601],
            [106.82934129533783, -6.14807146981803],
            [106.82959762640893, -6.147790275593269],
            [106.82982500162487, -6.147485454955311],
            [106.83002123126123, -6.147159943531492],
            [106.83018442555701, -6.14681687620975],
            [106.83031301291253, -6.146459556946589],
            [106.83040575502302, -6.146091426946996],
            [106.83046175880162, -6.145716031522793],
            [106.8304804849777, -6.145336985948625],
            [106.83046175328737, -6.144957940644451],
            [106.83040574420639, -6.144582546019851],
            [106.83031299720923, -6.144214417318746],
            [106.83018440557049, -6.143857099803052],
            [106.83002120775959, -6.143514034610606],
            [106.82982497551124, -6.143188525616086],
            [106.82959759868685, -6.142883707614069],
            [106.82934126707265, -6.142602516130594],
            [106.82905844929003, -6.142347659153901],
            [106.82875186902096, -6.142121591056534],
            [106.82842447877788, -6.141926488959913],
            [106.82807943147019, -6.141764231768917],
            [106.82772005004145, -6.14163638207835],
            [106.82734979546963, -6.141544171125477],
            [106.82697223343835, -6.141488486933492],
            [106.82659100000002, -6.141469865760099],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.80659100000001, -6.201150431372328],
            [106.8061782292941, -6.2011705906848364],
            [106.8057694337018, -6.201230874481759],
            [106.80536855005775, -6.201330702210796],
            [106.80497943900738, -6.201469112498459],
            [106.80460584782982, -6.20164477240778],
            [106.80425137435245, -6.2018559902740735],
            [106.80391943230406, -6.202100731995205],
            [106.80361321844063, -6.2023766406195096],
            [106.80333568175979, -6.2026810590428125],
            [106.8030894951007, -6.203011055595991],
            [106.80287702940284, -6.203363452276759],
            [106.80270033087149, -6.203734855353842],
            [106.80256110127, -6.204121688048872],
            [106.8024606815285, -6.204520224981324],
            [106.80240003882726, -6.204926628044823],
            [106.80237975727898, -6.2053369833693495],
            [106.80240003230006, -6.205747339013429],
            [106.80246066872493, -6.206153743023307],
            [106.80256108268212, -6.206552281492596],
            [106.80270030721358, -6.206939116255861],
            [106.80287700158408, -6.2073105218530955],
            [106.80308946419015, -6.207662921409084],
            [106.80333564894534, -6.207992921082059],
            [106.8036131849833, -6.20829734274984],
            [106.80391939948962, -6.208573254618624],
            [106.80425134344189, -6.2088179994595505],
            [106.80460582001108, -6.209029220201064],
            [106.80497941534948, -6.209204882630537],
            [106.80536853146985, -6.209343294986435],
            [106.80576942089823, -6.209443124252308],
            [106.80617822276689, -6.209503408995611],
            [106.80659100000001, -6.209523568627672],
            [106.80700377723312, -6.209503408995611],
            [106.8074125791018, -6.209443124252308],
            [106.80781346853016, -6.209343294986435],
            [106.80820258465053, -6.209204882630537],
            [106.80857617998892, -6.209029220201064],
            [106.80893065655812, -6.2088179994595505],
            [106.80926260051041, -6.208573254618624],
            [106.8095688150167, -6.20829734274984],
            [106.80984635105469, -6.207992921082059],
            [106.81009253580986, -6.207662921409084],
            [106.81030499841593, -6.2073105218530955],
            [106.81048169278641, -6.206939116255861],
            [106.81062091731789, -6.206552281492596],
            [106.81072133127508, -6.206153743023307],
            [106.81078196769995, -6.205747339013429],
            [106.81080224272102, -6.2053369833693495],
            [106.81078196117275, -6.204926628044823],
            [106.8107213184715, -6.204520224981324],
            [106.81062089873, -6.204121688048872],
            [106.8104816691285, -6.203734855353842],
            [106.81030497059717, -6.203363452276759],
            [106.81009250489932, -6.203011055595991],
            [106.80984631824022, -6.2026810590428125],
            [106.80956878155938, -6.2023766406195096],
            [106.80926256769595, -6.202100731995205],
            [106.80893062564756, -6.2018559902740735],
            [106.80857615217018, -6.20164477240778],
            [106.80820256099263, -6.201469112498459],
            [106.80781344994224, -6.201330702210796],
            [106.80741256629823, -6.201230874481759],
            [106.8070037707059, -6.2011705906848364],
            [106.80659100000001, -6.201150431372328],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.816591, -6.211146317071237],
            [106.81617781580985, -6.2111664961946556],
            [106.81576861071504, -6.211226839233536],
            [106.81536732549365, -6.211326765065094],
            [106.8149778246582, -6.211465311371141],
            [106.81460385924137, -6.211641143904881],
            [106.81424903067446, -6.211852569339296],
            [106.81391675610585, -6.212097551573401],
            [106.81361023549375, -6.2123737313394285],
            [106.8133324207899, -6.212678448922122],
            [106.813085987511, -6.213008769771459],
            [106.81287330897175, -6.213361512762168],
            [106.81269643342735, -6.2137332808279675],
            [106.81255706434608, -6.21412049367558],
            [106.81245654400162, -6.214519422263508],
            [106.81239584054339, -6.214926224713602],
            [106.81237553866956, -6.215336983309582],
            [106.81239583399261, -6.21574774222626],
            [106.81245653115181, -6.216154545626135],
            [106.81255704569102, -6.216553475756425],
            [106.81269640968398, -6.216940690679706],
            [106.81287328105249, -6.2173124612747115],
            [106.81308595648878, -6.217665207150974],
            [106.81333238785689, -6.2179955311313195],
            [106.81361020191555, -6.2183002519701525],
            [106.81391672317284, -6.218576434992318],
            [106.81424899965222, -6.218821420357431],
            [106.8146038313221, -6.219032848677398],
            [106.8149778009148, -6.219208683740349],
            [106.8153673068386, -6.219347232122063],
            [106.81576859786522, -6.2194471594959815],
            [106.81617780925907, -6.219507503484642],
            [106.816591, -6.219527682928762],
            [106.81700419074093, -6.219507503484642],
            [106.81741340213479, -6.2194471594959815],
            [106.81781469316141, -6.219347232122063],
            [106.8182041990852, -6.219208683740349],
            [106.8185781686779, -6.219032848677398],
            [106.81893300034778, -6.218821420357431],
            [106.81926527682717, -6.218576434992318],
            [106.81957179808448, -6.2183002519701525],
            [106.81984961214312, -6.2179955311313195],
            [106.82009604351123, -6.217665207150974],
            [106.82030871894752, -6.2173124612747115],
            [106.82048559031603, -6.216940690679706],
            [106.82062495430898, -6.216553475756425],
            [106.8207254688482, -6.216154545626135],
            [106.8207861660074, -6.21574774222626],
            [106.82080646133045, -6.215336983309582],
            [106.82078615945662, -6.214926224713602],
            [106.82072545599839, -6.214519422263508],
            [106.82062493565392, -6.21412049367558],
            [106.82048556657266, -6.2137332808279675],
            [106.82030869102826, -6.213361512762168],
            [106.820096012489, -6.213008769771459],
            [106.81984957921011, -6.212678448922122],
            [106.81957176450625, -6.2123737313394285],
            [106.81926524389416, -6.212097551573401],
            [106.81893296932554, -6.211852569339296],
            [106.81857814075863, -6.211641143904881],
            [106.8182041753418, -6.211465311371141],
            [106.81781467450635, -6.211326765065094],
            [106.81741338928497, -6.211226839233536],
            [106.81700418419015, -6.2111664961946556],
            [106.816591, -6.211146317071237],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.816591, -6.204092272556385],
            [106.81646827661365, -6.204098266245122],
            [106.81634673511226, -6.20411618958924],
            [106.81622754599901, -6.204145869978344],
            [106.81611185712278, -6.204187021576013],
            [106.8160007826243, -6.204239248072504],
            [106.81589539220639, -6.204302046501308],
            [106.81579670083244, -6.2043748120828885],
            [106.81570565895191, -6.204456844048886],
            [106.8156231433471, -6.204547352390755],
            [106.81554994868912, -6.204645465467816],
            [106.81548677988506, -6.204750238401472],
            [106.81543424528891, -6.204860662174759],
            [106.81539285084287, -6.204975673349583],
            [106.81536299520451, -6.205094164308093],
            [106.81534496590733, -6.20521499391954],
            [106.8153389365913, -6.205336998529919],
            [106.81534496533034, -6.205459003168546],
            [106.81536299407273, -6.2055798328636484],
            [106.81539284919977, -6.20569832395801],
            [106.81543424319764, -6.205813335315657],
            [106.815486777426, -6.205923759311715],
            [106.81554994595675, -6.206028532499529],
            [106.81562314044642, -6.2061266458523665],
            [106.81570565599444, -6.206217154481035],
            [106.81579669793177, -6.206299186733831],
            [106.81589538947402, -6.206371952591188],
            [106.81600078016524, -6.20643475127415],
            [106.81611185503154, -6.20648697799341],
            [106.81622754435591, -6.206528129773906],
            [106.81634673398048, -6.206557810298858],
            [106.81646827603667, -6.206575733726633],
            [106.816591, -6.206581727443617],
            [106.81671372396335, -6.206575733726633],
            [106.81683526601952, -6.206557810298858],
            [106.8169544556441, -6.206528129773906],
            [106.81707014496847, -6.20648697799341],
            [106.81718121983477, -6.20643475127415],
            [106.81728661052598, -6.206371952591188],
            [106.81738530206823, -6.206299186733831],
            [106.81747634400558, -6.206217154481035],
            [106.81755885955359, -6.2061266458523665],
            [106.81763205404326, -6.206028532499529],
            [106.817695222574, -6.205923759311715],
            [106.81774775680236, -6.205813335315657],
            [106.81778915080024, -6.20569832395801],
            [106.81781900592728, -6.2055798328636484],
            [106.81783703466965, -6.205459003168546],
            [106.8178430634087, -6.205336998529919],
            [106.81783703409268, -6.20521499391954],
            [106.81781900479551, -6.205094164308093],
            [106.81778914915714, -6.204975673349583],
            [106.81774775471109, -6.204860662174759],
            [106.81769522011494, -6.204750238401472],
            [106.81763205131088, -6.204645465467816],
            [106.81755885665294, -6.204547352390755],
            [106.81747634104809, -6.204456844048886],
            [106.81738529916757, -6.2043748120828885],
            [106.81728660779362, -6.204302046501308],
            [106.8171812173757, -6.204239248072504],
            [106.81707014287723, -6.204187021576013],
            [106.81695445400099, -6.204145869978344],
            [106.81683526488774, -6.20411618958924],
            [106.81671372338636, -6.204098266245122],
            [106.816591, -6.204092272556385],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.816591, -6.1933444837230285],
            [106.81639455268471, -6.193354078197383],
            [106.81619999724319, -6.193382769221457],
            [106.8160092073303, -6.193430280488083],
            [106.81582402033834, -6.193496154442796],
            [106.81564621970277, -6.193579756690133],
            [106.81547751772736, -6.193680282102956],
            [106.81531953909429, -6.193796762575947],
            [106.81517380521795, -6.19392807634863],
            [106.81504171959314, -6.1940729588081584],
            [106.81492455427875, -6.1942300146678075],
            [106.81482343764696, -6.194397731403952],
            [106.8147393435163, -6.194574493822102],
            [106.81467308177274, -6.194758599611749],
            [106.81462529056964, -6.194948275740233],
            [106.8145964301812, -6.195141695527754],
            [106.81458677856918, -6.195336996239112],
            [106.81459642870514, -6.195532297022733],
            [106.81462528767428, -6.1957257170242706],
            [106.81467307756934, -6.195915393500298],
            [106.81473933816635, -6.19609949975766],
            [106.8148234313561, -6.196276262745723],
            [106.8149245472887, -6.1964439801320745],
            [106.81504171217256, -6.196601036697241],
            [106.815173797652, -6.196745919890483],
            [106.8153195316737, -6.19687723439688],
            [106.81547751073732, -6.196993715575387],
            [106.81564621341188, -6.197094241638418],
            [106.8158240149884, -6.197177844455667],
            [106.81600920312687, -6.197243718878095],
            [106.81619999434784, -6.197291230492265],
            [106.81639455120867, -6.197319921730355],
            [106.816591, -6.197329516276975],
            [106.81678744879133, -6.197319921730355],
            [106.81698200565216, -6.197291230492265],
            [106.81717279687314, -6.197243718878095],
            [106.81735798501163, -6.197177844455667],
            [106.81753578658811, -6.197094241638418],
            [106.81770448926268, -6.196993715575387],
            [106.81786246832631, -6.19687723439688],
            [106.81800820234803, -6.196745919890483],
            [106.81814028782745, -6.196601036697241],
            [106.81825745271131, -6.1964439801320745],
            [106.81835856864392, -6.196276262745723],
            [106.81844266183366, -6.19609949975766],
            [106.81850892243067, -6.195915393500298],
            [106.81855671232573, -6.1957257170242706],
            [106.81858557129486, -6.195532297022733],
            [106.81859522143084, -6.195336996239112],
            [106.81858556981881, -6.195141695527754],
            [106.81855670943035, -6.194948275740233],
            [106.81850891822727, -6.194758599611749],
            [106.81844265648371, -6.194574493822102],
            [106.81835856235304, -6.194397731403952],
            [106.81825744572126, -6.1942300146678075],
            [106.81814028040685, -6.1940729588081584],
            [106.81800819478205, -6.19392807634863],
            [106.81786246090572, -6.193796762575947],
            [106.81770448227265, -6.193680282102956],
            [106.81753578029723, -6.193579756690133],
            [106.81735797966167, -6.193496154442796],
            [106.8171727926697, -6.193430280488083],
            [106.81698200275682, -6.193382769221457],
            [106.81678744731529, -6.193354078197383],
            [106.816591, -6.1933444837230285],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.85659100000001, -6.194118182776861],
            [106.8564708334933, -6.194124051701408],
            [106.85635182424781, -6.194141601954488],
            [106.85623511837994, -6.194170664518721],
            [106.856121839824, -6.194210959507599],
            [106.85601307950817, -6.194262098860871],
            [106.85590988484871, -6.194323590081681],
            [106.85581324966286, -6.194394840979459],
            [106.85572410459794, -6.1944751653729115],
            [106.85564330816885, -6.194563789698173],
            [106.85557163849019, -6.194659860458494],
            [106.85550978578243, -6.19476245244374],
            [106.85545834572476, -6.194870577640513],
            [106.8554178137181, -6.194983194747128],
            [106.85538858011402, -6.19509921920181],
            [106.85537092645515, -6.195217533627507],
            [106.85536502276352, -6.195336998592775],
            [106.85537092590285, -6.195456463585082],
            [106.85538857903066, -6.195574778090858],
            [106.8554178121453, -6.195690802675582],
            [106.85545834372297, -6.195803419957205],
            [106.85550978342857, -6.195911545367221],
            [106.8555716358747, -6.196014137595759],
            [106.85564330539226, -6.196110208620066],
            [106.85572410176694, -6.1961988332198645],
            [106.85581324688627, -6.196279157887853],
            [106.85590988223323, -6.196350409049616],
            [106.85601307715429, -6.196411900513718],
            [106.85612183782217, -6.1964630400802365],
            [106.85623511680713, -6.196503335244121],
            [106.85635182316442, -6.196532397938396],
            [106.856470832941, -6.196549948271555],
            [106.85659100000001, -6.196555817223142],
            [106.85671116705899, -6.196549948271555],
            [106.85683017683557, -6.196532397938396],
            [106.85694688319286, -6.196503335244121],
            [106.85706016217782, -6.1964630400802365],
            [106.8571689228457, -6.196411900513718],
            [106.85727211776675, -6.196350409049616],
            [106.85736875311373, -6.196279157887853],
            [106.85745789823306, -6.1961988332198645],
            [106.85753869460774, -6.196110208620066],
            [106.8576103641253, -6.196014137595759],
            [106.85767221657143, -6.195911545367221],
            [106.85772365627705, -6.195803419957205],
            [106.85776418785471, -6.195690802675582],
            [106.85779342096934, -6.195574778090858],
            [106.85781107409714, -6.195456463585082],
            [106.85781697723648, -6.195336998592775],
            [106.85781107354484, -6.195217533627507],
            [106.85779341988598, -6.19509921920181],
            [106.85776418628188, -6.194983194747128],
            [106.85772365427523, -6.194870577640513],
            [106.85767221421756, -6.19476245244374],
            [106.85761036150981, -6.194659860458494],
            [106.85753869183117, -6.194563789698173],
            [106.85745789540206, -6.1944751653729115],
            [106.85736875033714, -6.194394840979459],
            [106.85727211515128, -6.194323590081681],
            [106.85716892049183, -6.194262098860871],
            [106.85706016017602, -6.194210959507599],
            [106.85694688162005, -6.194170664518721],
            [106.8568301757522, -6.194141601954488],
            [106.8567111665067, -6.194124051701408],
            [106.85659100000001, -6.194118182776861],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.84659099999999, -6.182935311547128],
            [106.84635421600865, -6.182946876281073],
            [106.84611971234577, -6.182981459109708],
            [106.84588974738017, -6.1830387269859575],
            [106.84566653577286, -6.183118128396122],
            [106.84545222714974, -6.183218898670982],
            [106.8452488854003, -6.183340067349604],
            [106.84505846880215, -6.1834804675249515],
            [106.84488281116225, -6.183638747081318],
            [106.84472360415666, -6.183813381715374],
            [106.84458238103885, -6.184002689615449],
            [106.84446050187368, -6.184204847657711],
            [106.84435914043874, -6.184417908963289],
            [106.84427927291968, -6.184639821647242],
            [106.8442216685083, -6.18486844857888],
            [106.84418688199389, -6.185101587963137],
            [106.84417524841926, -6.18533699454477],
            [106.84418687985293, -6.185572401231221],
            [106.8442216643086, -6.185805540925913],
            [106.84427926682265, -6.18603416836167],
            [106.84435913267872, -6.186256081724049],
            [106.84446049274887, -6.186469143856293],
            [106.84458237089989, -6.186671302841694],
            [106.84472359339316, -6.186860611765133],
            [106.8448828001879, -6.1870352474634505],
            [106.84505845803868, -6.187193528084081],
            [106.84524887526132, -6.187333929282792],
            [106.84545221802489, -6.187455098904552],
            [106.84566652801284, -6.187555870006079],
            [106.84588974128316, -6.1876352720946715],
            [106.84611970814606, -6.187692540475038],
            [106.84635421386766, -6.187727123614107],
            [106.84659099999999, -6.187738688452872],
            [106.84682778613232, -6.187727123614107],
            [106.84706229185392, -6.187692540475038],
            [106.84729225871682, -6.1876352720946715],
            [106.84751547198714, -6.187555870006079],
            [106.84772978197509, -6.187455098904552],
            [106.84793312473866, -6.187333929282792],
            [106.8481235419613, -6.187193528084081],
            [106.84829919981209, -6.1870352474634505],
            [106.8484584066068, -6.186860611765133],
            [106.8485996291001, -6.186671302841694],
            [106.84872150725111, -6.186469143856293],
            [106.84882286732126, -6.186256081724049],
            [106.84890273317733, -6.18603416836167],
            [106.84896033569139, -6.185805540925913],
            [106.84899512014705, -6.185572401231221],
            [106.84900675158072, -6.18533699454477],
            [106.84899511800609, -6.185101587963137],
            [106.84896033149168, -6.18486844857888],
            [106.8489027270803, -6.184639821647242],
            [106.84882285956122, -6.184417908963289],
            [106.8487214981263, -6.184204847657711],
            [106.84859961896112, -6.184002689615449],
            [106.84845839584332, -6.183813381715374],
            [106.84829918883773, -6.183638747081318],
            [106.84812353119783, -6.1834804675249515],
            [106.84793311459968, -6.183340067349604],
            [106.84772977285024, -6.183218898670982],
            [106.84751546422711, -6.183118128396122],
            [106.84729225261981, -6.1830387269859575],
            [106.84706228765421, -6.182981459109708],
            [106.84682778399133, -6.182946876281073],
            [106.84659099999999, -6.182935311547128],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.84659099999999, -6.171917693308327],
            [106.84625389539285, -6.171934158098599],
            [106.845920037225, -6.171983393907537],
            [106.84559264067313, -6.172064926576427],
            [106.84527485868973, -6.172177970915778],
            [106.84496975164059, -6.1723214382665645],
            [106.84468025783362, -6.17249394698385],
            [106.84440916522286, -6.172693835741854],
            [106.84415908456013, -6.172919179532375],
            [106.84393242425283, -6.173167808202528],
            [106.84373136716967, -6.173437327353311],
            [106.8435578496185, -6.1737251413977825],
            [106.84341354269768, -6.174028478556841],
            [106.84329983620151, -6.174344417551916],
            [106.84321782523413, -6.17466991573755],
            [106.84316829966129, -6.175001838402979],
            [106.84315173650127, -6.1753369889605425],
            [106.84316829532877, -6.175672139730225],
            [106.8432178167356, -6.176004063023862],
            [106.84329982386353, -6.176329562229652],
            [106.8434135269944, -6.176645502597626],
            [106.84355783115339, -6.1769488414295655],
            [106.84373134665236, -6.177236657382614],
            [106.84393240247176, -6.1775061786043235],
            [106.84415906235236, -6.177754809428167],
            [106.8444091434418, -6.17798015537238],
            [106.84468023731631, -6.178180046201311],
            [106.8449697331755, -6.178352556827173],
            [106.84527484298647, -6.17849602585084],
            [106.84559262833515, -6.17860907156309],
            [106.84592002872644, -6.178690605252135],
            [106.84625389106033, -6.178739841689282],
            [106.84659099999999, -6.178756306691675],
            [106.84692810893965, -6.178739841689282],
            [106.84726197127354, -6.178690605252135],
            [106.84758937166484, -6.17860907156309],
            [106.84790715701351, -6.17849602585084],
            [106.84821226682448, -6.178352556827173],
            [106.84850176268367, -6.178180046201311],
            [106.84877285655818, -6.17798015537238],
            [106.84902293764762, -6.177754809428167],
            [106.84924959752821, -6.1775061786043235],
            [106.84945065334762, -6.177236657382614],
            [106.84962416884659, -6.1769488414295655],
            [106.84976847300557, -6.176645502597626],
            [106.84988217613645, -6.176329562229652],
            [106.84996418326438, -6.176004063023862],
            [106.85001370467121, -6.175672139730225],
            [106.85003026349871, -6.1753369889605425],
            [106.85001370033868, -6.175001838402979],
            [106.84996417476584, -6.17466991573755],
            [106.84988216379847, -6.174344417551916],
            [106.8497684573023, -6.174028478556841],
            [106.84962415038149, -6.1737251413977825],
            [106.84945063283031, -6.173437327353311],
            [106.84924957574717, -6.173167808202528],
            [106.84902291543985, -6.172919179532375],
            [106.84877283477712, -6.172693835741854],
            [106.84850174216636, -6.17249394698385],
            [106.84821224835939, -6.1723214382665645],
            [106.84790714131024, -6.172177970915778],
            [106.84758935932686, -6.172064926576427],
            [106.84726196277498, -6.171983393907537],
            [106.84692810460713, -6.171934158098599],
            [106.84659099999999, -6.171917693308327],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.85659100000001, -6.173094486332437],
            [106.8563699132627, -6.173105284603074],
            [106.85615095568471, -6.173137575423049],
            [106.85593623592142, -6.1731910478180065],
            [106.85572782181774, -6.173265186825864],
            [106.85552772049456, -6.173359278455949],
            [106.85533785901994, -6.173472416564786],
            [106.85516006585094, -6.173603511582357],
            [106.85499605322514, -6.173751301004803],
            [106.85484740067115, -6.173914361552529],
            [106.85471553979691, -6.174091122876664],
            [106.85460174050263, -6.174279882681852],
            [106.85450709875037, -6.174478823119802],
            [106.85443252600906, -6.174686028295709],
            [106.85437874047578, -6.174899502718963],
            [106.85434626015841, -6.175117190520491],
            [106.85433539788596, -6.1753369952516595],
            [106.85434625829488, -6.175556800074067],
            [106.85437873682035, -6.1757744881458025],
            [106.85443252070218, -6.17598796300785],
            [106.854507091996, -6.176195168774274],
            [106.85460173256035, -6.176394109931772],
            [106.85471553097192, -6.176582870557885],
            [106.85484739130257, -6.176759632772776],
            [106.85499604367303, -6.1769226942468585],
            [106.85516005648238, -6.177070484595659],
            [106.85533785019494, -6.177201580503986],
            [106.85552771255229, -6.177314719433749],
            [106.85572781506337, -6.17740881178338],
            [106.85593623061456, -6.177482951381755],
            [106.8561509520293, -6.177536424215504],
            [106.85636991139918, -6.177568715305689],
            [106.85659100000001, -6.177579513667563],
            [106.85681208860082, -6.177568715305689],
            [106.85703104797072, -6.177536424215504],
            [106.85724576938546, -6.177482951381755],
            [106.85745418493663, -6.17740881178338],
            [106.85765428744772, -6.177314719433749],
            [106.85784414980506, -6.177201580503986],
            [106.85802194351763, -6.177070484595659],
            [106.85818595632698, -6.1769226942468585],
            [106.85833460869745, -6.176759632772776],
            [106.85846646902807, -6.176582870557885],
            [106.85858026743966, -6.176394109931772],
            [106.85867490800399, -6.176195168774274],
            [106.85874947929781, -6.17598796300785],
            [106.85880326317965, -6.1757744881458025],
            [106.85883574170512, -6.175556800074067],
            [106.85884660211404, -6.1753369952516595],
            [106.8588357398416, -6.175117190520492],
            [106.85880325952421, -6.174899502718963],
            [106.85874947399095, -6.174686028295709],
            [106.85867490124961, -6.174478823119802],
            [106.85858025949736, -6.174279882681852],
            [106.85846646020308, -6.174091122876664],
            [106.85833459932886, -6.173914361552529],
            [106.85818594677487, -6.173751301004803],
            [106.85802193414906, -6.173603511582357],
            [106.85784414098005, -6.173472416564786],
            [106.85765427950544, -6.173359278455949],
            [106.85745417818225, -6.173265186825864],
            [106.85724576407857, -6.1731910478180065],
            [106.85703104431528, -6.173137575423049],
            [106.8568120867373, -6.173105284603074],
            [106.85659100000001, -6.173094486332437],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.866591, -6.172489527098034],
            [106.86631027137524, -6.172503238384812],
            [106.86603224627736, -6.172544240200069],
            [106.86575960219844, -6.172612137680186],
            [106.86549496481163, -6.172706276945492],
            [106.8652408826861, -6.172825751397106],
            [106.864999802744, -6.172969410447445],
            [106.86477404669648, -6.173135870600377],
            [106.86456578868506, -6.1733235287743025],
            [106.86437703434396, -6.1735305777399265],
            [106.86420960148492, -6.173755023524029],
            [106.8640651025905, -6.173994704611687],
            [106.86394492928456, -6.174247312762068],
            [106.86385023892925, -6.1745104152372985],
            [106.86378194347806, -6.174781478230428],
            [106.86374070069188, -6.17505789126685],
            [106.86372690780283, -6.175336992344196],
            [106.86374069768729, -6.175616093568647],
            [106.86378193758436, -6.175892507040726],
            [106.86385023037293, -6.17616357074133],
            [106.86394491839442, -6.176426674168657],
            [106.86406508978507, -6.176679283479171],
            [106.86420958725627, -6.176918965890419],
            [106.86437701923892, -6.177143413110696],
            [106.86456577328407, -6.177350463569894],
            [106.86477403159144, -6.177538123237394],
            [106.86499978851536, -6.1777045848265],
            [106.86524086988067, -6.177848245200428],
            [106.86549495392151, -6.177967720812176],
            [106.8657595936421, -6.17806186102958],
            [106.86603224038366, -6.178129759217168],
            [106.86631026837067, -6.178170761468086],
            [106.866591, -6.178184472901966],
            [106.86687173162933, -6.178170761468086],
            [106.86714975961634, -6.178129759217168],
            [106.8674224063579, -6.17806186102958],
            [106.86768704607849, -6.177967720812176],
            [106.86794113011933, -6.177848245200428],
            [106.86818221148464, -6.1777045848265],
            [106.86840796840856, -6.177538123237394],
            [106.86861622671593, -6.177350463569894],
            [106.86880498076108, -6.177143413110696],
            [106.86897241274373, -6.176918965890419],
            [106.86911691021493, -6.176679283479171],
            [106.86923708160558, -6.176426674168657],
            [106.86933176962707, -6.17616357074133],
            [106.86940006241564, -6.175892507040726],
            [106.86944130231271, -6.175616093568647],
            [106.86945509219717, -6.175336992344196],
            [106.86944129930812, -6.17505789126685],
            [106.86940005652194, -6.174781478230428],
            [106.86933176107075, -6.1745104152372985],
            [106.86923707071544, -6.174247312762068],
            [106.8691168974095, -6.173994704611687],
            [106.8689723985151, -6.173755023524029],
            [106.86880496565604, -6.1735305777399265],
            [106.86861621131494, -6.1733235287743025],
            [106.86840795330352, -6.173135870600377],
            [106.868182197256, -6.172969410447445],
            [106.8679411173139, -6.172825751397106],
            [106.86768703518837, -6.172706276945492],
            [106.86742239780158, -6.172612137680186],
            [106.86714975372264, -6.172544240200069],
            [106.86687172862477, -6.172503238384812],
            [106.866591, -6.172489527098034],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.82659100000002, -6.162410061894735],
            [106.82630244249023, -6.16242415582415],
            [106.8260166639014, -6.162466301882383],
            [106.82573641639365, -6.162536094186472],
            [106.82546439886328, -6.162632860609112],
            [106.82520323095234, -6.162755669251215],
            [106.82495542782151, -6.162903337416036],
            [106.8247233759288, -6.1630744429985205],
            [106.82450931004743, -6.163267338180184],
            [106.82431529174441, -6.163480165297658],
            [106.82414318952648, -6.163710874732143],
            [106.82399466084541, -6.163957244647477],
            [106.82387113613521, -6.164216902386799],
            [106.82377380503542, -6.164487347321764],
            [106.82370360493319, -6.164765974934268],
            [106.82366121193427, -6.165050101898844],
            [106.82364703435033, -6.165336991924128],
            [106.82366120876488, -6.1656238821045894],
            [106.82370359871622, -6.165908009528728],
            [106.82377379600979, -6.166186637887524],
            [106.82387112464777, -6.166457083826826],
            [106.82399464733763, -6.166716742789939],
            [106.8241431745174, -6.166963114101483],
            [106.82431527581085, -6.167193825050946],
            [106.82450929380174, -6.167406653743945],
            [106.82472335999526, -6.167599550501132],
            [106.82495541281246, -6.167770657598596],
            [106.82520321744454, -6.1679183271596285],
            [106.82546438737583, -6.16804113702552],
            [106.82573640736803, -6.168137904452498],
            [106.82601665768443, -6.168207697502877],
            [106.82630243932086, -6.168249844020674],
            [106.82659100000002, -6.168263938105266],
            [106.82687956067919, -6.168249844020674],
            [106.82716534231561, -6.168207697502877],
            [106.827445592632, -6.168137904452498],
            [106.8277176126242, -6.16804113702552],
            [106.82797878255549, -6.1679183271596285],
            [106.82822658718757, -6.167770657598596],
            [106.82845864000477, -6.167599550501132],
            [106.82867270619828, -6.167406653743945],
            [106.82886672418917, -6.167193825050946],
            [106.82903882548261, -6.166963114101483],
            [106.82918735266242, -6.166716742789939],
            [106.82931087535226, -6.166457083826826],
            [106.82940820399024, -6.166186637887524],
            [106.82947840128382, -6.165908009528728],
            [106.82952079123515, -6.1656238821045894],
            [106.8295349656497, -6.165336991924128],
            [106.82952078806576, -6.165050101898844],
            [106.82947839506684, -6.164765974934268],
            [106.82940819496463, -6.164487347321764],
            [106.82931086386482, -6.164216902386799],
            [106.82918733915461, -6.163957244647477],
            [106.82903881047355, -6.163710874732143],
            [106.82886670825562, -6.163480165297658],
            [106.82867268995258, -6.163267338180184],
            [106.82845862407123, -6.1630744429985205],
            [106.8282265721785, -6.162903337416036],
            [106.82797876904768, -6.162755669251215],
            [106.82771760113675, -6.162632860609112],
            [106.82744558360638, -6.162536094186472],
            [106.82716533609864, -6.162466301882383],
            [106.8268795575098, -6.16242415582415],
            [106.82659100000002, -6.162410061894735],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.816591, -6.162821364287525],
            [106.8163429912924, -6.162833477703768],
            [106.81609737101051, -6.1628697012953255],
            [106.81585650457954, -6.1629296862140945],
            [106.81562271164499, -6.163012854780454],
            [106.81539824373446, -6.163118406046323],
            [106.81518526257517, -6.163245323508321],
            [106.81498581927609, -6.163392384896761],
            [106.81480183457542, -6.163558173946184],
            [106.81463508034307, -6.163741094034175],
            [106.81448716251674, -6.163939383557047],
            [106.81435950563582, -6.164151132894409],
            [106.81425333912182, -6.164374302799242],
            [106.81416968543775, -6.164606744036383],
            [106.8141093502405, -6.164846218080338],
            [106.81407291462091, -6.165090418673097],
            [106.8140607295063, -6.16533699403435],
            [106.81407291227968, -6.16558356951023],
            [106.81410934564803, -6.165827770442468],
            [106.8141696787705, -6.166067245037709],
            [106.81425333063602, -6.166299687016756],
            [106.81435949565758, -6.166522857825603],
            [106.81448715142953, -6.166734608194348],
            [106.81463506857295, -6.166932898836333],
            [106.81480182257472, -6.167115820088166],
            [106.81498580750598, -6.167281610301431],
            [106.81518525148795, -6.167428672808984],
            [106.81539823375624, -6.167555591302365],
            [106.8156227031592, -6.167661143472248],
            [106.81585649791229, -6.167744312780512],
            [106.81609736641803, -6.167804298250568],
            [106.81634298895118, -6.167840522181605],
            [106.816591, -6.167852635712475],
            [106.81683901104884, -6.167840522181605],
            [106.81708463358197, -6.167804298250568],
            [106.81732550208771, -6.167744312780512],
            [106.8175592968408, -6.167661143472248],
            [106.81778376624375, -6.167555591302365],
            [106.81799674851206, -6.167428672808984],
            [106.81819619249403, -6.167281610301431],
            [106.81838017742531, -6.167115820088166],
            [106.81854693142708, -6.166932898836333],
            [106.81869484857047, -6.166734608194348],
            [106.81882250434242, -6.166522857825603],
            [106.81892866936398, -6.166299687016756],
            [106.81901232122951, -6.166067245037709],
            [106.81907265435197, -6.165827770442468],
            [106.81910908772032, -6.16558356951023],
            [106.81912127049371, -6.16533699403435],
            [106.8191090853791, -6.165090418673097],
            [106.8190726497595, -6.164846218080338],
            [106.81901231456226, -6.164606744036383],
            [106.8189286608782, -6.164374302799242],
            [106.81882249436418, -6.164151132894409],
            [106.81869483748326, -6.163939383557047],
            [106.81854691965694, -6.163741094034175],
            [106.81838016542459, -6.163558173946184],
            [106.81819618072392, -6.163392384896761],
            [106.81799673742485, -6.163245323508321],
            [106.81778375626554, -6.163118406046323],
            [106.81755928835501, -6.163012854780454],
            [106.81732549542046, -6.1629296862140945],
            [106.81708462898949, -6.1628697012953255],
            [106.8168390087076, -6.162833477703768],
            [106.816591, -6.162821364287525],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.80659100000001, -6.163589807474587],
            [106.80641874947, -6.1635982206565085],
            [106.80624815779049, -6.163623379179529],
            [106.80608086783698, -6.163665040755675],
            [106.80591849068877, -6.163722804165023],
            [106.80576259011394, -6.163796113119517],
            [106.80561466750986, -6.163884261620126],
            [106.80547614744438, -6.163986400755766],
            [106.80534836393669, -6.164101546878497],
            [106.80523254761023, -6.164228591076294],
            [106.80512981384116, -6.164366309852167],
            [106.80504115201657, -6.164513376906792],
            [106.80496741600597, -6.164668375911203],
            [106.80490931593783, -6.164829814146533],
            [106.80486741136016, -6.164996136879482],
            [106.80484210585145, -6.165165742335039],
            [106.80483364313311, -6.165336997122311],
            [106.80484210472208, -6.165508251964878],
            [106.80486740914489, -6.165677857584192],
            [106.80490931272169, -6.1658441805830675],
            [106.80496741191263, -6.166005619176276],
            [106.8050411472033, -6.166160618616762],
            [106.80512980849298, -6.166307686168902],
            [106.80523254193263, -6.166445405484609],
            [106.80534835814784, -6.166572450243817],
            [106.80547614176675, -6.166687596927956],
            [106.80561466216164, -6.166789736603431],
            [106.80576258530068, -6.166877885601553],
            [106.80591848659544, -6.166951194992123],
            [106.80608086462088, -6.167008958759348],
            [106.80624815557518, -6.167050620601422],
            [106.80641874834063, -6.1670757792882],
            [106.80659100000001, -6.167084192525415],
            [106.80676325165936, -6.1670757792882],
            [106.80693384442482, -6.167050620601422],
            [106.80710113537914, -6.167008958759348],
            [106.80726351340458, -6.166951194992123],
            [106.80741941469932, -6.166877885601553],
            [106.80756733783836, -6.166789736603431],
            [106.80770585823326, -6.166687596927956],
            [106.80783364185217, -6.166572450243817],
            [106.80794945806738, -6.166445405484609],
            [106.80805219150703, -6.166307686168902],
            [106.80814085279671, -6.166160618616762],
            [106.80821458808738, -6.166005619176276],
            [106.8082726872783, -6.1658441805830675],
            [106.80831459085512, -6.165677857584192],
            [106.80833989527792, -6.165508251964878],
            [106.80834835686689, -6.165336997122311],
            [106.80833989414856, -6.165165742335039],
            [106.80831458863983, -6.164996136879482],
            [106.80827268406217, -6.164829814146533],
            [106.80821458399404, -6.164668375911203],
            [106.80814084798345, -6.164513376906792],
            [106.80805218615883, -6.164366309852167],
            [106.80794945238975, -6.164228591076294],
            [106.80783363606332, -6.164101546878497],
            [106.80770585255564, -6.163986400755766],
            [106.80756733249017, -6.163884261620126],
            [106.80741940988607, -6.163796113119517],
            [106.80726350931123, -6.163722804165023],
            [106.80710113216303, -6.163665040755675],
            [106.80693384220952, -6.163623379179529],
            [106.80676325053001, -6.1635982206565085],
            [106.80659100000001, -6.163589807474587],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.796591, -6.203781052872638],
            [106.79643759212807, -6.203788545161272],
            [106.79628566164484, -6.2038109498729765],
            [106.79613667171141, -6.2038480512400085],
            [106.7959920571708, -6.203899491958982],
            [106.79585321073, -6.203964776631764],
            [106.79572146954787, -6.204043276536276],
            [106.79559810235786, -6.204134235681247],
            [106.79548429724964, -6.204236778086588],
            [106.79538115022737, -6.204349916219335],
            [106.79528965465452, -6.204472560503871],
            [106.79521069168725, -6.2046035298149],
            [106.79514502178826, -6.204741562852078],
            [106.79509327740286, -6.204885330286815],
            [106.79505595686781, -6.205033447564235],
            [106.7950334196118, -6.205184488237046],
            [106.79502588269338, -6.2053369977028865],
            [106.79503341871022, -6.205489507212864],
            [106.7950559550993, -6.205640548016395],
            [106.79509327483538, -6.205788665506091],
            [106.7951450185205, -6.2059324332265025],
            [106.79521068784477, -6.206070466611779],
            [106.795289650385, -6.206201436319948],
            [106.79538114569486, -6.206324081035407],
            [106.79548429262834, -6.206437219616299],
            [106.79559809782533, -6.2065397624697844],
            [106.79572146527833, -6.206630722045677],
            [106.79585320688753, -6.206709222347331],
            [106.79599205390303, -6.206774507368209],
            [106.79613666914395, -6.206825948372857],
            [106.79628565987633, -6.206863049952166],
            [106.7964375912265, -6.206885454794589],
            [106.796591, -6.206892947127362],
            [106.7967444087735, -6.206885454794589],
            [106.79689634012367, -6.206863049952166],
            [106.79704533085606, -6.206825948372857],
            [106.79718994609698, -6.206774507368209],
            [106.79732879311248, -6.206709222347331],
            [106.79746053472167, -6.206630722045677],
            [106.79758390217467, -6.2065397624697844],
            [106.79769770737165, -6.206437219616299],
            [106.79780085430514, -6.206324081035407],
            [106.79789234961503, -6.206201436319948],
            [106.79797131215523, -6.206070466611779],
            [106.7980369814795, -6.2059324332265025],
            [106.79808872516463, -6.205788665506091],
            [106.79812604490071, -6.205640548016395],
            [106.79814858128978, -6.205489507212864],
            [106.79815611730662, -6.2053369977028865],
            [106.79814858038823, -6.205184488237046],
            [106.79812604313221, -6.205033447564235],
            [106.79808872259717, -6.204885330286815],
            [106.79803697821174, -6.204741562852078],
            [106.79797130831275, -6.2046035298149],
            [106.79789234534549, -6.204472560503871],
            [106.79780084977264, -6.204349916219335],
            [106.79769770275037, -6.204236778086588],
            [106.79758389764216, -6.204134235681247],
            [106.79746053045214, -6.204043276536276],
            [106.79732878927, -6.203964776631764],
            [106.7971899428292, -6.203899491958982],
            [106.7970453282886, -6.2038480512400085],
            [106.79689633835517, -6.2038109498729765],
            [106.79674440787194, -6.203788545161272],
            [106.796591, -6.203781052872638],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        circle: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.80659100000001, -6.171148846447315],
            [106.8061780964654, -6.17116901339233],
            [106.80576916932404, -6.171229320013244],
            [106.80536815667779, -6.1713291855378944],
            [106.80497892041481, -6.171467648228711],
            [106.8046052090204, -6.1716433746439305],
            [106.80425062147988, -6.17185467247823],
            [106.8039185726208, -6.172099506859168],
            [106.80361226022791, -6.172375519942534],
            [106.80333463424769, -6.172680053617959],
            [106.80308836837933, -6.173010175106188],
            [106.802875834325, -6.173362705201557],
            [106.80269907894811, -6.173734248887764],
            [106.8025598045592, -6.174121228032152],
            [106.80245935251942, -6.174519915843683],
            [106.80239869031975, -6.174926472762843],
            [106.80237840226033, -6.1753369834378455],
            [106.8023986838198, -6.175747494431086],
            [106.8024593397693, -6.176154052292728],
            [106.8025597860489, -6.176552741634766],
            [106.80269905538897, -6.176939722838869],
            [106.8028758066224, -6.177311269034849],
            [106.80308833759784, -6.177663801993596],
            [106.80333460157024, -6.177993926588771],
            [106.80361222691027, -6.178298463495312],
            [106.80391853994337, -6.178574479809793],
            [106.80425059069839, -6.178819317297678],
            [106.80460518131777, -6.179030617995355],
            [106.80497889685567, -6.179206346920347],
            [106.80536813816751, -6.179344811670879],
            [106.80576915657392, -6.179444678726039],
            [106.80617808996546, -6.179504986289435],
            [106.80659100000001, -6.179525153552684],
            [106.80700391003454, -6.179504986289435],
            [106.80741284342608, -6.179444678726039],
            [106.8078138618325, -6.179344811670879],
            [106.80820310314434, -6.179206346920347],
            [106.80857681868223, -6.179030617995355],
            [106.80893140930162, -6.178819317297678],
            [106.80926346005666, -6.178574479809793],
            [106.80956977308975, -6.178298463495312],
            [106.80984739842977, -6.177993926588771],
            [106.81009366240215, -6.177663801993596],
            [106.81030619337761, -6.177311269034849],
            [106.81048294461104, -6.176939722838869],
            [106.8106222139511, -6.176552741634766],
            [106.81072266023071, -6.176154052292728],
            [106.81078331618019, -6.175747494431086],
            [106.81080359773969, -6.1753369834378455],
            [106.81078330968026, -6.174926472762843],
            [106.81072264748059, -6.174519915843683],
            [106.81062219544081, -6.174121228032152],
            [106.81048292105191, -6.173734248887764],
            [106.81030616567502, -6.173362705201557],
            [106.81009363162067, -6.173010175106188],
            [106.80984736575232, -6.172680053617959],
            [106.8095697397721, -6.172375519942534],
            [106.8092634273792, -6.172099506859168],
            [106.80893137852014, -6.17185467247823],
            [106.80857679097963, -6.1716433746439305],
            [106.8082030795852, -6.171467648228711],
            [106.80781384332221, -6.1713291855378944],
            [106.80741283067597, -6.171229320013244],
            [106.8070039035346, -6.17116901339233],
            [106.80659100000001, -6.171148846447315],
          ],
        ],
      },
    },
  ],
};
