import { isEmpty } from 'lodash';
import React, { Fragment, useState, useEffect } from 'react';
import { usePlace } from '../../hooks/usePlace';
import './MapSearchTag.scss';

const IconCloseTemporary = () => {
  return (
    <svg
      className='close'
      height='12.263'
      viewBox='0 0 12.263 12.263'
      width='12.263'
    >
      <path
        d='M3.153,7.489V5.518H1.183a1.182,1.182,0,1,1,0-2.364h1.97V1.183a1.183,1.183,0,1,1,2.365,0V3.154H7.489a1.182,1.182,0,1,1,0,2.364H5.518V7.489a1.183,1.183,0,1,1-2.365,0Z'
        data-name='Union 24'
        fill='currentColor'
        id='Union_24'
        transform='translate(6.132) rotate(45)'
      />
    </svg>
  );
};

export function MapSearchTag() {
  const [province, isProvince] = useState(null);
  const [city, isCity] = useState(null);
  const [district, isDistrict] = useState(null);
  const [village, isVillage] = useState(null);
  const PLACE = usePlace();
  const DATA = PLACE?.state.geojson;

  useEffect(() => {
    DATA?.provinsi === null ? isProvince(null) : isProvince(DATA?.provinsi);
    DATA?.kota === null ? isCity(null) : isCity(DATA?.kota);
    DATA?.kecamatan === null ? isDistrict(null) : isDistrict(DATA?.kecamatan);
    DATA?.desa === null ? isVillage(null) : isVillage(DATA?.desa);
  }, [DATA]);

  return (
    <Fragment>
      {!isEmpty(province) && (
        <div className='bvt-mapsearch__card'>
          {village && (
            <Fragment>
              {village.id !== null && (
                <Fragment>
                  <div className='bvt-mapsearch-tag'>
                    {village.properties.displayName}
                  </div>
                  <button
                    className='bvt-mapsearch-tag__button'
                    disabled={PLACE.state.isAdvanceSearchDisabled}
                    onClick={() =>
                      isVillage(null) &
                      PLACE.setGeojson({ desa: null }) &
                      PLACE.removeParams({ desa: null })
                    }
                  >
                    <IconCloseTemporary />
                  </button>
                </Fragment>
              )}
            </Fragment>
          )}
          {district !== null && (
            <Fragment>
              {district.id !== null && (
                <Fragment>
                  <div className='space' />
                  <div className='bvt-mapsearch-tag'>
                    {district.properties.displayName}
                  </div>
                  <button
                    className='bvt-mapsearch-tag__button'
                    disabled={PLACE.state.isAdvanceSearchDisabled}
                    onClick={() =>
                      isDistrict(null) &
                      PLACE.setGeojson({ kecamatan: null }) &
                      PLACE.setGeojson({ desa: null }) &
                      PLACE.removeParams({ kecamatan: null }) &
                      PLACE.removeParams({ desa: null })
                    }
                  >
                    <IconCloseTemporary />
                  </button>
                </Fragment>
              )}
            </Fragment>
          )}
          {city !== null && (
            <Fragment>
              {city.id !== null && (
                <Fragment>
                  <div className='space' />
                  <div className='bvt-mapsearch-tag'>
                    {city.properties.displayName}
                  </div>
                  <button
                    className='bvt-mapsearch-tag__button'
                    disabled={PLACE.state.isAdvanceSearchDisabled}
                    onClick={() =>
                      isCity(null) &
                      PLACE.setGeojson({ kota: null }) &
                      PLACE.setGeojson({ kecamatan: null }) &
                      PLACE.setGeojson({ desa: null }) &
                      PLACE.removeParams({ kota: null }) &
                      PLACE.removeParams({ kecamatan: null }) &
                      PLACE.removeParams({ desa: null })
                    }
                  >
                    <IconCloseTemporary />
                  </button>
                </Fragment>
              )}
            </Fragment>
          )}
          {province !== null && (
            <Fragment>
              {province.id !== null && (
                <Fragment>
                  <div className='space' />
                  <div className='bvt-mapsearch-tag'>
                    {province.properties.displayName}
                  </div>
                  <button
                    className='bvt-mapsearch-tag__button'
                    disabled={PLACE.state.isAdvanceSearchDisabled}
                    onClick={() =>
                      isProvince(null) &
                      PLACE.setGeojson({
                        provinsi: null,
                      }) &
                      PLACE.setGeojson({ kota: null }) &
                      PLACE.setGeojson({ kecamatan: null }) &
                      PLACE.setGeojson({ desa: null }) &
                      PLACE.removeParams({
                        provinsi: null,
                      }) &
                      PLACE.removeParams({ kota: null }) &
                      PLACE.removeParams({ kecamatan: null }) &
                      PLACE.removeParams({ desa: null })
                    }
                  >
                    <IconCloseTemporary />
                  </button>
                </Fragment>
              )}
            </Fragment>
          )}
        </div>
      )}
    </Fragment>
  );
}
