import { useAuth } from '@bvt-features/auth/hooks';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export function SubModuleGateContainer() {
  const AUTH = useAuth();

  if (AUTH.state.data.isAuthenticated && AUTH.state.data.lokasiintelligenceAccess) {
    return AUTH.hasAccess('MAPANA.ASMANTELKOM') ? <Outlet /> : <Navigate to='/404' />;
  } else {
    return <Navigate to='/401' />;
  }
}
