export const AREA_STYLE = {
  selected: {
    fillColor: '#03D85D',
    color: '#03D85D',
    fillOpacity: 0.2,
    opacity: 1,
    weight: 2,
  },
  unselected: {
    fillColor: '#03D85D',
    color: '#03D85D',
    fillOpacity: 0,
    opacity: 0.4,
    weight: 4,
    dashArray: '8',
  },
};
