import { combineReducers } from 'redux';
import { GENSET_POI_COMBINE } from './poiCombineReducer';
import { GENSET_PARAMETER_REDUCER } from '../parameter/store/gensetParameter.slice';
import { MARKER_REDUCER } from '../marker/store/marker.slice';
import { CUSTOM_POI_REDUCER } from '../customPOI/store/customPOI.slice';

export const GENSET_REDUCER = combineReducers({
  PARAMETER: GENSET_PARAMETER_REDUCER,
  POI_MODULE: GENSET_POI_COMBINE,
  MARKER: MARKER_REDUCER,
  CUSTOM_POI: CUSTOM_POI_REDUCER,
});
